/*
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-09-03 11:04:03
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-05-08 16:39:25
 */
export default [
  // 用户信息
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () =>
      import(
        /* webpackChunkName: "userCenter" */ '@v/userCenter/account_info.vue'
      )
  },
  {
    path: '/contractInfo',
    name: 'contractInfo',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "contractInfo" */ '@v/userCenter/contract_info.vue'
      )
  },
  // 账户安全
  {
    path: '/accountSecurity',
    name: 'accountSecurity',
    component: () =>
      import(
        /* webpackChunkName: "account_security" */ '@v/userCenter/account_security.vue'
      )
  },
  // 认证信息
  {
    path: '/certification',
    name: 'certification',
    component: () =>
      import(
        /* webpackChunkName: "certification" */ '@v/userCenter/certification.vue'
      )
  },
  // 认证信息编辑
  {
    path: '/certificationEdit',
    name: 'certificationEdit',
    component: () =>
      import(
        /* webpackChunkName: "certificationEdit" */ '@v/userCenter/certification_edit.vue'
      )
  },
  // 人员管理
  {
    path: '/personnel',
    name: 'personnel',
    component: () =>
      import(/* webpackChunkName: "personnel" */ '@v/userCenter/personnel.vue')
  },
  // 岗位管理
  {
    path: '/positionSetting',
    name: 'positionSetting',
    component: () =>
      import(
        /* webpackChunkName: "positionSetting" */ '@v/userCenter/position_setting.vue'
      )
  },
  // 权限管理
  {
    path: '/authority',
    name: 'authority',
    component: () =>
      import(/* webpackChunkName: "authority" */ '@v/userCenter/authority.vue')
  },
  //新增订阅
  {
    path: '/addSubscription',
    name: 'addSubscription',
    meta: {
      title: '新增订阅',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/addSubscription.vue'
      )
  },
  //站内消息
  {
    path: '/msgList',
    name: 'msgList',
    meta: {
      title: '站内消息',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/msgList.vue'
      )
  },
  //新增订阅弹窗
  {
    path: '/addSubscriptionPopup',
    name: 'addSubscriptionPopup',
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/addSubscriptionPopup.vue'
      )
  },
  //订阅详情
  {
    path: '/subscriptionDetail',
    name: 'subscriptionDetail',
    meta: {
      title: '订阅详情',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/subscriptionDetail.vue'
      )
  },
  //订阅详情微信
  {
    path: '/subscriptionDetailWechat',
    name: 'subscriptionDetailWechat',
    meta: {
      title: '订阅详情微信',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/subscriptionDetailWechat.vue'
      )
  },
  //订阅详情qq
  {
    path: '/subscriptionDetailOther',
    name: 'subscriptionDetailOther',
    meta: {
      title: '订阅详情其他',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/subscriptionDetailOther.vue'
      )
  },
  //新增联系人
  {
    path: '/addSubscriptionPopupTwo',
    name: 'addSubscriptionPopupTwo',
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/addSubscriptionPopupTwo.vue'
      )
  },
  //新增联系人
  {
    path: '/addSubscriptionPopupTwos',
    name: 'addSubscriptionPopupTwos',
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/addSubscriptionPopupTwos.vue'
      )
  },
  //编辑订阅
  {
    path: '/editSubscription',
    name: 'editSubscription',
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/editSubscription.vue'
      )
  },
  //订阅管理
  {
    path: '/subscriptionManage',
    name: 'subscriptionManage',
    meta: {
      title: '订阅管理',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "msgAll" */

        '@v/msgCenter/news/subscriptionManage.vue'
      )
  },
  {
    path: '/msgDetail',
    name: 'msgDetail',
    component: () =>
      import(
        /* webpackChunkName: "msgDetail" */ '@v/msgCenter/news/msgDetail.vue'
      )
  },
  {
    path: '/management',
    name: 'management',
    component: () =>
      import(
        /* webpackChunkName: "management" */ '@v/msgCenter/subscription/management.vue'
      )
  },
  {
    path: '/wechatManagement',
    name: 'WeChatManagement',
    component: () =>
      import(
        /* webpackChunkName: "wechatManagement" */ '@v/msgCenter/subscription/wechatManagement.vue'
      )
  },
  {
    path: '/subscriptionManagePopup',
    name: 'subscriptionManagePopup',
    component: () =>
      import(
        /* webpackChunkName: "wechatManagement" */ '@v/msgCenter/news/subscriptionManagePopup.vue'
      )
  },
  // 全球直运服务  DSS
  {
    path: '/dssAccount',
    name: 'dssAccount',
    component: () =>
      import(
        /* webpackChunkName: "dssAccount" */ '@v/wallet/accounts/dss_account.vue'
      )
  },
  // 线上充值
  {
    path: '/rechargeOnline',
    name: 'rechargeOnline',
    component: () =>
      import(
        /* webpackChunkName: "rechargeOnline" */ '@v/wallet/recharge/Recharge.vue'
      )
  },
  // 充值历史
  {
    path: '/rechargeHistory',
    name: 'rechargeHistory',
    component: () =>
      import(
        /* webpackChunkName: "rechargeHistory" */ '@v/wallet/recharge_history.vue'
      )
  },
  // 充值打印预览
  {
    path: '/printRecharge',
    name: 'printRecharge',
    component: () =>
      import(
        /* webpackChunkName: "printRecharge" */ '@v/wallet/print_recharge.vue'
      )
  },
  // 订购退件套餐
  {
    path: '/grsServices',
    name: 'grsServices',
    component: () =>
      import(
        /* webpackChunkName: "grsServices" */ '@v/wallet/grsServices/GrsServices.vue'
      )
  },
  // 委托付款
  {
    path: '/entrustedPayment',
    name: 'entrustedPayment',
    component: () =>
      import(
        /* webpackChunkName: "entrustedPayment" */ '@v/wallet/entrusted_payment.vue'
      )
  },
  // 自动入账
  {
    path: '/autoBillsSet',
    name: 'autoBillsSet',
    component: () =>
      import(
        /* webpackChunkName: "autoBillsSet" */ '@v/wallet/autoBillsSet/auto_bills_set.vue'
      )
  },
  // 委托付款详情
  {
    path: '/entrustedPaymentDetail',
    name: 'entrustedPaymentDetail',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "entrustedPaymentDetail" */ '@v/wallet/entrusted_payment_detail.vue'
      )
  },
  // 我的账单
  {
    path: '/myBills',
    name: 'myBills',
    component: () =>
      import(/* webpackChunkName: "myBills" */ '@v/wallet/my_bills.vue')
  },
  {
    path: '/invoiceManege',
    name: 'invoiceManege',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "invoiceManege" */ '@v/wallet/invoiceManege/index.vue'
      )
  },
  // 未出账单详情
  {
    path: '/outbillDetail',
    name: 'outbillDetail',
    component: () =>
      import(
        /* webpackChunkName: "outbillDetail" */ '@v/wallet/outbill_detail.vue'
      )
  },
  // 每日费用
  {
    path: '/freeEveryday',
    name: 'freeEveryday',
    component: () =>
      import(
        /* webpackChunkName: "freeEveryday" */ '@v/wallet/free_everyday.vue'
      )
  },
  // 仓租收费记录
  {
    path: '/warehouseChargeRecord',
    name: 'warehouseChargeRecord',
    // meta: {
    //   keepAlive: true
    // },
    component: () =>
      import(
        /* webpackChunkName: "warehouseChargeRecord" */ '@v/wallet/warehouseChargeRecord/WarehouseChargeRecord.vue'
      )
  },
  // 仓租收费详情
  {
    path: '/warehouseChargeDetail',
    name: 'warehouseChargeDetail',
    component: () =>
      import(
        /* webpackChunkName: "warehouseChargeDetail" */ '@v/wallet/warehouseChargeRecord/Detail.vue'
      )
  },
  // 消费流水
  {
    path: '/consumeList',
    name: 'consumeList',
    component: () =>
      import(/* webpackChunkName: "consumeList" */ '@v/wallet/consume_list.vue')
  },
  // 海外仓流水详情
  {
    path: '/oversideDetail',
    name: 'oversideDetail',
    meta: {
      title: '费用明细详情',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "oversideDetail" */ '@v/wallet/consume/overside_detail.vue'
      )
  },
  // 消费流水
  {
    path: '/consumeDetail',
    name: 'consumeDetail',
    component: () =>
      import(
        /* webpackChunkName: "consumeDetail" */ '@v/wallet/consume_detail.vue'
      )
  },
  // dss消费流水详情
  {
    path: '/dssConsumeDetail',
    name: 'dssConsumeDetail',
    meta: {
      title: '费用明细详情',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "dss_consumeDetail" */ '@v/wallet/dss_consume_detail.vue'
      )
  },
  // 冻结流水
  {
    path: '/freezeList',
    name: 'freezeList',
    component: () =>
      import(/* webpackChunkName: "freezeList" */ '@v/wallet/freeze_list.vue')
  },
  // 冻结流水
  {
    path: '/freezeDetail',
    name: 'freezeDetail',
    component: () =>
      import(
        /* webpackChunkName: "freezeDetail" */ '@v/wallet/freeze_detail.vue'
      )
  },
  // 水单列表
  {
    path: '/waterList',
    name: 'waterList',
    component: () =>
      import(/* webpackChunkName: "waterList" */ '@v/wallet/water_list.vue')
  },
  // 水单列表
  {
    path: '/waterDetail',
    name: 'waterDetail',
    component: () =>
      import(/* webpackChunkName: "waterDetail" */ '@v/wallet/water_detail.vue')
  },
  // 水单录入
  // {
  //   path: '/addWater',
  //   name: 'addWater',
  //   component: () =>
  //     import(/* webpackChunkName: "addWater" */ '@v/wallet/add_water.vue')
  // },
  // 币种转换
  {
    path: '/currencyTransfer',
    name: 'currencyTransfer',
    component: () =>
      import(
        /* webpackChunkName: "currencyTransfer" */ '@v/wallet/currency_transfer.vue'
      )
  },
  // 币种互换设置
  {
    path: '/currencySetting',
    name: 'currencySetting',
    component: () =>
      import(
        /* webpackChunkName: "currencySetting" */ '@v/wallet/currency_setting.vue'
      )
  },
  // 认证入口
  {
    path: '/qualification',
    name: 'qualification',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "qualification" */ '@v/userCenter/qualification/index.vue'
      )
  },
  // 认证类型选择
  {
    path: '/choose_qualification',
    name: 'choose_qualification',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "choose_qualification" */ '@v/userCenter/qualification/choose_qualification.vue'
      )
  },
  // 个人认证1
  {
    path: '/personal',
    name: 'personal',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "personal" */ '@v/userCenter/qualification/personal.vue'
      )
  },
  // 个人线上认证
  {
    path: '/personal_online',
    name: 'personal_online',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "personal_online" */ '@v/userCenter/qualification/personal_online.vue'
      )
  },
  // 个人线上认证
  {
    path: '/personal_offline',
    name: 'personal_offline',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "personal_offline" */ '@v/userCenter/qualification/personal_offline.vue'
      )
  },
  // 企业实名认证
  {
    path: '/company_1',
    name: 'company_1',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "company_1" */ '@v/userCenter/qualification/company_1.vue'
      )
  },
  // 企业实名认证2
  {
    path: '/company_2',
    name: 'company_2',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "company_2" */ '@v/userCenter/qualification/company_2.vue'
      )
  },
  // 企业实名认证3
  {
    path: '/company_3',
    name: 'company_3',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "company_3" */ '@v/userCenter/qualification/company_3.vue'
      )
  },
  // 企业实名认证4
  {
    path: '/company_4',
    name: 'company_4',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "company_4" */ '@v/userCenter/qualification/company_4.vue'
      )
  },
  // 企业实名认证5
  {
    path: '/company_5',
    name: 'company_5',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "company_5" */ '@v/userCenter/qualification/company_5.vue'
      )
  },
  // 企业实名未完成
  {
    path: '/company_warn',
    name: 'company_warn',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "company_warn" */ '@v/userCenter/qualification/company_warn.vue'
      )
  },
  // 企业实名未完成
  {
    path: '/company_info',
    name: 'company_info',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "company_info" */ '@v/userCenter/qualification/company_info.vue'
      )
  },
  // 直发-地址管理
  {
    path: '/dssAddress',
    name: 'dssAddress',
    meta: {
      title: '地址管理',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "dssAddress" */ '@v/userCenter/address/DssAddress.vue'
      )
  },
  {
    path: '/fb4Address',
    name: 'fb4Address',
    meta: {
      title: '地址管理',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "fb4Address" */ '@v/userCenter/address/FB4/Index.vue'
      )
  },
  // 分销账户设置
  {
    path: '/distriList',
    name: 'distriList',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "distribution" */ '@v/userCenter/dirstrLister.vue'
      )
  },
  // 首页设置
  {
    path: '/index_settings',
    name: 'index_settings',
    component: () =>
      import(/* webpackChunkName: "userCenter" */ '@v/index/index_settings.vue')
  },
  // 隐私协议
  {
    path: '/privacyAgree',
    name: 'privacyAgree',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "userCenter" */ '@v/userCenter/privacyAgree.vue'
      )
  }
]
