/*
 * @Description: 数据中心
 * @Autor: S9637/chifuk
 * @Date: 2020-09-07 10:52:56
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-09-12 14:30:14
 */
export default [
  {
    path: '/storeData:id',
    name: 'storeData',
    component: () =>
      import(/* webpackChunkName: "storeData" */ '@v/dataCenter/DataCenter'),
    children: [
      {
        path: '/storeSummary',
        name: 'storeSummary',
        component: () =>
          import(
            /* webpackChunkName: "storeSummary" */ '@v/dataCenter/inventory/StoreSummary'
          )
      },
      {
        path: '/storeDetail',
        name: 'storeDetail',
        component: () =>
          import(
            /* webpackChunkName: "storeDetail" */ '@v/dataCenter/inventory/StoreDetail'
          )
      },
      {
        path: '/storeTime',
        name: 'storeTime',
        component: () =>
          import(
            /* webpackChunkName: "storeTime" */ '@v/dataCenter/inventory/StoreTime'
          )
      },
      {
        path: '/outAnalyze',
        name: 'outAnalyze',
        component: () =>
          import(
            /* webpackChunkName: "outAnalyze" */ '@v/dataCenter/out/OutAnalyze'
          )
      },
      {
        path: '/inboundFulfillment',
        name: 'inboundFulfillment',
        component: () =>
          import(
            /* webpackChunkName: "inboundFulfillment" */ '@v/dataCenter/order/inboundFulfillment'
          )
      },
      {
        path: '/outboundFulfillment',
        name: 'outboundFulfillment',
        component: () =>
          import(
            /* webpackChunkName: "outboundFulfillment" */ '@v/dataCenter/order/outboundFulfillment'
          )
      },
      {
        path: '/abnormal',
        name: 'abnormal',
        component: () =>
          import(
            /* webpackChunkName: "abnormal" */ '@v/dataCenter/order/abnormal'
          )
      },
      {
        path: '/inventoryDiagnosis',
        name: 'inventoryDiagnosis',
        component: () =>
          import(
            /* webpackChunkName: "inventoryDiagnosis" */ '@v/dataCenter/inventory/InventoryDiagnosis'
          )
      },
      {
        path: '/orderAging',
        name: 'orderAging',
        meta: {
          ignore: true
        },
        component: () =>
          import(
            /* webpackChunkName: "orderAging" */ '@v/dataCenter/dss/OrderAging'
          )
      }
    ]
  }
]
