<template>
  <div class="home-index">
    <div class="mb20 index-row">
      <BannerList />
      <!-- 最新公告 -->
      <Announcement />
    </div>
    <div class="index-row">
      <div class="section-box trial-box">
        <NewDssFreight
          :isHomePage="true"
          :isNewHomePage="true"
          :isDssHomePage="true"
        />
      </div>
    </div>
    <div class="index-row">
      <div class="section-box">
        <RecommendProduct />
        <HotCountry />
        <FeaturedServices />
        <RegistrationGuidelines
          v-if="
            !$store.state.userInfo.isDSSOpen &&
              !$store.state.userInfo.isFB4Open &&
              !$store.state.userInfo.isGRSOpen &&
              !$store.state.userInfo.isTRSOpen
          "
        />
      </div>
    </div>
    <div class="index-footer">
      <p class="footer-com">
        {{
          $t('index_tran_034', {
            newYear: new Date().getFullYear()
          })
        }}
        <a class="footer-a" href="https://beian.miit.gov.cn" target="_blank">{{
          $t('index_tran_03402')
        }}</a>
      </p>
      <!-- <p class="footer-right">© 2004-2024</p> -->
    </div>
    <collection-popup></collection-popup>
    <!-- 调查问券弹窗 -->
    <!-- <SurveyPopup /> -->
    <!-- <ScorePopup /> -->
    <OpenFlow />
  </div>
</template>

<script>
import BannerList from './newIndexComponents/BannerList.vue'
import NewDssFreight from '../serviceCenter/dssFreight/index'
import RecommendProduct from './newIndexComponents/RecommendProduct.vue'
import HotCountry from './newIndexComponents/HotCountry.vue'
import FeaturedServices from './newIndexComponents/FeaturedServices.vue'
import RegistrationGuidelines from './newIndexComponents/RegistrationGuidelines.vue'
import Announcement from './announcement/Announcement'

import CollectionPopup from './collectionPopup/CollectionPopup'
import OpenFlow from './overseasCertfication/OpenFlow'

export default {
  name: 'home-index',
  components: {
    BannerList,
    Announcement,
    NewDssFreight,
    RecommendProduct,
    HotCountry,
    FeaturedServices,
    RegistrationGuidelines,
    CollectionPopup,
    OpenFlow
  },
  data() {
    return {
      bannerList: []
    }
  },
  created() {
    this.checkUserStatus()
  },
  mounted() {
    this.activeNav()
    let item = {
      name: 'index',
      active: true
    }
    let navItems = []
    try {
      navItems = JSON.parse(sessionStorage.getItem('nav.items'))
    } catch (error) {
      navItems = []
    }

    navItems.forEach(x => {
      x.active = false
      if (item.name == x.name) {
        x.active = true
      }
    })
    this.$store.commit('updateNavItems', navItems)
  },
  methods: {
    activeNav() {
      let item = {
        name: 'index'
      }
      let navItems = []
      try {
        navItems = JSON.parse(sessionStorage.getItem('nav.items'))
      } catch (error) {
        navItems = []
      }

      ;(navItems || []).forEach(x => {
        x.active = false
        if (item.name == x.name) {
          x.active = true
        }
      })
      this.$store.commit('updateNavItems', navItems)
    },
    checkUserStatus() {
      this.$get('v2/account/iscancel').then(res => {
        if (res === true) {
          sessionStorage.clear()
          location.href = '/logout'
        }
      })
    }
  }
}
</script>

<style lang="less">
// div,i,span {
//     caret-color: transparent;
// }
.home-index {
  position: relative;
  box-sizing: border-box;
  padding-top: 20px;
  .mb20 {
    margin-bottom: 20px !important;
  }
  .index-row {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
  }
  .index-title {
    height: 18px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 900;
    color: #000000;
    line-height: 18px;
    display: inline-block;
  }

  .section-box {
    width: calc(66.67% + 360px);
    min-width: 1220px;
  }
  .trial-box {
    height: 92px;
    // overflow: hidden;
    border-radius: 12px;
  }
  .b-more {
    cursor: pointer;
    font-size: 12px;
    color: #999;
    float: right;
  }
  .index-footer {
    height: 67px;
    background: #ffffff;
    // margin-top: 24px;
    display: flex;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    .footer-com {
      text-align: center;
      padding-top: 0px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
    }
    .footer-right {
      text-align: center;
      font-size: 12px;
      font-family: Helvetica;
      color: #666666;
      line-height: 12px;
      margin-top: 11px;
      margin-bottom: 14px;
    }
    .footer-a {
      color: #666666 !important;
      &:hover {
        color: #3960df !important;
      }
    }
  }
}

.recommend-tit-box {
  margin-top: 28px;
  margin-bottom: 20px;
  text-align: center;
}
.recommend-tit {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  padding: 0 16px;
  font-size: 20px;
  color: #323643;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  transition: all 0.3s;
  animation: all 0.3s;
  &:hover {
    transform: scale(1.1);
  }
  &::before {
    position: absolute;
    top: 15px;
    margin-left: -100%;
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    transform: rotate(180deg);
    background-image: linear-gradient(
      270deg,
      rgba(107, 153, 241, 0) 0%,
      #3960df 100%
    );
    border-radius: 3px;
  }
  &::after {
    position: absolute;
    top: 15px;
    margin-left: 100%;
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background-image: linear-gradient(
      270deg,
      rgba(107, 153, 241, 0) 0%,
      #3960df 100%
    );
  }
}
.content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 18px;
  margin-bottom: 15px;

  .content-name {
    font-size: 18px;
    color: #1f2e5f;
    line-height: 18px;
    font-weight: bold;
  }
  .content-link {
    position: relative;
    display: flex;
    align-content: center;
    font-size: 16px;
    color: #3960df;
    line-height: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
    animation: all 0.3s;
    .iconfont {
      margin-left: 6px;
      font-size: 8px;
      font-weight: normal;
    }
    &:hover {
      position: relative;
      transform: scale(1.1);
    }
  }
}
</style>
