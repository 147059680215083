<!--
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-08-28 13:55:45
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-09-10 13:15:19
-->
<i18n>
{
  "zh": {
    "app": "商家中心",
    "personal": "个人中心",
    "account": "财务管理",
    "logout": "退出登录",
    "index": "首页",
    "FB4":"海外仓",
    "storeSummary": "数据中心",
    "oceanAir": "海运空运",
    "jiyun": "集运",
    "dssAccount": "财务管理",
    "userCenter": "个人中心",
    "overseasSearch": "服务中心",
    "orderManagement": "交易订单",
    "serviceCenterIndex": "服务中心",
    "open": "开通服务",
    "desc": "服务介绍",
    "old": "返回旧版",
    "oldBusiness": "返回商家旧版",
    "oldDss": "返回直发旧版",
    "oldFB4": "返回海外仓旧版",
    "tipdesc": "商家中心进行全新升级啦，在使用过程中有任何疑问可以给我们留言或者向客服反馈哦！"
  },
  "ja": {
    "app": "ビジネスセンター",
    "personal": "パーソナルセンター",
    "index_set.homePageSettings":"トップページの設定",
    "account": "私の口座",
    "logout": "ログインを終了",
    "index": "トップページ",
    "DSS":"DSS",
    "FB4":"FB4",
    "TRS":"TRS",
    "GRS":"GRS",
    "storeSummary": "データ",
    "oceanAir": "海運空輸",
    "jiyun": "コレクション",
    "dssAccount": "アカウント",
    "userCenter": "パーソナルセンター",
    "overseasSearch": "サービス",
    "orderManagement": "取引受注",
    "serviceCenterIndex": "サービスセンター",
    "open": "開通サービス",
    "desc": "サービス紹介",
    "old": "旧版",
    "oldBusiness":"商家旧版に戻る",
    "oldDss":"直発旧版に戻る",
    "oldFB4":"海外倉旧版に戻る",
    "tipdesc": "ビジネスセンターは新しいアップグレードを行いました。使用中に何か質問があれば、メッセージを送ったり、カスタマーサービスにフィードバックしたりすることができますよ。"
  },
  "de": {
    "app":"Benutzercenter",
    "personal":"Personal Center",
    "index_set.homePageSettings":"Startseite Einstellungen",
    "account":"Mein Konto",
    "logout":"Abmelden",
    "index":"Startseite",
    "DSS":"DSS",
    "FB4":"FB4",
    "TRS":"TRS",
    "GRS":"GRS",
    "storeSummary":"Daten",
    "oceanAir":"Luft- und Seeverkehr",
    "jiyun": "Zentraler Verkehr",
    "dssAccount":"Konto",
    "userCenter":"Personal Center",
    "overseasSearch":"Servicer",
    "orderManagement":"Transaktionsauftrag",
    "serviceCenterIndex":"Service Center",
    "open":"Öffnungsservice",
    "desc":"Service Einführung",
    "old":"Alte  Version",
    "oldBusiness": "Alte Version",
    "oldDss": "DSS alte Version",
    "oldFB4": "FB4 alte Version",
   "tipdesc": "Das Merchant Center wurde neu aufgerüstet. Wenn Sie während des Nutzungsprozesses Fragen haben, können Sie eine Nachricht hinterlassen oder dem Kundenservice Feedback geben!"
  },
  "en": {
    "app": "User center",
    "personal": "Personal Center",
    "index_set.homePageSettings":"Home Page Settings",
    "account": "Account",
    "logout": "Logout",
    "index": "Index",
    "DSS":"DSS",
    "FB4":"FB4",
    "TRS":"TRS",
    "GRS":"GRS",
    "storeSummary": "Data",
    "oceanAir": "Air/Ocean Shipping",
    "jiyun": "Centralized transportation",
    "dssAccount": "Account",
    "userCenter": "Personal Center",
    "overseasSearch": "Service",
    "orderManagement": "Orders",
    "serviceCenterIndex": "Service",
    "open": "Open Service",
    "desc": "Service Introduction",
    "old": "Old",
    "oldBusiness": "Old Version",
    "oldDss": "DSS Old Version",
    "oldFB4": "FB4 Old Version",
    "tipdesc": "The merchant center has undergone a brand new upgrade. If you have any questions during the use process, you can leave a message or give feedback to the customer service!"
  }
}
</i18n>
<template>
  <div class="header-box">
    <Notify v-if="hasTopBanner" />
    <div class="nav-bar flex-sp">
      <div>
        <!-- nav logo -->
        <div class="nav-logo">
          <img class="nav-logo-img" src="../../assets/nav/logo@2x.png" alt="" />
          <div class="nav-logo-line"></div>

          <p class="nav-logo-title">{{ $t('app') }}</p>
          <span @click="triggerCollapsed" class="toggleCollapsed-icon"
            ><a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          /></span>
          <template v-if="username">
            <a-dropdown>
              <p class="nav-logo-back">
                {{ $t('old') }}
              </p>
              <a-menu slot="overlay" @click="handleOldClick">
                <!-- <a-menu-item
                  data-baidu-pv="顶部导航栏，首页，返回旧版"
                  key="B"
                  >{{ $t('oldBusiness') }}</a-menu-item
                > -->
                <a-menu-item
                  v-if="!$store.getters.isOverSeas"
                  data-baidu-pv="顶部导航栏，首页，返回直发旧版"
                  key="DSS"
                  >{{ $t('oldDss') }}</a-menu-item
                >
                <!-- <a-menu-item
                  data-baidu-pv="顶部导航栏，首页，返回海外仓旧版"
                  key="FB4"
                  >{{ $t('oldFB4') }}</a-menu-item
                > -->
              </a-menu>
            </a-dropdown>
          </template>
        </div>
        <!-- nav menu -->
        <ul class="nav-menu">
          <template v-for="(item, index) in navItems">
            <li
              :style="!item.sub ? navPadding : ''"
              :key="index"
              :class="[item.active ? 'active' : '']"
              :data-baidu-pv="item.pv"
              @click="onNavClick(item)"
              v-if="
                (!username &&
                  (item.name == 'index' || item.name == 'productService')) ||
                  username
              "
            >
              <a-dropdown v-if="item.sub" class="nav-dropdown">
                <div class="ant-dropdown-link" @click="e => e.preventDefault()">
                  {{ $t(item.name) }} <a-icon type="down" />
                </div>
                <a-menu slot="overlay" @click="handleServiceMenuClick">
                  <a-menu-item
                    :key="s.name"
                    :value="item.name"
                    v-for="s in item.sub"
                  >
                    {{ $t(s.name) }}
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a :href="urlLink(item.name)" v-else>{{ $t(item.name) }}</a>
            </li>
          </template>
        </ul>
      </div>

      <div
        class="nav-right"
        :style="{
          top: hasTopBanner ? '24px' : '0px',
          right: !username ? '108px' : '0px'
        }"
      >
        <MiniProgtDialog />
        <weChatDialog v-if="username" />
        <!-- 渤海银行借贷按钮 -->
        <span
          v-if="whiteStatus == 'Y' && username"
          class="bohai-btn"
          @click="loanVisible = !loanVisible"
          >贷</span
        >

        <a-dropdown>
          <div style="height: 56px">
            <div class="nav-lang">
              {{ lang }}
            </div>
          </div>
          <a-menu slot="overlay" @click="handleLangMenuClick">
            <a-menu-item key="zh">中文 </a-menu-item>
            <a-menu-item key="en">English </a-menu-item>
            <!-- v-if="this.$route.name == 'orderFrame'" -->
            <a-menu-item key="de">Deutsch</a-menu-item>
            <!-- v-if="this.$route.name == 'orderFrame'" -->
            <a-menu-item key="ja">日本語</a-menu-item>
          </a-menu>
        </a-dropdown>
        <span class="nav-msg"></span>
        <!-- 消息中心 -->
        <div
          v-if="!$store.getters.isOverSeas && username"
          class="nav-notify"
          @click="goMesCenter"
          style="position: relative"
          data-baidu-pv="顶部导航栏，消息，查看消息"
        >
          <i
            :style="{
              'font-size': '18px',
              color: isMsgRouter ? '#3960df' : ''
            }"
            class="iconfont iconxiaoxi1"
          ></i>

          <div class="msg-num" v-if="this.$store.state.navModule.unReadMeaages">
            {{
              this.$store.state.navModule.unReadMeaages > 99
                ? '99+'
                : this.$store.state.navModule.unReadMeaages
            }}
          </div>
        </div>
        <!-- <div class="nav-notify" v-if="imShow">
          <a-popover placement="bottom">
            <template slot="content">
              <IM />
            </template>
            <img
              class="nav-img"
              src="../../assets/nav/customer@2x.png"
              alt=""
            />
          </a-popover>
        </div> -->
        <a-dropdown v-if="username">
          <div class="user-menu ant-dropdown-link" style="padding-left: 0">
            <div class="nav-user">
              <img
                class="nav-img"
                src="../../assets/nav/avatar@2x.png"
                alt=""
                style="width: 28px; height: auto"
              />
            </div>
            <div class="user-name" v-if="!showAvator">
              {{ $store.state.username || username }}
            </div>
            <a-icon class="user-name-down" type="down" />
          </div>
          <a-menu
            slot="overlay"
            @click="handleMenuClick"
            style="width: auto; margin-left: 30px"
          >
            <a-menu-item
              key="userCenter"
              data-baidu-pv="顶部导航栏，个人中心，查看个人中心"
            >
              {{ $t('personal') }}
            </a-menu-item>
            <a-menu-item key="dssAccount">
              {{ $t('account') }}
            </a-menu-item>
            <a-menu-divider />
            <!--
            <a-menu-item v-if="!$store.getters.isOverSeas" key="index_settings">
              {{ $t('index_set.homePageSettings') }}
            </a-menu-item>
            -->
            <a-menu-item key="logout">
              {{ $t('logout') }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <a-button
        @click="onLogin"
        class="search-btn"
        style="margin-right: 24px"
        v-if="!username"
        >{{ $t('login') }}</a-button
      >
    </div>
    <OpenWrapper ref="openWrapper" :info="currentBrand" />
    <!-- 消息弹窗 -->
    <a-modal
      v-if="popData.length > 0"
      v-model="popVisible"
      :title="popData[current - 1].categoryName"
      centered
      style="float: right"
    >
      <template>
        <div v-html="popData[current - 1].content"></div>
      </template>
      <template #footer>
        <div class="pagination">
          <a-pagination
            v-model="current"
            @change="changePopPage"
            simple
            :total="popLength"
            :pageSize="1"
          />
        </div>
        <a-button key="submit" type="primary" @click="handleOk"
          >我已知晓</a-button
        >
      </template>
    </a-modal>
    <!-- 渤海银行借贷 -->
    <LoanPopup v-if="loanVisible" @close="loanVisible = !loanVisible" />
  </div>
</template>
<script>
import { fb4Request } from '@/api/fb4'
import { navUrl } from '@/const/moudUrl'
import IM from '@c/IM/IM'
import { LINKS } from '@const/index'
import { unLoginHandler } from '../../api/index'
import Cookie from 'js-cookie'
import moment from 'moment'
import OpenWrapper from '../index/businessKanban/status/OpenWrapper'
import Notify from './Notify'
import LoanPopup from '../index/bohai/LoanPopup'
import MiniProgtDialog from './MiniProgtDialog'
import weChatDialog from './weChatDialog'
import sensors from 'sa-sdk-javascript'

export default {
  name: 'nav-bar',
  components: {
    IM,
    OpenWrapper,
    Notify,
    LoanPopup,
    weChatDialog,
    MiniProgtDialog
  },

  computed: {
    lang() {
      console.log('语言', this.$i18n.locale)
      let lang = '中'
      switch (this.$i18n.locale) {
        case 'zh':
          lang = '中'
          break
        case 'en':
          lang = 'EN'
          break
        case 'ja':
          lang = 'JA'
          break
        case 'de':
          lang = 'DE'
          break
        default:
          lang = '中'
      }
      return lang
    },
    navItems() {
      let navMenu = this.$store.state.navModule.items
      // 获取分销商管理模块
      if (!this.showitems) {
        this.$get('v2/distributor/showitem').then(res => {
          this.showitems = res
          if (res.type === 'BU' || res.type === 'BD') {
            let news = this.$store.state.navModule.items
            news = news.filter(item => item.name != 'FXGL')
            if (this.$store.getters.isOverSeas) {
              news = this.filterNavItem()
            }
            navMenu = news
          }
        })
      } else {
        if (this.showitems.type === 'BU' || this.showitems.type === 'BD') {
          let news = this.$store.state.navModule.items
          news = news.filter(item => item.name != 'FXGL')
          if (this.$store.getters.isOverSeas) {
            news = this.filterNavItem()
          }
          navMenu = news
        }
      }
      if (this.$store.getters.isOverSeas) {
        navMenu = this.filterNavItem()
      }
      navMenu = navMenu.filter(item => {
        if (item.name == 'oceanAir') {
          delete item.sub
        }
        return item
      })
      return navMenu
    },
    hasTopBanner() {
      return this.$store.state.hasTopBanner
    },
    imShow() {
      const urls = this.$store.state.imUrls || {}
      let hasFind = false
      for (let k in urls) {
        if (urls[k]) hasFind = true
      }
      return hasFind
    }
  },
  data() {
    return {
      count: 0,
      isMsgRouter: false,
      collapsed: false,
      username: process.env.NODE_ENV !== 'production' ? 'dev' : '',
      navPadding: 'padding: 0 20px;',
      showAvator: false,
      brandList: [],
      currentBrand: {},
      showitems: null,
      isFB4White: false,
      popVisible: false,
      current: 1,
      popData: [],
      popLength: 5,
      loanVisible: false,
      whiteStatus: '',
      AppENV: process.env.NODE_ENV,
      isMicroApp: ''
    }
  },
  created() {
    this.getUser()
    this.getBrandInfo()
    this.checkFB4WhiteList()
    this.getCustomerStatus()
    var that = this
    document.addEventListener('click', function(e) {
      e.path?.forEach(item => {
        if (item?.className && item?.className?.indexOf('wc-icon') > -1) {
          that.loanVisible = false
        }
      })
    })
  },
  watch: {
    $route(to) {
      // if (
      //   this.$route.name != 'orderFrame' &&
      //   this.$route.name != 'refresh' &&
      //   this.$i18n.locale != 'zh' &&
      //   this.$i18n.locale != 'en'
      // ) {
      //   this.handleLangMenuClick({ key: 'zh' })
      // }
      if (
        this.$route.name == 'msgCenter' ||
        this.$route.name == 'msgList' ||
        this.$route.name == 'addSubscription' ||
        this.$route.name == 'subscriptionManage' ||
        this.$route.name == 'WeChatManagement' ||
        this.$route.name == 'management' ||
        this.$route.name == 'productIndex' ||
        this.$route.name == 'msgDetail'
      ) {
        this.isMsgRouter = true
      } else {
        this.isMsgRouter = false
      }
      // 判断融入微前端应用
      if (
        to.name == 'orderFrame' ||
        to.name == 'jiyun' ||
        to.name == 'trsFrame'
      ) {
        if (to.name == 'trsFrame' && to.hash == '#/myserviceList') {
          this.$router.push({
            name: 'myserviceList',
            params: { tabKey: 2, orderCode: '' }
          })
        } else {
          this.isMicroApp = to.name + '#'
        }
      } else {
        this.isMicroApp = ''
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$root.bus.$on('onresize', _.debounce(this.layoutNav, 300))
      window.onload = () => {
        this.layoutNav()
      }
    })
    let params = { channelCode: 'stationMail' }
    this.$post('v2/message/getUnReadCount', params).then(res => {
      this.$store.state.navModule.unReadMeaages = res.unReadCount
    })
    // 站内消息
    this.$post('v2/message/getBubbleMessage', {
      pageNum: 1,
      pageSize: 1,
      isRead: 'false'
    }).then(res => {
      if (res?.list?.length > 0) {
        this.$notification.warning({
          // duration: 5,
          icon: (
            <i
              style="color:#FF6400;font-size:22px"
              class="iconfont iconbianzu"
            ></i>
          ),
          key: res.list[0].recordId,
          message: (
            <div>
              贵司有
              <a>
                【
                {res.list[0].title
                  ? res.list[0].title
                  : res.list[0].itemCategoryName}
                】
              </a>
              等消息待查看！
            </div>
          ),
          description: (
            <a-button size="small" style="margin-top:10px" type="primary">
              立即查看
            </a-button>
          ),

          onClick: () => {
            this.$router.push({
              name: 'msgDetail',
              query: { recordId: res.list[0].recordId }
            })
            this.$notification.close(res.list[0].recordId)
          }
        })
      }
    })

    // 弹窗消息
    this.$get('v2/message/pop').then(res => {
      this.popVisible = true
      this.popData = res || []
      this.popLength = this.popData?.length
    })
  },

  beforeDestroy() {
    this.$root.bus.$off('onresize')
  },
  methods: {
    filterNavItem() {
      let newItems = this.$store.state.navModule.items
      newItems = newItems.filter(item => {
        if (
          item.name != 'DSS' &&
          item.name != 'TRS' &&
          item.name != 'GRS' &&
          item.name != 'oceanAir' &&
          item.name != 'FXGL' &&
          item.name != 'storeSummary' &&
          item.name != 'serviceCenterIndex' &&
          item.name != 'productService' &&
          item.name != 'jiyun'
        ) {
          return item
        }
      })
      return newItems
    },
    getCustomerStatus() {
      this.$post('hbLoan/queryBhCustomerStatus')
        .then(res => {
          // 是否是白名单
          this.whiteStatus = res.whiteStatus
        })
        .catch(() => {
          this.$hide()
        })
    },
    changePopPage() {
      console.log('current', this.current)
      if (this.current > 1) {
        this.read('page')
      }
    },
    triggerCollapsed() {
      this.collapsed = !this.collapsed
      this.$store.commit('updateCollapsed', this.collapsed)
      this.$emit('collapsed', this.collapsed)
    },
    onLogin() {
      Cookie.remove('lang', { path: '', domain: '.i4px.com' })
      Cookie.remove('lang', { path: '', domain: '.4px.com' })
      sessionStorage.clear()
      // location.href = '/logout'
      setTimeout(() => {
        unLoginHandler()
      }, 200)
    },
    handleOldClick(item) {
      if (item.key === 'B') {
        this.onOldClick()
      } else if (item.key === 'DSS') {
        // ud 标识强制返回旧版，即使是白名单
        location.href = `${LINKS[item.key]}/order/personal_center/index?ud=1`
      } else if (item.key === 'FB4') {
        window.open(LINKS[item.key], '_blank')
      }
    },
    handleOk() {
      this.popVisible = false
      this.read()
    },
    // 已读
    read(type) {
      let readIdArr = []
      let current = this.current
      if (type == 'page') {
        current--
      }
      this.popData.forEach((item, index) => {
        if (index < current) {
          readIdArr.push(item.recordId)
        }
      })
      this.$post(`v2/message/read`, {
        recordIdList: readIdArr
      }).then(() => {
        this.selectedRowKeys = []
      })
    },
    toText(html) {
      let newHtml =
        html
          .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
          .replace(/<[^>]+?>/g, '')
          .replace(/\s+/g, ' ')
          .replace(/ /g, ' ')
          .replace(/>/g, ' ') || '-'
      newHtml = newHtml.replace(/&nbsp;/g, '') //去掉空格
      return newHtml
    },

    onOldClick() {
      Cookie.set('use_old_version', true, { expires: 30, path: '/' })
      location.href = '/dashboard/dashboardList'
    },
    layoutNav() {
      // 1441 1460
      this.showAvator = window.innerWidth <= 1300
      const navLen = document.querySelector('.nav-menu')
        ? document.querySelector('.nav-menu').childElementCount
        : 0
      if (window.innerWidth <= 1440 && navLen > 8) {
        this.navPadding = 'padding: 0 18px;'
      } else {
        this.navPadding = 'padding: 0 26px;'
      }
    },
    /**
     * @description: 头像 dropdown
     * @param {type}
     * @return {type}
     */
    handleMenuClick(item) {
      if (item.key == 'logout') {
        Cookie.remove('newOrderLang')
        Cookie.remove('lang')
        sessionStorage.clear()
        Cookie.set('lang', 'zh', { path: '', domain: document.domain })
        Cookie.set('lang', 'zh', { domain: '.i4px.com' })
        location.href = '/logout'
      } else {
        // this.$router.push({ name: item.key })
        if (item.key == 'userCenter') {
          this.onNavClick({
            name: item.key,
            active: false,
            pId: '6235'
          })
        }
        if (item.key == 'dssAccount') {
          this.onNavClick({
            name: item.key,
            active: false,
            pId: '6234'
          })
        }
        if (item.key == 'index_settings') {
          this.$router.push({ name: item.key })
        }
      }
    },
    /**
     * @description: 获取未读消息数
     * @param {type}
     * @return {type}
     */
    // getMsgCount() {
    //   this.$get('v2/messageCenter/managePage').then(res => {
    //     if (res) {
    //       this.count = res.totalUnreadCount || 0
    //     }
    //   })
    // },
    /**
     * @description: 获取登录用户名
     * @param {type}
     * @return {type}
     */
    getUser() {
      this.$get('v2/user/accountSecurity').then(res => {
        if (res) {
          this.username = res.userInfo?.username || ''
          this.$store.state.username = this.username
          // 埋点用户数据上报
          if (res && res.userInfo && res.userInfo.userId) {
            sensors.login(res.userInfo?.userId)
            sensors.setProfile({ email: res.userInfo?.email })
            sensors.setProfile({ user_name: res.userInfo?.username })
            sensors.setProfile({ customer_id: res.userInfo?.customCode })
          }
          // 注册公共属性
          sensors.registerPage({
            is_login: true,
            platform_type: 'Web'
          })
        } else {
          // 注册公共属性
          sensors.registerPage({
            is_login: false,
            platform_type: 'Web'
          })
        }
      })
    },
    /**
     * @description: 服务未开通，nav bar 可以操作开通服务
     * @param {type}
     * @return {type}
     */
    getBrandInfo() {
      const brand = sessionStorage.getItem('brandList')
      if (brand) {
        this.brandList = JSON.parse(brand)
      } else {
        this.$get('v2/brand/info')
          .then(res => {
            this.brandList = res || []
          })
          .catch(() => {})
      }
    },
    /**
     * @description: 服务未开通，会展示下拉框选
     * @param {type}
     * @return {type}
     */
    handleServiceMenuClick(e) {
      const code = e.item.value
      const brand = this.brandList.filter(x => x.brandCode == code)
      if (brand && brand.length) {
        this.currentBrand = brand[0]
      }
      // 开通服务
      if (e.key == 'open') {
        this.$refs['openWrapper'].onOpen()
      }
      // 服务介绍
      if (e.key == 'desc') {
        this.$refs['openWrapper'].visibleIntrduce = true
      }
    },
    async checkFB4WhiteList() {
      this.$show()
      let customCode = ''
      await this.$get('v2/user/accountSecurity').then(res => {
        customCode = res.userInfo.customCode
      })
      fb4Request
        .get('/v1/white', {
          params: {
            customerId: customCode
          }
        })
        .then(res => {
          if (res && res.data && res.result == 0) {
            this.isFB4White = res.data
          }
          this.$hide()
        })
        .catch(() => {
          this.$hide()
        })
    },
    /**
     * @description: 切换语言
     * @param {type}
     * @return {type}
     */
    handleLangMenuClick(item) {
      Cookie.remove('lang')
      Cookie.remove('lang', { path: '', domain: '.i4px.com' })

      this.$i18n.locale = item.key
      this.$store.state.locale = item.key
      sessionStorage.setItem('locale', item.key)

      Cookie.set('lang', item.key)
      Cookie.set('lang', item.key, { path: '', domain: document.domain })
      Cookie.set('lang', item.key, { domain: '.i4px.com' })
      this.setFB4Lang(item.key)
      window.location.reload()
      setTimeout(() => {
        this.$router.push('/refresh')
      }, 100)
    },
    /**
     * @description: 设置fb4语言
     * @param {key}
     */
    setFB4Lang(key) {
      let lang = 'zh-CN'
      let momentLang = 'zh_CN'
      switch (key) {
        case 'zh':
          lang = 'zh-CN'
          momentLang = 'en_GB'
          break
        case 'en':
          lang = 'en-US'
          momentLang = 'en_GB'
          break
        case 'ja':
          lang = 'ja-JP'
          momentLang = 'ja_JP'
          break
        case 'de':
          lang = 'de-DE'
          momentLang = 'de_DE'
          break
        default:
          lang = 'zh-CN'
          momentLang = 'zh_CN'
      }
      Cookie.set('newOrderLang', lang)

      moment.locale(momentLang)
    },
    /**
     * @description: 消息中心
     * @param {type}
     * @return {type}
     */
    goMesCenter() {
      this.$router.push('/msgList')
      // this.onNavClick({
      //   name: 'msgList',
      //   pId: '6237'
      // })
    },
    activeNav(item) {
      this.navItems.forEach(x => {
        x.active = false
        if (item.name == x.name) {
          x.active = true
        }
      })
      this.$store.commit('updateNavItems', this.navItems)
    },
    urlLink(name) {
      if (!name) return ''
      let url = name || ''
      let moudleUrl = navUrl.filter(nItem => nItem.name == name)
      url = moudleUrl && moudleUrl.length > 0 ? moudleUrl[0].url : ''
      if (this.AppENV != 'development') {
        url = '/dist' + url
      }
      return url
    },
    /**
     * @description: 导航
     * @param {type}
     * @return {type}
     */
    onNavClick(item) {
      var event = item.domEvent || window.event
      event.preventDefault()
      this.activeNav(item)
      // 对点击的是业务系统处理
      const businessNav = [
        'DSS',
        'FB4',
        'TRS',
        'FXGL',
        'oceanAir',
        'jiyun',
        'productService'
      ]
      if (businessNav.indexOf(item.name) > -1) {
        // item.sub 标识点击服务nav, 为开通服务时会有sub下拉菜单
        if (item.sub) {
          return
        }
        if (item.name == 'DSS') {
          this.$router.push('/personal_center')
        } else if (item.name == 'TRS') {
          this.$router.push('/trsFrame')
        } else if (item.name == 'FXGL') {
          this.$router.push('/serverIntor')
        } else if (item.name == 'FB4') {
          // if (this.isFB4White) {
          //   // FB4 新版
          //   this.$router.push('/orderFrame')
          // } else {
          //   // FB4跳出，重构后在考虑融入
          //   window.open(LINKS[item.name], '_blank')
          // }
          // FB4 新版 旧版已注销，避开/v1/white接口经常出问题导致不能进入的问题
          this.$router.push('/orderFrame')
          return
        } else if (item.name == 'oceanAir') {
          // oceanAir b2b 头程海运/空运
          this.$router.push('/oceanWaybill')
          return
        } else if (item.name == 'jiyun') {
          // jiyun
          this.$router.push('jiyun#/inbound/list')
          return
        } else if (item.name == 'productService') {
          this.$router.push('productService')
        }
        return
      }
      const mnu = JSON.parse(decodeURIComponent(sessionStorage.getItem('mnu')))
      if (mnu && item.pId) {
        const m = mnu[item.pId]
        // 如果点击的 nav 把权限不存在，则查找下一项作为route path
        if (m.indexOf(item.name) == -1) {
          this.$router.push({ name: m[0] })
          return
        }
      }
      // 服务数据中心录用切换
      if (
        item.name == 'storeSummary' &&
        !this.$store.getters.getFB4Open &&
        this.$store.getters.getDSSOpen
      ) {
        // storeSummary 数据中心
        this.$router.push('/orderAging')
        return
      }
      this.$router.push({ name: item.name })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-dropdown-menu-item {
  text-align: center;
}
.header-box {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  min-width: 1212px;
}
ul,
li {
  margin: 0;
}
.nav-bar {
  width: 100%;
  height: 56px;
  // background: linear-gradient(90deg, #228fff 0%, #0d57dc 100%);
  // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.03);
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(211, 215, 226, 0.6);
  user-select: none;
  .nav-logo {
    margin-right: 40px;
    display: inline-block;
    vertical-align: top;
    min-width: auto;
    height: 56px;
    .nav-logo-img {
      display: inline-block;
      vertical-align: top;
      margin-top: 18px;
      width: 56px;
      height: 18px;
      margin-left: 16px;
      margin-right: 5px;
      margin-top: 19px;
    }
    .nav-logo-line {
      display: inline-block;
      vertical-align: top;
      margin-top: 24px;
      width: 1px;
      height: 12px;
      background: #eef0f1;
      opacity: 0.5;
      margin-right: 5px;
    }
    .nav-logo-title {
      height: 20px;
      display: inline-block;
      vertical-align: top;
      margin-top: 18px;
      font-size: 20px;
      font-weight: 600;
      color: #323643;
      line-height: 20px;
    }
    .toggleCollapsed-icon {
      margin-left: 24px;
      width: 20px;
      height: 18px;
      font-size: 20px;
      line-height: 56px;
      color: #c3cbd5;
      cursor: pointer;
      .icon {
        font-size: 20px;
      }
    }
    .nav-logo-back {
      height: 22px;
      display: inline-block;
      vertical-align: top;
      margin-top: 17px;
      font-weight: 400;
      color: #767d91;
      font-size: 12px;
      border-radius: 2px;
      margin-left: 17px;
      padding: 2px 6px;
      cursor: pointer;
      line-height: 16px;
      transition: all 0.3s;
      border: 1px solid rgba(218, 223, 232, 1);
      &:hover {
        color: #767d91;
        border: 1px solid rgba(255, 255, 255, 0.568);
      }
    }
  }
  .nav-menu {
    display: inline-block;
    vertical-align: top;
    user-select: none;
    cursor: pointer;
    li {
      user-select: none;
      display: inline-block;
      vertical-align: middle;
      padding: 0px 16px !important;
      height: 56px;
      font-size: 14px;
      font-weight: 500;
      color: #364253;
      line-height: 56px;
      transition: background 0.3s;
      a {
        display: inline-block;
        color: #364253;
      }
      &:hover {
        a {
          color: #3561cc;
          background-image: url(../../assets/nav/hover-bg.png);
        }
        color: #3561cc;
        background-image: url(../../assets/nav/hover-bg.png);
      }
    }
    li.active {
      background-image: url(../../assets/nav/hover-bg.png);
      color: #3561cc;
      font-weight: 600;
      border-bottom: 3px solid #005adc;
      a {
        display: inline-block;
        color: #3561cc;
      }
    }
  }
  .ant-dropdown-link {
    height: 56px;
    line-height: 56px;
    padding: 0 0px;
  }
  .user-menu {
    margin-right: 16px;
  }
  .nav-right {
    position: fixed;
    right: 0;
    top: 24px;
    float: right;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 12px;
    height: 56px;
    user-select: none;
    .nav-lang {
      display: inline-block;
      margin-top: 17px;
      width: 22px;
      height: 22px;
      background: #edf2ff;
      border-radius: 4px;
      text-align: center;
      font-size: 12px;
      font-family: AlibabaPuHuiTiM;
      color: #005adc;
      line-height: 22px;
      margin-right: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.3s;
      &:hover {
        background: #3960df;
        color: #fff;
      }
    }
    .nav-lang-icon {
      margin-left: 5px;
      margin-right: 4px;
      font-size: 10px;
      transition: background 0.3s;
      &:hover {
        background: rgba(99, 155, 255, 0.64);
      }
    }
    .nav-notify {
      // background: #639bff;
      cursor: pointer;
      display: inline-block;
      width: 18px;
      height: 21px;
      border-radius: 16px;
      margin-right: 32px;
      text-align: center;
      transition: background 0.3s;
      .iconxiaoxi1 {
        margin-top: 2px;
        font-size: 18px;
        color: #767d91;
        display: block;
        &:hover {
          color: #3960df;
          // background: rgba(99, 155, 255, 0.64);
        }
        /deep/sup {
          position: absolute;
          top: -20px;
          right: -35px;
          width: 36px;
        }
      }

      img {
        width: 26px;
        height: 26px;
      }
    }
    .msg-num {
      position: absolute;
      right: -15px;
      top: -7px;
      color: #fff;
      background: #fe5e5e;
      box-shadow: 0px 1px 4px 0px rgba(254, 94, 94, 0.2);
      padding: 0 5px;
      height: 16px;
      line-height: 16px;
      border-radius: 8px;
      min-width: 24px;
      font-size: 10px;
    }
    .nav-user {
      display: inline-block;
      margin-top: 14px;
      margin-right: 8px;
      width: 28px;
      height: 28px;
      font-size: 28px;
      color: #323643;
      line-height: 32px;
      border-radius: 16px;
      cursor: pointer;
      &:hover {
        // background: rgba(99, 155, 255, 0.64);
      }
    }
    .user-name {
      display: inline-block;
      font-size: 12px;
      vertical-align: top;
      color: #323643;
      font-weight: 600;
      cursor: pointer;

      @media screen and (max-width: 1431px) {
        display: none;
      }
    }
    .user-name-down {
      margin-top: -15px;
      margin-left: 4px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.pagination {
  float: left;
}
/deep/.ant-modal-footer {
  position: absolute;
  bottom: 0;
  width: 600px !important;
}
/deep/.ant-modal-title {
  text-align: center !important;
}
/deep/.ant-pagination-prev {
  padding: 0 !important;
  min-width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}
/deep/.ant-pagination-simple-pager input {
  padding: 0 !important;
  margin-right: 0 !important;
  height: 31px !important;
  line-height: 31px !important;
  width: 31px !important;
}
/deep/.ant-pagination-next {
  padding: 0 !important;
  min-width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}
/deep/.ant-pagination-slash {
  margin: 0 5px 0 5px !important;
}
/deep/.ant-modal-content {
  width: 600px !important;
  min-height: 400px !important;
}

/deep/.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 18px;
  font-weight: bold;
}
.bohai-btn {
  margin-right: 28px;
  padding: 0;
  text-align: center;
  width: 22px;
  border-radius: 12px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  line-height: 20px;
  color: #3561cc;
  cursor: pointer;
  border: 1px solid rgba(53, 97, 204, 1);
}

// 小于1440的窗口.num-text 文字的大小设置成16px
@media screen and (max-width: 1440px) {
  .nav-bar .nav-menu li {
    padding: 0px 12px !important;
  }
  .nav-bar .nav-logo {
    margin-right: 16px;
  }
}
</style>
