<!--
 * @Description: 正常开通状态服务
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 10:17:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-22 21:59:01
-->
<i18n>
{
  "zh": {
    "last": "账户结余",
    "locked": "账户已锁定",
    "rout": "注销",
    "locktip": "尊敬的客户，您的账户已被锁定，如因欠费充值后仍未解锁以及非欠费原因被锁定，请联系销售人员进行解锁操作。",
    "lockmsg": "账户已被锁定",
    "order": "下单",
    "pay": "充值",
    "pack": "套餐",
    "sku": "SKU申报",
    "skuSuccess": "恭喜您SKU申报已成功",
    "skuSuccessAfter": "马上去FB4订单中心下单吧",
    "goOrder": "去下单",
    "notMainAccount": "子账号无查看权限",
    "fb4.declared.sku": "待SKU申报",
    "fb4.declared.sku.tips": "海外仓服务需进行SKU申报，审核完成后即可进行下单操作。申报后也可联系您的销售进行加急处理。",
    "fb4.reviewed.sku": "待SKU审核",
    "fb4.reviewed.sku.tips": "审核完成后即可进行下单操作，您可联系您的销售进行加急处理"
  },
  "en": {
    "last": "Current currency balance",
    "locked": "Account is locked",
    "rout": "Logout",
    "locktip": "Dear customer, your account has been locked. If it is still not unlocked after recharging due to arrears or is locked for reasons other than arrears, please contact the sales staff to unlock it.",
    "lockmsg": "Account has been locked",
    "order": "Order",
    "pay": "Recharge",
    "pack": "Package",
    "sku": "SKU declaration",
    "skuSuccess": "Congratulations on the success of your SKU declaration",
    "skuSuccessAfter": "Go to the FB4 Order Center and place an order now.",
    "goOrder": "Go place an order",
    "notMainAccount": "Sub-account without permission",
    "fb4.declared.sku": "To be declared by sku",
    "fb4.declared.sku.tips": "SKU declaration is required for overseas warehouse service, and the order can be placed after the audit. After declaration, you can also contact your sales department for urgent treatment.",
    "fb4.reviewed .sku": "To be reviewed by sku",
    "fb4.reviewed.sku.tips": "After the audit, you can place an order. You can contact your sales department for urgent processing  "
  },
  "de": {
    "last": "Kontostand",
    "locked": "Konto ist gesperrt",
    "rout": "Stornierung",
    "locktip": "Sehr geehrter Kunde, Ihr Konto wurde gesperrt. Wenn Sie nach der Aufladung Ihres Kontos nicht freigeschaltet wurden, weil Sie die Gebühren nicht bezahlt haben, oder wenn Sie wegen Nichtzahlung gesperrt wurden, wenden Sie sich bitte an Ihren Vertriebsmitarbeiter, damit Ihr Konto freigeschaltet wird.",
    "lockmsg": "Das Konto wurde gesperrt",
    "order": "Eine Bestellung aufgeben",
    "pay": "Aufladen",
    "pack": "Pakete",
    "sku": "SKU-Erklärung",
    "skuSuccess": "Herzlichen Glückwunsch zu Ihrer erfolgreichen SKU-Meldung!",
    "skuSuccessAfter": "Gehen Sie zum FB4-Bestellzentrum und geben Sie jetzt Ihre Bestellung auf!",
    "goOrder": "Zur Bestellung",
    "notMainAccount": "Kein Ansichtszugriff für Unterkonten",
    "fb4.declared.sku": "Ausstehende SKU-Erklärung",
    "fb4.declared.sku.tips": "Übersee Lagerservice muss SKU deklarieren, und die Bestellung kann nach Überprüfung aufgegeben werden. Nach der Deklaration können Sie sich auch an Ihren Vertrieb für dringende Bearbeitung wenden.",
    "fb4.reviewed.sku": "Zur Überprüfung durch sku",
    "fb4.reviewed.sku.tips": "Nachdem die Überprüfung abgeschlossen ist, können Sie eine Bestellung aufgeben und Ihre Vertriebsabteilung für dringende Bearbeitung kontaktieren."
  },
  "ja": {
    "last": "口座残高",
    "locked": "アカウントがロックされている",
    "rout": "キャンセルについて",
    "locktip": "お客様へ：お客様のアカウントがロックされました。 料金未払いによりアカウントをトップアップしてもロックが解除されない場合、または料金未払いによりロックされた場合は、アカウントのロック解除を行いますので、担当営業までご連絡ください。",
    "lockmsg": "アカウントがロックされました",
    "order": "注文する",
    "pay": "リチャージ",
    "pack": "パッケージ",
    "sku": "SKU宣言",
    "skuSuccess": "SKU宣言成功おめでとうございます。",
    "skuSuccessAfter": "FB4オーダーセンターで、今すぐご注文ください。",
    "goOrder": "ご注文はこちら",
    "notMainAccount": "サブアカウントでの閲覧不可",
    "fb4.declared.sku": "SKU申告待ち",
    "fb4.declared.sku.tips": "海外倉庫サービスはSKU申告を行う必要があり、審査が完了すれば注文操作を行うことができる。申告後も販売に連絡して至急処理することができます。",
    "fb4.reviewed.sku": "SKUレビュー待ち",
    "fb4.reviewed.sku.tips": "レビューが完了すると注文操作が可能になり、販売に至急処理を依頼することができます"
  }
}
</i18n>
<template>
  <div class="business-opened" :class="info ? 'brand-' + info.brandCode : ''">
    <div class="b-name">{{ $t(info.brandCode + '-full') }}</div>
    <div class="b-more" @click="onCheckDetail">
      {{ $t('more') }}
      <div class="arrow-right"></div>
    </div>

    <div class="b-money">{{ $t('last') }}</div>

    <template v-if="info.brandCode === 'DSS'">
      <div
        class="b-money-value"
        v-if="isMainAccount"
        :style="
          currentCurrency.accountStatus == 'ON'
            ? 'font-size:36px;'
            : 'font-size:28px;'
        "
      >
        {{
          currentCurrency.accountStatus == 'ON'
            ? currentCurrency.finalSurplusVal
            : currentCurrency.accountStatus == 'FZ'
            ? $t('locked')
            : currentCurrency.accountStatus == 'OF'
            ? $t('warehouse_E') 
            : $t('rout')
        }}
      </div>
      <div v-else class="b-money-value" style="font-size: 28px">
        {{ $t('notMainAccount') }}
      </div>
    </template>

    <div
      v-else
      class="b-money-value"
      :style="
        currentCurrency.accountStatus == 'ON'
          ? 'font-size:36px;'
          : 'font-size:28px;'
      "
    >
      {{
        currentCurrency.accountStatus == 'ON'
          ? currentCurrency.finalSurplusVal
          : currentCurrency.accountStatus == 'FZ'
          ? $t('locked')
          : currentCurrency.accountStatus == 'OF'
          ? $t('warehouse_E') 
          : $t('rout')
      }}
    </div>

    <!-- 如果是信用账户，则不展示币种选择 -->
    <template v-if="!info.accountInfoDTOS && currentCurrency">
      <a class="b-curr-currency">{{ currentCurrency.currencyCode }} </a>
    </template>
    <!-- 多币种切换查看 -->
    <a-popover placement="bottom" v-else>
      <div slot="content" @click="onChangeCurrency">
        <!-- 币种 -->
        <a
          class="currency-a"
          style="margin: 3px 10px"
          v-for="c in info.accountInfoDTOS"
          :key="c.currencyCode"
          >{{ c.currencyCode }}</a
        >
      </div>
      <a class="b-curr-currency"
        >{{ currentCurrency.currencyCode }}
        <div class="arrow-strong-down"></div
      ></a>
    </a-popover>

    <!-- 币种锁定提示 -->
    <div
      class="locked"
      v-if="
        (info.frozenCurrencySet && info.frozenCurrencySet.length) ||
          currentCurrency.accountStatus == 'FZ'
      "
    >
      <a-popover placement="bottom">
        <div slot="content" style="width: 240px">
          {{ $t('locktip') }}
        </div>
        <img src="../../../../assets/common/error@2x.png" alt="" />
      </a-popover>
      {{
        info.frozenCurrencySet
          ? info.frozenCurrencySet.toString().replace(/,/g, '、')
          : currentCurrency.currencyCode
      }}
      {{ $t('lockmsg') }}
    </div>
    <!-- 开了品牌，但是没有合同 需要补合同 -->
    <!-- contractGuide：合同状态，STANDARD：标准合同，NOT_STANDARD：非标合同，NO_CONTRACT：无合同，NORMAL：合同正常无需做特殊处理 -->
    <div
      class="locked"
      v-if="
        (info.contractGuide == 'STANDARD' ||
          info.contractGuide == 'NOT_STANDARD' ||
          info.contractGuide == 'NO_CONTRACT') &&
          info.customerStatus == 2 &&
          info.brandCode != 'GRS'
      "
    >
      <a>
        <span @click="renewalFun()">{{ $t('contract.signing') }}</span>
      </a>
    </div>
    <div class="b-btn" v-if="info.brandCode != 'GRS'">
      <a :data-baidu-pv="info.pv.order">
        <span v-if="skuTipShow" @click="goOrderSku">{{ $t('sku') }}</span>
        <span @click="goOrder" v-else>{{ $t('order') }}</span>
      </a>
      <a-divider v-if="info.rechargeEnabled" type="vertical" />
      <a
        v-if="info.rechargeEnabled"
        @click="goPay"
        :data-baidu-pv="info.pv.pay"
        >{{ $t('pay') }}</a
      >
      <!-- grs 有套餐选项 -->
      <template v-if="info.packageEnabled">
        <a-divider type="vertical" />
        <a @click="goPackage" :data-baidu-pv="info.pv.pack">{{ $t('pack') }}</a>
      </template>
    </div>
    <!-- 提示信息 -->
    <div class="tip flex-center" v-if="skuTipShow">
      <a-popover placement="bottom">
        <template slot="content">
          <div class="tip-content">{{ tip.content }}</div>
        </template>
        <a href="javascript:;" @click="checkFB4WhiteList()" class="tip-a">{{
          tip.tip
        }}</a
        ><a-icon
          type="exclamation-circle"
          theme="filled"
          :style="{ fontSize: '12px', color: '#ccc' }"
      /></a-popover>
    </div>

    <!-- 异常状态提示 accountStatus = OF  -->
    <div v-if="currentCurrency.accountStatus === 'OF'" class="red locked flex-center">
      {{$t('index_status_tips')}}
    </div>

    <!-- sku申报完成以后的弹窗 -->
    <a-modal
      v-if="fb4ModalVisible"
      @cancel="() => (fb4ModalVisible = false)"
      :visible="fb4ModalVisible"
      :centered="true"
      width="380px"
      height="218px"
    >
      <div class="flex-center-h choose-msg">
        <div><a-icon type="check-circle" theme="filled" class="ok" /></div>
        <div class="success-msg">{{ $t('skuSuccess') }}</div>
        <div class="sell-msg">
          {{ $t('skuSuccessAfter') }}
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="goOrder">
          {{ $t('goOrder') }}
        </a-button>
      </template>
    </a-modal>

    <!-- 新逻辑 重新申请合同 -->
    <ContractSigning
      :info="info"
      type="mainReapply"
      titleI18nName="renewTitle"
      :visible="renewalVisible"
      @cancel="renewalVisible = false"
      @ok="openSigning"
      v-if="renewalVisible"
    />
  </div>
</template>

<script>
import { fb4Request } from '@/api/fb4'
import { LINKS } from '@const/index'
import ContractSigning from '../newStatus/ContractSigning'

export default {
  name: 'business-opened',
  props: {
    info: Object
  },
  data() {
    return {
      isMainAccount: true,
      currentCurrency: {},
      skuTipShow: false,
      fb4ModalVisible: false,
      isFB4White: false,
      fb4TipMap: [
        {
          tip: this.$t('fb4.declared.sku'),
          content: this.$t('fb4.declared.sku.tips')
        },
        {
          tip: this.$t('fb4.reviewed.sku'),
          content: this.$t('fb4.reviewed.sku.tips')
        }
      ],
      tip: {},
      renewalVisible: false
    }
  },
  components: {
    ContractSigning
  },
  watch: {
    info: {
      handler: function() {
        this.currentCurrency = this.info.accountInfoDTO || {}
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // 处理fb4状态
    if (this.info.brandCode === 'FB4') {
      this.getSkuDetail()
    }

    this.$get('v2/common/isMainAccount').then(res => {
      this.$store.commit('setIsmainAccount', res ? 1 : 0)
      this.isMainAccount = res ? true : false
    })
  },
  methods: {
    /**
     * 获取fb4 sku申报状态
     */
    getSkuDetail() {
      this.$get('v2/brand/getSKUDetail').then(res => {
        const { isDeclared, isAudited } = res
        // 未申报
        if (isDeclared === 0 && isAudited === 0) {
          this.skuTipShow = true
          this.tip = this.fb4TipMap[0]
        }
        // 已申报 审核中
        if (isDeclared === 1 && isAudited === 0) {
          this.skuTipShow = true
          this.tip = this.fb4TipMap[1]
        }
        // 如果sku审核通过并且没有给过用户提示那么弹窗提示
        const hasShowSkuModal = window.localStorage.getItem('showSkuModal')
        if (isDeclared === 1 && isAudited === 2 && !hasShowSkuModal) {
          window.localStorage.setItem('showSkuModal', 1)
          this.fb4ModalVisible = true
        }
      })
    },
    /**
     * @description: 切换币种
     * @param {type}
     * @return {type}
     */
    onChangeCurrency(e) {
      const code = e.target.innerText
      // 账户被锁定状态
      if (this.info && this.info.accountInfoDTOS) {
        const c = this.info.accountInfoDTOS.filter(
          item => item.currencyCode == code
        )
        if (c && c.length) {
          this.currentCurrency = c[0]
        }
      }
    },
    /**
     * @description: 跳转至 我的账户，查看账户详情
     * @param {type}
     * @return {type}
     */
    onCheckDetail() {
      this.$router.push({
        name: 'dssAccount'
      })
    },
    /**
     * @description: 跳转至订购套餐页
     * @param {type}
     * @return {type}
     */
    goPackage() {
      // 获取套餐详情
      this.$show()
      this.$get('v2/account/package/detail?brand=GRS')
        .then(res => {
          this.$hide()
          if (res) {
            // 当前有套餐（已订购，扣费中，已生效，扣费失败） 需要跳转到退件账户页面
            if ([10, 11, 20, 12].indexOf(res.status) > -1) {
              this.$router.push({ name: 'grsAccount' })
            } else {
              this.$router.push({ name: 'grsServices' })
            }
          } else {
            this.$router.push({ name: 'grsServices' })
          }
        })
        .catch(() => {
          this.$hide()
          this.$router.push({ name: 'grsServices' })
        })
    },
    /**
     * @description: 跳转至充值页
     * @param {type}
     * @return {type}
     */
    goPay() {
      this.$router.push({
        name: 'rechargeOnline',
        query: {
          currencyCode: this.currentCurrency.currencyCode,
          businessSystem: this.info.brandCode
        }
      })
    },
    // 判断海外仓白名单
    async checkFB4WhiteList() {
      this.$show()
      let customCode = ''
      await this.$get('v2/user/accountSecurity').then(res => {
        customCode = res.userInfo.customCode
      })

      await fb4Request
        .get('/v1/white', {
          params: {
            customerId: customCode
          }
        })
        .then(res => {
          if (res && res.result == 0) {
            if (res.data) {
              // FB4 新版海外仓 sku管理地址
              this.$router.push('/orderFrame#/sku/skulist')
            } else {
              window.open(LINKS['FB4'], '_blank')
            }
          } else {
            window.open(LINKS['FB4'], '_blank')
          }
          this.$hide()
        })
        .catch(() => {
          window.open(LINKS['FB4'], '_blank')
          this.$hide()
        })
    },
    /**
     * 跳转到fb4 sku申报
     */
    goOrderSku() {
      this.checkFB4WhiteList()
    },
    /**
     * @description: 打开对应业务系统
     * @param {type}
     * @return {type}
     */
    goOrder() {
      const code = this.info.brandCode
      if (code === 'FB4') {
        window.open(LINKS[code], '_blank')
      } else if (['GRS'].indexOf(code) > -1) {
        window.open('http://grs.4px.com/goms', '_blank')
      } else if (['TRS'].indexOf(code) > -1) {
        location.href = '/dist/trsFrame#/uploadbatch'
      } else {
        this.$router.push({
          name: 'to_create'
        })
      }
    },
    // 重新申请合同
    renewalFun() {
      if (
        this.info &&
        (this.info?.contractGuide == 'NOT_STANDARD' ||
          this.info?.brandCode == 'TRS')
      ) {
        this.$confirm({
          content:
            this.info?.brandCode == 'TRS'
              ? this.$t('ct_ap_005')
              : this.$t('ct_ap_002'),
          okText: this.$t('iKnow'),
          cancelText: this.$t('close'),
          onOk() {}
        })
      } else {
        this.renewalVisible = true
      }
    },
    openSigning() {
      this.$root.bus.$emit('reload-brand')
      this.renewalVisible = false
      this.$store.state.contractTipsModal = true
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../../styles/theme.less');
.choose-msg {
  .ok {
    font-size: 40px;
    color: #18bd92;
  }
  .success-msg {
    font-size: 16px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .sell-msg {
    font-size: 12px;
    color: #999;
  }
}
/deep/ .ant-modal-footer {
  padding-bottom: 20px;
}
.select-btn {
  margin-bottom: 20px;
}
.tip {
  margin-top: 8px;
  .tip-a {
    color: #333;
    font-size: 12px;
    padding-right: 4px;
    &:hover {
      color: #065cf3;
    }
  }
}
.tip-content {
  width: 212px;
  color: #666;
  font-size: 12px;
}
.business-opened {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 290px;
  border-radius: 4px;
  background-size: 100% 100%;
  user-select: none;
  .b-name {
    display: inline-block;
    padding: 17px 0 0 17px;
    font-weight: 400;
    color: #333333;
  }
  .b-money {
    font-weight: 400;
    color: #333333;
    font-size: 14px;
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
  .b-money-value {
    font-family: AlibabaPuHuiTiM;
    color: #000000;
    line-height: 50px;
    margin-top: 10px;
    text-align: center;
    max-width: 345px;
    word-break: break-all;
  }
  .b-more {
    cursor: pointer;
    font-size: 12px;
    color: #999;
    float: right;
    margin-right: 17px;
    margin-top: 17px;
  }
  .b-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    a {
      line-height: 1;
      width: 100%;
      text-align: center;
      color: #333333;
      transition: color 0.3s;
      &:hover {
        color: @prmaryColor;
      }
    }
  }
  .b-curr-currency {
    display: inline-block;
    width: 100%;
    text-align: center;
    height: 22px;
    font-size: 16px;
    font-family: AlibabaPuHuiTiR;
    color: #999999;
    line-height: 22px;
    margin-top: 4px;
  }
  .currency-a {
    display: inline-block;
    margin: 15px 20px 9px 15px;
  }
  .locked {
    margin-top: 10px;
    text-align: center;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    font-size: 12px;
    img {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
  .red{
  color: #FF3434!important;
  }
  .ant-divider,
  .ant-divider-vertical {
    height: 23px;
  }
}
</style>
