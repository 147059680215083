<template>
  <div class="country" @click="countryChose">
    <a-spin :spinning="spining">
      <a-tabs :defaultActiveKey="0">
        <template v-for="(t, idx) in list">
          <a-tab-pane
            :tab="idx == 0 && $i18n.locale == 'en' ? 'hot country' : t.name"
            :key="idx"
            v-if="hideHotGroup && t.name == '热门国家' ? false : true"
          >
            <template v-for="(c, index) in t.country">
              <div :key="index" class="c-l">{{ c.name }}</div>
              <div :key="index + 'r'" class="c-r">
                <p
                  :key="item.code"
                  v-for="item in c.countrys"
                  :class="item.isSelected == 1 ? 'ds' : ''"
                  :title="$i18n.locale == 'zh' ? item.display : item.ename"
                  :data-val="
                    JSON.stringify({
                      code: item.code,
                      name: $i18n.locale == 'zh' ? item.display : item.ename
                    })
                  "
                >
                  {{ $i18n.locale == 'zh' ? item.display : item.ename }}
                </p>
              </div>
            </template>
          </a-tab-pane>
        </template>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'country',
  props: {
    query: {
      type: Object
    },
    hideHotGroup: {
      type: false
    }
  },
  data() {
    return {
      list: [],
      spining: true
    }
  },
  watch: {
    query: {
      handler: 'getCountryList',
      immediate: true,
      deep: true
    }
  },
  methods: {
    /**
     * 获取当前选中的国家
     * @param event
     * @returns
     */
    countryChose(event) {
      if (event.target.nodeName.toUpperCase() == 'P') {
        this.$emit('getterCountry', event.target.dataset.val)
      }
    },
    async getCountryList() {
      this.spining = true
      const dt = await this.http_getCountryList(this.query)
      if (!sessionStorage.getItem('c_y'))
        sessionStorage.setItem('c_y', JSON.stringify(dt))
      this.list = Object.freeze(dt)
      this.spining = false
    },
    /**
     * 目的地国家列表
     * @param {*} param
     */
    async http_getCountryList(param) {
      const contentTypeForm = 'application/x-www-form-urlencoded;charset=UTF-8'
      const defaultHeader = { headers: { 'Content-Type': contentTypeForm } }
      return this.$post(`landmark/get_for_select`, param, defaultHeader)
        .then(res => {
          return res || []
        })
        .catch(err => {
          return Promise.reject(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.country {
  .ant-tabs-tabpane {
    max-height: 30vh;
    min-height: 120px;
    overflow-y: auto;
  }
  .c-l {
    width: 50px;
    color: red;
    float: left;
  }
  .c-r {
    float: left;
    width: 90%;
    p {
      display: inline-block;
      width: 130px;
      padding: 6px;
      margin-right: 2px;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 4px;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #005adc;
        color: #fff;
      }
    }
    .ds {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
    }
  }
}
</style>
