/*
 * @Description: help
 * @Autor: S9637/chifuk
 * @Date: 2020-09-25 21:19:21
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-09-29 20:00:36
 */
import moment from 'moment'
import { httpExportToFile } from '@/api/index'

/**
 * @description: 时间区间内，由于库内没法产生数据，此时折线图会有问题，次方法用于生成时间段补0数据
 * @param {type}
 * @return {type}
 */
const createDataByZero = (list, filedname) => {
  const dates = list.map(item => moment(item[filedname]))
  const startGroup = []
  const endGroup = []
  dates.forEach((item, index) => {
    if (index % 2 == 0) {
      startGroup.push(item)
    } else {
      endGroup.push(item)
    }
  })

  console.log(startGroup, endGroup)
  const addDates = []
  startGroup.forEach((s, index) => {
    let range = 0
    if (startGroup.length > endGroup.length) {
      let base
      if (index == startGroup.length - 1) {
        // range = s - endGroup[index]
        range = s.diff(endGroup[index - 1], 'days') - 1
        base = endGroup[index - 1]
      } else {
        range = endGroup[index].diff(s, 'days') - 1
        base = s
      }
      // console.log(range, base)
      if (range > 0) {
        for (let x = 0; x < range; x++) {
          addDates.push(moment(base).add(x + 1, 'days'))
        }
      }
    } else {
      // range = end - s
      range = endGroup[index].diff(s, 'days') - 1
      for (let x = 0; x < range; x++) {
        addDates.push(moment(s).add(x + 1, 'days'))
      }
    }
  })

  const allDates = [...startGroup, ...endGroup, ...addDates]
  // 按日期排序
  allDates.sort((a, b) => a - b)
  const resultDates = allDates.map(item => {
    return item.format('YYYY-MM-DD')
  })
  return resultDates
}

const exportDetailData = (url, params, filedname, stuiff = 'csv') => {
  return new Promise((resolve, reject) => {
    httpExportToFile(url, params, filedname, stuiff)
      .then(() => {
        resolve()
      })
      .catch(() => {
        reject()
      })
  })
}

export { createDataByZero, exportDetailData }
