<template>
  <div class="mask" @click="$emit('close')">
    <div class="modal-content-bg" @click.stop="">
      <div class="loan-content">
        <div class="tit-box">
          <div class="tit">渤海银行运费贷</div>
          <div class="des">纯信用 付运费 年化利率低至6%</div>
        </div>
        <img class="flow-img" src="./flow.jpg" alt="" />
        <!--  -->
        <div class="qr-box" v-if="signStatus == 'Y' && qrUrl.length == 1">
          <div class="qr-item" v-if="qrUrl.length > 0">
            <vue-qr
              :text="qrUrl[0]"
              :margin="0"
              :logoSrc="bohaiLogo"
              :size="120"
            ></vue-qr>
            <div class="des">微信扫码 开始申请</div>
          </div>
        </div>
        <div class="qr-box" v-if="signStatus == 'Y' && qrUrl.length > 1">
          <div class="qr-item">
            <vue-qr
              :text="qrUrl[1]"
              :margin="0"
              :logoSrc="bohaiLogo"
              :size="120"
            ></vue-qr>
            <div class="des">再次申请</div>
          </div>
          <div
            class="qr-item"
            style="margin-left: 30px"
            v-if="qrUrl.length > 1"
          >
            <vue-qr
              :text="qrUrl[0]"
              :margin="0"
              :logoSrc="bohaiLogo"
              :size="120"
            ></vue-qr>
            <div class="des">查看申请记录</div>
          </div>
        </div>
        <a-icon
          v-if="signStatus == null"
          :spin="true"
          style="
            padding: 30px 0;
            display: block;
            font-size: 60px;
            color: #065cf3;
          "
          type="sync"
        />
        <a-button
          v-if="signStatus == 'N'"
          class="btn"
          @click.stop="approvalVisible = true"
          type="primary"
          >立即申请</a-button
        >
        <div class="tips">
          产品以最终审批为准，年利率以单利计算
          <a
            href="https://fpx-bss-oss.oss-cn-shenzhen.aliyuncs.com/fpx_crm/2bfa73e1-7d33-410f-aae5-578318a9ad77.pdf"
            target="__blank"
            class="opt-guide"
            >操作指引</a
          >
        </div>
      </div>
    </div>
    <AuthorizationPopup
      v-if="approvalVisible"
      :visible="approvalVisible"
      @cancel="approvalVisible = false"
      @ok="getCustomerStatus"
    />
    <!--  -->
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import AuthorizationPopup from './AuthorizationPopup'

export default {
  name: 'LoanPopup',
  data() {
    return {
      approvalVisible: false,
      signStatus: null,
      approvalStatus: null,
      bohaiLogo: '',
      qrUrl: [],
    }
  },
  components: { VueQr, AuthorizationPopup },
  created() {
    this.getCustomerStatus()
  },
  mounted() {},

  methods: {
    getCustomerStatus() {
      this.$post('hbLoan/queryBhCustomerStatus')
        .then((res) => {
          console.log('res:', res)
          // 是否签署协议 signStatus
          // 申请过贷款 approvalStatus
          this.approvalStatus = res.approvalStatus
          this.signStatus = res.signStatus
          if (res.signStatus == 'Y') {
            this.getQR()
          }
        })
        .catch(() => {
          this.$hide()
        })
    },
    getQR() {
      this.approvalVisible = false
      this.$get('hbLoan/queryCussomerLoanEntryUrl/' + this.approvalStatus)
        .then((res) => {
          this.qrUrl = res || []
        })
        .catch(() => {
          this.$hide()
        })
    },
  },
}
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  background: rgba(0, 0, 0, 0.3);
}
.modal-content-bg {
  position: absolute;
  margin: 0;
  right: 100px;
  width: 326px;
  min-height: 316px;
  z-index: 10000;
}

.flow-img {
  width: 278px;
  height: auto;
}
.loan-content {
  width: 326px;
  min-height: 316px;
  background: #fff;
  text-align: center;
  .tit-box {
    padding: 24px 24px 22px;
    width: 100%;
    min-height: 104px;
    background: rgba(57, 96, 223, 0.08);
    text-align: left;
    .tit {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      color: #3561cc;
    }
    .des {
      margin-top: 10px;
      font-size: 16px;
      line-height: 24px;
      color: #3561cc;
    }
  }
  .btn {
    margin: 12px auto 0;
    width: 278px;
    height: 40px;
    line-height: 40px;
  }
  .tips {
    padding: 16px 0 20px;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #545d75;
    .iconizhushi {
      margin-right: 6px;
      width: 12px;
      font-size: 12px;
      color: #dadfe8;
    }
  }
}
.qr-box {
  margin: 16px 24px 0;
  display: flex;
  justify-content: center;
  min-height: 144px;
  img {
    width: 120px;
    height: 120px;
  }
  text-align: center;
  .des {
    margin: 10px 0 0;
    line-height: 14px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #545d75;
  }
}
.opt-guide {
  margin-left: 8px;
  color: #3561cc;
}
</style>
