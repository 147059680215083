<i18n>
{
  "zh": {
    "signingTitle": "合同签署",
    "reapplyTitle": "是否起重新申请？",
    "renewTitle": "是否发起续签？",
    "signingTips": "签署服务合同后将为您开通服务品牌，以下信息将会在合同及发票信息中展示，请您核对信息准确性。",
    "signingTips2": "续签//重新申请默认按照原合同内容拟定新合同，以下客户信息将会在合同里展示，请检查是否正确，若有误请及时进行修改",
    "customName":"客户名称：",
    "legalPerson":"法人：",
    "address":"通讯地址：",
    "contact":"联系人信息： ",
    "phone":"联系电话：",
    "email":"联系邮箱：",
    "cancel":"取消",
    "generation":"确认生成合同",
    "SupplementaryAddress":"请补充地址"
  },
  "en": {
    "signingTitle": "Contract signing",
    "reapplyTitle": "Do you want to re apply?",
    "renewTitle": "Initiate renewal?",
    "signingTips": "After signing the service contract, the service brand will be opened for you. The following information will be displayed in the contract and invoice information. Please check the accuracy of the information.",
    "signingTips2": "By default, a new contract is drawn up according to the contents of the original contract. The following customer information will be displayed in the contract. Please check whether it is correct. If there is any error, please modify it in time",
    "customName":"Customer Name:",
    "legalPerson":"Legal person:",
    "address":"postal address:",
    "contact":"Contact information: ",
    "phone":"contact number:",
    "email":"Contact email:",
    "cancel":"cancel",
    "generation":"Confirm to generate contract"
  },
  "de": {
    "signingTitle": "Vertrag unterzeichnet",
    "reapplyTitle": "Wird ein neuer Antrag gestellt?",
    "renewTitle": "Wird eine Erneuerung eingeleitet?",
    "signingTips": "Die folgenden Informationen werden in den Vertrags- und Rechnungsinformationen angezeigt, bitte überprüfen Sie die Richtigkeit der Angaben.",
    "signingTips2": "Standardmäßig wird für die Verlängerung/den Neuantrag ein neuer Vertrag auf der Grundlage des ursprünglichen Vertrags erstellt. Die folgenden Kundeninformationen werden im Vertrag angezeigt, bitte überprüfen Sie, ob sie korrekt sind, und korrigieren Sie sie im Falle von Fehlern umgehend",
    "customName": "Name des Kunden.",
    "legalPerson": "Juristische Person.",
    "address": "Korrespondenzadresse.",
    "contact": "Kontaktinformationen.",
    "phone": "Telefonnummer der Kontaktperson.",
    "email": "E-Mail-Kontakt.",
    "cancel": "Stornierung",
    "generation": "Bestätigung der Vertragserstellung",
    "SupplementaryAddress": "Bitte Adresse hinzufügen"
  },
  "ja": {
    "signingTitle": "契約締結",
    "reapplyTitle": "新しいアプリケーションが開始されましたか？",
    "renewTitle": "更新を開始しますか？",
    "signingTips": "契約書、請求書情報には、以下の情報が表示されますので、情報の正確性をご確認ください。",
    "signingTips2": "更新・再申し込みの際には、デフォルトで元の契約書に基づき新しい契約書が作成されます。 契約書には以下のお客様情報が表示されますので、内容が正しいかどうか確認し、誤りがあれば速やかに訂正してください。",
    "customName": "クライアント名",
    "legalPerson": "法務担当者",
    "address": "対応するアドレス",
    "contact": "連絡先",
    "phone": "連絡先電話番号",
    "email": "連絡先Eメール",
    "cancel": "キャンセルについて",
    "generation": "契約書生成の確認",
    "SupplementaryAddress": "アドレスを追加してください"
  }
}
</i18n>

<template>
  <div class="signing-box">
    <a-modal
      width="440px"
      :visible="visible"
      :maskClosable="false"
      :bodyStyle="{ 'padding-top': 0 }"
      @cancel="$emit('cancel')"
      :title="mdTitle"
      @ok="handleOk"
    >
      <div class="tips">{{ $t('signingTips') }}</div>
      <div class="detail-item">
        <div class="laber">{{ $t('customName') }}</div>
        <div class="text">{{ detail.name | nval }}</div>
      </div>
      <div class="detail-item" v-if="isCompany">
        <div class="laber">
          {{ $t('legalPerson') }}
        </div>
        <div class="text">{{ detail.legalName | nval }}</div>
      </div>
      <div class="detail-item">
        <div class="laber">{{ $t('address') }}<span class="red">*</span></div>
        <div class="text" v-if="address">
          {{ address.country }}{{ address.state }}{{ address.city
          }}{{ address.county }} {{ address.address }}

          <i @click="editAddressFun" class="eidt-icon iconfont iconbianji"></i>
        </div>
        <div class="text" v-else>
          {{ $t('SupplementaryAddress') }}
          <i @click="editAddressFun" class="eidt-icon iconfont iconbianji"></i>
        </div>
      </div>
      <div class="detail-item">
        <div class="laber">{{ $t('contact') }}<span class="red">*</span></div>
        <div class="text">
          <a-select
            v-model="selContactsList"
            style="width: 350px"
            @change="handleChange"
          >
            <a-select-option
              v-for="item in contactsList"
              :value="item.id"
              :placeholder="$t('index_tran_006')"
              :key="item.id"
            >
              {{ item.name ? item.name : item.mobile }}
            </a-select-option>
          </a-select>
          <i
            @click="showCreateContactsModal = true"
            class="eidt-icon iconfont iconbianji"
          ></i>
        </div>
      </div>

      <a-row>
        <a-col :span="12">
          <div class="detail-item">
            <div class="laber">{{ $t('phone') }}<span class="red">*</span></div>
            <div class="text">
              {{ sleContactsItem.mobile
              }}<span
                class="red"
                v-if="
                  sleContactsItem.mobile == '' || sleContactsItem.mobile == null
                "
                >{{ $t('index_tran_007') }}</span
              >
            </div>
          </div></a-col
        >
        <a-col :span="12">
          <div class="detail-item">
            <div class="laber">{{ $t('email') }}<span class="red">*</span></div>
            <div class="text">
              {{ sleContactsItem.email }}
              <span
                class="red"
                v-if="
                  sleContactsItem.email == '' || sleContactsItem.email == null
                "
                >{{ $t('index_tran_008') }}</span
              >
            </div>
          </div>
        </a-col>
      </a-row>
    </a-modal>
    <EditAddress
      :defAddress="address"
      :editAddress="address && address.countryCode ? true : false"
      @cancel="showAddressModal = false"
      @ok="hideModal"
      v-if="showAddressModal"
    />
    <CreateContacts
      @cancel="showCreateContactsModal = false"
      :sleContactsItem="sleContactsItem"
      @ok="hideModal"
      v-if="showCreateContactsModal"
    />
  </div>
</template>
<script>
import EditAddress from './EditAddress'
import CreateContacts from './CreateContacts'

export default {
  name: 'ContractSigning',
  props: {
    visible: {},
    titleI18nName: {},
    info: {},
    type: {}
  },
  data() {
    return {
      isCompany: false,
      detail: {
        name: '',
        enitity: ''
      },
      address: null,
      contactsList: [],
      selContactsList: null,
      sleContactsItem: {
        id: null,
        email: '',
        mobile: ''
      },
      showAddressModal: false,
      showCreateContactsModal: false
    }
  },
  computed: {
    mdTitle() {
      let title =
        this.type == 'reapply' || this.type == 'mainReapply'
          ? this.$t('reapplyTitle')
          : this.type == 'renew'
          ? this.$t('renewTitle')
          : this.$t('signingTitle')
      if (this.titleI18nName) {
        title = this.$t(this.titleI18nName)
      }
      return title
    }
  },
  components: {
    EditAddress,
    CreateContacts
  },
  filters: {
    nval(val) {
      return val ? val : '-'
    }
  },
  watch: {
    selContactsList: function(val) {
      var that = this
      this.contactsList.forEach(item => {
        if (item.id == val) {
          that.sleContactsItem = item
        }
      })
    }
  },
  created() {
    this.initData()
  },
  methods: {
    editAddressFun() {
      this.showAddressModal = true
    },
    hideModal() {
      this.showAddressModal = false
      this.showCreateContactsModal = false
      this.initData()
    },
    initData() {
      let that = this
      this.$show()
      // 获取用户名字和企业名字
      this.$get('v2/userAuth/getAuthTypeAndMerchantInfo').then(res => {
        if (res) {
          // 企业
          if (res.hasOwnProperty('newUserInfo')) {
            // this.detail = res.newUserInfo.organInfo
            if (
              res.newUserInfo.hasOwnProperty('organInfo') &&
              res.newUserInfo.organInfo != '' &&
              res.newUserInfo.organInfo != null
            ) {
              that.isCompany = true
              this.detail = res.newUserInfo.organInfo
            } else {
              that.isCompany = false
              this.detail = res.newUserInfo.indivInfo
            }
          } else {
            that.detail = res.manualUserInfo
          }
        }
        this.$hide()
      })
      // 获取用户地址
      this.$get('v2/user/accountSecurity').then(res => {
        if (res) {
          that.address = res.address
        }
        this.$hide()
      })
      // 联系人
      that.selContactsList = null
      this.$get('v2/bussinessInfo/page').then(res => {
        if (res) {
          let contactsList = []
          res.forEach(item => {
            if (item.name) {
              contactsList.push(item)
            }
          })
          that.contactsList = contactsList
          if (contactsList.length > 0) {
            that.selContactsList = contactsList[0].id
            that.sleContactsItem = contactsList[0]
          }
        }
        this.$hide()
      })
    },
    handleChange(e) {
      console.log('change', e)
    },
    handleOk() {
      this.appleSiging()
    },
    appleSiging() {
      var that = this
      this.$show()
      // ||
      //   this.address.address == '' ||
      //   this.address.address == '' ||
      //   this.address.countyCode == ''
      if (this.address == null) {
        this.$hide()
        this.$message.warning('请完善地址信息')
        return false
      }
      if (
        this.address &&
        this.address.countryCode == 485 &&
        (this.address.countyCode == '' || this.address.countyCode == null)
      ) {
        this.$hide()
        this.$message.warning('请选择完整地址：国家/省/市/区')
        return false
      }
      if (this.sleContactsItem == null || this.sleContactsItem.name == '') {
        this.$hide()
        this.$message.warning('请完善联系人信息')
        return false
      }
      if (this.sleContactsItem.mobile == '') {
        this.$hide()
        this.$message.warning('请完善联系人电话信息')
        return false
      }
      if (this.sleContactsItem.email == '') {
        this.$hide()
        this.$message.warning('请完善联系人邮箱信息')
        return false
      }
      let params = {
        serviceName: this.info.brandName,
        serviceCode: this.info.brandCode,
        customerName: this.detail.name,
        customerType: this.isCompany ? 1 : 0,
        cLegalName: this.detail.legalName,
        ...this.address,
        ...this.sleContactsItem,
        contactName: this.sleContactsItem.name
      }
      let url = 'v2/brand/openBrandAndApplyContract'
      if (this.type) {
        url = 'v2/contract/applyContract'
        // null   reapply
        params.operationType = this.type == 'mainReapply' ? null : this.type
        params.code = this.info.code
        params.serviceCode =
          this.type == 'mainReapply'
            ? this.info.brandCode
            : this.info.serviceCode
        params.serviceName =
          this.type == 'mainReapply'
            ? this.info.brandName
            : this.info.serviceName
      }
      this.$post(url, params)
        .then(res => {
          that.$hide()
          that.$emit('ok', {})
          that.$message.success(res.message || '操作成功！')
        })
        .catch(res => {
          this.applyFail(res)
          this.$hide()
        })
    },
    // 签署失败没有认证的客户跳转实名认证  code == '40099'
    applyFail(res) {
      let that = this
      if (res && res.code == '40099') {
        this.$emit('cancel', {})
        this.$confirm({
          content: this.$t('ct_ap_001'),
          okText: this.$t('ct_ap_004'),
          cancelText: this.$t('cancel'),
          onOk() {
            that.$router.push({
              path: '/openProgress?brandCode=' + that.info?.brandCode
            })
          }
        })
      } else if (res && res.code == '40009') {
        this.$emit('cancel', {})
        this.$confirm({
          content: res.message,
          okText: this.$t('op_brand_026'),
          cancelText: this.$t('cancel'),
          onOk() {
            that.$router.push({
              path: '/contractInfo'
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.signing-box .ant-modal-body {
  margin-top: 0;
}
.tips {
  padding: 0;
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 21px;
  color: #ff6400;
}
.detail-item {
  margin: 0 0 20px;
  width: 100%;
  min-height: 48px;
  font-size: 14px;
  .laber {
    font-family: PingFangSC-Regular;
    color: #545d75;
    line-height: 24px;
    opacity: 0.8;
  }
  .text {
    margin-top: 3px;
    line-height: 24px;
    font-family: PingFangSC-Regular;
    color: #323643;
    flex-wrap: 500;
  }
  .eidt-icon {
    margin-left: 15px;
    font-size: 20px;
    line-height: 24px;
    color: #c3cbd5;
    cursor: pointer;
  }
}
.red {
  color: #ff0e0e;
}
</style>
