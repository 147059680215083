<!--
 * @Description: 获取FB4子账号助记码
 * @Autor: S9637/chifuk
 * @Date: 2020-09-17 15:16:01
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-13 11:35:36
-->
<template>
  <a-select
    show-search
    option-filter-prop="children"
    :filter-option="filterOption"
    v-model="val"
    @change="handleChange"
    allowClear
  >
    <a-select-option :key="item" :value="item" v-for="item in list">
      {{ item }}
    </a-select-option>
    <em slot="suffixIcon" class="iconfont iconxiala" style="font-size: 10px;" />
  </a-select>
</template>
<script>
export default {
  name: 'customer-code',
  props: {
    value: String
  },
  data() {
    return {
      list: [],
      val: ''
    }
  },
  watch: {
    value(val) {
      this.val = val
    }
  },
  created() {
    this.getFB4Codes()
  },
  methods: {
    getFB4Codes() {
      this.$get('v2/common/getSubFB4MarkCode').then(res => {
        this.list = res || []
      })
    },
    handleChange(value) {
      console.log(value)
      this.$emit('customercode', value)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
