/*
 * @Description: 系统公告
 * @Autor: S9637/chifuk
 * @Date: 2020-09-01 10:52:00
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-09-18 17:21:31
 */
export default [
  {
    path: '/announcement',
    name: 'announcementALL',
    component: () =>
      import(
        /* webpackChunkName: "Announcement" */ '@v/index/announcement/DetailList'
      )
  },
  {
    path: '/announcement',
    name: 'announcementFB4',
    component: () =>
      import(
        /* webpackChunkName: "Announcement" */ '@v/index/announcement/DetailList'
      )
  },
  {
    path: '/announcement',
    name: 'announcementGRS',
    component: () =>
      import(
        /* webpackChunkName: "Announcement" */ '@v/index/announcement/DetailList'
      )
  },
  {
    path: '/announcement',
    name: 'announcementDSS',
    component: () =>
      import(
        /* webpackChunkName: "Announcement" */ '@v/index/announcement/DetailList'
      )
  },
  {
    path: '/announcement',
    name: 'announcementTRS',
    component: () =>
      import(
        /* webpackChunkName: "Announcement" */ '@v/index/announcement/DetailList'
      )
  },
  {
    path: '/announcementDetail',
    name: 'announcementDetail',
    component: () =>
      import(
        /* webpackChunkName: "announcementDetail" */ '@v/index/announcement/DetailMsg'
      )
  }
]
