<!--
 * @Description: 审核中状态
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 10:17:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-22 21:59:12
-->
<i18n>
{
  "zh": {
    "audit": "审核中...",
    "note": "需要1-2个工作日请耐心等待",
    "cus": "联系客服",
    "check": "请勾选已阅读",
    "startTry": "开始试单",
    "application": "申请正式账号",
    "welcom": "欢迎试单用户",
    "agree": "同意",
    "downfile": "全文下载",
    "dssDesc": "欢迎试用全球直运服务，试单单量上限为 {triedNumTotal} 单，如对我们的全球直运服务满意，请联系销售签署合同开通正式账号。试单客户除了全球直运服务的试单单量上限限制，其他体验与正式客户无异。点击【开始试单】开启体验之旅吧。",
    "dssDescnoLimit": "欢迎试用全球直运服务，请联系销售签署合同开通正式账号。点击【开始试单】开启体验之旅吧。",
    "toValidate": "邀请您实名认证",
    "validateDesc": "检测到您还未实名认证。申请正式用户前，需要补全实名认证材料。",
    "validate": "去认证",
    "article": "诚信承诺函",
    "read": "我已阅读《诚信承诺函》",
    "last": "当前币种余额",
    "locked": "账户已锁定",
    "rout": "注销",
    "locktip": "尊敬的客户，您的账户已被锁定，如因欠费充值后仍未解锁以及非欠费原因被锁定，请联系销售人员进行解锁操作。",
    "lockmsg": "账户已被锁定",
    "zsAccount": "申请正式账号已提交成功",
    "zsDesc": "等待销售顾问联系您签署合同，完成即转正成功",
    "contact": "您的直运合同签署成功",
    "contactDesc": "现已成功转为正式账号，点击「下单」可以马上体验了",
    "appTry": "点此申请",
    "appStart": "点此开始试单"
  },
  "en": {
    "audit": "Auditing...",
    "note": "It takes 1-2 working days, please be patient",
    "cus": "customer service",
    "check": "Please tick has been read",
    "startTry": "Start Try Order",
    "application": "Apply for an official account",
    "welcom": "Welcome trial users",
    "agree": "Agree",
    "downfile": "Full Text Download",
    "dssDesc": "Welcome to try the global direct shipping service. The trial order quantity is limited to {triedNumTotal} orders. If you are satisfied with our global direct shipping service, please contact the sales to sign the contract and open an official account. For trial order customers, except for the global direct shipping service trial order The maximum amount is limited, and other experiences are no different from formal customers. Click [Start Trial Order] to start the experience journey.",
    "dssDescnoLimit": "Welcome to try the global direct shipping service, please contact the sales to sign the contract to open an official account. Click [Start trial order] to start the experience journey.",
    "toValidate": "Invite you to authenticate with your real name",
    "validateDesc": "It has been detected that you have not yet verified your real name. Before applying for an official user, you need to complete the real name verification materials.",
    "validate": "Go to certification",
    "article": "Integrity Commitment Letter",
    "read": "I have read the $lg;$lg;Integrity Commitment Letter$gt;$gt",
    "last": "Current currency balance",
    "locked": "Account is locked",
    "rout": "Logout",
    "locktip": "Dear customer, your account has been locked. If it is still not unlocked after recharging due to arrears or is locked for reasons other than arrears, please contact the sales staff to unlock it.",
    "lockmsg": "Account has been locked",
    "zsAccount": "The application for an official account has been submitted successfully",
    "zsDesc": "Waiting for the sales consultant to contact you to sign the contract, and the conversion will be successful upon completion",
    "contact": "Your direct shipping contract was successfully signed",
    "contactDesc": "It has been successfully converted to an official account, click \"Place Order\" to experience it immediately",
    "appTry": "Click here to apply"
  },
  "de": {
    "audit": "Rückblick...",
    "note": "Bitte warten Sie 1-2 Arbeitstage",
    "cus": "Kontakt zum Kundendienst",
    "check": "Bitte kreuzen Sie an, dass Sie gelesen haben",
    "startTry": "Testbestellung starten",
    "application": "Beantragung eines vollständigen Kontos",
    "welcom": "Willkommene Testnutzer",
    "agree": "Zustimmen",
    "downfile": "Volltext herunterladen",
    "dssDesc": "Wenn Sie mit unserem Global-Direct-Shipping-Service zufrieden sind, wenden Sie sich bitte an Ihren Vertriebsmitarbeiter, um einen Vertrag zur Eröffnung eines Vollkontos zu unterzeichnen. Für Testkunden gelten die gleichen Bedingungen wie für reguläre Kunden, mit Ausnahme des Testbestellungslimits für den Global Direct Shipping Service. Klicken Sie auf [Probebestellung starten], um Ihre Erfahrung zu beginnen.",
    "dssDescnoLimit": "Bitte wenden Sie sich an Ihren Vertriebsmitarbeiter, um einen Vertrag zur Eröffnung eines Vollkontos abzuschließen. Klicken Sie auf [Probebestellung starten], um Ihre Erfahrung zu beginnen.",
    "toValidate": "Sie auffordern, Ihren Namen zu authentifizieren",
    "validateDesc": "Wir haben festgestellt, dass Sie Ihren Namen noch nicht authentifiziert haben. Bevor Sie ein vollständiges Benutzerkonto beantragen können, müssen Sie Ihren echten Namen verifizieren.",
    "validate": "Gehe zu Authentifizierung",
    "article": "Verpflichtungserklärung zur Integrität",
    "read": "Ich habe den Letter of Commitment to Integrity gelesen",
    "last": "Aktueller Währungssaldo",
    "locked": "Konto ist gesperrt",
    "rout": "Stornierung",
    "locktip": "Sehr geehrte Kundin, sehr geehrter Kunde, Ihr Konto wurde gesperrt. Wenn es nach der Aufladung wegen Nichtzahlung von Gebühren und aus Gründen der Nichtzahlung nicht entsperrt wurde, wenden Sie sich bitte an unser Verkaufspersonal, um die Entsperrung vorzunehmen.",
    "lockmsg": "Das Konto wurde gesperrt",
    "zsAccount": "Ihr Antrag auf ein offizielles Konto wurde erfolgreich eingereicht",
    "zsDesc": "Wenn Sie darauf warten, dass Ihr Verkaufsberater sich mit Ihnen in Verbindung setzt, um den Vertrag zu unterzeichnen, wird die Umwandlung erfolgreich abgeschlossen",
    "contact": "Ihr Direktversandvertrag wurde erfolgreich unterzeichnet",
    "contactDesc": "Jetzt, wo Sie zu einem vollständigen Konto umgewandelt worden sind, klicken Sie auf \"Bestellung aufgeben\", um es jetzt zu erleben!",
    "appTry": "Klicken Sie hier um sich zu bewerben",
    "appStart": "Klicken Sie hier, um die Testbestellung zu starten"
  },
  "ja": {
    "audit": "見直すと...",
    "note": "1-2営業日お待ちください。",
    "cus": "カスタマーサービスへのお問い合わせ",
    "check": "お読みいただいた項目にチェックを入れてください",
    "startTry": "トライアルオーダー開始",
    "application": "フルアカウントを申し込む",
    "welcom": "ようこそトライアルユーザーへ",
    "agree": "同意",
    "downfile": "全文ダウンロード",
    "dssDesc": "グローバルダイレクトシッピングサービスにご満足いただけた場合は、フルアカウント開設の契約を締結させていただきますので、担当営業までご連絡ください。 グローバルダイレクトシッピングのトライアル注文の制限を除き、通常のお客様と同じようにご利用いただけます。 体験注文を開始する］をクリックすると、体験が開始されます。",
    "dssDescnoLimit": "フルアカウント開設のための契約は、営業担当者にお問い合わせください。 体験注文を開始する］をクリックすると、体験が開始されます。",
    "toValidate": "名前の認証に招待する",
    "validateDesc": "お客様のお名前が認証されていないことが判明しました。 フルユーザーアカウントを申請する前に、本名確認を完了させる必要があります。",
    "validate": "認証に進む",
    "article": "誠実さへのコミットメント文書",
    "read": "誠実さへの誓約書」を拝読しました。",
    "last": "現在の通貨バランス",
    "locked": "アカウントがロックされている",
    "rout": "キャンセルについて",
    "locktip": "お客様へ：お客様のアカウントはロックされています。 料金未払いや未入金の理由でトップアップしてもロックが解除されない場合は、ロック解除操作のため、弊社営業担当までご連絡ください。",
    "lockmsg": "アカウントがロックされました",
    "zsAccount": "公式アカウントの申請が完了しました。",
    "zsDesc": "セールスコンサルタントからの連絡を待って契約すれば、コンバージョンは無事に完了する",
    "contact": "ダイレクトシッピングの契約が成立しました",
    "contactDesc": "フルアカウントになったので、「注文する」をクリックして、今すぐ体験してください。",
    "appTry": "応募はこちらから",
    "appStart": "トライアルオーダーはこちら"
  }
}
</i18n>
<template>
  <div class="business-try" :class="info ? 'brand-' + info.brandCode : ''">
    <div class="b-name">{{ $t(info.brandCode + '-full') }}</div>
    <div class="b-more" @click="onCheckDetail">
      {{ $t('more') }}
      <div class="arrow-right"></div>
    </div>

    <div class="b-money">{{ $t('last') }}</div>
    <div
      class="b-money-value"
      :style="
        currentCurrency.accountStatus == 'ON'
          ? 'font-size:36px;'
          : 'font-size:28px;'
      "
    >
      {{
        currentCurrency.accountStatus == 'ON'
          ? currentCurrency.availableSurplusVal
          : currentCurrency.accountStatus == 'FZ'
          ? $t('locked')
          : currentCurrency.accountStatus == 'OF'
          ? $t('warehouse_E') 
          : $t('rout')
      }}
    </div>

    <!-- 如果是信用账户，则不展示币种选择 -->
    <template v-if="!info.accountInfoDTOS && currentCurrency">
      <a class="b-curr-currency">{{ currentCurrency.currencyCode }} </a>
    </template>
    <!-- 多币种切换查看 -->
    <a-popover placement="bottom" v-else>
      <div slot="content" @click="onChangeCurrency">
        <!-- 币种 -->
        <a
          class="currency-a"
          style="margin: 3px 10px"
          v-for="c in info.accountInfoDTOS"
          :key="c.currencyCode"
          >{{ c.currencyCode }}</a
        >
      </div>
      <a class="b-curr-currency"
        >{{ currentCurrency.currencyCode }}
        <div class="arrow-strong-down"></div
      ></a>
    </a-popover>

    <!-- 币种锁定提示 -->
    <div
      class="locked"
      v-if="
        (info.frozenCurrencySet && info.frozenCurrencySet.length) ||
        currentCurrency.accountStatus == 'FZ'
      "
    >
      <a-popover placement="bottom">
        <div slot="content" style="width: 240px">
          {{ $t('locktip') }}
        </div>
        <img src="../../../../assets/common/error@2x.png" alt="" />
      </a-popover>
      {{
        info.frozenCurrencySet
          ? info.frozenCurrencySet.toString().replace(/,/g, '、')
          : currentCurrency.currencyCode
      }}
      {{ $t('lockmsg') }}
    </div>
    <!-- 币种结束 -->
    
    <!-- 异常状态提示 accountStatus = OF  -->
    <div v-if="currentCurrency.accountStatus === 'OF'" class="red locked flex-center ">
      {{$t('index_status_tips')}}
    </div>

    <div class="b-btn">
      <a
        v-if="info.brandEnabled"
        id="mask-focus-start-try"
        @click="startTry"
        data-baidu-pv="开始试单"
        >{{ $t('startTry') }}</a
      >
      <a-divider
        type="vertical"
        v-if="info.brandEnabled && info.probationStatus !== 3"
      />
      <a
        data-baidu-pv="申请正式账号"
        id="mask-focus-application-try"
        @click="onApplyAccount"
        v-if="[0, 1, 4].includes(info.probationStatus)"
        >{{ $t('application') }}</a
      >
      <span v-if="info.probationStatus === 2" class="pending">{{
        probationStatus[info.probationStatus]
      }}</span>
    </div>
    <!-- 首次登陆试单 欢迎提示 -->
    <a-modal
      v-model="modalVisible"
      @cancel="onWelcomCancel"
      centered
      width="498px"
    >
      <p class="welcome-word">{{ $t('welcom') }}</p>
      <div class="flex-center main-word">
        <span>{{
          triedNumTotal
            ? $t('dssDesc', { triedNumTotal })
            : $t('dssDescnoLimit')
        }}</span>
      </div>
      <template slot="footer">
        <div class="btn-box">
          <a-button style="height: 40px" type="primary" @click="onWelcomCancel">
            {{ $t('ok') }}
          </a-button>
        </div>
      </template>
    </a-modal>
    <!-- 未实名 -->
    <a-modal
      width="380px"
      :maskClosable="false"
      :bodyStyle="{ padding: 0 }"
      :footer="null"
      v-model="unValidate"
      :closable="false"
    >
      <div style="color: #20212d; font-size: 24px; padding: 20px 0 16px 20px">
        {{ $t('toValidate') }}
      </div>
      <div style="color: #262626; font-size: 16px; padding: 0 20px">
        {{ $t('validateDesc') }}
      </div>
      <div style="text-align: right">
        <a-button
          @click="toValidate"
          style="margin: 34px 20px 24px 0; height: 40px"
          type="primary"
          >{{ $t('validate') }}</a-button
        >
      </div>
    </a-modal>
    <!-- 申请正式账号提示 -->
    <a-modal
      width="380px"
      :maskClosable="false"
      :footer="null"
      v-model="applyModalVisible"
      :bodyStyle="{ 'text-align': 'center' }"
      centered
    >
      <i
        class="iconfont iconchenggong"
        style="color: #18bd92; font-size: 38px"
      ></i>
      <div style="color: #333; font-size: 16px; text-align: center">
        {{ $t('zsAccount') }}
      </div>
      <div
        style="
          color: #999;
          font-size: 12px;
          text-align: center;
          margin-top: 13px;
          margin-bottom: 19px;
        "
      >
        {{ $t('zsDesc') }}
      </div>
      <a-button
        style="height: 40px"
        type="primary"
        @click="() => (applyModalVisible = false)"
        >{{ $t('know') }}</a-button
      >
    </a-modal>

    <!-- 登录后，如果合同已签署给出提示 -->
    <a-modal
      width="380px"
      :footer="null"
      v-model="visibleProbationStatus3"
      :bodyStyle="{ 'text-align': 'center' }"
      centered
    >
      <i
        class="iconfont iconchenggong"
        style="color: #18bd92; font-size: 38px"
      ></i>
      <div style="color: #333; font-size: 16px; text-align: center">
        {{ $t('contact') }}
      </div>
      <div
        style="
          color: #999;
          font-size: 12px;
          text-align: center;
          margin-top: 13px;
          margin-bottom: 19px;
        "
      >
        {{ $t('contactDesc') }}
      </div>
      <a-button
        style="height: 40px"
        type="primary"
        @click="() => (visibleProbationStatus3 = false)"
        >{{ $t('know') }}</a-button
      >
    </a-modal>

    <!-- 首次点击立即试单，勾选承诺函 -->
    <a-modal
      v-model="commitmentModalVisible"
      centered
      :closable="false"
      width="740px"
      :maskClosable="false"
      class="commit-modal"
    >
      <div class="commit-box">
        <p class="name">{{ $t('article') }}</p>
        <div class="content">
          <Protocol />
        </div>
      </div>
      <template style="overflow: hidden" slot="footer">
        <a
          @click="downPDF"
          style="margin-left: 8px; float: left; line-height: 36px"
          >{{ $t('downfile') }}</a
        >
        <a-button
          style="float: right; margin-right: 8px"
          type="primary"
          @click="doAgree"
          :disabled="count > 0"
        >
          <p style="line-height: 36px">
            {{ $t('agree') }}
            <span style="padding: 0" v-if="!!count">({{ count }}s)</span>
          </p>
        </a-button>
        <a-checkbox style="line-height: 36px; float: right" v-model="hasRead">
          {{ $t('read') }}
        </a-checkbox>
      </template>
    </a-modal>

    <!-- 试单 开始试单 TIP -->
    <mask-view
      ref="maskViewStart"
      classId="mask-focus-start-try"
      :tip="$t('appStart')"
      v-if="maskTryStart"
    >
      <a
        v-if="info.brandEnabled"
        class="mask-focus-start-try focus-btn"
        data-baidu-pv="开始试单"
        @click="startTry('maskViewStart')"
        >{{ $t('startTry') }}</a
      >
    </mask-view>

    <!-- 试单 申请正式账号 TIP -->
    <mask-view
      ref="maskViewOpen"
      classId="mask-focus-application-try"
      :tip="$t('appTry')"
      v-if="maskTryOpen && [0, 1, 4].includes(info.probationStatus)"
    >
      <a
        class="mask-focus-application-try focus-btn"
        @click="onApplyAccount('maskViewOpen')"
        >{{ $t('application') }}</a
      >
    </mask-view>
  </div>
</template>

<script>
import { LINKS } from '@const/index'
import Protocol from './Protocol'
import MaskView from './MaskView'
import { dataURItoBlob } from '@u/index'
const probationStatus = {
  0: '待试单',
  1: '待跟进',
  2: '待合同签署',
  3: '已签署',
  4: '放弃合作',
}

export default {
  name: 'business-try',
  props: {
    info: Object,
  },
  components: {
    Protocol,
    MaskView,
  },
  data() {
    return {
      modalVisible: false,
      commitmentModalVisible: false,
      applyModalVisible: false,
      hasRead: false,
      count: 5,
      timer: null,
      triedNumTotal: 200, // 总试单量单量
      probationStatus,
      unValidate: false,
      visibleProbationStatus3: false,
      currentCurrency: {},
      maskTryOpen: false, // TIP: 申请正式账号
      maskTryStart: false, // TIP: 开始试单
    }
  },
  created() {
    // 是否为第一次提示
    if (this.$store.state.firstVisit) {
      // 查询 试单配置 并弹出提示框
      this.getTestOrderConfig()
    }
  },
  mounted() {
    this.currentCurrency = this.info.accountInfoDTO || {}
    // 试单状态
    // 试单用户状态 试单状态：0-待试单 1-待跟进 2-待签署 3-已签署 4-放弃合作
    if (this.info && this.info.probationStatus == 3) {
      this.visibleProbationStatus3 = true
    }

    // 如果是认证成功页点击 申请正式账号，回到此页需要提示
    if (this.$route.params.submit && this.info.probationStatus == 1) {
      this.applyModalVisible = true
    }

    // 当屏幕变化后，更新操作指引位置
    this.$root.bus.$on('onresize', () => {
      const maskOpen = this.$refs.maskViewOpen
      const maskTry = this.$refs.maskViewStart
      if (maskTry && maskTry.setStylePosition) {
        maskTry.setStylePosition()
      }
      if (maskOpen && maskOpen.setStylePosition) {
        maskTry.setStylePosition()
      }
    })
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.$store.state.firstVisit = false
    this.$root.bus.$off('onresize')
  },
  methods: {
    downPDF() {
      this.$show()
      this.$get(
        'v2/common/getFile?url=//bss-fss.i4px.com/4PX试单用户承诺函.pdf'
      ).then((res) => {
        dataURItoBlob(res, '4PX试单用户承诺函.pdf')
        this.$hide()
      })
    },
    /**
     * @description: 获取试单配置
     * @param {*}
     * @return {*}
     */
    getTestOrderConfig() {
      this.$post('v2/brand/queryOperationRulesConfiguration', {
        brandCode: this.info.brandCode,
      }).then((res) => {
        if (res) {
          this.triedNumTotal = res.triedNumTotal
        }
        if (this.$store.state.firstVisit) {
          this.modalVisible = true
        }
      })
    },
    /**
     * @description: 当有弹出欢迎提示，其他提示稍后
     * @param {*}
     * @return {*}
     */
    onWelcomCancel() {
      this.modalVisible = false
      if (this.$store.state.firstVisit) {
        // 显示指引之-申请正式账号 && 判断是否从 直发 跳转进来
        if (this.$route.query.from == 'dss') {
          this.maskTryOpen = true
        } else {
          // 显示指引之-开始试单
          this.maskTryStart = true
        }
        this.$store.state.firstVisit = false
      }
    },
    /**
     * @description: 切换币种
     * @param {type}
     * @return {type}
     */
    onChangeCurrency(e) {
      const code = e.target.innerText
      // 账户被锁定状态
      if (this.info && this.info.accountInfoDTOS) {
        const c = this.info.accountInfoDTOS.filter(
          (item) => item.currencyCode == code
        )
        if (c && c.length) {
          this.currentCurrency = c[0]
        }
      }
    },
    /**
     * @description: 跳转至 我的账户，查看账户详情
     * @param {type}
     * @return {type}
     */
    onCheckDetail() {
      // this.$router.push({ name: this.info.brandCode.toLowerCase() + 'Account' })
      this.$router.push({ name: 'dssAccount' })
    },
    toValidate() {
      this.unValidate = false
      // 标记试单账户去认证
      this.$store.state.tryValidateBrandCode = this.info.brandCode || 'DSS'
      this.$router.push({ name: 'qualification' })
    },
    // 申请正式账号
    // 未实名 -> 提示去实名认证
    // 已实名 -> 触发申请正式账号逻辑（1.试单申请变成待签署，2.通知销售去签合同，签完合同就自动转正）
    onApplyAccount(refname) {
      // 如果有 操作指引 则关闭
      if (refname) {
        const mask = this.$refs[refname]
        if (mask && mask.hideTip) {
          mask.hideTip()
        }
      }
      this.$show()
      this.$post('v2/brand/auth/check')
        .then((res) => {
          this.$hide()
          // 未实名认证  -  跳到实名认证页面
          // 实名认证中  -  跳到实名认证页面（继续上次未完成的实名认证）
          if (res == 0) {
            this.unValidate = true
          }
          // 已实名认证  -  触发申请正式账号逻辑
          if (res == 1) {
            this.$post('v2/honest/applyOfficialAccount', {
              brandCode: this.info.brandCode,
            }).then(() => {
              this.applyModalVisible = true
              this.$root.bus.$emit('reload-brand')
            })
          }
        })
        .catch(() => {
          this.$hide()
        })
    },
    // 开始试单
    startTry(refname) {
      // if (refname) {
      //   const mask = this.$refs[refname]
      //   if (mask && mask.hideTip) {
      //     mask.hideTip()
      //   }
      // }
      // this.$get('v2/honest/isSignHonestNote').then((res) => {
      //   if (!res) {
      //     this.commitmentModalVisible = true
      //     this.timer = setInterval(() => {
      //       if (this.count === 0) {
      //         this.timer = null
      //       } else {
      //         this.count--
      //       }
      //     }, 1000)
      //   } else {
      //     // 打开业务系统
      //     this.goOrder()
      //   }
      // })

      // 跳过诚信协议，直接到试单系统
      this.goOrder()
    },
    doAgree() {
      if (this.hasRead) {
        this.$get('v2/honest/insertHonestRecord').then(() => {
          this.commitmentModalVisible = false
          window.open(LINKS.DSS, '_blank')
        })
      } else {
        this.$message.warning(this.$t('check'))
      }
    },
    /**
     * @description: 打开对应业务系统
     * @param {type}
     * @return {type}
     */
    goOrder() {
      if (['FB4', 'GRS'].indexOf(this.info.brandCode) > -1) {
        const name = this.info.brandCode
        this.$router.push({
          name: `iframeView`,
          params: {
            src: LINKS[name],
            brand: name,
          },
        })
        const list = [...this.$store.getters.getNavItems]
        list.forEach((item) => {
          item.active = false
          if (item.name == name) {
            item.active = true
          }
        })
        this.$store.commit('updateNavItems', list)
      } else {
        window.open(LINKS[this.info.brandCode], '_blank')
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import url('../../../../styles/theme.less');
.commit-modal {
  .commit-box {
    .name {
      font-size: 24px;
      color: #20212d;
      font-weight: 700;
      padding-bottom: 6px;
      padding-top: 6px;
    }
    .content {
      overflow: hidden;
      height: 320px;
      border: 1px solid rgba(220, 220, 220, 1);
      font-size: 14px;
      color: #333;
      border-radius: 2px;
      padding: 10px;
      overflow-y: auto;
    }
  }
}

.welcome-word {
  font-size: 24px;
  color: #20212d;
  margin-top: 8px;
  font-weight: 700;
}
.main-word {
  font-size: 16px;
  color: #262626;
}
.btn-box {
  text-align: right;
}
/deep/ .ant-modal-footer {
  overflow: hidden;
  padding-bottom: 24px;
}
.business-try {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 290px;
  border-radius: 4px;
  background-size: 100% 100%;
  user-select: none;
  .b-name {
    display: inline-block;
    padding: 17px 0 0 17px;
    font-weight: 400;
    color: #333333;
  }
  .b-title {
    font-size: 28px;
    font-weight: 700;
    font-family: AlibabaPuHuiTiM;
    color: #000;
    line-height: 38px;
    margin-top: 72px;
    text-align: center;
  }
  .b-sub-title {
    margin-top: 59px;
    font-size: 14px;
    font-family: AlibabaPuHuiTiR;
    color: #000000;
    line-height: 12px;
    text-align: center;
  }
  .b-more {
    cursor: pointer;
    font-size: 12px;
    color: #999;
    float: right;
    margin-right: 16px;
    margin-top: 17px;
  }
  .b-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    a {
      line-height: 1;
      flex: 1;
      text-align: center;
      color: #333333;
      transition: color 0.3s;
      &:hover {
        color: @prmaryColor;
      }
    }
    .pending {
      flex: 1;
      text-align: center;
    }
  }
  .ant-divider,
  .ant-divider-vertical {
    height: 23px;
  }
}

.b-money {
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  margin-top: 40px;
  width: 100%;
  text-align: center;
}
.b-money-value {
  font-family: AlibabaPuHuiTiM;
  color: #000000;
  line-height: 50px;
  margin-top: 10px;
  text-align: center;
  max-width: 345px;
  word-break: break-all;
}
.b-curr-currency {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 22px;
  font-size: 16px;
  font-family: AlibabaPuHuiTiR;
  color: #999999;
  line-height: 22px;
  margin-top: 4px;
}
.currency-a {
  display: inline-block;
  margin: 15px 20px 9px 15px;
}
.locked {
  margin-top: 25px;
  text-align: center;
  font-weight: 400;
  color: #999999;
  line-height: 12px;
  font-size: 12px;
  img {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}
.red{
  color: #FF3434!important;
}
.ant-divider,
.ant-divider-vertical {
  height: 23px;
}

.focus-btn {
  text-align: center;
  color: #333;
  transition: color 0.3s;
  background: #fff;
  background: #fff;
  display: block;
  border-radius: 5px;
}
</style>
