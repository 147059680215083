<i18n>
{
  "zh": {
    "title": "运费试算",
    "move": "更多"
  },
  "en": {
    "title": "Direct address management",
    "move": "move"
  },
  "de": {
    "title": "Direct address management",
    "move": "move"
  },
  "ja": {
    "title": "Direct address management",
    "move": "move"
  }
}
</i18n>
<template>
  <!-- 服务中心首页 -->
  <div
    class="sc-index-content"
    :style="{ width: isDssHomePage || isNewHomePage ? '100%' : '' }"
    :class="[isHomePage && !isNewHomePage ? 'isHomePage' : 'freight-index']"
  >
    <!-- 首页不显示默认标题 -->
    <div v-if="!isHomePage" class="page-title">
      <span>
        {{ $t('product_expression_0012') }}
      </span>
      <div class="right-btn-box">
        <!-- <div @click="changeOldVersion" class="btn-backold">
          返回旧版
        </div> -->
        <div
          v-if="recommendProducts.length > 0 || allProducts.length > 0"
          @click="showSelectContrastItemVisible"
          class="contrast-btn"
        >
          {{ $t('product_expression_002')
          }}<span>({{ selectProductList.length || 0 }})</span>
        </div>
      </div>
    </div>
    <!-- 首页标题 -->
    <div
      class="isHomePageTitle"
      v-if="isHomePage && !isNewHomePage"
      :style="{ margin: '20px 0 0px 0' }"
    >
      <div class="index-title">
        {{ $t('title') }}
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: this.$t('title') }
              })
            }
          "
        />
      </div>

      <div
        v-if="!isDssHomePage && !isNewHomePage"
        class="b-more"
        @click="goDetail"
      >
        {{ $t('move') }}
        <div class="arrow-right"></div>
      </div>
    </div>
    <div
      class="page-inner-cnt"
      :style="{ padding: isNewHomePage ? '20px 18px 16px' : '24px' }"
    >
      <div class="search-box">
        <div class="search-item">
          <div class="lable">
            <span class="req">*</span>{{ $t('product_expression_003') }}
          </div>
          <ShippmentAddress
            ref="shippmentAddressRef"
            @ogId="onReciveOgId"
            :Name="orgName"
            :ogIdPickup="form.ogIdPickup"
            :isFixedBottom="isFixedBottom"
          />
        </div>
        <div class="search-item">
          <div class="lable"><span class="req">*</span>{{ $t('sv.t4') }}</div>
          <CountrySelect
            ref="countrySelectRef"
            :placement="isFixedBottom ? 'topRight' : ''"
            @pop-value="onSelectCountry"
            :selectedValue="countryName"
          />
          <div class="county-plancehold" v-if="!countryName" style="">
            {{ $t('wt.t58') }}
          </div>
        </div>
        <div class="search-item">
          <div class="lable">
            <span class="req">*</span>{{ $t('product_expression_004') }}
          </div>
          <div class="value">
            <input
              v-model="form.weight"
              @input="validatedWeight"
              @blur="validatedWeight"
              class="item-input"
              :placeholder="$t('entrustedPayment.o20')"
            />
            <span class="suffix">KG</span>
          </div>
        </div>
        <div class="search-item">
          <div class="lable">{{ $t('user_tran_009') }}</div>
          <input
            size="small"
            v-model="form.postCode"
            class="item-input"
            :placeholder="$t('entrustedPayment.o20')"
          />
        </div>
        <div class="attr-item search-item">
          <div class="lable">{{ $t('product_expression_005') }}</div>
          <!--  @visibleChange="goodsAttrChange" -->
          <a-popover
            v-model="goodsAttrFormVisible"
            placement="bottomLeft"
            trigger="click"
          >
            <template #content>
              <GoodsAttr
                @reset="resetGoodsAttrForm"
                @ok="hideGoodsAttrForm"
                ref="GoodsAttr"
                :formData="goodsAttrForm"
                :attrData="cargoTypeIdArr"
              />
            </template>
            <div v-if="hasGoodAttr" class="value">
              {{ goodsAttrForm.volumeLength || '-' }}*{{
                goodsAttrForm.width || '-'
              }}*{{ goodsAttrForm.height || '-' }}cm³/
              {{ goodsAttrForm.isBattery | getNameFromValue(isBatteryArr) }}/
              {{ goodsAttrForm.cargoType | getNameFromValue(cargoTypeArr) }}/
              {{ goodsAttrForm.categoryId | getNameFromValue(cargoTypeIdArr) }}/
              {{ goodsAttrForm.declareValue || '-' }}
              {{ goodsAttrForm.declareValue && 'USD' }}
            </div>
            <div v-else class="unvalue">
              {{ $t('please.select') }}
            </div>
          </a-popover>
        </div>
        <!--   @visibleChange="serviceCategoryChange" -->
        <div class="search-item" v-if="!isFixedBottom">
          <div class="lable">{{ $t('service.type') }}</div>
          <a-popover
            v-model="serviceCategoryFormVisible"
            placement="bottomLeft"
            trigger="click"
          >
            <template #content>
              <ServiceCategory
                @ok="hideServiceCategoryForm"
                ref="ServiceCategory"
                @reset="resetServiceCategoryForm"
                :formData="serviceCategoryForm"
              />
            </template>
            <div>
              <div
                v-if="
                  serviceCategoryForm.trackType ||
                    serviceCategoryForm.serviceLevel
                "
                class="value"
              >
                {{
                  serviceCategoryForm.trackType | getNameFromValue(trackTypeArr)
                }}
                /
                {{
                  serviceCategoryForm.serviceLevel
                    | getNameFromValue(serviceLevelArr)
                }}
              </div>
              <div v-else class="unvalue">
                {{ $t('please.select') }}
              </div>
            </div>
          </a-popover>
        </div>
        <div
          v-if="!isFixedBottom"
          @click="getProduct($event)"
          class="search-btn"
        >
          {{ $t('product_expression_006') }}
        </div>
        <div v-if="!isFixedBottom" @click="reset" class=" search-btn-o">
          {{ $t('reset') }}
        </div>
        <!-- 试算金额 -->
        <div
          v-if="isFixedBottom"
          @click="trialPrice('', $event)"
          class=" search-price-btn"
        >
          {{ $t('试算') }}
        </div>
      </div>
    </div>

    <!-- 广告产品 -->
    <div
      class="ad-product-box"
      v-if="!isSearch && !isDssHomePage && !isHomePage"
    >
      <div class="prod-box">
        <div
          class="prod-item"
          v-for="(item, index) in adProductList"
          :key="index"
        >
          <div
            class="prod-img-box"
            :style="{
              'background-image': 'url(' + item.imgUrl + ')'
            }"
          ></div>
          <div class="detail">
            <span class="tit-name">{{ item.name }}</span>
            <a target="_blank" :href="item.redirectUrl" class="link"
              >{{ $t('product_expression_007') }} ></a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <a-empty
      v-if="!isSearch && adProductList.length < 1"
      style="width: 100%; height: 270px; padding: 120px 0"
    /> -->

    <!-- 产品 -->
    <div class="search-product-box" v-if="isSearch">
      <div class="product">
        <div class="product-tit">
          <span class="tit-text">{{ $t('product_expression_008') }}</span>
          <div
            v-if="
              (recommendProducts.length > 0 || allProducts.length > 0) &&
                isHomePage
            "
            @click="showSelectContrastItemVisible"
            class="contrast-btn"
          >
            {{ $t('product_expression_002')
            }}<span>({{ selectProductList.length || 0 }})</span>
          </div>
        </div>
        <Product
          :selectProduct="selectProductList"
          @addSelectProduct="addSelectProduct"
          :queryParam="queryParam"
          :cityName="this.orgName || '深圳'"
          :countryName="countryName"
          :listData="recommendProducts"
          type="recommend"
          @showDetail="toggleDetailVisible"
        />
        <div class="product-tit">
          <span class="tit-text"
            >{{ $t('product_expression_009') }}
            <span class="trial-tips">{{ $t('product_expression_066') }}</span>
          </span>
          <div class="filtter-box">
            <span
              @click="sortProduct('timeSort')"
              class="filtter-item"
              :class="{ active: sortKey == 'timeSort' }"
              >{{ $t('product_expression_010') }}</span
            >
            <span class="gap-line"></span>
            <span
              @click="sortProduct('priceSort')"
              class="filtter-item"
              :class="{ active: sortKey == 'priceSort' }"
              >{{ $t('product_expression_011') }}</span
            >
          </div>
        </div>
        <Product
          :selectProduct="selectProductList"
          :countryName="countryName"
          @addSelectProduct="addSelectProduct"
          :listData="allProducts"
          :queryParam="queryParam"
          :cityName="this.orgName || '深圳'"
          type="all"
          @showDetail="toggleDetailVisible"
        />
      </div>
    </div>

    <!-- 产品详情 -->
    <Detail
      :baseData="productDetailItem"
      :queryParam="queryParam"
      :cityName="this.orgName || '深圳'"
      :countryName="countryName"
      v-if="detailVisible"
      @onClose="toggleDetailVisible"
      :visible="detailVisible"
      :productRank="productRank"
    />
    <!-- 选择对比产品 -->
    <SelectContrastItem
      v-if="selectContrastItemVisible"
      @subimt="toggleContrastItemVisible"
      @onClose="toggleSelectContrastItemVisible"
      @delProductItem="delProductItem"
      @clearSelectProductList="clearSelectProductList"
      :visible="selectContrastItemVisible"
      :productList="recommendAndAllproductList"
      :selectProduct="selectProductList"
    />
    <ContrastItemDetail
      v-if="contrastItemDetailVisible"
      @onClose="toggleContrastItemVisible"
      @delProductItem="delProductItem"
      :countryName="countryName"
      :queryParam="queryParam"
      :cityName="this.orgName || '深圳'"
      :visible="contrastItemDetailVisible"
      :allProductList="filtersProductList"
      :selectProduct="selectProductList"
    />
  </div>
</template>

<script>
import {
  isBatteryArr,
  cargoTypeArr,
  trackTypeArr,
  serviceLevelArr
} from './components/data'
import CountrySelect from './components/CountrySelect'
import ShippmentAddress from './components/ShipmentAddress'
import sensors from 'sa-sdk-javascript'
import { getMenuData } from '../servicecenter.data'
import ServiceCategory from './components/ServiceCategory'
import GoodsAttr from './components/GoodsAttr'
import Product from './components/Product'
import Detail from './components/Detail'
import SelectContrastItem from './components/SelectContrastItem'
import ContrastItemDetail from './components/ContrastItemDetail'
import { burialPoint } from './burialPoint.services'
import { buildUrlQueryParams } from '@/utiles/index'

const defForm = {
  ogIdPickup: '10', //发货地ID
  country: '',
  weight: '',
  postCode: ''
}
const defGoodsAttrForm = {
  volumeLength: '',
  width: '',
  height: '',
  isBattery: '',
  cargoType: 'P', //D 文件 P 包裹"
  declareValue: '', //"申报价值"
  categoryId: '' //"品类属性id"
}
const defServiceCategoryForm = {
  trackType: '', // "全跟踪, 1, 半跟踪, 2 无跟踪, 3"
  serviceLevel: ''
}
export default {
  props: {
    isHomePage: {
      type: Boolean,
      default: false
    },
    cityName: {
      type: String
    },
    isDssHomePage: {
      type: Boolean,
      default: false
    },
    isNewHomePage: {
      type: Boolean,
      default: false
    },
    isFixedBottom: {
      type: Boolean,
      default: false
    },
    productCode: {
      type: String,
      value: ''
    },
    popsCountryCode: {
      type: String,
      value: ''
    },
    popsCountryName: {
      type: String,
      value: ''
    },
    popsWeight: {
      type: [String, Number],
      value: ''
    },
    ogIdPickup: {
      type: [String, Number],
      value: ''
    },
    popsOrgName: {
      type: String,
      value: ''
    },
    popsGoodsAttrForm: {
      type: Object,
      return: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isBatteryArr: isBatteryArr(this),
      cargoTypeArr: cargoTypeArr(this),
      trackTypeArr: trackTypeArr(this),
      serviceLevelArr: serviceLevelArr(this),
      // productCode: '',
      productPopover: false,
      productSerachData: [],
      adProductList: [], //广告产品列表
      isSearch: false,
      recommendProducts: [],
      originAllProducts: [], //原产品数据
      allProducts: [], //排序后展示数据
      recommendAndAllproductList: [],
      productDetailItem: {},
      sortKey: '', //排序类型
      queryParam: {}, //搜索参数
      orgName: '',
      // 属性数据
      cargoTypeIdArr: [],
      detailVisible: false,
      productRank: '',
      selectContrastItemVisible: false,
      contrastItemDetailVisible: false,
      form: {
        ...defForm
      },
      countryName: undefined,
      // 货物属性
      goodsAttrForm: {
        ...defGoodsAttrForm
      },
      goodsAttrFormVisible: false,
      // 服务类型
      serviceCategoryForm: {
        ...defServiceCategoryForm
      },
      serviceCategoryFormVisible: false,
      showCountryError: false,
      showSendAreaError: false,
      // 选择的产品
      selectProductList: [],
      selectProductNameList: []
    }
  },
  components: {
    ShippmentAddress,
    CountrySelect,
    ServiceCategory,
    GoodsAttr,
    SelectContrastItem,
    ContrastItemDetail,
    Product,
    Detail
  },
  watch: {
    form: {
      immediate: false,
      deep: true,
      handler(newValue, oldValue) {
        this.$emit('watch changeForm', newValue)
      }
    },
    ogIdPickup(newVal) {
      if (newVal) {
        this.form.ogIdPickup = newVal
      }
    },
    popsOrgName(newVal) {
      if (newVal) {
        this.orgName = newVal
      }
    },
    popsCountryCode(newVal, oldVal) {
      newVal ? (this.form.country = newVal) : null
    },
    popsCountryName(newVal, oldVal) {
      newVal ? (this.countryName = newVal) : null
      this.popsCountryCode ? (this.form.country = this.popsCountryCode) : null
    }
  },
  computed: {
    linkPrefix() {
      let url = ''
      if (process.env.NODE_ENV == 'production') {
        url = '/dist'
      }
      return url
    },
    hasGoodAttr() {
      let hasGoodAttr = false
      if (
        this.goodsAttrForm?.volumelength ||
        this.goodsAttrForm?.width ||
        this.goodsAttrForm?.height ||
        this.goodsAttrForm?.isBattery ||
        this.goodsAttrForm?.cargoType ||
        this.goodsAttrForm?.categoryId ||
        this.goodsAttrForm?.declareValue
      ) {
        hasGoodAttr = true
      }
      return hasGoodAttr
    },
    filtersProductList() {
      const allProducts = this.allProducts || []
      const recommendProducts = this.recommendProducts || []
      const productList = allProducts.concat(recommendProducts)
      const selectProductList = this.selectProductList || []
      let filtersProductList = []
      let addListCode = []
      productList.forEach(element => {
        if (
          selectProductList.includes(element.productCode) &&
          !addListCode.includes(element.productCode)
        ) {
          addListCode.push(element.productCode)
          filtersProductList.push(element)
        }
      })
      filtersProductList = [...new Set(filtersProductList)]
      return filtersProductList
    }
  },

  created() {
    this.orgName = this.popsOrgName || this.orgName
    this.form.ogIdPickup = this.ogIdPickup || this.form.ogIdPickup || '10'
  },
  mounted() {
    getMenuData(this)
    this.getDefData()

    this.form.country = this.popsCountryCode || this.form.country || ''
    this.countryName = this.countryName || this.popsCountryName || ''
    this.form.weight = this.popsWeight || this.form.weight || ''
    if (this.isFixedBottom && !this.popsWeight && !this.form.weight) {
      this.form.weight = 0.2
    }
    this.setRouterParams()
    // 判断是否从首页过来 执行搜索功能
    if (
      this.form.ogIdPickup &&
      this.form.country &&
      this.form.weight &&
      this.$route.path.includes('dssFreight')
    ) {
      setTimeout(() => {
        this.getProduct('homepage')
      }, 300)
    }
  },
  methods: {
    // 设置路由传过来的参数
    setRouterParams() {
      const params = this.$route.params
      const {
        form = {},
        orgName = '',
        countryName = {},
        goodsAttrForm = {},
        serviceCategoryForm = {}
      } = params
      if (form?.country || form?.weight) {
        this.form = {
          ...form
        }
        // 发货地
        this.orgName = orgName || this.popsOrgName || this.orgName || ''
        // 目的国家
        this.countryName = countryName
        this.goodsAttrForm = {
          ...goodsAttrForm
        }
        this.serviceCategoryForm = {
          ...serviceCategoryForm
        }
      }
      // 产品详情页  判断当前路由是否是产品详情页
      if (this.$route.path.includes('productDetails')) {
        // 判断this.popsGoodsAttrForm 不能为空 或=={}
        if (
          this.popsGoodsAttrForm &&
          JSON.stringify(this.popsGoodsAttrForm) != '{}'
        ) {
          this.goodsAttrForm = {
            ...defGoodsAttrForm,
            ...this.popsGoodsAttrForm
          }
        }
        // 如果有国家code 直接试算价格
        if (this.form.country && this.form.weight && this.productCode) {
          this.trialPrice('fistTrial')
        }
      }
    },
    validatedWeight() {
      let reg = /^\d*\.?\d{0,3}$/
      if (!reg.test(this.form.weight)) {
        // 如果输入的数据不符合要求，将其设置为最后一个有效值
        this.form.weight = this.form.weight.slice(0, -1)
      }
      // 如果输入的数据包含除数字和小数点以外的字符，将其删除
      this.form.weight = this.form.weight.replace(/[^0-9.]/g, '')
      // 如果输入的数据有多个小数点，只保留第一个
      this.form.weight = this.form.weight.replace(/\.+/g, function(m, i) {
        return i === this.form.weight.indexOf('.') ? '.' : ''
      })
    },
    sortProduct(key) {
      if (key && key != this.sortKey) {
        this.$show()
        this.sortKey = key
        let allProducts = this.allProducts
        allProducts.sort((a, b) => a[key] - b[key])
        this.allProducts = allProducts
        setTimeout(() => {
          this.$hide()
        }, 300)
      } else if (key && this.sortKey == key) {
        this.sortKey = ''
        this.allProducts = this.originAllProducts
      }
    },
    addSelectProduct(code = '', item, bpType, index) {
      let selectProductList = this.selectProductList || []
      let selectProductNameList = this.selectProductNameList || []
      if (code && selectProductList.includes(code)) {
        this.delProductItem(code)
        this.$message.success(this.$t('product_expression_050'))
      } else if (code && !selectProductList.includes(code)) {
        this.$message.success(this.$t('product_expression_051'))
        selectProductList.push(code)
        selectProductNameList.push(item?.productName)
        this.selectProductList = selectProductList
        this.selectProductNameList = selectProductNameList
      }
      //埋点 添加对比
      burialPoint({
        event: '4', //添加对比
        productArea: bpType || '', //页面区域  1:推荐位 2:列表位 3:对比产品
        productRank: index + 1 || '', //排序
        productName: item?.productName || '', // 产品名称
        productCode: item?.productCode || '' //产品代码
      })
    },
    clearSelectProductList() {
      this.selectProductList = []
      this.selectProductNameList = []
    },
    delProductItem(code = '') {
      let selectProductList = this.selectProductList || []
      let selectProductNameList = this.selectProductNameList || []
      let index = selectProductList.indexOf(code)
      if (code && index !== -1) {
        selectProductList.splice(index, 1)
        selectProductNameList.splice(index, 1)
        this.selectProductList = selectProductList || []
        this.selectProductNameList = selectProductNameList || []
      }
    },
    getDefData() {
      // 查询品类属性
      this.$get('v2/freightFeeTrial/getCategoryAttributes').then(res => {
        let cargoTypeIdArr = res || []
        cargoTypeIdArr.forEach(element => {
          element.value = element.id
        })
        cargoTypeIdArr.unshift({
          name: '普通商品',
          id: 'product_expression_062',
          value: 'product_expression_062'
        })
        this.cargoTypeIdArr = cargoTypeIdArr
      })
      // 查询广告产品
      this.$post('v2/freightFeeTrial/getAdProducts', {
        location: 'FREIGHT_TRIAL_PAGE'
      }).then(res => {
        this.adProductList = res || []
      })
    },
    reset() {
      this.isSearch = false
      this.selectProduct = []
      this.recommendProducts = []
      this.originAllProducts = []
      this.allProducts = []
      this.countryName = ''
      this.sortKey = ''
      this.recommendAndAllproductList = []
      this.$root.bus.$on('clear', () => {
        this.curr = ''
      })

      this.form = {
        ...defForm
      }
      this.resetGoodsAttrForm()
      this.resetServiceCategoryForm()
      // 重置子组件
      this.$refs.countrySelectRef?.clearData()
      this.$refs.shippmentAddressRef?.clearData()
    },
    // 重置货物属性
    resetGoodsAttrForm() {
      this.goodsAttrForm = {
        ...defGoodsAttrForm
      }
      if (this.$refs.GoodsAttr && this.$refs.GoodsAttr.form) {
        this.$refs.GoodsAttr.form = {
          ...defGoodsAttrForm
        }
      }
    },
    hideGoodsAttrForm() {
      this.goodsAttrChange(false)
      this.goodsAttrFormVisible = false
    },
    // 重置服务类别
    resetServiceCategoryForm() {
      this.serviceCategoryForm = {
        ...defServiceCategoryForm
      }
      if (this.$refs.ServiceCategory && this.$refs.ServiceCategory.form) {
        this.$refs.ServiceCategory.form = {
          ...defServiceCategoryForm
        }
      }
    },
    hideServiceCategoryForm() {
      this.serviceCategoryChange(false)
      this.serviceCategoryFormVisible = false
    },
    validata(param) {
      let validata = false
      if (!param?.ogIdPickup) {
        this.$message.warning(this.$t('product_expression_012'))
        validata = true
      } else if (!param?.country) {
        this.$message.warning(this.$t('product_expression_013'))
        validata = true
      } else if (!param?.weight) {
        this.$message.warning(this.$t('product_expression_014'))
        validata = true
      }
      return validata
    },
    isDssHomePageGoDssFreight(params = {}) {
      if (!this.$store.state.username) {
        let url = this.$router.resolve({
          name: 'dssFreight',
          params: {
            routerfrom: 'homePage',
            form: {
              ...this.form
            },
            countryName: this.countryName || '',
            orgName:
              this.form.ogIdPickup == 10 && !this.orgName
                ? '深圳'
                : this.orgName,
            goodsAttrForm: this.goodsAttrForm,
            serviceCategoryForm: this.serviceCategoryForm
          }
        }).href

        window.open(url, '_blank') // '_blank' 表示在新标签页中打开
        return
      }
      this.$router.push({
        name: 'dssFreight',
        params: {
          routerfrom: 'homePage',
          form: {
            ...this.form
          },
          countryName: this.countryName || '',
          orgName:
            this.form.ogIdPickup == 10 && !this.orgName ? '深圳' : this.orgName,
          goodsAttrForm: this.goodsAttrForm,
          serviceCategoryForm: this.serviceCategoryForm
        }
      })
    },
    // 试算价格
    trialPrice(type, even) {
      this.$emit('trialPrice', {})
      let param = {
        ...this.form,
        ...this.goodsAttrForm,
        length: this.goodsAttrForm.volumeLength,
        ...this.serviceCategoryForm,
        productCode: this.productCode
      }
      delete param.volumeLength
      for (let key in param) {
        if (param[key] === '') {
          delete param[key]
        }
      }

      if (this.validata(param)) {
        return false
      }

      sensors.track('CalculateFreightClick', {
        page_type: '产品详情页',
        Shipping_region: this.orgName || '深圳',
        receiver_country: param?.country || ''
      })

      this.$show()
      param.weight ? (param.weight = Number(param.weight)) : null
      // 普通分类上传传参
      param.categoryId == 'product_expression_062'
        ? delete param.categoryId
        : ''

      this.$post('v2/freightFeeTrial/trialByProductCode', param)
        .then(res => {
          this.$emit('trialPrice', res)
          if (
            (res == null ||
              !res.deliveryPeriod ||
              res.price == '0' ||
              res.price == '0.00' ||
              !res.price) &&
            type != 'fistTrial'
          ) {
            this.$message.warning(
              this.$t('暂无预估费用，请确认您填写的内容在服务范围内！')
            )
          }
          this.$hide()
        })
        .catch(() => {
          this.$hide()
        })
    },
    // 试算产品
    getProduct(searchType, event) {
      let param = {
        ...this.form,
        ...this.goodsAttrForm,
        length: this.goodsAttrForm.volumeLength,
        ...this.serviceCategoryForm
      }
      delete param.volumeLength
      for (let key in param) {
        if (param[key] === '') {
          delete param[key]
        }
      }

      if (this.validata(param)) {
        return false
      }

      // 首页运费试算点击查询商品 跳转到内容
      if (this.isDssHomePage || this.isHomePage) {
        this.isDssHomePageGoDssFreight()

        sensors.track('CalculateFreightClick', {
          page_type: '首页',
          Shipping_region: this.orgName || '深圳',
          receiver_country: param?.country || ''
        })
        // 埋点  首页 查询
        burialPoint({
          event: '2',
          productArea: '5', //首页
          country: param?.country || '',
          city: this.orgName || '深圳',
          postcode: param?.postCode || '',
          weight: param?.weight || '',
          packageType: param?.cargoType || '', //货物类型
          declaredValue: param?.declareValue || '', //申报价值
          isBattery: param?.isBattery || '', //货物属性：带电、普货
          trackType: param?.trackType || '', //跟踪类型：全跟踪、半跟踪
          serviceLevel: param?.serviceLevel || '' //服务等级：优先、经济、标准等:
        })
        return false
      } else if (searchType != 'homepage') {
        sensors.track('CalculateFreightClick', {
          ...event?.target,
          page_type: '直发运费试算',
          Shipping_region: this.orgName || '深圳',
          receiver_country: param?.country || ''
        })
        // 埋点 查询
        burialPoint({
          event: '2',
          // productArea: '1', //页面区域  1:推荐位 2:列表位 3:对比产品 4:产品详情 5:首页
          country: param?.country || '',
          city: this.orgName || '深圳',
          postcode: param?.postCode || '',
          weight: param?.weight || '',
          packageType: param?.cargoType || '', //货物类型
          declaredValue: param?.declareValue || '', //申报价值
          isBattery: param?.isBattery || '', //货物属性：带电、普货
          trackType: param?.trackType || '', //跟踪类型：全跟踪、半跟踪
          serviceLevel: param?.serviceLevel || '' //服务等级：优先、经济、标准等:
        })
      }

      this.isSearch = true
      this.sortKey = ''
      this.$show()
      param.weight ? (param.weight = Number(param.weight)) : null
      // 普通分类上传传参
      param.categoryId == 'product_expression_062'
        ? delete param.categoryId
        : ''

      this.$post('v2/freightFeeTrial/trial', param)
        .then(res => {
          this.selectProductList = []
          this.selectProductNameList = []
          this.$hide()
          this.queryParam = res?.queryParam || {}
          // 添加推荐产品排序
          this.recommendProducts = res.recommendProducts.map(
            (item, reIndex) => {
              item.productRank = reIndex + 1
              return item
            }
          )
          // 添加产品排序
          this.allProducts = res.lstFeeBeans.map((item, allIndex) => {
            item.productRank = allIndex + 1
            return item
          })

          this.recommendAndAllproductList = [
            ...res?.recommendProducts,
            ...res?.lstFeeBeans
          ]
        })
        .catch(() => {
          this.selectProductList = []
          this.selectProductNameList = []
          this.queryParam = {}
          this.recommendProducts = []
          this.originAllProducts = []
          this.allProducts = []
          this.sortKey = ''
          this.recommendAndAllproductList = []
          this.$hide()
        })
    },
    // 选择发货地
    onReciveOgId(id, name = '') {
      this.form.ogIdPickup = id
      this.orgName = name
      this.showSendAreaError = !id
    },
    /**
     * @description: 选择目的国/地区
     * @param {*} code
     * @param {*} name
     * @return {*}
     */
    onSelectCountry(code, name) {
      this.form.country = code
      this.countryName = name
    },
    toggleDetailVisible(item, bpType, index, event) {
      this.productDetailItem = item

      // 旧版 弹窗详情
      // this.detailVisible = !this.detailVisible

      // 埋点 详情
      // if (this.detailVisible) {
      this.productRank = index + 1
      burialPoint({
        event: '3',
        productArea: bpType || '', //页面区域  1:推荐位 2:列表位 3:对比产品
        productRank: index + 1, //排序
        productName: item?.productName || '', // 产品名称
        productCode: item?.productCode || '' //产品代码
      })
      sensors.track('ProductDetailsClick', {
        ...event?.target,
        product_code: item?.productCode || '',
        product_name: item?.productName || ''
      })

      let parmas = {
        ...this.queryParam,
        orgName: this.orgName || '',
        productCode: item.productCode,
        countryName: this.countryName,
        goodsAttrForm: encodeURI(JSON.stringify(this.goodsAttrForm))
      }
      let queryString = buildUrlQueryParams(parmas)
      window.open(`${this.linkPrefix}/productDetails?${queryString}`)
    },
    toggleContrastItemVisible() {
      this.contrastItemDetailVisible = !this.contrastItemDetailVisible
      if (this.contrastItemDetailVisible) {
        // 点击开始对比 埋点
        burialPoint({
          event: '5',
          // 选择的产品列表合并并且去重转成,
          productCode: this.selectProductList.join(','), //产品代码
          productName: this.selectProductNameList.join(',')
        })
      }
    },
    showSelectContrastItemVisible() {
      if (this.selectProductList?.length < 1) {
        this.$message.warning(this.$t('product_expression_052'))
      } else {
        this.selectContrastItemVisible = true
      }
    },
    toggleSelectContrastItemVisible() {
      this.selectContrastItemVisible = !this.selectContrastItemVisible
    },
    goodsAttrChange(visible) {
      if (!visible) {
        let goodsAttrForm = this.$refs.GoodsAttr.form || {}
        this.goodsAttrForm = {
          volumeLength: goodsAttrForm?.volumeLength || '',
          width: goodsAttrForm?.width || '',
          height: goodsAttrForm?.height || '',
          isBattery: goodsAttrForm?.isBattery || '',
          cargoType: goodsAttrForm?.cargoType || '',
          declareValue: goodsAttrForm?.declareValue || '',
          categoryId: goodsAttrForm?.categoryId || ''
        }
      }
    },
    changeOldVersion() {
      this.$router.push({ path: '/oldDssFreight' })
    },
    goDetail() {
      this.$router.push('/dssFreight')
    },
    serviceCategoryChange(visible) {
      if (!visible) {
        let serviceCategoryForm = this.$refs.ServiceCategory.form || {}
        this.serviceCategoryForm = {
          trackType: serviceCategoryForm.trackType || '',
          serviceLevel: serviceCategoryForm.serviceLevel || ''
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.isHomePage {
  width: 66.67%;
  height: 332px;
  background: #fff;
  margin-right: 20px;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: auto;
  border-radius: 8px;
  padding-top: 0 !important;
}
.isHomePage.isHomePage {
  margin-top: 0px !important;
  background: white !important;
}
.isHomePage .isHomePageTitle {
  padding: 0 24px;
}
.isHomePage .search-product-box {
  padding: 0 24px;
}
.sc-index-content {
  background-color: transparent !important;
  padding: 0 0 24px 0 !important;
  min-height: 332px;
}
.page-title {
  position: relative;
  width: 100%;
  top: 0px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right-btn-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn-backold {
    font-size: 14px;
    color: #767d91;
    font-weight: 400;
    cursor: pointer;
  }
}
.page-inner-cnt {
  // padding: 0px !important;
  margin-top: 44px !important;
}
div.page-inner-cnt:nth-of-type(2) {
  margin-top: 0px !important;
}

// 搜索样式

.search-box {
  display: flex;
  justify-content: space-between;
  align-content: center;
  .search-item {
    position: relative;
    margin-right: 8px;
    width: 128px;
    flex: 1;
    min-width: 120px;
    height: 60px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 12px;
    color: #545d75;
    font-size: 14px;
    border: 1px solid rgba(218, 223, 232, 1);
    .lable {
      line-height: 14px;
      margin-bottom: 8px;
    }
    .req {
      color: #f85359;
    }
    .county-plancehold {
      position: absolute;
      bottom: 6px;
      height: 20px;
      line-height: 20px;
      width: 100%;
      color: #c5c5d6;
    }
    .value {
      position: relative;
      display: inline-block;
      color: #323643;
      font-weight: 500;
      line-height: 20px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .unvalue {
      // padding-left: 7px;
      color: #bfbfbf;
      line-height: 20px;
    }
    .suffix {
      width: 26px;
      text-align: right;
      position: absolute;
      right: 0px;
    }
  }
  .attr-item {
    flex: 2;
  }
  .item-input {
    padding: 0;
    border: 0;
    max-width: 100%;
    color: #323643;
    font-weight: 500;
    outline: none; /*清除input点击之后的黑色边框*/
    &::placeholder {
      color: #bfbfbf;
    }
    &:focus {
      border: 0;
    }
    &:active {
      border: 0;
    }
  }

  .search-btn {
    min-width: 128px;
    width: 128px;
    height: 60px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    background: #3960df;
    transition: all 0.2s;
    animation: all 0.2s;
    &:hover {
      background: #3e83ff !important;
    }
  }
  .search-price-btn {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3960df;
    cursor: pointer;
    background: rgba(57, 96, 223, 0.1);
    transition: all 0.2s;
    animation: all 0.2s;
    &:hover {
      background: rgba(57, 96, 223, 0.2) !important;
    }
  }
  .search-btn-o {
    margin-left: 12px;
    width: 64px;
    width: 64px;
    height: 60px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid rgba(218, 223, 232, 1);
    color: #545d75;
    transition: all 0.2s;
    animation: all 0.2s;
    &:hover {
      color: #8f9ec5;
    }
  }
}

::v-deep.ant-select > .ant-select-selection {
  border: 0 !important;
  border-color: transparent;
}
::v-deep.ant-select-opend > .ant-select-selection,
::v-deep.ant-select-focused {
  border: 0 !important;
  border-color: transparent;
}
//右边距
::v-deep.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 0 !important;
}
::v-deep.ant-select-selection--single > .ant-select-selection__rendered {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
::v-deep.ant-select-focused .ant-select-selection,
::v-deep.ant-select-selection:focus,
::v-deep.ant-select-selection:active {
  border-color: transparent !important;
  box-shadow: 0 0 0 0px rgba(255, 255, 255, 0) !important;
}
.trial-tips {
  font-size: 14px;
  margin-left: 12px;
  color: #545d75;
}
.product-tit {
  margin-top: 16px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #151f28;
  line-height: 16px;
  font-weight: 500;
  .tit-text::before {
    margin-right: 6px;
    display: inline-block;
    content: '';
    width: 4px;
    height: 16px;
    background: #3960df;
    border-radius: 2px;
  }
  .filtter-box {
    display: flex;
    align-content: center;
    font-size: 14px;
    color: #545d75;
    font-weight: 400;
    cursor: pointer;
  }

  .filtter-box .filtter-item.active {
    color: #3960df;
    font-weight: bold;
  }
  .gap-line {
    margin: 0 9px;
    width: 0;
    height: 12px;
    border-right: 1px solid #a9aeba;
  }
}

// 产品列表
.prod-box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.prod-item {
  margin-bottom: 16px;
  margin-right: 12px;
  width: calc((100% - 24px) / 3);
  background: #e1e1e1;
  box-shadow: 4px 4px 16px 0 rgba(144, 155, 190, 0.13);
  border-radius: 8px 8px 0 0;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .prod-img-box {
    width: 100%;
    min-height: 0;
    border-radius: 8px 8px 0 0;
    padding-bottom: 41.666%;
    background-color: #e1e1e1;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .detail {
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    width: 100%;
    height: 42px;
    font-size: 14px;
    color: #323643;
    line-height: 14px;
    font-weight: 500;
    .link {
      font-size: 14px;
      color: #3960df;
      line-height: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
.contrast-btn {
  // position: absolute;
  cursor: pointer;
  right: 0px;
  display: inline-block;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  min-width: 98px;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  background: #feedde;
  border: 1px solid #f77300;
  border-radius: 4px;
  font-size: 14px;
  color: #ff6400;
  font-weight: 500;
  text-align: center;
}
.isHomePage .contrast-btn {
  margin-top: -10px !important;
}
</style>
