<template>
  <span>
    <a-popover
      placement="bottomLeft"
      @visibleChange="visibleChange"
      trigger="click"
    >
      <template slot="content">
        <a-spin :spinning="loadingLineChat">
          <div class="line-chart-tit">
            {{ item.productName }}({{ item.productCode }})
          </div>
          <div
            :id="'line-chart' + type + item.productCode"
            class="line-chart"
          ></div>
        </a-spin>
      </template>
      <span @click="getPriceTrends(item)" class="tips-btn">
        <img src="./img/prices.svg" class="iconfont" />
        价格走势
      </span>
    </a-popover>
  </span>
</template>

<script>
import echarts from 'echarts'
import { burialPoint } from '../burialPoint.services'

export default {
  name: 'Product',
  props: {
    item: {
      type: Object,
      default: () => []
    },
    type: {
      type: String
    },
    // 埋点点击区域类型
    bpType: {
      type: String
    },
    // 点击排序
    bpIndex: {
      type: Number
    },
    visible: {
      type: Boolean,
      default: false
    },
    queryParam: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chart: null,
      showChat: false,
      loadingLineChat: false,
      xAxisData: [],
      yAxisMax: 450,
      seriesData: []
    }
  },
  methods: {
    visibleChange(visible) {
      this.showChat = visible
      if (visible) {
        setTimeout(() => {
          this.drawLine()
        }, 200)
        // 价格走势 埋点
        let item = this.item
        burialPoint({
          event: '6',
          productArea: this.bpType || '', //页面区域  1:推荐位 2:列表位 3:对比产品
          productRank: this.bpIndex + 1 || '', //排序
          productName: item?.productName || '', // 产品名称
          productCode: item?.productCode || '' //产品代码
        })
      } else {
        this.chart.clear()
      }
    },
    mounted() {},
    getPriceTrends() {
      let item = this.item
      this.loadingLineChat = true
      this.$post('v2/freightFeeTrial/getPriceTrends', {
        ...this.queryParam,
        productCode: item?.productCode,
        totalRmbAmount: item.totalRmbAmount
      })
        .then(res => {
          this.loadingLineChat = false
          let resData = res || []
          let xAxisData = []
          let seriesData = []
          resData.forEach(item => {
            xAxisData.push(item.month)
            seriesData.push(Number(item.price) || 0)
          })
          this.yAxisMax =
            xAxisData.length > 0 ? parseInt(Math.max(...seriesData)) + 50 : 400
          this.xAxisData = xAxisData
          this.seriesData = seriesData

          // 更新图表数据
          setTimeout(() => {
            this.setDrawLineChartData()
          }, 200)
        })

        .catch(() => {
          this.loadingLineChat = false
        })
    },
    setDrawLineChartData() {
      let that = this
      let option = {
        xAxis: {
          data: that.xAxisData
        },
        yAxis: {
          max: that.yAxisMax || 450
        },
        series: [
          {
            data: that.seriesData
          }
        ]
      }
      this.chart.resize()
      this.chart.setOption(option)
    },
    drawLine() {
      let that = this
      const chartDom = document.getElementById(
        'line-chart' + this.type + this.item.productCode
      )
      if (this.chart) {
        this.chart.clear()
      } else {
        this.chart = echarts.init(chartDom)
      }

      // 绘制图表
      let option = {
        grid: {
          top: '5%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          formatter: '价格：{c} <br/> 时间： {b}'
        },
        xAxis: {
          type: 'category',
          color: '#767D91',
          axisLine: {
            lineStyle: {
              color: '#DEE1E4'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#767D91'
            }
          },
          data: ['x月']
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#DEE1E4'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#767D91'
            }
          }
        },
        series: [
          {
            data: that.seriesData,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#3561CC ', //改变折线点的颜色
                lineStyle: {
                  color: '#3561CC' //改变折线颜色
                },
                textStyle: {
                  color: '#ff0000' // 这里将文字颜色设为红色
                }
              }
            },
            label: {
              show: true,
              position: 'top'
            },
            // 渐变色
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(53,97,204,0.24)'
                },
                {
                  offset: 1,
                  color: ' rgba(53,97,204,0.00)'
                }
              ])
            },
            smooth: true
          }
        ]
      }
      setTimeout(() => {
        this.chart.resize()
        this.chart.setOption(option)
      }, 200)
    }
  }
}
</script>

<style lang="less" scoped>
.left-btn {
  display: flex;
  align-items: center;
  .tips-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .gap-line {
    margin: 0 10px;
    width: 0;
    height: 12px;
    border-left: 1px solid #c3cbd5;
  }
  .iconfont {
    margin-right: 3px;
    width: 14px;
    height: 14px;
  }
  .order-btn {
    height: 32px;
  }
}
.label {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 12px;
  color: #767d91;
  line-height: 12px;
  font-weight: 400;
  .tips-btn {
    margin-left: 3px;
    width: 16px;
    height: 16px;
  }
}
.line-chart {
  margin-top: 16px;
  width: 370px;
  height: 260px;
}
.line-chart-tit {
  font-size: 16px;
  color: #323643;
  text-align: left;
  line-height: 20px;
  font-weight: bold;
}
</style>
