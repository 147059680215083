<template>
  <div>
    <div class="recommend-tit-box">
      <div class="recommend-tit">行业创新解决方案</div>
    </div>
    <div class="featured-product">
      <div class="left-box">
        <div
          class="left-item"
          @mouseover="changeContentItem(index)"
          :class="{ active: tabValue == index }"
          v-for="(item, index) in servicesList"
          :key="index"
        >
          <i class="iconfont" :class="item.icon"></i>
          <div class="tit-box">
            <div class="name">{{ item.name }}</div>
            <div class="des">{{ item.des }}</div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <!-- <div class="content-top">
          <div class="content-name">{{ servicesList[tabValue].content }}</div>
          <a
            class="content-link"
            :href="servicesList[tabValue].url"
            target="_blank"
          >
            立即体验 <i class="iconfont iconyoujiantou"></i>
          </a>
        </div> -->
        <div
          class="content-img-box"
          :class="servicesList[tabValue].url ? ' pointer' : ''"
          :style="{ 'background-image': `url(${servicesList[tabValue].img})` }"
        >
          <a-popover placement="top" v-if="this.$store.state.username">
            <template slot="content">
              <Customer />
            </template>
            <div
              v-if="
                (tabValue == 2 || tabValue == 3) && this.$store.state.username
              "
              class="custom-button"
            >
              联系销售咨询 <i class="iconfont iconzixunxiaoshou"></i>
            </div>
          </a-popover>
          <div
            v-if="
              (tabValue == 2 || tabValue == 3) && !this.$store.state.username
            "
            class="custom-button"
          >
            联系销售咨询 <i class="iconfont iconzixunxiaoshou"></i>
          </div>
          <div
            v-if="tabValue == 0 || tabValue == 1"
            @click="goDetailUrl"
            class="custom-button"
          >
            立即体验<i class="iconfont iconlijitiyanjiantou"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Customer from '@v/index/customer/Customer'

export default {
  // name: 'FeaturedServices',
  data() {
    return {
      tabValue: 0,
      servicesList: [
        {
          icon: 'iconguijidingyue',
          name: '轨迹定制',
          des: '个性化订阅订单物流轨迹',
          url: 'https://b.4px.com/dist/trajectorySubscribe',
          content: '特色功能标题文案特色功能标题文案',
          img: require('../../../assets/featured/f_1.svg')
        },
        {
          icon: 'iconERPduijie',
          name: '系统对接',
          des: '一键对接递四方物流API服务',
          content: '特色功能标题文案特色功能标题文案',
          url: 'https://open.4px.com',
          img: require('../../../assets/featured/f_2.svg')
        },
        {
          icon: 'iconfenduanwuliu',
          name: '分段物流',
          des: '跨境电商物流生态圈新模式',
          content: '特色功能标题文案特色功能标题文案',
          url: '',
          img: require('../../../assets/featured/f_3.svg')
        },
        {
          icon: 'iconyijiandaifa',
          name: '一件代发',
          des: '一站式全链路仓储发货服务',
          content: '特色功能标题文案特色功能标题文案',
          url: '',
          img: require('../../../assets/featured/f_4.svg')
        }
      ]
    }
  },
  components: {
    Customer
  },
  created() {},
  mounted() {},
  methods: {
    changeContentItem(index) {
      this.tabValue = index || 0
    },
    // 跳转到响应的链接
    goDetailUrl() {
      let url = this.servicesList[this.tabValue].url
      if (url) {
        window.open(url)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.recommend-tit {
  &::before {
    margin-left: -32px;
    transform: translate(-100%, 0px) rotate(180deg);
  }
}
.featured-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .left-box {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    // width: 25%;
    width: calc(25% - 12px);
  }
  .left-item {
    margin-bottom: 12px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    height: 64px;
    cursor: pointer;
    transition: all 0.2s;
    animation: all 0.2s;
    &:hover {
      transform: scale(1.01);
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    .iconfont {
      margin-right: 16px;
      font-size: 36px;
    }

    .name {
      font-size: 16px;
      color: #323643;
      line-height: 16px;
      font-weight: bold;
    }
    .des {
      margin-top: 8px;
      font-size: 12px;
      color: #545d75;
      letter-spacing: 0.17px;
      line-height: 12px;
      font-weight: 400;
    }
  }
  .active {
    background: #3960df;
    box-shadow: 0 0 12px 0 rgba(154, 177, 255, 0.58);
    border-radius: 8px;

    .name,
    .des,
    .iconfont {
      color: #fff !important;
    }
  }
  .content-box {
    // padding: 24px;
    padding: 0px;
    height: 292px;
    box-sizing: border-box;
    width: calc(75% - 8px);
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    .content-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 18px;
      margin-bottom: 15px;
    }

    .content-img-box {
      position: relative;
      width: 100%;
      // height: 212px;
      height: 292px;
      overflow: hidden;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .pointer {
      cursor: pointer;
    }
    .content-img {
      width: 100%;
      // height: 212px;
      height: 292px;
      transition: all 0.3s;
      animation: all 0.3s;
      &:hover {
        position: relative;
      }
    }
  }
}

.custom-button {
  padding: 15px 0;
  color: #3960df;
  font-size: 18px;
  position: absolute;
  left: 60px;
  bottom: 30px;
  cursor: pointer;
  .iconfont {
    margin-left: 2px;
    font-size: 20px;
    color: #3960df;
    vertical-align: middle;
  }
}

@media screen and (max-width: 1680px) {
  .custom-button {
    left: 55px;
  }
}
@media screen and (max-width: 1600px) {
  .custom-button {
    left: 50px;
    font-size: 18px;
  }
}
@media screen and (max-width: 1560px) {
  .custom-button {
    left: 45px;
    font-size: 18px;
  }
}
// 小于1440的窗口
@media screen and (max-width: 1440px) {
  .custom-button {
    left: 35px;
    font-size: 17px;
  }
}

// 小于1440的窗口
@media screen and (max-width: 1380px) {
  .custom-button {
    left: 30px;
    font-size: 16px;
  }
}

// 小于1440的窗口
@media screen and (max-width: 1340px) {
  .custom-button {
    left: 15px;
    font-size: 16px;
  }
}
</style>
