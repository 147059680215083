<!--
 * @Description: 未开通、审核驳回、已关闭服务
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 10:17:46
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-12-26 18:24:21
-->
<i18n>
{
  "zh": {
    "open": "立即开通",
    "show": "服务介绍",
    "brand": "申请品牌",
    "sfail": "审核不通过（驳回）",
    "sclose": "已关闭",
    "sunopen": "未开通",
    "cert": "去认证"
  },
  "en": {
    "open": "Open Now",
    "show": "Introduction",
    "brand": "Apply for Brand",
    "sfail": "The audit failed (rejected)",
    "sclose": "Closed",
    "sunopen": "Unopened",
    "cert": "Go to certification"
  },
  "de": {
    "open": "Jetzt öffnen",
    "show": "Beschreibung der Dienstleistung",
    "brand": "Bewerben Sie sich für eine Marke",
    "sfail": "Überprüfung nicht genehmigt (abgelehnt)",
    "sclose": "Geschlossen",
    "sunopen": "Nicht geöffnet",
    "cert": "Weiter zur Zertifizierung"
  },
  "ja": {
    "open": "現在公開中",
    "show": "サービス内容",
    "brand": "ブランドに応募する",
    "sfail": "レビューが承認されなかった（却下された）",
    "sclose": "クローズド",
    "sunopen": "未開封",
    "cert": "認証のページへ"
  }
}
</i18n>
<template>
  <div
    class="business-unopen"
    :class="info ? 'brand-off-' + info.brandCode : ''"
  >
    <div class="b-name">{{ $t(info.brandCode + '-full') }}</div>
    <div class="b-title">{{ statusText }}</div>
    <!-- 审核驳回： 驳回原因	-->
    <div v-if="info.auditRemark" class="audit-remark">
      {{ info.auditRemark || '' }}
    </div>

    <div class="b-btn" v-if="info.status != 4 && info.brandCode != 'GRS'">
      <a href="JavaScript:void(0)" @click="onOpen" v-if="info.brandEnabled">
        {{ $t('open') }}
      </a>
      <a-divider type="vertical" v-if="info.brandEnabled" />
      <a @click="onOPenDesc">{{ $t('show') }}</a>
    </div>

    <!-- 品牌关闭重新续约开通 -->
    <div
      class="b-btn"
      v-if="
        info.status == 4 && info.customerStatus == 2 && info.brandCode != 'GRS'
      "
    >
      <a href="JavaScript:void(0)" @click="renewalFun">
        {{ $t('ct_ap_003') }}
      </a>
    </div>
    <!-- && info.brandCode != 'GRS' -->
    <OpenWrapper ref="openWrapper" :info="info" />
    <!-- 新逻辑 重新续约开通 -->
    <ContractSigning
      :info="info"
      type="mainReapply"
      titleI18nName="renewTitle"
      :visible="renewalVisible"
      @cancel="renewalVisible = false"
      @ok="openSigning"
      v-if="renewalVisible"
    />
  </div>
</template>

<script>
import OpenWrapper from './OpenWrapper'
import ContractSigning from '../newStatus/ContractSigning'

export default {
  name: 'business-unopen',
  props: {
    info: Object
  },
  data() {
    return {
      renewalVisible: false
    }
  },
  components: {
    OpenWrapper,
    ContractSigning
  },
  computed: {
    statusText() {
      return this.info.status == 3
        ? this.$t('sfail')
        : this.info.status == 4
        ? this.$t('sclose')
        : this.$t('sunopen')
    }
  },
  methods: {
    // 重新申请合同
    renewalFun() {
      if (this.info && this.info?.brandCode == 'TRS') {
        this.$confirm({
          content: this.$t('ct_ap_005'),
          okText: this.$t('iKnow'),
          cancelText: this.$t('close'),
          onOk() {}
        })
      } else {
        this.renewalVisible = true
      }
    },
    openSigning() {
      this.$root.bus.$emit('reload-brand')
      this.renewalVisible = false
      this.$store.state.contractTipsModal = true
    },
    onOPenDesc() {
      this.$refs['openWrapper'].visibleIntrduce = true
    },
    /**
     * @description: 开通服务
     * @param {type}
     * @return {type}
     */
    async onOpen() {
      this.$refs['openWrapper'].onOpen()
    },
    /**
     * @description: 跳转至 我的账户，查看账户详情
     * @param {type}
     * @return {type}
     */
    onCheckDetail() {
      this.$router.push({ name: 'dssAccount' })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../../styles/theme.less');
.business-unopen {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 290px;
  border-radius: 4px;
  background-size: 100% 100%;
  user-select: none;
  .audit-remark {
    margin-top: 24px;
    text-align: center;
  }
  .b-name {
    display: inline-block;
    padding: 17px 0 0 17px;
    font-weight: 400;
    color: #333333;
  }
  .b-title {
    font-size: 28px;
    font-family: AlibabaPuHuiTiM;
    color: #999999;
    line-height: 38px;
    margin-top: 72px;
    text-align: center;
  }
  .b-more {
    cursor: pointer;
    font-size: 12px;
    color: #999;
    float: right;
    margin-right: 16px;
    margin-top: 17px;
  }
  .b-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    a {
      line-height: 1;
      width: 100%;
      text-align: center;
      color: #333333;
      transition: color 0.3s;
      &:hover {
        color: @prmaryColor;
      }
    }
  }
  .ant-divider,
  .ant-divider-vertical {
    height: 23px;
  }
}
.brand-off-DSS {
  background-image: url('../images/dss@2x.png');
  // background: url('../images/css_sprites.png') -506px -10px;
}
.brand-off-FB4 {
  background-image: url('../images/fb4@2x.png');
}
.brand-off-GRS {
  background-image: url('../images/grs@2x.png');
}
.brand-off-TRS {
  background-image: url('../images/trs@2x.png');
}
</style>
