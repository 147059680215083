/*
 * @Description: 用于FB4 request。原因在于商家、直发、FB4每个应用的 报文 不一致 故需要单独
 * @Autor: S9637/chifuk
 * @Date: 2022-03-12 17:20:22
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2022-03-12 17:20:22
 */
import axios from 'axios'
const qs = require('qs')
import { message } from 'ant-design-vue'
import { LINKS } from '@/const/index'
const isPrd = process.env.NODE_ENV === 'production'
const baseURL = isPrd ? LINKS['ORDER'] : 'api/'

const fb4Request = axios.create({
  baseURL,
  timeout: 30 * 1000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

fb4Request.interceptors.request.use(
  config => {
    if (
      config.method == 'post' &&
      config.headers['Content-Type'] ==
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = qs.stringify(config.data, { indices: false })
    }
    config.headers['session'] = localStorage.getItem('session_ORDER')
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

fb4Request.interceptors.response.use(
  res => {
    // 会话过期
    if (isPrd) {
      if (res && res.request && res.request.responseURL.indexOf('sso') > -1) {
        message.warning('登录过期，请重新登录~')
        sessionStorage.clear()
        location.href = res.request.responseURL
      }
    }
    if (res && res.status === 200) {
      return res.data
    }
    return res
  },
  err => {
    const status = err && err.response ? err.response.status : ''
    switch (status) {
      case 400:
        message.warning('400~错误请求！')
        break
      case 401:
        message.warning('401~未授权，请重新登录！')
        location.href = '/502'
        break
      case 404:
        message.warning('404~')
        break
      case 408:
        message.warning('408~请求超时！')
        break
      case 500:
        message.warning('500~服务器异常！')
        break
      default:
        break
    }
    if (err && err.message.indexOf('timeout') > -1) {
      message.warning('请求超时~，请稍后重试！')
    }
    return Promise.reject(err)
  }
)

export { fb4Request }
