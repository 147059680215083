<i18n>
{
  "zh": {
    "table1": "排行",
    "table2": "产品名称",
    "table3": "妥投率"
  },
  "en": {
     "table1": "Rangking",
    "table2": "Product name",
    "table3": "Appropriate investment rate"
  },
  "de": {
     "table1": "Rangking",
    "table2": "Product name",
    "table3": "Appropriate investment rate"
  },
  "ja": {
     "table1": "Rangking",
    "table2": "Product name",
    "table3": "Appropriate investment rate"
  }
}
</i18n>

<template>
  <div>
    <a-empty
      v-if="top7.length == 0"
      style="width: 100%; height: 270px; padding-top: 154px"
    />
    <a-table
      v-else
      :style="{ 'margin-top': showType ? '0px' : '46px' }"
      :columns="columns"
      :data-source="top7"
      :pagination="false"
    >
      <span
        class="rangking"
        :style="{
          background: text == 2 ? '#868686' : text == 3 ? '#9D837D' : '',
        }"
        v-if="text < 4 && showType"
        slot="rangking"
        slot-scope="text"
        >{{ text }}</span
      >
      <span v-else class="rangking-other" slot="rangking" slot-scope="text">{{
        text
      }}</span>
    </a-table>
  </div>
</template>

<script>
import { rankingColumns } from '../dataCenter.data.js'

export default {
  name: 'RankingTable',
  data() {
    let getColumns = rankingColumns(this)
    return {
      columns: getColumns,
    }
  },
  props: {
    top7: {
      type: Array,
      default: [],
    },
    showType: {
      type: String,
      default: '',
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.rangking {
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  background: #faaa1c;
}
.rangking-other {
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  border-radius: 50%;
}
</style>
