<!--
 * @Description: 营销位
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 17:47:32
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-11 10:12:01
-->
<template>
  <div class="ad">
    <a-carousel :autoplay="true">
      <img
        style="overflow:hidden;height:350px"
        @click="e => onClickImg(item, index)"
        :data-baidu-pv="
          '首页，运营模块，查看运营广告(' +
            item.name +
            '-' +
            $store.state.username +
            ')'
        "
        :key="item.code"
        :src="item.imgUrl"
        v-for="(item, index) in imgList"
        alt=""
      />
    </a-carousel>
  </div>
</template>

<script>
export default {
  name: 'ad',
  data() {
    return {
      imgList: []
    }
  },
  created() {
    this.getAd()
  },
  methods: {
    getAd() {
      this.$show()
      this.$post(`v2/content/advertiseList`, {
        pageNo: 0,
        pageSize: 3,
        status: 6,
        isPaging: true,
        systemName: '商家中心'
      })
        .then(res => {
          this.$hide()
          this.imgList = res.data || []
        })
        .catch(() => {
          this.$hide()
        })
    },
    onClickImg(item, index) {
      window.open(item.redirectUrl, '_blank')
    }
  }
}
</script>

<style lang="less">
.ad {
  width: 340px;
  height: 332px;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  .ant-carousel {
    box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    height: 332px;
    overflow: hidden;
    box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    img {
      height: 332px;
    }
  }
  .ant-carousel .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #d7e0e5;
  }
  .ant-carousel .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #065cf3;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: 22px;
  }
}
</style>
