<!--
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-08-27 18:14:35
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-08-05 20:52:59
-->
<template>
  <a-config-provider
    placement="topLeft"
    :locale="locale"
    :autoInsertSpaceInButton="false"
  >
    <div id="app">
      <!-- loading -->
      <a-spin
        class="app-level-loading"
        :spinning="loading"
        :tip="$t('loading')"
      >
      </a-spin>
      <!-- content -->
      <layout-index :showSlider="showSlider">
        <router-view />
      </layout-index>
      <!-- 在线客服 -->
      <NewCustom />
      <!-- 判断主体是否一致 -->
      <!-- <subjectNotUnified
        @cancel="subjectVisible = false"
        :visible="subjectVisible"
      /> -->
      <!-- 新功能指引：直发 -->
      <DssGuide
        v-if="
          showDSSGuide &&
            orSubjectVisible == false &&
            !$store.getters.isOverSeas
        "
      />
      <!-- 新版首页更新提示 -->
      <!-- <newHomePageDialog /> -->
    </div>
  </a-config-provider>
</template>

<script>
import DssGuide from '@/guide/dss/DssGuide'
import en from '@/i18n/locales/title/en.json'
import zh from '@/i18n/locales/title/zh.json'
import { getDomainWorld } from '@/utiles/index'
import IM from '@c/IM/IM'
import NewCustom from '@c/NewCustom/index'
import LayoutIndex from '@v/layout/Index'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import Cookies from 'js-cookie'
import moment from 'moment'
import 'moment/locale/zh-cn'
import newHomePageDialog from '@v/index/newIndexComponents/newHomePageDialog'

import sensors from 'sa-sdk-javascript'

moment.locale('zh-cn')
// import subjectNotUnified from '@v/index/businessKanban/newStatus/subjectNotUnified'
import { loginMicroApp } from './mirco'

let SECTIONS_PERMISSON = null
export default {
  name: 'App',
  data() {
    return {
      subjectVisible: null,
      orSubjectVisible: null
    }
  },
  components: {
    LayoutIndex,
    IM,
    NewCustom,
    newHomePageDialog,
    DssGuide
    // subjectNotUnified,
  },
  computed: {
    locale() {
      const lang =
        Cookies.get('lang', { path: '', domain: document.domain }) ||
        this.$store.state.locale
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$i18n.locale = lang
      moment.locale(lang == 'zh' ? 'zh-cn' : 'en')
      return lang == 'zh' ? zhCN : enUS
    },
    loading() {
      return this.$store.state.loading
    },
    showSlider() {
      // 首页无侧边栏 'iframeView',
      return (
        [
          'index',
          'openProgress',
          'productDetails',
          'applyContract',
          'contractSigning',
          'openSuccess',
          // 'unauthorized',没有权限，还能查看当前的菜单
          'oceanWaybillDetails',
          'airWaybillDetails',
          'index_settings'
        ].indexOf(this.$route.name) == -1
      )
    },
    imShow() {
      const urls = this.$store.state.imUrls || {}
      let hasFind = false
      for (let k in urls) {
        if (urls[k]) hasFind = true
      }
      return hasFind
    },
    showDSSGuide() {
      // 直发新版指引
      return this.$store.state.username && !localStorage.getItem('guide')
        ? true
        : false
    }
  },
  watch: {
    $route() {
      if (process.env.VUE_APP_LOGIN != 1) return
      this.$hide()
      const routename = this.$route.name
      const isIgnore = this.$route.meta ? this.$route.meta.ignore : false
      if (
        !routename ||
        isIgnore ||
        ['unauthorized', 'iframeView'].indexOf(routename) > -1
      )
        return
      // 设置 title
      // document.getElementsByTagName('title')[0].innerHTML = this.$t(routename)
      // 如果未开通Dss
      if (
        !this.$store.getters.getDSSOpen &&
        this.$store.state.brandStatus.DSS != 4 &&
        ['orderAging'].indexOf(routename) > -1
      ) {
        this.$router.push({
          name: 'unauthorized',
          params: { text: '请先开通DSS直发业务' }
        })
        return
      }
      // 如果未开通fb4,数据模块无权访问
      if (
        !this.$store.getters.getFB4Open &&
        this.$store.state.brandStatus.FB4 != 4 &&
        ['storeSummary', 'storeDetail', 'storeTime', 'outAnalyze'].indexOf(
          routename
        ) > -1
      ) {
        if (
          !this.$store.getters.getDSSOpen &&
          this.$store.state.brandStatus.DSS != 4 &&
          ['storeSummary'].indexOf(routename) > -1
        ) {
          this.$router.push({
            name: 'unauthorized',
            params: { text: '请先开通FB4订单履约服务' }
          })
        } else if (
          (this.$store.getters.getDSSOpen ||
            this.$store.state.brandStatus.DSS == 4) &&
          ['storeSummary'].indexOf(routename) > -1
        ) {
          this.$router.push({
            name: 'orderAging'
          })
        }

        return
      }

      // 如果未开通grs, 无法订购套餐
      if (
        !this.$store.getters.getGRSOpen &&
        ['grsServices'].indexOf(routename) > -1
      ) {
        this.$router.push({
          name: 'unauthorized',
          params: { text: '请先开通GRS退件服务' }
        })
        return
      }

      // 直接打开某个页面进入会存在 SECTIONS_PERMISSON 为空，此时需要取缓存
      if (!SECTIONS_PERMISSON)
        SECTIONS_PERMISSON = JSON.parse(
          decodeURIComponent(sessionStorage.getItem('mnu'))
        )

      if (SECTIONS_PERMISSON) {
        // 排除不需要权限页
        if (this.$store.state.ignore.indexOf(routename) == -1) {
          let hasPermission = false

          // 特殊：处理仅本身是单纯菜单，但却显示为父子结构的菜单
          let permissionRoute = null
          this.$store.state.oneMenuWithSub.forEach(x => {
            if (x && x.indexOf(routename) > -1) {
              permissionRoute = x[0]
            }
          })

          for (let k in SECTIONS_PERMISSON) {
            if (
              SECTIONS_PERMISSON[k].indexOf(routename) > -1 ||
              SECTIONS_PERMISSON[k].indexOf(permissionRoute) > -1
            ) {
              hasPermission = true
              break
            }
          }

          if (!hasPermission) this.$router.push({ name: 'unauthorized' })
        }
      }
    }
  },
  beforeCreate() {
    loginMicroApp('DSS')
  },
  created() {
    this.sensorsData()
  },
  mounted() {
    this.$i18n.locale =
      Cookies.get('lang', { path: '', domain: document.domain }) || 'zh'
    this.$store.state.locale =
      Cookies.get('lang', { path: '', domain: document.domain }) || 'zh'
    this.redirectHttps()
    this.getAccountType()
    this.getArivable()
    // this.checkSubject()
    // 注册 全局事件
    // 使用 this.$root.bus.$on('onresize', cb)
    window.onresize = _.debounce(this.onResizeHandler, 150)
    this.getBrand()
    this.getAccountRegStatus()
  },
  methods: {
    // 获取注册认证账号状态
    getAccountRegStatus() {
      this.$get('v2/homepage/schedule').then(res => {
        res && this.$store.commit('updateAccountRegStatus', res)
      })
    },
    // 神策埋点
    sensorsData() {
      let project = 'default' //测试项目
      if (getDomainWorld() === 'prod') {
        project = 'production' //生产项目
      } else {
        project = 'default'
      }
      sensors.init({
        server_url: 'https://ubdm-api.i4px.com/sa?project=' + project,
        is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
        use_client_time: true,
        send_type: 'beacon',
        heatmap: {
          //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
          clickmap: 'default',
          collect_tags: {
            div: true
          },
          // 通过 collect_tags 配置是否开启 div 的全埋点采集，默认不采集。如需开启 ，配置  collect_tags 参数如下：
          //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
          scroll_notice_map: 'default'
        }
      })
      sensors.quick('autoTrack', {
        platform: 'Web'
      }) //用于采集 $pageview 事件。
      sensors.setProfile({ platform_type: 'Web' })
    },
    // 获取全局v2/brand/info接口，
    // 目前的问题是session失效后退出登录，
    // 进来跳转到首页，没等v2/brand/info接口请求完，
    // 已经重定向到历史页面，导致权限判断有误
    getBrand() {
      this.$get('v2/brand/info')
        .then(res => {
          this.brandList = res || []
          // 排序brandCode: DSS、FB4、TRS、GRS
          const GRS = this.brandList[2]
          const TRS = this.brandList[3]
          this.brandList[2] = TRS
          this.brandList[3] = GRS

          // FB4 DSS TRS GRS 如果未开通服务，则导航栏显示下拉菜单【开通服务、服务介绍】
          const nav = [...this.$store.state.navModule.items]
          // 是否为试单|正常用户
          const isTryAccount = res.some(e => e.triedCustomer)
          //  正常用户
          if (!isTryAccount) {
            this.$store.state.isTryAccount = false
          }
          // 埋点
          const pvMap = {
            FB4: {
              order: '首页，下单，海外仓下单',
              pay: '首页，充值，海外仓充值'
            },
            GRS: {
              order: '首页，下单，退件下单',
              pay: '首页，充值，退件充值',
              pack: '首页，套餐，退件套餐'
            },
            DSS: {
              order: '首页，下单，直发下单'
            },
            TRS: {
              order: '首页，下单，转运下单',
              pay: '首页，充值，转运充值'
            }
          }
          this.brandList = this.brandList.map(e => {
            const pv = pvMap[e.brandCode]
            return {
              ...e,
              pv: pv
            }
          })
          this.brandList.forEach(item => {
            // 未开通服务
            if (item.status != 2 && item.status != 4) {
              if (item.brandCode == 'DSS') {
                nav[1].sub = [{ name: 'open' }, { name: 'desc' }]
              }
              if (item.brandCode == 'FB4') {
                nav[2].sub = [{ name: 'open' }, { name: 'desc' }]
              }
              if (item.brandCode == 'TRS') {
                nav[3].sub = [{ name: 'open' }, { name: 'desc' }]
              }
              if (item.brandCode == 'GRS') {
                nav[4].sub = [{ name: 'open' }, { name: 'desc' }]
              }
            } else {
              if (item.brandCode == 'DSS') {
                loginMicroApp('DSS')
                delete nav[1].sub
              }
              if (item.brandCode == 'FB4') {
                loginMicroApp('FB4')
                loginMicroApp('ORDER')
                delete nav[2].sub
              }
              if (item.brandCode == 'TRS') {
                loginMicroApp('TRS')
                delete nav[3].sub
              }
              if (item.brandCode == 'GRS') {
                loginMicroApp('GRS')
                delete nav[4].sub
              }
            }
            // 是否开通 dss
            if (item.brandCode == 'DSS') {
              const usr = this.$store.getters.getUserInfo
              usr.isDSSOpen = item.status == 2
              this.$store.state.brandStatus.DSS = item.status
              this.$store.commit('updateUserInfo', usr)
            }
            // 是否开通 fb4
            if (item.brandCode == 'FB4') {
              const usr = this.$store.getters.getUserInfo
              usr.isFB4Open = item.status == 2
              this.$store.state.brandStatus.FB4 = item.status
              this.$store.commit('updateUserInfo', usr)
            }
            // 是否开通 GRS
            if (item.brandCode == 'GRS') {
              const usr = this.$store.getters.getUserInfo
              usr.isGRSOpen = item.status == 2
              this.$store.state.brandStatus.GRS = item.status
              this.$store.commit('updateUserInfo', usr)
            }
            // 是否开通 TRS
            if (item.brandCode == 'TRS') {
              const usr = this.$store.getters.getUserInfo
              usr.isTRSOpen = item.status == 2
              this.$store.state.brandStatus.TRS = item.status
              this.$store.commit('updateUserInfo', usr)
            }
          })
          sessionStorage.setItem('brandList', JSON.stringify(this.brandList))
          this.$store.commit('updateNavItems', nav)
          this.$root.bus.$emit('getBrand')
          if (Cookies.get('successUrl')) {
            let successUrl = Cookies.get('successUrl')
            Cookies.remove('successUrl')
            location.href = successUrl
          }
        })
        .catch(() => {})
    },
    // 判断主体是否一至
    checkSubject() {
      const subjectVisible = sessionStorage.getItem('subjectVisible')
      if (subjectVisible) {
        return false
      }
      this.$post('v2/common/isSubjectAccord').then(res => {
        if (res == false) {
          sessionStorage.setItem('subjectVisible', true)
          this.subjectVisible = true
          this.orSubjectVisible = true
        } else {
          this.subjectVisible = false
          this.orSubjectVisible = false
        }
      })
    },
    redirectHttps() {
      // 如果访问的域名不是 http, 重定向到 https
      // 用于解决 直发登录问题
      if (getDomainWorld() === 'prod' && location.protocol === 'http:') {
        location.href = 'https://b.4px.com'
      }
    },
    onResizeHandler() {
      this.$root.bus.$emit('onresize')
      this.$nextTick(() => {
        // 确定是否选用使用滚动表格
        const cnt = document.querySelector('.index-content')
        if (cnt) {
          this.$store.state.needScrollTable =
            cnt.getBoundingClientRect().width < 1241
        }
      })
    },
    /**
     * @description: 获取账户类型：主账号 || 子账号
     * @param {type}
     * @return {type}
     */
    getAccountType() {
      this.$get('v2/common/isMainAccount').then(res => {
        this.$store.commit('setIsmainAccount', res ? 1 : 0)
      })
    },
    /**
     * @description: 获取可访问客服
     * @param {*}
     * @return {*}
     */
    getArivable() {
      let hasValue = false
      const urls = this.$store.state.imUrls || {}
      for (let k in urls) {
        if (urls[k]) hasValue = true
      }
      if (!hasValue) {
        this.onIMType('DSS')
        this.onIMType('FB4')
        this.onIMType('GRS')
        this.onIMType('TRS')
      }
    },
    /**
     * @description:
     * @param {*} brand
     * @return {*}
     */
    onIMType(brand) {
      this.$get(`v2/resource/udesk/data/${brand}`).then(res => {
        let markCode = ''
        let crmCode = ''
        let c_owner = ''
        let c_owner_group = ''
        // 增加字段
        let nonce = ''
        let signature = ''
        let timestamp = ''
        let web_token = ''

        if (res) {
          markCode = res.markCode ? res.markCode : ''
          crmCode = res.crmCode ? res.crmCode : ''
          c_owner = res.udeskCode ? res.udeskCode : ''
          c_owner_group = res.udeskGroupCode ? res.udeskGroupCode : ''
          // 增加字段
          nonce = res.nonce ? res.nonce : ''
          signature = res.signature ? res.signature : ''
          timestamp = res.timestamp ? res.timestamp : ''
          web_token = res.web_token ? res.web_token : ''
        }
        if (res && res.isShow != '0') {
          if (brand === 'FB4') {
            this.$store.state.imUrls[
              brand
            ] = `https://1851023.s4.udesk.cn/im_client/?web_plugin_id=33111&c_owner_group=34750&c_owner=${c_owner}&nonce=${nonce}&signature=${signature}&timestamp=${timestamp}&web_token=${web_token}&c_name=${encodeURIComponent(
              `${markCode ? markCode : ''}`
            )}`
          } else if (brand === 'DSS') {
            this.$store.state.imUrls[
              brand
            ] = `https://4px.s2.udesk.cn/im_client/?web_plugin_id=4324&c_owner_group=${c_owner_group}&c_owner=${c_owner}&nonce=${nonce}&signature=${signature}&timestamp=${timestamp}&web_token=${web_token}&c_name=${encodeURIComponent(
              `${markCode ? markCode : ''}`
            )}`
          } else if (brand === 'GRS') {
            this.$store.state.imUrls[
              brand
            ] = `https://1851023.s4.udesk.cn/im_client/?web_plugin_id=33129&c_owner_group=35030&c_owner=${c_owner}&nonce=${nonce}&signature=${signature}&timestamp=${timestamp}&web_token=${web_token}&c_name=${encodeURIComponent(
              `${markCode ? markCode : ''}`
            )}`
          } else if (brand === 'TRS') {
            this.$store.state.imUrls[
              brand
            ] = `https://4px.s2.udesk.cn/im_client/?web_plugin_id=13877&c_owner=${c_owner}`
          }
        }
      })
    }
  },
  /**
   * @description: 自动定位左侧菜单栏展开状态、激活状态
   * @param {type}
   * @return {type}
   */
  updated() {
    const routeName = this.$route.name
    document.title = this.$i18n.locale === 'zh' ? zh[routeName] : en[routeName]
    if (routeName === this.lastRouteName) return // 避免重复执行
    this.$store.state.defaultSelectedKeys = [routeName]
    const store = this.$store.state
    const m = store.menuItems || []
    this.lastRouteName = routeName
    m.length > 0 &&
      m.some(item => {
        if (item.sub) {
          item.sub.some(s => {
            if (s.name === routeName) {
              store.defaultOpenKeys = [
                ...new Set([...store.defaultOpenKeys, item.name])
              ]
            }
          })
        } else {
          if (item.name === routeName) {
            store.defaultOpenKeys = [
              ...new Set([...store.defaultOpenKeys, item.name])
            ]
            return true
          }
          return false
        }
      })

    // 更新 nav bar 激活项
    // 1.mircor apps
    const nav = this.$store.getters.getNavItems
    const appTag = this.$route.meta ? this.$route.meta.tag : ''
    let has = false
    if (appTag) {
      nav.forEach(item => {
        item.active = false
        if (item.name === appTag) {
          item.active = true
          has = true
        }
      })
      if (has) {
        this.$store.commit('updateNavItems', nav)
        return
      }
    }
    // 2.normal
    const menu = sessionStorage.getItem('mnu')
    if (!has && menu) {
      const val = JSON.parse(decodeURIComponent(menu))
      if (val) {
        const nav = this.$store.getters.getNavItems
        let isFind = false
        for (let x in val) {
          if (val[x].indexOf(routeName) > -1) {
            nav.forEach(item => {
              item.active = false
              if (item.name == val[x][0]) {
                item.active = true
                isFind = true
              }
            })
            break
          }
        }
        if (isFind) this.$store.commit('updateNavItems', nav)
      }
    }
  }
}
</script>
<style lang="less">
.app-level-loading {
  position: fixed !important;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100000;
  background-color: hsla(0, 3%, 31%, 0.56);
  .ant-spin-dot {
    margin-top: 40vh;
  }
  .ant-spin-text {
    margin-top: 12px;
  }
}
.index-udesk {
  position: fixed;
  cursor: pointer;
  right: 0;
  top: 50%;
  width: 55px;
  height: 49px;
  background-color: #fff;
  border-radius: 49px 0 0 49px;
  background-repeat: no-repeat;
  background-position: 12px 8px;
  background-image: url('assets/common/customer@2x.png');
  background-size: 36px 36px;
  box-shadow: 0 3px 10px #d1cfcf;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 18px;
  font-weight: bold !important ;
}

.ant-notification-notice {
  padding: 16px !important ;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-left: 34px !important ;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 34px !important ;
}

.ant-popover-inner .ant-popover-buttons {
  text-align: center !important;
}
.ant-popover-buttons .ant-btn {
  height: 32px;
  line-height: 32px;
}
.ant-notification {
  width: 440px !important;
}
</style>
