<!--
 * @Description: 签署承诺函
 * @Autor: S9637/chifuk
 * @Date: 2021-04-13 11:24:54
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-04-21 19:44:25
-->
<i18n>
{
  "zh": {
   "article": "诚信承诺函",
    "read": "我已阅读《递四方物流服务条款》",
    "agree": "同意",
    "downfile": "全文下载",
    "check": "请勾选已阅读"
  },
  "en": {
    "agree": "Agree",
    "downfile": "Full Text Download",
    "article": "Integrity Commitment Letter",
    "read": "I have read the $lg;$lg;4px Logistics Terms of Service;$gt",
    "check": "Please tick has been read"
  },
  "de": {
    "article": "Brief zur Integritätsverpflichtung",
    "read": "Ich habe die Allgemeinen Geschäftsbedingungen von Delivery Logistics gelesen.",
    "agree": "Zustimmen",
    "downfile": "Den vollständigen Text herunterladen",
    "check": "Bitte ankreuzen, wenn Sie Folgendes gelesen haben"
  },
  "ja": {
    "article": "インテグリティ・コミットメント・レター",
    "read": "デリバリーロジスティクスの利用規約を読みました。",
    "agree": "同意",
    "downfile": "全文ダウンロード",
    "check": "お読みいただいた方はチェックを入れてください。"
  }
}
</i18n>
<template>
  <div class="sign-protocol">
    <a-modal
      v-model="commitmentModalVisible"
      centered
      width="740px"
      :closable="false"
      :maskClosable="false"
      class="commit-modal"
      @cancel="onClosed"
    >
      <div class="commit-box">
        <p class="name">{{ $t('article') }}</p>
        <div class="content">
          <ProtocolView />
        </div>
      </div>
      <template style="overflow: hidden" slot="footer">
        <a
          @click="downPDF"
          style="margin-left: 8px; float: left; line-height: 36px"
          >{{ $t('downfile') }}</a
        >
        <a-button
          style="float: right; margin-right: 8px"
          type="primary"
          @click="doAgree"
          :disabled="count > 0"
        >
          <p style="line-height: 36px">
            {{ $t('agree') }}
            <span style="padding: 0" v-if="!!count">({{ count }}s)</span>
          </p>
        </a-button>
        <a-checkbox style="line-height: 36px; float: right" v-model="hasRead">
          {{ $t('read') }}
        </a-checkbox>
      </template>
    </a-modal>
  </div>
</template>

<script>
import ProtocolView from './ProtocolView'
export default {
  name: 'sign-protocol',
  components: {
    ProtocolView
  },
  data() {
    return {
      timer: null,
      commitmentModalVisible: false,
      count: 5,
      hasRead: false
    }
  },
  mounted() {
    // 如果已经弹出，则不再重复
    if (!sessionStorage.getItem('visibleBoolean')) {
      this.getStatus()
    }
  },
  methods: {
    onClosed() {
      this.commitmentModalVisible = false
      sessionStorage.setItem('visibleBoolean', 1)
    },
    /**
     * @description: 是否显示诚信承诺函
     * @param {*}
     * @return {*}
     */
    getStatus() {
      this.$get('v2/retroactive/contract/visibleBoolean').then(res => {
        if (res) {
          this.commitmentModalVisible = true
          this.timer = setInterval(() => {
            if (this.count === 0) {
              this.timer = null
            } else {
              this.count--
            }
          }, 1000)
        }
      })
    },
    downPDF() {
      window.open('https://bss-fss.i4px.com/递四方物流服务条款.docx', '_blank')
    },
    doAgree() {
      if (this.hasRead) {
        this.$get('v2/retroactive/contract/saveUserAgrementRecord?type=1').then(
          () => {
            this.commitmentModalVisible = false
          }
        )
      } else {
        this.$message.warning(this.$t('check'))
      }
    }
  }
}
</script>

<style lang="less" scoped>
.commit-modal {
  .commit-box {
    .name {
      font-size: 24px;
      color: #20212d;
      font-weight: 700;
      padding-bottom: 6px;
      padding-top: 6px;
    }
    .content {
      overflow: hidden;
      height: 320px;
      border: 1px solid rgba(220, 220, 220, 1);
      font-size: 14px;
      color: #333;
      border-radius: 2px;
      padding: 10px;
      overflow-y: auto;
    }
  }
}
/deep/ .ant-modal-footer {
  overflow: hidden;
  padding-bottom: 24px;
}
</style>
