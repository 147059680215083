import { LINKS, sellerAcc } from '@const/index'
import { blobToExcel } from '@microDss/util/help'
import { handlerRules } from '@microDss/view/orderCore/singleOrder/rule'
import qs from 'qs'
import httpBuffer, { instance, httpBuffer2 } from './api.config'

const contentTypeForm = 'application/x-www-form-urlencoded;charset=UTF-8'
const defaultHeader = { headers: { 'Content-Type': contentTypeForm } }
const applicationJson = {
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }
}
const isProduction = process.env.NODE_ENV === 'production'
const path = isProduction ? LINKS['DSS'] : '/api'

/**
 * order api
 */
const Api = {
  codData: 'order/my_orders/management/query_cod_data_count', //代收货款数据看板
  checkPrint: 'order/my_orders/management/info_received/check_print', //是否欠费查询

  rule: 'order/my_orders/get_rule_and_extra_service',
  orderList: `order/my_orders/management/query_order_list`,
  queryForecastCounts: `order/personal_center/queryForecastCounts`,
  queryPrintCount: `order/personal_center/queryPrintCount`,
  orderCount: 'order/my_orders/management/order_counts',
  abnCount: 'order/personal_center/queryAbnormalCounts',
  queryArrearCount: 'order/personal_center/queryArrearCount',
  forecast: 'order/my_orders/management/draft/forecast', // 草稿变预报
  exportExcel: '/order/my_orders/management/export_excel', // 导出Excel
  remove: 'order/my_orders/management/remove', // 删除订单
  returnDraft: 'order/my_orders/management/returnDraft', // 回退草稿
  holdup: 'order/my_orders/management/holdup', // 拦截
  unholdup: 'order/my_orders/management/unholdup', // 取消拦截
  markPrint: 'order/my_orders/management/info_received/print_marking', // 标记已打印
  detail: 'order/my_orders/management/query_order_detail', // 详情
  track: 'order/valueadd/track/query', // 查询轨迹
  trackAppend: 'order/valueadd/track/getServiceInfo', // 查询轨迹

  detailAll: '/order/my_orders/management/order_form_page', //
  queryAnnouncement: 'order/personal_center/listNotice', // 查询公告
  queryAnnouncementDetail: 'order/personal_center/getNoticeDetai', // 查询详情
  saveOrder: 'order/my_orders/create/save_order', // 创建订单
  editDraftOrder: 'order/my_orders/create/edit_order', // 编辑草稿
  reforcastOrder: 'order/my_orders/create/reforcast_Order', // 重新预报
  insurance: 'valueadd/insurance/insuranceAttachIndex', // 投保信息
  getChildCargo: 'valueadd/insurance/getChildCargo',
  queryTemplate: 'order/my_orders/create/template_management/query_template', // 查询模板
  downloadStandardUrl: `${path}/order/my_orders/create/template_management/download_template`, // 标准模板下载
  uploadOrder: `order/my_orders/create/upload_order`, // 批量上传
  uploadTemp: `${path}/order/my_orders/create/template_management/upload_template`, // 模板上传
  orderComment: `order/my_orders/management/query_order_comment`, // 订单日志
  queryArrears: 'order/my_order/order_problem/queryArrearage', //' order/my_order/order_problem/list', // 欠费件
  queryNotifyInfolist: 'order/my_order/order_problem/queryNotifyInfolist', // 仓外通知件
  updateChangeStatus: 'order/my_order/order_problem/updateChangeStatus', // 仓外通知件标记为已读
  abnormalOrder: 'order/my_orders/management/query_order_problem', // 订单详情页的异常件
  exportFailOrder: isProduction
    ? 'my_orders/export/failOrder'
    : 'order/my_orders/export/failOrder', // 批量下单导出失败数据
  abnormalCount: 'order/my_order/order_problem/problem_counts', // 异常件数量
  fileUpLoad: `${path}/order/my_order/order_problem/fileUpLoad`, // 附件上传
  orderUpload: `${path}/order/my_orders/orderAccessoryUpLoad`, // 附件上传
  saveNotifyLog: 'order/my_order/order_problem/saveNotifyLog', // 仓外回复
  abnormalityReply: 'order/my_order/order_problem/abnormalityReply', // 仓内回复
  batchAbnormalityReply: 'order/my_order/order_problem/batchAbnormalityReply', // 仓内 批量回复
  saveBatchNotifyLogs: 'order/my_order/order_problem/saveBatchNotifyLogs', // 仓外 批量回复
  innerExport: `${path}/order/my_order/order_problem/export_excel?abnIds=`, // 仓内 导出
  outerExport: `${path}/order/my_order/order_problem/exportNotifyExcel`, // 仓外 导出
  arrearsExport: `${path}/order/my_order/order_problem/exportArrearageExcel`, // 欠费件 导出
  outTempl: 'order/my_order/order_problem/getNotifyHelp', // 仓外模板
  innerTempl: 'order/my_order/order_problem/getAbnormalityHelp', // 仓内模板
  query_document_order_list:
    'valueadd/document/management/query_document_order_list', // 单据管理
  downloadSigneCertificate: `${path}/valueadd/document/management/downloadSigneCertificate`, // 下载签收证明
  downloadShippingCertificate: `${path}/valueadd/document/management/downloadShippingCertificate`, // 下载发运证明,
  downloadServiceCertificate: `${path}/valueadd/document/management/downloadServiceCertificate`, // 下载服务商签收证明
  queryInsurance: 'valueadd/insurance/queryInsurance', // 保险list
  buyInsurance: 'valueadd/insurance/buyInsurance', // 购买保险
  saveInsuranceAttach: 'valueadd/insurance/saveInsuranceAttach',
  insuranceExport: `${path}/valueadd/insurance/export_excel`, // 安全投保 导出Excel
  everyday_query:
    'order/finance_management/my_bill/customer_amount_change/everyday_query', // 我的钱包 每日费用
  customer_amount_change:
    'order/finance_management/my_bill/customer_amount_change/query', // 我的钱包 账户变动
  untapped: 'order/finance_management/coupon/untapped/query', // 我的钱包 未使用的优惠券
  inuse: 'order/finance_management/coupon/inuse/query', // 我的钱包 使用中的优惠券
  used: 'order/finance_management/coupon/used/query', // 我的钱包 已使用的优惠券
  expired: 'order/finance_management/coupon/expired/query', // 我的钱包 已过期的优惠券
  activeCoupon: 'order/finance_management/coupon/active', // 我的钱包 激活优惠券
  couponDetail: 'order/finance_management/coupon/detail/query', // 我的钱包 查询优惠劵抵扣的费用明细
  get_remaining_sum: 'order/finance_management/payonline/get_remaining_sum', // 我的钱包 余额
  pay: '/order/finance_management/payonline/recharge', // 充值
  queryElectronicBillList:
    'order/finance_management/electronic_bill/queryElectronicBillList', // 我的钱包 电子账单
  exportEb: `${path}/order/finance_management/electronic_bill/exportElectronicBill`, // 我的钱包 电子账单导出
  expense_detail: 'order/finance_management/my_bill/expense_detail/query', // 我的钱包 费用明细
  expense_detailExport: `${path}/order/finance_management/my_bill/expense_detail/export`, // 我的钱包 导出

  oracleAbnormal: `order/my_order/old_order_problem/list`, // oracle 中异常件
  oracleAbnormalTypes: 'order/my_order/old_order_problem/queryIssueType', // oracle 问题类型
  oracleLog: 'order/my_order/old_order_problem/get_todo_details', // 沟通记录
  oracleReply: 'order/my_order/old_order_problem/reply', // 回复
  oracleMarkRead: 'order/my_order/old_order_problem/sign', // 标记已读
  oracleQueryKeyword: 'order/my_order/old_order_problem/query', // 根据单号查询
  oracleExport: `${path}/order/my_order/old_order_problem/export_excel`, // 导出Excel

  queryRefundList: 'settings/return_address/list', // 查询退件地址
  addRefund: 'settings/return_address/add', // 添加退件地址
  editRefund: 'settings/return_address/edit', // 修改退件地址
  delRefund: 'settings/return_address/del', // 批量删除
  defaultRefund: 'return_address/setdefault', // /return_address/setdefault

  destroyLog: 'order/my_order/order_problem/chooseDestroyAndSaveLog', // 自主处理 - 销毁指令
  batchDestroyLog: 'order/my_order/order_problem/batchChooseDestroyAndSaveLog', // 批量销毁指令

  chooseReturnAndSaveLog: 'order/my_order/order_problem/chooseReturnAndSaveLog', // 退件指令
  batchReturnAndSaveLog:
    'order/my_order/order_problem/BatchChooseReturnAndSaveLog', // 批量退件指令

  otherDataImport: 'inbound/operation/dataImport', // 装袋和发运文件上传
  otherUploadFileList: 'operation/uploadFileList', // 装袋/出库上传结果页面查询接口
  otherBagPrint: 'order/my_orders/management/label/print_service_bag_label', // 袋标签批量打印
  otherExport: '/operation/uploadResult/export', // 装袋/出库上传结果页面导出接口

  //  ********* 卖家助手接口 before   *********
  getTenantAccount: `${sellerAcc}eclink/order/getTenantAccount.do`, //获取卖家tenantCustomerId
  getSyncOrderRecordData: `${sellerAcc}eclink/order/getSyncOrderRecordData.do`, //获取下载记录
  deleteSyncOrderRecord: `${sellerAcc}eclink/order/deleteSyncOrderRecord.do`, //删除下载记录
  getSkuList: `${sellerAcc}eclink/sku/getSkuList.do`, //获取SKU列表
  updateSku: `${sellerAcc}eclink/sku/updateSku.do`, //修改SKU列表
  delSkus: `${sellerAcc}eclink/sku/delSkus.do`, //删除SKU列表
  getSellerAccountData: `${sellerAcc}eclink/sellerAccount/getSellerAccountData.do`, //获取卖家账号列表
  updateAccountName: `${sellerAcc}eclink/sellerAccount/updateAccountName.do`, //修改卖家账号名称
  delSellerAccount: `${sellerAcc}eclink/sellerAccount/delSellerAccount.do`, //删除卖家账号
  getOrderData: `${sellerAcc}eclink/order/getOrderData.do`, //订单管理待推送订单
  getPushedOrder: `${sellerAcc}eclink/order/getPushedOrder.do`, //订单管理已推送订单
  getMergeOrder: `${sellerAcc}eclink/order/getMergeOrder.do`, //可合并运单
  mergeAction: `${sellerAcc}eclink/order/mergeAction.do`, //合并运单
  deleteOrder: `${sellerAcc}eclink/order/deleteOrder.do`, //删除订单
  pushOrder: `${sellerAcc}eclink/order/pushOrder.do`, //推送订单
  orderDetail: `${sellerAcc}eclink/order/orderDetailTwo.do`, //订单详情
  saveOrderDetail: `${sellerAcc}eclink/order/saveOrderDetail.do`, //修改订单详情
  getShipway: `${sellerAcc}eclink/order/getShipway.do`, //获取渠道列表
  setShipwayAction: `${sellerAcc}eclink/order/setShipwayAction.do`, //设置渠道
  setSenderAction: `${sellerAcc}eclink/order/setSenderAction.do`, //设置寄件人
  getSender: `${sellerAcc}eclink/order/getSender.do`, //获取寄件人列表
  syncAction: `${sellerAcc}eclink/order/syncAction.do`, //下载运单
  writeBackMark: `${sellerAcc}eclink/order/writeBackMark.do`, //标记出货
  getEbaySignInUrl: `${sellerAcc}eclink/sellerAccount/getEbaySignInUrl.do`, //Ebay授权链接
  geAmazonSignInUrl: `${sellerAcc}eclink/sellerAccount/geAmazonSignInUrl.do`, //获取Amazon授权链接
  getAmazonAppNum: `${sellerAcc}eclink/sellerAccount/getAmazonAppNum.do`, //获取Amazon授权链接Num
  getAmazonAppName: `${sellerAcc}eclink/sellerAccount/getAmazonAppName.do`, //获取Amazon授权Name
  saveSellerAccount: `${sellerAcc}eclink/sellerAccount/saveSellerAccount.do`, //新增卖家账号
  saveReauthorization: `${sellerAcc}eclink/sellerAccount/saveReauthorization.do`, //卖家账号重新授权
  //  ********* 卖家助手接口   end    *********

  exportCollectBigExcel: '/collect/exportCollectBigExcel', //组包大包导出
  exportCollectOrderExcel: '/collect/exportCollectOrderExcel', //预约单导出

  customerQuery: 'declare/customer/query', //查询客户信息
  customerAdd: 'declare/customer/add', //注册客户注册信息

  commodityQueryList: 'declare/commodity/queryList', //分页查询备案的商品列表
  deleteCommodity: 'declare/commodity/remove', //商品删除
  commodityAdd: 'declare/commodity/add', //添加备案的商品
  commodityEdit: 'declare/commodity/edit', //编辑备案的商品
  getCommodityDetail: 'declare/commodity/detail', //商品详情

  queryProcess: 'order/my_order/order_problem/queryProcess', //仓外问题件 -- 根据notifyId查询指令树
  queryNotifyClassify: 'order/my_order/order_problem/queryNotifyClassify', //查询问题一级/二级分类-仓外问题件
  uploadFiles: `${path}/order/my_order/order_problem/uploadFiles`, // 附件上传
  download: `${path}/order/my_order/order_problem/download`, // 附件下载
  saveNotifyReply: 'order/my_order/order_problem/saveNotifyReply', // 仓外问题件  回复
  expenseDetail: `order/finance_management/my_bill/expense_detail`, // 查询流水

  amzOrderList: `amazon/order/pageList`, // 亚马逊问题单列表
  amzDetail: `amazon/order/getOrderDetail`, // 亚马逊问题单详情
  amzEdit: `amazon/order/editOrderDetail`, // 亚马逊问题单编辑
  amzAllProducts: `amazon/product/select`, // 获取所有亚马逊的产品
  amzCloseOrder: `amazon/order/close`, // 亚马逊关闭订单
  // 城市邮编查询页面接口
  query_city_zipcode_list: 'cityPostCode/query',
  findBillingFeeItem: 'v2/consumptionRecord/findBillingFeeItem',
  queryHkOrderConfig: `order/my_orders/queryHkOrderConfig`, // 查询香港预约仓
  orderHkWarehouse: `order/my_orders/orderHkWarehouse`, // 香港仓预约收获

  batchfileUpLoad: `${path}/batchOrder/batchUploadOrderPreview`, // 批量上传
}

// 封装 JSONP 请求
function jsonp(url, callback, domCallBack) {
  return new Promise(resolve => {
    window.jsonCallBack = result => {
      resolve(result)
    }
    const JSONP = document.createElement('script')
    JSONP.type = 'text/javascript'
    if (url.indexOf('?') > -1) {
      JSONP.src = `${url}&callback=${callback}`
    } else {
      JSONP.src = `${url}?callback=${callback}`
    }
    document.getElementsByTagName('head')[0].appendChild(JSONP)
    if (domCallBack) {
      JSONP.onload = JSONP.onreadystatechange = function() {
        if (
          !this.readyState ||
          this.readyState === 'loaded' ||
          this.readyState === 'complete'
        ) {
          domCallBack()
          JSONP.onload = JSONP.onreadystatechange = null
        }
      }
    }
    setTimeout(() => {
      document.getElementsByTagName('head')[0].removeChild(JSONP)
    }, 500)
  })
}

// 封装 upload 请求
function http_upload(url, data) {
  return new Promise((resolve, reject) => {
    instance
      .post(path + '/' + url, data, {
        'Content-Type': 'multipart/form-data'
      })
      .then(res => {
        if (res.status == 200) {
          resolve(res.data)
        } else {
          reject()
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 通用 POST
 * @param {*} url
 * @param {*} param
 */
async function http_post(url, param, header) {
  var nowUrl =
    url.startsWith('http') || url.startsWith('https') ? url : `${path}/${url}`
  return instance
    .post(nowUrl, param, header || defaultHeader)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

async function http_post_json(url, param, header) {
  return instance
    .post(`${path}/${url}`, param, {
      ...applicationJson,
      ...header
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 通用 GET
 * @param {*} url
 * @param {*} param
 */
async function http_get(url, param) {
  var nowUrl =
    url.startsWith('http') || url.startsWith('https') ? url : `${path}/${url}`

  // eslint-disable-next-line no-undef
  const r = _.isEmpty(param) ? `${nowUrl}` : `${nowUrl}?${qs.stringify(param)}`
  return instance
    .get(r)
    .then(res => {
      if (res.status == 200 && res.data) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * excel 导出
 * @param {*} url
 * @param {*} param
 */
async function http_exportToExcel(url, param, filename) {
  return new Promise((resolve, reject) => {
    httpBuffer
      .get(param ? `${url}?${qs.stringify(param)}` : url)
      .then(res => {
        if (res.status == 200 && res.data) {
          blobToExcel(res.data, filename)
          resolve()
        } else {
          reject()
        }
      })
      .catch(() => {
        reject()
      })
  })
}
/**
 * excel 导出
 * @param {*} url
 * @param {*} param
 */
async function http_exportToExcel2(url, param, filename) {
  return new Promise((resolve, reject) => {
    httpBuffer2
      .get(param ? `${url}?${qs.stringify(param)}` : url)
      .then(res => {
        if (res.status == 200 && res.data) {
          blobToExcel(res.data, filename)
          resolve()
        } else {
          reject()
        }
      })
      .catch(() => {
        reject()
      })
  })
}

/**
 * excel 导出
 * @param {*} url
 * @param {*} param
 */
async function http_post_exportToExcel(url, param, filename) {
  return httpBuffer
    .post(url, param)
    .then(res => {
      if (res.status == 200 && res.data) {
        blobToExcel(res.data, filename)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 获取下单规则
 * @param {*} param
 */
async function http_getRules(param) {
  if (!param || !param.countryCode || !param.productCode) return
  return instance
    .get(
      `${path}/order/my_orders/get_rule_and_extra_service?productCode=${param.productCode}&countryCode=${param.countryCode}`
    )
    .then(res => {
      if (res.status == 200 && res.data) {
        return handlerRules(res.data.data, param.productCode, param.countryCode)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 目的地国家列表
 * @param {*} param
 */
async function http_getCountryList(param) {
  return instance
    .post(`${path}/country/get_for_select`, param, defaultHeader)
    .then(res => {
      if (res.status == 200) {
        // return res.data
        if (res.data.status == 1) {
          return res.data.data
        }
      }
      return []
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 产品列表
 * @param {*} param
 */
async function http_ProductList(param) {
  return instance
    .post(`${path}/product/get_for_select`, param, defaultHeader)
    .then(res => {
      if (res.status == 200) {
        if (res.data.status == 1) {
          return res.data.data
        }
      }
      return []
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 模糊查询目的国家
 * @param {*} param
 */
async function http_getCountry(param) {
  return instance
    .post(`${path}/country/search`, param, defaultHeader)
    .then(res => {
      if (res.status == 200) {
        if (res.data.status == 1) {
          return res.data.data
        }
      }
      return []
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 模糊查询产品
 * @param {*} param
 */
async function http_getProduct(param) {
  return instance
    .post(`${path}/product/search`, param, defaultHeader)
    .then(res => {
      if (res.status == 200) {
        if (res.data.status == 1) {
          return res.data.data
        }
      }
      return []
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 收件人列表
 * @param {*} param
 */
async function http_getConsigneeList(param) {
  return instance
    .post(`${path}/settings/consignee_account/list`, param, defaultHeader)
    .then(res => {
      if (res.status == 200) {
        if (res.data) {
          return res.data
        }
      }
      return []
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 发件人列表
 * @param {*} param
 */
async function http_getShipperList(param) {
  return instance
    .post(`${path}/settings/shipper_account/list`, param, defaultHeader)
    .then(res => {
      if (res.status == 200) {
        if (res.data) {
          return res.data
        }
      }
      return []
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 运费试算
 */
async function http_getCharge(param) {
  return http_post(`order/finance_management/charge/query`, param)
}

/**
 * 运费试算获取出货地
 */
async function http_getChargeLocation(param) {
  return http_get(`order/finance_management/charge/chargeLocation`, param)
}

/**
 * 获取标准列
 */
async function http_queryStandardColumn() {
  return instance
    .get(
      `${path}/order/my_orders/create/template_management/query_standard_column`
    )
    .then(res => {
      if (res.status == 200 && res.data) {
        return res.data.data || []
      }
      return []
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 是否需要服务商单号，用于单票下单
 */
async function http_allowServiceNo() {
  return instance
    .get(`${path}/order/my_orders/management/allow_serverNo`)
    .then(res => {
      if (res.status == 200 && res.data) {
        return res.data.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 删除自定义模板
 * @param {*} param
 */
async function http_delCustomTemp(param) {
  return instance
    .post(
      `${path}/order/my_orders/create/template_management/delete_template?templateId=${param.id}`,
      {},
      defaultHeader
    )
    .then(res => {
      if (res.status == 200) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 批量添加
 * @param {*} param
 */
async function http_batchOrder(param) {
  return instance
    .post(
      `${path}/order/my_orders/create/batch_add_order`,
      param,
      defaultHeader
    )
    .then(res => {
      if (res.status == 200) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 模板编辑
 * @param {*} param
 */
async function http_editTemp(param) {
  return instance
    .post(
      `${path}/order/my_orders/create/template_management/edit_template`,
      param,
      defaultHeader
    )
    .then(res => {
      if (res.status == 200) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 保存上传成功后，编辑过的模板
 * @param {*} param
 */
async function http_saveTemp(param) {
  return instance
    .post(
      `${path}/order/my_orders/create/template_management/save_template`,
      param,
      defaultHeader
    )
    .then(res => {
      if (res.status == 200) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 获取发件人规则
 */
async function http_shipperConsigneeRules(isShipper) {
  return instance
    .get(
      `${path}/defaule_rule/queryDefaultRule?className=com.fpx.xoms3.settings.domain.${
        isShipper ? 'ShipperAccountDTO' : 'ConsigneeAccountDTO'
      }`
    )
    .then(res => {
      if (res.status == 200 && res.data) {
        return res.data.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 需回复
 * 仓外: 处理中+4px
 *
 * 待客服处理
 * 仓外：处理中+我自己
 * @param {*} param
 */
async function http_out_reply(param) {
  return instance
    .post(
      `${path}/order/my_order/order_problem/queryNotifyInfo`,
      param,
      defaultHeader
    )
    .then(res => {
      if (res.status == 200) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 需回复
 * 仓内：未解决+4px
 *
 * 待客服处理
 * 仓内: 未解决+ 我自己
 * @param {*} param
 */
async function http_inner_reply(param) {
  return instance
    .post(
      `${path}/order/my_order/order_problem/listProblem`,
      param,
      defaultHeader
    )
    .then(res => {
      if (res.status == 200) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 查询仓内问题件异常原因列表
 * @param {*} param
 */
async function http_inner_listProblem() {
  return instance
    .get(
      `${path}/order/my_order/order_problem/queryAbnTypeAndReason`,
      defaultHeader
    )
    .then(res => {
      if (res.status == 200) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 查询仓外问题件异常原因列表
 * @param {*} param
 */
async function http_outer_listProblem(param) {
  return instance
    .post(
      `${path}/order/my_order/order_problem/queryNotifyType`,
      param,
      defaultHeader
    )
    .then(res => {
      if (res.status == 200) {
        return res.data
      }
      return null
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * 查询仓内回复记录
 * @param {*} param
 * @param {*} url
 */
async function http_inner_log(param, url) {
  const res = await http_get(
    url || 'order/my_order/order_problem/get_abnormality_details',
    param,
    applicationJson
  ).catch(() => {})
  if (res && res.status == 1) {
    return res.data
  } else {
    return null
  }
}

/**
 * 查询仓外回复记录
 * @param {*} param
 */
async function http_outer_log(param) {
  const res = await http_post(
    'order/my_order/order_problem/getNotifyLog',
    param
  ).catch(() => {})
  if (res && res.status == 1) {
    return res.data
  } else {
    return null
  }
}

/**
 * 获取费用明细
 * @param {*} param
 */
async function http_orderBill(param) {
  const res = await http_get(
    'order/finance_management/my_bill/orderBill',
    param
  ).catch(() => {})
  if (res && res.status == 1) {
    return res.data
  } else {
    return null
  }
}

/**
 * 获取问题件 方案建议
 * @param {*} param
 */
async function http_getAbnormalitySuggest(param) {
  const res = await http_get(
    'order/my_order/order_problem/getAbnormalitySuggest',
    param
  ).catch(() => {})
  if (res && res.status == 1) {
    return res.data
  } else {
    return null
  }
}

/**
 * 获取标签切换的开关
 * @param {*} param
 */
async function http_printLabelMode(param) {
  const res = await http_post(
    'order/my_orders/management/info_received/web_switch',
    param
  ).catch(() => {})
  return res
}

/**
 * 获取子账号
 */
async function http_getSubUser() {
  const res = await http_get(
    'order/finance_management/my_bill/expense_detail/index'
  ).catch(() => {})
  return res
}

export {
  Api,
  jsonp,
  http_upload,
  http_post,
  http_post_json,
  http_get,
  http_getCountryList,
  http_ProductList,
  http_getRules,
  http_getCountry,
  http_getProduct,
  http_getConsigneeList,
  http_getShipperList,
  http_getCharge,
  http_getChargeLocation,
  http_exportToExcel,
  http_exportToExcel2,
  http_post_exportToExcel,
  http_queryStandardColumn,
  http_allowServiceNo,
  http_delCustomTemp,
  http_batchOrder,
  http_editTemp,
  http_saveTemp,
  http_shipperConsigneeRules,
  http_out_reply,
  http_inner_reply,
  http_inner_listProblem,
  http_outer_listProblem,
  http_inner_log,
  http_outer_log,
  http_orderBill,
  http_getAbnormalitySuggest,
  http_printLabelMode,
  http_getSubUser
}
