export const navUrl = [
  {
    name: 'index',
    url: '/'
  },
  {
    name: 'DSS',
    url: '/personal_center'
  },
  {
    name: 'TRS',
    url: '/trsFrame'
  },
  {
    name: 'FXGL',
    url: '/serverIntor'
  },
  {
    name: 'FB4',
    url: '/orderFrame'
  },
  {
    name: 'oceanAir',
    url: '/oceanWaybill'
  },
  {
    name: 'jiyun',
    url: '/jiyun#/inbound/list'
  },
  {
    name: 'storeSummary',
    url: '/storeSummary'
  },
  {
    name: 'dssAccount',
    url: '/dssAccount'
  },
  {
    name: 'serviceCenterIndex',
    url: '/serviceCenterIndex'
  }
]
