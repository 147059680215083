// initial state
import { httpGet, httpPost } from '../../api/index'

const state = () => ({
  ptyCode: '',
  basicData: {},
  listSelectOrderNo: '',
  listQueryParams: {},
  listQueryData: [],
  listLoading: false,
  listPaginationTotal: 0,
  billingState: [
    { code: 0, name: '未计费' },
    { code: 1, name: '计费成功' },
    { code: 2, name: '计费失败' },
    { code: 5, name: '取消计费成功' },
    { code: 6, name: '取消计费失败' }
  ],
  deductionState: [
    { code: 0, name: '未扣款' },
    { code: 1, name: '扣款成功' },
    { code: 2, name: '扣款失败' }
  ]
})

// getters
const getters = {}

// actions
const actions = {
  getDemo({ commit }) {
    let products = 'aa'
    commit('seDemo', products)
  },
  getBasicData({ commit }) {
    httpGet('basicData/getOrderBasicData').then(res => {
      commit('basicData', res)
    })
  },
  getOrderListData(context, params) {
    let listQueryParams = params
    if (!listQueryParams) {
      listQueryParams = context.state.listQueryParams
    } else {
      context.commit('setListQuery', listQueryParams)
    }
    httpPost('web/orderTicket/selectOrderTicketPage', listQueryParams).then(
      res => {
        context.commit('setListData', res)
      }
    )
  }
}

// mutations
const mutations = {
  seDemo(state, products) {
    state.demo = products
  },
  setListSelectOrderNo(state, id) {
    state.listSelectOrderNo = id
  },
  basicData(state, data) {
    state.basicData = data
  },
  setListQuery(state, data) {
    state.listQueryData = data
    state.listLoading = false
  },
  setListData(state, data) {
    state.listLoading = false
    state.listQueryData = data.data
    state.listPaginationTotal = data.recordsTotal
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
