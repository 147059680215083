var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chatPage"},[_c('img',{staticClass:"retract-index-udesk",style:({
      display:
        !_vm.defCustonVisble && !_vm.custonHover && !_vm.popoverVisble && _vm.robotVisible
          ? 'block'
          : 'none'
    }),attrs:{"src":require("../../assets/icon/robot_s.png")},on:{"mouseenter":_vm.mouseEnterRetract,"mouseleave":_vm.mouseLeaveRetract}}),_c('div',{staticClass:"custom-box",on:{"mouseleave":_vm.toggles,"mouseenter":_vm.mouseenterCustonBox}},[_c('a-popover',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.getters.isOverSeas && this.$store.state.username),expression:"!$store.getters.isOverSeas && this.$store.state.username"}],attrs:{"placement":"topRight"},on:{"visibleChange":_vm.customerIMChange}},[_c('template',{slot:"content"},[_c('IM')],1),(
          !_vm.$store.getters.isOverSeas &&
            this.$store.state.username &&
            (_vm.$store.state.userInfo.isDSSOpen ||
              _vm.$store.state.userInfo.isFB4Open ||
              _vm.$store.state.userInfo.isGRSOpen ||
              _vm.$store.state.userInfo.isTRSOpen ||
              _vm.$store.state.accountRegStatus.certification)
        )?_c('div',{staticClass:"new-index-udesk",style:({ display: _vm.custonHover || _vm.popoverVisble ? 'block' : 'none' })}):_vm._e()],2),_c('div',{staticClass:"custom-btn-box",style:({
        display: _vm.custonHover || _vm.popoverVisble ? 'block' : 'none',
        height: _vm.custonHover || _vm.popoverVisble ? 'auto' : 0
      })},[(
          this.$store.state.username &&
            !_vm.$store.state.userInfo.isDSSOpen &&
            !_vm.$store.state.userInfo.isFB4Open &&
            !_vm.$store.state.userInfo.isGRSOpen &&
            !_vm.$store.state.userInfo.isTRSOpen
        )?_c('div',{staticClass:"hover btn-item",on:{"click":_vm.goRegstratopm}},[_c('i',{staticClass:"iconfont iconwoyaoruzhu"}),_c('div',{staticClass:"item-text"},[_vm._v("我要入驻")])]):_vm._e(),_c('div',{staticClass:"btn-item hover",on:{"click":_vm.goInstructions}},[_c('i',{staticClass:"iconfont iconxinshouzhinan"}),_c('div',{staticClass:"item-text"},[_vm._v("新手指南")])]),_c('a-popover',{attrs:{"placement":"leftBottom"},on:{"visibleChange":_vm.customerPopoverChange}},[_c('template',{slot:"content"},[_c('div',{staticClass:"wx_grounp_content"},[_c('div',{staticClass:"wx_grounp_text"},[_vm._v("微信扫码进群咨询")]),_c('div',{staticClass:"wx_grounp_img_box"},[_c('img',{staticClass:"wx_grounp",attrs:{"src":`https://fpx-bss-oss.oss-cn-shenzhen.aliyuncs.com/fpx_boms/40827170653596IMG_5990.png?timeStr=${new Date().getTime()}`}})])])]),(
            !_vm.$store.state.accountRegStatus.certification ||
              !this.$store.state.username
          )?_c('div',{staticClass:"btn-item"},[_c('i',{staticClass:"iconfont iconqunneizixun"}),_c('div',{staticClass:"item-text"},[_vm._v(" 售前咨询 ")])]):_vm._e()],2),_c('a-popover',{attrs:{"placement":"leftBottom"},on:{"visibleChange":_vm.customerPopoverChange}},[_c('template',{slot:"content"},[_c('Customer')],1),(this.$store.state.username)?_c('div',{staticClass:"btn-item"},[_c('i',{staticClass:"iconfont iconlianxixiaoshou"}),_c('div',{staticClass:"item-text"},[_vm._v("联系销售")])]):_vm._e()],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }