<i18n>
{
  "zh": {
    "title": "信息使用授权",
    "content":"为了保障您的合法权益，您应当仔细阅读并遵守本授权书，请您务必审慎阅读、充分理解授权书条款内容，特别是免除或者减轻深圳市递四方速递有限公司（以下简称“递四方”）和渤海银行股份有限公司（以下简称“渤海银行”或“贵行”）、贵司（以下简称“贵司”）（以下递四方、渤海银行统称“被授权人”）责任，或限制您权利的条款。除非您已阅读并接受本授权书所有条款，否则您无权使用递四方和渤海银行跨境运费贷合作项目。本人/本企业充分理解并同意递四方提供的服务是指渤海银行为跨境运费贷项目下的贷删除的内容:递四方款服务（以下简称“贷款服务”或“业务”），现本人/本企业主动申请开通上述贷款服务。同时，为使渤海银行客观、准确地评估本人/本企业的信用状况，就处理（包括查询、采集、整理、分析、保存、加工、使用、传输等）本人/本企业信息用于渤海银行向本人/本企业提供贷款服务的相关事宜，本人/本企业向递四方和渤海银行作出以下授权：",
    "readed":"我已阅读《信息使用授权书》",
    "ok":"同意"
  },
  "en": {
    "title": "信息使用授权",
    "content":"为了保障您的合法权益，您应当仔细阅读并遵守本授权书，请您务必审慎阅读、充分理解授权书条款内容，特别是免除或者减轻深圳市递四方速递有限公司（以下简称“递四方”）和渤海银行股份有限公司（以下简称“渤海银行”或“贵行”）、贵司（以下简称“贵司”）（以下递四方、渤海银行统称“被授权人”）责任，或限制您权利的条款。除非您已阅读并接受本授权书所有条款，否则您无权使用递四方和渤海银行跨境运费贷合作项目。本人/本企业充分理解并同意递四方提供的服务是指渤海银行为跨境运费贷项目下的贷删除的内容:递四方款服务（以下简称“贷款服务”或“业务”），现本人/本企业主动申请开通上述贷款服务。同时，为使渤海银行客观、准确地评估本人/本企业的信用状况，就处理（包括查询、采集、整理、分析、保存、加工、使用、传输等）本人/本企业信息用于渤海银行向本人/本企业提供贷款服务的相关事宜，本人/本企业向递四方和渤海银行作出以下授权：",
    "readed":"我已阅读《信息使用授权书》",
    "ok":"同意"
  },
  "de": {
    "title": "信息使用授权",
    "content":"为了保障您的合法权益，您应当仔细阅读并遵守本授权书，请您务必审慎阅读、充分理解授权书条款内容，特别是免除或者减轻深圳市递四方速递有限公司（以下简称“递四方”）和渤海银行股份有限公司（以下简称“渤海银行”或“贵行”）、贵司（以下简称“贵司”）（以下递四方、渤海银行统称“被授权人”）责任，或限制您权利的条款。除非您已阅读并接受本授权书所有条款，否则您无权使用递四方和渤海银行跨境运费贷合作项目。本人/本企业充分理解并同意递四方提供的服务是指渤海银行为跨境运费贷项目下的贷删除的内容:递四方款服务（以下简称“贷款服务”或“业务”），现本人/本企业主动申请开通上述贷款服务。同时，为使渤海银行客观、准确地评估本人/本企业的信用状况，就处理（包括查询、采集、整理、分析、保存、加工、使用、传输等）本人/本企业信息用于渤海银行向本人/本企业提供贷款服务的相关事宜，本人/本企业向递四方和渤海银行作出以下授权：",
    "readed":"我已阅读《信息使用授权书》",
    "ok":"同意"
  },
  "ja": {
    "title": "信息使用授权",
    "content":"为了保障您的合法权益，您应当仔细阅读并遵守本授权书，请您务必审慎阅读、充分理解授权书条款内容，特别是免除或者减轻深圳市递四方速递有限公司（以下简称“递四方”）和渤海银行股份有限公司（以下简称“渤海银行”或“贵行”）、贵司（以下简称“贵司”）（以下递四方、渤海银行统称“被授权人”）责任，或限制您权利的条款。除非您已阅读并接受本授权书所有条款，否则您无权使用递四方和渤海银行跨境运费贷合作项目。本人/本企业充分理解并同意递四方提供的服务是指渤海银行为跨境运费贷项目下的贷删除的内容:递四方款服务（以下简称“贷款服务”或“业务”），现本人/本企业主动申请开通上述贷款服务。同时，为使渤海银行客观、准确地评估本人/本企业的信用状况，就处理（包括查询、采集、整理、分析、保存、加工、使用、传输等）本人/本企业信息用于渤海银行向本人/本企业提供贷款服务的相关事宜，本人/本企业向递四方和渤海银行作出以下授权：",
    "readed":"我已阅读《信息使用授权书》",
    "ok":"同意"
  }
}
</i18n>

<template>
  <div>
    <a-modal
      :title="$t('title')"
      :visible="visible"
      :footer="null"
      @cancel="$emit('cancel')"
      :bodyStyle="{ 'padding-top': 0 }"
    >
      <div class="auth-content">
        <p>
          为了保障您的合法权益，您应当仔细阅读并遵守本授权书，请您务必审慎阅读、充分理解授权书条款内容，特别是免除或者减轻深圳市递四方速递有限公司（以下简称“递四方”）和渤海银行股份有限公司（以下简称“渤海银行”或“贵行”）、贵司（以下简称“贵司”）（以下递四方、渤海银行统称“被授权人”）责任，或限制您权利的条款。<b
            class="red"
            >除非您已阅读并接受本授权书所有条款，否则您无权使用递四方和渤海银行跨境运费贷合作项目。</b
          >
        </p>
        <p>
          本人/本企业充分理解并同意递四方提供的服务是指渤海银行为跨境运费贷项目下的贷删除的内容:递四方款服务（以下简称“贷款服务”或“业务”），现本人/本企业主动申请开通上述贷款服务。同时，为使渤海银行客观、准确地评估本人/本企业的信用状况，就处理（包括查询、采集、整理、分析、保存、加工、使用、传输等）本人/本企业信息用于渤海银行向本人/本企业提供贷款服务的相关事宜，本人/本企业向递四方和渤海银行作出以下授权：
        </p>
      </div>
      <div class="flex-row">
        <span>
          <a-checkbox @change="onChange"> {{ $t('readed') }}</a-checkbox>
        </span>
        <a-button type="primary" @click="submit">
          {{ $t('ok') }}
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'AuthPopup',
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      readed: null
    }
  },
  created() {},
  mounted() {},
  methods: {
    onChange(e) {
      this.readed = e.target.checked
    },
    submit() {
      if (!this.readed) {
        this.$message.warning('请阅读信息使用授权书并勾选已阅读！')
      } else {
        this.agree()
      }
    },
    agree() {
      this.$post('hbLoan/insertAuthorization')
        .then(res => {
          this.$emit('ok')
        })
        .catch(() => {
          this.$hide()
        })
    }
  }
}
</script>
<style lang="less" scoped>
.auth-content {
  padding: 12px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(218, 223, 232, 1);
  p {
    text-indent: 28px;
  }
  .red {
    color: #f85359;
  }
}
.flex-row {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
