<!--
 * @Description: 服务面板（各状态）
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 10:17:46
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-08-05 21:40:59
-->
<i18n>
{
  "zh": {
    "dirtitle": "B2B商家邀请",
    "dircontext": "您已经是B2B商家了，无需重复操作",
    "dircontext2": "您已经是B2B供应商了，无需重复操作"
  },
  "en":{
    "dirtitle": "B2B商家邀请",
    "dircontext": "您已经是B2B商家了，无需重复操作",
    "dircontext2": "您已经是B2B供应商了，无需重复操作"
  },
  "de": {
    "dirtitle": "B2B-Händler-Einladung",
    "dircontext": "Sie sind bereits ein B2B-Händler, Sie müssen den Prozess nicht wiederholen",
    "dircontext2": "Sie sind bereits B2B-Lieferant, Sie müssen den Prozess nicht wiederholen"
  },
  "ja": {
    "dirtitle": "B2Bマーチャントインビテーション",
    "dircontext": "すでにB2Bマーチャントであるため、プロセスを繰り返す必要はありません。",
    "dircontext2": "すでにB2Bサプライヤーであるため、プロセスを繰り返す必要はない"
  }
}
</i18n>
<template>
  <div
    :style="{
      ...kanbanBoxStyle
    }"
    class="business-kanban"
  >
    <BrandItem
      @hook:mounted="lisenMounted"
      :info="b"
      :key="index"
      v-for="(b, index) in brandList"
    />
    <!-- 合同签署完后的弹窗 大陆弹窗-->
    <a-modal
      v-if="mainlandModalVisible"
      @cancel="() => (mainlandModalVisible = false)"
      :visible="mainlandModalVisible"
      :centered="true"
      width="380px"
      height="218px"
    >
      <div class="flex-center-h choose-msg">
        <div><a-icon type="check-circle" theme="filled" class="ok" /></div>
        <div class="success-msg">
          {{
            flowDetail.brandService
              ? generateDes()[flowDetail.brandService].word
              : ''
          }}
        </div>
        <div class="sell-msg">
          {{
            flowDetail.brandService
              ? generateDes()[flowDetail.brandService].tip
              : ''
          }}
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="doOperate(flowDetail.brandService)">
          {{
            flowDetail.brandService
              ? generateDes()[flowDetail.brandService].btn
              : ''
          }}
        </a-button>
      </template>
    </a-modal>
    <!-- 非大陆用户弹进度框 -->
    <StatusView
      v-if="modalVisible"
      :visible="modalVisible"
      :flowDetail="flowDetail"
      @close="modalVisible = false"
    />

    <!-- 判断是否需要补签合同 -->
    <SignProtocol />

    <!--已经是商家-->
    <a-modal
      v-model="distrvisible"
      :title="$t('dirtitle')"
      @cancel="dirtcancel(2)"
    >
      <template slot="footer">
        <a-button key="back" @click="dirtcancel(1)">
          {{ $t('index_tran_014') }}
        </a-button>
        <a-button key="submit" type="primary" @click="dirtsubmit">
          {{ $t('index_tran_015') }}
        </a-button>
      </template>
      <h2>{{ dirtext }}</h2>
    </a-modal>
    <!-- 进入合同页面提示 -->
    <a-modal
      :visible="this.$store.state.contractTipsModal"
      :bodyStyle="{ 'padding-top': 0 }"
      width="480px"
      :title="$t('friendtip')"
      :ok-text="$t('index_tran_016')"
      :cancel-text="$t('cancel')"
      @cancel="hideContractTipsModal"
      @ok="goContractInfoFun"
    >
      <p>{{ $t('index_tran_022') }}</p>
    </a-modal>
  </div>
</template>

<script>
import BrandItem from './status/BrandItem'
import StatusView from './status/statusView'
import { LINKS } from '@const/index'
import SignProtocol from './status/SignPortocol'
import Cookie from 'js-cookie'
import { loginMicroApp } from '@/mirco'
export default {
  name: 'business-kanban',
  props: {
    kanbanBoxStyle: {
      type: Object,
      default: {}
    }
  },
  components: {
    BrandItem,
    StatusView,
    SignProtocol
  },
  created() {
    this.getBrand()
    this.getFlow()
    // 根据cookie判断是否请求接口，如果有就请求
    if (Cookie.get('InviteFlag')) {
      this.getherporp()
    }
    // 获取分销商管理模块
    this.$get('v2/distributor/showitem').then(res => {
      if (res.type === 'BU' || res.type === 'BD') {
        let news = this.$store.getters.getNavItems
        news = news.filter(item => item.name != 'FXGL')
        this.$store.commit('updateNavItems', news)
      }
    })
  },
  mounted() {
    this.$root.bus.$on('reload-brand', this.getBrand)
    this.$root.bus.$on('onresize', this.updateStyle)
    this.$show()
    loginMicroApp('DSS')
      .then(() => {
        this.$hide()
      })
      .catch(() => {
        this.$hide()
      })
  },
  data() {
    return {
      brandList: [],
      mainlandModalVisible: false,
      modalVisible: false,
      mainlandArr: [],
      notMainlandArr: [],
      flowDetail: {},
      distrvisible: false,
      dirtext: '',
      dircon: '',
      goContractInfo: false
    }
  },
  beforeDestroy() {
    this.$root.bus.$off('reload-brand')
    this.$root.bus.$off('onresize')
    this.$store.state.firstVisit = false
  },
  methods: {
    lisenMounted() {
      this.updateStyle()
    },
    hideContractTipsModal() {
      this.$store.state.contractTipsModal = false
    },
    goContractInfoFun() {
      this.$store.state.contractTipsModal = false
      // this.goContractInfo = false
      this.$router.push({
        name: 'contractInfo'
      })
    },
    updateStyle() {
      this.$nextTick(() => {
        let w = document
          .querySelector('.business-kanban')
          .getBoundingClientRect().width
        const brands = document.getElementsByClassName('brand-item')
        const padding = document.body.clientWidth > 1400 ? 10 : 2
        w = (w - padding * 3) / 4
        Array.prototype.slice.call(brands).forEach(item => {
          item.style.width = w + 'px'
        })
      })
    },
    /**
     * 生成提示信息
     */
    generateDes() {
      return {
        DSS: {
          word: '您的直运合同签署成功',
          tip: '现已成功开通直运服务，点击「下单」可以马上体验了',
          btn: '去下单'
        },
        FB4: {
          word: '您的海外仓合同签署成功',
          tip: '请您登录FB4订单中心进行SKU申报',
          btn: 'SKU申报'
        },
        GRS: {
          word: '您的退件合同签署成功',
          tip: '退件服务需要购买套餐，方可下单',
          btn: '查看套餐'
        },
        TRS: {
          word: '您的转运合同签署成功',
          tip: `转运服务还需等待商品库备案${
            this.flowDetail.sellPhone
              ? `，可直接联系销售(${this.flowDetail.sellPhone}`
              : ''
          })`,
          btn: '我知晓了'
        }
      }
    },
    /**
     * 弹窗的按钮操作
     */
    doOperate(brand) {
      this.mainlandModalVisible = false
      if (['FB4'].includes(brand)) {
        this.$router.push({
          name: `iframeView`,
          params: {
            src: LINKS[name],
            brand: name
          }
        })
      } else if (brand === 'GRS') {
        this.$router.push({ path: '/grsServices' })
      } else if (brand === 'DSS') {
        window.open(LINKS[brand], '_blank')
      }
    },
    /**
     * 获取流程节点
     */
    getFlow() {
      this.$post('v2/brand/getFlowDetail', {
        brandCode: ''
      }).then(res => {
        if (res) {
          // 大陆弹窗
          this.mainlandArr = res.filter(
            e => e.customerType === '0' && e.isOpened && e.isUpdated
          )
          if (this.mainlandArr.length > 0) {
            this.doOpenMainlandModal()
          }
          // 非大陆弹窗
          this.notMainlandArr = res.filter(
            e => e.customerType === '1' && e.isUpdated
          )
          if (this.notMainlandArr.length > 0) {
            this.doOpenModal()
          }
        }
      })
    },
    doOpenModal() {
      this.flowDetail = this.notMainlandArr.pop()
      this.modalVisible = true
    },
    doOpenMainlandModal() {
      this.flowDetail = this.mainlandArr.pop()
      this.mainlandModalVisible = true
    },
    /**
     * @description: 获取客户品牌信息
     * @param {type}
     * @return {type}
     */
    getBrand() {
      this.$get('v2/brand/info')
        .then(res => {
          this.brandList = res || []
          // 排序brandCode: DSS、FB4、TRS、GRS
          const GRS = this.brandList[2]
          const TRS = this.brandList[3]
          this.brandList[2] = TRS
          this.brandList[3] = GRS

          // FB4 DSS TRS GRS 如果未开通服务，则导航栏显示下拉菜单【开通服务、服务介绍】
          const nav = [...this.$store.state.navModule.items]
          // 是否为试单|正常用户
          const isTryAccount = res.some(e => e.triedCustomer)
          //  正常用户
          if (!isTryAccount) {
            this.$store.state.isTryAccount = false
          }
          // 埋点
          const pvMap = {
            FB4: {
              order: '首页，下单，海外仓下单',
              pay: '首页，充值，海外仓充值'
            },
            GRS: {
              order: '首页，下单，退件下单',
              pay: '首页，充值，退件充值',
              pack: '首页，套餐，退件套餐'
            },
            DSS: {
              order: '首页，下单，直发下单'
            },
            TRS: {
              order: '首页，下单，转运下单',
              pay: '首页，充值，转运充值'
            }
          }
          this.brandList = this.brandList.map(e => {
            const pv = pvMap[e.brandCode]
            return {
              ...e,
              pv: pv
            }
          })
          this.brandList.forEach(item => {
            // 未开通服务
            if (item.status != 2 && item.status != 4) {
              if (item.brandCode == 'DSS') {
                nav[1].sub = [{ name: 'open' }, { name: 'desc' }]
              }
              if (item.brandCode == 'FB4') {
                nav[2].sub = [{ name: 'open' }, { name: 'desc' }]
              }
              if (item.brandCode == 'TRS') {
                nav[3].sub = [{ name: 'open' }, { name: 'desc' }]
              }
              if (item.brandCode == 'GRS') {
                nav[4].sub = [{ name: 'open' }, { name: 'desc' }]
              }
            } else {
              if (item.brandCode == 'DSS') {
                loginMicroApp('DSS')
                delete nav[1].sub
              }
              if (item.brandCode == 'FB4') {
                loginMicroApp('FB4')
                loginMicroApp('ORDER')
                delete nav[2].sub
              }
              if (item.brandCode == 'TRS') {
                loginMicroApp('TRS')
                delete nav[3].sub
              }
              if (item.brandCode == 'GRS') {
                loginMicroApp('GRS')
                delete nav[4].sub
              }
            }
            // 是否开通 dss
            if (item.brandCode == 'DSS') {
              const usr = this.$store.getters.getUserInfo
              usr.isDSSOpen = item.status == 2
              this.$store.state.brandStatus.DSS = item.status
              this.$store.commit('updateUserInfo', usr)
            }
            // 是否开通 fb4
            if (item.brandCode == 'FB4') {
              const usr = this.$store.getters.getUserInfo
              usr.isFB4Open = item.status == 2
              this.$store.state.brandStatus.FB4 = item.status
              this.$store.commit('updateUserInfo', usr)
            }
            // 是否开通 GRS
            if (item.brandCode == 'GRS') {
              const usr = this.$store.getters.getUserInfo
              usr.isGRSOpen = item.status == 2
              this.$store.state.brandStatus.GRS = item.status
              this.$store.commit('updateUserInfo', usr)
            }
            // 是否开通 TRS
            if (item.brandCode == 'TRS') {
              const usr = this.$store.getters.getUserInfo
              usr.isTRSOpen = item.status == 2
              this.$store.state.brandStatus.TRS = item.status
              this.$store.commit('updateUserInfo', usr)
            }
          })
          sessionStorage.setItem('brandList', JSON.stringify(this.brandList))
          this.$store.commit('updateNavItems', nav)
          this.$root.bus.$emit('getBrand')
        })
        .catch(() => {})
    },
    // 判断是否弹出分销商弹框
    getherporp() {
      this.$get(
        `v2/distributor/whetherpopup?suppercustomerCode=${Cookie.get(
          'InviteFlag'
        )}`
      ).then(res => {
        switch (res.flag) {
          case 'BP':
            this.distrvisible = true
            this.dirtext = `${res.tag}邀请您成为B2B商家`
            break
          case 'BD':
            this.dircon = this.$t('dircontext')
            this.$success({
              title: this.$t('dirtitle'),
              // JSX support
              content: <div>{this.dircon}</div>
            })
            break
          case 'BS':
            this.dircon = this.$t('dircontext2')
            this.$success({
              title: this.$t('dirtitle'),
              // JSX support
              content: <div>{this.dircon}</div>
            })
            break
        }
      })
    },
    // 点击邀请分销商右上角叉
    // 分销商邀请发送请求
    dirtcancel(type) {
      const formData = new FormData()
      formData.append('SupplierCode', Cookie.get('InviteFlag'))
      formData.append('type', type)
      this.$http({
        url: 'v2/distributor/send/message',
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.distrvisible = false
        this.distrvisible2 = false
      })
    },
    dirtsubmit() {
      const formData = new FormData()
      formData.append('supplierCode', Cookie.get('InviteFlag'))
      this.$http({
        url: 'v2/distributor/save/isAgreee',
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.distrvisible = false
        const formDatas = new FormData()
        formDatas.append('SupplierCode', Cookie.get('InviteFlag'))
        formDatas.append('type', 0)
        this.$http({
          url: 'v2/distributor/send/message',
          method: 'post',
          data: formDatas,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
          this.distrvisible = false
          this.distrvisible2 = false
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .big-modal-header {
  .ant-modal-footer {
    padding-right: 24px;
    text-align: right;
    button {
      width: 100px;
      height: 40px;
      padding: 0;
    }
  }
}

.business-kanban {
  width: 66.67%;
  display: flex;
  justify-content: space-between;
  user-select: none;
  position: relative;
}
.choose-msg {
  .ok {
    font-size: 40px;
    color: #18bd92;
  }
  .success-msg {
    font-size: 16px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .sell-msg {
    font-size: 12px;
    color: #999;
  }
}
/deep/ .ant-modal-footer {
  padding-bottom: 20px;
}
.select-btn {
  margin-bottom: 20px;
}

.guide-mask {
  z-index: 1000;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
  }
}
.intro {
  min-width: 290px;
  position: absolute;
  left: 50%;
  bottom: -30px;
  transform: translate(-50%, 100%);
  background: #0e5ade;
  border-radius: 4px;
  padding: 20px 14px 18px 14px;
  box-sizing: border-box;
  &.serviceIntro {
    bottom: -40px;
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 158px;
    top: -16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid #0e5ade;
  }
}
</style>
