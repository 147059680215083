<!--
 * @Description: 审核中状态
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 10:17:46
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-12-26 18:16:44
-->
<i18n>
{
  "zh": {
    "audit": "审核中...",
    "note": "需要1-2个工作日请耐心等待",
    "reapply": "重新申请",
    "cus": "联系客服"
  },
  "en": {
    "audit": "Auditing...",
    "note": "It takes 1-2 working days, please be patient",
    "reapply": "Reapply",
    "cus": "customer service"
  },
  "de": {
    "audit": "Rückblick...",
    "note": "Bitte warten Sie 1-2 Arbeitstage",
    "reapply": "Erneut anwenden",
    "cus": "Kontakt zum Kundendienst"
  },
  "ja": {
    "audit": "見直すと...",
    "note": "1-2営業日お待ちください。",
    "reapply": "再申請",
    "cus": "カスタマーサービスへのお問い合わせ"
  }
}
</i18n>
<template>
  <div class="business-audit" :class="info ? 'brand-' + info.brandCode : ''">
    <div class="b-name">{{ $t(info.brandCode + '-full') }}</div>
    <div class="b-more" @click="onCheckDetail">
      {{ $t('more') }}
      <div class="arrow-right"></div>
    </div>
    <div class="b-title">
      {{ isContractSigned ? $t('contract.review') : $t('contract.signing') }}
    </div>
    <div class="b-sub-title">
      <a
        v-if="flowDetail && Object.keys(flowDetail).length"
        @click="modalVisible = true"
        >{{ $t('current.progress') }}</a
      >
    </div>
    <div class="b-btn">
      <a
        @click="openUdesk"
        v-if="
          info.brandEnabled && (isSubApplication || isSubApplication == null)
        "
        >{{ $t('cus') }}</a
      >
      <a
        @click="reapply"
        v-if="info.brandEnabled && isSubApplication == false"
        >{{ $t('op_brand_021') }}</a
      >
      <a-divider type="vertical" v-if="info.brandEnabled" />
      <a @click="onOPenDesc">{{ $t('service.introduction') }}</a>
    </div>
    <OpenWrapper ref="openWrapper" :info="info" />
    <!-- 进度弹窗 -->
    <StatusView
      v-if="modalVisible"
      :visible="modalVisible"
      :flowDetail="flowDetail"
      @close="modalVisible = false"
    />

    <!-- 新逻辑 重新事情 -->
    <ContractSigning
      :info="info"
      type="mainReapply"
      :visible="newVisibleCurrency"
      @cancel="newVisibleCurrency = false"
      @ok="openSigning"
      v-if="newVisibleCurrency"
    />
    <!-- FB4提示弹窗 -->
    <fb4-tipsModal
      v-if="FB4Visible"
      @cancel="FB4Visible = false"
      :FB4Visible="FB4Visible"
    />
  </div>
</template>

<script>
import Fb4TipsModal from './FB4TipsModal'
import OpenWrapper from './OpenWrapper'

import ContractSigning from '../newStatus/ContractSigning'
import StatusView from './statusView'

const cur = {
  sub_auth_info: 0,
  approve: 1,
  apply_for_brand: 2,
  uploading_protocol: 3,
  brand_audit: 4,
}

export default {
  name: 'business-audit',
  props: {
    info: Object,
  },
  components: {
    OpenWrapper,
    StatusView,
    ContractSigning,
    Fb4TipsModal,
  },
  data() {
    return {
      modalVisible: false,
      flowDetail: {},
      isSubApplication: null,
      FB4Visible: false,
      newVisibleCurrency: false,
      isContractSigned: false,
    }
  },
  created() {
    this.checkProgress()
  },
  methods: {
    reapply() {
      if (this.info?.brandCode == 'FB4') {
        // 判断开通品牌是否是FB4
        this.$hide()
        this.handleFb4OpenProcss()
      } else {
        //三流合一版本 直接弹窗申请合同
        this.newVisibleCurrency = true
        // 海外入住单独跳页面
        // this.$router.push({
        //   path: '/applyContract?brandCode=' + this.info?.brandCode,
        // })
      }
    },
    async handleFb4OpenProcss() {
      let authentication = await this.getAuthentication()
      if (authentication == 1) {
        this.FB4Visible = true
      } else {
        // //三流合一版本 直接弹窗申请合同
        this.newVisibleCurrency = true
        // 海外入住单独跳页面
        // this.$router.push({
        //   path: '/applyContract?brandCode=' + 'FB4',
        // })
      }
      this.$hide()
    },
    // 获取客户账号类型  1:个人，2：企业
    getAuthentication() {
      return new Promise((resolve, reject) => {
        this.$show()
        this.$post('v2/user/getAuthentication')
          .then((res) => {
            resolve(res?.entity)
          })
          .catch((err) => {
            reject(err)
            this.$hide()
          })
      }).catch(() => {})
    },
    openSigning() {
      this.$root.bus.$emit('relo  ad-brand')
      this.newVisibleCurrency = false
      this.$store.state.contractTipsModal = true
    },
    checkProgress() {
      const { brandCode } = this.info
      this.$post('v2/brand/getFlowDetail', {
        brandCode: brandCode,
      }).then((res) => {
        if (res) {
          this.flowDetail =
            res.filter((e) => e.brandService === brandCode)[0] || {}
          // 非大陆用户需要计算合同是否签署
          if (this.flowDetail.customerType === '1') {
            this.isContractSigned = this.checkContractSigned()
          }
          // 判断是否签署合同

          if (brandCode != 'TRS') {
            let isSubApplication = null
            this.flowDetail?.details?.forEach((item) => {
              if (
                item.nodeId == 'sub_contract_application' &&
                item.nodeStatus == 2
              ) {
                isSubApplication = true
              }
            })
            this.isSubApplication = isSubApplication ? isSubApplication : false
          }
        }
      })
    },
    // 计算合同是否签署
    checkContractSigned() {
      const { currentStatusCode, newNodeStatusCode } = this.flowDetail
      const step = cur[currentStatusCode]
      const current = newNodeStatusCode === 2 ? step : step - 1
      return current === 3
    },
    doOk() {
      this.modalVisible = false
      this.flowDetail = {}
    },
    modalCancel() {
      this.flowDetail = {}
    },
    onOPenDesc() {
      this.$refs['openWrapper'].visibleIntrduce = true
    },
    /**
     * @description: 打开udesk 客服
     * @param {type}
     * @return {type}
     */
    openUdesk() {
      window.open(
        'https://4px.s2.udesk.cn/im_client/?web_plugin_id=4326&',
        '_blank',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes,width=780,height=800'
      )
    },
    /**
     * @description: 跳转至 我的账户，查看账户详情
     * @param {type}
     * @return {type}
     */
    onCheckDetail() {
      // this.$router.push({ name: this.info.brandCode.toLowerCase() + 'Account' })
      this.$router.push({ name: 'dssAccount' })
    },
  },
}
</script>

<style lang="less" scoped>
@import url('../../../../styles/theme.less');
.business-audit {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 228px;
  width: 100%;
  height: 290px;
  border-radius: 4px;
  background-size: 100% 100%;
  user-select: none;
  .b-name {
    display: inline-block;
    padding: 17px 0 0 17px;
    font-weight: 400;
    color: #333333;
  }
  .b-title {
    font-size: 28px;
    font-family: AlibabaPuHuiTiM;
    color: #000;
    height: 76px;
    line-height: 38px;
    margin-top: 72px;
    text-align: center;
  }
  .b-sub-title {
    // margin-top: 59px;
    margin-top: 20px;
    font-size: 12px;
    font-family: AlibabaPuHuiTiR;
    color: #999999;
    line-height: 12px;
    text-align: center;
  }
  .b-more {
    cursor: pointer;
    font-size: 12px;
    color: #999;
    float: right;
    margin-right: 16px;
    margin-top: 17px;
  }
  .b-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    a {
      line-height: 1;
      width: 100%;
      text-align: center;
      color: #333333;
      transition: color 0.3s;
      &:hover {
        color: @prmaryColor;
      }
    }
  }
  .ant-divider,
  .ant-divider-vertical {
    height: 23px;
  }
}

.tip-box {
  display: flex;
  justify-content: center;
  padding: 16px 0;
  background: #f3f4f8;
  border: 1px solid rgba(221, 221, 221, 1);
  margin-top: 32px;
  font-size: 12px;

  .tip {
    text-align: left;
    margin-left: 6px;
    p {
      line-height: 1.8;
    }
  }
}
/deep/ .ant-modal-footer {
  padding: 0;
}
</style>
