/**
 * 从left中查找有用key，去除right中无效字段
 * @param {*} left Array
 * @param {*} right Object
 */
const returnPurekeys = (left, right) => {
  if (!left || !right) return

  const keys = Object.keys(right)
  const res = {}
  if (_.isArray(left[0])) {
    for (let i = 0; i < left.length; i++) {
      const row = left[i]
      for (let j = 0; j < row.length; j++) {
        const fieldName = row[j].fieldName
        if (keys.indexOf(fieldName) > -1) {
          res[fieldName] = right[fieldName]
        }
      }
    }
  } else {
    for (let j = 0; j < left.length; j++) {
      const fieldName = left[j].fieldName
      if (keys.indexOf(fieldName) > -1) {
        res[fieldName] = right[fieldName]
      }
    }
  }

  return res
}

/**
 * 将数组分为 二维数组
 * @param {*} list 原数组
 * @param {*} len 一维数组长度
 */
const groupListByLen = (list, len) => {
  if (!list) return
  if (!len || isNaN(len)) {
    throw new Error('请指定一维数组的长度')
  }

  let list2 = []
  const rows = Math.ceil(list.length / len)
  for (let i = 0; i < rows; i++) {
    list2.push([])
  }
  for (let i = 0; i < list.length; i++) {
    list2[Math.floor(i / len)].push(list[i])
  }
  return list2
}

/**
 * 流文件下载
 * @param res 字节流
 */
const blobToExcel = (res, filename, stuiff = '.xls') => {
  const blob = new Blob([res])
  const blobUrl = window.URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.style.display = 'none'
  const d = new Date()

  if (filename) {
    const ftype =
      filename.lastIndexOf('.') > -1
        ? filename.substring(filename.lastIndexOf('.') + 1)
        : null
    if (!ftype) {
      if (stuiff) {
        filename += stuiff
      } else {
        filename += '.xlsx'
      }
    }
  }
  a.download =
    filename || `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}.xlsx`
  a.href = blobUrl
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(blobUrl)
}

/**
 * 保留 position 位有效数字
 * @param {*} num
 * @param {*} position
 */
const rounding = (num, position = 2) => {
  if (!num || isNaN(num)) return 0
  let fixNum = new Number(num + 1).toFixed(position)
  let fixedNum = new Number(fixNum - 1).toFixed(position)
  return fixedNum
}

/**
 * iframe 请求
 * @param {*} src
 */
const iframeExport = src => {
  const iframe = document.createElement('iframe')
  iframe.setAttribute('id', 'epIf')
  iframe.setAttribute('src', src)
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
  setTimeout(() => {
    document.getElementById('epIf').remove()
  }, 300)
}

/**
 * 将单号转为为以逗号分隔
 * @param {*} str
 */
const ordersHandler = orderNos => {
  if (orderNos) {
    orderNos = _.trim(orderNos).replace(/[\r\n]/g, ',')
    orderNos = orderNos.split(/[,.;\s]/).filter(item => _.trim(item) != '')
  }
  return orderNos ? orderNos.toString() : ''
}

/**
 * @author chifukang
 * @description 限制只能输入数字，其它字符去除
 * @param val
 */
const limitInteger = val => {
  if (!/\D/.test(val)) return val
  const vals = val.split('')
  let result = ''
  for (let i = 0; i < vals.length; i++) {
    if (!isNaN(vals[i])) result += vals[i]
  }
  return result
}

/**
 * 设置字段校验规则
 * @param {*} ruleMap
 * @param {*} k
 */
const configRules = (ruleMap, k) => {
  if (!ruleMap) return
  const decorator = [k, { rules: [] }]
  let isRequired = false
  let requiredItem
  const r = ruleMap[k] || []
  r.forEach(item => {
    isRequired = item.ruleType == 'nullValue' && item.ruleValue == 'Y'
    if (isRequired) requiredItem = item
    let mode
    if (item.ruleType == 'regex')
      mode = { pattern: item.ruleValue, message: item.msg }
    if (item.ruleType == 'length')
      mode = {
        min: parseInt(item.ruleValue.split('-')[0]),
        max: parseInt(item.ruleValue.split('-')[1]),
        message: item.msg
      }
    if (mode) decorator[1].rules.push(mode)
  })
  if (requiredItem) {
    decorator[1].rules.push({
      required: true,
      message: requiredItem.msg
    })
  }
  return {
    decorator: decorator || [],
    placeholder: r && r.length > 0 ? r[r.length - 1].placeholder : '',
    eplaceholder: r && r.length > 0 ? r[r.length - 1].eplaceholder : ''
  }
}

/**
 * 手机号（国内）校验
 * @param {*} value
 */
const phoneValidte = value => {
  return /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-3,5,8,9]))[0-9]{8}$/.test(
    value
  )
}

/**
 * 表格头部国际化
 * @param {*} vm
 */
const tableI18n = vm => {
  vm.$root.bus.$on('locale', locale => {
    const columns = [...vm.columns]
    columns.forEach(item => {
      item.title = locale == 'zh' ? item.zh : item.en
    })
    vm.columns = Object.freeze(columns)
  })

  vm.$root.bus.$emit('locale', vm.$i18n.locale)
}

/**
 * @author chifukang
 * @description 输入限制条件
 * @param val 待处理数值
 * @param len 保留小数位数
 */
const inputNumLimit = (val, len = 3) => {
  val = val + ''
  if (isNaN(val.charAt(0))) val = ''
  const dot = val.indexOf('.')
  if (dot > -1) {
    if (isNaN(val.charAt(val.length - 1)))
      val = val.substring(0, val.length - 1)
  }
  let result = val.charAt(0)
  for (let i = 1; i < val.length; i++) {
    const curr = val[i]
    if (dot > -1) {
      if (isNaN(curr) && i !== dot) continue
    } else {
      if (isNaN(curr)) continue
    }
    result += curr
  }
  const d = result.indexOf('.')
  if (d > -1) {
    const v = result.substring(d + 1).substring(0)
    if (v.length > len)
      return Number(
        result.substring(0, d + 1) + result.substring(d + 1).substring(0, len)
      )
  }
  return result ? Number(result) : ''
}

/**
 * @description: 复制
 * @param {*} copy
 * @return {*}
 */
const Util_Copy = content => {
  const input = document.createElement('input')
  input.value = content
  input.setAttribute('id', 'copyInput')
  input.style.opacity = 0
  input.style.position = 'fixed'
  document.body.appendChild(input)
  input.select()
  document.execCommand('Copy')
  document.getElementById('copyInput').remove()
}

/**
 * @description: 组件中首个input自动获取焦点
 * @param {*} className
 * @return {*}
 */
const firstInputFocus = (vm, className) => {
  vm.$nextTick(() => {
    const wrapper = document.querySelector(`.${className}`)
    if (wrapper) {
      const input = wrapper.querySelector('.ant-input')
      if (input) {
        input.focus()
      }
    }
  })
}

export {
  returnPurekeys,
  blobToExcel,
  groupListByLen,
  rounding,
  iframeExport,
  ordersHandler,
  configRules,
  phoneValidte,
  tableI18n,
  limitInteger,
  inputNumLimit,
  Util_Copy,
  firstInputFocus
}
