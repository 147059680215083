<template>
  <a-drawer
    width="520px"
    placement="right"
    wrapClassName="order-detial"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="detail-box">
      <div class="title" slot="title">
        <span>选择产品对比</span>
        <i class="iconfont  iconlujing" @click="onClose"></i>
      </div>
      <div class="all-prod  recommend-prod-box">
        <a-empty
          v-if="filtersProductList.length == 0"
          style="width: 100%; height: 270px; padding-top: 154px"
        />
        <div
          v-else
          class=" recommend-prod-item"
          v-for="item in filtersProductList"
          :key="item.productCode"
        >
          <div class="content">
            <div class="tit-box">
              <div class="tit-content">
                <div class="name">
                  <span class="name-text">
                    {{ item.productName }}({{ item.productCode }})
                  </span>
                  <div v-if="item.recommendTag" class="recommend-tag">
                    {{ item.recommendTag }}
                  </div>
                </div>
                <div class="tag-box">
                  <div
                    class="tag"
                    :class="{
                      'tag-hb': item.trackingType == 2,
                      'tag-gr': item.trackingType == 3
                    }"
                  >
                    {{ item.trackingType | getNameFromValue(trackTypeArr) }}
                  </div>
                  <div
                    class="tag"
                    :class="{
                      'tag-g': item.isBattery == 1,
                      'tag-o': item.isBattery != 1
                    }"
                  >
                    {{ item.isBattery == 1 ? '带电' : '不可带电' }}
                  </div>
                  <div class="tag-bt">
                    <i class="iconfont iconfuwubiaozhun"></i>
                    {{ item.serviceLevel | getNameFromValue(serviceLevelArr) }}
                    |
                    {{
                      item.openCheckType == '1'
                        ? '可开查'
                        : item.openCheckType == '0'
                        ? '不可开查'
                        : '-'
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="prod-opt" @click="delProductItem(item.productCode)">
            <i class="iconfont iconshanchuchanpin"></i>
          </div>
        </div>
      </div>

      <!-- 顶部按钮 -->
      <div class="footer-opt">
        <a-button
          :disabled="filtersProductList.length < 1"
          type="primary"
          @click="submitContrast"
          class="contrast-btn"
          >开始对比</a-button
        >
        <a-popconfirm
          placement="topLeft"
          ok-text="确认"
          cancel-text="取消"
          @confirm="$emit('clearSelectProductList')"
        >
          <template slot="title">
            <p>确认清空对比该产品？</p>
          </template>
          <span class="clear-btn">清空</span>
        </a-popconfirm>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import { trackTypeArr, serviceLevelArr } from './data'

export default {
  name: 'SelectContrastItem',
  props: {
    visible: {
      type: Boolean
    },
    productList: {
      type: Array,
      default: () => []
    },
    selectProduct: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      trackTypeArr: trackTypeArr(this),
      serviceLevelArr: serviceLevelArr(this),
      defaultActive: 1,
      value1: '',
      listData: []
    }
  },
  computed: {
    filtersProductList() {
      const productList = this.productList || []
      const selectProduct = this.selectProduct || []
      let filtersProductList = []
      let addListCode = []
      productList.forEach(element => {
        if (
          selectProduct.includes(element.productCode) &&
          !addListCode.includes(element.productCode)
        ) {
          addListCode.push(element.productCode)
          filtersProductList.push(element)
        }
      })
      filtersProductList = [...new Set(filtersProductList)]
      return filtersProductList
    }
  },
  methods: {
    delProductItem(code) {
      this.$emit('delProductItem', code)
    },
    onClose() {
      this.$emit('onClose')
    },
    submitContrast() {
      this.$emit('subimt', this.listData)
    },
    /**
     * 切换
     */
    callback(curr) {}
  }
}
</script>

<style lang="scss" scoped>
.detail-box {
  padding: 0 20px;
  min-height: 100vh;
  background: #f2f4f8 !important;
  .title {
    height: 60px;
    line-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #151f28;
    line-height: 20px;
    font-weight: 600;
    .iconfont {
      padding: 20px;
      padding-right: 0;
      font-size: 15px;
      color: rgba(84, 93, 117, 0.5);
      font-weight: 500;
      cursor: pointer;
    }
  }
}
.recommend-prod-box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
}
.all-prod {
  flex-wrap: wrap;
  flex-direction: column;
  .recommend-prod-item {
    position: relative;
    margin-bottom: 12px;
    box-sizing: border-box;
    overflow: inherit;
    width: calc(100% - 36px) !important;
    height: 84px;
    flex-direction: row;
  }
  .content {
    padding: 16px 20px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    .tit-box {
      width: 100%;
    }
    .tit-content {
      flex: none;
      width: 100%;
    }
    .recommend-tag {
      margin-top: 0;
      padding: 0;
      text-align: center;
      box-sizing: border-box;
      width: 60px;
      height: 20px;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      font-weight: 500;
      background: #ff6400;
      border-radius: 12px 12px 12px 0;
      background: '';
    }
    .recommend-tag-b {
      background: #439dfa;
    }
    .recommend-tag-g {
      background: #00b0b0;
    }
  }
}
// 产品
.recommend-prod-item {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 12px;
  overflow: inherit;
  width: calc((100% - 24px) / 3);
  height: 182px;
  background: #ffffff;
  box-shadow: 4px 4px 16px 0 rgba(144, 155, 190, 0.13);
  border-radius: 8px;
  overflow: hidden;
  &:nth-of-type(3) {
    margin-right: 0;
  }
}
.recommend-prod-item .content {
  padding: 16px 16px 16px 20px;
  flex: 1;
}

.recommend-prod-item .content .name {
  display: flex;
  font-size: 16px;
  color: #364253;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .name-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tag-box {
  margin: 10px 0;
  width: 100%;
  display: flex;
  height: 20px;
  .tag {
    margin-right: 6px;
    padding: 0 12px;
    display: inline-block;
    height: 20px;
    background: rgba(57, 96, 223, 10%);
    border-radius: 4px;
    font-size: 12px;
    color: #3960df;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
  }
  .tag-hb {
    background: #167ff010;
    color: #1681f0;
  }
  .tag-g {
    background: #07ab8210 !important;
    color: #07ab81 !important;
  }
  .tag-o {
    background: rgba(255, 100, 0, 10%);
    color: #ff6400;
  }
  .tag-gr {
    background: #545d7510 !important;
    color: #545d75 !important;
  }
  .tag-bt {
    margin-left: 4px;
    font-size: 12px;
    color: #323643;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
    font-size: 12px;

    .iconfont {
      font-size: 14px;
      color: #8492bd;
    }
  }
}
.prod-opt {
  position: absolute;
  right: -36px;
  top: 0;
  width: 36px;
  height: 80px;
  line-height: 80px;
  text-align: right;
  color: #a9aeba;
  font-size: 22px;
  cursor: pointer;
}

// 顶部浮动
.footer-opt {
  position: absolute;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  height: 80px;
  background: #f2f4f8;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .contrast-btn {
    flex: 1;
    height: 45px;
  }
  .clear-btn {
    width: 36px;
    text-align: right;
    font-size: 14px;
    color: #767d91;
    font-weight: 500;
    cursor: pointer;
  }
}
// 设置整个弹窗背景
::v-deep.ant-drawer-wrapper-body {
  background: #f2f4f8 !important;
}
</style>
