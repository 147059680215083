<template>
  <!--  class="cunt-product" -->
  <div class="flex-product-box">
    <!-- :href="
        item.redirectUrl
          ? item.redirectUrl
          : `${linkPrefix}/productDetails?productCode=${item.productCode}${
              ogIdPickup ? '&ogIdPickup=' + ogIdPickup : ''
            }${orgName ? '&orgName=' + orgName : ''}`
      " -->
    <div
      target="_blank"
      class="cunt-item"
      v-for="(item, pIndex) in productList"
      :key="pIndex"
      @click="goDetail(item, $event)"
    >
      <div class="cunt-top-box">
        <div class="name-box">
          <span class="name">
            <a-popover>
              <template #content>
                {{ item.productName || '-' }}({{ item.productCode || '-' }})
              </template>
              <span
                >{{ item.productName || '-' }}({{
                  item.productCode || '-'
                }})</span
              >
            </a-popover></span
          >
          <div
            class="prd-making-tag"
            :class="
              item.tagType == '热门产品'
                ? ' making-tag-g'
                : item.tagType == '时效优先'
                ? ' making-tag-b'
                : ''
            "
            v-if="item.tagType"
          >
            {{ item.tagType }}
          </div>
        </div>

        <a-popover placement="bottomLeft">
          <!-- 浮点窗 -->
          <template #content>
            <TabBox
              v-if="item.labelCodeList && item.labelCodeList.length > 2"
              :item="item"
            />
          </template>
          <TabBox
            v-if="item.labelCodeList && item.labelCodeList.length > 2"
            :item="item"
          />
        </a-popover>

        <TabBox
          v-if="!item.labelCodeList || item.labelCodeList.length < 3"
          :item="item"
        />
      </div>

      <div v-if="item.deliveryPeriod || item.totalRmbAmount" class="price-box">
        <div class="price-item">
          <div v-if="item.deliveryPeriod" class="num-data">
            <span class="num-text">{{ item.deliveryPeriod || '-' }}</span>
            <span class="unit">天</span>
          </div>
          <span v-else class="unit">暂无预估时效</span>
        </div>
        <div class="price-item">
          <div v-if="item.totalRmbAmount" class="num-data">
            <span class="blue num-text">{{
              item.totalRmbAmount | toMoney
            }}</span>
            <span class="unit">元/KG起</span>
          </div>
          <span v-else class="unit">点击详情试算费用</span>
        </div>
      </div>
      <div v-else class="price-box">
        <div class="no-price-text">该产品线路暂无预估时效价格</div>
      </div>
    </div>
  </div>
</template>

<script>
import sensors from 'sa-sdk-javascript'
import TabBox from './TabBox'
export default {
  name: 'ProductItem',
  props: {
    productList: {
      type: Array,
      default: () => []
    },
    // 起始地
    ogIdPickup: {
      type: String,
      value: ''
    },
    orgName: {
      type: String,
      value: ''
    },
    //国家
    country: {
      type: String,
      value: ''
    },
    countryName: {
      type: String,
      value: ''
    }
  },
  components: {
    TabBox
  },
  data: () => ({}),
  computed: {
    linkPrefix() {
      let url = ''
      if (process.env.NODE_ENV == 'production') {
        url = '/dist'
      }
      return url
    }
  },
  mounted() {},
  methods: {
    goDetail(item, event) {
      const path = location.pathname.split('/')
      const currRoute = path[path.length - 1]
      sensors.track('HomeProductClick', {
        ...event?.target,
        recommend_type: currRoute == 'productService' ? '产品服务' : '热门国家', //	页面类型
        product_code: item.productCode,
        product_name: item.productName
      })
      // 跳转新窗口
      if (item.redirectUrl) {
        window.open(item.redirectUrl)
      } else {
        window.open(
          `${this.linkPrefix}/productDetails?productCode=${item.productCode}${
            this.ogIdPickup ? '&ogIdPickup=' + this.ogIdPickup : ''
          }${this.orgName ? '&orgName=' + this.orgName : ''}${
            this.country ? '&country=' + this.country : ''
          }${this.countryName ? '&countryName=' + this.countryName : ''}`
        )
      }
    }
  }
}
</script>
<style lang="less" scoped>
.cunt-product {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.flex-product-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.empty-box {
  margin: 30px auto !important;
}
.more-product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #fffaf2;
  box-shadow: 4px 4px 16px 0 rgba(144, 155, 190, 0.13);
  border-radius: 8px;
  font-size: 14px;
  color: #cd5f00;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  animation: all 0.2s;
  &:hover {
    box-shadow: 2px 2px 16px 0px rgba(57, 96, 223, 0.15);
    transform: scale(1.01);
  }
}

.iconfont {
  margin-left: 6px;
  font-size: 8px;
  color: #cd5f00;
  height: 40px;
  line-height: 40px;
  font-weight: normal;
}
.cunt-item {
  position: relative;
  display: block;
  margin-right: 16px;
  margin-bottom: 16px;
  width: calc(25% - 12px);
  height: 130px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid rgba(57, 96, 223, 0);
  box-shadow: 4px 4px 16px 0 rgba(144, 155, 190, 0.13);
  transition: all 0.2s;
  animation: all 0.2s;
  // animation: 800ms ease-in-out 1s 1 normal both;
  &:hover {
    margin-top: -4px;
    background: #ffffff;
    border: 2px solid rgba(57, 96, 223, 1);
    box-shadow: 2px 2px 16px 0px rgba(57, 96, 223, 0.35);
    transform: scale(1.02);
  }
  &:nth-child(4n) {
    margin-right: 0;
  }

  .cunt-top-box {
    flex-direction: column;
    align-content: flex-start;
    padding: 18px 20px 12px;
    box-sizing: border-box;
    height: 80px;
    border-bottom: 1px dashed #dadfe8;
    .name-box {
      font-size: 16px;
      color: #364253;
      line-height: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      .name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 20px;
        line-height: 20px;
      }
    }
    .prd-making-tag {
      min-width: 60px;
      height: 20px;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      background: #00b0b0;
      border-radius: 12px 12px 12px 0;
    }
    .making-tag-g {
      background: #ff6400;
    }
    .making-tag-b {
      background: #4e75f2;
    }
  }
  .tag-box;
  .price-box {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    font-size: 22px;
    color: #545d75;
    line-height: 18px;
    .price-item {
      flex: 1;
      white-space: nowrap;
      &:nth-last-child(1) {
        text-align: right;
      }
    }
    .no-price-text {
      font-size: 14px;
      color: #545d75;
      line-height: 14px;
    }
    .num-text {
      font-weight: bold;
    }
    .blue {
      color: #3960df;
    }
    .unit {
      margin-left: 8px;
      font-size: 12px;
      color: #323643;
      line-height: 12px;
      font-weight: bold;
    }
  }
}

.ant-popover {
  .tag-box;
  .tag-box .tag {
    margin-top: 0 !important;
  }
}
.tag-box {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  scrollbar-width: none; /* 对于 Firefox 隐藏滚动条 */
  /* 隐藏滚动条的样式 */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
  // &::after {
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   background: red;
  //   display: block;
  //   content: '';
  //   width: 20px;
  //   height: 20px;
  //   z-index: 300;
  // }
  .tag {
    margin-top: 10px;
    margin-right: 6px;
    display: inline-block;
    padding: 0 6px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 12px;
    color: #4c6fe2;
    line-height: 18px;
    height: 20px;
    border: 1px solid #88a0ec;
    border-radius: 4px;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
  .tag-g {
    color: #07ab81;
    background: #18bd910a;
    border-color: #18bd910a;
  }
  .tag-o {
    color: #ff6400;
    background: #ff660010;
    border-color: #ff660010;
  }
}
// 小于1440的窗口.num-text 文字的大小设置成16px
@media screen and (max-width: 1440px) {
  .price-item .num-text {
    font-size: 16px !important;
  }
}
</style>
