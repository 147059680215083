<template>
  <div class="country-select">
    <a-popover
      :placement="placement || 'bottomLeft'"
      v-model="countryPopover"
      trigger="click"
    >
      <template slot="content">
        <div style="min-height:180px">
          <Country
            :hideHotGroup="hideHotGroup"
            style="width:650px;"
            @getterCountry="getterCountry"
          />
        </div>
      </template>
      <a-select
        showSearch
        :allowClear="hideHotGroup ? false : true"
        size="small"
        v-model="curr"
        :defaultActiveFirstOption="false"
        :filterOption="false"
        :notFoundContent="null"
        :getPopupContainer="getPopupContainerFunc"
        @search="handleCountrySearch"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleCountryChange"
      >
        <a-select-option
          v-for="d in countrySerachData"
          :dataset="d"
          :key="d.display"
        >
          {{ d.name }}
        </a-select-option>
      </a-select>
      <!-- <span v-if="!curr && !countryPopover" class="ct-placeholder"
        >请选择目的地</span
      > -->
    </a-popover>
  </div>
</template>

<script>
import Country from './Country'

export default {
  name: 'country-select',
  components: {
    Country
  },
  props: {
    selectedValue: {
      type: String
    },
    hideHotGroup: {
      type: false
    },
    placement: {
      type: String,
      value: 'bottomLeft'
    }
  },
  data() {
    return {
      curr: '',
      code: '',
      countryPopover: false,
      countrySerachData: []
    }
  },
  watch: {
    // 设置选中的国家
    selectedValue(newVal, oldVal) {
      this.setDefCountryData(newVal)
    }
  },
  mounted() {
    this.setDefCountryData(this.selectedValue)

    this.$nextTick(() => {
      this.$root.bus.$on('clear', () => {
        this.curr = ''
      })

      this.$root.bus.$on('default', value => {
        this.curr = value
      })

      // emit onscroll
      this.$root.bus.$on('onscroll', () => {
        this.countryPopover = false
      })
    })
  },
  beforeDestroy() {
    this.$root.bus.$off('clear')
    this.$root.bus.$off('default')
    this.$root.bus.$off('onscroll')
  },
  methods: {
    setDefCountryData(newVal = '') {
      if (newVal) {
        this.curr = newVal
        const selectedCode = this.selectedValue
        if (selectedCode) {
          // 根据国家 code 查找国家完整信息展示
          let countyList = sessionStorage.getItem('c_y')
          if (countyList) {
            countyList = JSON.parse(countyList)
            let result
            for (let x = 0; x < countyList.length; x++) {
              for (let y = 0; y < countyList[x].country.length; y++) {
                const c = countyList[x].country[y].countrys
                c.some(v => {
                  if (v.code == selectedCode) {
                    result = { code: v.code, name: v.display }
                    return true
                  } else {
                    return false
                  }
                })
                if (result) break
              }
              if (result) break
            }

            if (result) {
              this.emitVal(result)
              this.curr = result
            }
          }
        }
      }
    },
    getPopupContainerFunc() {
      return document.querySelector('.dss-index') || document.body
    },
    handleFocus() {
      this.countryPopover = false
      this.countrySerachData = []
      this.$emit('handleFocus', false)
    },
    handleBlur() {
      this.$emit('handleFocus', true)
    },
    clearData() {
      this.curr = ''
    },
    getterCountry(val) {
      if (!val) return
      val = JSON.parse(val)
      this.curr = val.name
      this.countryPopover = false
      this.emitVal(val)
    },
    handleCountrySearch: _.debounce(async function(val) {
      const dt = await this.http_getCountry({
        queryType: 'orderNoservice',
        key: _.trim(val)
      })
      this.countryPopover = false
      this.countrySerachData = Object.freeze(dt)
    }, 500),
    handleCountryChange(countryName, opt) {
      let code = ''
      if (opt && opt.data && opt.data.attrs && opt.data.attrs.dataset) {
        code = opt.data.attrs.dataset.code
      }
      this.code = code
      this.$emit('pop-value', code, countryName, this.$attrs)
    },
    emitVal(result) {
      let curr = result || {}
      this.code = curr.code
      this.$emit('pop-value', curr.code, curr.name, this.$attrs)
    },
    /**
     * 模糊查询目的国家
     * @param {*} param
     */
    async http_getCountry(param) {
      const defaultHeader = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      }
      return this.$post(`landmark/search`, param, defaultHeader)
        .then(res => {
          return res || []
        })
        .catch(err => {
          return Promise.reject(err)
        })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep.ant-select > .ant-select-selection {
  border: 0 !important;
  border-color: transparent;
  background: transparent;
  z-index: 2;
}
::v-deep.ant-select-opend > .ant-select-selection,
::v-deep.ant-select-focused {
  border: 0 !important;
  border-color: transparent;
}
//右边距
::v-deep.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 0 !important;
}
::v-deep.ant-select-selection--single > .ant-select-selection__rendered {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
::v-deep.ant-select-focused .ant-select-selection,
::v-deep.ant-select-selection:focus,
::v-deep.ant-select-selection:active {
  border-color: transparent !important;
  box-shadow: 0 0 0 0px rgba(255, 255, 255, 0) !important;
  background: transparent;
}

.ct-placeholder {
  margin-top: -23px;
  position: absolute;
  display: block;
  color: #bfbfbf;
  line-height: 20px;
  z-index: 1;
}
</style>
