<template>
  <div>
    <div class="recommend-tit-box">
      <div class="recommend-tit">热门国家</div>
    </div>
    <div class="opt-box">
      <div class="tab-country">
        <!-- <div class="tab-item" @click="changeTab(item.countryCode)" :class="{'active':item.countryCode==tabValue}" v-for="(item, index) in countryArr" :key="index">{{item.countryName}}</div> -->
        <a-tabs
          default-active-key="1"
          class="tabs-box"
          tab-position="top"
          :style="{ height: '30px' }"
          @tabClick="tabChange"
          ><a-tab-pane
            v-for="item in countryList"
            :key="item.countryCode"
            :tab="item.countryName"
            >{{ item.countryName }}</a-tab-pane
          ></a-tabs
        >
      </div>

      <div v-if="countryList.length > 0" class="city-box">
        <div class="label">发货地：</div>
        <div class="sel-item">
          <ShippmentAddress
            ref="shippmentAddressRef"
            @ogId="onReciveOgId"
            :transparentBg="true"
            :Name="orgName"
          />
        </div>
      </div>
    </div>

    <div class="cunt-product">
      <ProductItem
        :country="tabValue"
        :countryName="
          `${countryName ? countryName + '(' + tabValue + ')' : tabValue}`
        "
        :productList="productList"
        :ogIdPickup="ogIdPickup"
        :orgName="orgName"
      />
      <a-empty
        class="empty-box"
        v-if="productList.length < 1"
        :image="emptyImage"
        :image-style="{
          width: '180px',
          height: '140px'
        }"
        description="产品上架中…敬请期待"
        v-cloak
      ></a-empty>

      <div @click="goProductList" class="more-product">
        没找到合适的？点击前往「产品服务」查看所有4PX物流产品
        <i class="iconfont iconyoujiantou"></i>
      </div>
    </div>
  </div>
</template>
<script>
import sensors from 'sa-sdk-javascript'
import ShippmentAddress from '../../serviceCenter/dssFreight/components/ShipmentAddress'
import ProductItem from '@v/productService/components/ProductItem'

export default {
  name: 'HotCountry',
  data() {
    return {
      emptyImage: require('../../../assets/newIndex/no_data.png'),
      tabValue: '',
      countryName: '', //国家名称
      countryList: [
        // { countryCode: "BE", countryName: "比利时", eName: "BELGIUM" },
      ],
      productList: [],
      // 发货城市 默认深圳
      ogIdPickup: '10', //发货地ID
      orgName: '深圳'
    }
  },
  components: {
    ShippmentAddress,
    ProductItem
  },
  created() {
    this.getHotCountry()
  },
  mounted() {},
  methods: {
    goProductList() {
      this.$router.push({
        name: 'productService'
      })
    },
    getHotCountry() {
      this.$show()
      this.$get('v2/homepage/queryHotCountry').then(res => {
        this.$hide()
        if (res.length > 0) {
          this.countryList = res || []
          this.tabValue = res[0]?.countryCode
          this.countryName = res[0]?.countryName
          this.getHotProduct(res[0]?.countryCode)
        }
      })
    },
    getHotProduct(cCode = '') {
      let countryCode = cCode || this.tabValue
      let ogIdPickup = this.ogIdPickup

      // this.$show()
      this.$get(
        `v2/homepage/queryHotProductByCountry?countryCode=${countryCode}&ogIdPickup=${ogIdPickup}`
      ).then(res => {
        this.$hide()
        this.productList = res || []
      })
    },
    goDetail(item, event) {
      sensors.track('HomeProductClick', {
        ...event?.target,
        recommend_type: '热门国家', //	页面类型
        product_code: item.productCode,
        product_name: item.productName
      })
      // 跳转新窗口
      if (item.redirectUrl) {
        // window.open(item.redirectUrl)
      }
    },
    tabChange(code) {
      code ? this.getHotProduct(code) : ''
      this.tabValue = code
      //  从国家列表数据countryList 里面判断国家countryCode ==code 复制给countryName
      let item = this.countryList.find(item => item?.countryCode === code)
      if (item && item?.countryName) {
        this.countryName = item?.countryName || ''
      }
    },
    changeTab(code) {
      code ? this.getHotProduct(code) : ''
      this.tabValue = code
    },
    // 选择发货地
    onReciveOgId(id, name = '') {
      let oldId = this.ogIdPickup

      this.ogIdPickup = id || '10'
      this.orgName = name || '深圳'
      if (id && id != oldId) {
        this.getHotProduct()
      }
    }
  }
}
</script>
<style lang="less" scoped>
* {
  caret-color: transparent;
}
.opt-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.city-box {
  position: relative;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  line-height: 30px;
  .label {
    flex-wrap: nowrap;
    font-size: 14px;
    color: #767d91;
    text-align: right;
    font-weight: 500;
  }
  .sel-item {
    // position: relative;
    width: 100px;
    font-size: 14px;
    color: #545d75;
    font-weight: 500;

    .ant-input {
      background: red;
    }
  }
}
.tab-country {
  margin: 0 0 16px;
  display: flex;
  height: 30px;
  width: calc(100% - 200px);
  line-height: 30px;
  ::v-deep .ant-tabs-bar {
    border: 0 !important;
  }
  ::v-deep .ant-tabs-nav .ant-tabs-tab {
    margin: 0 8px 0 0 !important;
    padding: 0px 12px !important;
    font-size: 14px !important;
    color: #323643 !important;
    font-weight: 400 !important;
    height: 30px;
    line-height: 30px;
    animation: none !important;
    transition: color 0s !important;
    &:hover {
      color: #ffffff !important;
      background: #3960df;
      font-weight: bold !important;
      box-shadow: 0 0 8px 0 rgba(154, 177, 255, 0.58);
      border-radius: 15px;
      animation: none !important;
    }
  }
  ::v-deep .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    color: #ffffff !important;
    font-weight: bold !important;
    background: #3960df !important;
    box-shadow: 0 0 8px 0 rgba(154, 177, 255, 0.58) !important;
    border-radius: 15px !important;
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .ant-tabs-nav .ant-tabs-ink-bar {
    display: none !important;
  }

  .tab-item {
    padding: 0 12px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #545d75;
    font-weight: bold;
    cursor: pointer;
  }
  .active {
    color: #ffffff;
    font-weight: bold;
    background: #3960df;
    box-shadow: 0 0 8px 0 rgba(154, 177, 255, 0.58);
    border-radius: 15px;
  }
}

.cunt-product {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.empty-box {
  margin: 30px auto !important;
}
.more-product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #fffaf2;
  box-shadow: 4px 4px 16px 0 rgba(144, 155, 190, 0.13);
  border-radius: 8px;
  font-size: 14px;
  color: #cd5f00;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  animation: all 0.2s;
  &:hover {
    box-shadow: 2px 2px 16px 0px rgba(57, 96, 223, 0.15);
    transform: scale(1.01);
  }
}

.iconfont {
  margin-left: 6px;
  font-size: 8px;
  color: #cd5f00;
  height: 40px;
  line-height: 40px;
  font-weight: normal;
}
</style>
