<template>
  <div :class="{ isHomePage: isHomePage }">
    <div v-if="isHomePage" :style="{ marginBottom: '20px' }">
      <div class="index-title">
        {{ $t('servuce_tran_040') }}
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: '异常概况' }
              })
            }
          "
        />
      </div>
      <div class="b-more" @click="goDetail">
        {{ $t('more') }}
        <div class="arrow-right"></div>
      </div>
    </div>
    <div class="data-view flex-sp">
      <div
        class="data-view-item"
        :class="{ f1: !isHomePage }"
        v-for="(i, k) in countList"
        :key="k"
      >
        <div class="name flex-start">
          <span class="name-word">{{ i.name }}</span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ i.tip }}</span>
            </template>
            <a-icon type="question-circle" class="question" />
          </a-tooltip>
        </div>
        <div class="data">{{ i.value }}</div>
        <div class="percent" v-if="i.perValue === '-'">
          <span class="per">-</span>
        </div>
        <div class="percent up" v-else-if="i.perValue === 0">
          <span class="word">{{ $t('user_tran_124') }}</span>
        </div>
        <div class="percent up" v-else-if="i.perValue > 0">
          <span><i class="iconfont iconshangzhang"></i></span>
          <span class="per">{{ i.perValue * 100 }}%</span>
          <span class="word">{{ $t('user_tran_125') }}</span>
        </div>
        <div class="percent down" v-else>
          <span><i class="iconfont iconxiajiang"></i></span>
          <span class="per">{{ Math.abs(i.perValue) * 100 }}%</span>
          <span class="word">{{ $t('user_tran_126') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    isHomePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allData: {},
      dataTime: moment()
        .add(-1, 'days')
        .format('YYYY-MM-DD'),
      countList: [
        {
          value: '-',
          name: this.$t('servuce_tran_042'),
          dataIndex: 'ab_cnt',
          perIndex: 'allAnomalousComp',
          perValue: '-',
          tip: this.$t('servuce_tran_045'),
          abType: '',
          active: true
        },
        {
          value: '-',
          name: this.$t('StorageException.four_px'),
          dataIndex: 'iw_cnt',
          perIndex: 'iwAnomalousComp',
          perValue: '-',
          tip: this.$t('servuce_tran_046'),
          abType: 'in',
          active: false
        },
        {
          value: '-',
          name: this.$t('servuce_tran_043'),
          dataIndex: 'ow_cnt',
          perIndex: 'owAnomalousComp',
          perValue: '-',
          tip: this.$t('servuce_tran_047'),
          abType: 'out',
          active: false
        },
        {
          value: '-',
          name: this.$t('servuce_tran_044'),
          dataIndex: 'att_cnt',
          perIndex: 'attAnomalousComp',
          perValue: '-',
          tip: this.$t('servuce_tran_048'),
          abType: 'rec',
          active: false
        }
      ]
    }
  },
  created() {
    this.queryCount()
  },
  methods: {
    moment,
    queryCount() {
      this.$post('v2/anomalous/count', {
        stDate: this.dataTime
      }).then(res => {
        if (res && res.data) {
          const { data } = res
          const count = data.rows[0]
          this.countList = this.countList.map(e => ({
            ...e,
            value: count[e.dataIndex],
            perValue: count[e.perIndex]
          }))
        }
      })
    },
    goDetail() {
      this.$router.push('/abnormal')
    }
  }
}
</script>

<style lang="less" scoped>
.data-view {
  margin-bottom: 18px;
}
.data-view-item {
  width: 49%;
  height: 130px;
  padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #edeef0;

  .name {
    font-size: 16px;
    color: #35405a;
    line-height: 1;
    span {
      padding-right: 8px;
    }
    .name-word {
      font-weight: bold;
    }
    .question {
      color: #999;
      font-size: 12px;
    }
  }
  .data {
    font-size: 40px;
    color: #313b52;
    padding: 12px 0;
    line-height: 1;
    font-weight: 600;
  }
  .percent {
    line-height: 1;
    font-size: 16px;
    .word {
      color: #545d75;
      padding-left: 4px;
    }
    .per {
      padding-left: 2px;
      font-weight: bold;
    }
    &.up {
      color: #f85359;
    }
    &.down {
      color: #005adc;
    }
  }
}

.f1 {
  flex: 1;
  height: 144px;
  padding: 24px;
  margin-right: 16px;
  margin-bottom: 0;
  &:last-child {
    margin-right: 0;
  }
  border: none;
}

.isHomePage {
  width: 66.67%;
  height: 332px;
  background: #fff;
  margin-right: 20px;
  padding: 20px;
  padding-bottom: 0;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: auto;
  border-radius: 8px;

  .data-view-item {
    height: 118px;
    padding: 10px 24px;
  }
}
</style>
