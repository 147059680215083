<template>
  <div class="section">
    <div class="s-tit">{{ $t('experience_tran_004') }}</div>
    <div class="tag-box">
      <a
        :href="`${AppENV != 'development' ? '/dist' : ''}${item.link}`"
        class="tag-item"
        v-for="(item, index) in linkData"
        :key="index"
      >
        <i class="iconfont" :class="item.class"></i>
        <span class="text">{{ $t(item.nameKey) }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FastEntry',
  components: {},
  computed: {
    isOpenFB4() {
      return this.$store.state.userInfo.isFB4Open
    },
    isCloseFB4() {
      return this.$store.state.brandStatus.FB4
    },
  },
  data() {
    return {
      AppENV: process.env.NODE_ENV,
      linkData: [
        {
          nameKey: 'sgOrder.title2',
          class: { icondanpiaoxiadan1: true },
          link: '/to_create',
        },
        {
          nameKey: 'order.title1',
          class: { iconwodedingdan: true },
          link: '/managementinfo_received',
        },
        {
          nameKey: 'experience_tran_006',
          class: { iconlanshouyuyue: true },
          link: '/collectManager',
        },
        {
          nameKey: 'consumeList',
          class: { iconxiaofeiliushui: true },
          link: '/consumeList',
        },
        {
          nameKey: 'myBills',
          class: { iconwodezhangdan1: true },
          link: '/myBills',
        },
        {
          nameKey: 'myserviceList',
          class: { iconwodefuwudan: true },
          link: '/myserviceList',
        },
      ],
      spinning: false,
    }
  },
  created() {},
  methods: {},
}
</script>

<style lang="less" scoped>
.section {
  margin-bottom: 24px;
  width: 100%;
  height: 162px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .s-tit {
    margin-bottom: 20px;
    width: 100%;
    height: 18px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 900;
    color: #000000;
    line-height: 18px;
  }
  .tag-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .tag-item {
    margin-right: 16px;
    margin-bottom: 12px;
    width: calc(100% / 3 - 32px / 3);
    height: 36px;
    text-align: center;
    background: rgba(57, 96, 223, 0.06);
    border-radius: 4px;
    .iconfont {
      width: 15px;
      height: 15px;
      margin-right: 4px;
      color: #3960df;
    }
    .text {
      height: 36px;
      line-height: 36px;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: #545d75;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
    }
  }
  .tag-item:nth-child(3n) {
    margin-right: 0;
  }
}
</style>
