<template>
  <div class="mask" v-if="visible">
    <div class="modal-content-box">
      <img class="modal-content-bg" src="./images/minprogram.png" alt="" />
      <div class="btn-box">
        <a v-if="minAppUrl" class="btn-go" :href="minAppUrl" @click="hidePopup" target="_blank">前往小程序</a>
        <a-button v-else class="btn-go" type="primary" @click="handlerJumpMinApp">
          前往小程序
        </a-button>
        <a-button class="btn-cancel" @click="hidePopup">
          以后再说
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Minprogramdrainage',
  data() {
    return {
      visible: false,
      minAppUrl: ''
    }
  },
  watch:{
  '$store.getters.getDSSOpen':{
    handler:function(newVal,oldVal){
        if(newVal||oldVal){
          this.initHandle()
        }
      },
    deep: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.initHandle()
    }, 3000)
  },
  methods: {
    initHandle(){
      if (this.isMobileDevice()  && this.$store.getters.getDSSOpen) {
        this.showPopup()
      }
    },
    isMobileDevice() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
        return true
      } else {
        return false
      }
    },
    async showPopup() {
      this.$post('v2/customer/isSignSubscribeNote', {
        type: 'jumpApplet'
      })
        .then(async res => {
          this.$hide()
          if (res?.signSubscribe != true) {
            await this.getMinprogramUrl()
            this.visible = true
          }
        })
        .catch(() => {
          this.$hide()
        })
    },
    async hidePopup() {
      // 开通直发业务
      return this.$post('v2/customer/insertSubscribeRecord', {
        type: 'jumpApplet'
      })
        .then(() => {
          this.$hide()
          this.visible = false
          return true
        })
        .catch((err) => {
          this.$hide()
          return Promise.reject(err)
        })
    },
    async handlerJumpMinApp() {
      let jumpLink = null
      try {
        jumpLink = await this.getMinprogramUrl()
      } catch {
        this.$message.error('跳转链接请求错误')
      }
      if (jumpLink) {
        window.open(jumpLink)
      } else {
        this.$message.error('跳转链接请求错误')
      }
    },
    async getMinprogramUrl() {
      this.$show()
      return this.$post('v2/generate/link', {
        jumpPagePath: 'pages/home/index/index' //跳转小程序的页面
      })
        .then(async res => {
          this.$hide()
          this.minprogramUrl = res?.jumpLink
          if (res?.jumpLink) {
            this.minAppUrl = res?.jumpLink
            return res?.jumpLink
          } else {
            this.$message.error(res?.message || '跳转链接请求错误')
            return Promise.reject(res)
          }
        })
        .catch(res => {
          this.$hide()
          this.$message.error(res?.message || '跳转链接请求错误')
          return Promise.reject(res)
        })
    },
  }
}
</script>
<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.modal-content-box {
  left: 50%;
  top: 50vh;
  position: absolute;
  margin-top: -492px;
  margin-left: -320px;
  width: 640px;
  height: 984px;
  background: #fff;
  border-radius: 16px;
  transform: scale(0.5, 0.5);
  -webkit-transform: scale(0.5, 0.5);

  .modal-content-bg {
    width: 640px;
    height: 984px;
    z-index: 1000;
  }

  .btn-box {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 54px;
    text-align: center;

    .btn-go {
      display: block;
      margin: 0 auto;
      width: 544px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      background: #3960df;
      color: #fff;
      border-radius: 40px;
      font-size: 32px;
    }

    .btn-cancel {
      margin-top: 36px;
      font-size: 28px;
      border: 0;
      color: #767d91;
      background: transparent;
    }
  }
}
</style>
