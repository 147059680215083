// 注销账户
export default [
  // 注销账户选择原因
  {
    path: '/unsubscribeChoice',
    name: 'unsubscribeChoice',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "airWaybill" */ '../views/userCenter/unsubscribe/unsubscribeChoice.vue'
      )
  },
  // 注销账户状态选择
  {
    path: '/popUpWindows',
    name: 'popUpWindows',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "airWaybill" */ '../views/userCenter/unsubscribe/popUpWindows.vue'
      )
  }
]
