<!--
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-08-28 18:06:24
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-08-28 18:33:09
-->
<i18n>
{
  "zh": {
    "signingTitle": "新建联系人",
    "signingTitle2": "编辑联系人",
    "name":"姓名",
    "phone":"联系电话",
    "email":"邮箱"

  },
  "en": {
    "signingTitle": "New contact",
    "signingTitle2": "Edit Contact",
    "name":"Full name",
    "phone":"Contact number",
    "email":"Email"
  },
  "de": {
    "signingTitle": "Neue Kontaktperson",
    "signingTitle2": "Kontaktperson bearbeiten",
    "name": "Name",
    "phone": "Kontaktnummer",
    "email": "E-Mail"
  },
  "ja": {
    "signingTitle": "新しい担当者",
    "signingTitle2": "コンタクトパーソンを編集する",
    "name": "名称",
    "phone": "お問い合わせ番号",
    "email": "電子メール"
  }
}
</i18n>

<template>
  <div class="signing-box">
    <a-modal
      width="480px"
      :visible="visible"
      :bodyStyle="{ 'padding-top': 0 }"
      :maskClosable="false"
      :okText="$t('storage')"
      @cancel="cancleModal"
      :footer="null"
    >
      <template slot="title">
        <div class="tit-box">
          <template v-if="isEdit">
            {{ $t('signingTitle2') }}
          </template>
          <template v-else>
            {{ $t('signingTitle') }}
          </template>
          <!-- {{  ? $t('signingTitle') : $t('signingTitle1') }} -->
          <div class="tag" v-if="isEdit" @click="createContacts">
            {{ $t('p.t2') }}
          </div>
        </div>
      </template>
      <a-form-model ref="ruleForm" :model="addressData" :rules="rules">
        <a-form-model-item
          :label="$t('name')"
          required
          prop="name"
          :wrapper-col="{ span: 24 }"
        >
          <a-input v-model="addressData.name" />
        </a-form-model-item>
        <a-form-model-item
          :label="$t('phone')"
          required
          prop="mobile"
          :wrapper-col="{ span: 24 }"
        >
          <a-input v-model="addressData.mobile" />
        </a-form-model-item>
        <a-form-model-item
          :label="$t('email')"
          required
          prop="email"
          :wrapper-col="{ span: 24 }"
        >
          <a-input v-model="addressData.email" />
        </a-form-model-item>
        <a-form-model-item :style="{ 'text-align': 'right' }">
          <a-button
            type="default"
            :style="{ margin: '0 24px' }"
            @click="cancleModal"
          >
            {{ $t('cancel') }}</a-button
          >
          <a-button type="primary" @click="onSubmit">
            {{ $t('storage') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'CreateContacts',
  props: {
    edit: {
      type: String,
    },
    sleContactsItem: {
      type: Object,
    },
  },
  data() {
    return {
      isEdit: true,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      rules: {
        name: [
          { required: true, message: '请填写姓名', trigger: 'change' },
          { pattern: /^[^\s]*$/, message: '禁止输入空格' },
        ],
        mobile: [
          {
            required: true,
            message: '联系电话不能为空',
            trigger: 'change',
          },
          // {
          //   pattern: /^(((0\d{2,3}-\d{7,8})|(0\d{2,3}\d{7,8}))|(1[123567894]\d{9}))$/,
          //   message: '请输正确手机号或电话号',
          //   trigger: 'change'
          // }
        ],
        email: [
          { required: true, message: '请填写邮箱', trigger: 'change' },
          { pattern: /^[^\s]*$/, message: '禁止输入空格' },
        ],
      },
      chinaData: [],
      visible: true,
      addressValidata: false,
      addressArr: [],
      addressData: {
        id: '',
        name: '',
        contactPhonel: '',
        email: '',
      },
    }
  },
  mounted() {
    if (
      this.sleContactsItem &&
      this.sleContactsItem != null &&
      this.sleContactsItem.id
    ) {
      this.addressData = {
        id: this.sleContactsItem.id,
        name: this.sleContactsItem.name,
        mobile: this.sleContactsItem.mobile.replace('+86-', ''),
        email: this.sleContactsItem.email,
      }
    } else {
      this.createContacts()
    }
  },
  created() {},
  methods: {
    createContacts() {
      this.isEdit = false
      this.addressData = {
        id: '',
        name: '',
        contactPhonel: '',
        email: '',
      }
    },
    cancleModal() {
      this.visible = false
      this.$emit('cancel')
    },
    onChange(value) {
      this.addressArr = value
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.handleSubmit()
        } else {
          this.$message.warning('请检查数据是否填写完整')
          return false
        }
      })
    },
    handleSubmit() {
      let addressData = this.addressData
      let params = {
        ...addressData,
      }
      this.isEdit ? this.setEditAddress(params) : this.submitAddress(params)
    },
    submitAddress(params) {
      this.$show()
      delete params.id
      this.$post('v2/bussinessInfo/saveContact', params)
        .then(() => {
          this.$hide()
          this.$message.success('新建联系人成功！')
          this.visible = false
          this.$emit('ok')
        })
        .catch(() => {
          this.$hide()
        })
    },
    setEditAddress(params) {
      this.$post('v2/bussinessInfo/updateContact', params)
        .then(() => {
          this.$hide()
          this.$message.success(this.$t('index_tran_102'))
          this.visible = false
          this.$emit('ok')
        })
        .catch(() => {
          this.$hide()
        })
    },
  },
}
</script>

<style lang="less" scoped>
.tag {
  padding: 0 6px;
  display: inline-block;
  min-width: 40px;
  height: 21px;
  font-size: 14px;
  color: #015adc;
  border-radius: 2px;
  line-height: 21px;
  background: rgba(57, 96, 223, 0.2);
  cursor: pointer;
}
</style>
