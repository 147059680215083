export default [
  {
    path: '/overseasSearch',
    name: 'overseasSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "overseasSearch" */ '@v/serviceCenter/overseasSearch.vue'
      )
  },
  {
    path: '/directSearch',
    name: 'directSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "directSearch" */ '@v/serviceCenter/directSearch.vue'
      )
  },
  {
    path: '/directClaim',
    name: 'directClaim',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "directClaim" */ '@v/serviceCenter/directClaim.vue'
      )
  },
  {
    path: '/overseasClaim',
    name: 'overseasClaim',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "overseasClaim" */ '@v/serviceCenter/overseasClaim.vue'
      )
  },
  {
    path: '/overseasService',
    name: 'overseasService',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "overseasService" */ '@v/serviceCenter/overseasService.vue'
      )
  },
  {
    path: '/myserviceList',
    name: 'myserviceList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "myserviceList" */ '@v/serviceCenter/myserviceList.vue'
      )
  },
  {
    path: '/myserviceList',
    name: 'all',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "all" */ '@v/serviceCenter/myserviceList.vue')
  },
  {
    path: '/myserviceList',
    name: 'pending',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pending" */ '@v/serviceCenter/myserviceList.vue'
      )
  },
  {
    path: '/myserviceList',
    name: 'accepting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accepting" */ '@v/serviceCenter/myserviceList.vue'
      )
  },
  {
    path: '/myserviceList',
    name: 'resolved',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "resolved" */ '@v/serviceCenter/myserviceList.vue'
      )
  },
  {
    path: '/myserviceList',
    name: 'returned',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "returned" */ '@v/serviceCenter/myserviceList.vue'
      )
  },
  {
    path: '/complaint',
    name: 'complaint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "complaint" */ '@v/serviceCenter/complaint.vue'
      )
  },
  // {
  //   path: '/consultingService',
  //   name: 'consultingService',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "consultingService" */ '@v/serviceCenter/consultingService.vue'
  //     )
  // },
  {
    path: '/addService',
    name: 'addService',
    meta: {
      ignore: true
    },
    component: () => import('@v/serviceCenter/addServices/addService')
  },
  {
    //海外仓
    path: '/overseaLocation',
    name: 'overseaLocation',
    meta: {
      ignore: true
    },
    component: () => import('@v/serviceCenter/addServices/overseaLocation')
  },
  {
    //直发
    path: '/dssLocation',
    name: 'dssLocation',
    meta: {
      ignore: true
    },
    component: () => import('@v/serviceCenter/addServices/dssLocation')
  },
  {
    //直发
    path: '/trsLocation',
    name: 'trsLocation',
    meta: {
      ignore: true
    },
    component: () => import('@v/serviceCenter/addServices/trsLocation')
  },
  {
    path: '/warehouseRent',
    name: 'warehouseRent',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "warehouseRent" */ '@v/serviceCenter/selfService/warehouseRent.vue'
      )
  },
  {
    path: '/freight',
    name: 'freight',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "freight" */ '@v/serviceCenter/selfService/FreightIndex.vue'
      )
  },
  {
    path: '/oldDssFreight',
    name: 'oldDssFreight',
    meta: {
      ignore: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "oldDssFreight" */ '@v/serviceCenter/selfService/components/Freight.vue'
        // /* webpackChunkName: "oldDssFreight" */ '@microDss/view/spreadsheet/Spreadsheet'
      )
  },
  {
    path: '/dssFreight',
    name: 'dssFreight',
    component: () =>
      import(
        /* webpackChunkName: "dssFreight" */ '@v/serviceCenter/dssFreight/index.vue'
      )
  },
  // 本期暂时隐藏-此菜单是新增的，线上环境尚未有配置对应的菜单，下期显示，请配置线上菜单
  {
    path: '/usFreight',
    name: 'usFreight',
    component: () =>
      import(
        /* webpackChunkName: "USFreight" */ '@v/serviceCenter/selfService/USFreight.vue'
      )
  },
  {
    path: '/settlementOfClaimsDetail',
    name: 'settlementOfClaimsDetail',
    meta: {
      ignore: true,
      title: '理赔服务单详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "settlementOfClaimsDetail" */ '@v/serviceCenter/serviceListDetail/settlementOfClaimsDetail.vue'
      )
  },
  {
    path: '/checkServiceDetail',
    name: 'checkServiceDetail',
    meta: {
      ignore: true,
      title: '查询服务单详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "checkServiceDetail" */ '@v/serviceCenter/serviceListDetail/checkServiceDetail.vue'
      )
  },
  {
    path: '/serviceListDetail',
    name: 'serviceListDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "serviceListDetail" */ '@v/serviceCenter/serviceListDetail/serviceListDetail.vue'
      )
  },
  {
    path: '/complaintDetail',
    name: 'complaintDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "complaintDetail" */ '@v/serviceCenter/serviceListDetail/complaintDetail.vue'
      )
  },
  {
    path: '/consultingDetail',
    name: 'consultingDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "consultingDetail" */ '@v/serviceCenter/serviceListDetail/consultingDetail.vue'
      )
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () =>
      import(
        /* webpackChunkName: "serviceListDetail" */ '@v/serviceCenter/feedback/Feedback'
      )
  },
  {
    path: '/trajectorySubscribe',
    name: 'trajectorySubscribe',
    component: () =>
      import(
        /* webpackChunkName: "serviceListDetail" */ '@v/serviceCenter/trajectoryService/trajectorySubscribe'
      )
  }
  // {
  //   path: '/material_apply',
  //   name: 'material_apply',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "material_apply" */ '@v/serviceCenter/material/apply/index.vue'
  //     )
  // },
]
