<!--
 * @Description: 客服热线
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 17:47:32
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-10 21:52:16
-->
<i18n>
{
  "zh": {
    "customer.service": "客户服务",
    "exclusive.salesman": "专属业务员",
    "complaints.hotline": "投诉热线",
    "tips": "关注官方微信公众号 获得更多资讯"
  },
  "en": {
    "customer.service": "Customer service",
    "exclusive.salesman": "Exclusive salesman",
    "complaints.hotline": "Complaints Hotline",
    "tips": "Follow the official WeChat official account for more information"
  },
  "de": {
    "customer.service": "Kundenservice",
    "exclusive.salesman": "Exklusiver Verkäufer",
    "complaints.hotline": "Beschwerdehotline",
    "tips": "Folgen Sie dem offiziellen WeChat-Konto für weitere Informationen"
  },
  "ja": {
    "customer.service": "カスタマーサービス",
    "exclusive.salesman": "専属業務員",
    "complaints.hotline": "苦情ホットライン",
    "tips": "公式ウィーチャット公式アカウントに注目して詳細情報を入手"
  }
}
</i18n>
<template>
  <div class="customer">
    <!-- <div class="index-title">{{ $t('customer.service') }}</div> -->
    <div class="tel">
      <div class="tel-img">
        <img
          src="../../../assets/customer/customer.png"
          alt=""
          class="code-img"
        />
      </div>
      <div class="tel-info">
        <div>{{ $t('exclusive.salesman') }}</div>
        <div>
          {{ name }}<span>{{ tel }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="tel mt-25">
      <div class="tel-img">
        <img src="../../../assets/customer/tel.png" alt="" class="code-img" />
      </div>
      <div class="tel-info">
        <div>{{ $t('complaints.hotline') }}</div>
        <div><span class="pl-0">400-002-5566</span></div>
      </div>
    </div> -->
    <!-- <div class="tel mt-25" v-if="!$store.getters.isOverSeas">
      <div class="tel-img">
        <img src="../../../assets/customer/wc.png" alt="" class="code-img" />
      </div>
      <div class="tel-info">
        <div>{{ $t('tips') }}</div>
        <div class="mt-7">
          <img
            src="../../../assets/customer/4px_code.png"
            alt=""
            class="code-img"
          />
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'customer',
  data() {
    return {
      name: '-',
      tel: '-'
    }
  },
  mounted() {
    this.$get('v2/common/getSalesInfo').then(res => {
      this.name = res?.name
      this.tel = res?.phoneNumber
    })
  }
}
</script>

<style lang="less" scoped>
.customer {
  margin: -20px;
  width: 340px;
  height: 21.42%;
  // min-height: 332px;
  // min-height: 220px;
  min-height: 80px;
  background: #fff;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  .index-title {
    font-weight: 800;
    color: #000000 100%;
    letter-spacing: 0.4;
    font-size: 16px;
  }
  .tel {
    // margin-top: 20px;
    .tel-img {
      width: 48px;
      height: 48px;
      float: left;
      margin-right: 12px;
      img {
        width: 100%;
      }
    }
    .tel-info {
      margin-left: 12px;
      font-size: 14px;
      div:first-child {
        color: #545d75;
      }
      div:last-child {
        color: #999999;
        margin-top: 6px;
        span {
          color: #3960df;
          padding-left: 10px;
        }
      }
    }
  }
  .mt-25 {
    margin-top: 25px;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
  .mt-7 {
    margin-top: 7px;
  }
  .code-img {
    width: 100px;
    height: auto;
  }
}
</style>
