<i18n>
{
  "zh": {
    "signingTitle": "编辑地址",
    "address":"通讯地址",
    "detailAddress":"填写详细地址"

  },
  "en": {
    "signingTitle": "Edit address",
    "address":"Postal address",
    "detailAddress":"Fill in the detailed address"
  },
   "de": {
    "signingTitle": "Anschrift der Redaktion",
    "address": "Korrespondenzadresse",
    "detailAddress": "Füllen Sie die Adressdaten aus"
  },
  "ja": {
    "signingTitle": "編集部アドレス",
    "address": "対応アドレス",
    "detailAddress": "住所の詳細を記入する"
  }
}
</i18n>

<template>
  <div class="signing-box">
    <a-modal
      width="480px"
      :visible="visible"
      :title="$t('signingTitle')"
      :bodyStyle="{ 'padding-top': 0 }"
      :maskClosable="false"
      :okText="$t('storage')"
      @cancel="cancleModal"
      :footer="null"
    >
      <a-form-model ref="ruleForm" :model="addressData" :rules="rules">
        <a-form-model-item
          :label="$t('index_tran_009')"
          required
          :wrapper-col="{ span: 24, offset: 0 }"
          :class="{ 'has-error': addressValidata }"
        >
          <a-cascader
            :show-search="{ filter }"
            :options="chinaData"
            :load-data="loadData"
            v-model="addressData.cascaderCode"
            :default-value="addressArr"
            :placeholder="$t('index_tran_010')"
            change-on-select
            @change="onChange"
          />
          <div
            v-if="addressValidata && addressData.cascaderCode[0] != 485"
            class="ant-form-explain"
          >
            {{ $t('index_tran_011') }}
          </div>
          <div
            v-if="addressValidata && addressData.cascaderCode[0] == 485"
            class="ant-form-explain"
          >
            {{ $t('index_tran_012') }}
          </div>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('index_tran_013')"
          required
          prop="address"
          :wrapper-col="{ span: 24 }"
        >
          <a-input v-model="addressData.address" />
        </a-form-model-item>
        <a-form-model-item :style="{ 'text-align': 'right' }">
          <a-button
            type="default"
            :style="{ margin: '0 24px' }"
            @click="cancleModal"
          >
            {{ $t('cancel') }}</a-button
          >
          <a-button type="primary" @click="onSubmit">
            {{ $t('storage') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import jsonp from 'jsonp'
export default {
  name: 'EditAddress',
  props: {
    editAddress: {
      type: Boolean,
    },
    defAddress: {
      type: Object,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      rules: {
        addressCode: [
          {
            type: 'array',
            message: this.$t('please.select.an.address'),
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('servuce_tran_017'),
            trigger: 'change',
          },
          // { pattern: /^[^\s]*$/, message: '禁止输入空格' },
        ],
      },
      chinaData: [],
      visible: true,
      addressValidata: false,
      addressArr: [],
      addressData: {
        cascaderCode: [],
        address: '',
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        county: '',
        countyCode: '',
        code: '',
        contact: '',
      },
    }
  },
  watch: {
    addressArr() {
      if (this.addressArr[0] != 485 || this.addressArr.length > 3) {
        this.addressValidata = false
      }
    },
  },
  mounted() {
    if (this.editAddress) {
      this.addressData.address = this.defAddress.address
    }
  },
  created() {
    this.getCountry()
    this.editAddress ? this.getAddress() : ''
  },
  methods: {
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      )
    },
    cancleModal() {
      this.visible = false
      this.$emit('cancel')
    },
    onChange(value) {
      this.addressArr = value
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.addressData.cascaderCode.length > 0) {
            if (
              this.addressData.cascaderCode[0] == 485 &&
              this.addressData.cascaderCode.length < 4
            ) {
              this.addressValidata = true
              this.$message.warning(this.$t('index_tran_012'))
            } else {
              this.addressValidata = false
              this.handleSubmit()
            }
          } else {
            this.addressValidata = true
            this.$message.warning(this.$t('please.select.an.address'))
          }
        } else {
          if (this.addressData.cascaderCode.length < 1) {
            this.addressValidata = true
          }
          this.$message.warning(this.$t('index_tran_050'))
          return false
        }
      })
    },
    handleSubmit() {
      let pcaName = this.getPCQName()
      let addressData = this.addressData
      let params = {
        // country: '中国大陆',
        // countryCode: 485,
        countryCode: addressData.cascaderCode[0],
        country: pcaName[0],
        stateCode:
          addressData.cascaderCode.length > 1
            ? addressData.cascaderCode[1]
            : '',
        state: addressData.cascaderCode.length > 1 ? pcaName[1] : '',
        cityCode:
          addressData.cascaderCode.length > 2
            ? addressData.cascaderCode[2]
            : '',
        city: addressData.cascaderCode.length > 2 ? pcaName[2] : '',
        countyCode:
          addressData.cascaderCode.length > 3
            ? addressData.cascaderCode[3]
            : '',
        county: addressData.cascaderCode.length > 3 ? pcaName[3] : '',
        address: addressData.address,
      }
      this.setEditAddress(params)
      // this.editAddress
      //   ? this.setEditAddress(params)
      //   : this.submitAddress(params)
    },
    submitAddress(params) {
      this.$show()
      this.$post('web/address/add', params)
        .then(() => {
          this.$hide()
          this.$message.success(this.$t('servuce_tran_018'))
          this.$emit('ok')
        })
        .catch(() => {
          this.$hide()
        })
    },
    setEditAddress(params) {
      this.$post('v2/user/updateCustomerAddr', params)
        .then(() => {
          this.$hide()
          this.$message.success(this.$t('servuce_tran_019'))
          this.$emit('ok')
        })
        .catch(() => {
          this.$hide()
        })
    },
    // 获取省市区名称
    getPCQName() {
      var pcaName = []
      var cascaderCode = this.addressData.cascaderCode
      this.chinaData.forEach((item) => {
        if (item.key == cascaderCode[0]) {
          pcaName.push(item.label)
          item.children &&
            item.children.forEach((cItem) => {
              if (cascaderCode.length > 1 && cItem.key == cascaderCode[1]) {
                pcaName.push(cItem.label)
                cItem.children &&
                  cItem.children.forEach((ccItem) => {
                    if (
                      cascaderCode.length > 2 &&
                      ccItem.key == cascaderCode[2]
                    ) {
                      pcaName.push(ccItem.label)
                      if (ccItem.children && ccItem.children.length > 0) {
                        ccItem.children &&
                          ccItem.children.forEach((cccItem) => {
                            if (
                              cascaderCode.length > 3 &&
                              cccItem.key == cascaderCode[3]
                            ) {
                              pcaName.push(cccItem.label)
                            }
                          })
                      }
                    }
                  })
              }
            })
        }
      })
      return pcaName
    },
    async getAddress() {
      this.$show()
      await this.getCountry()
      let cascaderCode = []
      this.defAddress.countryCode
        ? cascaderCode.push(parseInt(this.defAddress.countryCode))
        : ''
      this.defAddress.stateCode
        ? cascaderCode.push(parseInt(this.defAddress.stateCode))
        : ''
      this.defAddress.cityCode
        ? cascaderCode.push(parseInt(this.defAddress.cityCode))
        : ''
      this.defAddress.countyCode
        ? cascaderCode.push(parseInt(this.defAddress.countyCode))
        : ''
      this.addressData.cascaderCode = cascaderCode
      let addressArr = []
      this.defAddress.countryCode
        ? addressArr.push(parseInt(this.defAddress.countryCode))
        : ''
      this.defAddress.stateCode
        ? addressArr.push(parseInt(this.defAddress.stateCode))
        : ''
      this.defAddress.cityCode
        ? addressArr.push(parseInt(this.defAddress.cityCode))
        : ''
      this.defAddress.countyCode
        ? addressArr.push(parseInt(this.defAddress.countyCode))
        : ''
      this.addressArr = addressArr
      this.initChinaDataload()
    },
    initChinaDataload() {
      let chinaData = this.chinaData
      let cascaderCode = this.addressData.cascaderCode
      jsonp(
        `https://components.i4px.com/component/getChildDistrict?parentId=${cascaderCode[0]}`,
        (err, res) => {
          var cityData = []
          res.data.forEach((item) => {
            cityData.push({
              label:
                this.$i18n.locale != 'zh' ? item.nameEnglish : item.nameChinese,
              value: item.code,
              pid: item.parentId,
              isLeaf: this.addressArr > 2 ? false : false,
              key: item.code,
            })
          })
          chinaData.forEach((citem, index) => {
            if (citem.value == cascaderCode[0]) {
              chinaData[index].children = cityData
            }
          })
          this.chinaData = chinaData
          this.initCity()
          this.$hide()
        }
      )
    },
    initCity() {
      let chinaData = this.chinaData
      let cascaderCode = this.addressData.cascaderCode
      jsonp(
        `https://components.i4px.com/component/getChildDistrict?parentId=${cascaderCode[1]}`,
        (err, res) => {
          var areaData = []
          res.data?.forEach((item) => {
            areaData.push({
              label:
                this.$i18n.locale != 'zh' ? item.nameEnglish : item.nameChinese,
              value: item.code,
              isLeaf: this.addressArr > 3 ? false : false,
              pid: item.parentId,
              key: item.code,
            })
          })
          chinaData.forEach((pItem, pIndex) => {
            if (pItem.value == cascaderCode[0]) {
              chinaData[pIndex].children.forEach((cItem, cIndex) => {
                if (cItem.value == cascaderCode[1]) {
                  chinaData[pIndex].children[cIndex].children = areaData
                }
              })
            }
          })
          this.chinaData = [...this.chinaData]
          cascaderCode.length > 3 ? this.initAera() : ''
        }
      )
    },
    initAera() {
      let chinaData = this.chinaData
      let cascaderCode = this.addressData.cascaderCode
      if (cascaderCode < 4) {
        return false
      }
      jsonp(
        `https://components.i4px.com/component/getChildDistrict?parentId=${cascaderCode[2]}`,
        (err, res) => {
          var areaData = []
          res.data.forEach((item) => {
            areaData.push({
              label:
                this.$i18n.locale != 'zh' ? item.nameEnglish : item.nameChinese,
              value: item.code,
              isLeaf: false,
              pid: item.parentId,
              key: item.code,
            })
          })
          chinaData.forEach((pItem, pIndex) => {
            if (pItem.value == cascaderCode[0]) {
              chinaData[pIndex].children.forEach((cItem, cIndex) => {
                if (cItem.value == cascaderCode[1]) {
                  //
                  chinaData[pIndex].children[cIndex].children.forEach(
                    (aItem, aIndex) => {
                      if (aItem.value == cascaderCode[2]) {
                        chinaData[pIndex].children[cIndex].children[
                          aIndex
                        ].children = areaData
                      }
                    }
                  )
                }
              })
            }
          })
          this.chinaData = [...this.chinaData]
        }
      )
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      targetOption.isLeaf = true
      var isLeaf = false
      if (!targetOption.value || this.addressArr.length > 3) {
        targetOption.loading = false
        targetOption.isLeaf = true
        return false
      }
      if (this.addressArr.length > 3 || selectedOptions.length >= 3) {
        isLeaf = true
      }

      jsonp(
        `https://components.i4px.com/component/getChildDistrict?parentId=${targetOption.value}`,
        (err, res) => {
          var treeData = []
          res.data.forEach((item) => {
            treeData.push({
              label:
                this.$i18n.locale != 'zh' ? item.nameEnglish : item.nameChinese,
              value: item.code,
              pid: item.parentId,
              isLeaf: isLeaf,
              key: item.code,
            })
          })
          targetOption.children = treeData
          targetOption.loading = false
          this.chinaData = [...this.chinaData]
        }
      )
    },
    getCountry() {
      return new Promise((resolve, reject) => {
        var value = '&_=1645154814347' //485中国大陆
        jsonp(
          `https://components.i4px.com/component/getCountryList?parentId=${value}`,
          (err, res) => {
            var chinaData = []
            res.data.forEach((item) => {
              chinaData.push({
                label:
                  this.$i18n.locale != 'zh'
                    ? item.nameEnglish
                    : item.nameChinese,
                value: item.code,
                pid: item.parentId,
                isLeaf: false,
                key: item.code,
              })
            })
            this.chinaData = chinaData
            resolve(res)
            reject(err)
          }
        )
      })
    },
  },
}
</script>

<style lang="less" scoped>
.detail-item {
  margin: 0 0 20px;
  width: 100%;
  min-height: 48px;
  font-size: 14px;
  .laber {
    font-family: PingFangSC-Regular;
    color: #545d75;
    line-height: 24px;
    opacity: 0.8;
  }
  .text {
    margin-top: 5px;
    line-height: 24px;
    font-family: PingFangSC-Regular;
    color: #323643;
    flex-wrap: 500;
  }
  .detail-address {
    margin-top: 15px;
  }
}
</style>
