<!--
 * @Description: 查询工具
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 17:47:32
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-12 21:03:18
-->
<i18n>
{
  "zh": {
    "tool": "查询工具",
    "track": "轨迹查询",
    "shisuang": "运费试算",
    "ryf": "燃油附件费",
    "com": "航空公司",
    "air": "查询机场",
    "serach": "查询服务",
    "Fuelsurcharge": "燃油附加费"
  },
  "en": {
    "tool": "Query Tool",
    "track": "Track Query",
    "shisuang": "Freight trial calculation",
    "ryf": "Fuel accessory fee",
    "com": "Airline",
    "air": "Query Airport",
    "serach": "Query Service",
    "Fuelsurcharge": "Fuel surcharge"
  },
  "de": {
    "tool": "Abfrage-Tools",
    "track": "Abfragen verfolgen",
    "shisuang": "Tarifversuch",
    "ryf": "Kraftstoff-Zusatzkosten",
    "com": "Fluggesellschaften",
    "air": "Einen Flughafen finden",
    "serach": "Auskunftsdienst",
    "Fuelsurcharge": "Treibstoff-Zuschlag"
  },
  "ja": {
    "tool": "クエリツール",
    "track": "トラッククエリ",
    "shisuang": "タリフ・トライアル",
    "ryf": "燃料付属品料金",
    "com": "航空会社",
    "air": "空港を探す",
    "serach": "お問い合わせサービス",
    "Fuelsurcharge": "燃料サーチャージ"
  }
}
</i18n>
<template>
  <div class="tool">
    <div class="index-title">
      {{ $t('tool') }}
    </div>
    <div class="tool-list">
      <div
        class="item"
        @click="onClickItem(item.href, item.path, item.text)"
        :key="index"
        v-for="(item, index) in tools"
        :data-baidu-pv="item.pv"
      >
        <img
          class="item-img"
          :src="item.url"
          alt=""
          style="width: 56px; height: auto"
        />
        <div class="item-title">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tool',
  data() {
    return {
      tools: [
        {
          text: this.$t('track'),
          url: require('./images/track@2x.png'),
          href: 'http://track.4px.com/#/',
          pv: '首页，轨迹查询，轨迹查询'
        },
        {
          text: this.$t('shisuang'),
          url: require('./images/try@2x.png'),
          path: 'dssFreight',
          pv: '首页，运费试算，运费试算'
        },
        {
          text: this.$t('Fuelsurcharge'),
          url: require('./images/oil@2x.png'),
          href: 'http://express.4px.com/article/detail/id/500073/cid/317',
          pv: '首页，燃油附加费，燃油附加费'
        },
        {
          text: this.$t('com'),
          url: require('./images/aviation@2x.png'),
          href: 'http://express.4px.com/search/category/id/7',
          pv: '首页，航空公司，航空公司'
        },
        {
          text: this.$t('air'),
          url: require('./images/air@2x.png'),
          href: 'http://express.4px.com/search/category/id/6',
          pv: '首页，查询机场，查询机场'
        },
        {
          text: this.$t('serach'),
          url: require('./images/search@2x.png'),
          href: 'http://express.4px.com/search/category/id/5',
          pv: '首页，查询服务，查询服务'
        }
      ]
    }
  },
  methods: {
    onClickItem(href, path, text) {
      if (path) {
        this.$router.push({ name: path })
      } else {
        window.open(href)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tool {
  width: 340px;
  height: 21.42%;
  min-height: 332px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .tool-list {
    margin-top: 14px;
    .item {
      cursor: pointer;
      display: inline-block;
      width: 33.33%;
      text-align: center;
      margin-top: 25px;
      transition: background 0.3s;
      &:hover {
        background: #fafbff;
      }
    }
    .item-img {
      background: #fafbff;
    }
    .item-title {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
      margin-top: 14px;
      margin-bottom: 5px;
    }
  }
}
</style>
