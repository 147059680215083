<!--
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-09-08 15:50:43
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-12-26 17:56:06
-->
<template>
  <div class="brand-item">
    <!-- 未开通、审核不通过（驳回）、 已关闭 -->
    <StatusUnopen
      :info="info"
      v-if="info.status == 0 || info.status == 3 || info.status == 4"
    />
    <!-- 试单  -->
    <!-- <StatusIsTry v-if="info.triedCustomer === 1" :info="info" /> -->
    <StatusIsTry v-if="info.triedCustomer === 1" :info="info" />

    <!-- 待审核 -->
    <StatusAudit v-if="info.status == 1" :info="info" />

    <!-- 审核通过（已开通） -->
    <StatusOpened v-if="info.status == 2 && !info.triedCustomer" :info="info" />
    <!-- 合同签署 -->
  </div>
</template>

<script>
import StatusUnopen from './StatusUnopen'
import StatusAudit from './StatusAudit'
import StatusOpened from './StatusOpened'
import StatusIsTry from './StatusIsTry'
export default {
  name: 'brand-item',
  props: {
    info: {
      type: Object,
    },
  },
  components: {
    StatusUnopen,
    StatusAudit,
    StatusOpened,
    StatusIsTry,
  },
}
</script>

<style lang="less" scoped>
.brand-item {
  flex: 1;
  min-width: 200px;
}
.brand-item:nth-of-type(2) {
  margin: 0 10px;
  @media screen and (max-width: 1400px) {
    margin: 0 2px;
  }
}
.brand-item:nth-of-type(3) {
  margin-right: 10px;
  @media screen and (max-width: 1400px) {
    margin-right: 2px;
  }
}

.brand-DSS {
  background-image: url(../images/pass@2x.png);
}
.brand-FB4 {
  background-image: url(../images/normal@2x.png);
}
.brand-GRS {
  background-image: url(../images/unopen@2x.png);
}
.brand-TRS {
  background-image: url(../images/audit@2x.png);
}
</style>
