/*
 * @Description: util
 * @Autor: S9637/chifuk
 * @Date: 2020-08-27 18:14:35
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-08-05 16:47:08
 */
//  store.getters.isOverSeas  store.getters.veriable
import store from '../store/index'
const isOverSeas = store?.getters?.isOverSeas

/**
 * 流文件下载
 * @param res 字节流
 * @param filename 文件名
 * @returns
 */
const blobToExcel = (res, filename) => {
  const blob = new Blob([res])
  const blobUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  const d = new Date()
  if (filename) filename += '.xlsx'
  a.download =
    filename || `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}.xlsx`
  a.href = blobUrl
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(blobUrl)
}

/**
 * @description 文件下载
 * @param {*} content 文件内容
 * @param {*} fileName 文件名
 * @param {*} suffix 文件格式
 * @returns
 */
const downLoadFileWith = (content, fileName, suffix) => {
  const aEle = document.createElement('a')
  aEle.setAttribute('id', 'sps-dwn')
  const blob = new Blob([content])
  aEle.download = fileName
  if (suffix) aEle.download = `${fileName}.${suffix}`
  document.body.appendChild(aEle)
  aEle.href = URL.createObjectURL(blob)
  aEle.click()
  setTimeout(() => {
    document.body.removeChild(document.getElementById('sps-dwn'))
  }, 300)
}

/**
 * @description: base64 转文件下载
 * @param {type}
 * @return {type}
 */
function dataURItoBlob(dataURI, filename) {
  const MIME = {
    '.png': 'image/png',
    '.jpe': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.jpz': 'image/jpeg',
    '.xls': 'application/vnd.ms-excel',
    '.xlsx':
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.xlt': 'application/vnd.ms-excel',
    '.xlw': 'application/vnd.ms-excel',
    '.zip': 'application/zip',
    '.json': 'application/json',
    '.pdf': 'application/pdf'
  }

  // decode base64 string, remove space for IE compatibility
  var binary = window.atob(dataURI.replace(/\s/g, ''))
  var len = binary.length
  var buffer = new ArrayBuffer(len)
  var view = new Uint8Array(buffer)
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }

  //获取文件的mime类型
  var arr = filename.split('.')
  var mime = MIME['.' + arr[arr.length - 1]]
  // create the blob object with content-type "application/pdf"
  var blob = new Blob([view], { type: mime })
  var eleLink = document.createElement('a')
  eleLink.download = filename
  eleLink.style.display = 'none'
  eleLink.href = URL.createObjectURL(blob)
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}

var funDownload = function(domImg, filename) {
  // 创建隐藏的可下载链接
  var eleLink = document.createElement('a')
  eleLink.download = filename
  eleLink.style.display = 'none'
  // 图片转base64地址
  var canvas = document.createElement('canvas')
  var context = canvas.getContext('2d')
  context.drawImage(domImg, 0, 0)
  // 如果是PNG图片，则canvas.toDataURL('image/png')
  eleLink.href = canvas.toDataURL('image/jpeg')
  // 触发点击
  document.body.appendChild(eleLink)
  eleLink.click()
  // 然后移除
  document.body.removeChild(eleLink)
}

/**
 * @description: 下划线命名转驼峰
 * @param {type}
 * @return {type}
 */
const toCamelCase = field => {
  if (!field) return
  const v = field.split('_')
  if (v.length > 1) {
    return v[0] + `${v[1].substring(0, 1).toUpperCase()}${v[1].substring(1)}`
  }
  return field
}

function iframeReady(iframe, fn) {
  if (iframe.attachEvent) {
    iframe.attachEvent('onload', function() {
      fn && fn.call(iframe)
    })
  } else {
    iframe.onload = function() {
      fn && fn.call(iframe)
    }
  }
}
/**
 * @description: create iframe
 * @param {*} src
 * @param {*} iframe name
 * @return {*}
 */
const createIframe = (src, name, cb) => {
  if (!document.querySelector(`iframe[name=${name}]`)) {
    const ifr = document.createElement('iframe')
    // ifr.style.display = 'none'
    ifr.style.position = 'fixed'
    ifr.style.left = '-1000px'
    ifr.style.top = '-1000px'
    ifr.style.height = '1px'
    ifr.src = src
    ifr.name = name
    document.body.appendChild(ifr)
    if (cb) iframeReady(ifr, cb)
    return false
  }
  return true
}

/**
 * @description: 获取当前域名关键词
 * @param {*}
 * @return {*} eg: b.test.4px.com -> test
 */
const getDomainWorld = () => {
  const host = location.host
  const arr = host.split('.')
  if (arr && arr[0] === 'b-test' && !isOverSeas) return 'test'
  if (arr.length > 3 && !isOverSeas) {
    // dev/test/uat
    return arr[1]
  } else if (arr.length === 3 || isOverSeas) {
    // production
    return 'prod'
  } else {
    // local
    return 'local'
  }
}

const getQueryString = name => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}

/**
 * @description: 创建唯一ID
 * @param {*}
 * @return {*}
 */
const createID = (length = 2) => {
  return Number(
    Math.random()
      .toString()
      .substr(3, length) + Date.now()
  ).toString(36)
}

const getFiled = (lang, item, chinese, eng) => {
  return lang === 'zh' || !item[eng] ? item[chinese] : item[eng]
}

const formartSelect = (data, lang, val, chinese, eng) => {
  // 从data种取到对应语种的值
  const list = (data || []).map(item => ({
    label: getFiled(lang, item, chinese, eng),
    value: item[val]
  }))
  return list
}

const getListValue = (
  list,
  code,
  valueItem = 'value',
  valueLabel = 'label'
) => {
  // 通过code在list知道对应的label值
  if (list && Array.isArray(list) && list.length > 0) {
    let obj = {}
    for (let i = 0; i < list.length; i++) {
      if (list[i][valueItem] === code) {
        obj = list[i]
        return obj[valueLabel]
      }
    }
  }
  return code
}

const flatTree = (list, childrenNode) => {
  // 平铺树
  let res = []
  for (const item of list) {
    const i = item
    const children = item[childrenNode]
    if (children && children.length) {
      res = res.concat(flatTree(children, childrenNode))
    }
    res.push(i)
  }
  return res
}

// 把obj数据转成url参数 比如：{a:1,b:2} => a=1&b=2}
const buildUrlQueryParams = obj => {
  return Object.keys(obj)
    .filter(key => obj[key] !== null && obj[key] !== undefined)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

export {
  blobToExcel,
  downLoadFileWith,
  toCamelCase,
  funDownload,
  dataURItoBlob,
  createIframe,
  getDomainWorld,
  getQueryString,
  createID,
  formartSelect,
  getListValue,
  flatTree,
  buildUrlQueryParams
}
