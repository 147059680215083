export default [
  // 海运委托单列表
  {
    path: '/oceanWaybill',
    name: 'oceanWaybill',
    meta: {
      ignore: true,
      title: '海运委托单列表'
    },
    component: () =>
      import(
        /* webpackChunkName: "oceanWaybill" */ '../views/oceanWaybill/List'
      )
  },
  {
    path: '/createOceanWaybill',
    name: 'createOceanWaybill',
    meta: {
      ignore: true,
      title: '创建海运委托单'
    },
    component: () =>
      import(
        /* webpackChunkName: "createOceanWaybill" */ '../views/oceanWaybill/Create'
      )
  },
  {
    path: '/oceanWaybillDetails',
    name: 'oceanWaybillDetails',
    meta: {
      ignore: true,
      title: '海运委托单详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "oceanWaybillDetails" */ '../views/oceanWaybill/Details'
      )
  },
  {
    path: '/splitOrder',
    name: 'splitOrder',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "splitOrder" */ '../views/oceanWaybill/SplitOrder'
      )
  }
]
