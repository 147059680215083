var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"width":"1200px","placement":"right","wrapClassName":"order-detial","closable":false,"visible":_vm.visible,"maskClosable":false},on:{"close":_vm.onClose}},[_c('div',{staticClass:"detail-box"},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(_vm.$t('product_expression_015')))]),_c('i',{staticClass:"iconfont iconlujing",on:{"click":_vm.onClose}})]),_c('div',{staticClass:"contrast-container"},[(_vm.columns.length < 2)?_c('a-empty',{staticStyle:{"width":"100%","height":"400px","padding":"120px 0"},attrs:{"description":_vm.$t('product_expression_016')}}):_c('a-table',{staticClass:"table-content",attrs:{"bordered":"","columns":_vm.columns,"data-source":_vm.productList,"pagination":false},scopedSlots:_vm._u([_vm._l((_vm.cloumnsAllCol),function(col,colIndex){return {key:col,fn:function(text, record, index){return [(index == 0)?_c('span',{class:{ red: record[`detail_name_${colIndex}_cheapestSort`] }},[_vm._v(_vm._s(text || '-')+" "+_vm._s(_vm.$t('yuan')))]):(index == 1)?_c('span',{class:{
              red: record[`detail_name_${colIndex}_fastestTimeSort`]
            }},[_vm._v(_vm._s(text || '-')+" "+_vm._s(_vm.$t('product_expression_058')))]):(index == 2)?_c('span',{staticClass:"tag",class:{
              tag: true,
              'tag-hb': text == 2,
              'tag-gr': text == 3
            }},[_vm._v(_vm._s(_vm._f("getNameFromValue")(text,_vm.trackTypeArr)))]):(index == 3)?[(text == 1)?_c('span',{staticClass:"tag-g tag"},[_vm._v(_vm._s(_vm.$t('product_expression_017')))]):_c('span',{staticClass:"tag-o tag"},[_vm._v(_vm._s(_vm.$t('product_expression_018')))])]:(index == 4)?[_vm._v(_vm._s(_vm._f("getNameFromValue")(text,_vm.serviceLevelArr)))]:(index == 5)?[_vm._v(" "+_vm._s(text || '-')+"kg")]:(index == 7)?[_vm._v(" "+_vm._s(text || '-')+" USD")]:(index == 8)?_vm._l((text),function(tagItem,tagIndex){return _c('i',{key:tagIndex},[_vm._v(_vm._s(tagIndex > 0 ? '、' : '')+_vm._s(tagItem))])}):[_vm._v(" "+_vm._s(text)+" ")]]}}})],null,true)})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }