<!--
 * @Description: 首页
 * @Autor: S9637/chifuk
 * @Date: 2020-08-28 11:04:25
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-06-01 13:09:09
-->
<i18n>
{
  "zh": {
    "tipdesc": "商家中心进行全新升级啦，在使用过程中有任何疑问可以给我们留言或者向客服反馈哦！"
  },
  "en": {
    "tipdesc": "The merchant center has undergone a brand new upgrade. If you have any questions during the use process, you can leave a message or give feedback to the customer service!"
  },
   "de": {
    "tipdesc": "The merchant center has undergone a brand new upgrade. If you have any questions during the use process, you can leave a message or give feedback to the customer service!"
  },
   "ja": {
    "tipdesc": "The merchant center has undergone a brand new upgrade. If you have any questions during the use process, you can leave a message or give feedback to the customer service!"
  }
}
</i18n>
<template>
  <div class="main-index">
    <Notify />
    <!-- 头部 -->
    <Nav @collapsed="onCollapsed" />
    <!-- 左侧（首页无侧边栏） -->
    <div
      v-if="showSlider"
      :class="!collapsed ? '' : 'slider-collapsed'"
      class="index-slider"
      :style="`top:${hasTopBanner ? '84px' : '56px'}`"
    >
      <Slider
        :collapsed="collapsed"
        @collapsed="onCollapsed"
        :menuItems="menuItems"
      />
    </div>
    <!-- 内容区域 -->
    <div
      :style="
        `${
          showSlider
            ? !collapsed
              ? `width: calc(100% - 224px); ${
                  hasTopBanner ? 'paddingTop: 102px' : 'paddingTop: 74px'
                }`
              : `width: calc(100% - 56px); ${
                  hasTopBanner ? 'paddingTop: 102px' : 'paddingTop: 74px'
                }`
            : `${hasTopBanner ? 'paddingTop: 84px' : 'paddingTop: 56px'}`
        } `
      "
      :class="[
        !showSlider ? 'no-slider' : 'index-content',
        frameView ? 'cnt-no-padding' : ''
      ]"
    >
      <slot></slot>
    </div>
    <!-- 移动端打开商家中心时候，小程序弹窗 -->
    <Minprogramdrainage/>
  </div>
</template>

<script>
import Nav from './Nav'
import Notify from '../index/notify/index'
import Slider from './Slider'
import Minprogramdrainage from '../index/popup/Minprogramdrainage'

export default {
  name: 'main-index',
  components: {
    Nav,
    Slider,
    Minprogramdrainage,
    Notify
  },
  props: {
    showSlider: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    menuItems() {
      var menus =
        process.env.NODE_ENV === 'production'
          ? this.handlerRoutePermission()
          : this.$store.state.menuItems
      return menus
    },
    frameView() {
      return this.$route.name == 'iframeView'
    },
    hasTopBanner() {
      return this.$store.state.hasTopBanner
    }
  },
  data() {
    this.sections = {}
    return {
      collapsed: false
    }
  },
  created() {
    this.getPermissionMenu()
    this.getCustomCode()
  },
  methods: {
    getCustomCode() {
      this.$get('v2/user/accountSecurity').then(res => {
        this.$store.state.customCode = res.userInfo.customCode
      })
    },
    onCollapsed(collapsed) {
      this.collapsed = collapsed
    },
    /**
     * @description: 根据权限数据渲染对应菜单
     * @param {type}
     * @return {type}
     */
    handlerRoutePermission() {
      // ignore 配置不需要校验权限菜单
      // 微应该不需校验
      const routename = this.$route.name
      const isIgnore = this.$route.meta ? this.$route.meta.ignore : false
      if (this.$store.state.ignore.indexOf(routename) > -1 || isIgnore) {
        let routes = [...this.$store.state.menuItems]
        if (!this.$store.getters.getMainAccountStatus) {
          // name = settings 子账号设置
          routes = routes.filter(item => item.name != 'settings')
        }
        return routes
      }

      // 权限数据
      let permissonMenus = this.getCurrentPathMenus()
      // 处理后最终权限菜单
      let newRoutes = []
      this.$store.state.menuItems.forEach(item => {
        if (item.sub) {
          const newItem = {
            name: item.name,
            text: item.text,
            icon: item.icon,
            sub: []
          }
          item.sub.forEach(s => {
            if (permissonMenus.indexOf(s.name) > -1 || item.nolimit) {
              newItem.sub.push(s)
            }
          })
          if (newItem.sub.length) newRoutes.push(newItem)
        } else {
          if (permissonMenus.indexOf(item.name) > -1 || item.nolimit) {
            newRoutes.push(item)
          }
        }
      })

      // 如果是子账号登录，即使有权限菜单也不显示（子账号设置下的菜单）
      // console.log(newRoutes, this.$store.getters.getMainAccountStatus)
      if (!this.$store.getters.getMainAccountStatus) {
        // name = settings 子账号设置
        newRoutes = newRoutes.filter(item => item.name != 'settings')
      }
      return newRoutes
    },
    /**
     * @description: 获取权限菜单
     * @param {type}
     * @return {type}
     */
    getPermissionMenu() {
      // 如果已经存在，则不必重复查询
      const old = sessionStorage.getItem('mnu')
      if (old) {
        const m = JSON.parse(decodeURIComponent(old))
        if (m) {
          this.sections = m
          return
        }
      }
      this.$show()
      this.$get(`v2/resource/menu/all/jKZTWIL6AF6WrpAm`)
        .then(res => {
          this.$hide()
          const list = res || []
          // menu handler
          this.sections = this.generateRoutesNames(list)
          sessionStorage.setItem(
            'mnu',
            encodeURIComponent(JSON.stringify(this.sections))
          )
          // nav premission handler
          this.handlerNav(list)
        })
        .catch(() => {
          this.$hide()
        })
    },
    handlerNav(list) {
      const permissionIds = list.map(item => item.id.toString())
      const nav = this.$store.getters.getNavItems
      let navNew = []
      nav.forEach(item => {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('permissionId')) {
          if (permissionIds.indexOf(item.permissionId) > -1) {
            navNew.push(item)
          }
        } else {
          navNew.push(item)
        }
      })
      if (permissionIds.length === 0) {
        // eslint-disable-next-line no-prototype-builtins
        navNew = navNew.filter(item => !item.hasOwnProperty('permissionId'))
      }
      this.$store.commit('updateNavItems', navNew)
    },
    /**
     * @description: 生成权限菜单，将各组的权限菜单URL（route name）放入数组
     * @param {type}
     * @return {type}
     */
    generateRoutesNames(list) {
      const sections = {
        6232: [], // 首页
        6233: [], // 数据中心
        6234: [], // 我的账户
        6235: [], // 个人中心
        6236: [], // 服务中心
        6237: [], // 消息中心
        6321: [], // 分销管理
        6327: [] // 海运空运
      }

      const keys = Object.keys(sections)
      keys.forEach(id => {
        list.forEach(item => {
          if (item.id == id) {
            if (item.url) {
              sections[id].push(item.url)
            }
            if (item.children) {
              item.children.forEach(c => {
                if (c.url) sections[id].push(c.url)
                if (c.children) {
                  c.children.forEach(s => {
                    sections[id].push(s.url)
                  })
                }
              })
            }
          }
        })
      })
      return sections
    },
    /**
     * @description: 获取当前访问路由下对应的权限集合
     * @param {type}
     * @return {type}
     */
    getCurrentPathMenus() {
      const routeName = this.$route.name
      let sections = this.sections || []

      if (sections.length == 0) {
        const old = sessionStorage.getItem('mnu')
        if (old) {
          const m = JSON.parse(decodeURIComponent(old))
          if (m) {
            this.sections = m
            return
          }
        }
      }

      let res = []

      for (let k in sections) {
        if (sections[k].indexOf(routeName) > -1) {
          res = sections[k]
          break
        }
      }

      // 特殊：处理仅本身是单纯菜单，但却显示为父子结构的菜单
      if (sections['6237'] && sections['6237'].indexOf('msgAll') > -1) {
        sections['6237'] = [...sections['6237'], 'msgFB4', 'msgGRS', 'msgXMS']
      }

      return res
    }
  }
}
</script>

<style lang="less" scoped>
.main-index {
  overflow: auto;
  .index-slider {
    float: left;
    position: fixed;
    width: 224px;
    left: 0;
    bottom: 0;
    padding-bottom: 44px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #ffffff;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.03);
    z-index: 10;
  }
  .index-content {
    float: right;
    padding: 110px 24px 24px 25px;
    margin-top: 48px;
    div:first-child {
      position: relative;
      background-color: #fff;
      padding: 24px;
      min-height: 224px;
      margin-bottom: 24px;
      border-radius: 8px;
    }
  }
  .cnt-no-padding {
    padding: 0;
  }
  .no-slider {
    width: 100%;
    height: 100vh;
  }

  @keyframes collapsedAni {
    from {
      width: 224px;
    }
    to {
      width: 56px;
    }
  }
  .slider-collapsed {
    width: 56px;
    animation: collapsedAni 0.3s;
  }
}
</style>
