<template>
  <a-drawer
    width="1200px"
    placement="right"
    wrapClassName="order-detial"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :maskClosable="false"
  >
    <div class="detail-box">
      <div class="title" slot="title">
        <span>{{ $t('product_expression_015') }}</span>
        <i class="iconfont  iconlujing" @click="onClose"></i>
      </div>
      <div class="contrast-container">
        <a-empty
          v-if="columns.length < 2"
          :description="$t('product_expression_016')"
          style="width: 100%; height: 400px; padding: 120px 0"
        />
        <a-table
          v-else
          bordered
          class="table-content"
          :columns="columns"
          :data-source="productList"
          :pagination="false"
        >
          <template
            v-for="(col, colIndex) in cloumnsAllCol"
            :slot="col"
            slot-scope="text, record, index"
          >
            <span
              v-if="index == 0"
              :class="{ red: record[`detail_name_${colIndex}_cheapestSort`] }"
              >{{ text || '-' }} {{ $t('yuan') }}</span
            >
            <span
              :class="{
                red: record[`detail_name_${colIndex}_fastestTimeSort`]
              }"
              v-else-if="index == 1"
              >{{ text || '-' }} {{ $t('product_expression_058') }}</span
            >
            <!-- 服务类型 -->
            <span
              v-else-if="index == 2"
              class="tag"
              :class="{
                tag: true,
                'tag-hb': text == 2,
                'tag-gr': text == 3
              }"
              >{{ text | getNameFromValue(trackTypeArr) }}</span
            >
            <template v-else-if="index == 3">
              <span v-if="text == 1" class="tag-g tag">{{
                $t('product_expression_017')
              }}</span>
              <span v-else class="tag-o tag">{{
                $t('product_expression_018')
              }}</span>
            </template>
            <template v-else-if="index == 4">{{
              text | getNameFromValue(serviceLevelArr)
            }}</template>
            <!-- 重量限制	 -->
            <template v-else-if="index == 5"> {{ text || '-' }}kg</template>
            <!-- 申报金额限制	 -->
            <template v-else-if="index == 7"> {{ text || '-' }} USD</template>
            <!-- 禁运品类	 -->
            <template v-else-if="index == 8">
              <i v-for="(tagItem, tagIndex) in text" :key="tagIndex"
                >{{ tagIndex > 0 ? '、' : '' }}{{ tagItem }}</i
              >
            </template>
            <template v-else>
              {{ text }}
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import sensors from 'sa-sdk-javascript'
import { burialPoint } from '../burialPoint.services'
import { trackTypeArr, serviceLevelArr } from './data'
const defColumns = vue => [
  {
    title: vue.$t('trajectory_009'),
    width: '90px',
    dataIndex: 'itemName',
    key: 'itemName'
  }
]

const tableData = vue => [
  {
    itemName: vue.$t('product_expression_019'),
    renderKeyItemName: 'totalRmbAmount'
  },
  {
    itemName: vue.$t('SC.prescription'),
    renderKeyItemName: 'deliveryPeriod'
  },
  {
    itemName: vue.$t('product_expression_020'),
    renderKeyItemName: 'trackingType'
  },
  {
    itemName: vue.$t('sgOrder.base.label2'),
    renderKeyItemName: 'isBattery'
  },
  {
    itemName: vue.$t('service.type'),
    renderKeyItemName: 'serviceLevel'
  },
  {
    itemName: vue.$t('product_expression_021'),
    renderKeyItemName: 'minWeight'
  },
  {
    itemName: vue.$t('product_expression_022'),
    renderKeyItemName: 'lwh'
  },
  {
    itemName: vue.$t('product_expression_023'),
    renderKeyItemName: 'minDeclaredValue'
  },
  {
    itemName: vue.$t('product_expression_024'),
    renderKeyItemName: 'prohibitedGoods'
  }
]
export default {
  name: 'ContrastItemDetail',
  props: {
    visible: {
      type: Boolean
    },
    selectProduct: {
      type: Array,
      default: () => []
    },
    allProductList: {
      type: Array,
      default: () => []
    },
    queryParam: {
      type: Object,
      default: () => {}
    },
    countryName: {
      type: String
    },
    cityName: {
      type: String
    }
  },
  data() {
    return {
      tableData: [...tableData(this)],
      trackTypeArr: trackTypeArr(this),
      serviceLevelArr: serviceLevelArr(this),
      defaultActive: 1,
      value1: '',
      cloumnsAllCol: [],
      columns: [],
      filtersProductList: [],
      productList: []
    }
  },
  mounted() {
    this.productList = []
    this.getFiltersProductList()
  },
  methods: {
    getFiltersProductList() {
      const allProductList = this.allProductList || []
      const selectProduct = this.selectProduct || []
      let filtersProductList = []
      let addListCode = []
      allProductList.forEach(element => {
        if (
          selectProduct.includes(element.productCode) &&
          !addListCode.includes(element.productCode)
        ) {
          addListCode.push(element.productCode)
          filtersProductList.push(element)
        }
      })
      // 设置失效和价格排序 start
      filtersProductList
      let minFastestTimeSort = filtersProductList.reduce(
        (min, p) => (p?.timeSort < min ? p?.timeSort : min),
        filtersProductList[0]?.timeSort
      )
      let minCheapestSort = filtersProductList.reduce(
        (min, p) => (p.priceSort < min ? p.priceSort : min),
        filtersProductList[0]?.priceSort
      )

      filtersProductList = filtersProductList.map(item => ({
        ...item,
        fastestTimeSort: item?.timeSort === minFastestTimeSort,
        cheapestSort: item.priceSort === minCheapestSort
      }))
      //  end
      filtersProductList = [...new Set(filtersProductList)]
      this.setColumns(filtersProductList)
      this.filtersProductList = filtersProductList || []
      // return filtersProductList
    },
    onClose() {
      this.$emit('onClose')
    },
    setColumns(filtersProductList) {
      let that = this
      const productList = filtersProductList || []
      let columns = [...defColumns(this)]
      let cloumnsAllCol = []
      productList.forEach((item, index) => {
        let keyName = `detail_name_${index}`
        columns.push({
          title: (
            <div class="second-header">
              <div class="table-tit">{item.productName}</div>
              <div>
                <a-button
                  type="primary"
                  onClick={$event => this.playOrder(item, $event)}
                  class="playorder-btn"
                >
                  {that.$t('product_expression_026')}
                </a-button>
                <i
                  onClick={() => this.delItem(item)}
                  class="iconfont iconshanchuchanpin"
                ></i>
              </div>
            </div>
          ),
          width: '182px',
          dataIndex: keyName,
          key: keyName,
          scopedSlots: { customRender: keyName }
        })
        cloumnsAllCol.push(keyName)
      })
      this.columns = columns
      this.cloumnsAllCol = cloumnsAllCol

      // 设置表格数据
      setTimeout(() => {
        this.setTableData()
      }, 100)
    },
    setTableData() {
      this.productList = []
      this.tableData.forEach((item, index) => {
        this.filtersProductList.forEach((dataItem, dataIndex) => {
          if (item['renderKeyItemName'] == 'minWeight') {
            // 重量限制
            item[`detail_name_${dataIndex}`] =
              dataItem[item['renderKeyItemName']] +
              ' - ' +
              dataItem['maxWeight'] //价格
          } else if (item['renderKeyItemName'] == 'minDeclaredValue') {
            // 申报金额限制
            item[`detail_name_${dataIndex}`] =
              dataItem[item['renderKeyItemName']] +
              ' - ' +
              dataItem['maxDeclaredValue'] //价格
          } else if (item['renderKeyItemName'] == 'lwh') {
            // 材积限制
            item[`detail_name_${dataIndex}`] = `${dataItem['length'] ||
              '-'} * ${dataItem['width'] || '-'} * ${dataItem['height'] ||
              '-'} cm` //价格
          } else {
            item[`detail_name_${dataIndex}`] =
              dataItem[item['renderKeyItemName']]

            // 添加失效快和运费便宜的字段
            if (item['renderKeyItemName'] == 'totalRmbAmount') {
              item[`detail_name_${dataIndex}_cheapestSort`] =
                dataItem['cheapestSort']
            }
            // 添加失效快和运费便宜的字段
            if (item['renderKeyItemName'] == 'deliveryPeriod') {
              item[`detail_name_${dataIndex}_fastestTimeSort`] =
                dataItem['fastestTimeSort']
            }
          }
        })
      })
      this.productList = this.tableData
    },
    /**
     * 切换
     */
    playOrder(record, event) {
      // 埋点 下单
      burialPoint({
        event: '1',
        productArea: 3, //页面区域  1:推荐位 2:列表位 3:对比产品
        // productRank: record?.productRank || '', // 排序
        productName: record?.productName || '', // 产品名称
        productCode: record?.productCode || '', //产品代码
        country: this.queryParam.country || '', //国家
        city: this.cityName || '' //城市
      })
      sensors.track('CalculateFreightOrder', {
        ...event?.target,
        product_code: record?.productCode || '',
        product_name: record?.productName || ''
      })
      this.$router.push({
        name: 'to_create',
        params: {
          quickOrder: {
            order: {
              holdProduct: record.productName,
              productCode: record.productCode,
              destinationCountry: this.queryParam.country,
              holdCountry: this.countryName,
              countryCode: this.queryParam.country
            },
            orderAttach: {
              customerWeight: this.queryParam.weight
                ? this.queryParam.weight.toString()
                : ''
            }
          }
        }
      })

      // this.$router.resolve(
      // window.open(routeData.href, "_blank")
    },
    delItem(item) {
      this.$emit('delProductItem', item.productCode)
      this.$show()
      setTimeout(() => {
        this.$hide()
        this.getFiltersProductList()
      }, 200)
    }
  }
}
</script>

<style lang="less" scoped>
.detail-box {
  padding: 0 20px;
  min-height: 100vh;
  background: #f2f4f8 !important;
  .title {
    height: 60px;
    line-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #151f28;
    line-height: 20px;
    font-weight: 600;
    .iconfont {
      padding: 20px;
      padding-right: 0;
      font-size: 15px;
      color: rgba(84, 93, 117, 0.5);
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.tag-box {
  margin: 10px 0;
  width: 100%;
  display: flex;
  height: 20px;
  .tag {
    margin-right: 6px;
    padding: 0 12px;
    display: inline-block;
    height: 20px;
    background: rgba(57, 96, 223, 10%);
    border-radius: 4px;
    font-size: 12px;
    color: #3960df;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
    color: #ff6400 !important;
  }
  .tag-hb {
    background: #167ff010;
    color: #1681f0;
  }
  .tag-g {
    background: #07ab8210 !important;
    color: #07ab81 !important;
  }
  .tag-o {
    background: rgba(255, 100, 0, 10%) !important;
    color: #ff6400 !important;
  }
  .tag-gr {
    background: #545d7510 !important;
    color: #545d75 !important;
  }
  .tag-b {
    background: #3960df10 !important;
    color: #3960df !important;
  }
  .tag-bt {
    margin-left: 4px;
    font-size: 12px;
    color: #323643;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
    font-size: 12px;

    .iconfont {
      font-size: 14px;
      color: #8492bd;
    }
  }
}
.table-content {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
}
.table-tit {
  margin-bottom: 8px;
  font-size: 14px;
  color: #151f28;
  line-height: 14px;
  font-weight: bold;
}
.playorder-btn {
  height: 100px;
  height: 30px;
}
.iconshanchuchanpin {
  margin-left: 8px;
  font-size: 18px;
  color: #a9aeba;
  line-height: 30px;
  cursor: pointer;
}

.tag {
  margin-right: 6px;
  padding: 0 12px;
  display: inline-block;
  height: 20px;
  background: rgba(57, 96, 223, 10%);
  border-radius: 4px;
  font-size: 12px;
  color: #3960df;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
}
.tag-hb {
  background: #167ff010;
  color: #1681f0;
}
.tag-g {
  background: #07ab8210 !important;
  color: #07ab81 !important;
}
.tag-o {
  background: rgba(255, 100, 0, 10%);
  color: #ff6400;
}
.tag-gr {
  background: #545d7510 !important;
  color: #545d75 !important;
}
.tag-bt {
  margin-left: 4px;
  font-size: 12px;
  color: #323643;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  font-size: 12px;

  .iconfont {
    font-size: 14px;
    color: #8492bd;
  }
}
.contrast-container {
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
}
// 设置整个弹窗背景
::v-deep.ant-drawer-wrapper-body {
  background: #f2f4f8 !important;
}
.red {
  color: #f85359 !important;
}
</style>
