// initial state

const state = () => ({
  ptyCode: '',
  basicData: {},
  listSelectOrderNo: '',
  customsSelectOrderNo: '',
  maxLength: 30,
  textMaxLength: 500,
  pieceUnit: [
    { name: '箱', code: 'box' },
    { name: '件', code: 'piece' },
    { name: '袋', code: 'bag' },
    { name: '托', code: 'torr' },
    { name: '个', code: 'pcs' }
  ]
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setListSelectOrderNo(state, id) {
    state.listSelectOrderNo = id
  },
  setCustomsSelectOrderNo(state, id) {
    state.customsSelectOrderNo = id
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
