/*
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2021-02-23 17:20:22
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-05-19 22:32:02
 */
import axios from 'axios'
import { LINKS } from '@const/index'
import { loginMicroApp } from '@/mirco'
import { isCheck } from './whiteList'

// BASE URL
axios.defaults.withCredentials = true
const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? `${LINKS['DSS']}/` : '/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true
})

const qs = require('qs')
import { message } from 'ant-design-vue'

instance.interceptors.request.use(
  config => {
    if (
      config.method == 'post' &&
      config.headers['Content-Type'] ==
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = qs.stringify(config.data, { indices: false })
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  res => {
    // 会话过期
    if (process.env.NODE_ENV === 'production') {
      if (res && res.request && res.request.responseURL.indexOf('sso') > -1) {
        message.warning('登录过期，请重新登录~')
        sessionStorage.clear()
        location.href = res.request.responseURL
      }
    }
    return res
  },
  err => {
    const status = err && err.response ? err.response.status : '';
    if (isCheck(err,"usageTime/log/addUsageTimeLog")) return Promise.reject(err);
    if (isCheck(err, "order/my_orders/estimatedCostV2")) return Promise.reject(err);
    switch (status) {
      case 400:
        message.warning('400~错误请求！')
        break
      case 401:
        message.warning('401~未授权，请重新登录！')
        location.href = '/502'
        break
      case 404:
        message.warning('404~')
        break
      case 408:
        message.warning('408~请求超时！')
        break
      case 500:
        message.warning('500~服务器异常！')
        break
      default:
        // if(err) message.warning(err)
        // 直发服务访问失败处理
        // location.href = '/'
        loginMicroApp('DSS')
        break
    }
    if (err && err.message.indexOf('timeout') > -1) {
      message.warning('请求超时~，请稍后重试！')
    }

    return Promise.reject(err)
  }
)
const isProduction = process.env.NODE_ENV === 'production'
const path = isProduction ? LINKS['DSS'] : '/api'
// 用于处理响应类型为： arraybuffer 请求
// 一般用于文件流的下载
const httpBuffer2 = axios.create({
  baseURL: `${path}/`,
  responseType: 'arraybuffer'
})

// 用于处理响应类型为： arraybuffer 请求
// 一般用于文件流的下载
const httpBuffer = axios.create({
  baseURL: `${LINKS['DSS']}/`,
  responseType: 'arraybuffer'
})

export default httpBuffer

export { instance, httpBuffer2 }
