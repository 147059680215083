<template>
  <div :class="`anchor-container ${!isOpen ? 'close-box' : ''}`">
    <div>
      <a
        v-for="(i, k) in data"
        :key="k"
        :class="
          `${!isOpen ? 'close anchor-link' : 'anchor-link'}${
            i.active ? ' active' : ''
          }`
        "
        @click="anchorClick(i, k)"
        >{{ i.title }}</a
      >
    </div>
    <em
      :class="isOpen ? 'iconyoujiantou' : 'iconzuojiantou'"
      class="play iconfont"
      @click="doSlideAnchor"
    />
  </div>
</template>
<script>
let timer = null // 定时器
let openTimer = null
import { throttle } from '@u/throttle.js'

export default {
  name: 'anchor',
  props: {
    list: Array,
    getContainer: Function
  },
  data() {
    return {
      isOpen: true,
      manualclose: false, //点击收起后 滚动不在计算是否展开
      linkClick: false, //点击link的时候不计算active
      data: [],
      t1: 0,
      t2: 0
    }
  },
  created() {
    this.data = this.list
  },
  mounted() {
    const el = this.getContainer()
    throttle('scroll', 'optimizedScroll', el)
    el.addEventListener('optimizedScroll', this.scrollFunc, false)
    this.calcTop()
  },
  methods: {
    // 计算scrollTop
    calcTop() {
      this.$nextTick(() => {
        this.data = this.data.map((e, i) => {
          return {
            ...e,
            index: i,
            offsetTop: document.querySelector(e.href).offsetTop
          }
        })
      })
    },
    anchorClick(record, k) {
      if (record.active) return
      this.manualclose = true
      this.linkClick = true
      this.data = this.data.map((e, i) => ({ ...e, active: k === i }))
      this.getContainer().scrollTo(
        0,
        document.querySelector(record.href).offsetTop
      ) 
    },
    doSlideAnchor() {
      this.isOpen = !this.isOpen
      this.manualclose = true
    },
    scrollFunc() {
      const el = this.getContainer()
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.t2 = el.scrollTop
        if (this.t2 == this.t1) {
          console.log('滚动结束了')
          this.linkClick = false
          this.doOpen()
        }
      }, 200)
      this.t1 = el.scrollTop
      if (!this.manualclose) {
        this.isOpen = false
      }
      // 不是点击链接的时候的滚动的时候才计算
      if (!this.linkClick) {
        this.calcPosition()
      }
    },
    doOpen() {
      if (!this.manualclose) {
        clearTimeout(openTimer)
        openTimer = setTimeout(() => {
          this.isOpen = true
        }, 1000)
      }
    },
    // 计算tab条位置
    calcPosition() {
      var vm = this
      const el = this.getContainer()
      vm.data = vm.data.map(e => ({
        ...e,
        offsetTop: document.querySelector(e.href).offsetTop
      }))
      const cur = vm.data.filter((e, i) => {
        if (i === vm.data.length - 1) {
          return el.scrollTop > vm.data[i].offsetTop
        }
        return (
          el.scrollTop > e.offsetTop - 18 &&
          el.scrollTop < vm.data[i + 1].offsetTop
        )
      })
      if (cur.length > 0) {
        vm.data = vm.data.map((e, i) => ({ ...e, active: i === cur[0].index }))
      }
    }
  },
  beforeDestroy() {
    clearTimeout(timer)
    this.getContainer().removeEventListener(
      'optimizedScroll',
      this.scrollFunc,
      false
    )
  }
}
</script>
<style lang="less" scoped>
.anchor-container {
  padding: 6px 10px 16px;
  &.close-box {
    width: 24px;
  }
  transition: all 0.3s 0s;
  .play {
    position: absolute;
    bottom: 0;
    left: 50%;
    border: 1px solid #dee1e4;
    background: #fff;
    width: 24px;
    height: 24px;
    font-size: 10px;
    color: rgba(84, 93, 117, 0.5);
    &:hover {
      color: #005adc;
      border: 1px solid #005adc;
    }
    transform: translate(-50%, 50%);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .anchor-link {
    display: block;
    color: #636464;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    padding-left: 14px;
    overflow: hidden;
    position: relative;
    &:hover {
      color: #005adc;
    }
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: #dee1e4;
      border-radius: 4px;
    }
    &.close {
      width: 4px;
      padding-left: 0;
    }
    &.active {
      color: #005adc;
      &:after {
        background: #005adc;
      }
    }
  }
}
</style>
