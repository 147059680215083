<i18n>
{
  "zh": {
    "next": "下一步",
    "tip1": "点击这里进入 【直发】业务首页",
    "tip2": "点击这里可充值、查看余额、对账等",
    "tip3": "点击这里可查看【开查索赔】、【运费试算】等",
    "tip4": "点击这里可进行账户管理、地址管理等操作"
  },
  "en": {
    "next": "next",
    "tip1": "Click here to enter the 【Straight Hair】 business home page",
    "tip2": "Click here to top up, view balances, reconcile, etc.",
    "tip3": "Click here to view 【Open Claims】, 【Freight Trial】 and so on.",
    "tip4": "Click here for account management, address management and more"
  },
  "de": {
    "next": "next",
    "tip1": "Click here to enter the 【Straight Hair】 business home page",
    "tip2": "Click here to top up, view balances, reconcile, etc.",
    "tip3": "Click here to view 【Open Claims】, 【Freight Trial】 and so on.",
    "tip4": "Click here for account management, address management and more"
  },
  "ja": {
    "next": "next",
    "tip1": "Click here to enter the 【Straight Hair】 business home page",
    "tip2": "Click here to top up, view balances, reconcile, etc.",
    "tip3": "Click here to view 【Open Claims】, 【Freight Trial】 and so on.",
    "tip4": "Click here for account management, address management and more"
  }
}
</i18n>
<template>
  <div class="tip-view" v-if="show">
    <!-- focus view -->
    <div class="focus-view" :class="lastIndex ? 'focus-view-last' : ''"></div>
    <div class="tip-line"></div>
    <!-- 内容 -->
    <div class="triangle-up"></div>
    <div class="cnt-pos">
      <div style="position: relative;">
        {{ msg }}
        <!-- 下一步 -->
        <div
          class="next-pos"
          :style="lastIndex ? 'left: 19px;' : 'right: 19px;'"
          @click="onNext"
        >
          {{ lastIndex ? $t('know') : $t('next') }}
        </div>
        <!-- close -->
        <div class="close-tip" @click="() => (show = false)" v-if="!lastIndex">
          <a-icon type="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tip-view',
  data() {
    return {
      show: true,
      currStep: 0,
      tips: []
    }
  },
  computed: {
    lastIndex() {
      return this.currStep > 0 && this.currStep == this.tips.length - 1
    },
    msg() {
      return this.tips.length ? this.tips[this.currStep].msg : ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      localStorage.setItem('guide', 1)
      // 获取指引的节点
      // 导航栏：直发、我的账户、服务中心、头像
      const nav = document.querySelector('.nav-menu').children
      this.tips = [
        { msg: this.$t('tip1'), node: nav[1] },
        { msg: this.$t('tip2'), node: nav[nav.length - 2] },
        { msg: this.$t('tip3'), node: nav[nav.length - 1] },
        {
          msg: this.$t('tip4'),
          node: document.querySelector('.nav-user')
        }
      ]
      this.setStylePosition()
      this.$root.bus.$on('onresize', () => {
        this.$nextTick(() => {
          this.setStylePosition(
            this.currStep === this.tips.length - 1 ? 'left' : 'right'
          )
        })
      })
    })
  },
  beforeDestroy() {
    this.$root.bus.$off('onresize')
  },
  methods: {
    onNext() {
      if (this.currStep === this.tips.length - 1) {
        this.show = false
      }
      this.currStep = this.currStep + 1
      this.setStylePosition(
        this.currStep === this.tips.length - 1 ? 'left' : 'right'
      )
    },
    /**
     * @description: 定位焦点元素，修正样式
     * @param {*}
     * @return {*}
     */
    setStylePosition(dir = 'right') {
      if (this.tips.length === 0 || this.currStep > this.tips.length - 1) return
      const node = this.tips[this.currStep].node
      if (!node) return
      const rect = node.getBoundingClientRect()
      const fv = document.querySelector('.focus-view')
      if (fv) {
        fv.innerHTML = node.innerHTML
        fv.style.left = rect.x + 'px'
        fv.style.top = rect.y + 'px'
        fv.style.width = rect.width + 'px'
        if (dir === 'left') {
          fv.style.width = 'auto'
        }
      }
      const line = document.querySelector('.tip-line')
      const triangle = document.querySelector('.triangle-up')
      const cnt = document.querySelector('.cnt-pos')
      if (dir === 'left') {
        if (line) {
          line.style.left = rect.x + rect.width - 5 + 'px'
          line.style.top = '72px'
          line.style.height = '78px'
        }
        if (triangle) {
          triangle.style.left = rect.x + rect.width / 2 + 2 + 'px'
          triangle.style.top = '150px'
          triangle.style.borderRight = '2px'
        }
        if (cnt) {
          cnt.style.left = ''
          cnt.style.right = '3px'
          cnt.style.top = rect.y + rect.height + 88 + 'px'
        }
      } else {
        if (line) {
          line.style.left = rect.x + rect.width / 2 - 5 + 'px'
          line.style.top = rect.y + 52 + 'px'
        }
        // triangle
        if (triangle) {
          triangle.style.left = rect.x + rect.width / 2 - 14 + 'px'
          triangle.style.top = rect.y + rect.height + 90 - 12 + 'px'
        }
        // content
        if (cnt) {
          cnt.style.left = rect.x - 58 + 'px'
          cnt.style.top = rect.y + rect.height + 90 + 'px'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tip-view {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  .tip-line {
    position: absolute;
    left: 50%;
    top: 44px;
    height: 85px;
    width: 2px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('./imgs/line.png');
  }
  .triangle-up {
    position: fixed;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #005adc;
  }
  .focus-view {
    position: fixed;
    height: 44px;
    border-radius: 22px;
    color: #fff;
    text-align: center;
    line-height: 44px;
    box-sizing: border-box;
    margin-top: 8px;
    background: #005adc;
    border: 2px solid #ffffff;
  }
  .focus-view-last {
    width: auto;
    margin-top: -8px;
    padding: 4px 8px 0 8px;
    text-align: left;
  }
  .cnt-pos {
    position: fixed;
    box-sizing: border-box;
    background: #005adc;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.14);
    color: #fff;
    line-height: 1.2;
    width: 392px;
    font-size: 20px;
    padding: 38px 26px;
    border-radius: 90px;
  }
  .next-pos {
    position: absolute;
    margin-top: 49px;
    cursor: pointer;
    box-sizing: border-box;
    background: #ffffff;
    height: 44px;
    border-radius: 22px;
    padding: 8px 31px;
    font-size: 16px;
    color: #005adc;
    line-height: 28px;
    &:hover {
      color: #005cdcbb;
    }
  }
  .close-tip {
    position: absolute;
    background: #fff;
    border-radius: 12px;
    width: 24px;
    height: 24px;
    color: #005adc;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    top: -170px;
    right: 19px;
    cursor: pointer;
  }
}
</style>
