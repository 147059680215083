<template>
  <div class="page-container" :class="{ isHomePage: isHomePage }">
    <div
      class="anchor"
      ref="anchor"
      :style="`top: ${hasTopBanner ? '234px' : '210px'}`"
      v-if="!isHomePage"
    >
      <Anchor :list="anchorList" :get-container="getContainer" />
    </div>
    <div v-else :style="{ marginBottom: '20px' }">
      <div class="index-title">
        {{ $t('instrument.to.perform.monitoring') }}
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: this.$t('instrument.to.perform.monitoring') }
              })
            }
          "
        />
      </div>
      <div class="b-more" @click="goDetail">
        {{ $t('more') }}
        <div class="arrow-right"></div>
      </div>
    </div>
    <div class="page-content">
      <p class="page-name" v-if="!isHomePage">
        <span>{{ $t('instrument.to.perform.monitoring') }}</span>
        <span
          style="
            font-size: 14px;
            color: rgba(84, 93, 117, 0.5);
            font-weight: normal;
            margin-left: 14px;
          "
          >{{ $t('数据更新日期') }}：{{ dataTime }}</span
        >
      </p>

      <div class="tab flex-start" :class="{ homePageTab: isHomePage }">
        <span
          :class="`flex-center ${isOfficial ? 'active' : ''}`"
          @click="tabChange(1)"
          >{{ $t('official.hair.head') }}</span
        >
        <span
          :class="`flex-center ${!isOfficial ? 'active' : ''}`"
          @click="tabChange"
          >{{ $t('non-official.hair') }}</span
        >
      </div>

      <div class="main-content" :class="{ HomePageMain: isHomePage }">
        <div>
          <a-form :layout="formLayout" class="flex-sp" ref="form">
            <div class="query-item flex-start">
              <a-form-item :label="$t('customerCode')">
                <CustomerCode
                  :value="topForm.customerCode"
                  @customercode="val => (topForm.customerCode = val)"
                  style="width: 200px"
                />
              </a-form-item>
              <a-form-item :label="$t('创建订单时间')">
                <a-range-picker
                  :value="[topForm.createDateStart, topForm.createDateEnd]"
                  :format="dateFormat"
                  :allowClear="false"
                  @change="onDateChange"
                  :disabled-date="disabledDate"
                >
                  <a-icon slot="suffixIcon" type="calendar" />
                </a-range-picker>
              </a-form-item>
              <a-form-item class="query-handle">
                <a-space>
                  <a-button @click="queryAll" class="search-btn">
                    {{ $t('inquire') }}
                  </a-button>
                  <a-button @click="resetTopForm">
                    {{ $t('reset') }}
                  </a-button>
                </a-space>
              </a-form-item>
            </div>
          </a-form>
        </div>
        <div>
          <div v-if="isOfficial">
            <div
              class="title-name"
              id="inful-all"
              :class="{ HomePageTitle: isHomePage }"
            >
              <span>{{ $t('performance.overview') }}</span>
            </div>
            <a-steps :current="-1" size="small" labelPlacement="vertical">
              <a-step :title="$t('create')" />
              <a-step :title="$t('国内仓收货')">
                <div slot="description" class="desc">
                  <span>收货件数</span>
                  <span>{{ allData.whe_receive_qty }}</span>
                </div>
              </a-step>
              <a-step :title="$t('domestic.warehouse')" />
              <a-step :title="$t('departure')" />
              <a-step :title="$t('go.to.hong.kong')" />
              <a-step title="上架">
                <span slot="description" class="desc">
                  <span>上架件数</span>
                  <span>{{ allData.putaway_qty }}</span>
                </span>
              </a-step>
            </a-steps>
            <Chart :list="chartData" :baseNum="chartData[0].value" />
          </div>
          <div v-else>
            <div class="title-name" id="inful-all">
              <span>{{ $t('performance.overview') }}</span>
            </div>
            <a-steps :current="-1" size="small" labelPlacement="vertical">
              <a-step :title="$t('create')" />
              <a-step :title="$t('预报')"> </a-step>
              <a-step :title="$t('reservation')" />
              <a-step :title="$t('overseas.warehouse')">
                <span slot="description" class="desc">
                  <span>上架件数</span>
                  <span>{{ allData.putawayQty }}</span>
                </span>
              </a-step>
            </a-steps>
            <Chart
              :list="unofficialChartData"
              :baseNum="unofficialChartData[0].value"
            />
          </div>
        </div>
        <!-- 分仓查看 -->
        <div v-if="!isHomePage">
          <div class="title-name" id="warehouse">
            <span>{{ $t('warehouse.view') }}</span>
          </div>
          <a-form :layout="formLayout" class="flex-sp-e" ref="form">
            <div class="query-item flex-start">
              <a-form-item :label="$t('warehouseName')">
                <Warehouse
                  :value="warehouseForm.toWarehouseCode"
                  @warehouse="val => (warehouseForm.toWarehouseCode = val)"
                  style="width: 200px"
                />
              </a-form-item>
              <a-form-item class="query-handle">
                <a-space>
                  <a-button @click="queryByWarehouse()" class="search-btn">
                    {{ $t('inquire') }}
                  </a-button>
                  <a-button @click="resetWarehouseForm">
                    {{ $t('reset') }}
                  </a-button>
                </a-space>
              </a-form-item>
            </div>
            <a-button @click="doWarehouseExport">{{
              $t('export.data')
            }}</a-button>
          </a-form>
          <div style="height: 16px"></div>
          <a-table
            :columns="isOfficial ? columns : unofficalColumns"
            :data-source="warehouseList"
            :pagination="pagination"
            :loading="warehouseLoading"
            @change="handleWarehouseTableChange"
          >
          </a-table>
        </div>
        <!-- 订单明细 -->
        <div v-if="!isHomePage">
          <div class="title-name" id="order">
            <span id="order">{{ $t('order.details') }}</span>
          </div>
          <a-form :layout="formLayout" class="flex-sp-e" ref="form">
            <div class="query-item flex-start">
              <a-form-item :label="$t('single.number')">
                <a-input
                  style="width: 200px"
                  placeholder="多个请逗号隔开"
                  v-model="orderForm.consignmentNo"
                ></a-input>
              </a-form-item>
              <a-form-item :label="$t('warehouseName')">
                <Warehouse
                  :value="orderForm.toWarehouseCode"
                  @warehouse="val => (orderForm.toWarehouseCode = val)"
                  style="width: 200px"
                />
              </a-form-item>
              <a-form-item :label="$t('transportation.method')">
                <TransportType
                  style="width: 200px"
                  v-model="orderForm.transportTypecode"
                />
              </a-form-item>
              <a-form-item class="query-handle">
                <a-space>
                  <a-button @click="queryByOrder()" class="search-btn">
                    {{ $t('inquire') }}
                  </a-button>
                  <a-button @click="resetOrderForm">
                    {{ $t('reset') }}
                  </a-button>
                </a-space>
              </a-form-item>
            </div>
            <a-button @click="doOrderExport">{{ $t('export.data') }}</a-button>
          </a-form>
          <div style="height: 16px"></div>
          <a-table
            :columns="orderColumns"
            :data-source="orderList"
            :pagination="orderPagination"
            :loading="orderLoading"
            @change="handleOrderTableChange"
            :scroll="{ x: 1200 }"
          >
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getInWarehouseListColumns,
  getInUnofficialWarehouseListColumns,
  getInOrderListColumns
} from './index.data.js'
import Warehouse from '@c/warehouse/Warehouse'
import TransportType from '@c/transportType/TransportType'
import Anchor from '@c/anchor/index'
import CustomerCode from '@c/customercode/CustomerCode'
import { httpExportToExcel } from '@/api/index'
import moment from 'moment'
import Chart from './chart.vue'

const defaultTopForm = {
  customerCode: '',
  createDateStart: moment()
    .add(-7, 'days')
    .format('YYYY-MM-DD'),
  createDateEnd: moment()
    .add(-1, 'days')
    .format('YYYY-MM-DD')
}

const defaultWarehouseForm = {
  toWarehouseCode: ''
}

const defaultOrderForm = {
  toWarehouseCode: '',
  consignmentNo: '',
  transportTypecode: ''
}

const defaultChartData = [
  {
    label: '创建单量',
    dataIndex: 'create_cnt',
    desc:
      '在所选时间范围内已成功创建需要入库的订单量，不包含草稿和取消状态的订单',
    value: 0
  },
  {
    label: '收货单量',
    dataIndex: 'whe_receive_cnt',
    desc:
      '<div><div>收货件数：已到我司国内仓库完成收货的SKUpcs数</div><div>收货单量：已到我司国内仓库完成收货的订单量</div></div>',
    value: 0
  },
  {
    label: '签出单量',
    dataIndex: 'whe_ship_cnt',
    desc: '已到我司国内仓库完成签出的订单量',
    value: 0
  },
  {
    label: '离港单量',
    dataIndex: 'departure_cnt',
    desc: '根据北京时间进行统计，已离开国内起始港口/已经起飞的订单量',
    value: 0
  },
  {
    label: '到港单量',
    dataIndex: 'arrival_cnt',
    desc: '根据北京时间进行统计，已到达海外目的港口/已经到达机场的订单量',
    value: 0
  },
  {
    label: '上架单量',
    dataIndex: 'putaway_cnt',
    desc:
      '<div><div>上架件数：根据北京时间进行统计，在海外仓已完成上架的SKUpcs数</div><div>上架单量：根据北京时间进行统计，在海外仓已完成上架的订单量</div></div>',
    value: 0
  }
]

const defaultNotOfficialChartData = [
  {
    label: '创建单量',
    dataIndex: 'createCnt',
    desc:
      '在所选时间范围内已成功创建需要入库的订单量，不包含草稿和取消状态的订单',
    value: 0
  },
  {
    label: '预报单量',
    dataIndex: 'planCnt',
    desc:
      '已在系统完成预报的订单量，若不进行预报会存在上架单量大于预报单量的情况',
    value: 0
  },
  {
    label: '预约单量',
    dataIndex: 'reservationCnt',
    desc:
      '已在系统完成预约的订单量，若不进行预约会存在上架单量大于预约单量的情况',
    value: 0
  },
  {
    label: '上架单量',
    dataIndex: 'putawayCnt',
    desc:
      '<div><div>上架件数：根据北京时间进行统计，在海外仓已完成上架的SKUpcs数</div><div>上架单量：根据北京时间进行统计，在海外仓已完成上架的订单量</div></div>',
    value: 0
  }
]

const anchorList = [
  {
    href: '#inful-all',
    title: '履行总览'
  },
  {
    href: '#warehouse',
    title: '分仓查看'
  },
  {
    href: '#order',
    title: '订单明细'
  }
]

export default {
  props: {
    isHomePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formLayout: 'vertical',
      dateFormat: 'YYYY-MM-DD',
      topForm: { ...defaultTopForm },
      columns: getInWarehouseListColumns(this),
      unofficalColumns: getInUnofficialWarehouseListColumns(this),
      pagination: { ...this.$pagination() },
      chartData: [...defaultChartData],
      allData: {},
      unofficialChartData: [...defaultNotOfficialChartData],
      warehouseList: [],
      warehouseForm: { ...defaultWarehouseForm },
      warehouseLoading: false,
      orderColumns: getInOrderListColumns(this),
      orderList: [],
      orderForm: { ...defaultOrderForm },
      orderLoading: false,
      orderPagination: { ...this.$pagination() },
      isOfficial: true,
      isOpen: true,
      dataTime: moment()
        .add(-1, 'days')
        .format('YYYY-MM-DD'),
      anchorList
    }
  },
  components: {
    Chart,
    Warehouse,
    CustomerCode,
    TransportType,
    Anchor
  },
  mounted() {
    this.queryAll()
    this.$nextTick(() => {
      // 添加外层容器样式
      const content = document.querySelectorAll('.index-content')[0]
      if (content) content.className += ' content-new-style'
    })
  },
  beforeDestroy() {
    // 去掉外层容器样式
    const content = document.querySelectorAll('.index-content')[0]
    content.className = content.className.replace('content-new-style', '')
  },
  computed: {
    hasTopBanner() {
      return this.$store.state.hasTopBanner
    },
    warehouseParams() {
      return {
        ...this.topForm,
        ...this.warehouseForm,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
    },
    orderParams() {
      var form = { ...this.orderForm }
      if (!this.isOfficial) {
        form.transportType = form.transportTypecode
        delete form.transportTypecode
      }
      return {
        ...this.topForm,
        ...form,
        pageNum: this.orderPagination.current,
        pageSize: this.orderPagination.pageSize
      }
    },
    allDataUrl() {
      return this.isOfficial
        ? 'v2/dataCenter/getWarehousePandectDTO'
        : 'v2/unoffical/overview'
    },
    warehouseUrl() {
      return this.isOfficial
        ? 'v2/dataCenter/getBranchWarehouseDTO'
        : 'v2/unoffical/subquery'
    },
    warehouseExportUrl() {
      return this.isOfficial
        ? 'v2/dataCenter/exportBranchWarehouseDTO'
        : 'v2/unoffical/subquery/export'
    },
    orderUrl() {
      return this.isOfficial
        ? 'v2/dataCenter/getWarehouseDetailDTO'
        : 'v2/unoffical/detailed'
    },
    orderExportUrl() {
      return this.isOfficial
        ? 'v2/dataCenter/exporWarehouseDetailDTO'
        : 'v2/unoffical/detailed/export'
    }
  },
  methods: {
    moment,
    disabledDate(current) {
      return current && current > moment().add(-1, 'days')
    },
    // 指定滚动的容器
    getContainer() {
      return document.querySelector('.main-index')
    },
    /**
     * tab切换
     */
    tabChange(type) {
      if (this.isOfficial === (type === 1)) return
      this.isOfficial = type === 1
      this.warehouseList = []
      this.orderList = []
      this.chartData = [...defaultChartData]
      this.unofficialChartData = [...defaultNotOfficialChartData]
      this.resetTopForm()
      this.resetWarehouseForm()
      this.resetOrderForm()
      this.queryAll()
    },
    /**
     * 获取所有数据
     */
    queryAll() {
      this.$post(this.allDataUrl, this.topForm).then(res => {
        this.allData = res
        // 官方
        if (this.isOfficial) {
          this.chartData = this.chartData.map(e => ({
            ...e,
            value: res[e.dataIndex]
          }))
          return
        }
        this.unofficialChartData = this.unofficialChartData.map(e => ({
          ...e,
          value: res[e.dataIndex]
        }))
      })
      this.queryByWarehouse()
      this.queryByOrder()
    },
    resetTopForm() {
      this.topForm = { ...defaultTopForm }
      this.resetWarehouseForm()
      this.resetOrderForm()
    },
    /**
     * 按仓库查看数据
     */
    queryByWarehouse(pagination) {
      // 如果是点的查询按钮 默认查询第一页
      this.warehouseLoading = true
      this.$post(this.warehouseUrl, {
        ...this.warehouseParams,
        pageNum: !pagination ? 1 : pagination.current
      })
        .then(res => {
          this.warehouseList = res.rows
          this.warehouseLoading = false
          this.pagination.total = res.totalNum
          this.pagination.current = res.pageNum
          this.pagination.pageSize = res.pageSize
        })
        .catch(() => (this.warehouseLoading = false))
    },
    handleWarehouseTableChange(pagination) {
      this.pagination = pagination
      this.queryByWarehouse(pagination)
    },
    resetWarehouseForm() {
      this.warehouseForm = { ...defaultWarehouseForm }
    },
    doWarehouseExport() {
      const param = { ...this.warehouseParams }
      delete param.pageNum
      delete param.pageSize
      var name = this.isOfficial
        ? this.$t('官方分仓查看')
        : this.$t('非官方分仓查看')
      httpExportToExcel(this.warehouseExportUrl, param, name)
    },
    /**
     * 订单明细数据
     */
    queryByOrder(pagination) {
      this.orderLoading = true
      this.$post(this.orderUrl, {
        ...this.orderParams,
        pageNum: !pagination ? 1 : pagination.current
      })
        .then(res => {
          this.orderList = res.rows
          this.orderLoading = false
          this.orderPagination.total = res.totalNum
          this.orderPagination.current = res.pageNum
          this.orderPagination.pageSize = res.pageSize
        })
        .catch(() => (this.orderLoading = false))
    },
    handleOrderTableChange(pagination) {
      this.orderPagination = pagination
      this.queryByOrder(pagination)
    },
    resetOrderForm() {
      this.orderForm = { ...defaultOrderForm }
    },
    doOrderExport() {
      const param = { ...this.orderParams }
      delete param.pageNum
      delete param.pageSize
      var name = this.isOfficial
        ? this.$t('官方订单明细')
        : this.$t('非官方订单明细')
      httpExportToExcel(this.orderExportUrl, param, name)
    },
    onDateChange(dates, dateStrings) {
      this.topForm.createDateStart = dateStrings[0]
      this.topForm.createDateEnd = dateStrings[1]
    },
    goDetail() {
      this.$router.push('/inboundFulfillment')
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/styles/newstyle.less';
.tab {
  span {
    width: 142px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-right: 8px;
    background: rgba(255, 255, 255, 0.7);
    color: #545d75;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
    &.active {
      background: #fff;
      color: #005adc;
    }
  }
}
.homePageTab {
  span {
    width: 72px;
    &:not(:last-child)::after {
      content: '|';
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.25);
      // border-right: 1px solid red;
    }
  }
}

.HomePageMain {
  padding: 0;
  .HomePageTitle {
    margin-top: 10px;
  }
}

.desc {
  font-size: 14px;
  line-height: 1;
  padding: 8px 0;
  white-space: nowrap;
  span:last-child {
    font-weight: 600;
    margin-left: 4px;
    font-size: 16px;
    color: #323643;
  }
}
/deep/ .ant-steps-item-description {
  height: 35px;
  line-height: 35px;
}

.isHomePage {
  justify-content: flex-start;
  width: 66.67%;
  height: 332px;
  background: #fff;
  margin-right: 20px;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: auto;
  border-radius: 8px;
}
</style>
