<template>
  <a-modal
    :centered="true"
    :closable="false"
    :visible="show"
    :mask="true"
    :maskClosable="false"
    width="680px"
    height="412px"
  >
    <div class="content">
      <div class="tit">截止10月22日未结清预警通知</div>
      <div class="content-text">
        <div class="m24 start-msg">尊敬的直发客户：</div>
        <div class="m24 msg">
          您好，我司直发结算系统已于9月23日完成系统升级，新系统支持多币种报价结算，并设置了逾期未支付账户锁定功能，账户锁定后将影响您的账户正常发货以及信用评分等级。
        </div>
        <div class="msg">
          温馨提示：<b class="ligin-c"
            >截止10月22日，如您的账户还存在未结清的款项，逾期将自动锁定账户无法出货，故请务必于10月28日18:00前结清所有币种账户上期应付款项，</b
          >如您已结清请忽略此信息，给您带来不便敬请谅解，感谢您的支持与配合！
        </div>
      </div>
    </div>
    <template slot="footer">
      <a-button class="btn" type="primary" @click="closePopup()">
        我已知晓
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  name: 'collectionPopup',
  data() {
    return {
      show: false
    }
  },
  created() {},
  mounted() {},
  computed: mapState({
    isDSSOpen: state => state.userInfo.isDSSOpen
  }),
  watch: {
    isDSSOpen(val) {
      if (val) {
        this.handlePopup()
      }
    }
  },
  methods: {
    moment,
    handlePopup() {
      if (
        moment().format('YYYY-MM-DD') >
        moment('2021-10-28').format('YYYY-MM-DD')
      ) {
        return false
      }
      var arrearsPopup = window.localStorage.getItem('arrearsPopup')
        ? JSON.parse(window.localStorage.getItem('arrearsPopup'))
        : null
      if (
        (arrearsPopup == null && this.isDSSOpen) ||
        (arrearsPopup.username != this.$store.state.username &&
          this.isDSSOpen) ||
        (arrearsPopup.username == this.$store.state.username &&
          this.isDSSOpen &&
          arrearsPopup.hide != 1)
      ) {
        this.show = true
      } else {
        this.show = false
      }
    },
    closePopup() {
      this.show = false
      window.localStorage.setItem(
        'arrearsPopup',
        JSON.stringify({
          username: this.$store.state.username,
          hide: 1
        })
      )
    }
  }
}
</script>

<style lang="less" scoped>
.tit {
  margin-bottom: 10px;
  font-size: 22px;
  list-style: 24px;
  text-align: center;
  color: #151f28;
  font-weight: 600;
}
.content-text {
  font-size: 14px;
  font-weight: 500;
  color: #545d75;
  line-height: 24px;
  .msg {
    text-indent: 28px;
  }
  .ligin-c {
    color: #ff6400;
    font-weight: 600;
  }
  .m24 {
    margin: 24px 0;
  }
}
.btn {
  padding: 0;
  height: 40px;
  line-height: 40px;
  max-width: 124px;
  text-align: center;
}
::v-deep.ant-modal-content {
  border-radius: 8px;
}
</style>
