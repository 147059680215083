import { http, httpGet, httpPost } from '@/api/index'

const burialPoint = paramData => {
  let param = {
    ...paramData
  }
  httpPost('v2/burialPoint/burialPoint', param)
    .then(res => {})
    .catch(() => {})
}

export { burialPoint }
