/**
 * 埋点拦截提示清除 | 试算的提示拦截
 * @param {*} err
 * @returns
 */
export const isCheck = (err, link) => err &&
err.response &&
err.response.config &&
err.response.config.url &&
err.response.config.url.includes(link);
