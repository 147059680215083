<template>
  <div class="product">
    <div class="item-tit">可跟踪类型</div>
    <div class="item-box">
      <a-radio-group v-model="form.trackType" size="small" button-style="solid">
        <a-radio-button
          v-for="(item, index) in trackTypeArr"
          class="radio-item"
          :key="index"
          :value="item.value"
        >
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="item-tit">服务等级</div>
    <div class="item-box">
      <a-radio-group
        v-model="form.serviceLevel"
        size="small"
        button-style="solid"
      >
        <a-radio-button
          v-for="(item, index) in serviceLevelArr"
          class="radio-item"
          :key="index"
          :value="item.value"
        >
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="btn-box">
      <a-button @click="() => $emit('reset')">{{ $t('reset') }}</a-button>
      <a-button @click="() => $emit('ok')" type="primary">
        {{ $t('index_tran_016') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { trackTypeArr, serviceLevelArr } from './data'
export default {
  name: 'serviceCategory',
  props: {
    formData: {
      type: Object,
      require: true,
      default: () => ({
        trackType: '',
        serviceLevel: ''
      })
    }
  },
  data() {
    return {
      trackTypeArr: trackTypeArr(this),
      serviceLevelArr: serviceLevelArr(this),
      form: {
        trackType: '', // "全跟踪, 1, 半跟踪, 2 无跟踪, 3"
        serviceLevel: ''
      }
    }
  },
  created() {
    this.form = {
      ...this.formData
    }
  },
  methods: {
    /**
     * 获取当前选中的产品
     * @param event
     * @returns
     */
    productChose(event) {
      if (event.target.nodeName.toUpperCase() == 'P') {
        this.$emit('getterProduct', event.target.dataset.val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product {
  width: 400px;
  background: #fff;
  .ant-tabs-tabpane {
    max-height: 30vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
}
.item-box {
  padding-bottom: 6px;
}
.item-tit {
  margin-bottom: 6px;
  font-size: 14px;
  color: #545d75;
  font-weight: 400;
  line-height: 20px;
}
.item-tit:nth-of-type(1) {
  margin-top: 0;
}
// radio
::v-deep.radio-item {
  margin-bottom: 6px;
  padding: 0 12px;
  height: 30px;
  line-height: 28px;
  box-sizing: border-box;
  margin-right: 6px;
  border-radius: 4px;
  border: 1px solid #c3cbd5;
}
::v-deep .ant-radio-button .ant-radio-button-checked {
  border-radius: 4px !important;
}
::v-deep .ant-radio-button-wrapper:not(:first-child)::before {
  // position: relative;
  top: 0px;
  left: 0px;
  display: block;
  box-sizing: content-box;
  width: 0px;
  height: 100%;
  padding: 0px 0;
  background-color: transparent;
  transition: background-color 0.3s;
  content: '';
}
.btn-box {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
