export default [
  // 空运委托单列表
  {
    path: '/airWaybill',
    name: 'airWaybill',
    meta: {
      ignore: true,
      title: '空运委托单'
    },
    component: () =>
      import(/* webpackChunkName: "airWaybill" */ '../views/airWaybill/List')
  },
  {
    path: '/fileManage',
    name: 'fileManage',
    meta: {
      ignore: true,
      title: '文件管理'
    },
    component: () =>
      import(
        /* webpackChunkName: "fileManage" */ '../views/airWaybill/FileManage'
      )
  },
  {
    path: '/customsManage',
    name: 'customsManage',
    meta: {
      ignore: true,
      title: '报关管理'
    },
    component: () =>
      import(
        /* webpackChunkName: "customsManage" */ '../views/airWaybill/CustomsManage'
      )
  },
  {
    path: '/createAirWaybill',
    name: 'createAirWaybill',
    meta: {
      ignore: true,
      title: '创建空运委托单'
    },
    component: () =>
      import(
        /* webpackChunkName: "createAirWaybill" */ '../views/airWaybill/Create'
      )
  },
  {
    path: '/airWaybillDetails',
    name: 'airWaybillDetails',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "airWaybillDetails" */ '../views/airWaybill/Details'
      )
  }
]
