import { groupListByLen } from '@microDss/util/help'
import Cookies from 'js-cookie'

// 新邮税改 - 客户下单页面，添加“是否完税”lvgPaid字段
const handlerNewPost = (resExtraService, productCode, countryCode) => {
  if (countryCode === 'SG' && ['B1', 'CQ', 'C3'].indexOf(productCode) > -1) {
    resExtraService.push({
      isSpeical: true,
      isRequired: false,
      fieldName: 'lvgPaid',
      fieldLabel: '是否完税',
      fieldElabel: 'Whether taxable',
      // eslint-disable-next-line no-useless-escape
      msg: '请选择',
      emsg: 'Please Select'
    })
  }
}

// 以下产品需要添加 单票件数
const handlerParcellCount = (resExtraService, isRequired) => {
  if (!isRequired) return
  if (resExtraService.some(item => item.fieldName === 'parcelCount')) return
  const isZh = Cookies.get('newOrderLang') !== 'en-US'
  resExtraService.push({
    isSpeical: true,
    type: 'input',
    isRequired: true,
    fieldName: 'parcelCount',
    fieldLabel: '单票件数',
    fieldElabel: 'Number of single ticket',
    // eslint-disable-next-line no-useless-escape
    ruleValue: '/[^d]/g',
    val: '',
    msg: '请输入1-999以内正整数',
    emsg: 'Please enter a positive integer within 1-999',
    tips: isZh
      ? '提示：一票多件的数量（外箱数量）'
      : 'Tip: The number of multiple pieces per ticket (number of outer boxes)'
  })
}

/**
 * @description: 调整字段显示位置
 * @param {*} resShipper
 * @param {*} resConsignee
 * @return {*}
 */
const sortField = (resShipper, resConsignee) => {
  console.log(resShipper, resConsignee)
  // 发件人显示顺序
  const viewShipper = [
    'shipperName',
    'shipperCompany',
    'shipperProvince',
    'shipperCity',
    'shipperPostcode',
    'shipperTelephone',
    'shipperAddress1'
  ]

  const res_Shipper = []
  viewShipper.forEach(e => {
    resShipper.forEach(item => {
      if (item.fieldName == e) {
        res_Shipper.push(item)
        return true
      }
      return false
    })
  })

  // 收件人显示顺序
  const viewConsignee = [
    'consigneeName',
    'consigneeCompany',
    'consigneeFirstName',
    'consigneeLastName',
    'consigneeProvince',
    'consigneeCity',
    'consigneePostcode',
    'consigneeMobile',
    'consigneeTelephone',
    'consigneeDoorNo',
    'consigneeMail',
    'consigneeVatNo',
    'tariffNo',
    'buyerId',
    'consigneeAddress1'
  ]

  const res_consignee = []
  viewConsignee.forEach(e => {
    resConsignee.some(item => {
      if (item.fieldName == e) {
        res_consignee.push(item)
        return true
      }
      return false
    })
  })
  return { res_Shipper, res_consignee }
}

/**
 * @description: 根据规程信息，产出对应校验 rules
 * @param {*} item
 * @return {*}
 */
const generatorRules = item => {
  const r = []
  if (item.isRequired) {
    r.push({ required: true, message: item.msg })
  }

  if (item.ruleType == 'regex') {
    // 剔除错误的正则表达式
    let isRight = true
    let reg
    try {
      reg = new RegExp(item.ruleValue)
    } catch (e) {
      isRight = false
    }
    // console.log(item.fieldName, ' - ', reg)
    if (isRight && reg) {
      r.push({ pattern: reg, message: item.msg })
    }
  }

  if (item.ruleType == 'length') {
    const min = parseInt(item.ruleValue.split('-')[0]) || 0
    const max = parseInt(item.ruleValue.split('-')[1]) || 50
    r.push({ min: min, max: max, message: item.msg })
  }
  if (['customerOrderNo', 'serverOrderNo'].indexOf(item.fieldName) > -1) {
    r.push({ min: 0, max: 30, message: item.msg || item.placeholder })
  }
  return r
}

/**
 * @description: 读取规则 封装成可用的规则对象
 * @param {*} rules
 * @param {*} productCode
 * @param {*} countryCode
 * @return {*}
 */
const handlerRules = (rules, productCode, countryCode) => {
  if (!rules) return
  let resConsignee = [
      {
        fieldName: 'consigneeName',
        fieldLabel: '姓名',
        fieldElabel: 'consigneeName',
        ruleType: 'length',
        ruleValue: '0-100'
      },
      {
        fieldName: 'consigneeCompany',
        fieldLabel: '公司',
        fieldElabel: 'consigneeCompany',
        ruleType: 'length',
        ruleValue: '0-100'
      },
      {
        fieldName: 'consigneeFirstName',
        fieldLabel: '名',
        fieldElabel: 'consigneeFirstName',
        ruleType: 'length',
        ruleValue: '0-100'
      },
      {
        fieldName: 'consigneeLastName',
        fieldLabel: '姓',
        fieldElabel: 'consigneeLastName',
        ruleType: 'length',
        ruleValue: '0-100'
      },
      {
        fieldName: 'consigneeAddress1',
        view: 'textarea',
        fieldLabel: '详细地址',
        fieldElabel: 'consigneeAddress1',
        isRequired: true,
        ruleType: 'length',
        ruleValue: '0-330'
      },
      {
        fieldName: 'buyerId',
        fieldLabel: '买家ID',
        fieldElabel: 'buyerId',
        msg: '60个字符以内',
        emsg: 'Within 60 characters',
        placeholder: '60个字符以内',
        eplaceholder: 'Within 60 characters',
        ruleType: 'length',
        ruleValue: '0-60'
      },
      {
        fieldName: 'consigneeCity',
        fieldLabel: '城市',
        fieldElabel: 'consigneeCity',
        ruleType: 'length',
        ruleValue: '0-60'
      },
      {
        fieldName: 'consigneeDoorNo',
        fieldLabel: '门牌号',
        fieldElabel: 'consigneeDoorNo',
        ruleType: 'length',
        ruleValue: '0-50'
      },
      {
        fieldName: 'consigneeProvince',
        fieldLabel: '省/州',
        fieldElabel: 'consigneeProvince',
        ruleType: 'length',
        ruleValue: '0-60'
      },
      {
        fieldName: 'consigneeVatNo',
        fieldLabel: '税号',
        fieldElabel: 'consigneeVatNo',
        ruleType: 'length',
        ruleValue: '0-50'
      },
      {
        fieldName: 'consigneePostcode',
        fieldLabel: '邮编',
        fieldElabel: 'consigneePostcode',
        ruleType: 'length',
        ruleValue: '0-20'
      },
      {
        fieldName: 'consigneeMail',
        fieldLabel: '邮箱',
        fieldElabel: 'consigneeMail',
        ruleType: 'length',
        ruleValue: '0-50'
      },
      {
        fieldName: 'consigneeTelephone',
        fieldLabel: '电话',
        fieldElabel: 'consigneeTelephone',
        ruleType: 'length',
        ruleValue: '0-40'
      },
      {
        fieldName: 'consigneeMobile',
        fieldLabel: '手机号',
        fieldElabel: 'consigneeMobile',
        ruleType: 'length',
        ruleValue: '0-40'
      },
      {
        fieldName: 'tariffNo',
        fieldLabel: 'EORI',
        fieldElabel: 'tariffNo',
        msg: '数字、字母，20个字符以内',
        emsg: 'Numbers, letters, within 20 characters',
        placeholder: '数字、字母，20个字符以内',
        eplaceholder: 'Numbers, letters, within 20 characters',
        ruleType: 'length',
        ruleValue: '0-20'
      }
    ],
    resShipper = [
      {
        fieldName: 'shipperName',
        fieldLabel: '姓名',
        fieldElabel: 'shipperName',
        ruleType: 'length',
        ruleValue: '0-100'
      },
      {
        fieldName: 'shipperCompany',
        fieldLabel: '公司',
        fieldElabel: 'shipperCompany',
        ruleType: 'length',
        ruleValue: '0-100'
      },
      {
        fieldName: 'shipperProvince',
        fieldLabel: '省/州',
        fieldElabel: 'shipperProvince',
        ruleType: 'length',
        ruleValue: '0-60'
      },
      {
        fieldName: 'shipperTelephone',
        fieldLabel: '电话',
        fieldElabel: 'shipperTelephone',
        ruleType: 'length',
        ruleValue: '0-40'
      },
      {
        fieldName: 'shipperCity',
        fieldLabel: '城市',
        fieldElabel: 'shipperCity',
        ruleType: 'length',
        ruleValue: '0-60'
      },
      {
        fieldName: 'shipperPostcode',
        fieldLabel: '邮编',
        fieldElabel: 'shipperPostcode',
        ruleType: 'length',
        ruleValue: '0-20'
      },
      {
        fieldName: 'shipperAddress1',
        view: 'textarea',
        fieldLabel: '详细地址',
        fieldElabel: 'shipperAddress1',
        ruleType: 'length',
        ruleValue: '0-330'
      }
    ],
    resultDeclare = {
      ename: {
        fieldName: 'ename',
        fieldLabel: '英文名',
        fieldElabel: 'ename',
        ruleType: 'length',
        ruleValue: '0-100'
      },
      cname: {
        fieldName: 'cname',
        fieldLabel: '中文名',
        fieldElabel: 'cname',
        ruleType: 'length',
        ruleValue: '0-66'
      },
      unitPrice: {
        fieldName: 'unitPrice',
        fieldLabel: '单价',
        fieldElabel: 'unitPrice',
        ruleType: 'length',
        ruleValue: '0-9'
      },
      pcs: {
        fieldName: 'pcs',
        fieldLabel: '数量',
        fieldElabel: 'pcs',
        ruleType: 'length',
        ruleValue: '0-100'
      },
      hscode: {
        fieldName: 'hscode',
        fieldLabel: '海关编号',
        fieldElabel: 'hscode',
        ruleType: 'length',
        ruleValue: '0-10'
      },
      name: {
        fieldName: 'name',
        fieldLabel: '配货',
        fieldElabel: 'name',
        ruleType: 'length',
        ruleValue: '0-10'
      },
      note: {
        fieldName: 'note',
        fieldLabel: '备注',
        fieldElabel: 'note',
        ruleType: 'length',
        ruleValue: '0-200'
      },
      url: {
        fieldName: 'url',
        fieldLabel: 'URL',
        fieldElabel: 'url',
        ruleType: 'length',
        ruleValue: '0-800'
      },
      skuCode: {
        fieldName: 'skuCode',
        fieldLabel: 'SKU代码',
        fieldElabel: 'SKU Code',
        ruleType: 'length',
        ruleValue: '0-20'
      },
      declareSpecification: {
        fieldName: 'declareSpecification',
        fieldLabel: '规格型号',
        fieldElabel: 'declareSpecification',
        ruleType: 'length',
        ruleValue: '0-20'
      }
    }
  const maps = rules.ruleMap || {}
  let ecCode = maps.ecCode
  let currency =
    maps.declareCurrency && maps.declareCurrency.length > 0
      ? JSON.parse(maps.declareCurrency[0].ruleValue)
      : ''
  if (currency) {
    const ks = []
    currency.forEach(item => {
      for (let k in item) {
        ks.push({ value: k, label: item[k] })
      }
    })
    currency = ks
    resultDeclare.unitPrice.addonAfter = currency
  }
  let shipperIsRequired = true
  let materialIsRequired = false // 申报信息 - 材质
  let usesIsRequired = false // 申报信息 - 用途
  let customerOrderNo = {
      msg: '数字、字母及中横线组合',
      emsg: 'Alphanumeric or hypthen',
      fieldLabel: '客户单号',
      fieldElabel: "customer's order number",
      ruleType: 'regex',
      ruleValue: '^$|^[0-9a-zA-Z-]*$',
      max: 30,
      fieldName: 'customerOrderNo',
      placeholder: '数字、字母及中横线组合',
      eplaceholder: 'Alphanumeric or hypthen',
      type: 'input'
    },
    customerWeight = {
      msg: '正数',
      emsg: 'Positive number',
      fieldElabel: 'weight',
      fieldLabel: '重量',
      fieldName: 'customerWeight',
      invisible: 'regex',
      max: 8,
      placeholder: '正数',
      ruleType: 'regex',
      type: 'input',
      ruleValue:
        '^1(.d{1,3})?$|^2(.0{1,3})?$|^0.[0-9]{0,2}[1-9]$|^0.[1-9][0-9]{0,2}$|^0.[0-9][1-9][0-9]$'
    },
    serverOrderNo,
    freightCharges,
    declareInsurance,
    iossNo,
    codStringPrice,
    codServerNo,
    lucidNo

  // cod
  if (
    rules &&
    rules.extraService &&
    rules.extraService.collectionPayment == 'Y'
  ) {
    codStringPrice = {
      fieldElabel: 'COD',
      fieldLabel: '代收货款金额',
      fieldName: 'codStringPrice',
      type: 'input',
      addonAfter: [{ value: 'PHP', label: 'PHP' }],
      rules: [
        { required: true, message: '代收货款金额不能为空' },
        {
          pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
          message: '代收货款金额保留小数点后2位'
        }
      ]
    }
  }
  if (rules && rules.isCodAgainDelivery == 'Y') {
    codServerNo = {
      fieldElabel: 'COD妥投失败服务商单号',
      fieldLabel: 'COD妥投失败服务商单号',
      fieldName: 'codServerNo',
      type: 'input',
      rules: [{ required: true, message: 'COD妥投失败服务商单号不能为空' }]
    }
  }

  for (let k in maps) {
    const ruleArr = maps[k]
    var item = { rules: [] }
    let invisible
    for (let i = 0; i < ruleArr.length; i++) {
      const rule = ruleArr[i]
      const type = rule.ruleType
      const value = rule.ruleValue
      // 记录是否存在“隐藏”规则
      if (type) invisible = type
      // type == 'nullValue' && value == 'Y' 表示必填
      const isRequired = type == 'nullValue' && value == 'Y'
      Object.assign(item, rule)
      if (isRequired) {
        item.isRequired = true
        rule.isRequired = true
      }

      // 发件人是否可见
      if (k == 'shipperName' && type == 'invisible' && value == 'Y') {
        shipperIsRequired = false
        resShipper = resShipper.filter(x => x.fieldName != k)
      }
      if (item.fieldName.indexOf('shipper') > -1) {
        // 某些产品下，字段必填
        if (
          ['A1', 'A5', 'CJ', 'TH', 'TI', 'TJ', 'TK'].indexOf(productCode) > -1
        ) {
          if (
            [
              'shipperName',
              'shipperProvince',
              'shipperCity',
              'shipperAddress1',
              'shipperPostcode',
              'shipperTelephone'
            ].indexOf(item.fieldName) > -1
          ) {
            item.rules.push({
              required: true,
              message: item.placeholder
            })
          }
        }
        resShipper.forEach(x => {
          if (x.fieldName == item.fieldName) {
            Object.assign(x, item)
          }
        })
      }

      // 申报信息 - 材质
      if (k == 'material' && type == 'visible' && value == 'Y') {
        materialIsRequired = true
      }
      // 申报信息 - 用途
      if (k == 'uses' && type == 'visible' && value == 'Y') {
        usesIsRequired = true
      }
      // 重量 客户单号 服务单号 运费 申报保险费 IOSS号
      if (k == 'customerOrderNo') {
        item.max = 30
        item.type = 'input'
        customerOrderNo = item
      }
      if (k == 'customerWeight') {
        item.max = 8
        item.type = 'input'
        customerWeight = item
      }
      if (k == 'serverOrderNo' && type == 'invisible' && value == 'Y') {
        item.max = 30
        item.type = 'input'
        serverOrderNo = item
      }
      if (k == 'freightCharges' && type == 'visible' && value == 'Y') {
        item.max = 8
        item.type = 'input'
        item.addonAfter = currency
        freightCharges = item
        // productCode A2 countryCode IL
        if (productCode == 'A2' && countryCode == 'IL') {
          item.isRequired = true
          freightCharges.isRequired = true
          item.rules.push({ required: true, message: '运费不能为空' })
        }
      }
      if (k == 'declareInsurance' && type == 'visible' && value == 'Y') {
        item.max = 8
        item.type = 'input'
        item.addonAfter = currency
        declareInsurance = item
      }
      if (k == 'iossNo' && type == 'visible' && value == 'Y') {
        item.type = 'input'
        iossNo = item
      }

      if (k == 'lucidNo' && type == 'visible' && value == 'Y') {
        ;(item.fieldLabel = 'LUCID号'), (item.type = 'input')
        lucidNo = item
      }

      // 截取收件人相关规则
      if (rule.fieldName.indexOf('consignee') > -1) {
        if (rule.fieldName.indexOf('consigneeAddress') > -1) {
          item.view = 'textarea'
        }
      } else if (rule.fieldName.indexOf('shipper') > -1) {
        // 截取发件人相关规则
        if (rule.fieldName.indexOf('shipperAddress') > -1) {
          item.view = 'textarea'
        }
      }
      item.rules = item.rules.concat(generatorRules(rule))
      item.invisible = invisible

      if (k === 'tariffNo' && isRequired) {
        resConsignee.forEach(x => {
          if (x.fieldName === 'tariffNo') {
            x.isRequired = true
            Object.assign(x, item)
          }
        })
      }
    }

    // 收发件人
    if (Object.keys(item).length > 1) {
      if (item.fieldName.indexOf('consignee') > -1) {
        const fieldName = item.fieldName
        const type = item.ruleType
        const value = item.ruleValue
        if (fieldName === 'consigneeName') {
          if (type == 'invisible' && value == 'Y') {
            resConsignee = resConsignee.filter(
              x => x.fieldName != 'consigneeName'
            )
          }
        } else if (
          fieldName === 'consigneeFirstName' ||
          fieldName === 'consigneeLastName'
        ) {
          if (type == 'invisible' && value == 'Y') {
            resConsignee = resConsignee.filter(
              x =>
                ['consigneeFirstName', 'consigneeLastName'].indexOf(
                  x.fieldName
                ) == -1
            )
          }
        }

        // 某些产品下，字段必填
        if (
          ['A1', 'A5', 'CJ', 'TH', 'TI', 'TJ', 'TK'].indexOf(productCode) > -1
        ) {
          if (item.fieldName === 'consigneeTelephone') {
            item.rules.push({
              required: true,
              message: item.placeholder
            })
          }
        }

        // 更新当前项
        resConsignee.forEach(x => {
          if (x.fieldName == fieldName) {
            Object.assign(x, item)
          }
        })
      }
    }

    item.val = ''

    // 申报信息
    if (k == 'ename') resultDeclare.ename = item // 英文品名
    if (k == 'cname') resultDeclare.cname = item // 中文品名
    if (k == 'unitPrice') {
      // 申报单价
      resultDeclare.unitPrice = item
      resultDeclare.unitPrice.addonAfter = currency
    }
    if (k == 'pcs') resultDeclare.pcs = item // 数量
    if (k == 'hscode') resultDeclare.hscode = item // 海关编码
    if (k == 'name') resultDeclare.name = item // 配货
    if (k == 'note') resultDeclare.note = item // 备注
    if (k == 'url') resultDeclare.url = item // URL

    if (k == 'material' && materialIsRequired) resultDeclare.material = item // 材质
    if (k == 'uses' && usesIsRequired) resultDeclare.uses = item // 用途

    if (k == 'declareSpecification') {
      // 规格型号
      resultDeclare.declareSpecification = item
      resultDeclare.declareSpecification.rules &&
        resultDeclare.declareSpecification.rules.splice(0, 0, {
          required: true,
          message: item.msg
        })
    }
    if (k == 'skuCode') {
      // skuCode
      resultDeclare.skuCode = item
      resultDeclare.skuCode.rules &&
        resultDeclare.skuCode.rules.splice(0, 0, {
          required: true,
          message: item.msg
        })
    }
  }

  // 附加服务 extraService
  const extraService = rules.extraService || {}
  /**
   * 交货区域和相对应的服务商
   */
  const declarationMap = rules.declarationMap
  const declarationMapOptions = []
  if (declarationMap) {
    for (var i in declarationMap) {
      declarationMapOptions.push({
        label: i,
        value: `${i}_${declarationMap[i]}`
      })
    }
  }

  const rs = {
    fba: {
      fieldLabel: 'FBA头程',
      fieldElabel: 'FBA',
      fieldName: 'fbaCode',
      val: 'N',
      exist: extraService.fba
    },
    declaration: {
      fieldLabel: '单独报关',
      fieldElabel: 'Separate declaration',
      fieldName: 'isDeclare',
      val: 'N',
      exist: extraService.declaration
    },
    signature: {
      fieldLabel: '签名服务',
      fieldElabel: 'Signature service',
      fieldName: 'hasSignature',
      val: 'N',
      exist: extraService.signature
    }
  }

  if (extraService && extraService.returnable == 'Y') {
    rs.returnable = {
      fieldLabel: '是否需要服务商退件',
      fieldElabel: 'Whether to support return',
      fieldName: 'isNeedSendBack',
      val: 'N',
      exist: extraService.returnable,
      tips:
        Cookies.get('newOrderLang') !== 'en-US'
          ? '注：联邮通退件策略为报价表有“退件及重派服务”且勾选“是”才提供退件服务，详情及费用请参考报价表'
          : 'Note: The return policy of UnionPay is to provide return services only when there is "Return and Redelivery Services" in the quotation table and "Yes" is checked. For details and fees, please refer to the quotation table'
    }
  }
  //交货区域  企业海关编码
  ;(rs.deliveryArea = {
    fieldLabel: '交货区域',
    fieldElabel: 'Delivery area',
    fieldName: 'deliveryArea',
    isSpeical: true,
    exist: declarationMapOptions.length ? 'Y' : '',
    options: declarationMapOptions,
    val: declarationMapOptions[0] && declarationMapOptions[0].value
  }),
    (rs.ecCode = {
      fieldElabel: 'Enterprise Customs code',
      fieldLabel: '企业海关编码',
      fieldName: 'ecCode',
      isSpeical: true,
      rules:
        ecCode && ecCode.length
          ? generatorRules({ ...ecCode[0], isRequired: true })
          : []
    })

  // 保险
  const insurance = {}
  if (
    extraService.insurance5y == 'Y' ||
    extraService.insurance6p == 'Y' ||
    extraService.insurance8y == 'Y'
  ) {
    insurance.exist = 'Y'
    insurance.isInsurace = true
    insurance.fieldLabel = '保险'
    insurance.fieldElabel = 'insurance'
    insurance.options = []
    if (extraService.insurance5y == 'Y') {
      insurance.options.push({
        label: '5元每票',
        elabel: '5 yuan per ticket',
        value: '5y',
        etitle:"Insurance price: Free Sifang’s own value-added insurance service (the insurance amount is recommended not to exceed USD100)",
        title: '保价：递四方自有增值保险服务(投保金额建议不超过USD100)',
        content: '注意：①成功购买保价服务以我司审核通过为准，详情可查阅安全投保页面已投保单②保价理赔标准：投保金额与实际成交价值或损失金额相比较，取低者，最高不超过USD100。具体参考递四方官网保价服务；',
        econtent:"Note: ①Successful purchase of insured services is subject to the approval of our company. For details, please refer to the insured form on the security insurance page. For details, please refer to the insured service on the Sifang official website;"
      })
    }
    if (extraService.insurance8y == 'Y') {
      insurance.options.push({
        label: '8元每票',
        elabel: '8 yuan per ticket',
        value: '8y',
        title: '保价:递四方自有增值保险服务(投保金额建议不超过USD300)',
        etitle:
          "Insured price: handing out the Quartet's own value-added insurance service (the recommended amount of insurance does not exceed USD300)"
      })
    }
    if (extraService.insurance6p == 'Y') {
      insurance.options.push({
        label: '0.6%保费',
        elabel: '0.6% premium',
        value: '6p',
        title: '保险：与第三方华泰保险公司合作保险服务(投保金额建议超过USD100)',
        etitle:'Insurance: Cooperative insurance service with the third-party Huatai Insurance Company (the insurance amount is recommended to exceed USD100)',
        content:'注意：①成功购买保险服务以有保单为准，详情可查阅安全投保页面已投保单 ②保险理赔标准：具体参考递四方官网保险服务；',
        econtent:"Note: ①Successful purchase of insurance services is subject to the existence of an insurance policy. For details, please refer to the insurance policy on the security insurance page."
      })
    }
  }

  // 客户单号、重量、服务商单号 加入 resExtraService 中
  // 对于服务商单号需要额外规则判断是否可显示，即通过 isNeedServiceNo
  let resExtraService = []
  if (customerOrderNo) {
    if (!customerOrderNo.rules) {
      customerOrderNo.rules = generatorRules(customerOrderNo)
    }
    resExtraService.push(customerOrderNo)
  }
  if (customerWeight) {
    if (!customerWeight.rules) {
      customerWeight.rules = generatorRules(customerWeight)
    }
    resExtraService.push(customerWeight)
  }
  if (serverOrderNo) resExtraService.unshift(serverOrderNo)
  if (freightCharges) resExtraService.push(freightCharges)
  if (declareInsurance) resExtraService.push(declareInsurance)
  if (iossNo) resExtraService.push(iossNo)
  if (codStringPrice) resExtraService.push(codStringPrice)
  if (codServerNo) resExtraService.push(codServerNo)
  if (lucidNo) resExtraService.push(lucidNo)

  // 选择分销商
  let dis = sessionStorage.getItem('dss-u001')
  if (dis) {
    dis = JSON.parse(decodeURIComponent(dis))
    if (dis.distributions && dis.distributions.length) {
      const list = [{ label: '当前账号', value: '' }]
      dis.distributions.forEach(item => {
        list.push({
          label: item.distributorName,
          value: item.distributorCode
        })
      })
      resExtraService.push({
        fieldElabel: 'Select a distributor',
        fieldLabel: '选择分销商',
        fieldName: 'distributorCode',
        placeholder: '选择分销商',
        eplaceholder: 'Select a distributor',
        type: 'select',
        value: '',
        options: list
      })
    }
  }
  // 香港自提点
  if (
    productCode == 'S893' &&
    rules.pickUpStationList &&
    rules.pickUpStationList.length
  ) {
    resExtraService.push({
      fieldElabel: 'Pick-up point',
      fieldLabel: '自提点',
      fieldName: 'pickUpStationName',
      placeholder: '选择自提点',
      eplaceholder: 'Select a pick-up point',
      type: 'select',
      val: '',
      rules: [{ required: true, message: '请选择自提点' }],
      options: rules.pickUpStationList.map(item => ({
        label: `${item.name}->${item.address}`,
        value: item.code
      }))
    })
  }
  /**
   * SU产品对齐旧系统 - 需要支持单票件数
   */
  if (productCode === "SU") {
    rules.multipleFlag = "Y"
  }

  handlerParcellCount(resExtraService, rules.multipleFlag === 'Y')
  handlerNewPost(resExtraService, productCode, countryCode)

  for (let k in rs) {
    if (rs[k].exist && rs[k].exist == 'Y') {
      resExtraService.push(rs[k])
    }
  }

  // 处理结果
  const resultExtraService = groupListByLen(resExtraService, 4)
  const { res_Shipper, res_consignee } = sortField(resShipper, resConsignee)
  const resultConsignee = groupListByLen(res_consignee, 4)
  const resultShipper = groupListByLen(res_Shipper, 4)

  return {
    providerReturnIsRequired: ['S951', 'S952'].includes(productCode), // 针对特定产品代码S951\S952，下单页面新增服务商退件信
    resultExtraService,
    resultConsignee,
    resultShipper,
    shipperIsRequired,
    resultDeclare,
    materialIsRequired,
    usesIsRequired,
    extraService: rules.extraService,
    ruleMap: maps,
    insurance
  }
}

export { handlerRules }
