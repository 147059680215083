<i18n>
{
  "zh": {
    "title": "请选择要咨询的服务",
    "fb4": "海外仓",
    "grs": "退件",
    "dss": "直发",
    "trs": "转运"
  },
  "ja": {
    "title": "相談するサービスを選択してください",
    "fb4": "海外サービス",
    "grs": "戻るサービス",
    "dss": "ストレートヘアサービス",
    "trs": "輸送輸入業務"
  },
  "de": {
    "title": "Auswahl von Beratungsleistungen",
    "fb4": "Overseas Service",
    "grs": "Return Service",
    "dss": "Straight hair service",
    "trs": "Umschlagsimportgeschäft"
  },
  "en": {
    "title": "Please select the service to be consulted",
    "fb4": "Overseas Service",
    "grs": "Return Service",
    "dss": "Straight hair service",
    "trs": "Transshipment import business"
  }
}
</i18n>
<template>
  <div class="IM">
    <div class="tit">
      {{ $t('title') }}
    </div>
    <a-button
      type="primary"
      v-if="isOpenDDS && urls['DSS']"
      class="im-button"
      ghost
      @click="openWindow('DSS')"
      data-baidu-pv="顶部导航栏，直发服务，在线客服咨询"
      >{{ $t('dss') }}</a-button
    >
    <a-button
      v-if="isFB4 && urls['FB4']"
      type="primary"
      class="im-button"
      ghost
      @click="openWindow('FB4')"
      data-baidu-pv="顶部导航栏，海外仓服务，在线客服咨询"
      >{{ $t('fb4') }}</a-button
    >
    <!-- <a-button
      v-show="urls['GRS']"
      type="primary"
      class="im-button"
      ghost
      @click="openWindow('GRS')"
      data-baidu-pv="顶部导航栏，退件服务，在线客服咨询"
      >{{ $t('grs') }}</a-button
    > -->

    <a-button
      v-show="urls['TRS']"
      type="primary"
      class="im-button ghost-button-hover"
      ghost
      data-baidu-pv="顶部导航栏，转运服务，在线客服咨询"
      @click="openWindow('TRS')"
      >{{ $t('trs') }}</a-button
    >
    <a-button
      type="primary"
      class="im-button ghost-button-hover"
      ghost
      data-baidu-pv="顶部导航栏，香港集运，在线客服咨询"
      @click="openHKjY()"
      >{{ $t('香港集运') }}</a-button
    >
  </div>
</template>

<script>
export default {
  name: 'IM',
  data() {
    return {
      userData: {
        userInfo: {},
        markCodes: []
      }
    }
  },
  computed: {
    urls() {
      return this.$store.state.imUrls || {}
    },
    isOpenDDS() {
      return this.$store.getters.getDSSOpen
      // && this.$store.state.brandStatus.DSS != 4
    },
    isFB4() {
      return this.$store.getters.getFB4Open
      //  && this.$store.state.brandStatus.FB4 != 4
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$get('v2/user/accountSecurity').then(res => {
        this.userData = res
      })
    },
    encodeUTF8 (s) {
      var i = 0
      var r = []
      var c = ''
      var x = ''
      for (i = 0; i < s.length; i++) {
        if ((c = s.charCodeAt(i)) < 0x80) {
          r.push(c)
        } else if (c < 0x800) {
          r.push(0xC0 + (c >> 6 & 0x1F), 0x80 + (c & 0x3F))
        } else {
          if ((x = c ^ 0xD800) >> 10 === 0) {
            // 对四字节UTF-16转换为Unicode
            c = (x << 10) + (s.charCodeAt(++i) ^ 0xDC00) + 0x10000
            r.push(0xF0 + (c >> 18 & 0x7), 0x80 + (c >> 12 & 0x3F))
          } else {
            r.push(0xE0 + (c >> 12 & 0xF))
            r.push(0x80 + (c >> 6 & 0x3F), 0x80 + (c & 0x3F))
          }
        }
      }
      return r
    },
    sha1 (s) {
      // 字符串加密成 hex 字符串
      var data = new Uint8Array(this.encodeUTF8(s))
      var i, j, t
      var l = ((data.length + 8) >>> 6 << 4) + 16
      s = new Uint8Array(l << 2)
      s.set(new Uint8Array(data.buffer))
      s = new Uint32Array(s.buffer)
      for (t = new DataView(s.buffer), i = 0; i < l; i++)s[i] = t.getUint32(i << 2)
      s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8)
      s[l - 1] = data.length << 3
      var w = []
      var f = [
        function () { return m[1] & m[2] | ~m[1] & m[3] },
        function () { return m[1] ^ m[2] ^ m[3] },
        function () { return m[1] & m[2] | m[1] & m[3] | m[2] & m[3] },
        function () { return m[1] ^ m[2] ^ m[3] }
      ]
      var rol = function (n, c) { return n << c | n >>> (32 - c) }
      var k = [1518500249, 1859775393, -1894007588, -899497514]
      var m = [1732584193, -271733879, null, null, -1009589776]
      m[2] = ~m[0]
      m[3] = ~m[1]
      for (i = 0; i < s.length; i += 16) {
        var o = m.slice(0)
        for (j = 0; j < 80; j++) {
          w[j] = j < 16 ? s[i + j] : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1)
          t = rol(m[0], 5) + f[j / 20 | 0]() + m[4] + w[j] + k[j / 20 | 0] | 0
          m[1] = rol(m[1], 30)
          m.pop()
          m.unshift(t)
        }
        for (j = 0; j < 5; j++) m[j] = m[j] + o[j] | 0
      }
      t = new DataView(new Uint32Array(m).buffer)
      for (var p = 0; p < 5; p++) m[p] = t.getUint32(p << 2)
      var hex = Array.prototype.map.call(new Uint8Array(new Uint32Array(m).buffer), function (e) {
        return (e < 16 ? '0' : '') + e.toString(16)
      }).join('')
      return hex
    },
    createRandomString (len) {
      // 生成随机字符串
      len = len || 32
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789' // 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
      let maxlen = chars.length
      let rs = ''
      for (var i = 0; i < len; i++) {
        rs += chars.charAt(Math.floor(Math.random() * maxlen))
      }
      return rs
    },
    openHKjY() {
      let customCode = this.userData?.userInfo?.customCode || ''
      let userId = this.userData?.userInfo?.userId || ''
      let baseStr = `https://4px.s2.udesk.cn/im_client/?web_plugin_id=54808`
      let nonce = this.createRandomString(30)
      let timestamp = +new Date()
      let hrefUrl = ''
      if (customCode) {
        let c_cf_dialogueDesc = encodeURI(JSON.stringify({c_name: customCode, entryType: 'PC', customerCode: customCode, subAccount: userId || ''}))
        let sign_str = this.sha1(`nonce=${nonce}&timestamp=${timestamp}&web_token=${customCode}&5c7152db60d234b21161587913dc40e3`).toUpperCase()
        hrefUrl = `${baseStr}&nonce=${nonce}&timestamp=${timestamp}&web_token=${customCode}&signature=${sign_str}&c_desc=商家中心香港集运&c_cf_dialogueDesc=${c_cf_dialogueDesc}&c_name=${customCode}`
      } else {
        hrefUrl = baseStr
      }
      window.open(
        encodeURI(hrefUrl),
        '_blank',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes,width=780,height=800'
      )
    },
    openWindow(brand) {
      let url = this.urls[brand]
      if (brand === 'TRS') {
        // 转运客服变更
        // https://devops.aliyun.com/projex/project/9724d6a1374496913279cc6bd8/req#viewIdentifier=d7f112f9d023e2108fa1b0d8&openWorkitemIdentifier=113fb38e42fb2e0e4c77c2c8ee
        window.open(`${window.location.origin}/dist/addService?value=3`)
        return
      }
      if (brand == 'FB4' || brand == 'DSS') {
        let markCodesItme = this.userData.markCodes?.find(
          item => item.brand == brand
        )
        if (
          !this.userData.userInfo?.customCode ||
          !markCodesItme?.markCode ||
          !this.userData.userInfo?.userId
        ) {
          this.$message.warning('登陆超时，请重新登陆!')
          return
        }
        url =
          url +
          `&c_cf_dialogueDesc={"entryType":"PC","customerCode":"${this.userData.userInfo?.customCode}","cmCode":"${markCodesItme?.markCode}","subAccount":"${this.userData.userInfo?.userId}"}`
      }
      window.open(
        encodeURI(url),
        '_blank',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes,width=780,height=800'
      )
    }
  }
}
</script>
<style lang="less" scoped>
.tit {
  margin-bottom: 14px;
  font-size: 16px;
  color: #323643;
  line-height: 16px;
  font-weight: 400;
}
.im-button {
  margin-right: 6px;
  display: inline-block;
  padding: 0 18px;
  border: 1px solid #3960df;
  border-radius: 15px;
  min-width: 56px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #3960df;
  font-weight: 500;
  &:nth-last-child(1) {
    margin-right: 0;
  }
}
</style>
