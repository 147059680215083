<template>
  <a-select
    show-search
    option-filter-prop="children"
    :filter-option="filterOption"
    v-model="val"
    @change="handleChange"
    allowClear
  >
    <a-select-option :key="k" :value="item.code" v-for="(item, k) in list">
      {{ item.name }}
    </a-select-option>
    <em slot="suffixIcon" class="iconfont iconxiala" style="font-size: 10px;" />
  </a-select>
</template>
<script>
export default {
  name: 'transport-type',
  model: {
    prop: 'value', //绑定的值，通过父组件传递
    event: 'change'
  },
  props: {
    value: String
  },
  data() {
    return {
      list: [],
      val: ''
    }
  },
  watch: {
    value(val) {
      this.val = val
    }
  },
  created() {
    this.getTransport()
  },
  methods: {
    getTransport() {
      this.$get('v2/tranporttype/list', { cache: true }).then(res => {
        this.list = res || []
      })
    },
    handleChange(value) {
      console.log(value)
      this.$emit('change', value)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
