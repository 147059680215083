<!--
 * @Description: 开通fb4,才可查看数据权限
 * @Autor: S9637/chifuk
 * @Date: 2020-09-26 15:25:30
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-10 11:47:47
-->
<i18n>
{
  "zh": {
    "open": "开通",
    "fb4": "海外仓服务",
    "see": "，可查看"
  },
  "en": {
    "open": "Open",
    "fb4": "After FB4 Warehousing Service",
    "see": ", can be viewed"
  },
  "de": {
    "open": "Öffnen Sie",
    "fb4": "Überseeischer Lagerservice",
    "see": "zur Ansicht"
  },
  "ja": {
    "open": "オープン",
    "fb4": "海外倉庫サービス",
    "see": "を見る"
  }
}
</i18n>
<template>
  <div class="mast-view" :class="[$attrs.type]">
    <img
      src="../../../assets/check@2x.png"
      alt=""
      style="width: 80px; height: auto"
    />
    <div class="mask-bg">
      {{ $t('open') }}<span style="color: #065cf3">{{ $t('fb4') }}</span
      >{{ $t('see') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'mask-view',
  inheritAttrs: false,
  data() {
    this.imgType = {
      line: './images/linegb.png',
      pie: './images/piebg.png'
    }
    return {}
  }
}
</script>

<style lang="less" scoped>
.mast-view {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.line {
  background-image: url('./images/linebg.png');
}
.pie {
  background-image: url('./images/piebg.png');
}
</style>
