export const productColumns = vue => [
  {
    title: vue.$t('prdTable1'),
    dataIndex: 'product_name',
    key: 'product_name',
    scopedSlots: { customRender: 'product_name' }
  },
  {
    title: vue.$t('prdTable2'),
    dataIndex: 'destination_name',
    key: 'destination_name',
    width: '100px',
    scopedSlots: { customRender: 'destination_name' }
  },
  {
    title: vue.$t('prdTable3'),
    dataIndex: 'tatal_num',
    key: 'tatal_num',
    width: '100px',
    scopedSlots: { customRender: 'tatal_num' }
  },
  {
    title: vue.$t('prdTable4'),
    dataIndex: 'deliever_num',
    key: 'deliever_num',
    width: '100px',
    scopedSlots: { customRender: 'deliever_num' }
  },
  {
    title: vue.$t('prdTable5'),
    dataIndex: 'deliever_rate',
    key: 'deliever_rate',
    width: '100px',
    scopedSlots: { customRender: 'deliever_rate' }
  },
  {
    title: vue.$t('prdTable6'),
    dataIndex: 'avg_deliever_day',
    key: 'avg_deliever_day',
    width: '80px',
    scopedSlots: { customRender: 'avg_deliever_day' }
  },
  {
    title: vue.$t('prdTable7'),
    dataIndex: 'num_3',
    key: 'num_3',
    width: '100px',
    scopedSlots: { customRender: 'num_3' }
  },
  {
    title: vue.$t('prdTable8'),
    dataIndex: 'num_3_5',
    key: 'num_3_5',
    width: '100px',
    scopedSlots: { customRender: 'num_3_5' }
  },
  {
    title: vue.$t('prdTable9'),
    dataIndex: 'num_5_7',
    key: 'num_5_7',
    width: '100px',
    scopedSlots: { customRender: 'num_5_7' }
  },
  {
    title: vue.$t('prdTable10'),
    dataIndex: 'num_7_10',
    key: 'num_7_10',
    scopedSlots: { customRender: 'num_7_10' }
  },
  {
    title: vue.$t('prdTable11'),
    dataIndex: 'num_10_15',
    key: 'num_10_15',
    scopedSlots: { customRender: 'num_10_15' }
  },
  {
    title: vue.$t('prdTable12'),
    dataIndex: 'num_15_20',
    key: 'num_15_20',
    scopedSlots: { customRender: 'num_15_20' }
  },
  {
    title: vue.$t('prdTable13'),
    dataIndex: 'num_20_30',
    key: 'num_20_30',
    scopedSlots: { customRender: 'num_20_30' }
  },
  {
    title: vue.$t('prdTable14'),
    dataIndex: 'num_30_m',
    key: 'num_30_m',
    scopedSlots: { customRender: 'num_30_m' }
  }
]
export const orderColumns = vue => [
  {
    title: vue.$t('orderTable1'),
    dataIndex: 'customer_hawb_code',
    key: 'customer_hawb_code',
    scopedSlots: { customRender: 'customer_hawb_code' }
  },
  {
    title: vue.$t('orderTable2'),
    dataIndex: 'fpx_track_no',
    key: 'fpx_track_no',
    scopedSlots: { customRender: 'fpx_track_no' }
  },
  {
    title: vue.$t('orderTable3'),
    dataIndex: 'service_hawb_code',
    key: 'service_hawb_code',
    scopedSlots: { customRender: 'service_hawb_code' }
  },
  {
    title: vue.$t('prdTable1'),
    dataIndex: 'p_name',
    key: 'p_name',
    scopedSlots: { customRender: 'p_name' }
  },
  {
    title: vue.$t('prdTable2'),
    dataIndex: 'destination_country',
    key: 'destination_country',
    scopedSlots: { customRender: 'destination_country' }
  },

  {
    title: vue.$t('orderTable6'),
    dataIndex: 'find_day',
    key: 'find_day',
    scopedSlots: { customRender: 'find_day' }
  },
  {
    title: vue.$t('orderTable7'),
    dataIndex: 'last_track_time',
    key: 'last_track_time',
    scopedSlots: { customRender: 'last_track_time' }
  },
  {
    title: vue.$t('orderTable8'),
    dataIndex: 'last_track_desc',
    key: 'last_track_desc',
    scopedSlots: { customRender: 'last_track_desc' }
  }
]
export const rankingColumns = vue => [
  {
    title: vue.$t('table1'),
    dataIndex: 'rangking',
    width: '70px',
    key: 'rangking',
    scopedSlots: { customRender: 'rangking' }
  },
  {
    title: vue.$t('table2'),
    dataIndex: 'product_name',
    key: 'product_name'
  },
  {
    title: vue.$t('table3'),
    dataIndex: 'deliever_rate',
    width: '80px',
    key: 'deliever_rate',
    scopedSlots: { customRender: 'ratedeliever_rate' }
  }
]
