/*
 * @Description: store center
 * @Autor: S9637/chifuk
 * @Date: 2020-08-27 18:14:35
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-04-28 16:52:29
 */

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import nav from './nav'
/* mrcro app dss store  */
import dssStoreMain from '@microDss/store/store.js'
import dssStoreAbnormal from '@microDss/store/abnormal.js'
// b2b海运
import oceanWaybill from './modules/oceanWaybill'
import airWaybill from './modules/airWaybill'

export default new Vuex.Store({
  state: {
    // zh:简体中文，en:英文
    locale: 'zh',
    // loading
    loading: false,
    // 首次登陆
    firstVisit: false,
    // 标记试单账户认证, 不为空表示当前操作的品牌
    tryValidateBrandCode: '',
    // 忽略权限控制白名单，
    ignore: [
      'refresh',
      'iframeView',
      'announcementALL',
      'announcementFB4',
      'announcementGRS',
      'announcementDSS',
      'announcementTRS',
      'announcementDetail',
      'printRecharge',
      'consumeDetail',
      // 'dssConsumeDetail',
      'waterDetail',
      'freezeDetail',
      'outbillDetail',
      'personal',
      'personal_online',
      'company_info',
      'certification',
      'certificationEdit',
      'choose_qualification',
      'company_1',
      'company_2',
      'company_3',
      'company_4',
      'company_5',
      'company_fail_info',
      'company_warn',
      'msgDetail',
      'serviceListDetail',
      'complaintDetail',
      'consultingDetail',
      'orderManagement',
      'delivery',
      'authorized',
      'skuAssociation',
      'skuDeclare',
      'orderDetail',
      'warehouseChargeDetail',
      'inventoryDiagnosis',
      'oceanWaybill',
      'createOceanWaybill',
      'airWaybill',
      'createAirWaybill',
      'index_settings'
    ],
    // 子菜单，但在显示上却是树形结构
    // 如：消息中心：分出了4个子菜单（全部、fb4、...）但仅属于一个权限
    oneMenuWithSub: [['msgAll', 'msgFB4', 'msgGRS', 'msgXMS']],
    contractTipsModal: false,
    // 菜单数组
    menuItems: [],
    // 左侧菜单，已激活分组
    defaultOpenKeys: [],
    // 左侧菜单，当前选中菜单
    defaultSelectedKeys: [],
    // 当前用户信息
    userInfo: {
      ismainAccount: true,
      isDSSOpen: false,
      isFB4Open: false,
      isGRSOpen: false,
      isTRSOpen: false
    },
    // 账号注册状态
    accountRegStatus: {
      contractApply: false, //合同申请状态 true:已申请
      contractSign: false, //合同签署状态 true:已签署
      currentNode: '', //当前节点：certification:待实名，contractApply:待合同申请，contractSign:待合同签署，brandSuccess:品牌开通成功
      certification: false, //实名认证状态 true:已实名
      customerProfile: false, //客户类型
      entity: 1 //	1：个人  2：企业
    },
    // 新手指引状态
    guideStatus: 1,
    // 是否为试单用户
    isTryAccount: true,
    // 用户名
    username: '',
    // 首页的banner
    hasTopBanner: false,
    // IM
    imUrls: {
      DSS: '',
      FB4: '',
      GRS: '',
      TRS: ''
    },
    customCode: '',
    ptyCode: '',
    brandStatus: {
      DSS: 0,
      FB4: 0,
      GRS: 0,
      TRS: 0
    },
    miniProgramPopup: false
  },
  getters: {
    getMainAccountStatus() {
      return sessionStorage.getItem('is.main.account') > 0
    },
    getDSSOpen(state) {
      const u = sessionStorage.getItem('uinfo')
      if (u) {
        const v = JSON.parse(u)
        return v.isDSSOpen
      }
      return state.userInfo.isDSSOpen
    },
    getFB4Open(state) {
      const u = sessionStorage.getItem('uinfo')
      if (u) {
        const v = JSON.parse(u)
        return v.isFB4Open
      }
      return state.userInfo.isFB4Open
    },
    getGRSOpen(state) {
      const u = sessionStorage.getItem('uinfo')
      if (u) {
        const v = JSON.parse(u)
        return v.isGRSOpen
      }
      return state.userInfo.isGRSOpen
    },
    getTRSOpen(state) {
      const u = sessionStorage.getItem('uinfo')
      if (u) {
        const v = JSON.parse(u)
        return v.isTRSOpen
      }
      return state.userInfo.isTRSOpen
    },
    getUserInfo(state) {
      const u = sessionStorage.getItem('uinfo')
      if (u) {
        return JSON.parse(u)
      }
      return state.userInfo
    },
    isOverSeas() {
      let isOverSeas =
        location.hostname.indexOf('eu') > -1 ||
        // location.hostname.indexOf('127.0.0.1') > -1 ||
        location.hostname.indexOf('asia') > -1 ||
        location.hostname.indexOf('us') > -1
      return isOverSeas
    },
    veriable() {
      let veriable = ''
      let hostname = location.hostname
      if (hostname.indexOf('eu') > -1) veriable = 'eu'
      if (hostname.indexOf('asia') > -1) veriable = 'asia'
      if (hostname.indexOf('us') > -1) veriable = 'us'
      return veriable
    }
  },
  mutations: {
    setIsmainAccount(state, val) {
      state.userInfo.ismainAccount = val ? true : false
      sessionStorage.setItem('is.main.account', val)
    },
    updateUserInfo(state, payload) {
      state.userInfo = payload
      if (payload) {
        sessionStorage.setItem('uinfo', JSON.stringify(payload))
      }
    },
    updateAccountRegStatus(state, payload) {
      state.accountRegStatus = {
        ...state.accountRegStatus,
        ...payload
      }
    }
  },
  actions: {},
  modules: {
    navModule: nav,
    microDss_main: dssStoreMain,
    microDss_abnormal: dssStoreAbnormal,
    oceanWaybill,
    airWaybill
  }
})
