var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"width":"520px","placement":"right","wrapClassName":"order-detial","closable":false,"visible":_vm.visible},on:{"close":_vm.onClose}},[_c('div',{staticClass:"detail-box"},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v("选择产品对比")]),_c('i',{staticClass:"iconfont iconlujing",on:{"click":_vm.onClose}})]),_c('div',{staticClass:"all-prod recommend-prod-box"},[(_vm.filtersProductList.length == 0)?_c('a-empty',{staticStyle:{"width":"100%","height":"270px","padding-top":"154px"}}):_vm._l((_vm.filtersProductList),function(item){return _c('div',{key:item.productCode,staticClass:"recommend-prod-item"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"tit-box"},[_c('div',{staticClass:"tit-content"},[_c('div',{staticClass:"name"},[_c('span',{staticClass:"name-text"},[_vm._v(" "+_vm._s(item.productName)+"("+_vm._s(item.productCode)+") ")]),(item.recommendTag)?_c('div',{staticClass:"recommend-tag"},[_vm._v(" "+_vm._s(item.recommendTag)+" ")]):_vm._e()]),_c('div',{staticClass:"tag-box"},[_c('div',{staticClass:"tag",class:{
                    'tag-hb': item.trackingType == 2,
                    'tag-gr': item.trackingType == 3
                  }},[_vm._v(" "+_vm._s(_vm._f("getNameFromValue")(item.trackingType,_vm.trackTypeArr))+" ")]),_c('div',{staticClass:"tag",class:{
                    'tag-g': item.isBattery == 1,
                    'tag-o': item.isBattery != 1
                  }},[_vm._v(" "+_vm._s(item.isBattery == 1 ? '带电' : '不可带电')+" ")]),_c('div',{staticClass:"tag-bt"},[_c('i',{staticClass:"iconfont iconfuwubiaozhun"}),_vm._v(" "+_vm._s(_vm._f("getNameFromValue")(item.serviceLevel,_vm.serviceLevelArr))+" | "+_vm._s(item.openCheckType == '1' ? '可开查' : item.openCheckType == '0' ? '不可开查' : '-')+" ")])])])])]),_c('div',{staticClass:"prod-opt",on:{"click":function($event){return _vm.delProductItem(item.productCode)}}},[_c('i',{staticClass:"iconfont iconshanchuchanpin"})])])})],2),_c('div',{staticClass:"footer-opt"},[_c('a-button',{staticClass:"contrast-btn",attrs:{"disabled":_vm.filtersProductList.length < 1,"type":"primary"},on:{"click":_vm.submitContrast}},[_vm._v("开始对比")]),_c('a-popconfirm',{attrs:{"placement":"topLeft","ok-text":"确认","cancel-text":"取消"},on:{"confirm":function($event){return _vm.$emit('clearSelectProductList')}}},[_c('template',{slot:"title"},[_c('p',[_vm._v("确认清空对比该产品？")])]),_c('span',{staticClass:"clear-btn"},[_vm._v("清空")])],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }