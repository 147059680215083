<!--
 * @Description: 直发入口
 * @Autor: S9637/chifuk
 * @Date: 2021-03-17 14:06:03
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-08-05 21:21:49
-->
<i18n>
{
  "zh": {
    "personal_center": "直发业务中心",
    "to_create": "单票下单",
    "successForcast": "单票下单预报成功",
    "successDraft": "单票下单保存成功",
    "batch_upload": "批量下单",
    "batch_preview":"批量下单预览",
    "managementinfo_received": "我的订单",
    "abnormal_all": "全部问题件",
    "abnormal_arrears": "欠费件",
    "abnormal_notify": "通知件",
    "order_oracle": "自打单问题订单",
    "abnormal_oracleNotify": "自打单客户通知件",
    "abnormal_oracleAbnormal": "自打单客户问题件",
    "collectSuccess": "揽收预报提交成功",
    "collectDetail": "揽收预报详情",
    "collectEdit": "揽收预报修改",
    "standard": "标准回复",
    "my_orderslabelPrint": "标签打印",
    "bagImport": "装袋/出库导入",
    "bagImportResult": "装袋/出库结果查询",
    "bagPrint": "袋标签打印",
    "insuranceindex": "安全投保",
    "trackindex": "轨迹查询",
    "documentindex": "单据管理",
    "my_billcustomer_amount_change": "我的账单",
    "couponindex": "我的优惠券",
    "payonlineindex": "我要充值",
    "chargeindex": "运费试算",
    "expense_detail": "费用明细",
    "electronic_bill": "电子账单",
    "platformAccountindex": "多账号管理",
    "customer_cargo_kindindex": "申报品管理",
    "dhl_senderindex": "DHL发件人白名单",
    "tophatter_accountindex": "tophatter账号绑定",
    "insurance_manageindex": "保险管理",
    "tokenindex": "TOKEN",
    "wish_accountindex": "WISH账号绑定",
    "vova_accountindex": "VOVA账号绑定",
    "order_problemabn": "问题订单",
    "permission_eclink": "增值服务",
    "permission_bagMenu": "装袋出库",
    "permission_setting": "管理中心",
    "trackcod": "代收货款数据看板",
    "customerInformation": "单独报关",
    "eclinkorder_list": "卖家助手",
    "orderManagement": "平台订单管理",
    "managementSKU": "SKU管理",
    "downloadRecord": "下载记录",
    "sellerAccount": "卖家账号",
    "newPDF": "新版手册",
    "collectindex": "揽收预报管理",
    "groupPackage": "揽收组包",
    "collectManager": "揽收预约",
    "difference": "揽收差异化报表",
    "collectAddress": "揽收地址管理",
    "orderDetail": "订单详情"
  },
  "en": {
    "personal_center": "DSS Business Center",
    "to_create": "Single ticket order",
    "successForcast": "Single ticket order forecast success",
    "successDraft": "Single ticket order saved successfully",
    "batch_upload": "Bulk orders",
    "batch_preview":"Batch preview",
    "managementinfo_received": "My Order",
    "abnormal_all": "All question pieces",
    "abnormal_arrears": "Unpaid",
    "abnormal_notify": "Notices",
    "order_oracle": "Self-ordering question orders",
    "abnormal_oracleNotify": "Self-ordering customer notifications",
    "abnormal_oracleAbnormal": "Self-ordering customer question pieces",
    "collectSuccess": "Collect forecast submission successful",
    "collectDetail": "Details of the collection forecast",
    "collectEdit": "Receiving forecast modification",
    "standard": "Standard reply",
    "my_orderslabelPrint": "Label Printing",
    "bagImport": "Bagging/out-of-warehouse import",
    "bagImportResult": "Bagging/out-of-storage results query",
    "insuranceindex": "Safe insurance",
    "trackindex": "track query",
    "documentindex": "Document management",
    "my_billcustomer_amount_change": "My Bill",
    "couponindex": "My coupon",
    "payonlineindex": "I want to top up",
    "chargeindex": "Freight trial",
    "expense_detail": "Cost Details",
    "electronic_bill": "Electronic Billing",
    "platformAccountindex": "Multi-account management",
    "customer_cargo_kindindex": "Declaration Management",
    "dhl_senderindex": "DHL sender whitelist",
    "tophatter_accountindex": "Binding Tophatter Account",
    "insurance_manageindex": "Insurance Management",
    "tokenindex": "TOKEN",
    "wish_accountindex": "WISH Account Binding",
    "vova_accountindex": "VOVA Account Binding",
    "order_problemabn": "Problem Order",
    "permission_eclink": "Value-added services",
    "permission_bagMenu": "Bag out of the warehouse",
    "permission_setting": "Management Center",
    "trackcod": "Cod data Kanban",
    "customerInformation": "separate customs declaration",
    "eclinkorder_list": "Seller's Assistant",
    "orderManagement": "Platform order management",
    "ManagementSKU": "SKU Management",
    "downloadRecord": "Download records",
    "sellerAccount": "sellerAccount",
    "newPDF": "new manual",
    "collectindex": "Collection forecast management",
    "groupPackage": "Create collection forecast",
    "collectManager": "LanShou booking",
    "difference": "difference",
    "collectAddress": "collectAddress",
    "orderDetail": "orderDetail"
  },
  "de": {
    "personal_center": "DSS Business Center",
    "to_create": "Single ticket order",
    "successForcast": "Single ticket order forecast success",
    "successDraft": "Single ticket order saved successfully",
    "batch_upload": "Bulk orders",
    "batch_preview":"Batch preview",
    "managementinfo_received": "My Order",
    "abnormal_all": "All question pieces",
    "abnormal_arrears": "Unpaid",
    "abnormal_notify": "Notices",
    "order_oracle": "Self-ordering question orders",
    "abnormal_oracleNotify": "Self-ordering customer notifications",
    "abnormal_oracleAbnormal": "Self-ordering customer question pieces",
    "collectSuccess": "Collect forecast submission successful",
    "collectDetail": "Details of the collection forecast",
    "collectEdit": "Receiving forecast modification",
    "standard": "Standard reply",
    "my_orderslabelPrint": "Label Printing",
    "bagImport": "Bagging/out-of-warehouse import",
    "bagImportResult": "Bagging/out-of-storage results query",
    "insuranceindex": "Safe insurance",
    "trackindex": "track query",
    "documentindex": "Document management",
    "my_billcustomer_amount_change": "My Bill",
    "couponindex": "My coupon",
    "payonlineindex": "I want to top up",
    "chargeindex": "Freight trial",
    "expense_detail": "Cost Details",
    "electronic_bill": "Electronic Billing",
    "platformAccountindex": "Multi-account management",
    "customer_cargo_kindindex": "Declaration Management",
    "dhl_senderindex": "DHL sender whitelist",
    "tophatter_accountindex": "Binding Tophatter Account",
    "insurance_manageindex": "Insurance Management",
    "tokenindex": "TOKEN",
    "wish_accountindex": "WISH Account Binding",
    "vova_accountindex": "VOVA Account Binding",
    "order_problemabn": "Problem Order",
    "permission_eclink": "Value-added services",
    "permission_bagMenu": "Bag out of the warehouse",
    "permission_setting": "Management Center",
    "trackcod": "Cod data Kanban",
    "customerInformation": "separate customs declaration",
    "eclinkorder_list": "Seller's Assistant",
    "orderManagement": "Platform order management",
    "ManagementSKU": "SKU Management",
    "downloadRecord": "Download records",
    "sellerAccount": "sellerAccount",
    "newPDF": "new manual",
    "collectindex": "Collection forecast management",
    "groupPackage": "Create collection forecast",
    "collectManager": "LanShou booking",
    "difference": "difference",
    "collectAddress": "collectAddress",
    "orderDetail": "orderDetail"
  },
  "ja": {
    "personal_center": "DSS Business Center",
    "to_create": "Single ticket order",
    "successForcast": "Single ticket order forecast success",
    "successDraft": "Single ticket order saved successfully",
    "batch_upload": "Bulk orders",
    "batch_preview":"Batch preview",
    "managementinfo_received": "My Order",
    "abnormal_all": "All question pieces",
    "abnormal_arrears": "Unpaid",
    "abnormal_notify": "Notices",
    "order_oracle": "Self-ordering question orders",
    "abnormal_oracleNotify": "Self-ordering customer notifications",
    "abnormal_oracleAbnormal": "Self-ordering customer question pieces",
    "collectSuccess": "Collect forecast submission successful",
    "collectDetail": "Details of the collection forecast",
    "collectEdit": "Receiving forecast modification",
    "standard": "Standard reply",
    "my_orderslabelPrint": "Label Printing",
    "bagImport": "Bagging/out-of-warehouse import",
    "bagImportResult": "Bagging/out-of-storage results query",
    "insuranceindex": "Safe insurance",
    "trackindex": "track query",
    "documentindex": "Document management",
    "my_billcustomer_amount_change": "My Bill",
    "couponindex": "My coupon",
    "payonlineindex": "I want to top up",
    "chargeindex": "Freight trial",
    "expense_detail": "Cost Details",
    "electronic_bill": "Electronic Billing",
    "platformAccountindex": "Multi-account management",
    "customer_cargo_kindindex": "Declaration Management",
    "dhl_senderindex": "DHL sender whitelist",
    "tophatter_accountindex": "Binding Tophatter Account",
    "insurance_manageindex": "Insurance Management",
    "tokenindex": "TOKEN",
    "wish_accountindex": "WISH Account Binding",
    "vova_accountindex": "VOVA Account Binding",
    "order_problemabn": "Problem Order",
    "permission_eclink": "Value-added services",
    "permission_bagMenu": "Bag out of the warehouse",
    "permission_setting": "Management Center",
    "trackcod": "Cod data Kanban",
    "customerInformation": "separate customs declaration",
    "eclinkorder_list": "Seller's Assistant",
    "orderManagement": "Platform order management",
    "ManagementSKU": "SKU Management",
    "downloadRecord": "Download records",
    "sellerAccount": "sellerAccount",
    "newPDF": "new manual",
    "collectindex": "Collection forecast management",
    "groupPackage": "Create collection forecast",
    "collectManager": "LanShou booking",
    "difference": "difference",
    "collectAddress": "collectAddress",
    "orderDetail": "orderDetail"
  }
}
</i18n>
<template>
  <div class="dss-index" id="dss-index-container">
    <p class="dss-page-title">
      {{ title }}
      <a
        class="dss-page-btn"
        @click="newPDF"
        v-if="$route.name == 'personal_center'"
      >
        {{ $t('newPDF') }}
      </a>
    </p>
    <div :class="wrapClass">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import '@microDss/asset/iconfont/iconfont.js'
import generateMenu from './menu'
import dssRoutes from '@/microApps/dss/router/router.js'

export default {
  name: 'dss-index',
  computed: {
    title() {
      return this.$t(this.$route.name)
    },
    wrapClass() {
      return this.$route.name !== 'batch_preview' ? 'dss-wrapper':"dss-batch_wrapper"
    }
  },
  watch: {
    $route() {
      this.unPermissionHandler()
    }
  },
  async created() {
    // 更新status
    await this.getDssStatus()
    sessionStorage.removeItem('dss-menu')
    // 校验是否有权限
    this.unPermissionHandler()
  },
  mounted() {
    this.$nextTick(() => {
      // 主子账号
      this.$store.dispatch('getSubAccount')
      // 账户信息
      this.$store.dispatch('getAccountInfo').then(() => {
        // 菜单
        this.getBusinessMenu()
      })
    })
  },
  methods: {
    menuHandle() {
      this.$router.push({ name: 'batch_upload' })
    },
    getDssStatus() {
      return new Promise((resolve, reject) => {
        this.$get('v2/brand/info')
          .then(res => {
            res.forEach(item => {
              if (item.brandCode == 'DSS') {
                this.$store.state.brandStatus.DSS = item.status
              }
            })
            resolve(res)
          })
          .catch(res => {
            reject(res)
          })
      })
    },
    /**
     * @description: 关闭品牌删除创建菜单
     * @param {type}
     * @return {type}
     */
    deleteCreateMenu(menus) {
      let newMenu = []
      if (this.$store.state.brandStatus.DSS == 4) {
        menus.forEach(item => {
          if (item.name != 'to_create' && item.name != 'batch_upload') {
            if (
              item.name == 'collectindex' ||
              item.name == 'eclinkorder_list'
            ) {
              let newSubItem = item.sub.filter(subItem => {
                if (
                  subItem.name != 'collectManager' &&
                  subItem.name != 'groupPackage' &&
                  subItem.name != 'orderManagement'
                ) {
                  return subItem
                }
              })
              item.sub = newSubItem
              newMenu.push(item)
            } else {
              newMenu.push(item)
            }
          }
        })
      } else {
        newMenu = menus
      }
      return newMenu
    },
    handlerBagMenu(list) {
      if (list.some(item => item.url == 'permission_bagMenu')) return
      list.push({
        id: 6314,
        code: null,
        name: '装袋出库',
        type: null,
        parentId: 4932,
        children: [
          {
            id: 6315,
            code: null,
            name: '装袋/出库导入',
            type: null,
            parentId: 6314,
            children: null,
            url: 'bagImport'
          },
          {
            id: 6317,
            code: null,
            name: '装袋/出库结果查询',
            type: null,
            parentId: 6314,
            children: null,
            url: 'bagImportResult'
          },
          {
            id: 6318,
            code: null,
            name: '袋标签打印',
            type: null,
            parentId: 6314,
            children: null,
            url: 'bagPrint'
          }
        ],
        url: 'permission_bagMenu'
      })
    },
    /**
     * @description: 直发系统菜单
     * @param {type}
     * @return {type}
     */
    getBusinessMenu() {
      let menus = generateMenu(this)
      menus = this.deleteCreateMenu(menus)
      this.$get(`v2/resource/menu/all/PlFQDpnfPoFEVSgM`)
        .then(res => {
          // 是否显示 出库装袋
          const info = this.$store.getters.getAccountInfo || {}
          if (info && info.vipFlag != 'Y') {
            menus = menus.filter(item => item.url != 'permission_bagMenu')
          }
          // 菜单权限控制
          sessionStorage.removeItem('dssRole')
          const list = res || []

          // hack 装袋出库 没有配置权限系统
          this.handlerBagMenu(list)

          const names = []
          // 二级页面无权限控制
          dssRoutes.forEach(r => {
            if (r?.meta && r?.meta?.level == 2) {
              names.push(r.name)
            }
          })

          list.forEach(item => {
            if (item.children) {
              names.push(item.url)
              item.children.forEach(x => {
                if (x.children) {
                  names.push(x.url)
                  x.children.forEach(s => {
                    names.push(s.url)
                  })
                } else {
                  names.push(x.url)
                }
              })
            } else {
              names.push(item.url)
            }
          })
          names.forEach(n => {
            if (n.indexOf('/')) names.push(n.replace('/', '_'))
          })
          const result = []
          menus.forEach(item => {
            if (item.sub && item.hasFullSub) {
              if (names.includes(item.name)) {
                result.push(item)
                // hasFullSub 下忽略校验
                names.push(item.name)
                item.sub.forEach(c => {
                  names.push(c.name)
                })
              }
            } else if (item.sub) {
              if (
                item.name === 'order_oracle' &&
                (names.includes('abnormal_oracleAbnormal') ||
                  names.includes('abnormal_oracleNotify'))
              ) {
                names.push(item.name)
                result.push(
                  menus.filter(item => item.name === 'order_oracle')[0]
                )
              } else {
                if (names.includes(item.name)) {
                  const sub = []
                  item.sub.forEach(s => {
                    if (names.includes(s.name)) {
                      sub.push(s)
                    }
                  })
                  item.sub = sub
                  if (sub.length) result.push(item)
                }
              }
            } else {
              if (names.includes(item.name)) {
                result.push(item)
              }
            }
          })
          sessionStorage.setItem('dssRole', JSON.stringify(names))
          this.$store.state.menuItems = result
        })
        .catch(() => {})
    },
    unPermissionHandler() {
      const currentName = this.$route.name
      const roles = sessionStorage.getItem('dssRole')
      const routes = roles ? JSON.parse(roles) : []
      if (routes && routes.length) {
        if (
          !routes.some(item => item == currentName) &&
          !this.$route?.meta.ignore
        ) {
          this.$router.push({ name: 'unauthorized' })
        }
      }
    },
    newPDF() {
      window.open(
        location.protocol +
          '//' +
          location.host +
          '/dist/doc/collect/直发接单融入商家中心-指引说明.pdf'
      )
    }
  }
}
</script>

<style lang="less">
#dss-index-container {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  border-radius: 8px;
  background-color: #eef2f6;
  margin-top: -48px;
  .sc-index-content {
    margin-top: 0;
    padding-bottom: 0 !important;
  }
  .dss-page-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
    font-family: PingFangSC-Medium;
    color: #262E42;
    background-color: #eef2f6;
    position: relative;
  }
  .dss-page-btn {
    float: right;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 18px 8px 38px;
    border: 1px solid #d3d7da;
    color: #333;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-image: url('./file/pre_icon.png');
    background-position: 17px 8px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
  }
  .dss-page-btn:hover {
    color: #065cf3;
    border: 1px solid #065cf3;
  }
  .dss-wrapper {
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    width: 100%;
  }
  .dss-batch_wrapper {
    border-radius: 8px;
    // background: #fff;
    overflow: hidden;
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}
</style>
