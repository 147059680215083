
<template>
  <div class="index-row">
    <div class="section-left">
      <FastEntry />
      <Trial />
      <DssOrderData />
    </div>
    <div class="slide-right">
      <Ad />
      <div class="mb24"></div>
      <Tool />
      <div class="mb24"></div>
      <Customer />
    </div>
  </div>
</template>

<script>
import FastEntry from './FastEntry'
import Trial from './Trial'
import DssOrderData from './DssOrderData'
import Tool from '../tool/Tool'
import Customer from '../customer/Customer'
import Ad from '../ad/Ad'

export default {
  name: 'dssIndex',
  components: { FastEntry, Trial, DssOrderData, Tool, Customer, Ad },
  computed: {
    isOpenFB4() {
      return this.$store.state.userInfo.isFB4Open
    },
    isCloseFB4() {
      return this.$store.state.brandStatus.FB4
    },
  },
  data() {
    return {
      spinning: false,
    }
  },
  created() {},
  methods: {},
}
</script>

<style lang="less" scoped>
.index-row {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.section-left {
  width: 66.67%;
  margin-right: 20px;
}

.slide-right {
  width: 340px;
  height: auto;
}
.mb24 {
  margin-bottom: 24px;
}
</style>
