const serviceCenterIndex = [
  {
    // 服务中心首页
    path: '/serviceCenterIndex',
    name: 'serviceCenterIndex',
    meta: { ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "serviceCenterIndex" */ '@v/serviceCenter/serviceCenterIndex/index'
      )
  },
  // ********************************** 操作指南 before **********************************
  {
    // 查询初始页
    path: '/helpGuideList',
    name: 'helpGuideList',
    meta: { ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "helpGuideList" */ '@v/serviceCenter/serviceCenterIndex/common/helpGuideList'
      )
  },
  {
    // 查询结果展开锚点页
    path: '/anchorList',
    name: 'anchorList',
    meta: { ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "anchorList" */ '@v/serviceCenter/serviceCenterIndex/common/anchorList'
      )
  },
  {
    // 详情
    path: '/helpDetails',
    name: 'helpDetails',
    meta: { ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "anchorList" */ '@v/serviceCenter/serviceCenterIndex/common/helpDetails'
      )
  },
  {
    // 动态菜单初始页
    path: '/helpGuideIndex',
    name: 'helpGuideIndex',
    meta: { ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "helpGuideIndex" */ '@v/serviceCenter/serviceCenterIndex/helpGuide/helpGuideIndex'
      )
  },
  {
    // 新手指南
    path: '/instructions',
    name: 'instructions',
    meta: { ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "instructions" */ '@v/serviceCenter/serviceCenterIndex/instructions/instructions'
      )
  },
  {
    // 新手详情
    path: '/instructionsDetails',
    name: 'instructionsDetails',
    meta: { ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "instructionsDetails" */ '@v/serviceCenter/serviceCenterIndex/instructions/instructionsDetails'
      )
  }
  // ********************************** 操作指南 end **********************************
]

// 服务中心打上免登录tag
const names = []
serviceCenterIndex.forEach(item => {
  item.meta.unLogin = true
  names.push(item.name)
})
names.concat(['index', 'productService', 'productDetails'])
sessionStorage.setItem('docnames', JSON.stringify(names))

export default serviceCenterIndex
