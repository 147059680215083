<!--
 * @Description: 最新公共
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 15:34:55
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-12 10:46:01
-->
<i18n>
{
  "zh": {
    "news": "最新公告"
  },
  "en": {
    "DSS":"DSS",
    "FB4":"FB4",
    "TRS":"TRS",
    "GRS":"GRS",
    "news": "Latest announcement"
  },
 "de": {
    "DSS":"DSS",
    "FB4":"FB4",
    "TRS":"TRS",
    "GRS":"GRS",
    "news": "Letzte Ankündigungen"
  },
  "ja": {
    "DSS":"DSS",
    "FB4":"FB4",
    "TRS":"TRS",
    "GRS":"GRS",
    "news": "最新のお知らせ"
  }
}
</i18n>
<template>
  <div class="announcement">
    <div class="title">{{ $t('news') }}</div>
    <div class="b-more" @click="goMoreAnno">
      {{ $t('more') }}
      <div class="arrow-right" data-baidu-pv="首页，公告模块，查看公告"></div>
    </div>
    <div class="anno-tag">
      <a
        v-for="item in tabs"
        :key="item"
        data-baidu-pv="首页，公告模块，查看公告"
        @click="onChange(item)"
        :class="activeKey === item ? 'active' : ''"
        >{{ $t(item) }}</a
      >
    </div>
    <ul class="content" v-cloak>
      <li
        :key="item.time"
        @click="onItemclick(item)"
        v-for="item in currNews"
        data-baidu-pv="首页，公告模块，查看公告"
      >
        <div class="cnt-desc">{{ item.name }}</div>
        <div class="b-more">
          {{ item.publishTime | timeFormat }}
          <div class="arrow-right"></div>
        </div>
      </li>
    </ul>
    <a-empty
      style="margin-top: 30px"
      v-if="currNews.length == 0"
      v-cloak
    ></a-empty>
  </div>
</template>

<script>
export default {
  name: 'announcement',
  computed: {
    currNews() {
      return this.news[this.activeKey].slice(0, 5)
    }
  },
  filters: {
    timeFormat(val) {
      return val.substring(5, 10)
    }
  },
  data() {
    return {
      activeKey: 'ALL',
      tabs: ['ALL', 'DSS', 'FB4', 'TRS', 'GRS'],
      news: {
        ALL: [],
        FB4: [],
        GRS: [],
        DSS: [],
        TRS: []
      }
    }
  },
  created() {
    this.getListByCategory()
  },
  methods: {
    /**
     *
     */
    doOk() {
      this.$store.state.guideStatus = 3
      document.querySelector('#app').scrollTo(0, 300)
    },
    /**
     * @description: 获取公告列表
     * @param {type}
     * @return {type}
     */
    getListByCategory() {
      const params = {
        pageNo: 0,
        pageSize: 6,
        isPaging: true
      }
      if (this.activeKey != 'ALL') {
        params.category = this.activeKey.toUpperCase()
      }
      this.$post('v2/content/noticeList', params)
        .then(res => {
          this.news[this.activeKey] = res ? res.data : []
        })
        .catch(() => {})
    },
    /**
     * @description: change tag
     * @param {type}
     * @return {type}
     */
    onChange(type) {
      this.activeKey = type
      this.getListByCategory()
    },
    /**
     * @description: 查看更多公共
     * @param {type}
     * @return {type}
     */
    goMoreAnno() {
      this.$router.push({
        name: 'announcementALL',
        query: { type: this.activeKey.toUpperCase() }
      })
    },
    /**
     * @description: 查看具体工具详情
     * @param {type}
     * @return {type}
     */
    onItemclick(item) {
      this.$router.push({
        name: 'announcementDetail',
        query: { type: this.activeKey, code: item.code }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../styles/common.less');
.announcement {
  width: 340px;
  height: 260px;
  margin-left: 20px;
  padding: 20px 14px 0 20px;
  background: #ffffff;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 18px;
    display: inline-block;
  }
  .anno-tag {
    margin-top: 16px;
    user-select: none;
    a {
      display: inline-block;
      vertical-align: top;
      background: #f6f6f6;
      color: #666666;
      border-radius: 2px;
      padding: 2px 8px;
      font-size: 12px;
      margin-right: 10px;
    }
    a.active {
      color: #065cf3;
      background-color: #f0f5ff;
    }
  }
  .content {
    padding-left: 0;
  }
  .content li {
    height: 16px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 16px;
    margin-top: 17px;
    transition: color 0.3s;
    list-style-type: none;
    cursor: pointer;
    .cnt-desc {
      display: inline-block;
      .ellipsis (calc(100% - 65px));
    }
    &:hover {
      color: #065cf3;
      .b-more {
        color: #065cf3;
      }
    }
  }
}

.guide-mask {
  z-index: 1000;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
  }
}
.intro {
  min-width: 260px;
  position: absolute;
  left: 50%;
  bottom: -30px;
  transform: translate(-50%, 100%);
  background: #0e5ade;
  border-radius: 4px;
  padding: 20px 14px 18px 14px;
  box-sizing: border-box;
  &.serviceIntro {
    bottom: -40px;
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 135px;
    top: -16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid #0e5ade;
  }
}
</style>
