<template>
  <div>
    <div class="mask" v-if="isShow && this.$store.state.username">
      <div class="dialog">
        <!-- <div class="close" @click="closeDialog">
          <i class="iconfont iconlujing"></i>
        </div> -->
        <img
          class="dialog_img"
          src="../../../assets/popup/upload_home.png"
          alt=""
          width="620"
          height="566"
          @click="closeDialog"
        />
        <div class="btn-box" @click="goHomePage"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newHomePageDialog',
  data() {
    return {
      isShow: false
    }
  },

  created() {
    this.isShowDialog()
  },
  methods: {
    isShowDialog() {
      //判断是否显示
      const inShow = localStorage.getItem('newHomePage20240627')
      if (inShow) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    },
    goHomePage() {
      this.closeDialog()
      // this.$router.push({
      //   path: '/'
      // })
    },
    closeDialog() {
      this.isShow = false
      localStorage.setItem('newHomePage20240627', '1')
    }
  }
}
</script>

<style lang="less" scoped>
.wc-icon {
  display: inline-block;
  position: relative;
  margin-right: 16px;
  width: 22px;
  height: 22px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background: #fff;

  cursor: pointer;
  line-height: 20px;
}
.wc-icon .iconxiaochengxu {
  width: 22px;
  height: 22px;
  color: #3561cc;
}
.dialog {
  position: fixed;
  margin-top: -280px;
  top: 50%;
  left: 50%;
  margin-left: -310px;
  // width: 742px;
  // height: 665px;
  width: 620px;
  height: 566px;
  z-index: 10000;
}
.dialog .close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  z-index: 1000;
  cursor: pointer;
}
.dialog .btn-box {
  position: absolute;
  left: auto;
  bottom: 40px;
  right: auto;
  width: 100%;
  height: 79px;
  z-index: 1000;
  cursor: pointer;
}
.dialog_img {
  width: 620px;
  height: 566px;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh);
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.miniprog-btn {
  margin-right: 22px;
  padding: 0;
  text-align: center;
  width: 22px;
  border-radius: 12px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  background: #3561cc;
  cursor: pointer;
  border: 1px solid rgba(53, 97, 204, 1);
}
</style>
