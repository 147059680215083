/*
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2021-03-17 18:02:47
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-03-17 19:57:29
 */
import {
  http_inner_listProblem,
  http_outer_listProblem
} from '@microDss/service/http/http'

export default {
  state: {
    // 仓内问题类型
    innerAbnormal: null,
    // 仓外问题类型
    outerAbnormal: null
  },
  getters: {},
  mutations: {},
  actions: {
    /**
     * 仓内问题类型
     * @param {*} param0
     */
    getInnerAbnormalTypes({ state }) {
      if (!state.innerAbnormal) {
        http_inner_listProblem()
          .then(res => {
            if (res && res.status == 1) {
              const dt = res.data
              const groups = []
              let lastTypeId
              for (let i = 0; i < dt.length; i++) {
                const currTypeId = dt[i].typeId
                if (!currTypeId) {
                  groups.push([dt[i]])
                  continue
                }
                if (lastTypeId != currTypeId) {
                  let hasAdd = 0
                  groups.forEach(g => {
                    if (g.some(item => item.typeId == currTypeId)) hasAdd++
                  })
                  if (hasAdd == 0) {
                    groups.push(
                      dt.filter(
                        item => currTypeId != 0 && item.typeId == currTypeId
                      )
                    )
                    hasAdd = 0
                  }
                }
                lastTypeId = currTypeId
              }

              state.innerAbnormal = groups
            }
          })
          .catch(() => {})
      }
    },
    /**
     * 仓外问题类型
     * @param {*} param0
     */
    getOuterAbnormalTypes({ state }) {
      if (!state.outerAbnormal) {
        http_outer_listProblem()
          .then(res => {
            if (res && res.status == 1) {
              const dt = res.data || []
              state.outerAbnormal = dt.filter(item => item.notifyType == 1) // notifyType == 1 仓外问题件异常类型
            }
          })
          .catch(() => {})
      }
    }
  }
}
