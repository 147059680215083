<template>
  <a-drawer
    width="1000px"
    placement="right"
    wrapClassName="order-detial"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="detail-box">
      <div class="title" slot="title">
        <span>{{ $t('product_expression_025') }}</span>
        <i class="iconfont  iconlujing" @click="onClose"></i>
      </div>
      <div class="fade-in">
        <div class="detail-card">
          <div class="detal-tit-box">
            <span
              >{{ productInfo.salePkName || '-' }} ({{
                productInfo.salePkCode || '-'
              }})</span
            >
            <a-button
              @click="handleToOrder(productInfo)"
              type="primary"
              class="order-btn"
              >{{ $t('product_expression_026') }}</a-button
            >
          </div>

          <div class="tag-box">
            <!-- 全跟踪 -->
            <div class="tag" v-if="stdProductInfoList.length>0 && stdProductInfoList[0].trackType">
              {{
                stdProductInfoList[0].trackType | getNameFromValue(trackTypeArr)
              }}
            </div>
            <div class="tag" v-else>-</div>
            <div class="tag-g tag">
              {{
                (productInfo && productInfo.stdProductInfoList?.length > 0 &&
                productInfo.stdProductInfoList[0].outGoodsType == 1)
                  ? $t('product_expression_017')
                  :$t('product_expression_018')
              }}
            </div>
          </div>
          <div class="tag-bt">
            <i class="iconfont iconfuwubiaozhun"></i>
            {{ productInfo.serviceLevel | getNameFromValue(serviceLevelArr) }} |
            {{
              productInfo.openCheck == '0'
                ? $t('product_expression_027')
                : productInfo.openCheck == '1'
                ? $t('product_expression_028')
                : '-'
            }}
          </div>
          <div class="des-box">
            {{ productInfo.introduce }}
          </div>
        </div>

        <div class="cust-tbas">
          <div
            :class="{ 'tabs-item': true, active: defaultActive == item.value }"
            v-for="item in tabsData"
            @click="changeTabs(item.value)"
            :key="item.value"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- 价格时效 -->
        <div class="tabs-detail-box" v-if="defaultActive == 1">
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('wt.t47') }}</span>
          </div>
          <div class="detail-des">
            <a-table
              :columns="freightColumns"
              :data-source="freightData"
              :pagination="false"
              bordered
            >
            </a-table>
          </div>
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_029') }}</span>
          </div>
          <div class="detail-des">
            {{ productInfo.chargeType || '-' }}
          </div>
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_030') }}</span>
          </div>
          <div class="detail-des">
            <a-table
              :columns="quotColumns"
              :data-source="quotationTableData"
              :pagination="false"
              bordered
            >
              <span slot="countryName" slot-scope="text, record"> {{ text }} {{ record.districtAreaName?' / '+record.districtAreaName:'' }} </span>
              <span slot="weightBegin" slot-scope="text, record"> {{ record.weightBegin }} - {{ record.weightEnd }} </span>
            </a-table>
          </div>
          <!-- <div class="detail-item-tit">
            <span class="tit-text">计费标准</span>
          </div>
          <div class="detail-des">
            1、产品说待定..............
          </div> -->
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_031') }}</span>
          </div>
          <div class="detail-des">
            {{ productInfo.surchargeStd || '-' }}
          </div>
        </div>
        <!-- 发货要求 -->
        <div class="tabs-detail-box" v-if="defaultActive == 2">
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_032') }}</span>
          </div>
          <div class="detail-des">
            <div class="lst" v-for="sdpItem in productInfo.stdProductInfoList" :key="sdpItem">
              【{{$t('product_expression_021')}}】{{ sdpItem.minWeight }}-{{ sdpItem.maxWeight }}kg</br>
              【{{$t('product_expression_022')}}】{{ productInfo.volumeLimit || '-' }}
            </div>
          </div>

          <div
            v-if="productInfo && productInfo.batteryPackagingRequirements"
            class="detail-item-tit"
          >
            <span class="tit-text">{{ $t('product_expression_033') }}</span>
          </div>
          <div
            v-if="productInfo && productInfo.batteryPackagingRequirements"
            class="detail-des"
          >
            {{ productInfo.batteryPackagingRequirements || '-' }}
          </div>
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_034') }}</span>
          </div>
          <div class="detail-des">
            <span

              v-for="(pgItem,pgIndex) in productInfo.prohibitedGoods"
              :key="pgItem"
              >{{pgIndex>0?'、':''}}{{ pgItem }}
            </span>
            <span v-if="!productInfo.prohibitedGoods || productInfo.prohibitedGoods.length == 0">-</span>
          </div>
          <!-- <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_035') }}</span>
          </div>
          <div class="detail-des">
            <span
              v-for="(rgItem, rgIndex) in productInfo.restrictedGoods"
              :key="rgItem"
              >{{rgIndex>0?'、':''}}{{ rgItem }}
            </span>
            <span v-if="!productInfo?.restrictedGoods || productInfo?.restrictedGoods.length == 0">-</span>
          </div> -->
          <!-- <div class="detail-item-tit">
            <span class="tit-text">限运条件</span>
          </div>
          <div class="detail-des">
            {{ productInfo.restriction || '-' }}
          </div> -->
        </div>

        <!-- 服务标准 -->
        <div class="tabs-detail-box" v-if="defaultActive == 3">
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_053') }}</span>
          </div>
          <div class="detail-des">
            {{ productInfo.declaredAndTax || '-' }}
          </div>
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_036') }}</span>
          </div>
          <div class="detail-des">
            {{ productInfo.returnInstructions || '-' }}
          </div>
          <div class="detail-item-tit">
            <span class="tit-text">{{ $t('product_expression_037') }}</span>
          </div>
          <div class="detail-des">
            <a href="http://express.4px.com/article/detail/id/503420/cid/28" target="_blank" >http://express.4px.com/article/detail/id/503420/cid/28</a>
          </div>
        </div>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import {
  trackTypeArr,
  serviceLevelArr,
  freightColumns,
  quotationColumns
} from './data'
import { burialPoint } from '../burialPoint.services'

export default {
  name: 'Detail',
  props: {
    visible: {
      type: Boolean
    },
    baseData: {
      type: Object,
      default: () => {}
    },
    queryParam: {
      type: Object,
      default: () => {}
    },
     cityName: {
      type: String
    },
    countryName: {
      type: String
    },
    productRank: {
      type: String|Number
    }
  },
  data() {
    return {
      trackTypeArr: trackTypeArr(this),
      serviceLevelArr: serviceLevelArr(this),

      tabsData: [
        {
          name: this.$t('product_expression_038'),
          value: '1'
        },
        {
          name: this.$t('product_expression_039'),
          value: '2'
        },
        {
          name: this.$t('product_expression_040'),
          value: '3'
        }
      ],
      freightColumns: [...freightColumns(this)],
      freightData: [],
      quotColumns: quotationColumns(this),
      quotationTableData: [],
      defaultActive: 1,
      productInfo: {
        stdProductInfoList: []
      },
      stdProductInfoList: []
    }
  },
  created() {
    this.getTrialProductDetail()
    this.setTableColumns()
  },
  methods: {
    setTableColumns() {
      const feeList = this.baseData.feeList || []
      const columns = this.freightColumns
      const freightColumns = []
      const freightData = {}
      feeList.forEach(item => {
        freightColumns.push({
          title: item.feeName,
          dataIndex: item.feeCode
        })
        freightData[item.feeCode] = `${item.amount} ${item.currencyCode}`
      })

      columns.splice(1, 0, ...freightColumns)
      this.freightColumns = columns
      this.freightData = [
        {
          ...this.baseData,
          ...freightData
        }
      ]
    },
    handleToOrder(record) {
       // 埋点 下单
      burialPoint({
        event: '1',
        productArea: '4', //页面区域  1:推荐位 2:列表位 3:对比产品 4:产品详情
        // productRank: record?.productRank || this.productRank || '', // 排序
        productName: record?.salePkName || '', // 产品名称
        productCode: record?.salePkCode || '', //产品代码
        country: this.queryParam?.country || '', //国家
        city: this.cityName || '' //城市
      })

      this.$router.push({
        name: 'to_create',
        params: {
          quickOrder: {
            order: {
              holdProduct: record.salePkName,
              productCode: record.salePkCode,
              destinationCountry: this.queryParam.country,
              holdCountry: this.countryName,
              countryCode: this.queryParam.country
            },
            orderAttach: {
              customerWeight: this.queryParam.weight
                ? this.queryParam.weight.toString()
                : ''
            }
          }
        }
      })
    },
    onClose() {
      this.$emit('onClose')
    },
    getTrialProductDetail() {
      this.$show()
      this.$post('v2/freightFeeTrial/trialProductDetail', {
        ...this.queryParam,
        productCode: this.baseData.productCode,
        stdPkCode: this.baseData.stdPkCode
      })
        .then(res => {
          this.$hide()
          this.quotationTableData = res?.priceInfo || []
          this.productInfo = res?.productInfo
          this.stdProductInfoList = res?.productInfo.stdProductInfoList || []
        })
        .catch(() => {
          this.$hide()
        })
    },
    /**
     * 切换
     */
    changeTabs(value) {
      this.defaultActive = value || 1
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-box {
  padding: 0 20px;
  min-height: 100vh;
  background: #f2f4f8 !important;
  .title {
    height: 60px;
    line-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #151f28;
    line-height: 20px;
    font-weight: 600;
    .iconfont {
      padding: 20px;
      padding-right: 0;
      font-size: 15px;
      color: rgba(84, 93, 117, 0.5);
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.detail-card {
  margin: 16px 0;
  padding: 16px 20px;
  background: #ffffff;
  border-radius: 4px;
  &:nth-of-type(1) {
    margin-top: 0;
  }
}
.detal-tit-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #323643;
  line-height: 20px;
  font-weight: 600;
}
.tag-box {
  margin: 4px 0 10px;
  width: 100%;
  display: flex;
  height: 24px;
  .tag {
    margin-right: 6px;
    padding: 0 12px;
    display: inline-block;
    height: 24px;
    background: rgba(57, 96, 223, 10%);
    border-radius: 4px;
    font-size: 12px;
    color: #3960df;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
  }
  .tag-g {
    background: rgba(255, 100, 0, 10%);
    color: #ff6400;
  }
}

.tag-bt {
  margin-bottom: 8px;
  font-size: 14px;
  color: #323643;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  font-size: 12px;
  .iconfont {
    margin-right: 2px;
    font-size: 14px;
    color: #8492bd;
  }
}

.des-box {
  font-size: 14px;
  color: #323643;
  line-height: 24px;
  font-weight: 400;
}
// tabs 样式
.tabs-detail-box {
  padding: 20px;
  background: #fff;
  .detail-item-tit {
    margin-top: 16px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #151f28;
    line-height: 16px;
    font-weight: 500;
    .tit-text::before {
      margin-right: 6px;
      display: inline-block;
      vertical-align: bottom;
      content: '';
      width: 4px;
      height: 16px;
      background: #3960df;
      border-radius: 2px;
    }
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
  .details-des {
    font-size: 14px;
    color: #323643;
    line-height: 24px;
    font-weight: 400;
  }
}

.cust-tbas {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f6f7fc;
  height: 44px;
  .tabs-item {
    min-width: 100px;
    padding: 0 20px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #545d75;
    text-align: center;
    line-height: 20px;
    font-weight: 500;
    cursor: pointer;
    border-top: 2px solid transparent;
  }
  .tabs-item.active {
    background: #fff;
    color: #3960df;
    font-weight: 500;
    border-top: 2px solid #3960df;
  }
}
</style>
