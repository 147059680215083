<template> </template>

<script>
export default {
  name: 'top-notify',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.top-notify {
  width: 100%;
  height: 24px;
  background: #ff9300;
}
</style>
