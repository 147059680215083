<!--
 * @Description: 4px 诚信承诺函
 * @Autor: S9637/chifuk
 * @Date: 2020-12-09 17:18:27
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-12-10 19:02:50
-->
<template functional>
  <div>
    <h2 style="text-align:center;">4PX 试单用户承诺函</h2>

    版本发布日期：2020 年 12 月 16 日
    <br />
    版本生效日期：2020 年 12 月 16 日
    <br />

    <strong>
      致深圳市递四方速递有限公司及其关联公司（以下简称“递四方”或“4PX”或
      “贵司”）：
    </strong>
    <br />
    本人（即：4PX
    试单用户）作为出口跨境电商商家，欲委托递四方提供国际物流运输服务，
    在签署正式的物流服务协议之前，本人愿意遵循贵司的要求与标准，以试单用户身份，通过
    在递四方网站（ https://order.4px.com
    ）下单的方式使用递四方的全球直发服务产品。
    <br />
    <strong> 本人已审慎阅读本承诺函，且完全理解并遵守如下内容： </strong>
    <br />
    <strong> 一、用户资格</strong>
    <br />
    1、本人确认，本人在取得 4PX
    试单用户账号前，已具备中华人民共和国法律规定的及在使
    用服务时所在国家或地区的相关法律规定的与本人行为相适应的民事行为能力，否则本人及
    监护人应依据法律规定承担因此导致的一切后果。
    2、若本人属于非自然人法律主体，本人确认，在取得前取得 4PX
    试单用户账号前，为在中
    国大陆地区合法设立并已具备相应资质开展经营活动或其他业务的法人或其他组织，且使用
    递四方物流服务不受本人所属、所居住或开展经营活动或其他业务的国家或地区法律法规的
    禁止，否则本人及负责人应依据法律规定承担因此导致的一切后果。
    3、本人确保不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限
    制的对象，否则可能无法使用服务。
    <br />

    <strong> 二、账号管理</strong>
    <br />
    1、未经递四方同意，<strong
      >本人不得直接或间接授权第三方使用 4PX 试单用户账号（以下简称：
      账号）或获取账号项下信息。</strong
    >否则，本人将为使用递四方账号的行为承担全部责任。如递四
    方判断本人账号的使用可能危及本人的账号安全和/或递四方用户端信息安全的，递四方可
    以拒绝提供相应服务。 2、本人需保管好 4PX
    试单用户账号密码和登陆信息，并确保在每个上网时段结束时退出登
    录并以正确步骤离开 4PX
    用户端。账号因本人主动泄露或遭受他人攻击、诈骗等行为导致的
    损失及后果，均由本人自行承担。 3、【账号回收】<strong
      >如果本人账号符合以下条件且不存在未到期的有效业务的，则递四方有权
      回收本人的账号，本人的账号将不能再登录递四方网站，相应服务同时终止：</strong
    >
    （一）连续六个月未用于登录递四方网站，但如通过系统对接方式接入或下单的视为登录递
    四方网站
    <br />（二）订单数量达到上限且所有订单操作完毕之日起一年后。
    <br />
    <strong>三、服务内容</strong>
    <br />
    1、递四方为本人提供以下范围中的全部或部分服务：
    （1）中国大陆境内揽收服务；（2）商品查验服务；（3）头程运输服务：商品自中国大陆境
    内到目的国的运输服务；（4）关务服务：中国大陆出口报关服务、目的国进口清关服务等；
    （5）尾程派送服务。2、本人知悉并同意，本人将通过主动在递四方网站下单的方式完成订
    单的创建。具体服务内容、价格信息和操作流程以递四方网站公布的或 4PX
    系统设置信息为 准。<strong
      >本人知悉并同意，如递四方查验中发现商品的品名、数量、重量、体积等信息与本人下
      单预报信息不一致的，以递四方查验清点的数据为准。
      3、本人确认下单信息后，系统将根据公布价格计算订单费用金额，本人应及时通过递四方
      网站支持的支付方式付款，本人完成付款后方表示完成下单。本人知悉并同意，若经递四方
      查验商品发现订单费用金额差异或清关、尾程过程中产生相关附加费用的，递四方通过系
      统向本人加收的，本人应在 7
      个工作日内足额支付，以避免因欠费导致商品未能寄递成功
      的情况。如超期未付费，则递四方有权中止或终止服务，本人同意承担因此而产生的所有
      费用（如包裹退回或继续配送）。</strong
    >4、本人知悉并同意，每一 4PX 试单用户的下单数量上限 为 200
    单，如下单数量达到上限，则不可以继续下单。如欲继续使用递四方服务的，本人应
    在递四方网站注册成为递四方正式用户，并通过实名认证、完成服务协议签订后方可长久使
    用递四方服务。
    <br />
    <strong>四、本人对寄递物品及行为作如下保证与承诺：</strong>
    1、本人承诺遵守国家法律法规中关于禁止或限制寄递物品的规定,不通过 4PX
    寄递渠道危害 国家安全、公共安全和公民、法人、其他组织的合法权益。
    <br />
    <strong>2、本人承诺在交 4PX 寄递的物品中,不包含以下物品</strong>
    (一)枪支(含仿制品、主要零部件)弹药<br />
    1.枪支(含仿制品、主要零部件):如手枪、步枪、冲锋枪、防暴枪、气枪、猎枪、运动枪、
    麻醉注射枪、钢珠枪、催泪枪等。
    2.弹药(含仿制品):如子弹、炸弹、手榴弹、火箭弹、照明弹、燃烧弹、烟幕(雾)弹、信号
    弹、催泪弹、毒气弹、地雷、手雷、炮弹、火药等。 （二)管制器具<br />
    1.管制刀具:如匕首、二棱刮刀、带有自锁装置的弹簧刀(跳刀)、其他相类似的单刃、双刃、
    二棱尖刀等。 2.其他:如弩、催泪器、催泪枪、电击器等。 (三)爆炸物品<br />
    1.爆破器材:如炸药、雷管、导火索、导爆索、爆破剂等。
    2.烟花爆竹:如烟花、鞭炮、摔炮、拉炮、砸炮、彩药弹等烟花爆竹及黑火药、烟火药、发
    令纸、引火线等。 3.其他:如推进剂、发射药、硝化棉、电点火头等。
    (四)压缩和液化气体及其容器<br />
    1.易燃气体:如氢气、甲烷、乙烷、丁烷、天然气、液化石油气、乙烯、丙烯、乙炔、打火
    机等。 2.有毒气体:如一氧化碳、一氧化氮、氯气等。
    易爆或者窒息、助燃气体:如压缩氧气、氮气、氦气、氖气、气雾剂等。 (五)易燃液体
    如汽油、柴油、煤油、桐油、丙酮、乙醚、油漆、生漆、苯、酒精、松香油等。<br />
    (六)易燃固体、自燃物质、遇水易燃物质<br />
    1.易燃固体:如红磷、硫磺、铝粉、闪光粉、固体酒精、火柴、活性炭等。
    2.自燃物质:如黄磷、白磷、硝化纤维(含胶片)、钛粉等。
    3.遇水易燃物质:如金属钠、钾、锂、锌粉、镁粉、碳化钙(电石)、氰化钠、氰化钾等。
    (七)氧化剂和过氧化物<br />
    如高锰酸盐、高氯酸盐、氧化氢、过氧化钠、过氧化钾、过氧化铅、氯酸盐、溴酸盐、硝酸
    盐、双氧水等。 (八)毒性物质<br />
    如砷、砒霜、汞化物、铊化物、氰化物、硒粉、苯酚、汞、剧毒农药等。
    (九)生化制品、传染性、感染性物质<br />
    如病菌、炭疸、寄生虫、排泄物、医疗废弃物、尸骨、动物器官、肢体、未经硝制的兽皮、
    未经药制的兽骨等。 (十)放射性物质 如铀、钴、镭、钚等<br />
    (十一)腐蚀性物质<br />
    如硫酸、硝酸、盐酸、蓄电池、氢氧化钠、氢氧化钾等。<br />
    (十二)毒品及吸毒工具、非正当用途麻醉药品和精神药品、非正当用途的易制毒化学品
    1.毒品、麻醉药品和精神药品:如鸦片(包括罂粟壳、花、苞、叶)、吗啡、海洛因、可卡因、
    大麻、甲基苯丙胺(冰毒)、氯胺酮、甲卡西酮、苯丙胺、安钠咖等。
    2.易制毒化学品:如胡椒醛、黄樟素、黄樟油、麻黄素、伪麻黄素、羟亚胺、邻酮、苯乙酸、
    溴代苯丙酮、醋酸酐、甲苯、丙酮等。 3.吸毒工具:如冰壶等。
    (十三)非法出版物、印刷品、音像制品等宜传品<br />
    如含有反动、煽动民族仇恨、破坏国家统一、破坏社会稳定、宣扬邪教、宗教极端思想、淫
    秽等内容的图书、刊物、图片、照片、音像制品等。 (十四)间谍专用器材<br />
    如暗藏式窃听器材、窃照器材、突发式收发报机、一次性密码本、密写工具、用于获取情报
    的电子监听和截收器材等。 (十五)非法伪造物品<br />
    如伪造或者变造的货币、证件、公章等。 (十六)侵犯知识产权和假冒伪劣物品<br />
    1.侵犯知识产权:如侵犯专利权、商标权、著作权的图书、音像制品等。
    2.假冒伪劣:如假冒伪劣的食品、药品、儿童用品、电子产品、化妆品、纺织品等。
    (十七)濒危野生动物及其制品 如象牙、虎骨、犀牛角及其制品等。<br />
    (十八)禁止进出境物品<br />
    如有碍人畜健康的、来自疫区的以及其他能传播疾病的食品、药品或者其他物品;内容涉及
    国家秘密的文件、资料及其他物品。 （十九）、其他物品<br />
    《危险化学品目录》《民用爆炸物品品名表》《易制爆危险化学品名录》《易制毒化学品的分
    类和品种目录》《中华人民共和国禁止进出境物品表》载明的物品和《人问传染的病原微生
    物名录》载明的第一、二类病原微生物等,以及法律、行政法规、国务院和国务院有关部门
    规定禁止寄递的其他物品。 3、4PX
    有权要求本人当面检视交寄物品,检查是否属于国家禁止或限制寄递的物品,以及确
    认物品的名称,类别,数量等是否与运单上所填写的内容一致。根据国家规定需寄件人(托运
    人)提供相关单证的,本人有义务提供单证原件。
    如本人拒绝递四方验视,或不如实填写运单,不提供有关单证的,4PX 可以拒绝收寄。<br />
    4、4PX
    在已经收寄的邮件中发现有上述条款所列禁寄或限寄物品的,可以停止转发和投递,
    对其中依法需要没收或者销毁的物品,有权立即向有关部门报告,并配合有关部门处理。<br />
    5、因本人在寄递快件中夹带国家禁止运输、缺少相关手续的限制运输物品或危险品,被国家
    有关部门查处的,或者由此造成其他公民,法人及其他组织损害的,由本人依法承担赔偿责任;
    致使4PX 因此受到损害及遭受第二人索赔的,本人将赔偿4PX
    损失,并积极采取有效措施以避 免 4PX 受到牵连。<br />
    6、本人将如实申报快件的内容和价值,并准确填写寄件人(托运人)、收件人的名称、地址和
    联系电话等资料。<br />
    7、本人向递四方披露个人信息的，本人保证：（1）是通过合法的途径并得到信息主体的授
    权取得了信息主体的个人信息；（2）向递四方提供个人信息是基于合法的目的；（3）针对个
    人信息的处理及已向信息主体作出披露或说明；（4）处理及披露个人信息不会违反任何适用
    的法律法规的规定。“个人信息”指与物流运输相关，任一方处理的能够令个人的身份是明
    显可辨认并且能够合理确定的个人信息或数据，无论正确与否，且无论以有形形式记载与否。
    递四方有权向已受托履行服务内容任何义务的关联方转移交易数据（包括个人信息）。<br />
    8、本人承诺并保证遵守所有适用法律、法规、国际公约及相关行业标准、平台规则的要求。
    本人承诺商品及其包装符合启运地、目的地、中转地的法律法规的全部要求。<br />
    9、本人负责提供符合相关法律法规规定和相关政府机关（包括但不限于海关和税务部门）
    要求的全部信息和文件，并对文件内容的合法性、真实性、完整性负责。<br />
    10、本人应遵守启运国和目的地国家海关、税务等相关法律法规及销售平台规定。本人向海
    关申报的商品价格必须符合海关规定。本人应按照目的地法律要求注册并提供当地进口主体
    及税号，还应进行合法税务申报，如当地法律法规有此要求。如因本人存在低报、虚报或逃
    税、漏税等税务问题或进口主体及税号存在问题，导致递四方被相关政府部门调查、起诉或
    采取其他法律行动，递四方有义务全力配合政府部门并提供本人相关信息等，本人将承担全
    部法律责任，并向赔偿由此给递四方造成的一切损失，包括但不限于律师费等。<br /><br />
    <strong
      >本人理解并同意：如本人违反上述的任何内容，视为实质性违约，递四方有权随时终止提
      供服务，并有权就所受之全部损失向本人主张权利。</strong
    >
  </div>
</template>

<script>
export default {
  name: 'protocol'
}
</script>
