<template>
  <div>
    <a-modal
      :visible="modalVisible"
      centered
      :title="$t('servuce_tran_023')"
      @cancel="modalCancel"
      width="588px"
    >
      <div class="step-modal-body">
        <div class="notice flex-center">
          <span>{{ tip(flowDetail) }}</span>
        </div>
        <a-steps :current="current" labelPlacement="vertical" size="small">
          <a-step :title="$t('servuce_tran_024')" />
          <a-step :title="$t('op_brand_019')" />
          <a-step :title="$t('servuce_tran_026')" />
          <a-step :title="$t('op_brand_020')" />
        </a-steps>
        <div class="btn-box">
          <a-button type="primary" @click="doOk">
            {{ current == 2 ? $t('op_brand_030') : $t('ok') }}
          </a-button>
        </div>
      </div>
      <template slot="footer">
        <div class="tip-box">
          <div class="left-word">{{ $t('servuce_tran_028') }}:</div>
          <div class="tip">
            <p>
              {{ $t('servuce_tran_029') }}
            </p>
            <p>{{ $t('servuce_tran_030') }}</p>
            <p>{{ $t('servuce_tran_031') }}</p>
            <p>{{ $t('servuce_tran_032') }}</p>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
// 非大陆用户
const notMainCur = {
  sub_auth_info: 0,
  approve: 1,
  apply_for_brand: 2,
  uploading_protocol: 3,
  brand_audit: 4,
  // 新增国内功能节点
  certification: 0,
  sub_contract_application: 3,
  contract_sign_finish: 3
}

// 大陆用户
const mainCur = {
  certification: 0,
  apply_for_brand: 2,
  sub_contract_application: 3,
  contract_sign_finish: 3
}

export default {
  name: 'status-view',
  props: {
    flowDetail: Object,
    visible: Boolean
  },
  data() {
    return {
      current: -1,
      tipIndex: 0,
      modalVisible: false
    }
  },
  created() {
    const {
      currentStatusCode,
      newNodeStatusCode,
      customerType
    } = this.flowDetail
    const step =
      customerType === '0'
        ? mainCur[currentStatusCode]
        : notMainCur[currentStatusCode]
    this.current = newNodeStatusCode === 2 ? step : step - 1
    this.modalVisible = this.visible
  },
  methods: {
    tip({ sellPhone, serviceTel, sellName }) {
      const tel = sellPhone
        ? `，` +
          this.$t('servuce_tran_033', {
            sellName: sellName,
            sellPhone: sellPhone
          })
        : serviceTel
        ? `，` +
          this.$t('servuce_tran_034', {
            sellName: sellName,
            serviceTel: serviceTel
          })
        : ''
      const map = {
        0: this.$t('servuce_tran_035'),
        1: this.$t('servuce_tran_036') + `${tel}`,
        2: this.$t('servuce_tran_037') + `${tel}`,
        3: this.$t('servuce_tran_038') + `${tel}`,
        4: this.$t('servuce_tran_039')
      }
      return map[this.current]
    },
    doOk() {
      this.modalVisible = false
      this.$emit('close')
      if (this.current == 2) {
        this.$router.push({
          name: 'contractInfo'
        })
      }
    },
    modalCancel() {
      this.$emit('close')
    }
  },
  watch: {
    visible(data) {
      this.modalVisible = data
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../../styles/theme.less');

.tip-box {
  display: flex;
  justify-content: center;
  padding: 16px 0;
  background: #f3f4f8;
  border: 1px solid rgba(221, 221, 221, 1);
  font-size: 12px;
  .left-word {
    padding-top: 1px;
  }
  .tip {
    text-align: left;
    margin-left: 6px;
    p {
      line-height: 1.8;
      margin-bottom: 4px;
    }
  }
}
/deep/ .ant-modal-footer {
  padding: 0;
  text-align: right;
  button {
    width: 100px;
    height: 40px;
  }
}

.step-modal-body {
  margin: 0 auto;
  width: 440px;
  .notice {
    background: #f3f4f8;
    border: 1px solid rgba(238, 238, 238, 1);
    margin-bottom: 30px;
    padding: 6px;
    border-radius: 4px;
  }
  /deep/ .ant-steps {
    transform: translate(-18px, 0);
  }
  .btn-box {
    text-align: center;
    margin-top: 36px;
    margin-bottom: 4px;
    button {
      width: 100px;
      height: 40px;
    }
  }
}
</style>
