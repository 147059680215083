<template>
  <div class="chatPage">
    <img
      src="../../assets/icon/robot_s.png"
      :style="{
        display:
          !defCustonVisble && !custonHover && !popoverVisble && robotVisible
            ? 'block'
            : 'none'
      }"
      class="retract-index-udesk"
      @mouseenter="mouseEnterRetract"
      @mouseleave="mouseLeaveRetract"
    />
    <div
      @mouseleave="toggles"
      @mouseenter="mouseenterCustonBox"
      class="custom-box"
    >
      <!-- 客服 在线咨询 icon -->
      <a-popover
        v-show="!$store.getters.isOverSeas && this.$store.state.username"
        placement="topRight"
        @visibleChange="customerIMChange"
      >
        <template slot="content">
          <IM />
        </template>
        <div
          v-if="
            !$store.getters.isOverSeas &&
              this.$store.state.username &&
              ($store.state.userInfo.isDSSOpen ||
                $store.state.userInfo.isFB4Open ||
                $store.state.userInfo.isGRSOpen ||
                $store.state.userInfo.isTRSOpen ||
                $store.state.accountRegStatus.certification)
          "
          :style="{ display: custonHover || popoverVisble ? 'block' : 'none' }"
          class="new-index-udesk"
        ></div>
      </a-popover>
      <!-- <div v-if="!this.$store.state.username" class="new-index-udesk"></div> -->
      <div
        class="custom-btn-box"
        :style="{
          display: custonHover || popoverVisble ? 'block' : 'none',
          height: custonHover || popoverVisble ? 'auto' : 0
        }"
      >
        <div
          v-if="
            this.$store.state.username &&
              !$store.state.userInfo.isDSSOpen &&
              !$store.state.userInfo.isFB4Open &&
              !$store.state.userInfo.isGRSOpen &&
              !$store.state.userInfo.isTRSOpen
          "
          @click="goRegstratopm"
          class="hover btn-item"
        >
          <i class="iconfont iconwoyaoruzhu"></i>
          <div class="item-text">我要入驻</div>
        </div>

        <div @click="goInstructions" class="btn-item hover">
          <i class="iconfont iconxinshouzhinan"></i>
          <div class="item-text">新手指南</div>
        </div>
        <a-popover
          placement="leftBottom"
          @visibleChange="customerPopoverChange"
        >
          <template slot="content">
            <div class="wx_grounp_content">
              <div class="wx_grounp_text">微信扫码进群咨询</div>
              <!-- 获取js 时间戳 -->
              <div class="wx_grounp_img_box">
                <img
                  class="wx_grounp"
                  :src="
                    `https://fpx-bss-oss.oss-cn-shenzhen.aliyuncs.com/fpx_boms/40827170653596IMG_5990.png?timeStr=${new Date().getTime()}`
                  "
                />
              </div>
            </div>
          </template>
          <!-- 未认证、未登录、 已认证&未开通所有品牌 -->
          <div
            v-if="
              !$store.state.accountRegStatus.certification ||
                !this.$store.state.username
            "
            class="btn-item"
          >
            <i class="iconfont iconqunneizixun"></i>
            <div class="item-text">
              售前咨询
            </div>
          </div>
        </a-popover>
        <a-popover
          placement="leftBottom"
          @visibleChange="customerPopoverChange"
        >
          <template slot="content">
            <Customer />
            <!-- <IM /> -->
          </template>
          <div v-if="this.$store.state.username" class="btn-item">
            <i class="iconfont iconlianxixiaoshou"></i>
            <div class="item-text">联系销售</div>
          </div>
        </a-popover>
      </div>
      <!-- <div v-if="this.$store.state.username" @click="toggles" class="opt-btn">
      <i class="iconfont iconyoujiantou" :class="defCustonVisble ? ' put' : ''"></i>
    </div> -->
    </div>
  </div>
</template>
<script>
import IM from '@c/IM/IM'
import Customer from '@v/index/customer/Customer'

export default {
  name: 'newCustom',
  data() {
    return {
      defCustonVisble: true,
      custonHover: true,
      popoverVisble: false,
      robotVisible: false
    }
  },
  components: {
    IM,
    Customer
  },
  methods: {
    toggles(e) {
      let _even = e || event
      _even.stopPropagation()
      if (this.defCustonVisble) {
        this.defCustonVisble = false
        this.custonHover = false
        this.robotVisible = true
      } else if (!this.defCustonVisble && this.custonHover) {
        this.custonHover = false
        this.robotVisible = true
      }
    },
    mouseenterCustonBox(e) {
      let _even = e || event
      _even.stopPropagation()
      this.custonHover = true
      this.robotVisible = false
    },
    mouseEnterRetract(e) {
      let _even = e || event
      _even.stopPropagation()
      this.custonHover = true
    },
    mouseLeaveRetract(e) {
      let _even = e || event
      _even.stopPropagation()
      this.robotVisible = false
    },
    goRegstratopm() {
      this.$router.push('/openProgress')
    },
    goInstructions() {
      this.$router.push('/instructions')
    },
    customerPopoverChange(visible) {
      this.popoverVisble = visible
    },

    customerIMChange(visible) {
      this.popoverVisble = visible
    }
  }
}
</script>
<style lang="less" scoped>
* {
  caret-color: transparent;
}

.custom-box {
  position: fixed;
  cursor: pointer;
  right: 0;
  bottom: 100px;
  width: 100px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  // box-shadow: 0 3px 10px #d1cfcf;
  // &:hover {
  // .retract-index-udesk {
  //   // transform: scale(0);
  //   width: 0;
  //   height: 0;
  //   display: none;
  //   opacity: 0;
  // }
  // .new-index-udesk,
  // .custom-btn-box {
  //   display: block;
  // }
  // }
}
.show {
  display: block;
}
.retract-index-udesk {
  position: fixed;
  cursor: pointer;
  right: 0px;
  bottom: 100px;
  display: none;
  opacity: 1;
  margin-bottom: 6px;
  width: 48px;
  height: 88px;
  z-index: 1000;
  // transition: all 0.2s;
  // animation: all 0.2s;
  // animation: scaleAnimation 6s infinite;
  // animation-timing-function: ease-in-out; /* 缓动效果，这里使用了先加速后减速 */
}
@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1); /* 初始和结束时大小不变 */
    transform: translateX(0);
  }
  60% {
    transform: scale(1); /* 中间时放大到1.03倍 */
    transform: translateX(0);
  }
  75% {
    transform: scale(1.2) translateX(0px);
  }

  85% {
    transform: scale(1); /* 中间时放大到1.03倍 */
    transform: scale(1) translateX(0px);
  }
}
.new-index-udesk {
  display: none;
  margin-bottom: 6px;
  width: 84px;
  height: 94px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url('../../assets/icon/robot.png');
  background-size: 84px 94px;
  z-index: 1000;
  // transition: all 0.4s;
  // animation: all 0.4s;
  &:hover {
    transform: scale(1.05);
  }
}
.custom-btn-box {
  display: none;
  margin: 0px 3px 0 0;
  width: 60px;
  background: #ffffff;
  box-shadow: 2px 2px 20px 0 rgba(58, 76, 135, 0.16);
  border-radius: 8px;
  // transition: all 0.4s;
  // animation: all 0.4s;
  .btn-item {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 59px;
    background: #ffffff;
    border-bottom: 1px solid #dadfe8;
    // transition: all 0.4s;
    // animation: all 0.4s;
    &:hover {
      transform: scale(1.08);
    }
    .item-text {
      margin-top: 7px;
      font-size: 12px;
      width: 50px;
      text-align: center;
      color: #414b67;
      font-weight: 400;
    }
    .iconfont {
      font-size: 21px;
      color: #414b67;
    }
  }

  .hover {
    &:hover {
      .item-text {
        color: #065cf3 !important;
      }
      .iconfont {
        color: #065cf3 !important;
      }
      color: #065cf3 !important;
    }
  }
}
.wx_grounp_content {
  text-align: center;
  font-size: 15px;
  font-size: 16px;
  color: #323643;
  font-weight: 500;
  .wx_grounp_text {
    line-height: 1.4;
  }
  .wx_grounp_img_box {
    margin: 6px auto 0;
    display: block;
    width: 150px;
    height: 150px;
  }
  .wx_grounp {
    display: block;
    width: 150px;
    height: 150px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
  animation: all 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.opt-btn {
  margin-top: 6px;
  margin-right: 3px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 3px 20px 0 rgba(58, 76, 135, 0.16);
  transition: all 0.3s;
  animation: all 0.2s;
  &:hover {
    transform: translateY(4px);
  }
  .iconfont {
    font-size: 8px;
    text-align: center;
    color: #878fa6;
    // transform: rotate(90deg);
    transform: rotate(270deg);
    transition: all 0.3s;
    animation: all 0.2s;
  }
  .put {
    // transform: rotate(270deg);
    transform: rotate(90deg);
    transition: all 0.3s;
    animation: all 0.2s;
  }
}

::v-deep.ant-popover-inner-content {
  padding: 0px 16px !important;
}
</style>
