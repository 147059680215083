<!--
 * @Description: 操作指引
 * @Autor: S9637/chifuk
 * @Date: 2020-12-14 11:42:43
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-12-15 17:35:52
-->
<template>
  <div class="mask-tip" @click="hideTip">
    <div class="focus-view">
      <!-- focus view -->
      <slot></slot>
      <!-- tip -->
      <div class="opt-wrapper">
        <div class="arrow-top"></div>
        <div class="opt-btn">{{ tip }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mask-tip',
  props: {
    classId: {
      // 操作指引 聚焦元素class 标识
      type: String
    },
    tip: {
      // 按钮提示文字
      type: String
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setStylePosition()
    })

    // ESC 关闭
    document.onkeypress = e => {
      if (e.keyCode === 27) this.hideTip()
    }
  },
  methods: {
    /**
     * @description: 定位焦点元素，修正样式
     * @param {*}
     * @return {*}
     */
    setStylePosition() {
      if (!this.classId) return
      console.log(this.classId)
      const node = document.getElementById(this.classId)
      console.log(node)
      // if (!node) return
      const rect = node.getBoundingClientRect()
      console.log(rect)
      const fv = document.querySelector('.focus-view')
      const fva = document.querySelector(`.${this.classId}`)
      if (fv) {
        fv.style.left = rect.x + 'px'
        fv.style.top = rect.y + 'px'
      }
      if (fva) {
        fva.style.width = rect.width + 'px'
        fva.style.height = rect.height + 'px'
        fva.style.lineHeight = rect.height + 'px'
      }
    },
    /**
     * @description: 移除
     * @param {*}
     * @return {*}
     */
    hideTip() {
      this.$nextTick(() => {
        const tip = document.querySelector('.mask-tip')
        if (tip) tip.remove()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mask-tip {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  .focus-view {
    position: absolute;
  }
  .opt-wrapper {
    position: relative;
  }
  .opt-btn {
    width: 100%;
    height: 62px;
    background: #fff;
    z-index: 11;
    margin-top: 22px;
    background: #0e5ade;
    border-radius: 4px;
    text-align: center;
    line-height: 62px;
    color: #fff;
    position: relative;
  }
  .arrow-top {
    width: 0;
    height: 0;
    border-bottom: 6px solid #0e5ade;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}
</style>
