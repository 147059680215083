<!--
 * @Description: 
 * @Autor: S9637/chifuk
 * @Date: 2021-01-25 13:51:52
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-03-24 18:42:46
-->
<i18n>
{
  "zh": {
    "title": "欢迎来到商家中心。在使用系统的过程中如有任何疑问，可加入商家中心沟通",
    "desc": "向工作人员进行反馈。",
    "qq": "QQ群"
  },
  "en": {
     "title": "Welcome to the Merchant Center. If you have any questions during the use of the system, you can join the Merchant Center Communication.",
    "desc": "Feedback to staff." ,
    "qq": "QQ Group"
  },
  "de": {
     "title": "Welcome to the Merchant Center. If you have any questions during the use of the system, you can join the Merchant Center Communication.",
    "desc": "Feedback to staff." ,
    "qq": "QQ Group"
  },
  "ja": {
     "title": "Welcome to the Merchant Center. If you have any questions during the use of the system, you can join the Merchant Center Communication.",
    "desc": "Feedback to staff." ,
    "qq": "QQ Group"
  }
}
</i18n>
<template>
  <div class="top-notify flex-sp" v-if="isShow">
    <div class="flex-start">
      <span class="iconfont iconizhushi left-icon"></span>
      <div>
        {{ $t('title')
        }}<a href="javascript:;" class="qq"
          ><span>{{ $t('qq') }}</span>
          <div class="qrcode">
            <img src="../../assets/qq3.png" alt="" /><span
              class="triangle"
            ></span></div></a
        >{{ $t('desc') }}
      </div>
    </div>
    <a-icon type="close" class="close" @click="doClose" />
  </div>
</template>
<script>
export default {
  name: 'top-notify',
  data() {
    return {
      isShow: true,
      expireTime: 60 * 1000
    }
  },
  methods: {
    doClose() {
      this.isShow = false
      this.$store.state.hasTopBanner = false
    }
  }
}
</script>

<style lang="less" scoped>
.top-notify {
  width: 100%;
  height: 24px;
  background: #f0e5d7;
  padding: 0 16px;
  font-size: 10px;
  color: #545d75;
  .left-icon {
    font-size: 12px;
    color: #ff9300;
    padding-right: 8px;
  }
  .qq {
    position: relative;
    &:hover .qrcode {
      display: block;
    }
    .qrcode {
      display: none;
      position: absolute;
      left: 10px;
      top: 25px;
      background: #fff;
      box-shadow: 0 3px 10px #d1cfcf;
      transform: translateX(-50%);
      border-radius: 8px;
      padding: 4px;
      img {
        display: block;
        width: 188px;
        height: auto;
      }
    }
    .triangle {
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
      border-left: 8px solid transparent;
      position: absolute;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .close {
    font-size: 14px;
  }
}
</style>
