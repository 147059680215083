<i18n>
{
  "zh": {
    "nores": "未找到，试试改变关键词"
  },
  "en": {
    "nores": "Not found, try to change keywords"
  },
  "de": {
    "nores": "Not found, try to change keywords"
  },
  "ja": {
    "nores": "Not found, try to change keywords"
  }
}
</i18n>
<template>
  <div class="shipment-address" ref="shpaddress">
    <a-input
      size="small"
      class="item-input"
      :class="transparentBg ? 'bg-transparent' : ''"
      @change="onSearch"
      @focus="onFocus"
      v-model.trim="orgName"
      placeholder="请选择发货地"
    >
      <a-icon
        slot="suffix"
        :class="showSelect ? 'ArrowUp' : 'ArrowDown'"
        class="anticon anticon-down ant-select-arrow-icon"
        type="down"
        style="color: rgba(0, 0, 0, 0.25); font-size: 12px"
      />
    </a-input>
    <!-- drop down -->
    <ul
      class="my-dropdown"
      :class="isFixedBottom ? 'isFixedBottom' : ''"
      @click="onClickItem"
      v-if="showSelect"
    >
      <li style="text-align: center" v-if="Object.keys(res).length == 0">
        {{ $t('nores') }}
      </li>
      <li :key="code" v-for="(item, code) of res">
        <div style="font-size: 14px; color: #005adc; padding: 10px 0 5px 20px">
          {{ item[0].provinceName }}
        </div>
        <div class="shippment-children">
          <div
            class="sa-item"
            :key="c.ogId"
            :data-ogId="c.ogId + '-' + c.ogCityName"
            v-for="c in item"
          >
            {{
              c.ogCityEname.charAt(0).toUpperCase() +
                ' ' +
                (c.cityName || c.ogCityName)
            }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
var that = null
export default {
  name: 'shipment-address',
  data() {
    this.originData = {}
    return {
      res: {},
      ogId: '',
      orgName: '',
      isClicked: false,
      showSelect: false
    }
  },
  props: {
    Name: {
      type: String
    },
    ogIdPickup: {
      type: [String, Number]
    },
    transparentBg: {
      type: Boolean,
      default: false
    },
    isFixedBottom: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    Name(newVal) {
      this.orgName = this.Name
      this.ogId = this.ogIdPickup || this.ogId
    },
    showSelect(val, oldVal) {
      // 如果输入名称是正确的城市名称，则关闭时自动设置id
      if (!val) {
        const list = this.list || []
        const name = this.orgName
        const has =
          name &&
          list.some(item => {
            if (item.cityName == name || item.ogCityName == name) {
              this.orgName = item.cityName || item.ogCityName
              this.ogId = item.ogId
              this.$emit('ogId', item.ogId, item.cityName || item.ogCityName)
              return true
            } else {
              return false
            }
          })
        if (!has) {
          this.orgName = ''
          this.ogId = ''
          this.$emit('ogId', '')
        }
      }
    }
  },
  created() {
    this.orgName = this.Name ? this.Name : ''
    this.ogId = this.ogIdPickup || this.ogId || '10'
    this.setDefaultValue(this.Name)
  },
  mounted() {
    that = this
    this.getList()
    window.onclick = this.clickHide.bind(this)
  },
  beforeDestroy() {
    window.onclick = null
  },
  methods: {
    clearData() {
      this.ogId = '10'
      this.orgName = '深圳'
      this.$emit('ogId', 10)
    },
    setDefaultValue(name) {
      if (name) {
        return
      }
      // 默认选中深圳
      this.ogId = 10
      this.orgName = this.Name ? this.Name : '深圳'
      this.$emit('ogId', 10)
    },
    hasInputName(name) {
      const list = this.list || []
      return list.some(item => item.cityName == name || item.ogCityName == name)
    },
    clickHide(event) {
      let _that = that || this
      let placeholder = event.target.placeholder // 打印点击元素的placeholder
      if (
        (this.showSelect && !this.isClicked) ||
        placeholder != '请选择发货地'
      ) {
        _that.showSelect = false
      }
    },
    onFocus() {
      this.showSelect = true
      this.isClicked = true
      if (!this.orgName) {
        this.res = JSON.parse(JSON.stringify(this.originData))
      }
      setTimeout(() => {
        this.showSelect = true
        this.isClicked = false
      }, 300)
    },
    onBlur() {
      this.showSelect = false
    },
    onSearch() {
      const query = this.orgName
      const vals = this.originData
      const result = {}
      for (let k in vals) {
        vals[k].forEach(item => {
          if (
            item.cityName.indexOf(query) > -1 ||
            (item.provinceName && item.provinceName.indexOf(query) > -1)
          ) {
            if (!result[k]) {
              result[k] = [item]
            } else {
              result[k].push(item)
            }
          }
        })
      }
      this.res = result
    },
    getList() {
      this.$get('landmark/chargeLocation').then(res => {
        this.list = res || []
        this.handlerData(res ? res : [])
      })
    },
    /**
     * @description: 将城市数据按省分组
     * @param {*}
     * @return {*}
     */
    handlerData(list = []) {
      if (!list || list.length == 0) return
      let provinceCodes = list.map(item => item.provinceCode)
      if (provinceCodes) {
        provinceCodes = [...new Set(provinceCodes)]
      }
      const res = {}
      provinceCodes.forEach(code => {
        res[code] = list.filter(x => x.provinceCode == code)
      })
      this.res = res
      this.originData = JSON.parse(JSON.stringify(res))
    },
    /**
     * @description: 设置选中项
     * @param {*} e
     * @return {*}
     */
    onClickItem(e) {
      if (e.target.dataset.ogid) {
        const org = e.target.dataset.ogid.split('-')
        this.orgName = org[1]
        this.ogId = org[0]
        this.$emit('ogId', org[0], org[1])
      }
      this.showSelect = false
    },
    /**
     * @description: 清空选中
     * @param {*}
     * @return {*}
     */
    clearSelected() {
      this.setDefaultValue()
      this.showSelect = false
    }
  }
}
</script>

<style lang="less" scoped>
.sa-item {
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  color: #545d75;
  font-size: 14px;
  padding: 7px 18px;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background: #f6f8fa;
  }
}
.cust-input {
  position: absolute;
  left: 0;
  right: 11px;
  top: 6px;
  width: 204px;
  background: orange;
  z-index: 1;
}
.ArrowUp {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.ArrowDown {
  transform: rotate(0deg);
  transition: all 0.3s;
}
.my-dropdown {
  height: 300px;
  width: 214px;
  overflow: auto;
  position: absolute;
  z-index: 11;
  background: #fff;
  left: 26px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  right: 0;
  z-index: 100;
  overflow: auto;
}
.isFixedBottom {
  bottom: 80px !important;
}
.item-input {
  padding: 0;
  border: 0;
  color: #323643;
  font-weight: 500;
  outline: none; /*清除input点击之后的黑色边框*/

  &::placeholder {
    color: #bfbfbf;
  }
  &:focus {
    border: 0;
  }
  &:active {
    border: 0;
  }
}
::v-deep.bg-transparent {
  background: transparent !important;
  input {
    padding-right: 26px !important;
    padding-left: 0 !important;
    text-align: right;
    background: transparent !important;
  }
}
::v-deep.ant-input-affix-wrapper .ant-input {
  height: 22px;
  line-height: 22px;
  border: 0;
  color: #323643;
  font-weight: 500;
  &::placeholder {
    color: #bfbfbf !important;
  }
  &:focus {
    border: 0 !important;
    box-shadow: 0 0 0 2px rgba(6, 92, 243, 0);
  }
  &:active {
    border: 0;
    box-shadow: 0 0 0 2px rgba(6, 92, 243, 0);
  }
}
</style>
