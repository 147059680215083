<!--
 * @Description: 全球库存分析
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 17:44:47
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-10 20:56:12
-->
<i18n>
{
  "zh": {
    "title": "库存分析",
    "warehouseStock": "在库库存数量",
    "availableStock": "可用库存数量",
    "pendingStock": "待出库库存数量",
    "freezeStock": "冻结库存数量",
    "transitStock": "在途库库存数量",
    "Detaileddata": "详细数据"
  },
  "en": {
    "title": "Global inventory analysis",
    "warehouseStock": "WarehouseStock Quantity",
    "availableStock": "Available Stock Quantity",
    "pendingStock": "Stock quantity pending delivery",
    "freezeStock": "Freeze Stock Quantity",
    "transitStock": "In-transit stock inventory quantity",
    "Detaileddata": "Detailed data"
  },
  "de": {
    "title": "Aktienanalyse",
    "warehouseStock": "Vorrätig Menge",
    "availableStock": "Verfügbarer Bestand",
    "pendingStock": "Anzahl der freizugebenden Bestände",
    "freezeStock": "Anzahl der gefrorenen Bestände",
    "transitStock": "Anzahl der Bestände im Transit",
    "Detaileddata": "Detaillierte Daten"
  },
  "ja": {
    "title": "株式分析",
    "warehouseStock": "在庫数",
    "availableStock": "在庫あり",
    "pendingStock": "放出される株式数",
    "freezeStock": "冷凍在庫数",
    "transitStock": "輸送中の在庫数",
    "Detaileddata": "詳細データ"
  }
}
</i18n>
<template>
  <div class="instock section-left">
    <a-spin :spinning="spinning">
      <div class="index-title">
        {{ $t('title') }}
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: this.$t('title') }
              })
            }
          "
        />
      </div>
      <div
        class="b-more"
        @click="goDetail"
        data-baidu-pv="首页，详细数据，库存分析"
      >
        {{ $t('Detaileddata') }}
        <div class="arrow-right"></div>
      </div>

      <MaskView
        type="line"
        style="height: 270px; margin-top: 4px"
        v-if="!isOpenFB4 && isCloseFB4 != 4"
      />
      <template v-else>
        <VeLine
          v-if="chartData.rows.length > 0"
          height="294px"
          :data="chartData"
          :extend="extend"
        />
        <a-empty style="width: 100%; height: 270px; padding-top: 54px" v-else />
      </template>
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import VeLine from 'v-charts/lib/line.common'
import MaskView from '../mask/MaskView'
export default {
  name: 'instock',
  components: {
    VeLine,
    MaskView
  },
  computed: {
    isOpenFB4() {
      return this.$store.state.userInfo.isFB4Open
    },
    isCloseFB4() {
      return this.$store.state.brandStatus.FB4
    }
  },
  data() {
    this.extend = {
      ...this.$defaultChartSettings,
      legend: {
        right: 'center',
        width: '1000',
        ...this.$defaultChartSettings.legend
      },
      series: {
        type: 'line',
        height: 256
      }
    }
    return {
      spinning: false,
      chartData: {
        columns: [],
        rows: []
      }
    }
  },
  created() {
    this.getSummary()
  },
  methods: {
    doOk() {
      this.$store.state.guideStatus = 4
      document.querySelector('#app').scrollTo(0, 0)
    },
    getSummary() {
      this.spinning = true
      this.$post('v2/dataCenter/stockOverview', {
        customerId: '',
        customerCode: '',
        snapshotDateStart:
          moment()
            .subtract(7, 'days')
            .format(this.$root.dateFormat) + ' 00:00:00',
        snapshotDateEnd:
          moment()
            .subtract(1, 'days')
            .format(this.$root.dateFormat) + ' 00:00:00'
      })
        .then(res => {
          this.spinning = false
          this.generateChartData(res)
        })
        .catch(() => {
          this.spinning = false
        })
    },
    /**
     * @description: 生成徒步数据
     * @param {type}
     * @return {type}
     */
    generateChartData(list) {
      const rows = []
      const warehouseStock = this.$t('warehouseStock')
      const availableStock = this.$t('availableStock')
      const pendingStock = this.$t('pendingStock')
      const freezeStock = this.$t('freezeStock')
      const transitStock = this.$t('transitStock')
      list.forEach(item => {
        rows.push({
          snapshotDate: item.snapshotDate.substring(0, 10),
          [warehouseStock]: item.warehouseStock,
          [availableStock]: item.availableStock,
          [pendingStock]: item.pendingStock,
          [freezeStock]: item.freezeStock,
          [transitStock]: item.transitStock
        })
      })

      this.chartData = {
        columns: [
          'snapshotDate',
          warehouseStock,
          availableStock,
          pendingStock,
          freezeStock,
          transitStock
        ],
        rows
      }
      console.log(rows)
    },
    /**
     * @description: 跳转至数据中心
     * @param {type}
     * @return {type}
     */
    goDetail() {
      this.$router.push({ name: 'storeSummary' })
    }
  }
}
</script>

<style lang="less" scoped>
.instock {
  width: 66.67%;
  height: 332px;
  background: #fff;
  margin-right: 20px;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.guide-mask {
  z-index: 1000;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
  }
}
.intro {
  min-width: 290px;
  position: absolute;
  left: 50%;
  bottom: -30px;
  transform: translate(-50%, 100%);
  background: #0e5ade;
  border-radius: 4px;
  padding: 20px 14px 18px 14px;
  box-sizing: border-box;
  &.serviceIntro {
    bottom: -40px;
  }
  p {
    color: #fff;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 135px;
    top: -16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid #0e5ade;
  }
}
</style>
