// 入库官方分仓查看列表
const getInWarehouseListColumns = vue => [
  {
    dataIndex: 'to_warehouse_name',
    key: 'to_warehouse_name',
    title: vue.$t('warehouseName'),
    scopedSlots: { customRender: 'to_warehouse_name' }
  },
  {
    title: vue.$t('create.a.single.quantity'),
    dataIndex: 'create_cnt',
    key: 'create_cnt',
    scopedSlots: { customRender: 'create_cnt' }
  },
  {
    title: vue.$t('domestic.warehouse.receipt'),
    dataIndex: 'whe_receive_cnt',
    key: 'whe_receive_cnt',
    scopedSlots: { customRender: 'whe_receive_cnt' }
  },
  {
    title: vue.$t('国内仓签出单量'),
    dataIndex: 'whe_ship_cnt',
    key: 'whe_ship_cnt',
    scopedSlots: { customRender: 'whe_ship_cnt' }
  },
  {
    title: vue.$t('hong.kong'),
    dataIndex: 'departure_cnt',
    key: 'departure_cnt',
    scopedSlots: { customRender: 'departure_cnt' }
  },
  {
    title: vue.$t('to.hong.kong'),
    dataIndex: 'arrival_cnt',
    key: 'arrival_cnt',
    scopedSlots: { customRender: 'arrival_cnt' }
  },
  {
    title: vue.$t('shelf.single'),
    dataIndex: 'putaway_cnt',
    key: 'putaway_cnt',
    scopedSlots: { customRender: 'putaway_cnt' }
  }
]
// 入库官方分仓查看列表
const getInUnofficialWarehouseListColumns = vue => [
  {
    dataIndex: 'to_warehouse_name',
    key: 'to_warehouse_name',
    title: vue.$t('warehouseName'),
    scopedSlots: { customRender: 'to_warehouse_name' }
  },
  {
    title: vue.$t('create.a.single.quantity'),
    dataIndex: 'create_cnt',
    key: 'create_cnt',
    scopedSlots: { customRender: 'create_cnt' }
  },
  {
    title: vue.$t('forecast'),
    dataIndex: 'plan_cnt',
    key: 'plan_cnt',
    scopedSlots: { customRender: 'plan_cnt' }
  },
  {
    title: vue.$t('approximate.order'),
    dataIndex: 'reservation_cnt',
    key: 'reservation_cnt',
    scopedSlots: { customRender: 'reservation_cnt' }
  },
  {
    title: vue.$t('shelf.single'),
    dataIndex: 'putaway_cnt',
    key: 'putaway_cnt',
    scopedSlots: { customRender: 'putaway_cnt' }
  }
]
// 入库订单明细
const getInOrderListColumns = vue => [
  {
    dataIndex: 'customer_id',
    key: 'customer_id',
    title: vue.$t('fb4.customer.code'),
    width: 120,
    fixed: 'left',
    scopedSlots: { customRender: 'customer_id' }
  },
  {
    title: vue.$t('customerCode'),
    dataIndex: 'customer_code',
    key: 'customer_code',
    width: 100,
    fixed: 'left',
    scopedSlots: { customRender: 'customer_code' }
  },
  {
    title: vue.$t('single.number'),
    width: 200,
    fixed: 'left',
    dataIndex: 'consignment_no',
    key: 'consignment_no',
    scopedSlots: { customRender: 'consignment_no' }
  },
  {
    title: vue.$t('status'),
    dataIndex: 'ic_status',
    key: 'ic_status',
    scopedSlots: { customRender: 'ic_status' }
  },
  {
    title: vue.$t('SC.creationTime'),
    dataIndex: 'create_time',
    width: 180,
    key: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: vue.$t('warehouseName'),
    width: 150,
    dataIndex: 'to_warehouse_name',
    key: 'to_warehouse_name',
    scopedSlots: { customRender: 'to_warehouse_name' }
  },
  {
    title: vue.$t('transportation.method'),
    dataIndex: 'transport_type',
    key: 'transport_type',
    scopedSlots: { customRender: 'transport_type' }
  },
  {
    title: vue.$t('forecast.number'),
    dataIndex: 'plan_qty',
    key: 'plan_qty',
    scopedSlots: { customRender: 'plan_qty' }
  },
  {
    title: vue.$t('receipt.number'),
    dataIndex: 'actual_qty',
    key: 'actual_qty',
    scopedSlots: { customRender: 'actual_qty' }
  },
  {
    title: vue.$t('number.of.places'),
    dataIndex: 'putaway_qty',
    key: 'putaway_qty',
    scopedSlots: { customRender: 'putaway_qty' }
  }
]

// 出库分仓查看列表
const getWarehouseListColumns = vue => [
  {
    dataIndex: 'businessNo',
    key: 'businessNo',
    title: vue.$t('SC.warehouse'),
    scopedSlots: { customRender: 'businessNo' }
  },
  {
    title: vue.$t('creative.amount'),
    dataIndex: 'businessSystem',
    key: 'businessSystem',
    scopedSlots: { customRender: 'businessSystem' }
  },
  {
    title: vue.$t('wound'),
    dataIndex: 'currencyCode',
    key: 'currencyCode',
    scopedSlots: { customRender: 'currencyCode' }
  },
  {
    title: vue.$t('internet.volume'),
    dataIndex: 'totalFreezeAmount',
    key: 'totalFreezeAmount',
    scopedSlots: { customRender: 'totalFreezeAmount' }
  },
  {
    title: vue.$t('proper'),
    dataIndex: 'availableAmount',
    key: 'availableAmount',
    scopedSlots: { customRender: 'availableAmount' }
  },
  {
    title: vue.$t('wound.rate'),
    dataIndex: 'freezeStatus',
    key: 'freezeStatus',
    scopedSlots: { customRender: 'freezeStatus' }
  },
  {
    title: vue.$t('net.rate'),
    dataIndex: 'freezeStatus',
    key: 'freezeStatus',
    scopedSlots: { customRender: 'freezeStatus' }
  },
  {
    title: vue.$t('proper'),
    dataIndex: 'freezeStatus',
    key: 'freezeStatus',
    scopedSlots: { customRender: 'freezeStatus' }
  }
]

// 尾程履行监控 分仓查看列表
const getOutboundPointsWarehouse = (vue, type) => [
  //type :1.分仓查看表头  2.分产品查看表头
  // {
  //   dataIndex: 'index',
  //   key: 'index',
  //   title: vue.$t('serial.number'),
  //   scopedSlots: { customRender: 'index' }
  // },
  {
    title: type == 1 ? vue.$t('warehouseName') : vue.$t('logistics.product'),
    dataIndex: type == 1 ? 'warehouse_name' : 'logistics_product_name',
    key: type == 1 ? 'warehouseName' : 'logistics_product_name',
    scopedSlots: {
      customRender: type == 1 ? 'warehouseName' : 'logistics_product_name'
    }
  },
  {
    title: vue.$t('create.a.single.quantity'),
    dataIndex: 'created',
    key: 'created',
    scopedSlots: { customRender: 'created' }
  },
  {
    title: vue.$t('outCnt'),
    dataIndex: 'outbound',
    key: 'outbound',
    scopedSlots: { customRender: 'outbound' }
  },
  {
    title: vue.$t('internet'),
    dataIndex: 'ascan',
    key: 'ascan',
    scopedSlots: { customRender: 'ascan' }
  },
  {
    title: vue.$t('subject'),
    dataIndex: 'dscan',
    key: 'dscan',
    scopedSlots: { customRender: 'dscan' }
  },
  {
    title: vue.$t('wound.rate'),
    dataIndex: 'outbound_rate',
    key: 'outbound_rate',
    scopedSlots: { customRender: 'outbound_rate' }
  },
  {
    title: vue.$t('net.rate'),
    dataIndex: 'ascan_rate',
    key: 'ascan_rate',
    scopedSlots: { customRender: 'ascan_rate' }
  },
  {
    title: vue.$t('proper'),
    dataIndex: 'dscan_rate',
    key: 'dscan_rate',
    scopedSlots: { customRender: 'dscan_rate' }
  }
]

// 诊断列表
const getAbnormalListColumns = vue => [
  {
    dataIndex: 'customer_code',
    key: 'customer_code',
    title: vue.$t('助记码'),
    scopedSlots: { customRender: 'customer_code' }
  },
  {
    title: vue.$t('委托单号'),
    dataIndex: 'consignment_no',
    key: 'consignment_no',
    scopedSlots: { customRender: 'consignment_no' }
  },
  {
    title: vue.$t('委托类型'),
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: vue.$t('仓库名称'),
    dataIndex: 'warehouse_name_ch',
    key: 'warehouse_name_ch',
    scopedSlots: { customRender: 'warehouse_name_ch' }
  },
  {
    title: vue.$t('状态'),
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: vue.$t('异常原因'),
    dataIndex: 'ab_reason',
    key: 'ab_reason',
    scopedSlots: { customRender: 'ab_reason' }
  },
  {
    title: vue.$t('物流产品'),
    dataIndex: 'product_name_cn',
    key: 'product_name_cn',
    scopedSlots: { customRender: 'product_name_cn' }
  },
  {
    title: vue.$t('创建时间'),
    dataIndex: 'create_time',
    key: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: vue.$t('操作'),
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export {
  getInWarehouseListColumns,
  getOutboundPointsWarehouse,
  getInUnofficialWarehouseListColumns,
  getInOrderListColumns,
  getWarehouseListColumns,
  getAbnormalListColumns
}
