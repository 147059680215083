<template>
  <div>
    <div v-if="showPopupDailog" class="wc-icon" @click="openWcDailog">
      <!-- & -->
      <i class="iconfont iconxiaochengxu" style="font-size: 22px"></i>
    </div>
    <div class="mask" v-if="visible || $store.state.miniProgramPopup">
      <div class="dialog">
        <div class="close" @click="closePopup"></div>
        <img
          class="dialog_img"
          src="../../assets/min_dailog.png"
          alt=""
          width="742"
          height="667"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopupDailog: false,
      visible: false,
    }
  },
  watch: {
    $route(to) {
      if (to.name != 'personal_center') {
        this.$store.state.miniProgramPopup = false
      }
    },
  },
  created() {
    this.showPopup()
  },
  methods: {
    openWcDailog() {
      this.visible = true
    },
    showPopup() {
      this.$get('v2/message/miniProgramGuide')
        .then((res) => {
          this.$hide()
          this.showPopupDailog = res
        })
        .catch(() => {
          this.$hide()
        })
    },

    closePopup() {
      this.visible = false
      if (this.$store.state.miniProgramPopup) {
        this.$store.state.miniProgramPopup = false
        this.closeDssPopup()
      }
    },
    closeDssPopup() {
      this.$get('v2/message/closePopUpOnline')
        .then((res) => {
          this.$hide()
          this.$store.state.miniProgramPopup = false
        })
        .catch(() => {
          this.$hide()
        })
    },
  },
}
</script>

<style lang="less" scoped>
.wc-icon {
  display: inline-block;
  position: relative;
  margin-right: 16px;
  width: 22px;
  height: 22px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background: #fff;

  cursor: pointer;
  line-height: 20px;
}
.wc-icon .iconxiaochengxu {
  width: 22px;
  height: 22px;
  color: #3561cc;
}
.dialog {
  position: fixed;
  top: 83px;
  left: 50%;
  margin-left: -371px;
  // width: 742px;
  // height: 665px;
  width: 668px;
  height: 600px;
  z-index: 10000;
}
.dialog .close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  z-index: 1000;
  cursor: pointer;
}
.dialog_img {
  // width: 742px;
  // height: 665px;
  width: 668px;
  height: 600px;
}
.mask {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  background: rgba(0, 0, 0, 0.3);
}

.miniprog-btn {
  margin-right: 22px;
  padding: 0;
  text-align: center;
  width: 22px;
  border-radius: 12px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  background: #3561cc;
  cursor: pointer;
  border: 1px solid rgba(53, 97, 204, 1);
}
</style>


