<template>
  <div class="country-select">
    <a-popover placement="bottomLeft" v-model="countryPopover" trigger="click">
      <template slot="content">
        <Country style="width:650px;" @getterCountry="getterCountry" />
      </template>
      <a-select
        showSearch
        allowClear
        v-model="curr"
        :defaultActiveFirstOption="false"
        :filterOption="false"
        :notFoundContent="null"
        :getPopupContainer="getPopupContainerFunc"
        @search="handleCountrySearch"
        @focus="handleFocus"
        @change="handleCountryChange"
      >
        <a-select-option
          v-for="d in countrySerachData"
          :dataset="d"
          :key="d.display"
        >
          {{ d.name }}
        </a-select-option>
      </a-select>
    </a-popover>
  </div>
</template>

<script>
import Country from '@microDss/component/Country'
import { http_getCountry } from '@microDss/service/http/http'
export default {
  name: 'country-select',
  components: {
    Country
  },
  props: {
    selectedValue: {
      type: String
    }
  },
  data() {
    return {
      curr: '',
      code: '',
      countryPopover: false,
      countrySerachData: []
    }
  },
  mounted() {
    this.curr = this.selectedValue
    const selectedCode = this.selectedValue
    if (selectedCode) {
      // 根据国家 code 查找国家完整信息展示
      let countyList = sessionStorage.getItem('c_y')
      if (countyList) {
        countyList = JSON.parse(countyList)
        let result
        for (let x = 0; x < countyList.length; x++) {
          for (let y = 0; y < countyList[x].country.length; y++) {
            const c = countyList[x].country[y].countrys
            c.some(v => {
              if (v.code == selectedCode) {
                result = { code: v.code, name: v.display }
                return true
              } else {
                return false
              }
            })
            if (result) break
          }
          if (result) break
        }

        if (result) {
          this.emitVal(result)
          this.curr = result
        }
      }
    }

    this.$nextTick(() => {
      this.$root.bus.$on('clear', () => {
        this.curr = ''
      })

      this.$root.bus.$on('default', value => {
        this.curr = value
      })

      // emit onscroll
      this.$root.bus.$on('onscroll', () => {
        this.countryPopover = false
      })
    })
  },
  beforeDestroy() {
    this.$root.bus.$off('clear')
    this.$root.bus.$off('default')
    this.$root.bus.$off('onscroll')
  },
  methods: {
    getPopupContainerFunc() {
      return document.querySelector('.dss-index') || document.body
    },
    handleFocus() {
      this.countryPopover = false
      this.countrySerachData = []
    },
    getterCountry(val) {
      if (!val) return
      val = JSON.parse(val)
      this.curr = val.name
      this.countryPopover = false
      this.emitVal(val)
    },
    handleCountrySearch: _.debounce(async function(val) {
      const dt = await http_getCountry({
        queryType: 'orderNoservice',
        key: _.trim(val)
      })
      this.countryPopover = false
      this.countrySerachData = Object.freeze(dt)
    }, 500),
    handleCountryChange(countryName, opt) {
      let code = ''
      if (opt && opt.data && opt.data.attrs && opt.data.attrs.dataset) {
        code = opt.data.attrs.dataset.code
      }
      this.code = code
      this.$emit('pop-value', code, countryName, this.$attrs)
    },
    emitVal(result) {
      let curr = result || {}
      this.code = curr.code
      this.$emit('pop-value', curr.code, curr.name, this.$attrs)
    }
  }
}
</script>
