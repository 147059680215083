export default vm => [
  {
    name: 'personal_center',
    text: vm.$t('personal_center'),
    url: 'personal_center',
    icon: 'iconhome'
  },
  {
    name: 'to_create',
    text: vm.$t('to_create'),
    url: 'to_create',
    icon: 'icondanpiaoxiadan'
  },
  {
    name: 'batch_upload',
    text: vm.$t('batch_upload'),
    url: 'batch_upload',
    icon: 'iconpiliangxiadan'
  },
  {
    name: 'managementinfo_received',
    text: vm.$t('managementinfo_received'),
    url: 'managementinfo_received',
    icon: 'icondingdanguanli1'
  },
  {
    name: 'order_problemabn',
    text: vm.$t('order_problemabn'),
    url: 'order_problemabn',
    icon: 'iconwentijian',
    sub: [
      {
        name: 'abnormal_inner',
        text: vm.$t('abnormal_inner'),
        url: 'abnormal_inner'
      },
      {
        name: 'abnormal_outer',
        text: vm.$t('abnormal_outer'),
        url: 'abnormal_outer'
      },
      {
        name: 'abnormal_arrears',
        text: vm.$t('abnormal_arrears'),
        url: 'abnormal_arrears'
      },
      {
        name: 'abnormal_notify',
        text: vm.$t('abnormal_notify'),
        url: 'abnormal_notify'
      },
      {
        name: 'abnormal_amz',
        text: vm.$t('abnormal_amz'),
        url: 'abnormal_amz'
      }
    ]
  },
  {
    name: 'order_oracle',
    text: vm.$t('order_oracle'),
    url: 'order_oracle',
    icon: 'iconzidadan',
    sub: [
      {
        name: 'abnormal_oracleAbnormal',
        text: vm.$t('abnormal_oracleAbnormal'),
        url: 'abnormal_oracleAbnormal'
      },
      {
        name: 'abnormal_oracleNotify',
        text: vm.$t('abnormal_oracleNotify'),
        url: 'abnormal_oracleNotify'
      }
    ]
  },
  {
    name: 'collectManager',
    text: vm.$t('collectindex'),
    icon: 'iconfuwudan',
    url: 'collectManager',
    // hasFullSub: true, // 标识：如果有一级菜单，子菜单全都有 @todo权限控制
    sub: [
      {
        name: 'collectManager',
        text: vm.$t('collectManager'),
        url: 'collectManager',
        pv: '直发中心，揽收预报管理，揽收预约'
      },
      {
        name: 'groupPackage',
        text: vm.$t('groupPackage'),
        url: 'groupPackage',
        pv: '直发中心，揽收预报管理，揽收组组包'
      },
      {
        name: 'difference',
        text: vm.$t('difference'),
        url: 'difference',
        pv: '直发中心，揽收预报管理，揽收差异化报表'
      },
      {
        name: 'collectAddress',
        text: vm.$t('collectAddress'),
        url: 'collectAddress',
        pv: '直发中心，揽收预报管理，揽收地址管理'
      },
      {
        name: 'material_apply',
        text: vm.$t("material_apply"),
        url: 'material_apply',
        pv: '直发中心，揽收预报管理，物料申请'
      },
    ]
  },
  {
    name: 'eclinkorder_list',
    text: vm.$t('eclinkorder_list'),
    icon: 'iconfuwudan',
    url: 'orderManagement',
    hasFullSub: true,
    sub: [
      {
        name: 'orderManagement',
        text: vm.$t('orderManagement'),
        pv: '服务中心，卖家助手，订单管理'
      },
      {
        name: 'managementSKU',
        text: vm.$t('managementSKU'),
        pv: '服务中心，卖家助手，SKU管理'
      },
      {
        name: 'downloadRecord',
        text: vm.$t('downloadRecord'),
        pv: '服务中心，卖家助手，下载记录'
      },
      {
        name: 'sellerAccount',
        text: vm.$t('sellerAccount'),
        pv: '服务中心，卖家助手，卖家账号'
      }
    ]
  },
  {
    name: 'my_orderslabelPrint',
    text: vm.$t('my_orderslabelPrint'),
    url: 'my_orderslabelPrint',
    icon: 'iconbiaoqiandayin'
  },
  {
    name: 'permission_eclink',
    text: vm.$t('permission_eclink'),
    url: 'insuranceindex',
    icon: 'iconzengzhifuwu',
    sub: [
      {
        name: 'insuranceindex',
        url: 'insuranceindex',
        text: vm.$t('insuranceindex')
      },
      {
        name: 'trackindex',
        url: 'trackindex',
        text: vm.$t('trackindex')
      },
      {
        name: 'documentindex',
        url: 'documentindex',
        text: vm.$t('documentindex')
      },
      {
        name: 'trackcod',
        url: 'trackcod',
        text: vm.$t('trackcod')
      },
      {
        name: 'customerInformation',
        url: 'customerInformation',
        text: vm.$t('customerInformation')
      },
      {
        name: 'cityZipcode',
        url: 'cityZipcode',
        text: vm.$t('cityZipcode')
      }
    ]
  },
  {
    name: 'permission_bagMenu',
    text: vm.$t('permission_bagMenu'),
    url: 'bagImport',
    icon: 'iconzhuangdaichuku',
    hasFullSub: true,
    sub: [
      {
        name: 'bagImport',
        url: 'bagImport',
        text: vm.$t('bagImport')
      },
      {
        name: 'bagImportResult',
        url: 'bagImportResult',
        text: vm.$t('bagImportResult')
      },
      {
        name: 'bagPrint',
        url: 'bagPrint',
        text: vm.$t('bagPrint')
      }
    ]
  },
  {
    name: 'permission_setting',
    text: vm.$t('permission_setting'),
    url: 'permission_setting',
    icon: 'iconfujiaguanli1',
    sub: [
      {
        name: 'customer_cargo_kindindex',
        url: 'customer_cargo_kindindex',
        text: vm.$t('customer_cargo_kindindex')
      },
      {
        name: 'insurance_manageindex',
        url: 'insurance_manageindex',
        text: vm.$t('insurance_manageindex')
      },
      {
        name: 'wish_accountindex',
        url: 'wish_accountindex',
        text: vm.$t('wish_accountindex')
      },
      {
        name: 'vova_accountindex',
        url: 'vova_accountindex',
        text: vm.$t('vova_accountindex')
      },
      {
        name: 'tokenindex',
        url: 'tokenindex',
        text: vm.$t('tokenindex')
      },
      {
        name: 'tophatter_accountindex',
        url: 'tophatter_accountindex',
        text: vm.$t('tophatter_accountindex')
      },
      {
        name: 'dhl_senderindex',
        url: 'dhl_senderindex',
        text: vm.$t('dhl_senderindex')
      },
      {
        name: 'amazon_accountindex',
        url: 'amazon_accountindex',
        text: vm.$t('amazon_accountindex')
      },
      {
        name: 'dunhuang_accountindex',
        url: 'dunhuang_accountindex',
        text: vm.$t('dunhuang_accountindex')
      }
    ]
  }
]
