<!--
 * @Description: 出货地址
 * @Autor: S9637/chifuk
 * @Date: 2020-12-29 15:48:41
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-01-14 16:35:06
-->
<i18n>
{
  "zh": {
    "nores": "未找到，试试改变关键词"
  },
  "en": {
    "nores": "Not found, try to change keywords"
  },
  "de": {
    "nores": "Not found, try to change keywords"
  },
  "ja": {
    "nores": "Not found, try to change keywords"
  }
}
</i18n>
<template>
  <div class="shipment-address" ref="shpaddress">
    <!-- input -->
    <a-input @change="onSearch" @focus="onFocus" v-model.trim="orgName">
      <a-icon
        slot="suffix"
        :class="show ? 'ArrowUp' : 'ArrowDown'"
        class="anticon anticon-down ant-select-arrow-icon"
        type="down"
        style="color: rgba(0, 0, 0, 0.25); font-size: 12px"
      />
    </a-input>
    <!-- drop down -->
    <ul class="my-dropdown" @click="onClickItem" v-show="show">
      <li style="text-align: center" v-if="Object.keys(res).length == 0">
        {{ $t('nores') }}
      </li>
      <li :key="code" v-for="(item, code) of res">
        <div style="font-size: 14px; color: #005adc; padding: 10px 0 5px 20px">
          {{ item[0].provinceName }}
        </div>
        <div class="shippment-children">
          <div
            class="sa-item"
            :key="c.ogId"
            :data-ogId="c.ogId + '-' + c.ogCityName"
            v-for="c in item"
          >
            {{
              c.ogCityEname.charAt(0).toUpperCase() +
              ' ' +
              (c.cityName || c.ogCityName)
            }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'shipment-address',
  data() {
    this.originData = {}
    return {
      res: {},
      ogId: '',
      orgName: '',
      show: false,
    }
  },
  props: {
    Name: {
      type: String
    }
  },
  watch: {
    show(val) {
      // 如果输入名称是正确的城市名称，则关闭时自动设置id
      if (!val) {
        const list = this.list || []
        const name = this.orgName
        const has =
          name &&
          list.some((item) => {
            if (item.cityName == name || item.ogCityName == name) {
              this.orgName = item.cityName || item.ogCityName
              this.ogId = item.ogId
              this.$emit('ogId', item.ogId, item.ogCityName)
              return true
            } else {
              return false
            }
          })
        if (!has) {
          this.orgName = ''
          this.ogId = ''
          this.$emit('ogId', '')
        }
      }
    },
  },
  mounted() {
    this.getList()
    window.onclick = this.clickHide
    this.setDefaultValue()
  },
  beforeDestroy() {
    window.onclick = null
  },
  methods: {
    setDefaultValue() {
      // 默认选中深圳
      this.ogId = 10
      this.orgName = this.Name ? this.Name : '深圳'
      this.$emit('ogId', 10)
    },
    hasInputName(name) {
      console.log(name)
      const list = this.list || []
      console.log(
        list.some((item) => item.cityName == name || item.ogCityName == name)
      )
      return list.some(
        (item) => item.cityName == name || item.ogCityName == name
      )
    },
    clickHide() {
      if (this.show && !this.isClicked) this.show = false
    },
    onFocus() {
      this.show = true
      this.isClicked = true
      if (!this.orgName) {
        this.res = JSON.parse(JSON.stringify(this.originData))
      }
      setTimeout(() => {
        this.isClicked = false
      }, 300)
    },
    onBlur() {
      this.show = false
    },
    onSearch() {
      const query = this.orgName
      const vals = this.originData
      const result = {}
      for (let k in vals) {
        vals[k].forEach((item) => {
          if (
            item.cityName.indexOf(query) > -1 ||
            (item.provinceName && item.provinceName.indexOf(query) > -1)
          ) {
            if (!result[k]) {
              result[k] = [item]
            } else {
              result[k].push(item)
            }
          }
        })
      }
      this.res = result
    },
    getList() {
      this.$get_dss('order/finance_management/charge/chargeLocation').then(
        (res) => {
          this.list = res.data || []
          this.handlerData(res ? res.data : [])
        }
      )
    },
    /**
     * @description: 将城市数据按省分组
     * @param {*}
     * @return {*}
     */
    handlerData(list = []) {
      if (!list || list.length == 0) return
      let provinceCodes = list.map((item) => item.provinceCode)
      if (provinceCodes) {
        provinceCodes = [...new Set(provinceCodes)]
      }
      const res = {}
      provinceCodes.forEach((code) => {
        res[code] = list.filter((x) => x.provinceCode == code)
      })
      this.res = res
      this.originData = JSON.parse(JSON.stringify(res))
    },
    /**
     * @description: 设置选中项
     * @param {*} e
     * @return {*}
     */
    onClickItem(e) {
      if (e.target.dataset.ogid) {
        const org = e.target.dataset.ogid.split('-')
        this.orgName = org[1]
        this.ogId = org[0]
        this.$emit('ogId', org[0])
      }
      this.show = false
    },
    /**
     * @description: 清空选中
     * @param {*}
     * @return {*}
     */
    clearSelected() {
      this.setDefaultValue()
      this.show = false
    },
  },
}
</script>

<style lang="less">
.sa-item {
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  color: #545d75;
  font-size: 14px;
  padding: 7px 18px;
  text-align: center;
  transition: all 0.3s;
  &:hover {
    background: #f6f8fa;
  }
}
.cust-input {
  position: absolute;
  left: 0;
  right: 11px;
  top: 6px;
  width: 204px;
  background: orange;
  z-index: 1;
}
.ArrowUp {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.ArrowDown {
  transform: rotate(0deg);
  transition: all 0.3s;
}
.my-dropdown {
  height: 300px;
  width: 214px;
  overflow: auto;
  position: absolute;
  z-index: 11;
  background: #fff;
  left: 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  right: 0;
  overflow: auto;
}
</style>
