<!--
 * @Description: 库龄统计分析
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 17:44:47
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-09-29 19:39:09
-->
<i18n>
{
  "zh": {
    "title": "库龄分析",
    "Detaileddata": "详细数据"
  },
  "en": {
    "title": "Statistical Analysis of Warehouse Age",
    "Detaileddata": "Detailed data"
  },
   "de": {
    "title": "Altersanalyse",
    "Detaileddata": "Detaillierte Daten"
  },
  "ja": {
    "title": "年齢別分析",
    "Detaileddata": "詳細データ"
  }
}
</i18n>
<template>
  <div class="index-store-time section-left">
    <a-spin :spinning="spinning">
      <div class="index-title">
        {{ $t('title') }}
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: this.$t('title') }
              })
            }
          "
        />
      </div>
      <div
        class="b-more"
        @click="goDetail"
        data-baidu-pv="首页，详细数据，库龄分析"
      >
        {{ $t('Detaileddata') }}
        <div class="arrow-right"></div>
      </div>
      <MaskView
        style="height: 270px; margin-top: 4px"
        type="pie"
        v-if="!isOpenFB4 && isCloseFB4 != 4"
      />
      <template v-else>
        <StoreTimeChart
          v-if="chartDataQuantity.rows.length > 0"
          :extend="extend"
          :chartDataQuantity="chartDataQuantity"
          :chartDataVolume="chartDataVolume"
        />
        <a-empty style="margin-top: 54px" v-else />
      </template>
    </a-spin>
  </div>
</template>

<script>
import StoreTimeChart from '@v/dataCenter/inventory/StoreTimeChart'
import MaskView from '../mask/MaskView'

export default {
  name: 'store-time',
  components: {
    StoreTimeChart,
    MaskView
  },
  computed: {
    isOpenFB4() {
      return this.$store.state.userInfo.isFB4Open
    },
    isCloseFB4() {
      return this.$store.state.brandStatus.FB4
    }
  },
  data() {
    this.extend = {
      legend: {
        show: false
      },
      series: {
        type: 'pie',
        radius: ['20%', '35%'],
        top: '-20%',
        left: '-20%',
        label: {
          formatter: '{b}',
          color: '#333'
        }
      }
    }
    return {
      spinning: false,
      chartDataQuantity: {
        columns: [],
        rows: []
      },
      chartDataVolume: {
        columns: [],
        rows: []
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.spinning = true
      this.$post('v2/dataCenter/stockAgeData', {
        customerId: '',
        customerCode: '',
        skuCode: '',
        warehouseCode: '',
        inventoryAge: ''
      })
        .then(res => {
          this.spinning = false
          const list = res || []
          const quanity = [],
            volume = []
          list.forEach(item => {
            quanity.push({
              inventoryAge: item.inventoryAge,
              warehouseStock: item.warehouseStock
            })
            volume.push({
              inventoryAge: item.inventoryAge,
              volume: item.volume
            })
          })
          this.chartDataQuantity = {
            columns: ['inventoryAge', 'warehouseStock'],
            rows: quanity
          }
          this.chartDataVolume = {
            columns: ['inventoryAge', 'volume'],
            rows: volume
          }
        })
        .catch(() => {
          this.spinning = false
        })
    },
    /**
     * @description: 跳转至数据中心
     * @param {type}
     * @return {type}
     */
    goDetail() {
      this.$router.push({ name: 'storeTime' })
    }
  }
}
</script>

<style lang="less">
.index-store-time {
  width: 66.67%;
  height: 332px;
  background: #fff;
  margin-right: 20px;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  .store-time-chart {
    .pie-wrapper {
      border-radius: 0;
    }
  }
  .pie-title {
    margin-left: 0 !important;
  }
  .pie-wrapper {
    width: 50%;
  }
}
</style>
