// 单价 单位
const DECLARE_UNIT_CODE = [
  { code: 'PCE', name: '件' },
  { code: 'SET', name: '套' },
  { code: 'DZN', name: '打' },
  { code: 'KG', name: '公斤' },
  { code: 'MTR', name: '米' },
  { code: 'PR', name: '双' },
  { code: 'EA', name: '单个' },
  { code: 'MT', name: '吨' },
  { code: 'BOX', name: '箱' }
]

const ORDER_STATUS = {
  D: { name: '草稿', ename: 'Draft' },
  P: { name: '预报', ename: 'Info Received' },

  V: { name: '已收货', ename: 'Shipment Received' },
  H: { name: '入库', ename: ' In Operation' },
  B: { name: '已装袋', ename: 'Bagged' },

  C: { name: '已出库', ename: 'Outed Warehouse' },
  O: { name: '已交服务商', ename: 'Delivered to service provider' },
  R: { name: '干线运输中', ename: 'In trunk transportation' },
  Q: { name: '末公里派送中', ename: 'In the last kilometer' },

  X: { name: '已关闭', ename: 'Closed' },
  Z: { name: '制单', ename: 'Voucher preparation' },

  Y: { name: '发运失败', ename: 'Shipment failure' },
  W: { name: '入库失败', ename: 'Storage failure' },

  F: { name: '出库失败', ename: 'Delivery failure' },
  S: { name: '销毁', ename: 'Destruction' },
  E: { name: '已删除', ename: 'Deleted' },

  M: { name: '平邮件', ename: 'Ordinary mail' },
  K: { name: '已扣件', ename: 'Fastenings' },
  A: { name: '已取消', ename: 'Cancelled' },
  I: { name: '交接失败', ename: 'Handover failure' },
  U: { name: '收货失败', ename: 'Receiving failed' },
  T: { name: '已退件', ename: 'Returned' },
  INTERCEPT: { name: '已拦截订单-网站查询', ename: 'INTERCEPT' },
  N: { name: '已完成', ename: 'Finished' }
}
/**
 *  @todo 2023-11-30版本之前
      01. { zh: '六个月内订单', en: 'Orders within six months' }, // 原来的10，现在要成为0，放第一个
      02. 关闭和取消 -- 互换位置
 */
// const STATUS_FLAG = [
//   'DRAFT',
//   'INFO_RECEIVED',
//   'HANDOVER',
//   'IN_OPERATION',
//   'OUTWAREHOUSE',
//   /* 'MAIN_TRANSPORT', 'HANDOVER_SERVE',
//   'LASTKM_DELIVERY',*/ 'FINISHED',
//   'RETURNED',
//   'INTERCEPT',
//   'CLOSED',
//   'CANCEL',
//   'ALL',
//   'ALL'
// ]
// 订单状态码
const STATUS_FLAG = [
  'ALL',
  'DRAFT',
  'INFO_RECEIVED',
  'HANDOVER',
  'IN_OPERATION',
  'OUTWAREHOUSE',
  /* 'MAIN_TRANSPORT', 'HANDOVER_SERVE',
  'LASTKM_DELIVERY',*/ 
  'FINISHED',
  'RETURNED',
  'INTERCEPT',
  'CANCEL',
  'CLOSED',
  'ALL',
]

const OrderStatusEnum = {
  D: { name: '草稿', ename: 'Draft', flag: 'DRAFT' },
  P: { name: '已预报', ename: 'Info Received', flag: 'INFO_RECEIVED' },
  V: { name: '已交接', ename: 'Shipment Received', flag: 'HANDOVER' },
  H: { name: '库内作业中', ename: 'In Operation', flag: 'IN_OPERATION' },
  C: { name: '已出库', ename: 'Outed Warehouse', flag: 'OUTWAREHOUSE' },
  // O: {name: '已交服务商', ename: 'Shipment Picked Up', flag: 'HANDOVER_SERVE'},
  // R: {name: '干线运输中', ename: 'Main Transport', flag: 'MAIN_TRANSPORT'},
  // Q: {name: '末公里派送中', ename: 'LastKmDelivery', flag: 'LASTKM_DELIVERY'},
  N: { name: '已完成', ename: 'Finished', flag: 'FINISHED' },
  T: { name: '已退件', ename: 'Returned', flag: 'RETURNED' },
  INTERCEPT: { name: '已拦截', ename: 'Intercept', flag: 'INTERCEPT' },
  X: { name: '关闭', ename: 'Closed', flag: 'CLOSED' },
  A: { name: '取消', ename: 'Cancel', flag: 'CANCEL' },
  J: { name: '已拒收', ename: 'Rejection', flag: 'REJECTED' }
}

// 保险类型
const INSURANCE_TYPE = [
  { label: '无', value: '' },
  { label: '5元每票', value: '5Y' },
  { label: '8元每票', value: '8Y' },
  { label: '0.6%保费', value: '6P' }
]

// 保险状态
const InsuranceStatusEnum = {
  F: { name: '草稿', ename: 'Draft' },
  H: { name: '审核', ename: 'Assess' },
  R: { name: '发布', ename: 'Release' },
  A: { name: '投保失败', ename: 'Failure' },
  N: { name: '未购买', ename: 'Not buy' },
  U: { name: '拒保', ename: 'Refuse' }
}

// 货物类型
const GOODS_TYPE = [
  { label: '礼品', elabel: 'Gift', value: '1' },
  { label: '文件', elabel: 'Document', value: '2' },
  { label: '商品货样', elabel: 'Commodity', value: '3' },
  { label: '其他', elabel: 'Other', value: '5' }
]

// 快递公司
const EXPRESS = [
  { label: '顺丰', value: 'HKSF' },
  { label: '圆通', value: 'HKYTO' },
  { label: '申通', value: 'HKSTO' },
  { label: '德邦', value: 'DBKD' },
  { label: '优速', value: 'HKUC' },
  { label: '中通', value: 'HKZTO' },
  { label: '速尔', value: 'HKSUR' }
]

// 标准走货方案
const STANDARD_REPLY = [
  { label: '认可重量，确认走货', value: 1 },
  { label: '确认按申报金额出货', value: 2 },
  { label: '确认按包裹类型走货', value: 3 },
  { label: '确认按4PX系统的发件人信息进行操作', value: 4 },
  { label: '认可相关费用，确认走货', value: 5 },
  { label: '按原产地证明走货', value: 6 },
  { label: '更改发票信息走货', value: 7 },
  { label: '变更渠道走货', value: 8 },
  { label: '上传MSDS走货', value: 9 },
  { label: '换包装走货', value: 10 },
  { label: '上传熏蒸证明走货', value: 11 },
  { label: '上传其他证明走货', value: 12 },
  { label: '更换单号走货', value: 13 },
  { label: '重新核实', value: 14 },
  { label: '更改地址走货', value: 15 },
  { label: '上传付款证明附件走货', value: 16 },
  { label: '其他', value: 17 }
]

// 揽收预约状态
const COLLECT_STATUS = {
  submitFail: {
    val: 'SE',
    ctext: '提交失败',
    etext: 'SUBMITERROR'
  },
  submitSuccess: {
    val: 'S',
    ctext: '提交成功',
    etext: 'SUBMIT'
  },
  assigned: {
    val: 'E',
    ctext: '已分配',
    etext: 'ASSIGNED'
  },
  collect: {
    val: 'C',
    ctext: '已揽收',
    etext: 'COLLECT'
  },
  hand: {
    val: 'H',
    ctext: '已交仓',
    etext: 'HAND'
  },
  warehouse: {
    val: 'W',
    ctext: '仓库已签收',
    etext: 'WAREHOUE'
  },
  abnormal: {
    val: 'A',
    ctext: '揽收异常',
    etext: 'ABNORMAL'
  },
  cancel: {
    val: 'N',
    ctext: '已取消',
    etext: 'CANCEL'
  }
}

// 揽收组包状态
const PACKAGE_STATUS = {
  draft: {
    val: 'D',
    ctext: '草稿',
    etext: 'DRAFT'
  },
  submitFail: {
    val: 'SE',
    ctext: '提交失败',
    etext: 'SUBMITERROR'
  },
  submitSuccess: {
    val: 'S',
    ctext: '提交成功',
    etext: 'SUBMIT'
  },
  assigned: {
    val: 'E',
    ctext: '已分配',
    etext: 'ASSIGNED'
  },
  collect: {
    val: 'C',
    ctext: '已揽收',
    etext: 'COLLECT'
  },
  hand: {
    val: 'H',
    ctext: '已交仓',
    etext: 'HAND'
  },
  warehouse: {
    val: 'W',
    ctext: '仓库已签收',
    etext: 'WAREHOUE'
  },
  abnormal: {
    val: 'A',
    ctext: '揽收异常',
    etext: 'ABNORMAL'
  },
  cancel: {
    val: 'N',
    ctext: '已取消',
    etext: 'CANCEL'
  }
}

// 揽收袋子状态
const BAG_STATUS = [
  'D', // 草稿
  'R', // 预约
  'S', // 已提交
  'SI', // 提交中
  'SE', // 提交失败
  'C', // 已揽收
  'H', // 已交仓
  'N', // 已取消
  'EOther' // 异常（上面状态之外的都属于异常）
]

// 服务等级
const PK_ServiceLevel = [
  // P 优先 ，S 标准， E 经济， T 特惠，C超级经济
  { label: '优先', value: 'P' },
  { label: '标准', value: 'S' },
  { label: '经济', value: 'E' },
  { label: '特惠', value: 'T' },
  { label: '超级经济', value: 'C' }
]

// 可跟踪
const IS_TRACK = [
  { label: '无跟踪', value: '0' },
  { label: '全跟踪', value: '1' },
  { label: '半跟踪', value: '2' }
]

const tryPath =
  process.env.NODE_ENV === 'production'
    ? 'https://b.4px.com/dist?from=dss'
    : 'https://b.test.4px.com/dist?from=dss'

export {
  DECLARE_UNIT_CODE,
  STATUS_FLAG,
  ORDER_STATUS,
  INSURANCE_TYPE,
  GOODS_TYPE,
  EXPRESS,
  STANDARD_REPLY,
  COLLECT_STATUS,
  PACKAGE_STATUS,
  BAG_STATUS,
  PK_ServiceLevel,
  IS_TRACK,
  OrderStatusEnum,
  tryPath,
  InsuranceStatusEnum
}
