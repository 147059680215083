export default [
  // 服务介绍
  {
    path: '/serverIntor',
    name: 'serverIntor',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "distribution" */ '@v/distribution/serverIntor.vue'
      )
  },
  // 分销模式
  {
    path: '/distributionModule',
    name: 'distributionModule',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "distribution" */ '@v/distribution/distributionModule.vue'
      )
  },
  // 返佣中心
  {
    path: '/distriString',
    name: 'distriString',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "distribution" */ '@v/distribution/distriString.vue'
      )
  },
  // 订单中心
  {
    path: '/distrOrder',
    name: 'distrOrder',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "distribution" */ '@v/distribution/distrOrder.vue'
      )
  },
  // 分销模式
  {
    path: '/distributionFX',
    name: 'distributionFX',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "distribution" */ '@v/distribution/distributionFX.vue'
      )
  },
  // 拉新模式
  {
    path: '/distributionLX',
    name: 'distributionLX',
    meta: {
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "distribution" */ '@v/distribution/distributionLX.vue'
      )
  }
]
