<template>
  <div>
    <div v-if="!hideTitle" class="recommend-tit-box">
      <div class="recommend-tit">推荐产品</div>
    </div>
    <div class="recommend-product">
      <template v-for="(item, pIndex) in recommendList">
        <!-- :href="
            item.redirectUrl
              ? item.redirectUrl
              : `${linkPrefix}/productDetails?productCode=${item.productCode}`
          " -->
        <div :key="pIndex" target="_blank" class="recom-item" @click="goDetail(item, $event)" v-if="pIndex < showNum"
          :style="{
            width: showNum === 3 ? 'calc(33.3% - 72px)' : 'calc(25% - 12px)'
          }">
          <div v-if="!item.picUrl" class="recom-top-box">
            <div class="name" :style="{ width: item.tagType ? 'calc(100% - 50px)' : '100%)' }">
              <a-popover>
                <template #content>
                  {{
                  item.customTitle
                  ? item.customTitle
                  : `${item.productName || '-'}(${item.productCode || '-'})`
                  }}
                </template>
                <span>{{
                  item.customTitle
                  ? item.customTitle
                  : `${item.productName || '-'}(${item.productCode || '-'})`
                  }}
                </span>
              </a-popover>
            </div>
            <div class="re-tag" :class="{
                're-tag-p': item.tagType == '限时促销',
                're-tag-n': item.tagType == '新品上线'
              }" v-if="item.tagType">
              {{ item.tagType }}
            </div>
          </div>
          <div v-if="!item.picUrl" class="recom-box">
            <div v-if="item.copyWriting" class="recom-des-item">
              <i class="iconfont iconduihao"></i>
              <!-- <a-popover >
                <template #content>
                  <div style="width:600px">{{ item.copyWriting || '-' }}</div>
                </template>
                <span>{{ item.copyWriting || '-' }}</span>
              </a-popover> -->
              {{ item.copyWriting || '-' }}
            </div>
            <div class="recom-des-item">
              <i v-if="item.labelCodeList.length > 0" class="iconfont iconduihao"></i><span
                v-for="(tItem, tIndex) in item.labelCodeList" :key="tIndex">「{{ tItem }}」</span>
            </div>
          </div>
          <div class="recom-bg" v-if="item.picUrl" :style="{
              'background-image': `url(${item.picUrl})`
            }"></div>

          <div v-if="item.duration || item.price" class="price-box" :class="{ 'no-border': item.picUrl }">
            <div class="price-item">
              <div v-if="item.duration" class="num-data">
                <span class="num-text">{{ item.duration || '-' }}</span>
                <span class="unit">天</span>
              </div>
              <div v-else class="unit">暂无预估时效</div>
            </div>
            <div class="price-item">
              <div class="num-data">
                <span v-if="item.price" class="blue num-text">{{
                  item.price | toMoney
                  }}</span>
                <span v-if="item.price" class="unit">元/KG起</span>
                <span v-else class="unit">暂无预估价格</span>

                <span v-if="item.priceReduction" class="price-reduction"><i class="iconfont iconshujuxiajiang"></i>{{
                  item.priceReduction }}</span>
              </div>
            </div>
          </div>
          <div v-else class="price-box" :class="{ 'no-border': item.picUrl }">
            <div class="no-price-text">该产品线路暂无预估时效价格</div>
          </div>
        </div>
      </template>
      <div @click="goProductService" v-if="showNum === 3" class="more-btn">
        <i class="iconfont icongengduochanpin"></i>
        <div class="text">更多产品</div>
      </div>
      <a-empty class="empty-box" v-if="recommendList.length < 1 && showNum !== 3" :image="emptyImage" :image-style="{
          width: '180px',
          height: '140px'
        }" description="产品上架中…敬请期待" v-cloak></a-empty>
    </div>
  </div>
</template>
<script>
import sensors from 'sa-sdk-javascript'

export default {
  props: {
    hideTitle: {
      type: Boolean,
      default: false
    },
    showNum: {
      type: Number,
      default: 4
    },
    fliterProductCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      emptyImage: require('../../../assets/newIndex/no_data.png'),
      recommendList: []
    }
  },
  computed: {
    linkPrefix() {
      let url = ''
      if (process.env.NODE_ENV == 'production') {
        url = '/dist'
      }
      return url
    }
  },
  created() {
    this.getSuggestProduct()
  },
  mounted() {},
  methods: {
    goProductService() {
      this.$router.push({
        name: 'productService'
      })
    },
    goDetail(item, event) {
      const path = location.pathname.split('/')
      const currRoute = path[path.length - 1]
      sensors.track('HomeProductClick', {
        ...event?.target,
        recommend_type:
          currRoute == 'productService' ? '产品服务-推荐产品' : '推荐产品', //	页面类型
        product_code: item.productCode,
        product_name: item.productName
      })
      // 跳转新窗口
      if (item.redirectUrl) {
        window.open(item.redirectUrl)
      } else {
        let countrParams = ''
        if (item.designCountryCode && item.designCountryName) {
          countrParams = `&country=${item.designCountryCode}&countryName=${item.designCountryName}`
        }
        window.open(
          `${this.linkPrefix}/productDetails?productCode=${item.productCode}${countrParams}`
        )
      }
    },
    getSuggestProduct() {
      this.$show()
      this.$get('v2/homepage/querySuggestProduct').then(res => {
        this.$hide()
        if (res.length > 0) {
          if (this.fliterProductCode) {
            this.recommendList = (res || []).filter(item => {
              return (
                item.productCode.toUpperCase() !=
                this.fliterProductCode.toUpperCase()
              )
            })
          } else {
            this.recommendList = res || []
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
* {
  caret-color: transparent;
}
.empty-box {
  margin: 30px auto !important;
}
.recommend-product {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.more-btn {
  // margin-left: auto;
  width: 178px;
  height: 164px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3960df;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s;
  animation: all 0.2s;
  &:hover {
    margin-top: -4px;
    background: #ffffff;
    border: 2px solid rgba(57, 96, 223, 1);
    box-shadow: 2px 2px 16px 0px rgba(57, 96, 223, 0.35);
    transform: scale(1.02);
  }
  .iconfont {
    margin-bottom: 8px;
    font-size: 32px;
  }
}
.recom-item {
  position: relative;
  display: block;
  margin-right: 16px;
  width: calc(25% - 12px);
  height: 168px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  // box-shadow: 2px 2px 16px 0 rgba(57, 96, 223, 0.35);
  transition: all 0.2s;
  animation: all 0.2s;
  &:hover {
    margin-top: -4px;
    background: #ffffff;
    border: 2px solid rgba(57, 96, 223, 1);
    box-shadow: 2px 2px 16px 0px rgba(57, 96, 223, 0.35);
    transform: scale(1.02);
  }
  &:nth-last-child(1) {
    margin-right: 0;
  }

  .recom-top-box {
    position: relative;
    flex-direction: column;
    align-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
    background-image: linear-gradient(135deg, #3960df 0%, #3e83ff 100%);
    box-shadow: 4px 4px 16px 0 rgba(144, 155, 190, 0.13);

    .name {
      font-size: 16px;
      color: #ffffff;
      width: calc(100% - 50px);
      // line-height: 16px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .des {
      margin-top: 12px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
    }
    .re-tag {
      position: absolute;
      right: 0px;
      top: 10px;
      width: 76px;
      height: 28px;
      line-height: 28px;
      background: url('../../../assets/newIndex/tag_g.png');
      background-size: cover;
      padding-right: 6px;
      box-sizing: border-box;
      text-align: right;
      font-size: 12px;
      color: #916300;
      font-weight: bold;
    }
    .re-tag-p {
      background: url('../../../assets/newIndex/tag_p.png');
      background-size: cover;
      color: #b23700 !important;
    }
    .re-tag-n {
      background: url('../../../assets/newIndex/tag_n.png');
      background-size: cover;
      color: #b23700 !important;
    }
  }
  .recom-box {
    padding: 0 20px;
    box-sizing: border-box;
    height: 70px;
    border-top: 1px dashed transparent;
    .recom-des-item {
      margin-top: 12px;
      width: 100%;
      box-sizing: border-box;
      display: inline-block;
      align-content: center;
      font-size: 14px;
      color: #545d75;
      line-height: 14px;
      // 超出省略号
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .iconfont {
        margin-right: 6px;
        font-size: 10px;
        color: #3960df;
      }
    }
  }
  .recom-bg {
    width: 100%;
    height: 118px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .no-border {
    border-top: 0 !important;
  }
  .price-box {
    // margin-top: 15px;
    padding: 15px 20px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    color: #545d75;
    line-height: 18px;
    border-top: 1px dashed #dadfe8;

    .price-item {
      // width: 40%;
      &:nth-last-child(1) {
        flex: 1;
        text-align: right;
      }
    }
    .no-price-text {
      font-size: 14px;
      color: #545d75;
      line-height: 14px;
    }
    .num-text {
      font-weight: bold;
    }
    .blue {
      color: #3960df;
    }
    .unit {
      margin-left: 8px;
      font-size: 12px;
      color: #323643;
      line-height: 12px;
      font-weight: bold;
    }
    .price-reduction {
      font-size: 12px;
      color: #f94e01;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: bold;
      .iconfont {
        margin-left: 6px;
        margin-right: 2px;
        font-size: 12px;
        color: #f94e01;
      }
    }
  }

  // 小于1440的窗口.num-text 文字的大小设置成16px
  @media screen and (max-width: 1440px) {
    .num-text {
      font-size: 18px !important;
    }
    .price-reduction {
      transform: scale(0.7) !important;
    }
  }
}
</style>
