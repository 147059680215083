export default [
  // 海运FBA委托单列表
  {
    path: '/fbaList',
    name: 'fbaList',
    meta: {
      ignore: true,
      title: 'FBA委托单列表'
    },
    component: () =>
      import(/* webpackChunkName: "fbaList" */ '../views/fba/order/List')
  },
  {
    path: '/fbaCreate',
    name: 'fbaCreate',
    meta: {
      ignore: true,
      title: '创建FBA委托单'
    },
    component: () =>
      import(/* webpackChunkName: "fbaCreate" */ '../views/fba/order/Create')
  },
  {
    path: '/fbaDetails',
    name: 'fbaDetails',
    meta: {
      ignore: true
    },
    component: () =>
      import(/* webpackChunkName: "fbaDetails" */ '../views/fba/order/Details')
  }
]
