<!--
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-08-28 17:40:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-22 21:47:39
-->
<template>
  <div class="home-index">
    <div class="index-row">
      <!-- 业务看板 -->
      <BusinessKanban />
      <!-- 最新公告 -->
      <Announcement />
    </div>
    <!-- 新版dss 首页   -->
    <dssIndex
      v-if="!this.$store.getters.getFB4Open && this.$store.getters.getDSSOpen"
    ></dssIndex>
    <!-- 旧版首页 -->
    <div v-else class="index-row" v-for="board in boardData" :key="board.id">
      <component
        class="item-component flex-column-center"
        :isHomePage="true"
        v-for="(item, i) in board.moduleList"
        :key="i"
        :is="item.spectacularsName"
      />
    </div>

    <!-- <FeedBack /> -->
    <div class="index-footer">
      <p class="footer-com">{{ $t('index_tran_034') }}</p>
      <p class="footer-right">© 2004-2021</p>
    </div>
    <collection-popup></collection-popup>
    <!-- 调查问券弹窗 -->
    <!-- <SurveyPopup /> -->
    <!-- <ScorePopup /> -->
    <OpenFlow />
  </div>
</template>

<script>
import BusinessKanban from './businessKanban/Index'
import Announcement from './announcement/Announcement'
import Instock from './instock/Instock'
import Ad from './ad/Ad'
import Statistics from './statistics/Statistics'
import Tool from './tool/Tool'
import Customer from './customer/Customer'
import StoreTime from './storeTime/StoreTime'
import CollectionPopup from './collectionPopup/CollectionPopup'
// import ScorePopup from './popup/ScorePopup'
// import SurveyPopup from './popup/SurveyPopup'
// import FeedBack from './feedback/index.vue'
// 旧版试算
// import Freight from '../serviceCenter/selfService/components/Freight'
// 新版试算
import Freight from '../serviceCenter/dssFreight/index'
import whRent from '../serviceCenter/selfService/components/whRent'
import idReport from '@v/dataCenter/inventory/components/idReport'
import outFul from '@v/dataCenter/order/components/outFul'
import ab from '@v/dataCenter/order/components/ab'
import inboundFulfillment from '@v/dataCenter/order/inboundFulfillment'
import OpenFlow from './overseasCertfication/OpenFlow'
// 直发首页
import dssIndex from './dssIndex/Index.vue'

export default {
  name: 'home-index',
  components: {
    CollectionPopup,
    BusinessKanban,
    Announcement,
    // 全球库存分析
    Instock,
    // 营销位
    Ad,
    // 单量统计分析
    Statistics,
    // 查询工具
    Tool,
    // 客服热线
    Customer,
    // 库龄分析
    StoreTime,
    // 运费试算
    Freight,
    // 仓租试算
    whRent,
    // 库存诊断
    idReport,
    // 尾程履行
    outFul,
    // 头程履行
    inboundFulfillment,
    // 异常订单
    ab,
    // FeedBack,
    // SurveyPopup,
    // ScorePopup,
    OpenFlow,
    dssIndex
  },
  data() {
    return {
      boardData: [
        {
          id: 1,
          moduleList: [
            {
              spectacularsName: 'Instock',
              spectacularsId: 1
            },
            {
              spectacularsName: 'Ad',
              spectacularsId: 2
            }
          ]
        },
        {
          id: 2,
          moduleList: [
            {
              spectacularsName: 'Statistics',
              spectacularsId: 3
            },
            {
              spectacularsName: 'Tool',
              spectacularsId: 4
            }
          ]
        },
        {
          id: 3,
          moduleList: [
            {
              spectacularsName: 'StoreTime',
              spectacularsId: 5
            },
            {
              spectacularsName: 'Customer',
              spectacularsId: 6
            }
          ]
        }
      ]
    }
  },
  created() {
    this.getModuleConfig()
    this.checkUserStatus()
  },
  mounted() {},
  methods: {
    checkUserStatus() {
      this.$get('v2/account/iscancel').then(res => {
        if (res === true) {
          sessionStorage.clear()
          location.href = '/logout'
        }
      })
    },
    getModuleConfig() {
      this.$post('v2/homePageConfig/queryUserMenuConfig').then(res => {
        if (res && res.homePageJson) {
          this.boardData = JSON.parse(res.homePageJson)
        }
      })
    }
  }
}
</script>

<style lang="less">
.home-index {
  position: relative;
  box-sizing: border-box;
  padding-top: 30px;
  .index-row {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  .index-title {
    height: 18px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 900;
    color: #000000;
    line-height: 18px;
    display: inline-block;
  }
  .b-more {
    cursor: pointer;
    font-size: 12px;
    color: #999;
    float: right;
  }
  .index-footer {
    height: 67px;
    background: #ffffff;
    margin-top: 24px;
    .footer-com {
      text-align: center;
      padding-top: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
    }
    .footer-right {
      text-align: center;
      font-size: 12px;
      font-family: Helvetica;
      color: #666666;
      line-height: 12px;
      margin-top: 11px;
      margin-bottom: 14px;
    }
  }
}
</style>
