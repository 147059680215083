<i18n>
{
  "en": {
    "SC.warehouseRent": "Trial calculation of warehouse rent",
    "SC.warehouseRent.Warehouse": "Warehouse",
    "SC.warehouseRent.TotalSKUvolume": "Total SKU volume(m³)",
    "SC.warehouseRent.Totaldaysofstorageage": "Total days of storage age",
    "SC.warehouseRent.input": "Please enter",
    "SC.warehouseRent.Currentbillingdays": "Current billing days",
    "SC.warehouseRent.trial": "trial",
    "SC.warehouseRent.Warmtips": "Warm tips: the trial results are for reference only, and the final cost is subject to the actual situation of the goods.",
    "SC.warehouseRent.Pleaseselectwarehouse": "Please select warehouse",
    "SC.warehouseRent.positivenumber": "Please enter a positive number with no more than 3 decimal places",
    "SC.warehouseRent.Pleasefillinpositiveinteger": "Please fill in positive integer",
    "SC.warehouseRent.Cannotbegreaterthanthetotalstockagedays": "Cannot be greater than the total stock age days",
    "SC.warehouseRent.Sorrynotrialresults": "Sorry, no trial results!",
    "SC.warehouseRent.More": "more",
    "SC.warehouseRent.Cargotype": "Cargo Type",
    "SC.warehouseRent.Pleaseselectcargotype": "Please select cargo type",
    "SC.warehouseRent.Cargotype.T25": "Small pieces",
    "SC.warehouseRent.Cargotype.T50": "Middleware",
    "SC.warehouseRent.Cargotype.T120": "Bulky",
    "SC.warehouseRent.Cargotype.T240": "Huge",
    "SC.warehouseRent.Cargotype.TM": "Oversize",
    "SC.warehouseRent.Cargotype.Detail.type": "SKU type",
    "SC.warehouseRent.Cargotype.Detail.abbreviation": "abbreviation",
    "SC.warehouseRent.Cargotype.Detail.weight": "weight(kg)",
    "SC.warehouseRent.Cargotype.Detail.long": "long(cm)",
    "SC.warehouseRent.Cargotype.Detail.width": "width(cm)",
    "SC.warehouseRent.Cargotype.Detail.high": "high(cm)",
    "SC.warehouseRent.Cargotype.Detail.desc": "Measured by commodity logistics packaging, if the length, width, height and weight all meet the specification requirements, it is within the scope of the corresponding cargo type. If one of the four items is exceeded, the goods type is defined in the interval where the exceeded item is located. For example, the commodity weight is 10kg, the length is 250cm, the width is 20cm, and the height is 20cm. Because the length is greater than 240cm, it belongs to TM extra large."
  },
  "de": {
    "SC.warehouseRent": "Trial calculation of warehouse rent",
    "SC.warehouseRent.Warehouse": "Warehouse",
    "SC.warehouseRent.TotalSKUvolume": "Total SKU volume(m³)",
    "SC.warehouseRent.Totaldaysofstorageage": "Total days of storage age",
    "SC.warehouseRent.input": "Please enter",
    "SC.warehouseRent.Currentbillingdays": "Current billing days",
    "SC.warehouseRent.trial": "trial",
    "SC.warehouseRent.Warmtips": "Warm tips: the trial results are for reference only, and the final cost is subject to the actual situation of the goods.",
    "SC.warehouseRent.Pleaseselectwarehouse": "Please select warehouse",
    "SC.warehouseRent.positivenumber": "Please enter a positive number with no more than 3 decimal places",
    "SC.warehouseRent.Pleasefillinpositiveinteger": "Please fill in positive integer",
    "SC.warehouseRent.Cannotbegreaterthanthetotalstockagedays": "Cannot be greater than the total stock age days",
    "SC.warehouseRent.Sorrynotrialresults": "Sorry, no trial results!",
    "SC.warehouseRent.More": "more",
    "SC.warehouseRent.Cargotype": "Cargo Type",
    "SC.warehouseRent.Pleaseselectcargotype": "Please select cargo type",
    "SC.warehouseRent.Cargotype.T25": "Small pieces",
    "SC.warehouseRent.Cargotype.T50": "Middleware",
    "SC.warehouseRent.Cargotype.T120": "Bulky",
    "SC.warehouseRent.Cargotype.T240": "Huge",
    "SC.warehouseRent.Cargotype.TM": "Oversize",
    "SC.warehouseRent.Cargotype.Detail.type": "SKU type",
    "SC.warehouseRent.Cargotype.Detail.abbreviation": "abbreviation",
    "SC.warehouseRent.Cargotype.Detail.weight": "weight(kg)",
    "SC.warehouseRent.Cargotype.Detail.long": "long(cm)",
    "SC.warehouseRent.Cargotype.Detail.width": "width(cm)",
    "SC.warehouseRent.Cargotype.Detail.high": "high(cm)",
    "SC.warehouseRent.Cargotype.Detail.desc": "Measured by commodity logistics packaging, if the length, width, height and weight all meet the specification requirements, it is within the scope of the corresponding cargo type. If one of the four items is exceeded, the goods type is defined in the interval where the exceeded item is located. For example, the commodity weight is 10kg, the length is 250cm, the width is 20cm, and the height is 20cm. Because the length is greater than 240cm, it belongs to TM extra large."
  },
  "ja": {
    "SC.warehouseRent": "Trial calculation of warehouse rent",
    "SC.warehouseRent.Warehouse": "Warehouse",
    "SC.warehouseRent.TotalSKUvolume": "Total SKU volume(m³)",
    "SC.warehouseRent.Totaldaysofstorageage": "Total days of storage age",
    "SC.warehouseRent.input": "Please enter",
    "SC.warehouseRent.Currentbillingdays": "Current billing days",
    "SC.warehouseRent.trial": "trial",
    "SC.warehouseRent.Warmtips": "Warm tips: the trial results are for reference only, and the final cost is subject to the actual situation of the goods.",
    "SC.warehouseRent.Pleaseselectwarehouse": "Please select warehouse",
    "SC.warehouseRent.positivenumber": "Please enter a positive number with no more than 3 decimal places",
    "SC.warehouseRent.Pleasefillinpositiveinteger": "Please fill in positive integer",
    "SC.warehouseRent.Cannotbegreaterthanthetotalstockagedays": "Cannot be greater than the total stock age days",
    "SC.warehouseRent.Sorrynotrialresults": "Sorry, no trial results!",
    "SC.warehouseRent.More": "more",
    "SC.warehouseRent.Cargotype": "Cargo Type",
    "SC.warehouseRent.Pleaseselectcargotype": "Please select cargo type",
    "SC.warehouseRent.Cargotype.T25": "Small pieces",
    "SC.warehouseRent.Cargotype.T50": "Middleware",
    "SC.warehouseRent.Cargotype.T120": "Bulky",
    "SC.warehouseRent.Cargotype.T240": "Huge",
    "SC.warehouseRent.Cargotype.TM": "Oversize",
    "SC.warehouseRent.Cargotype.Detail.type": "SKU type",
    "SC.warehouseRent.Cargotype.Detail.abbreviation": "abbreviation",
    "SC.warehouseRent.Cargotype.Detail.weight": "weight(kg)",
    "SC.warehouseRent.Cargotype.Detail.long": "long(cm)",
    "SC.warehouseRent.Cargotype.Detail.width": "width(cm)",
    "SC.warehouseRent.Cargotype.Detail.high": "high(cm)",
    "SC.warehouseRent.Cargotype.Detail.desc": "Measured by commodity logistics packaging, if the length, width, height and weight all meet the specification requirements, it is within the scope of the corresponding cargo type. If one of the four items is exceeded, the goods type is defined in the interval where the exceeded item is located. For example, the commodity weight is 10kg, the length is 250cm, the width is 20cm, and the height is 20cm. Because the length is greater than 240cm, it belongs to TM extra large."
  },
  "zh": {
    "SC.warehouseRent": "仓租试算",
    "SC.warehouseRent.Warehouse": "仓库",
    "SC.warehouseRent.TotalSKUvolume": "总SKU体积(m³)",
    "SC.warehouseRent.Totaldaysofstorageage": "库龄总天数",
    "SC.warehouseRent.input": "请输入",
    "SC.warehouseRent.Currentbillingdays": "本期计费天数",
    "SC.warehouseRent.trial": "试算",
    "SC.warehouseRent.Warmtips": "温馨提示: 试算结果仅供参考，最终费用以货物实际情况为准。",
    "SC.warehouseRent.Pleaseselectwarehouse": "请选择仓库",
    "SC.warehouseRent.positivenumber": "请输入正数且小数点不超过3位",
    "SC.warehouseRent.Pleasefillinpositiveinteger": "请填入正整数",
    "SC.warehouseRent.Cannotbegreaterthanthetotalstockagedays": "不能大于库龄总天数",
    "SC.warehouseRent.Sorrynotrialresults": "抱歉无试算结果!",
    "SC.warehouseRent.More": "更多",
    "SC.warehouseRent.Cargotype": "货型",
    "SC.warehouseRent.Pleaseselectcargotype": "请选择货型",
    "SC.warehouseRent.Cargotype.T25": "小件",
    "SC.warehouseRent.Cargotype.T50": "中件",
    "SC.warehouseRent.Cargotype.T120": "大件",
    "SC.warehouseRent.Cargotype.T240": "超大件",
    "SC.warehouseRent.Cargotype.TM": "特大件",
    "SC.warehouseRent.Cargotype.Detail.type": "SKU货型",
    "SC.warehouseRent.Cargotype.Detail.abbreviation": "简称",
    "SC.warehouseRent.Cargotype.Detail.weight": "重量(kg)",
    "SC.warehouseRent.Cargotype.Detail.long": "长(cm)",
    "SC.warehouseRent.Cargotype.Detail.width": "宽(cm)",
    "SC.warehouseRent.Cargotype.Detail.high": "高(cm)",
    "SC.warehouseRent.Cargotype.Detail.desc": "以商品物流包装测量，长、宽、高、重量全部满足规格要求，则在对应货型范围内。如果4项中有一项超出，超出的项目所在区间定义货型。例如：商品重量10kg，长250cm，宽20cm，高20cm，由于长度大于240cm，属于TM特大件。"
  }
}
</i18n>

<template>
  <div class="page-content" :class="{ isHomePage: isHomePage }">
    <p v-if="!isHomePage" class="page-title">{{ $t('SC.warehouseRent') }}</p>
    <div v-else :style="{ marginBottom: '20px' }">
      <div class="index-title">
        {{ $t('SC.warehouseRent') }}
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: this.$t('SC.warehouseRent') }
              })
            }
          "
        />
      </div>
      <div class="b-more" @click="goDetail">
        {{ $t('SC.warehouseRent.More') }}
        <div class="arrow-right"></div>
      </div>
    </div>
    <div class="page-card">
      <a-form-model
        class="query-item"
        ref="ruleForm"
        :layout="formLayout"
        :model="form"
        :rules="rules"
      >
        <div class="query-item flex-start">
          <a-form-model-item
            :label="$t('SC.warehouseRent.Warehouse')"
            ref="warehouseName"
            prop="warehouseName"
            :colon="false"
          >
            <a-select
              show-search
              :placeholder="$t('select')"
              option-filter-prop="children"
              style="width:200px"
              :filter-option="filterOption"
              @change="(value, option) => onPlaceChange(value, option)"
              v-model="form.warehouseName"
            >
              <a-select-option
                v-for="(item, k) in FB4Warehouses"
                :key="k"
                :value="item.warehouseCode"
                >{{ item.warehouseName }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            :label="$t('SC.warehouseRent.TotalSKUvolume')"
            ref="totalSKUVolume"
            prop="totalSKUVolume"
            :colon="false"
          >
            <a-input
              v-model="form.totalSKUVolume"
              style="width:200px"
              :placeholder="$t('SC.warehouseRent.input')"
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item
            :label="$t('SC.warehouseRent.Totaldaysofstorageage')"
            ref="totalAgeDays"
            prop="totalAgeDays"
            :colon="false"
          >
            <a-input
              v-model="form.totalAgeDays"
              style="width:200px"
              :placeholder="$t('SC.warehouseRent.input')"
              @change="onTotalAgeDays"
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item
            :label="$t('SC.warehouseRent.Currentbillingdays')"
            ref="currentBillingDays"
            prop="currentBillingDays"
            :colon="false"
          >
            <a-input
              v-model="form.currentBillingDays"
              style="width:200px"
              :placeholder="$t('SC.warehouseRent.input')"
            >
            </a-input>
          </a-form-model-item>
          <div class="cargotype-wrap">
            <span slot="label" class="cargotype-label">
              {{ $t('SC.warehouseRent.Cargotype') }}&nbsp;
              <a-icon type="question-circle-o" @click="cargotypeDetail" />
            </span>
            <!-- :label="$t('SC.warehouseRent.Cargotype')" -->
            <a-form-model-item
              label=" "
              ref="sizeType"
              prop="sizeType"
              :colon="false"
            >
              <a-select
                show-search
                :placeholder="$t('select')"
                option-filter-prop="children"
                style="width:214px"
                :filter-option="filterOption"
                @change="(value, option) => onSizeTypeChange(value, option)"
                v-model="form.sizeType"
              >
                <a-select-option
                  v-for="(item, k) in cargotypeLists"
                  :key="k"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </div>
          <a-form-item class="query-handle">
            <a-space>
              <a-button type="primary" @click="query">
                {{ $t('SC.warehouseRent.trial') }}
              </a-button>
            </a-space>
          </a-form-item>
        </div>
      </a-form-model>
    </div>
    <div class="page-card">
      <div class="card-header flex-sp">
        <p class="card-title">
          <span>{{ $t('detailed.data') }}</span>
        </p>
      </div>
      <div style="height: 15px"></div>
      <a-alert
        :show-icon="false"
        closable
        :message="$t('SC.warehouseRent.Warmtips')"
        banner
      />

      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
      >
        <span slot="trialPrice" slot-scope="text, record">
          <div v-for="(i, k) in record.arr" :key="k">
            {{ i.trialPrice }}
          </div>
        </span>

        <span slot="billingPrice" slot-scope="text, record">
          <div v-for="(i, k) in record.arr" :key="k">
            {{ i.billingPrice }}
          </div>
        </span>

        <span slot="segmentBillingDays" slot-scope="text, record">
          <div v-for="(i, k) in record.arr" :key="k">
            {{ i.segmentBillingDays }}
          </div>
        </span>

        <span slot="subleaseAmount" slot-scope="text, record">
          <div v-for="(i, k) in record.arr" :key="k">
            {{ i.subleaseAmount ? Number(i.subleaseAmount).toFixed(2) : '' }}
          </div>
        </span>
      </a-table>
    </div>
    <a-modal
      v-model="visible"
      title="货型描述"
      centered
      :width="750"
      :footer="null"
    >
      <p class="cargotype-desc">
        {{ $t('SC.warehouseRent.Cargotype.Detail.desc') }}
      </p>
      <a-table
        :columns="cargotypeColumns"
        :data-source="cargotypeDatasource"
        :pagination="false"
      ></a-table>
    </a-modal>
  </div>
</template>

<script>
import {
  getWarehouseRentColumns,
  getMenuData,
  getCargotypeLists,
  cargotypeColumns,
  cargotypeData
} from '../../servicecenter.data.js'
import moment from 'moment'

export default {
  props: {
    isHomePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: [],
      columns: this.$columnsI18n(getWarehouseRentColumns(this), this),
      formLayout: 'vertical',
      form: {
        warehouseName: '',
        warehouseCode: '',
        totalSKUVolume: '',
        totalAgeDays: '',
        currentBillingDays: '',
        sizeType: ''
      },
      dateFormat: 'YYYY/MM/DD',
      currencyArr: [],
      brandList: [],
      pagination: {
        ...this.$pagination()
      },
      loading: false,
      FB4Warehouses: [],
      rules: {
        warehouseName: [
          {
            required: true,
            message: this.$t('SC.warehouseRent.Pleaseselectwarehouse'),
            trigger: 'change'
          }
        ],
        totalSKUVolume: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (/^([1-9]\d*(\.[0-9]{1,3})?|0\.\d{1,3})$/.test(value)) {
                callback()
                return
              }
              callback(this.$t('SC.warehouseRent.positivenumber'))
            }
          }
        ],
        totalAgeDays: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('SC.warehouseRent.Pleasefillinpositiveinteger'),
            pattern: /^[1-9][0-9]*$/
          }
        ],
        currentBillingDays: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (!/^[1-9][0-9]*$/.test(value)) {
                callback(
                  this.$t('SC.warehouseRent.Pleasefillinpositiveinteger')
                )
                return
              }
              if (Number(value) > Number(this.form.totalAgeDays)) {
                callback(
                  this.$t(
                    'SC.warehouseRent.Cannotbegreaterthanthetotalstockagedays'
                  )
                )
                return
              }
              callback()
            }
          }
        ],
        sizeType: [
          {
            required: true,
            message: this.$t('SC.warehouseRent.Pleaseselectcargotype'),
            trigger: 'change'
          }
        ]
      },
      cargotypeLists: this.$columnsI18n(getCargotypeLists(this), this),
      visible: false,
      cargotypeColumns: this.$columnsI18n(cargotypeColumns(this), this),
      cargotypeDatasource: this.$columnsI18n(cargotypeData(this), this)
    }
  },
  mounted() {
    getMenuData(this)
    this.getFB4Warehouses()
  },
  methods: {
    moment,
    goDetail() {
      this.$router.push('/warehouseRent')
    },
    onTotalSKUVolumeChange() {},
    //本期计费天数跟着库龄天数变化
    onTotalAgeDays(e) {
      this.form.currentBillingDays = e.target.value
    },
    handleTableChange(pagination) {
      this.pagination = pagination
      this.form.pageNo = pagination.current
      this.form.pageSize = pagination.pageSize
      this.query()
    },
    query() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$post('v2/trail/warehouseRentTrial', {
            billBusinessAttributes: [
              {
                name: 'warehouseCode',
                value: this.form.warehouseCode
              },
              {
                name: 'sizeType',
                value: this.form.sizeType
              }
            ],
            segBillings: [
              {
                segmentOperateCode: 'WHI',
                operateItems: [
                  {
                    operateName: 'rent_standard',
                    operateAttributes: [
                      {
                        name: '1018',
                        unit: 'DAY',
                        value: this.form.totalAgeDays
                      },
                      {
                        name: '1009',
                        unit: 'DAY',
                        value: this.form.currentBillingDays
                      },
                      {
                        name: '1008',
                        unit: 'MTQ',
                        value: this.form.totalSKUVolume
                      }
                    ]
                  }
                ]
              }
            ]
          })
            .then(res => {
              this.data = []
              this.loading = false
              if (res.data.length > 0) {
                res.data.map(e => {
                  e.phasePriceItemDTOs.map(a => {
                    a.phaseMeasureFactorDTOs.map(b => {
                      if (b.measureElementCode === '1018') {
                        this.data.push({
                          key: this.data.length,
                          warehouse: this.form.warehouseName,
                          SKUVolume: this.form.totalSKUVolume,
                          totalDays: this.form.totalAgeDays,
                          billingDays: this.form.currentBillingDays,
                          trialPrice: b.price,
                          billingPrice: b.unitageBegin + '-' + b.unitageEnd,
                          segmentBillingDays: b.billNumber,
                          currency: e.currencyCode,
                          subleaseAmount: a.billingAmount,
                          totalAmount: e.feeAmount
                        })
                      }
                    })
                  })
                })
                this.data = this.data.reduce((x, y) => {
                  if (x.length === 0) {
                    x.push({ ...y, arr: [y] })
                  } else {
                    x[0].arr.push(y)
                  }
                  return x
                }, [])
              } else {
                this.$message.warning(
                  this.$t('SC.warehouseRent.Sorrynotrialresults')
                )
              }

              this.matchingFB4Warehouses()
            })
            .catch(() => (this.loading = false))
        } else {
          return false
        }
      })
    },
    //转换仓库为name
    matchingFB4Warehouses() {
      this.data.map(e => {
        this.FB4Warehouses.map(a => {
          if (e.warehouse === a.warehouseCode) {
            e.warehouse = a.warehouseName
          }
        })
      })
    },
    resetForm() {
      // this.form = { ...defaultForm }
      this.$refs.ruleForm.resetFields()
    },
    getFB4Warehouses() {
      this.$get('v2/common/getFB4Warehouses').then(res => {
        res.map(e => {
          this.FB4Warehouses.push({
            warehouseName: e.warehouseName,
            warehouseCode: e.warehouseCode,
            warehouseNameEn: e.warehouseNameEn
          })
        })
      })
    },
    onPlaceChange(value) {
      this.form.warehouseCode = value
    },
    onSizeTypeChange(value) {
      this.form.sizeType = value
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    cargotypeDetail() {
      this.visible = true
    }
  },
  watch: {}
}
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0;
}
.export {
  color: #333;
  font-weight: 400;
  font-size: 14px;
  span {
    padding-left: 5px;
  }
}
.query-item {
  .ant-form-item {
    margin-right: 24px;
  }
}
.query-handle {
  display: flex;
  height: 73px;
  align-items: flex-end;
  justify-content: flex-end;
}

.isHomePage {
  width: 66.67%;
  height: 332px;
  background: #fff;
  margin-right: 20px;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: auto;
  border-radius: 8px;
}
.cargotype-wrap {
  position: relative;
  .cargotype-label {
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 2;
  }
}
.cargotype-desc {
  line-height: 24px;
  margin-bottom: 16px;
}
</style>
