/*
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-12-10 10:26:35
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-09-10 14:14:16
 */
import * as filters from '@/filters/index'
import * as filtersMicroDss from '@microDss/filter/filter'
import {
  http_get,
  http_post,
  http_post_json,
  jsonp
} from '@microDss/service/http/http'
import '@microDss/util/math.js'
import Antd, { message } from 'ant-design-vue'
import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n/i18n'
import router from './router'
import store from './store'

import '@microDss/styles/common.scss'
import 'ant-design-vue/dist/antd.less'
import apiRef from './api/apiRef'
import { http, httpGet, httpPost } from './api/index'
import './assets/iconfont/slider/iconfont.css'
import './assets/iconfont/slider/iconfont.js'
import './styles/common.less'
import './styles/reset.css'
import './styles/theme.less'

Vue.use(Antd)
Vue.config.productionTip = false

Vue.config.errorHandler = function(err, vm, info) {
  // handle error
  // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
  console.log(err, vm, info)
}

if (process.env.VUE_APP_BUILD_PROD !== 'prod') {
  window.onerror = e => {
    // console.log('e:', e)
  }
}

message.config({
  top: `120px`,
  duration: 5,
  maxCount: 2
})

/**
 * api定义及请求封装
 * get、post、upload、jsonp
 */
Vue.prototype.$api = apiRef
Vue.prototype.$get = httpGet
Vue.prototype.$http = http
Vue.prototype.$post = httpPost

/* micro app dss */
Vue.prototype.$post_dss = http_post
Vue.prototype.$postJson_dss = http_post_json
Vue.prototype.$get_dss = http_get
Vue.prototype.$jsonp_dss = jsonp

/**
 * 统一分页信息
 * 一个应用内分页配置一般相同
 * 需要个性化配置重写即可
 */
Vue.prototype.$pagination = (sizes = ['10', '20', '50', '100']) => {
  return {
    pageSizeOptions: sizes,
    showSizeChanger: true,
    showQuickJumper: true,
    total: 0,
    pageSize: sizes ? +sizes[0] : 10,
    current: 1,
    size: 'medium',
    hideOnSinglePage: false,
    showTotal: (total, range) => {
      const lang = i18n.locale
      return `${range[0]}-${range[1]} ${
        lang == 'zh' || (lang == 'zh') == 'undefined' ? '共' : ' | '
      } ${total} ${
        lang == 'zh' || (lang == 'zh') == 'undefined' ? '条' : i18n.t('total')
      }`
    },
    itemRender(current, type, originalElement) {
      const lang = i18n.locale
      if (type === 'prev') {
        return (
          <a class="flex-center">
            {' '}
            {lang == 'zh' || (lang == 'zh') == 'undefined'
              ? '上一页'
              : i18n.t('servuce_tran_021')}
          </a>
        )
      } else if (type === 'next') {
        return (
          <a class="flex-center">
            {lang == 'zh' || (lang == 'zh') == 'undefined'
              ? '下一页'
              : i18n.t('servuce_tran_022')}
          </a>
        )
      }
      return originalElement
    }
  }
}

/**
 * 图形基本属性配置
 */
Vue.prototype.$defaultChartSettings = {
  tooltip: {
    backgroundColor: '#1F2E55'
  },
  legend: {
    icon: 'circle',
    itemWidth: 10,
    itemHeight: 10
  },
  color: ['#0066FF', '#79CD4B', '#FFB83C', '#8758F6', '#F64681']
}

/**
 * 表头国际化
 * @param {*} columns
 */
Vue.prototype.$columnsI18n = (columns, vm) => {
  if (!columns) return []
  columns.forEach(item => {
    item.title = vm.$t(item.title) || vm.$t(item.dataIndex)
  })
  return columns
}

/* 直发中心表头国际化 */
Vue.prototype.$setColumnsLang = (columns, lang) => {
  if (!columns) return []
  columns.forEach(item => {
    item.title = lang == 'zh' ? item.zh : item.en
  })
  return Object.freeze(columns)
}

/**
 * 注册全局过滤器
 * 用于（全局）格式化显示，组件内特有的定义在组件内即可
 */
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Object.keys(filtersMicroDss).forEach(key => {
  Vue.filter(key, filtersMicroDss[key])
})

// 注册一个全局自定义指令 `v-focus`
Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时，自动获取焦点
  inserted: function(el) {
    // 聚焦元素
    if (el && (el.nodeName === 'INPUT' || el.nodeName === 'TEXTAREA')) {
      el.focus()
    } else {
      const ips = el.getElementsByTagName('input')
      if (ips && ips.length) ips[0].focus()
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  data: {
    // 特殊情况下使用，事件总线
    bus: new Vue(),
    dateFormat: 'YYYY-MM-DD',
    dateFormatTime: 'YYYY-MM-DD HH:mm:ss'
  },
  created() {
    /**
     * loading 使用方式：
     * 涉及到异步请求，需要loading时，使用的正确姿势：
     * 显示：this.$show()
     * 隐藏：this.$hide()
     */
    Vue.prototype.$show = () => {
      this.$store.state.loading = true
    }
    Vue.prototype.$hide = () => {
      this.$store.state.loading = false
    }
  },
  render: h => h(App)
}).$mount('#app')
