var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-index"},[_c('Notify'),_c('Nav',{on:{"collapsed":_vm.onCollapsed}}),(_vm.showSlider)?_c('div',{staticClass:"index-slider",class:!_vm.collapsed ? '' : 'slider-collapsed',style:(`top:${_vm.hasTopBanner ? '84px' : '56px'}`)},[_c('Slider',{attrs:{"collapsed":_vm.collapsed,"menuItems":_vm.menuItems},on:{"collapsed":_vm.onCollapsed}})],1):_vm._e(),_c('div',{class:[
      !_vm.showSlider ? 'no-slider' : 'index-content',
      _vm.frameView ? 'cnt-no-padding' : ''
    ],style:(`${
        _vm.showSlider
          ? !_vm.collapsed
            ? `width: calc(100% - 224px); ${
                _vm.hasTopBanner ? 'paddingTop: 102px' : 'paddingTop: 74px'
              }`
            : `width: calc(100% - 56px); ${
                _vm.hasTopBanner ? 'paddingTop: 102px' : 'paddingTop: 74px'
              }`
          : `${_vm.hasTopBanner ? 'paddingTop: 84px' : 'paddingTop: 56px'}`
      } `)},[_vm._t("default")],2),_c('Minprogramdrainage')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }