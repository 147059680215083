<template>
  <div class="chart-box">
    <canvas id="canvas"></canvas>
    <div class="chart flex-sp">
      <div class="item" v-for="(i, k) in list" :key="k">
        <div
          class="value-bg"
          :style="`height: ${Math.floor((i.value / baseNum) * 200) || 0}px`"
        ></div>
        <div class="tip-box">
          <div class="label flex-start">
            <span>{{ i.label }}</span>
            <a-tooltip
              :arrowPointAtCenter="true"
              :placement="k === list.length - 1 ? 'topRight' : 'top'"
            >
              <template slot="title">
                <div v-html="i.desc"></div>
              </template>
              <em
                class="iconfont iconizhushi"
                style="color: rgba(84, 93, 117, 0.5);font-size: 12px"
              ></em>
            </a-tooltip>
          </div>
          <div class="num">{{ i.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from '@u/throttle.js'
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    baseNum: {
      type: Number,
      default: () => []
    }
  },
  mounted() {
    // handle event
    throttle('resize', 'optimizedResize')

    window.addEventListener('optimizedResize', this.draw)
  },
  methods: {
    /**
     * 获取节点
     */
    getPoints() {
      var topPoints = []
      var bottomPoints = []
      var baseX = 0
      var baseY = 0
      const items = [...document.querySelectorAll('.value-bg')]
      for (let i = 0; i < items.length; i++) {
        const child = items[i]
        const { width, height, x, y } = child.getBoundingClientRect()
        if (i === 0) {
          const parentHeight = child.parentNode.getBoundingClientRect().height
          // 设置基准点
          baseX = x
          baseY = y - (parentHeight - height)
        }
        var topLeft = { x: x - baseX, y: y - baseY }
        var topRight = { x: x - baseX + width, y: y - baseY }
        var bottomLeft = { x: x - baseX, y: y - baseY + height }
        var bottomRight = { x: x - baseX + width, y: y - baseY + height }
        topPoints = [...topPoints, topLeft, topRight]
        bottomPoints = [...bottomPoints, bottomLeft, bottomRight]
      }
      return [...topPoints, ...bottomPoints.reverse()]
    },
    draw() {
      var points = this.getPoints()
      // 使用canvas画背景不规矩图像
      const canvas = document.querySelector('#canvas')
      const context = canvas.getContext('2d')
      const { width, height } = document
        .querySelector('.chart')
        .getBoundingClientRect()
      canvas.width = width
      canvas.height = height
      context.beginPath() //开始绘制线条
      //线条开始位置
      for (let i = 0; i < points.length; i++) {
        var { x, y } = points[i]
        context.lineTo(x, y) //线条经过点
      }
      context.fillStyle = '#E9F2FD'
      context.fill()
    }
  },
  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.draw, false)
  },
  watch: {
    list: {
      handler() {
        // mounted 不会承诺所有子节点都挂载完 所以使用nextTick等待节点都挂载 这样算出来的坐标是正确的
        this.$nextTick(() => {
          this.draw()
        })
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.chart-box {
  position: relative;
  width: 100%;
  .chart {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .item {
    width: 120px;
    height: 200px;
    background: #f7f7f7;
    position: relative;
    .value-bg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(#bcd4ff, #e9f2fd);
    }
    .tip-box {
      position: absolute;
      left: 12px;
      bottom: 14px;
      .label {
        font-size: 14px;
        span {
          padding-right: 4px;
        }
      }
      .num {
        font-size: 24px;
        color: #323643;
        font-weight: 600;
      }
    }
  }
}
</style>
