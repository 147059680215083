<!--
 * @Description: 未开通、审核驳回、已关闭服务
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 10:17:46
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-12-15 09:44:46
-->
<i18n>
{
  "zh": {
    "unvalidate": "未实名认证",
    "validating": "实名认证中",
    "nocurrency": "请选择币种",
    "open": "立即开通",
    "show": "服务介绍",
    "cy": "币种",
    "choseCurrency": "选择币种",
    "brand": "申请品牌",
    "tip1":"您的品牌申请已提交成功！",
    "tip2": "提交后需要1-2个工作日审核，请耐心等待。",
    "tip3": "开通服务前需要进行实名认证，您还没有完成实名认证，是否现在进行实名认证？",
    "tip4": "账号存在重复申请，请等待人工审核通过后再发起申请服务。",
    "tip5": "因您重复申请账号，审核未通过，不可继续申请服务。",
    "sfail": "审核不通过（驳回）",
    "sclose": "已关闭",
    "sunopen": "未开通",
    "cert": "去认证"
  },
  "en": {
    "unvalidate": "Unvalidate",
    "validating": "Real-name authentication in progress",
    "nocurrency": "Please select currency",
    "open": "Open Now",
    "show": "Introduction",
    "cy": "Currency",
    "choseCurrency": "Choose Currency",
    "brand": "Apply for Brand",
    "tip1":"Your brand application has been submitted successfully!",
    "tip2": "It will take 2-3 working days for review after submission, please be patient.",
    "tip3": "Real-name authentication is required before the service is activated. You have not completed the real-name authentication. Do you want to perform the real-name authentication now?",
    "tip4": "There are duplicate applications for account numbers. Please wait for manual approval before initiating the application service.",
    "tip5": "You cannot continue to apply for services because you repeatedly applied for an account and failed to pass the review.",
    "sfail": "The audit failed (rejected)",
    "sclose": "Closed",
    "sunopen": "Unopened",
    "cert": "Go to certification"
  },
  "de": {
    "unvalidate": "Keine Überprüfung des echten Namens",
    "validating": "Überprüfung des echten Namens in Arbeit",
    "nocurrency": "Bitte wählen Sie eine Währung",
    "open": "Jetzt öffnen",
    "show": "Beschreibung der Dienstleistung",
    "cy": "Währung",
    "choseCurrency": "Währung auswählen",
    "brand": "Bewerben Sie sich für eine Marke",
    "tip1": "Ihr Markenantrag wurde erfolgreich eingereicht!",
    "tip2": "Bitte haben Sie etwas Geduld, da die Prüfung nach der Einreichung 1-2 Arbeitstage dauert.",
    "tip3": "Sie haben Ihren echten Namen noch nicht verifiziert, müssen Sie das jetzt tun?",
    "tip4": "Es gibt doppelte Anträge für Ihr Konto. Bitte warten Sie, bis die manuelle Prüfung genehmigt ist, bevor Sie den Antragsservice starten.",
    "tip5": "Da Sie wiederholt ein Konto beantragt haben, wurde die Prüfung nicht genehmigt und Sie können den Dienst nicht weiter beantragen.",
    "sfail": "Audit nicht genehmigt (abgelehnt)",
    "sclose": "Geschlossen",
    "sunopen": "Nicht geöffnet",
    "cert": "Weiter zur Zertifizierung"
  },
  "ja": {
    "unvalidate": "実名確認ができない",
    "validating": "実名検証中",
    "nocurrency": "通貨を選択してください",
    "open": "オープンしました",
    "show": "サービス内容",
    "cy": "通貨",
    "choseCurrency": "通貨を選択する",
    "brand": "ブランドに応募する",
    "tip1": "ブランドアプリケーションは正常に送信されました",
    "tip2": "送信後、審査に1～2営業日かかりますので、しばらくお待ちください。",
    "tip3": "実名認証がまだ完了していませんが、今すぐ行う必要がありますか？",
    "tip4": "お客様のアカウントに重複した申請があります。サービス申請を開始する前に、手動監査が承認されるのをお待ちください。",
    "tip5": "繰り返しアカウントを申請しているため、審査が通らず、継続して申請することができません。",
    "sfail": "監査法人不認可（拒否）",
    "sclose": "クローズド",
    "sunopen": "未開封",
    "cert": "認証のページへ"
  }
}
</i18n>
<template>
  <div class="open-wrapper">
    <!-- 开通服务：服务介绍 -->
    <a-modal
      :title="info.brandName"
      :maskClosable="false"
      @cancel="() => (visibleIntrduce = false)"
      :visible="visibleIntrduce"
      :centered="true"
    >
      <div style="line-height: 28px">
        {{ info.desc }}
        <br />
        <a :href="links[info.brandCode]" target="_blank">{{
          links[info.brandCode]
        }}</a>
      </div>
      <template slot="footer">
        <a-button @click="() => (visibleIntrduce = false)" type="primary">{{
          $t('ok')
        }}</a-button>
      </template>
    </a-modal>

    <!-- 开通服务：选择币种 旧逻辑-->
    <!-- :title="!hasChoose ? $t('choseCurrency') : ''" -->
    <a-modal
      :maskClosable="false"
      @cancel="() => (visibleCurrency = false)"
      :visible="visibleCurrency"
      :centered="true"
      width="380px"
      height="218px"
    >
      <!-- 隐藏选择币种 直接提交 -->
      <!-- <div class="control-label" v-if="!hasChoose">
        <div class="lbl">{{ $t('cy') }}</div>
        <a-select style="width: 275px" v-model="selecedCurrency" mode="tags">
          <a-select-option
            :value="item.value"
            :key="item.value"
            v-for="item in currency"
          >
            {{ item.name + '(' + item.value + ')' }}
          </a-select-option>
        </a-select>
      </div> -->
      <!-- v-else -->
      <div class="flex-center-h choose-msg">
        <div><a-icon type="check-circle" theme="filled" class="ok" /></div>
        <div class="success-msg">
          {{ $t('index_tran_004') }}{{ info.brandName
          }}{{ $t('index_tran_005') }}
        </div>
        <div class="sell-msg">
          {{ $t('index_tran_001')
          }}<a @click="checkProgress">{{ $t('index_tran_002') }}</a>
          {{ $t('index_tran_003') }}
        </div>
      </div>
      <template slot="footer">
        <!-- :class="hasChoose ? '' : 'select-btn'" -->
        <a-button type="primary" @click="submitOpenData">
          <!-- {{ hasChoose ? $t('我知晓了') : $t('提交') }} -->
          {{ $t('sure') }}
        </a-button>
      </template>
    </a-modal>
    <!-- 新逻辑- 大陆用户走-自动发起合同签署 -->
    <ContractSigning
      :info="info"
      :visible="newVisibleCurrency"
      @cancel="newVisibleCurrency = false"
      @ok="openSigning"
      v-if="newVisibleCurrency"
    />
    <!-- 开通服务：未实名，提示实名认证 -->
    <a-modal
      :maskClosable="false"
      :title="$t('friendtip')"
      :visible="visibleValidate"
      :centered="true"
      :okText="$t('cert')"
      @ok="handleCertOk"
      @cancel="() => (visibleValidate = false)"
    >
      {{ $t('tip3') }}
    </a-modal>
    <!-- 进度弹窗 -->
    <StatusView
      v-if="modalVisible"
      :visible="modalVisible"
      :flowDetail="flowDetail"
      @close="modalVisible = false"
    />
    <!-- 非转化客户 -->
    <ChangeClue
      @cancel="changeClueModal = false"
      :changeClueModal="changeClueModal"
      :msg="changeClueMsg"
      v-if="changeClueModal"
    />
    <!-- FB4提示弹窗 -->
    <fb4-tipsModal
      v-if="FB4Visible"
      @cancel="FB4Visible = false"
      :FB4Visible="FB4Visible"
    />
  </div>
</template>

<script>
import StatusView from './statusView'
import ChangeClue from '../newStatus/ChangeClue'
import ContractSigning from '../newStatus/ContractSigning'
import Fb4TipsModal from './FB4TipsModal'
export default {
  name: 'open-wrapper',
  props: {
    info: Object,
  },
  components: {
    StatusView,
    ContractSigning,
    ChangeClue,
    Fb4TipsModal,
  },
  data() {
    this.links = {
      DSS: 'https://www.4px.com/view/send.html',
      FB4: 'https://www.4px.com/view/warehouse.html',
      TRS: 'https://www.4px.com/view/receive.html',
      GRS: 'https://www.4px.com/view/return.html',
    }
    return {
      selecedCurrency: ['CNY'],
      visibleIntrduce: false,
      visibleCurrency: false,
      newVisibleCurrency: false,
      visibleValidate: false,
      FB4Visible: false,
      hasChoose: false,
      modalVisible: false,
      flowDetail: {},
      currency: [],
      validateStatus: 0,
      goContractInfo: false,
      changeClueModal: false,
      changeClueMsg: '-',
    }
  },
  created() {
    this.getCurrencyList()
  },
  methods: {
    openSigning() {
      this.$root.bus.$emit('reload-brand')
      this.newVisibleCurrency = false
      this.$store.state.contractTipsModal = true
    },
    /**
     * 打开进度弹窗
     */
    checkProgress() {
      const { brandCode } = this.info
      this.$post('v2/brand/getFlowDetail', {
        brandCode: brandCode,
      }).then((res) => {
        if (res) {
          this.modalVisible = true
          this.flowDetail = res.filter((e) => e.brandService === brandCode)[0]
        }
      })
    },
    /**
     * @description: 获取币种列表
     * @param {type}
     * @return {type}
     */
    getCurrencyList() {
      this.$get('v2/common/getCurrencyList').then((res) => {
        this.currency = res || []
      })
    },
    getCustomRegion() {
      return new Promise((resolve, reject) => {
        this.$show()
        this.$post('v2/userAuth/isChineseCustomer')
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
            this.$hide()
          })
      })
    },
    // 处理FB4开通流程
    async handleFb4OpenProcss() {
      let authentication = await this.getAuthentication()
      if (authentication == 1) {
        this.FB4Visible = true
      } else {
        //三流合一版本 直接弹窗申请合同
        // this.newVisibleCurrency = true
        // 海外入住单独跳页面
        this.$router.push({
          path: '/applyContract?brandCode=FB4',
        })
      }
      this.$hide()
    },
    // 获取客户账号类型  1:个人，2：企业
    getAuthentication() {
      return new Promise((resolve, reject) => {
        this.$show()
        this.$post('v2/user/getAuthentication')
          .then((res) => {
            resolve(res?.entity)
          })
          .catch((err) => {
            reject(err)
            this.$hide()
          })
      })
    },
    /**
     * @description: 判断是否为转客户
     * @param {type}
     * @return {type}
     */
    changeClue() {
      return new Promise((resolve, reject) => {
        this.$show()
        this.$post('v2/userAuth/isChangeClue')
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
            this.$hide()
          })
      })
    },
    /**
     * @description: 开通服务
     * @param {type}
     * @return {type}
     */
    async onOpen() {
      // 判断是否为转客户
      let isChangeClue = await this.changeClue()
      if (isChangeClue && isChangeClue.isChangeClue == false) {
        //非转化客户
        this.changeClueModal = true
        if (isChangeClue && isChangeClue.code == '500001') {
          this.changeClueMsg = isChangeClue.msg
        }

        this.$hide()
        return false
      }
      // 判断客户地区 ture国内 false港澳台
      // let isChineseCustomer = await this.getCustomRegion()
      // 开通服务前
      this.$show()
      this.$post('v2/brand/auth/check')
        .then((res) => {
          this.$hide()
          this.validateStatus = res
          // 未实名认证  -  跳到实名认证页面
          if (res == 0) {
            this.visibleValidate = true
          }
          // 已实名认证  -  可以选择币种开通品牌
          if (res == 1) {
            // isChineseCustomer &&
            if (this.info.brandCode != 'TRS') {
              // 大陆用户
              if (this.info.brandCode == 'FB4') {
                // 判断开通品牌是否是FB4
                this.$hide()
                this.handleFb4OpenProcss()
              } else {
                //三流合一版本 直接弹窗申请合同(旧版)
                // this.newVisibleCurrency = true

                // 海外入住单独跳页面(新版)
                this.$router.push({
                  path: '/applyContract?brandCode=' + this.info.brandCode,
                })
              }
            } else {
              this.openBrand()
              //港澳用户及转运品牌(TRS)走旧流程
              this.visibleCurrency = true
            }
          }
          // 实名认证中  -  跳到实名认证页面（继续上次未完成的实名认证）
          if (res == 2) {
            this.$message.warning(this.$t('validating'))
          }
          if (res == 3) {
            this.$message.warning(this.$t('tip4'))
          }
          if (res == 4) {
            this.$message.warning(this.$t('tip5'))
          }
        })
        .catch(() => {
          this.$hide()
        })
    },
    /**
     * @description: 跳到实名认证页面
     * @param {type}
     * @return {type}
     */
    handleCertOk() {
      this.visibleValidate = false
      // 旧版实名认证页面
      // this.$router.push('/qualification?brand=' + this.info.brandCode)
      // 新版实名认证页面（海外入住优化）
      this.$router.push('/openProgress?brandCode=' + this.info.brandCode)
    },
    // 开通品牌
    openBrand() {
      this.$show()
      this.$post('v2/brand/open', {
        brandCode: this.info.brandCode,
      })
        .then(() => {
          this.$hide()
          // this.$message.success(this.$t('optSuccess'))
          this.hasChoose = true
        })
        .catch(() => {
          this.$hide()
        })
    },
    /**
     * @description: 提交开通服务信息
     * @param {type}
     * @return {type}
     */
    submitOpenData() {
      // 关闭提示框 选择金额弹窗
      if (this.hasChoose) {
        this.visibleCurrency = false
        this.$root.bus.$emit('reload-brand')
        return
      }
      if (!this.info) return

      if (this.selecedCurrency.length === 0) {
        this.$message.error(this.$t('nocurrency'))
        return
      }

      this.$show()
      this.$post('v2/brand/open', {
        brandCode: this.info.brandCode,
        currencyList: this.selecedCurrency,
      })
        .then(() => {
          this.$hide()
          this.$message.success(this.$t('optSuccess'))
          this.hasChoose = true
        })
        .catch(() => {
          this.$hide()
        })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .big-modal-header {
  .select-btn {
    margin-bottom: 16px;
    margin-top: 4px;
  }
  .ant-select-selection {
    .ant-select-selection__rendered {
      display: flex;
      align-items: center;
    }
    li {
      margin-bottom: 3px;
    }
  }
  .ant-modal-footer {
    padding-right: 24px;
    text-align: right;
    button {
      width: 100px;
      height: 40px;
    }
  }
  .ant-modal-footer .ant-btn span {
    padding: 0;
  }
  .control-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.choose-msg {
  .ok {
    font-size: 40px;
    color: #18bd92;
  }
  .success-msg {
    font-size: 16px;
    color: #333;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .sell-msg {
    font-size: 12px;
    color: #999;
  }
}
/deep/ .ant-modal-footer {
  padding-bottom: 20px;
}
</style>
