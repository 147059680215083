/*
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-06-29 20:50:16
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-03-17 18:25:02
 */
import { OrderStatusEnum, ORDER_STATUS, EXPRESS } from '@microDss/util/constant'
import moment from 'moment'
/**
 * 将订单状态码，转为文本
 * @param {*} status
 */
const f_orderState = (status, lang) => {
  if (!OrderStatusEnum || !status) return '-'
  const item = OrderStatusEnum[status]
  const k = lang == 'zh' ? 'name' : 'ename'
  if (item) return item[k]

  const item2 = ORDER_STATUS[status]
  if (item2) return item2[k]

  return '-'
}

/**
 * 快递代码转为快递公司
 * @param {*} code
 */
const f_expressLabel = code => {
  if (!code) return ''
  const d = EXPRESS.filter(item => item.value == code)
  if (d.length) {
    return d[0].label
  } else {
    return ''
  }
}

const f_date_dss = (dt, formatMode) => {
  if (dt) {
    return moment(dt).format(formatMode || 'YYYY-MM-DD HH:mm:ss')
  }
  return '-'
}

export { f_orderState, f_expressLabel, f_date_dss }
