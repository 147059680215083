export default [
  {
    path: '/personal_center',
    name: 'personal_center',
    meta: {
      title: '直发业务中心',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: personal_center */ '@microDss/view/main/Main.vue'
      )
  },
  /* 我的订单 */
  {
    path: '/to_create',
    name: 'to_create',
    meta: {
      title: '单票下单',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: to_create */ '@microDss/view/orderCore/singleOrder/SingleOrder.vue'
      )
  },
  {
    path: '/order/successForcast',
    name: 'successForcast',
    meta: {
      title: '单票下单预报成功',
      ignore: true,
      level: 2
    },
    component: () =>
      import(
        /* webPackChunkName: successForcast */ '@microDss/view/orderCore/singleOrder/SuccessForcast.vue'
      )
  },
  {
    path: '/order/successDraft',
    name: 'successDraft',
    meta: {
      title: '单票下单保存成功',
      ignore: true,
      level: 2
    },
    component: () =>
      import(
        /* webPackChunkName: successDraft */ '@microDss/view/orderCore/singleOrder/SuccessDraft.vue'
      )
  },
  /* 批量下单 */
  {
    path: '/batch_upload',
    name: 'batch_upload',
    meta: {
      title: '批量下单',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: batch_upload */ '@v/microApps/dss/react/batch/Batch.vue'
      )
  },
  {
    path: '/batch_preview',
    name: 'batch_preview',
    meta: {
      title: '预览页面',
      ignore: true,
      level: 2,
    },
    component: () =>
      import(
        /* webPackChunkName: batch_preview */ '@v/microApps/dss/react/preview/index.vue'
      )
  },
  {
    path: '/dssApp',
    name: 'dssApp',
    meta: {
      title: 'dss',
      ignore: true
    },
    component: () =>
      import(/* webPackChunkName: dssApp */ '@v/microApps/dss/Dss.vue')
  },
  {
    path: '/managementinfo_received',
    name: 'managementinfo_received',
    meta: {
      title: '我的订单',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: managementinfo_received */ '@microDss/view/orderCore/myOrder/MyOrder.vue'
      )
  },
  // {
  //   path: '/abnormal_all',
  //   name: 'abnormal_all',
  //   meta: {
  //     title: '全部问题件',
  //     ignore: true
  //   },
  //   component: () =>
  //     import(
  //       /* webPackChunkName: abnormal_all */ '@microDss/view/orderCore/problemOrder/All.vue'
  //     )
  // },
  {
    path: '/abnormal_inner',
    name: 'abnormal_inner',
    meta: {
      title: '异常处理-库内',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: abnormal_inner */ '@microDss/view/orderCore/problemOrder/AbnormalInner.vue'
      )
  },
  {
    path: '/abnormal_outer',
    name: 'abnormal_outer',
    meta: {
      title: '异常处理-尾程',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: abnormal_outer */ '@microDss/view/orderCore/problemOrder/AbnormalOuter.vue'
      )
  },
  {
    path: '/abnormal_oracleAbnormal',
    name: 'abnormal_oracleAbnormal',
    meta: {
      title: '自打单客户问题件',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: abnormal_oracleAbnormal */ '@microDss/view/orderCore/problemOrder/oracle/OracleAbnormal.vue'
      )
  },
  {
    path: '/abnormal_arrears',
    name: 'abnormal_arrears',
    meta: {
      title: '欠费件',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: abnormal_arrears */ '@microDss/view/orderCore/problemOrder/Arrears.vue'
      )
  },
  {
    path: '/abnormal_notify',
    name: 'abnormal_notify',
    meta: {
      title: '通知件',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: notify */ '@microDss/view/orderCore/problemOrder/Notify.vue'
      )
  },
  {
    path: '/abnormal_amz',
    name: 'abnormal_amz',
    meta: {
      title: '亚马逊预报问题单',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: amzPrealertOrder */ '@microDss/view/orderCore/problemOrder/amzOrder/AmzPrealertOrder.vue'
      )
  },
  {
    path: '/abnormal_oracleNotify',
    name: 'abnormal_oracleNotify',
    meta: {
      title: '自打单客户通知件',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: abnormal_oracleNotify */ '@microDss/view/orderCore/problemOrder/oracle/OracleNotify.vue'
      )
  },

  //*********************  约揽菜单before *************************/
  {
    path: '/collectManager',
    name: 'collectManager',
    meta: {
      title: '揽收预约',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: collectManager */ '@microDss/view/collect/manager/manager/Index.vue'
      )
  },
  {
    path: '/groupPackage',
    name: 'groupPackage',
    meta: {
      title: '揽收组包',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: groupPackage */ '@microDss/view/collect/manager/groupPackage/Index.vue'
      )
  },
  {
    path: '/difference',
    name: 'difference',
    meta: {
      title: '揽收差异化报表',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: groupPackage */ '@microDss/view/collect/difference/Index.vue'
      )
  },
  {
    path: '/collectAddress',
    name: 'collectAddress',
    meta: {
      title: '揽收地址管理',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: collectAddress */ '@microDss/view/personal/collectAddress/CollectAddress.vue'
      )
  },
  {
    path: '/material_apply',
    name: 'material_apply',
    meta: {
      title: '物料申请',
      ignore: true
    },
    component: () => import(
        /* webpackChunkName: "material_apply" */ '@v/serviceCenter/material/apply/index.vue'
    )
  },
  {
    path: '/collect_collectSuccess',
    name: 'collectSuccess',
    meta: {
      title: '揽收预报提交成功',
      ignore: true,
      level: 2
    },
    component: () =>
      import(
        /* webPackChunkName: collectSuccess */ '@microDss/view/collect/create/Step2Success.vue'
      )
  },

  // {
  //   path: '/collectindex',
  //   name: 'collectindex',
  //   meta: {
  //     title: '揽收订单管理',
  //     ignore: true
  //   },
  //   component: () =>
  //     import(
  //       /* webPackChunkName: collectindex */ '@microDss/view/collect/manager/Index.vue'
  //     )
  // },
  // {
  //   path: '/collect/create',
  //   name: 'collectIndex',
  //   meta: {
  //     title: '创建揽收预报',
  //     ignore: true
  //   },
  //   component: () =>
  //     import(
  //       /* webPackChunkName: collectIndex */ '@microDss/view/collect/create/Index.vue'
  //     )
  // },
  // {
  //   path: '/collect/collectSuccess',
  //   name: 'collectSuccess',
  //   meta: {
  //     title: '揽收预报提交成功',
  //     ignore: true
  //   },
  //   component: () =>
  //     import(
  //       /* webPackChunkName: collectSuccess */ '@microDss/view/collect/create/Step2Success.vue'
  //     )
  // },
  // {
  //   path: '/collect/collectDetail',
  //   name: 'collectDetail',
  //   meta: {
  //     title: '揽收预报详情',
  //     ignore: true
  //   },
  //   component: () =>
  //     import(
  //       /* webPackChunkName: collectDetail */ '@microDss/view/collect/manager/detail/Detail.vue'
  //     )
  // },
  // {
  //   path: '/collect/collectEdit',
  //   name: 'collectEdit',
  //   meta: {
  //     title: '揽收预报修改',
  //     ignore: true
  //   },
  //   component: () =>
  //     import(
  //       /* webPackChunkName: collectEdit */ '@microDss/view/collect/edit/CollectEdit.vue'
  //     )
  // },
  //*********************  约揽菜单end *************************/

  {
    path: '/order/standard',
    name: 'standard',
    meta: {
      title: '标准回复',
      ignore: true,
      level: 2
    },
    component: () =>
      import(
        /* webPackChunkName: standard */ '@microDss/view/orderCore/problemOrder/standardReply/ReplyIndex.vue'
      )
  },
  {
    path: '/my_orderslabelPrint',
    name: 'my_orderslabelPrint',
    meta: {
      title: '标签打印',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: my_orderslabelPrint */ '@microDss/view/label/LabelPrint.vue'
      )
  },
  /* 装袋出库 */
  {
    path: '/bagImport',
    name: 'bagImport',
    meta: {
      title: '装袋/出库导入',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: bagImport */ '@microDss/view/other/bag/BagImport.vue'
      )
  },
  {
    path: '/bagImportResult',
    name: 'bagImportResult',
    meta: {
      title: '装袋/出库结果查询',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: bagImportResult */ '@microDss/view/other/bag/BagImportResult.vue'
      )
  },
  {
    path: '/bagPrint',
    name: 'bagPrint',
    meta: {
      title: '袋标签打印',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: bagPrint */ '@microDss/view/other/bag/BagPrint.vue'
      )
  },
  /* 增值服务 */
  {
    path: '/insuranceindex',
    name: 'insuranceindex',
    meta: {
      title: '安全投保',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: insuranceindex */ '@microDss/view/servicesSupport/insure/Insure.vue'
      )
  },
  {
    path: '/trackcod',
    name: 'trackcod',
    meta: {
      title: '代收货款数据看板',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: trackcod */ '@microDss/view/servicesSupport/codData/codData.vue'
      )
  },
  {
    path: '/customerInformation',
    name: 'customerInformation',
    meta: {
      title: '单独报关',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: separateCustomsDeclaration */ '@microDss/view/servicesSupport/separateCustomsDeclaration/index.vue'
      )
  },
  {
    path: '/trackindex',
    name: 'trackindex',
    meta: {
      title: '轨迹查询',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: trackindex */ '@microDss/view/servicesSupport/track/Track.vue'
      )
  },
  {
    path: '/documentindex',
    name: 'documentindex',
    meta: {
      title: '单据管理',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: documentindex */ '@microDss/view/servicesSupport/bill/Bill.vue'
      )
  },
  {
    path: '/cityZipcode',
    name: 'cityZipcode',
    meta: {
      title: '城市邮编查询',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: documentindex */ '@microDss/view/servicesSupport/cityZipcode/cityZipcode.vue'
      )
  },
  /* 财务管理 */
  {
    path: '/my_billcustomer_amount_change',
    name: 'my_billcustomer_amount_change',
    meta: {
      title: '我的账单',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: my_billcustomer_amount_change */ '@microDss/view/wallet/Bill.vue'
      )
  },
  {
    path: '/couponindex',
    name: 'couponindex',
    meta: {
      title: '我的优惠券',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: couponindex */ '@microDss/view/wallet/Coupon.vue'
      )
  },
  {
    path: '/payonlineindex',
    name: 'payonlineindex',
    meta: {
      title: '我要充值',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: payonlineindex */ '@microDss/view/wallet/Wallet.vue'
      )
  },
  {
    path: '/chargeindex',
    name: 'chargeindex',
    meta: {
      title: '运费试算',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: chargeindex */ '@microDss/view/spreadsheet/Spreadsheet.vue'
      )
  },
  {
    path: '/expense_detail',
    name: 'expense_detail',
    meta: {
      title: '费用明细',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: expense_detail */ '@microDss/view/wallet/Charges.vue'
      )
  },
  {
    path: '/electronic_bill',
    name: 'electronic_bill',
    meta: {
      title: '电子账单',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: electronic_bill */ '@microDss/view/wallet/ElectronicBill.vue'
      )
  },
  /* 个人设置 */
  {
    path: '/platformAccountindex',
    name: 'platformAccountindex',
    meta: {
      title: '多账号管理',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: platformAccountindex */ '@microDss/view/personal/account/Index.vue'
      )
  },
  {
    path: '/customer_cargo_kindindex',
    name: 'customer_cargo_kindindex',
    meta: {
      title: '申报品管理',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: customer_cargo_kindindex */ '@microDss/view/personal/DeclarationManagement.vue'
      )
  },
  {
    path: '/dhl_senderindex',
    name: 'dhl_senderindex',
    meta: {
      title: 'DHL发件人白名单',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: dhl_senderindex */ '@microDss/view/personal/DHLSenderManagement/list.vue'
      )
  },
  {
    path: '/tophatter_accountindex',
    name: 'tophatter_accountindex',
    meta: {
      title: 'tophatter账号绑定',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: tophatter_accountindex */ '@microDss/view/personal/TophatterAccount.vue'
      )
  },
  {
    path: '/insurance_manageindex',
    name: 'insurance_manageindex',
    meta: {
      title: '保险管理',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: insurance_manageindex */ '@microDss/view/personal/InsuranceManagement.vue'
      )
  },
  {
    path: '/tokenindex',
    name: 'tokenindex',
    meta: {
      title: 'TOKEN',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: tokenindex */ '@microDss/view/personal/TokenAuthorization.vue'
      )
  },
  {
    path: '/wish_accountindex',
    name: 'wish_accountindex',
    meta: {
      title: 'WISH账号绑定',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: wish_accountindex */ '@microDss/view/personal/WishBindonAccount.vue'
      )
  },
  {
    path: '/vova_accountindex',
    name: 'vova_accountindex',
    meta: {
      title: 'VOVA账号绑定',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: vova_accountindex */ '@microDss/view/personal/VovaBindonAccount.vue'
      )
  },
  {
    path: '/amazon_accountindex',
    name: 'amazon_accountindex',
    meta: {
      title: '亚马逊账号绑定',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: amazon_accountindex */ '@microDss/view/personal/AmazonAccount.vue'
      )
  },
  {
    path: '/dunhuang_accountindex',
    name: 'dunhuang_accountindex',
    meta: {
      title: '敦煌账号绑定',
      ignore: true
    },
    component: () =>
      import(
        /* webPackChunkName: dunhuang_accountindex */ '@microDss/view/personal/DunhuangAccount.vue'
      )
  },
  // ********************************** 卖家助手 before **********************************
  {
    // 订单管理
    path: '/orderManagement',
    name: 'orderManagement',
    meta: { title: '订单管理', ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "orderManagement" */ '@v/serviceCenter/sellerAssistant/orderManagement'
      )
  },
  {
    // 订单管理--详情
    path: '/orderDetail',
    name: 'orderDetail',
    meta: { title: '订单详情', ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "orderDetail" */ '@v/serviceCenter/sellerAssistant/orderDetail'
      )
  },
  {
    // SKU管理
    path: '/managementSKU',
    name: 'managementSKU',
    meta: { title: 'SKU管理', ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "managementSKU" */ '@v/serviceCenter/sellerAssistant/managementSKU'
      )
  },
  {
    // 下载记录
    path: '/downloadRecord',
    name: 'downloadRecord',
    meta: { title: '下载记录', ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "downloadRecord" */ '@v/serviceCenter/sellerAssistant/downloadRecord'
      )
  },
  {
    // 卖家账号
    path: '/sellerAccount',
    name: 'sellerAccount',
    meta: { title: '卖家账号', ignore: true },
    component: () =>
      import(
        /* webpackChunkName: "sellerAccount" */ '@v/serviceCenter/sellerAssistant/sellerAccount'
      )
  },
  // ********************************** 卖家助手 end **********************************
]
