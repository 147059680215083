<template>
  <div class="product">
    <div v-if="type == 'recommend'" class="recommend-prod-box">
      <a-empty
        v-if="listData.length == 0"
        style="width: 100%; height: 270px; padding: 20px 0"
      />
      <div
        v-else
        class="recommend-prod-item"
        v-for="(item, index) in listData"
        :key="item.productCode"
      >
        <div class="content">
          <div class="tit-box">
            <div class="tit-content">
              <div class="name">
                {{ item.productName }}({{ item.productCode }})
              </div>
              <div class="tag-box">
                <!-- 跟踪 -->
                <div
                  class="tag"
                  :class="{
                    'tag-hb': item.trackingType == 2,
                    'tag-gr': item.trackingType == 3
                  }"
                >
                  {{ item.trackingType | getNameFromValue(trackTypeArr) }}
                </div>
                <div
                  v-if="item.productCategory == 'unionMail'"
                  class="tag"
                  :class="{
                    'tag-g': item.isBattery == 1,
                    'tag-o': item.isBattery != 1
                  }"
                >
                  {{
                    item.isBattery == 1
                      ? $t('product_expression_017')
                      : $t('product_expression_018')
                  }}
                </div>
                <!-- 优先 | 可开查  -->
                <div class="tag-bt">
                  <i class="iconfont iconfuwubiaozhun"></i>
                  {{ item.serviceLevel | getNameFromValue(serviceLevelArr) }} |
                  {{
                    item.openCheckType == '1'
                      ? $t('product_expression_028')
                      : item.openCheckType == '0'
                      ? $t('product_expression_027')
                      : '-'
                  }}
                </div>
              </div>
            </div>
            <div class="recommend-tag">
              {{ item.recommendTag || '' }}
            </div>
          </div>

          <div class="d-line"></div>
          <div class="price-box" v-if="item.multipleStdProduct">
            {{ $t('product_expression_054') }}
          </div>
          <div class="price-box" v-else>
            <span class="price-item">
              <div class="num-data">
                <span class="num-text">{{ item.deliveryPeriod || '-' }}</span>
                <span class="unit">{{ $t('stock_custom.day') }}</span>
              </div>
              <div class="label">{{ $t('product_expression_055') }}</div>
            </span>
            <span class="price-item">
              <div class="num-data">
                <span class="blue num-text">{{
                  item.totalRmbAmount || '-'
                }}</span>
                <span class="unit">{{ $t('yuan') }}</span>
                <span class="change-tips" v-if="item.trendsMsg"
                  ><i class="iconfont iconshujuxiajiang"></i
                  >{{ item.trendsMsg }}</span
                >
              </div>
              <div class="label">
                {{ $t('product_expression_056') }}
                <a-popover placement="bottom">
                  <template slot="content">
                    <div class="cost-des">
                      {{ $t('chargeWeight') }}：<span class="num"
                        >{{ item.chargeWeight || '-' }} </span
                      >KG
                    </div>
                    <div
                      class="cost-des"
                      v-for="fItem in item.feeList"
                      :key="fItem.feeName"
                    >
                      {{ fItem.feeName }}：<span class="num">{{
                        fItem.amount || '-'
                      }}</span
                      >{{ $t('yuan') }}
                    </div>
                  </template>
                  <img src="./img/question_mark.svg" class="tips-btn" />
                </a-popover>
              </div>
            </span>
          </div>

          <!-- 按钮 -->
          <div class="btn-box">
            <div class="left-btn">
              <PricesLineChart
                :type="type"
                :item="item"
                bpType="1"
                :bpIndex="index"
                :queryParam="queryParam"
              />
              <span class="gap-line"></span>
              <span
                @click="showDetail(item, 1, index, $event)"
                class="tips-btn"
              >
                <img src="./img/prod_detail.svg" class="iconfont" />
                {{ $t('product_expression_025') }}
              </span>
            </div>
            <a-button
              @click="handleToOrder(item, 1, index, $event)"
              type="primary"
              class="order-btn"
              >{{ $t('product_expression_026') }}</a-button
            >
          </div>
        </div>
        <div
          @click="selProduct(item.productCode, item, 1, index)"
          :class="{
            active: selectProduct.length
              ? selectProduct.includes(item.productCode)
              : false,
            'prod-opt': true
          }"
        >
          <i class="iconfont icontianjiaduibi"></i>
          <i class="iconfont iconyitianjia"></i>
          {{ $t('product_expression_057') }}
        </div>
      </div>
    </div>
    <div v-if="type == 'all'" class="all-prod recommend-prod-box">
      <a-empty
        v-if="listData.length == 0"
        style="width: 100%; height: 270px; padding: 20px 0"
      />
      <div
        v-else
        class=" recommend-prod-item"
        v-for="(item, index) in listData"
        :key="item.productCode"
      >
        <div class="content">
          <div class="tit-box">
            <div class="tit-content">
              <div class="name">
                <span class="name-text">
                  {{ item.productName }}({{ item.productCode }})
                </span>
              </div>
              <div class="tag-box">
                <div
                  class="tag"
                  :class="{
                    'tag-hb': item.trackingType == 2,
                    'tag-gr': item.trackingType == 3
                  }"
                >
                  {{ item.trackingType | getNameFromValue(trackTypeArr) }}
                </div>
                <div
                  v-if="item.productCategory == 'unionMail'"
                  class="tag"
                  :class="{
                    'tag-g': item.isBattery == 1,
                    'tag-o': item.isBattery != 1
                  }"
                >
                  {{
                    item.isBattery == 1
                      ? $t('product_expression_017')
                      : $t('product_expression_018')
                  }}
                </div>
                <div class="tag-bt">
                  <i class="iconfont iconfuwubiaozhun"></i>
                  {{ item.serviceLevel | getNameFromValue(serviceLevelArr) }} |
                  {{
                    item.openCheckType == '1'
                      ? $t('product_expression_028')
                      : item.openCheckType == '0'
                      ? $t('product_expression_027')
                      : '-'
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="d-line"></div>
          <div class="price-box" v-if="item.multipleStdProduct">
            {{ $t('product_expression_054') }}
          </div>
          <div class="price-box" v-else>
            <span class="price-item">
              <div class="num-data">
                <span class="num-text">{{ item.deliveryPeriod || '-' }}</span>
                <span class="unit">{{ $t('stock_custom.day') }}</span>
              </div>
              <div class="label">{{ $t('product_expression_055') }}</div>
            </span>
            <span class="price-item">
              <div class="blue num-data">
                <span class="blue num-text">{{
                  item.totalRmbAmount || '-'
                }}</span>
                <span class="unit">{{ $t('yuan') }}</span>
              </div>
              <div class="label">
                {{ $t('product_expression_056') }}
                <a-popover placement="bottom">
                  <template slot="content">
                    <div class="cost-des">
                      {{ $t('chargeWeight') }}：<span class="num"
                        >{{ item.chargeWeight || '-' }} </span
                      >KG
                    </div>
                    <div
                      class="cost-des"
                      v-for="fItem in item.feeList"
                      :key="fItem.feeName"
                    >
                      {{ fItem.feeName }}：<span class="num">{{
                        fItem.amount || '-'
                      }}</span
                      >{{ $t('yuan') }}
                    </div>
                  </template>
                  <img src="./img/question_mark.svg" class="tips-btn" />
                </a-popover>
              </div>
            </span>
          </div>
          <div class="des-box">
            <div class="des-text">
              【{{ $t('product_expression_021') }}】{{ item.minWeight }}-{{
                item.maxWeight
              }}kg
            </div>
            <div class="des-text">
              【{{ $t('product_expression_022') }}】
              <span v-if="item.volumeLimit && item?.volumeLimit?.length > 0">
                <a-popover>
                  <template slot="content">
                    <p>{{ item.volumeLimit }}</p>
                  </template>
                  <span type="primary">
                    {{ item.volumeLimit }}
                  </span>
                </a-popover>
              </span>
              <span v-else>
                {{ item.volumeLimit || '-' }}
              </span>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="btn-box">
            <div class="left-btn">
              <PricesLineChart
                :type="type"
                :item="item"
                bpType="2"
                :bpIndex="index"
                :queryParam="queryParam"
              />
              <span class="gap-line"></span>
              <span
                @click="showDetail(item, 2, index, $event)"
                class="tips-btn"
              >
                <img src="./img/prod_detail.svg" class="iconfont" />
                {{ $t('product_expression_025') }}
              </span>
            </div>
            <a-button
              @click="handleToOrder(item, 2, index, $event)"
              type="primary"
              size="small"
              class="order-btn"
              >{{ $t('product_expression_026') }}</a-button
            >
          </div>
        </div>
        <div
          @click="selProduct(item.productCode, item, 2, index)"
          :class="{
            active: selectProduct.length
              ? selectProduct.includes(item.productCode)
              : false,
            'prod-opt': true
          }"
        >
          <i class="iconfont icontianjiaduibi"></i>
          <i class="iconfont iconyitianjia"></i>
          {{ $t('product_expression_057') }}
        </div>
      </div>
    </div>
    <!-- </a-spin> -->
  </div>
</template>

<script>
import { trackTypeArr, serviceLevelArr } from './data'
import PricesLineChart from './PricesLineChart'
import { burialPoint } from '../burialPoint.services'
import sensors from 'sa-sdk-javascript'

export default {
  name: 'Product',
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'all'
    },
    selectProduct: {
      type: Array,
      default: () => []
    },
    queryParam: {
      type: Object,
      default: () => {}
    },
    cityName: {
      type: String
    },
    countryName: {
      type: String
    }
  },
  data() {
    return {
      chart: null,
      trackTypeArr: trackTypeArr(this),
      serviceLevelArr: serviceLevelArr(this),
      loadingLineChat: false,
      xAxisData: [],
      seriesData: []
    }
  },
  components: { PricesLineChart },
  computed: {
    filtersProductList() {
      let filtersProductList = []
      let addListCode = []
      this.recommendAndAllproductList.forEach(element => {
        if (
          this.selectProductList.includes(element.productCode) &&
          !addListCode.includes(element.productCode)
        ) {
          addListCode.push(element.productCode)
          filtersProductList.push(element)
        }
      })
      filtersProductList = [...new Set(filtersProductList)]
      return filtersProductList
    }
  },
  methods: {
    handleToOrder(record, bpType, bpIndex, event) {
      // 埋点 下单
      burialPoint({
        event: '1',
        productArea: bpType || '', //页面区域  1:推荐位 2:列表位 3:对比产品
        productRank: bpIndex + 1 || '', //排序

        productName: record?.productName || '', // 产品名称
        productCode: record?.productCode || '', //产品代码
        country: this.queryParam.country || '', //国家
        city: this.cityName || '' //国家
      })
      sensors.track('CalculateFreightOrder', {
        ...event?.target,
        product_code: record?.productCode || '',
        product_name: record?.productName || ''
      })
      this.$router.push({
        name: 'to_create',
        params: {
          quickOrder: {
            order: {
              holdProduct: record.productName,
              productCode: record.productCode,
              destinationCountry: this.queryParam.country,
              holdCountry: this.countryName,
              countryCode: this.queryParam.country
            },
            orderAttach: {
              customerWeight: this.queryParam.weight
                ? this.queryParam.weight.toString()
                : ''
            }
          }
        }
      })
    },
    selProduct(code, item, bpType, index) {
      this.$emit('addSelectProduct', code, item, bpType, index)
    },
    showDetail(item, bpType, index, event) {
      this.$emit('showDetail', item, bpType, index, event)
    }
  }
}
</script>

<style lang="less" scoped>
.product-tit {
  margin-top: 16px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #151f28;
  line-height: 16px;
  font-weight: 500;
  .tit-text::before {
    margin-right: 6px;
    display: inline-block;
    content: '';
    width: 4px;
    height: 16px;
    background: #3960df;
    border-radius: 2px;
  }
  .filtter-box {
    display: flex;
    align-content: center;
    font-size: 14px;
    color: #545d75;
    font-weight: 400;
    cursor: pointer;
  }
  .contrast-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 98px;
    height: 32px;
    box-sizing: border-box;
    background: #feedde;
    border: 1px solid #f77300;
    border-radius: 4px;
    font-size: 14px;
    color: #ff6400;
    font-weight: 500;
    text-align: center;
  }
  .filtter-box .filtter-item.active {
    color: #3960df;
  }
  .gap-line {
    margin: 0 9px;
    width: 0;
    height: 12px;
    border-right: 1px solid #a9aeba;
  }
}
.recommend-prod-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.recommend-prod-item {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 12px;
  width: calc((100% - 24px) / 3);
  height: 182px;
  background: #ffffff;
  box-shadow: 4px 4px 16px 0 rgba(144, 155, 190, 0.13);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s;
  animation: all 0.2s;
  &:nth-of-type(3) {
    margin-right: 0;
  }
  &:hover {
    margin-top: -2px;
    box-shadow: 2px 2px 16px 0px rgba(57, 96, 223, 0.35);
  }
}
.all-prod {
  flex-wrap: wrap;
  flex-direction: column;
  .recommend-prod-item {
    margin-bottom: 12px;
    width: 100% !important;
    height: 84px;
    flex-direction: row;
  }
  .content {
    padding: 18px 0px 14px 20px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1439px) {
      .tit-box {
        flex: none;
        width: 220px;
      }
    }
    @media screen and (min-width: 1440px) and (max-width: 1499px) {
      .tit-box {
        flex: none;
        width: 300px;
      }
    }
    @media screen and (min-width: 1500px) and (max-width: 1599px) {
      .tit-box {
        flex: none;
        width: 340px;
      }
    }
    @media screen and (min-width: 1600px) and (max-width: 1920px) {
      .tit-box {
        flex: none;
        width: 360px;
      }
    }

    @media screen and (min-width: 1920px) and (max-width: 2500px) {
      .tit-box {
        flex: none;
        width: 400px;
        flex: 1;
      }
    }
    @media screen and (min-width: 2501px) and (max-width: 3500px) {
      .tit-box {
        flex: none;
        width: 600px;
        flex: 1;
      }
    }
    // .tit-box {
    //   min-width: 240px;
    //   max-width: calc(50% - 270px);
    //   flex: 1;
    // }
    .tit-content {
      flex: none;
      width: 100%;
    }
    .recommend-tag {
      margin-top: 0;
      padding: 0;
      box-sizing: border-box;
      width: 60px;
      height: 20px;
      font-size: 12px;
      color: #ffffff;
      line-height: 18px;
      font-weight: 500;
      background: #ff6400;
      border-radius: 12px 12px 12px 0;
      background: '';
    }
    .tag-box {
      margin: 13px 0 0;
      width: 100%;
      display: flex;
      height: 18px;
      line-height: 18px;
    }
    .recommend-tag-b {
      background: #439dfa;
    }
    .recommend-tag-g {
      background: #00b0b0;
    }
    .price-box {
      width: 250px;
      // flex: 1;
      max-width: 300px;
      height: auto;
    }
    .order-btn {
      width: 144px;
      height: 32px;
    }
  }
  .d-line {
    // margin: 0 1.2%;
    margin: 0 24px;
    width: 0;
    height: 54px;
    bottom: 0;
    border-left: 1px solid #dadfe8;
  }
  .btn-box {
    margin-top: -4px;
    width: 180px;
    min-width: 160px;
    flex-direction: column;
  }
}
.tit-box {
  position: relative;
  display: flex;
  max-width: 560px;
  .tit-content {
    flex: 1;
  }
  .recommend-tag {
    position: relative;
    margin-top: -20px;
    padding: 21px 4px 0;
    box-sizing: border-box;
    width: 40px;
    height: 64px;
    font-size: 12px;
    color: #ffffff;
    line-height: 14px;
    font-weight: 500;
    text-align: center;
    background-image: url('./img/recm-icon.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
  }
}

.recommend-prod-item .content {
  padding: 16px 16px 16px 20px;
  flex: 1;
}

.recommend-prod-item .content .name {
  display: flex;
  font-size: 16px;
  color: #364253;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .name-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tag-box {
  margin: 10px 0;
  width: 100%;
  display: flex;
  height: 20px;
  .tag {
    margin-right: 6px;
    padding: 0 12px;
    display: inline-block;
    height: 20px;
    background: rgba(57, 96, 223, 10%);
    border-radius: 4px;
    font-size: 12px;
    color: #3960df;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
    olor: #ff6400 !important;
  }
  .tag-hb {
    background: #167ff010;
    color: #1681f0;
  }
  .tag-g {
    background: #07ab8210 !important;
    color: #07ab81 !important;
  }
  .tag-o {
    background: rgba(255, 100, 0, 10%);
    color: #ff6400;
  }
  .tag-gr {
    background: #545d7510 !important;
    color: #545d75 !important;
  }

  .tag-bt {
    margin-left: 4px;
    font-size: 12px;
    color: #323643;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 400;
    font-size: 12px;

    .iconfont {
      font-size: 14px;
      color: #8492bd;
    }
  }
}
.d-line {
  margin-bottom: 12px;
  width: calc(100% + 16px);
  height: 0;
  border-top: 1px dashed #dadfe8;
}
.price-box {
  display: flex;
  align-items: center;
  height: 38px;
  .price-item {
    width: 50%;
    .num-data {
      font-size: 0;
      .num-text {
        font-size: 22px;
        color: #545d75;
        line-height: 18px;
        font-weight: 600;
      }
      .unit {
        margin: 0 8px;
        font-size: 12px;
        color: #545d75;
        line-height: 12px;
        font-weight: 400;
      }
      .change-tips {
        font-size: 12px;
        color: #ff6400;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
        .iconfont {
          font-size: 12px;
        }
      }
    }
    .label {
      display: flex;
      align-items: center;
      margin-top: 8px;
      font-size: 12px;
      color: #767d91;
      line-height: 12px;
      font-weight: 400;
      .tips-btn {
        margin-left: 3px;
        width: 16px;
        height: 16px;
      }
    }
  }
}
.btn-box {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #545d75;
  letter-spacing: 0;
  font-weight: 500;
  .left-btn {
    display: flex;
    align-items: center;
    .tips-btn {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
    .gap-line {
      margin: 0 10px;
      width: 0;
      height: 12px;
      border-left: 1px solid #c3cbd5;
    }
    .iconfont {
      margin-right: 3px;
      width: 14px;
      height: 14px;
    }
  }
  .order-btn {
    width: 146px;
    height: 32px;
  }
}
.recommend-prod-item .prod-opt {
  display: flex;
  width: 40px;
  font-size: 12px;
  color: #f77300;
  font-weight: 500;
  height: 100%;
  background: #feedde;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .iconfont {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .icontianjiaduibi {
    display: block;
  }
  .iconyitianjia {
    display: none;
  }
}
.recommend-prod-item .prod-opt.active {
  .iconfont {
    font-size: 14px;
    margin-bottom: 4px;
    color: #18bd92;
  }
  .icontianjiaduibi {
    display: none;
  }
  .iconyitianjia {
    display: block;
  }
  background: rgba(64, 198, 144, 20%) !important;
  color: #40c690;
}
.des-box {
  margin: 0 1.2%;
  padding: 0 1.2%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  // max-width: 330px;
  min-width: 216px;
  // max-width: calc((100% - 540px) / 2);
  max-width: calc(50% - 270px);
  height: 54px;
  font-size: 12px;
  color: #545d75;
  letter-spacing: 0;
  line-height: 12px;
  font-weight: 400;
  border-left: 1px solid #c3cbd5;
  border-right: 1px solid #c3cbd5;
  .des-text {
    width: 100%;
    height: 24px;
    box-sizing: border-box;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.all-prod {
  @media screen and (max-width: 1439px) {
    .des-box {
      flex: none;
      width: 240px;
    }
  }
  @media screen and (min-width: 1440px) and (max-width: 1499px) {
    .des-box {
      flex: none;
      width: 300px;
    }
  }
  @media screen and (min-width: 1500px) and (max-width: 1599px) {
    .des-box {
      flex: none;
      width: 360px;
    }
  }
  @media screen and (min-width: 1600px) and (max-width: 1920px) {
    .des-box {
      width: 400px;
      flex: 1;
    }
  }
  @media screen and (min-width: 1920px) and (max-width: 2500px) {
    .des-box {
      width: 600px;
      flex: 1;
    }
  }
  @media screen and (min-width: 2501px) and (max-width: 3500px) {
    .des-box {
      width: 550px;
    }
  }
}
.blue {
  color: #3960df !important;
}

.cost-des {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #323643;
  line-height: 20px;
  font-weight: 400;
  .num {
    padding: 0 4px;
    color: #3960df;
  }
}
</style>
