/*
 * @Description: ajax config
 * @Autor: S9637/chifuk
 * @Date: 2020-08-27 18:14:35
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-09-10 13:43:55
 */

import axios from 'axios'
const qs = require('qs')
import { message } from 'ant-design-vue'
import { blobToExcel, downLoadFileWith } from '@u/index'
import { unLoginUrl } from '../const/unLoginUrl'
import Cookies from 'js-cookie'

/**
 * axios config
 * 统一请求头为contentType:application/json
 * 超时时间 60s
 * 文件上传头配置
 * 错误处理
 * location.origin + "/api/"
 */
const isPrd = process.env.NODE_ENV === 'production'
const path = isPrd ? '/' : 'api/'
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true
axios.defaults.timeout = 60000
axios.defaults.headers['locale'] = process.env.VUE_APP_LOCALE
const CancelToken = axios.CancelToken
// 文件上传头等配置
const config = {
  timeout: 60000 * 10,
  headers: {
    'Content-Type': 'application/form-data'
  }
}

// 用于处理响应类型为： arraybuffer 请求
// 一般用于文件流的下载
const httpBuffer = axios.create({
  baseURL: path,
  responseType: 'arraybuffer'
})

//  新增数据缓存机制
const defaultConfig = {
  cache: false,
  expire: 60 * 60 * 1000 // 默认缓存60分钟
}

axios.interceptors.request.use(config => {
  let {
    url,
    data,
    params,
    cache = defaultConfig.cache,
    expire = defaultConfig.expire
  } = config
  if (cache === true) {
    let getKey = data
      ? `${url}?cacheParams=${data}`
      : `${url}?cacheParams=${params}`
    let obj = JSON.parse(window.sessionStorage[getKey] || '{}')
    // 判断缓存数据是否存在
    if (obj.expire) {
      let curTime = new Date().getTime()
      let source = CancelToken.source()
      config.cancelToken = source.token
      // 判断缓存数据是否存在，存在的话是否过期，如果没过期就停止请求返回缓存
      if (curTime - obj.expire < expire) {
        source.cancel(obj.result)
      } else {
        window.sessionStorage.removeItem(getKey)
      }
    }
  }
  return config
})
// 拦截器 错误处理
axios.interceptors.response.use(
  res => {
    if (process.env.VUE_APP_LOGIN == 1) {
      if (
        (res.data &&
          res.data['Auth-Status'] &&
          res.data['Auth-Status'] == 'unLogin') ||
        (res && res.request && res.request.responseURL.indexOf('//sso.') > -1)
      ) {
        // 知识库免登录
        let docNames = JSON.parse(sessionStorage.getItem('docnames') || '[]')
        const path = location.pathname.split('/')
        const currRoute = path[path.length - 1]
        if (
          currRoute &&
          !docNames.includes(currRoute) &&
          !unLoginUrl.includes(currRoute)
        ) {
          console.log('被跳转了=currRoute===', currRoute)
          unLoginHandler()
        }
      }
    }
    let { url, cache, data, params } = res.config
    if (cache === true) {
      // 没有值的时候不缓存
      const nowData = res.data.data
      if (
        !nowData ||
        JSON.stringify(nowData) === '[]' ||
        JSON.stringify(nowData) === '{}'
      )
        return
      let obj = {
        expire: new Date().getTime(),
        params,
        data,
        result: res
      }
      let setKey = data
        ? `${url}?cacheParams=${data}`
        : `${url}?cacheParams=${params}`
      window.sessionStorage.setItem(setKey, JSON.stringify(obj))
    }
    return res
  },
  err => {
    // 返回缓存数据
    if (axios.isCancel(err)) {
      return Promise.resolve(err.message)
    }
    const status = err && err.response ? err.response.status : ''
    const msg = {
      400: '400,错误请求！',
      401: '401,未授权，请重新登录！',
      404: '404',
      408: '408,请求超时！',
      500: '500,服务器异常！',
      503: '503,网络错误！' //实际是后端服务在启动
    }
    if (msg[status]) {
      message.warning(msg[status])
    } else {
      if (
        process.env.VUE_APP_LOGIN == 1 &&
        !err.response &&
        err.message === 'Network Error'
      ) {
        if (err.config.url.indexOf('v2') > -1) {
          // 知识库免登录
          let docNames = JSON.parse(sessionStorage.getItem('docnames') || '[]')
          const path = location.pathname.split('/')
          const currRoute = path[path.length - 1]
          if (
            currRoute &&
            !docNames.includes(currRoute) &&
            !unLoginUrl.includes(currRoute)
          ) {
            console.log('被跳转了=currRoute===', currRoute)
            unLoginHandler()
          }
        }
      }
    }

    return Promise.reject(err)
  }
)
// 判断海外部署域名
const hostname = location.hostname
let isOverSeas =
  hostname.indexOf('eu') > -1 ||
  hostname.indexOf('asia') > -1 ||
  hostname.indexOf('us') > -1
let veriable = ''
if (hostname.indexOf('eu') > -1) veriable = 'eu'
if (hostname.indexOf('asia') > -1) veriable = 'asia'
if (hostname.indexOf('us') > -1) veriable = 'us'

var isUnLoginHandlerCalled = false
function unLoginHandler() {
  if (isUnLoginHandlerCalled) {
    return
  }
  Cookies.remove('newOrderLang')
  sessionStorage.clear()
  if (location.href.indexOf('doLogin') < 0 && location.pathname != '/dist/') {
    Cookies.set('successUrl', location.href)
  }
  const s = location.host.split('.')
  if ((s.length == 3 && !isOverSeas && s[0] === 'b-test') || s[0] == '127') {
    // 测试环境 127.0.0.1  或 b-test.4px.com
    location.href = `https://sso-test.4px.com/login?service=${location.protocol}//${location.host}/cas`
  } else if (s.length > 3 && isOverSeas && veriable) {
    // 海外部署 b.us.4px.com/b.eu.4px.com/b.asia.4px.com
    location.href = `https://sso.${veriable}.4px.com/login?service=${location.protocol}//${location.host}/cas`
  } else if (s.length == 3 && (s[0] === 'b' || s[0] === 'b-pre')) {
    // 生产或预发 b.4px.com/b-pre.4px.com
    location.href = `https://sso.4px.com/login?service=${location.protocol}//${location.host}/cas`
  } else {
    // 其他生产
    location.href = `https://sso.4px.com/login?service=${location.protocol}//${location.host}/cas`
  }
  setTimeout(() => {
    isUnLoginHandlerCalled = false
  }, 1000000)
}

// 封装 GET 请求
function httpGet(url, config = {}) {
  var nowUrl =
    url.startsWith('http') || url.startsWith('https') ? url : path + url
  return new Promise((resolve, reject) => {
    axios
      .get(nowUrl, {
        ...config,
        headers: { locale: sessionStorage.getItem('locale'), ...config.headers }
      })
      .then(res => {
        if (res && res.status === 200) {
          if (res.data && res.data.result == 0) {
            resolve(res.data.data)
          } else {
            if (res.data && res.data.message) {
              message.warning(h => {
                return h('span', {
                  domProps: {
                    innerHTML: res.data.message
                  }
                })
              })
            }
            reject(res.data)
          }
        } else {
          message.error(res.message || '服务器异常！')
          reject()
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}
// 0：成功； 1：失败
// 封装 POST 请
function httpPost(url, param, config = {}, exceptionCB) {
  var nowUrl =
    url.startsWith('http') || url.startsWith('https') ? url : path + url
  return new Promise((resolve, reject) => {
    let handleParam = JSON.stringify(param)
    if (
      config &&
      config.headers &&
      config.headers['Content-Type'] ==
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      handleParam = qs.stringify(param, { indices: false })
    }
    axios
      .post(nowUrl, handleParam, {
        ...config,
        headers: { locale: sessionStorage.getItem('locale'), ...config.headers }
      })
      .then(res => {
        if (res && res.status === 200) {
          if (res.data && res.data.result == 0) {
            resolve(res.data.data)
          } else {
            if (res.data && res.data.message) {
              if (exceptionCB) {
                exceptionCB(res.data.message)
              } else {
                message.warning(h => {
                  return h('span', {
                    domProps: {
                      innerHTML: res.data.message
                    }
                  })
                })
              }
            }
            reject(res.data)
          }
        } else {
          message.error(res.message || '服务器异常！')
          reject()
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

function http(params) {
  var nowUrl =
    params.url.startsWith('http') || params.url.startsWith('https')
      ? params.url
      : path + params.url
  return new Promise((resolve, reject) => {
    axios({
      ...params,
      url: nowUrl,
      headers: { ...params.headers, locale: sessionStorage.getItem('locale') }
    })
      .then(res => {
        if (res && res.status === 200) {
          if (res.data && res.data.result == 0) {
            resolve(res.data.data)
          } else {
            if (res.data && res.data.message) {
              message.warning(h => {
                return h('span', {
                  domProps: {
                    innerHTML: res.data.message
                  }
                })
              })
            }
            reject(res.data)
          }
        } else {
          message.error(res.message || '服务器异常！')
          reject()
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * @description: 获取校验票据
 * @param {*} url
 * @param {*} params
 * @return {*}
 */
function getST(url, params) {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.open('POST', url)
    request.setRequestHeader(
      'Content-type',
      'application/x-www-form-urlencoded;charset=utf-8'
    )
    request.send(qs.stringify(params, { indices: false }))
    request.onload = function() {
      if (request.readyState === 4 && request.status === 200) {
        resolve(request.response)
      } else {
        resolve()
      }
    }
    request.onerror = function(e) {
      reject(e)
    }
  })
}

/**
 * @description: 校验票据
 * @param {*} url
 * @return {*}
 */
function validateST(url) {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      headers: { 'Content-Type': 'text/plain;charset=utf-8' }
    })
      .then(res => {
        if (res && res.status === 200) {
          resolve(res.data)
        } else {
          reject()
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 封装 upload 请求
function httpUpload(url, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(path + url, param, config)
      .then(res => {
        if (res.status == 200) {
          resolve(res.data)
        } else {
          message.error(res.msg || '服务器异常！')
          reject()
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

// 封装 JSONP 请求
function jsonp(url, callback) {
  return new Promise(resolve => {
    window[callback] = result => {
      resolve(result)
    }
    const JSONP = document.createElement('script')
    JSONP.type = 'text/javascript'
    if (url.indexOf('?') > -1) {
      JSONP.src = `${url}&callback=${callback}`
    } else {
      JSONP.src = `${url}?callback=${callback}`
    }
    document.getElementsByTagName('head')[0].appendChild(JSONP)
    setTimeout(() => {
      document.getElementsByTagName('head')[0].removeChild(JSONP)
    }, 500)
  })
}

/**
 * excel 导出
 * @param {*} url
 * @param {*} param
 */
function httpExportToExcel(url, param, filename) {
  return httpBuffer
    .post(url, param)
    .then(res => {
      if (res.status == 200 && res.data) {
        blobToExcel(res.data, filename)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

/**
 * excel 导出
 * @param {*} url
 * @param {*} param
 */
function httpExportToFile(url, param, filename, stuiff) {
  return new Promise((resolve, reject) => {
    httpBuffer
      .post(url, param)
      .then(res => {
        if (res.status == 200 && res.data) {
          downLoadFileWith(res.data, filename, stuiff)
          resolve()
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 下载一个excel
 * @param {} res
 */
function downloadFile(res) {
  var a = document.createElement('a')
  a.id = 'download-excel'
  a.href = res
  a.download = true
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(document.getElementById('download-excel'))
}

export {
  config,
  httpGet,
  httpPost,
  httpUpload,
  http,
  jsonp,
  httpExportToExcel,
  downloadFile,
  httpExportToFile,
  getST,
  validateST,
  unLoginHandler
}
