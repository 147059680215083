/*
 * @Description: router index
 * @Autor: S9637/chifuk
 * @Date: 2020-08-27 18:14:34
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-08-05 20:55:08
 */
// 引入对应路由 list
// 通过 concat 融入 routes 中，避免多人冲突次文件
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@v/index/Index'
import NewIndex from '@v/index/NewIndex'
import homeConf from './homeConf'
import userConf from './user'
import unsubscribe from './unsubscribe'
import announcement from './announcement'
import SCRoutes from './serviceCenterRouter'
// import MCRouter from './msgCenterRouter'
import dataCenter from './dataCenter'
import serviceCenterIndex from './serviceCenterIndex'
import dssRoutes from '../microApps/dss/router/router'
import DSS from '@v/microApps/dss/Index'
import distributionRouter from './distributionRouter'
import oceanWaybillConf from './oceanWaybill'
import airWaybillConf from './airWaybill'
import fbaConf from './fba'
import productService from './productService'
import zh from '@/i18n/locales/title/zh.json'
import en from '@/i18n/locales/title/en.json'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// tag 直发
dssRoutes.forEach(item => {
  item.meta.tag = 'DSS'
})
// tag 空运海运
airWaybillConf.forEach(item => {
  item.meta.tag = 'oceanAir'
})
oceanWaybillConf.forEach(item => {
  item.meta.tag = 'oceanAir'
})
fbaConf.forEach(item => {
  item.meta.tag = 'oceanAir'
})

let routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页',
      ignore: true,
      unLogin: true
    },
    component: NewIndex
  },
  {
    path: '/openProgress',
    name: 'openProgress',
    meta: {
      title: '入驻进度',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "OpenProgress" */ '@v/index/overseasCertfication/OpenProgress'
      )
  },
  {
    path: '/applyContract',
    name: 'applyContract',
    meta: {
      title: '申请合同',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "applyContract" */ '@v/index/overseasCertfication/OpenProgress'
      )
  },
  {
    path: '/contractSigning',
    name: 'contractSigning',
    meta: {
      title: '合同签署',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "contractSigning" */ '@v/index/overseasCertfication/OpenProgress'
      )
  },
  {
    path: '/openSuccess',
    name: 'openSuccess',
    meta: {
      title: '品牌开通成功',
      ignore: true
    },
    component: () =>
      import(
        /* webpackChunkName: "openSuccess" */ '@v/index/overseasCertfication/OpenProgress'
      )
  },
  {
    path: '/demo',
    name: 'demo',
    meta: {
      title: 'demo'
    },
    component: () => import(/* webpackChunkName: "iframeView" */ '@v/demo/Demo')
  },
  {
    path: '/iframeView',
    name: 'iframeView',
    component: () =>
      import(/* webpackChunkName: "iframeView" */ '@v/iframe/IframeView')
  },
  {
    path: '/orderFrame',
    name: 'orderFrame',
    meta: {
      ignore: true,
      tag: 'ORDER'
    },
    component: () =>
      import(
        /* webpackChunkName: "orderFrame" */ '../views/microApps/newOrder/Index'
      )
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () =>
      import(/* webpackChunkName: "unauthorized" */ '@v/other/Unauthorized')
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: () =>
      import(/* webpackChunkName: "refresh" */ '@v/other/Refresh')
  },
  {
    path: '/personal_center',
    name: 'DSS',
    meta: {
      ignore: true,
      tag: 'DSS'
    },
    component: DSS,
    children: [...dssRoutes]
  },
  {
    path: '/trsFrame',
    name: 'trsFrame',
    meta: {
      ignore: true,
      tag: 'TRS'
    },
    component: () =>
      import(/* webpackChunkName: "trsFrame" */ '@v/microApps/trs/Index')
  },
  {
    path: '/jiyun',
    name: 'jiyun',
    meta: {
      ignore: true,
      tag: 'jiyun'
    },
    component: () =>
      import(/* webpackChunkName: "jiyun" */ '../views/microApps/jiyun/Index')
  },
  ...homeConf,
  ...userConf,
  ...announcement,
  ...SCRoutes,
  // ...MCRouter,
  ...dataCenter,
  ...serviceCenterIndex,
  ...oceanWaybillConf,
  ...airWaybillConf,
  ...fbaConf,
  ...productService,
  ...distributionRouter,
  ...unsubscribe
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const isZH = sessionStorage.getItem('locale') != 'en'
router.beforeEach((to, from, next) => {
  document.title = isZH ? zh[to.name] : en[to.name]
  next()
})

export default router
