<i18n>
{
  "zh": {
    "title": "无法开通",
    "tips":"为了更好地为您服务，我们将会安排销售顾问与您洽谈合作，请等待销售顾问与您联系后再重新申请",
    "tips2":"为了更好地为您服务，我们将尽快安排销售顾问与您洽谈合作，你也可以主动联系销售顾问（{msg}）谢谢！",
    "ok":"我知晓了"
  },
  "en": {
    "title": "Unable to open",
    "tips":"In order to better serve you, we will arrange the sales consultant to discuss cooperation with you. Please wait for the sales consultant to contact you before applying again",
    "tips2":"In order to better serve you, we will arrange a sales consultant to discuss cooperation with you as soon as possible. You can also take the initiative to contact the sales consultant ({msg}) Thank you!",
    "ok":"I got it!"
  },
  "de": {
    "title": "Kann nicht geöffnet werden",
    "tips": "Um Sie besser betreuen zu können, werden wir einen Verkaufsberater beauftragen, die Zusammenarbeit mit Ihnen zu besprechen. Bitte warten Sie, bis der Verkaufsberater Sie kontaktiert, bevor Sie sich erneut bewerben.",
    "tips2":"Um Sie besser bedienen zu können, vereinbaren wir schnellstmöglich einen Verkaufsberater, um die Zusammenarbeit mit Ihnen zu besprechen. Sie können auch die Initiative ergreifen, den Verkaufsberater ({msg}) zu kontaktieren.",
    "ok": "Ich weiß"
  },
  "ja": {
    "title": "開くことができない",
    "tips": "より良いサービスを提供するため、販売コンサルタントを手配し、協力についてご相談させていただきますので、再申請の前に販売コンサルタントからの連絡をお待ちください",
    "tips2":"お客様により良いサービスを提供するために、できるだけ早く営業コンサルタントを手配してお客様と商談を進めることにします。また、営業コンサルタント（｛msg｝）にも積極的に連絡してください。ありがとうございます。",
    "ok": "なんでも"
  }
}
</i18n>

<template>
  <div class="signing-box">
    <a-modal
      width="480px"
      :visible="changeClueModal"
      :title="mTitle ? mTitle : $t('title')"
      :maskClosable="false"
      :bodyStyle="{ 'padding-top': 0 }"
      @cancel="$emit('cancel')"
      :footer="null"
    >
      <div class="tips">{{ $t('tips2', { msg: msg }) }}</div>
      <a-form-model-item
        :style="{ 'margin-top': '24px', 'text-align': 'right' }"
      >
        <a-button type="primary" @click="$emit('cancel')">
          {{ $t('ok') }}
        </a-button>
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ChangeClue',
  props: {
    changeClueModal: {
      type: Boolean,
    },
    msg: {
      type: String,
    },
    mTitle: {
      type: String,
    },
  },
}
</script>
<style lang="less" scoped>
.tips {
  font-size: 14px;
  line-height: 24px;
  color: #323643;
}
</style>
