/*
 * @Description: 全局过滤器
 * @Autor: S9637/chifuk
 * @Date: 2020-08-27 18:14:35
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-02-25 19:53:53
 */
import moment from 'moment'

/**
 * @description: date format
 * @param {*} date
 * @param {*} format
 * @param {*} spreate 链接符号
 * @return {*} String
 */
const f_date = (date, format, spreate) => {
  if (!date) return '-'
  if (format) {
    return moment(date).format(format)
  } else if (spreate) {
    return moment(date).format(`YYYY${spreate}MM${spreate}DD HH:mm:ss`)
  } else {
    return moment(date).format('YYYY.MM.DD HH:mm:ss')
  }
}

/**
 * 日期格式化显示 无时间
 * @param {*} date
 */
const f_date_notime = date => {
  return moment && date ? moment(date).format('YYYY-MM-DD') : '-'
}

/**
 * 格式化百分比
 * @param {*} num 数值
 * @param {*} fix 精确度到第几位
 */
const f_rate = (num, fix = 1) => {
  console.log((Number(num) * 100).toFixed(fix))
  return (Number(num) * 100).toFixed(fix)
}
const toMoney = num => {
  if (num) {
    num = typeof num == 'string' ? parseFloat(num) : num //判断是否是字符串如果是字符串转成数字
    num = num.toFixed(2) //保留两位
    num = parseFloat(num) //转成数字
    num = num.toLocaleString() //转成金额显示模式
    //判断是否有小数
    if (num.indexOf('.') == -1) {
      num = num + '.00'
    } else {
      num = num.split('.')[1].length < 2 ? num + '0' : num
    }
    // 返回的是具有千分位格式并保留2位小数的字符串
  } else {
    num = '0.00'
  }
  return num
}

const nval = val => {
  return val ? val : '-'
}

// 通过value 获取nanme
const getNameFromValue = (value = '', arr = []) => {
  if (value == '' || arr == [] || arr == '') {
    return '-'
  }
  let item = (arr || []).find(item => item?.value == value)
  return item ? item?.name : value || ' -'
}

export { f_date, f_date_notime, f_rate, toMoney, nval, getNameFromValue }
