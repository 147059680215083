<template>
  <div>
    <a href="javascript:;" class="wc-icon" @click="openWcDailog">
      <i class="iconfont iconweixindingyue" style="font-size: 22px"></i>
    </a>
    <div v-if="visible" class="mask">
      <div class="dialog">
        <div class="close " @click="closeWcDailog"></div>
        <img
          src="../../assets/wc_bind_bg.png"
          alt=""
          class="dialog_bg"
          width="326"
          v-if="visible"
        />
        <img v-if="qrUrl" :src="qrUrl" class="eq_img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      bohaiLogo: '',
      qrUrl: ''
    }
  },
  created() {
    // this.checkQrCodeRecord()
    var that = this
    document.addEventListener('click', function(e) {
      e?.path?.forEach(item => {
        if (item?.className && item?.className?.indexOf('bohai-btn') > -1) {
          that.visible = false
        }
      })
    })
  },
  watch: {
    visible(nval, oval) {
      if (nval) {
        this.createQrcode()
      } else {
        this.qrUrl = ''
      }
    }
  },
  methods: {
    openWcDailog() {
      this.visible = !this.visible
    },
    closeWcDailog() {
      this.insertQrCodeRecord()
    },
    // 判断客户是否查看过二维码
    checkQrCodeRecord() {
      this.$post('v2/publicAccount/existQrCodeRecord', {}).then(res => {
        if (res?.showQrCodeNote) {
          this.visible = true
          this.createQrcode()
        }
      })
    },
    // 获取二维码
    createQrcode() {
      this.$show()
      this.$post('v2/publicAccount/createQrcode', {})
        .then(res => {
          this.$hide()
          this.qrUrl = res?.showQrCodeUrl
        })
        .catch(() => {
          this.$hide()
        })
    },
    // 新增微信订阅记录
    insertQrCodeRecord() {
      this.$show()
      this.$post('v2/publicAccount/insertQrCodeRecord', {
        type: 'wechatQrCode'
      })
        .then(() => {
          this.openWcDailog()
          this.$hide()
        })
        .catch(() => {
          this.openWcDailog()
          this.$hide()
        })
    }
  }
}
</script>

<style lang="less" scoped>
.wc-icon {
  margin-right: 16px;
  color: #23a470;
  position: relative;
}

.mask {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: calc(100vh);
  background: rgba(0, 0, 0, 0.3);
}
.dialog {
  position: absolute;
  top: calc(50vh - 458px / 2);
  left: calc(50% - 494px / 2);
  width: 494px;
  height: 458px;
  box-shadow: 0px 2px 24px 0px rgba(207, 209, 216, 0.66);
  // background: #fff;
}
.dialog .dialog_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 494px;
  height: 458px;
}
.dialog .eq_img {
  position: absolute;
  top: 230px;
  left: 208px;
  width: 92px;
  height: 92px;
  z-index: 1000;
}
.dialog .close {
  position: absolute;
  top: 22px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 1000;
  cursor: pointer;
}
</style>
