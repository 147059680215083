import { render, staticRenderFns } from "./ProtocolView.vue?vue&type=template&id=bfc4c09e&functional=true"
var script = {}
import style0 from "./ProtocolView.vue?vue&type=style&index=0&id=bfc4c09e&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports