<i18n>
{
  "zh": {
    "title": "直发下单工作台全新升级",
    "try": "立即体验",
    "index0": "融入商家中心",
    "index1": "各业务统一入口",
    "index2": "页面风格",
    "index3": "统一美观便捷",
    "index4": "下单流程优化",
    "index5": "提升下单效率"
  },
  "en": {
    "title": "Straight-forward order workbench new upgrade",
    "try": "Experience now",
    "index0": "Integrate into the Merchant Center",
    "index1": "Unified entry point for each business",
    "index2": "Page Style",
    "index3": "Unified and beautiful and convenient",
    "index4": "Order process optimization",
    "index5": "Increased order efficiency"
  },
  "de": {
    "title": "Straight-forward order workbench new upgrade",
    "try": "Experience now",
    "index0": "Integrate into the Merchant Center",
    "index1": "Unified entry point for each business",
    "index2": "Page Style",
    "index3": "Unified and beautiful and convenient",
    "index4": "Order process optimization",
    "index5": "Increased order efficiency"
  },
  "ja": {
    "title": "Straight-forward order workbench new upgrade",
    "try": "Experience now",
    "index0": "Integrate into the Merchant Center",
    "index1": "Unified entry point for each business",
    "index2": "Page Style",
    "index3": "Unified and beautiful and convenient",
    "index4": "Order process optimization",
    "index5": "Increased order efficiency"
  }
}
</i18n>
<template>
  <div class="dss-guide">
    <TipView v-if="next" />
    <a-modal
      wrapClassName="dss-guide-wrapper"
      width="640px"
      :visible="visible"
      :closable="false"
      :footer="null"
    >
      <div class="title">{{ $t('title') }}</div>
      <a-carousel autoplay>
        <div :key="index" v-for="(item, index) in imgs">
          <img :src="item.img" alt="直发下单工作台全新升级" />
          <div class="dss-guide-title">
            <p class="t1">{{ item.msg[0] }}</p>
            <p class="t2">{{ item.msg[1] }}</p>
          </div>
        </div>
      </a-carousel>
      <a-button class="top-24px" @click="onClickTry" type="primary">{{
        $t('try')
      }}</a-button>
    </a-modal>
  </div>
</template>

<script>
import TipView from './TipView.vue'
export default {
  name: 'dss-guide',
  components: {
    TipView
  },
  data() {
    return {
      visible: true,
      next: false,
      imgs: [
        {
          img: require('./imgs/3.png'),
          msg: [this.$t('index0'), this.$t('index1')]
        },
        {
          img: require('./imgs/2.png'),
          msg: [this.$t('index2'), this.$t('index3')]
        },
        {
          img: require('./imgs/1.png'),
          msg: [this.$t('index4'), this.$t('index5')]
        }
      ]
    }
  },
  methods: {
    /**
     * @description: 立即体验
     * @param {*}
     * @return {*}
     */
    onClickTry() {
      this.visible = false
      this.next = true
    }
  }
}
</script>

<style lang="scss">
.dss-guide-wrapper {
  text-align: center;
  .title {
    color: #363636;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 14px;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -12px;
  }
  .ant-carousel .slick-dots li {
    background: rgb(195 203 213 / 39%);
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #c3cbd5;
  }
  .dss-guide-title {
    position: absolute;
    top: 35%;
    text-align: left;
    padding-left: 12px;
    .t1 {
      font-family: PingFangSC-Light;
      color: #fff;
      font-size: 20px;
      line-height: 28px;
    }
    .t2 {
      font-family: PingFangSC-Semibold;
      color: #fff;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>
