var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hideTitle)?_c('div',{staticClass:"recommend-tit-box"},[_c('div',{staticClass:"recommend-tit"},[_vm._v("推荐产品")])]):_vm._e(),_c('div',{staticClass:"recommend-product"},[_vm._l((_vm.recommendList),function(item,pIndex){return [(pIndex < _vm.showNum)?_c('div',{key:pIndex,staticClass:"recom-item",style:({
          width: _vm.showNum === 3 ? 'calc(33.3% - 72px)' : 'calc(25% - 12px)'
        }),attrs:{"target":"_blank"},on:{"click":function($event){return _vm.goDetail(item, $event)}}},[(!item.picUrl)?_c('div',{staticClass:"recom-top-box"},[_c('div',{staticClass:"name",style:({ width: item.tagType ? 'calc(100% - 50px)' : '100%)' })},[_c('a-popover',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(item.customTitle ? item.customTitle : `${item.productName || '-'}(${item.productCode || '-'})`)+" ")]},proxy:true}],null,true)},[_c('span',[_vm._v(_vm._s(item.customTitle ? item.customTitle : `${item.productName || '-'}(${item.productCode || '-'})`)+" ")])])],1),(item.tagType)?_c('div',{staticClass:"re-tag",class:{
              're-tag-p': item.tagType == '限时促销',
              're-tag-n': item.tagType == '新品上线'
            }},[_vm._v(" "+_vm._s(item.tagType)+" ")]):_vm._e()]):_vm._e(),(!item.picUrl)?_c('div',{staticClass:"recom-box"},[(item.copyWriting)?_c('div',{staticClass:"recom-des-item"},[_c('i',{staticClass:"iconfont iconduihao"}),_vm._v(" "+_vm._s(item.copyWriting || '-')+" ")]):_vm._e(),_c('div',{staticClass:"recom-des-item"},[(item.labelCodeList.length > 0)?_c('i',{staticClass:"iconfont iconduihao"}):_vm._e(),_vm._l((item.labelCodeList),function(tItem,tIndex){return _c('span',{key:tIndex},[_vm._v("「"+_vm._s(tItem)+"」")])})],2)]):_vm._e(),(item.picUrl)?_c('div',{staticClass:"recom-bg",style:({
            'background-image': `url(${item.picUrl})`
          })}):_vm._e(),(item.duration || item.price)?_c('div',{staticClass:"price-box",class:{ 'no-border': item.picUrl }},[_c('div',{staticClass:"price-item"},[(item.duration)?_c('div',{staticClass:"num-data"},[_c('span',{staticClass:"num-text"},[_vm._v(_vm._s(item.duration || '-'))]),_c('span',{staticClass:"unit"},[_vm._v("天")])]):_c('div',{staticClass:"unit"},[_vm._v("暂无预估时效")])]),_c('div',{staticClass:"price-item"},[_c('div',{staticClass:"num-data"},[(item.price)?_c('span',{staticClass:"blue num-text"},[_vm._v(_vm._s(_vm._f("toMoney")(item.price)))]):_vm._e(),(item.price)?_c('span',{staticClass:"unit"},[_vm._v("元/KG起")]):_c('span',{staticClass:"unit"},[_vm._v("暂无预估价格")]),(item.priceReduction)?_c('span',{staticClass:"price-reduction"},[_c('i',{staticClass:"iconfont iconshujuxiajiang"}),_vm._v(_vm._s(item.priceReduction))]):_vm._e()])])]):_c('div',{staticClass:"price-box",class:{ 'no-border': item.picUrl }},[_c('div',{staticClass:"no-price-text"},[_vm._v("该产品线路暂无预估时效价格")])])]):_vm._e()]}),(_vm.showNum === 3)?_c('div',{staticClass:"more-btn",on:{"click":_vm.goProductService}},[_c('i',{staticClass:"iconfont icongengduochanpin"}),_c('div',{staticClass:"text"},[_vm._v("更多产品")])]):_vm._e(),(_vm.recommendList.length < 1 && _vm.showNum !== 3)?_c('a-empty',{staticClass:"empty-box",attrs:{"image":_vm.emptyImage,"image-style":{
        width: '180px',
        height: '140px'
      },"description":"产品上架中…敬请期待"}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }