<template>
  <div class="carousel-box">
    <a-carousel autoplay :autoplaySpeed="5000">
      <div
        v-for="(bItem, index) in bannerList"
        class="carousel-item"
        :key="index"
      >
        <div
          @click="goDetail(bItem, index, $event)"
          class="banner-box"
          :style="{ 'background-image': `url(${bItem.picUrl})` }"
        ></div>
      </div>
    </a-carousel>
    <a-empty
      class="empty-box"
      v-if="bannerList.length < 1"
      :image="emptyImage"
      :image-style="{
        width: '180px',
        height: '140px'
      }"
      description="banner配置中…敬请期待"
      v-cloak
    ></a-empty>
  </div>
</template>
<script>
import sensors from 'sa-sdk-javascript'
export default {
  name: 'BannerList',
  data() {
    return {
      // 自定义自动播放设置

      emptyImage: require('../../../assets/newIndex/no_data.png'),
      bannerList: [],
      tabValue: 0
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {},
  methods: {
    goDetail(item, index, event) {
      sensors.track('BannerClick', {
        ...event?.target,
        page_type: '首页', //	页面类型	首页
        banner_type: '广告图片', //	banner	广告图片
        banner_name: item.adName, //		banner名称
        index: index + 1, //		序号
        to_url: item.redirectUrl //	跳转链接
      })
      // 跳转新窗口 redirectUrl
      if (item?.redirectUrl && item?.redirectUrl.length > 0) {
        window.open(item.redirectUrl)
      }
    },
    getBanner() {
      this.$get('v2/homepage/queryBannerList/homePage').then(res => {
        if (res.length > 0) {
          this.bannerList = res || []
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
* {
  caret-color: transparent;
}

.carousel-box {
  width: 66.67%;
  border-radius: 8px;
  text-align: center;
  .carousel-item {
    height: 260px;
    img {
      width: 100%;
      height: 260px;
    }
    .banner-box {
      width: 100%;
      height: 260px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
.empty-box {
  padding-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
::v-deep.ant-carousel .slick-dots-bottom {
  bottom: 16px !important;
}
.ant-carousel .slick-dots {
  height: 6px !important;
}
::v-deep.carousel-box .ant-carousel .slick-dots li button {
  width: 6px !important;
  height: 6px !important;
  border-radius: 3px !important;
}
::v-deep.ant-carousel .slick-dots li.slick-active button {
  width: 16px !important;
  height: 6px !important;
  border-radius: 3px !important;
}
</style>
