<template>
  <div class="country" @click="countryChose">
    <a-spin :spinning="spining">
      <a-tabs :defaultActiveKey="0">
        <a-tab-pane
          :tab="idx == 0 && $i18n.locale == 'en' ? 'hot country' : t.name"
          :key="idx"
          v-for="(t, idx) in list"
        >
          <template v-for="(c, index) in t.country">
            <div v-if="c.countrys.length > 0" :key="index" class="c-l">
              {{ c.name }}
            </div>
            <div :key="index + 'r'" class="c-r">
              <p
                :key="item.code"
                v-for="item in c.countrys"
                :class="item.isSelected == 1 ? 'ds' : ''"
                :title="$i18n.locale == 'zh' ? item.display : item.ename"
                :data-val="
                  JSON.stringify({
                    code: item.code,
                    name: $i18n.locale == 'zh' ? item.display : item.ename
                  })
                "
              >
                {{ $i18n.locale == 'zh' ? item.display : item.ename }}
              </p>
            </div>
          </template>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
/**
 * 扁平转化国家数据 & 过滤掉热门国家, 筛选出有用的国家
 * @returns
 */
export const transferTree = (tree = []) => {
  const target = tree
    ?.filter(x => x.name !== '热门国家')
    .map(({ country, ...others }) => ({
      ...others,
      countrys: country
    }))
  let result = []
  const transfer = tree => {
    for (let node of tree) {
      if (
        node.name &&
        typeof node.name === 'string' &&
        node.name?.length === 1
      ) {
        result.push({
          label: node.name,
          children: node.countrys?.map(x => ({
            ...x,
            value: x.code,
            label: x?.display
          }))
        })
      }
      node.countrys &&
        Array.isArray(node.countrys) &&
        node.countrys?.length &&
        transfer(node.countrys)
    }
  }
  transfer(target)
  // 对result过滤去重，并排序
  result = (result || [])
    .reduce((prev, curr) => {
      if (prev.findIndex(x => x?.label === curr?.label) === -1) {
        prev.push(curr)
      }
      return prev
    }, [])
    .sort((a, b) => a - b)
  return result
    ?.reduce((p, c) => {
      p.push(...(c?.children || []))
      return p
    }, [])
    ?.filter(x => x.isSelected !== 1)
}
import { http_getCountryList } from '@microDss/service/http/http'
export default {
  name: 'country',
  props: {
    query: {
      type: Object
    }
  },
  data() {
    return {
      list: [],
      spining: true
    }
  },
  watch: {
    query: {
      handler: 'getCountryList',
      immediate: true,
      deep: true
    }
  },
  methods: {
    /**
     * 获取当前选中的国家
     * @param event
     * @returns
     */
    countryChose(event) {
      if (event.target.nodeName.toUpperCase() == 'P') {
        this.$emit('getterCountry', event.target.dataset.val)
      }
    },
    async getCountryList() {
      this.spining = true
      const dt = await http_getCountryList(this.query)
      if (!sessionStorage.getItem('c_y'))
        sessionStorage.setItem('c_y', JSON.stringify(dt))

      let dataSource = Object.freeze(dt) // 模拟数据源
      const flatData = transferTree(dt) // 递归-扁平转化国家数据 & 过滤掉热门国家, 筛选出其他有用的国家集
      let flagNames = []; // 剔除名单--针对顶层tab
      dataSource.forEach((item) => {
        item.country.forEach(node => {
          node.countrys = node.countrys.filter(x => x.isSelected !== 1)
          if (node.name === 'HOT' && node.countrys?.length < 8) {
            const hotLenth = node.countrys.length
            const suffixLenth = 8 - hotLenth
            // 当前的这些热门国家不能包含在内
            const filterData =
              flatData.filter(
                x => !node.countrys?.map(y => y?.code)?.includes(x?.code)
              ) || []
            if (filterData?.length >= suffixLenth) {
              const suffixData = filterData.slice(0, suffixLenth)
              node.countrys = node.countrys.concat(suffixData)
            } else if (filterData?.length < suffixLenth) {
              const suffixData = filterData.slice(0, filterData?.length)
              node.countrys = node.countrys.concat(suffixData)
            }
          }
          // 剔除一个都没有的
          if (item.country.every(x => !x.countrys.length) && item.name !== "热门国家") {
            flagNames.push(item.name);
          }
        })
      })
      dataSource = dataSource.filter(x => !flagNames.includes(x.name))
      this.list = dataSource || []
      this.spining = false
    }
  }
}
</script>

<style lang="scss" scoped>
.country {
  .ant-tabs-tabpane {
    max-height: 30vh;
    min-height: 120px;
    overflow-y: auto;
  }
  .c-l {
    width: 50px;
    color: red;
    float: left;
  }
  .c-r {
    float: left;
    width: 90%;
    p {
      display: inline-block;
      width: 130px;
      padding: 6px;
      margin-right: 2px;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 4px;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #005adc;
        color: #fff;
      }
    }
    .ds {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      display: none;
    }
  }
}
</style>
