import { http, httpGet, httpPost } from '@/api/index'

// 请求接口，判断轨迹是否显示
export const grayscaleSwitch = () => {
  return new Promise((resolve, reject) => {
    httpPost('v2/trackingSubscribe/grayscaleSwitch', {
      itemType: 'trackingGrayscaleSwitch'
    })
      .then(res => {
        resolve(res)
      })
      .catch(res => {
        reject(res)
      })
  })
}

export const firstDirectory = () => {
  return new Promise((resolve, reject) => {
    httpGet('v2/contCatalog/firstDirectory')
      .then(res => {
        resolve(res)
      })
      .catch(() => {
        reject('error')
      })
  })
}
