<!--
 * @Description: 
 * @Autor: S9637/chifuk
 * @Date: 2020-09-23 20:49:47
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-13 12:38:17
-->
<i18n>
{
  "zh": {
    "Proportionofthenumberofeachagesegment": "各库龄段的数量占比",
    "numberofSKUs": "不同时间段的库龄占在库SKU件数的百分比:",
    "Volumeproportionofeachagesegment": "各库龄段的体积占比",
    "skuvolume": "不同时间段的库龄占在库SKU体积的百分比"
  },
  "en": {
    "Proportionofthenumberofeachagesegment": "Proportion of the number of each age segment",
    "numberofSKUs": "The percentage of stock age in the number of SKUs in stock at different time periods",
    "Volumeproportionofeachagesegment": "Volume proportion of each age segment",
    "skuvolume": "The percentage of stock age in sku volume in different time periods"
  },
  "de": {
    "Proportionofthenumberofeachagesegment": "Proportion of the number of each age segment",
    "numberofSKUs": "The percentage of stock age in the number of SKUs in stock at different time periods",
    "Volumeproportionofeachagesegment": "Volume proportion of each age segment",
    "skuvolume": "The percentage of stock age in sku volume in different time periods"
  },
  "ja": {
    "Proportionofthenumberofeachagesegment": "Proportion of the number of each age segment",
    "numberofSKUs": "The percentage of stock age in the number of SKUs in stock at different time periods",
    "Volumeproportionofeachagesegment": "Volume proportion of each age segment",
    "skuvolume": "The percentage of stock age in sku volume in different time periods"
  }
}
</i18n>
<template>
  <div class="store-time-chart">
    <div class="pie-wrapper">
      <div class="pie-title">
        {{ $t('Proportionofthenumberofeachagesegment') }}
        <a-tooltip>
          <template slot="title">
            {{ $t('numberofSKUs') }}
          </template>
          <img
            src="../../../assets/common/info.png"
            alt=""
            style="margin-bottom: -2px;"
          />
        </a-tooltip>
      </div>
      <ve-pie
        v-if="$attrs.chartDataQuantity.rows.length > 0"
        :data="$attrs.chartDataQuantity"
        :extend="extend1"
      ></ve-pie>
      <a-empty style="height:400px;padding-top:100px;" v-else />
    </div>
    <div class="pie-wrapper">
      <div class="pie-title">
        {{ $t('Volumeproportionofeachagesegment') }}
        <a-tooltip>
          <template slot="title">
            {{ $t('skuvolume') }}
          </template>
          <img
            src="../../../assets/common/info.png"
            alt=""
            style="margin-bottom: -2px;"
          />
        </a-tooltip>
      </div>
      <ve-pie
        v-if="$attrs.chartDataVolume.rows.length > 0"
        :data="$attrs.chartDataVolume"
        :extend="extend2"
      ></ve-pie>
      <a-empty style="height:400px;padding-top:100px;" v-else />
    </div>
  </div>
</template>

<script>
import VePie from 'v-charts/lib/pie.common'

export default {
  name: 'store-time-chart',
  inheritAttrs: false,
  components: {
    VePie
  },
  data() {
    this.extend1 = {
      ...this.$defaultChartSettings,
      color: [
        '#0066FF',
        '#5B99F9',
        '#C360FB',
        '#D07FFE',
        '#FF93B8',
        '#FF7AA7',
        '#F64681'
      ],
      legend: {
        orient: 'vertical',
        right: '5%',
        top: 'center',
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10
      },
      series: {
        type: 'pie',
        radius: ['30%', '50%'],
        left: '-20%',
        label: {
          formatter: '{b}',
          color: '#333'
        }
      }
    }

    this.extend2 = {
      ...this.$defaultChartSettings,
      color: [
        '#18BD92',
        '#60CFB2',
        '#9FE21E',
        '#C2E77B',
        '#FFBC90',
        '#FF944E',
        '#FF6B09'
      ],
      legend: {
        orient: 'vertical',
        right: '5%',
        top: 'center',
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10
      },
      series: {
        type: 'pie',
        radius: ['30%', '50%'],
        left: '-20%',
        label: {
          formatter: '{b}',
          color: '#333'
        }
      },
      tooltip: {
        trigger: 'item',
        backgroundColor: '#1F2E55',
        formatter: function(obj) {
          return `${obj.name}: ${obj.value} cm³ (${obj.percent}%)`
        }
      }
    }
    return {}
  },
  mounted() {
    if (this.$attrs.extend) {
      Object.assign(this.extend1, this.$attrs.extend)
      Object.assign(this.extend2, this.$attrs.extend)
    }
  }
}
</script>

<style lang="less" scoped>
.store-time-chart {
  width: 100%;
  background-color: #eef2f6;
}
.pie-title {
  margin: 20px auto auto 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  border-left: 2px solid #065cf3;
  line-height: 14px;
  padding-left: 3px;
}
.gap-space {
  width: 16px;
  background-color: #eef2f6;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}
.pie-wrapper {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  background-color: #fff;
  border-radius: 4px;
}
</style>
