<i18n>
{
  "zh": {
   "tips": "开通海外仓服务需要进行企业实名认证，您本账号已做个人实名认证，如需开通海外仓服务，请另注册账号重新认证。"

  },
  "en": {
    "tips": "Opening the overseas warehouse service requires enterprise real name authentication. Your account has been verified with your personal real name. If you need to open the overseas warehouse service, please register another account for re authentication."
  },
  "de": {
    "tips": "Für die Eröffnung des Auslandslagerdienstes ist die Authentifizierung des Unternehmens erforderlich. Ihr Konto wurde mit Ihrem persönlichen echten Namen verifiziert. Wenn Sie den Auslandslagerdienst öffnen müssen, registrieren Sie bitte ein anderes Konto zur erneuten Authentifizierung."
  },
  "ja": {
    "tips": "海外倉庫サービスの開設には企業の実名認証が必要です。あなたのアカウントはすでに個人の実名認証をしています。海外倉庫サービスを開設する必要があれば、別のアカウントを登録して再認証してください。"
  }
}
</i18n>
<template>
  <a-modal
    :footer="null"
    :title="$t('friendtip')"
    :visible="FB4Visible"
    @cancel="$emit('cancel')"
    width="600px"
    :maskClosable="false"
    :bodyStyle="{ paddingTop: 0 }"
  >
    <div style="padding: 30px 0;color:#e13f3f;line-height:1.8;">
      {{ $t('tips') }}
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'FB4TipsModal',
  data() {
    return {}
  },
  props: {
    FB4Visible: {
      type: Boolean
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="less" scoped></style>
