<!--
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-08-28 13:55:31
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-05-19 21:41:12
-->
<template>
  <div class="slider-wrapper">
    <a-menu
      class="slider"
      :openKeys="openGroupKeys"
      :selectedKeys="selectedKeys"
      mode="inline"
      @click="handleClick($event)"
      @openChange="handleOpenClick"
      :inline-collapsed="collapsed"
    >
      <template v-for="item in menuItems">
        <a-sub-menu v-if="item.sub" :key="item.name">
          <span slot="title"
            ><i class="iconfont" :class="item.icon || 'iconfile'" /><span>{{
              item.text
            }}</span></span
          >

          <template v-for="s in item.sub">
            <a-sub-menu v-if="s.sub" :title="s.text" :key="'sub2' + s.name">
              <a-menu-item
                style="padding-left: 8px"
                :key="x.name"
                v-for="x in s.sub"
                :data-baidu-pv="x.pv"
              >
                <a :href="urlLink(x.name)">{{ x.text }}</a>
              </a-menu-item>
            </a-sub-menu>

            <a-menu-item v-else :key="s.name" :data-baidu-pv="s.pv">
              <a :href="urlLink(s.name)">{{ s.text }}</a>
            </a-menu-item>
          </template>
        </a-sub-menu>

        <a-menu-item v-else :key="item.name" :data-baidu-pv="item.pv">
          <a :href="urlLink(item.name)">
            <i class="iconfont" :class="item.icon || 'iconfile'" />
            <span>{{ item.text }}</span>
          </a>
        </a-menu-item>
      </template>
    </a-menu>
    <!-- <div
      :style="collapsed ? 'width:80px' : 'width:200px'"
      class="slider-collapsed"
      @click="triggerCollapsed"
    >
      <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'slider',
  props: {
    // 菜单项：显示顺序同数组顺序
    menuItems: {
      type: Array
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedKeys() {
      const first = this.$store.state.defaultSelectedKeys
      let defaultSelectedKeys = []
      if (first && first.length > 0) {
        const currName = first[0]
        this.findName(defaultSelectedKeys, this.menuItems, currName)
      }
      return defaultSelectedKeys
    },
    openGroupKeys() {
      return this.$store.state.defaultOpenKeys
    }
  },
  data() {
    return {
      AppENV: process.env.NODE_ENV,
      isMicroApp: ''
      // collapsed: false,
    }
  },
  watch: {
    $route(to) {
      // 判断融入微前端应用
      if (
        to.name == 'orderFrame' ||
        to.name == 'jiyun' ||
        to.name == 'trsFrame'
      ) {
        this.isMicroApp = to.name + '#'
      } else {
        this.isMicroApp = ''
      }
    }
  },
  methods: {
    findName(defaultSelectedKeys, arr = [], currName) {
      arr.length > 0 &&
        arr.some(item => {
          if (item.sub) {
            this.findName(defaultSelectedKeys, item.sub, currName)
          } else {
            if (item.name === currName) {
              defaultSelectedKeys.push(currName)
              return true
            }
            return false
          }
        })
    },
    urlLink(name) {
      let url = this.isMicroApp + name
      if (!name) return ''
      if (this.AppENV != 'development') {
        url = '/dist/' + this.isMicroApp + name
      }
      return url
    },
    triggerCollapsed() {
      this.collapsed = !this.collapsed
      this.$emit('collapsed', this.collapsed)
    },
    handleOpenClick(groups) {
      this.$store.state.defaultOpenKeys = groups
    },
    findRouteconfig(arr = [], currRouteObj, currName) {
      arr.length > 0 &&
        arr.some(item => {
          if (item.sub) {
            this.findRouteconfig(item.sub, currRouteObj, currName)
          } else {
            if (item.name === currName) {
              Object.assign(currRouteObj, item)
              return true
            }
            return false
          }
        })
    },
    /**
     * @description: 路由切换
     * @param {type}
     * @return {type}
     */
    handleClick(item) {
      var event = item.domEvent || window.event
      event.preventDefault()
      const currName = item.key
      let currRouteObj = {}
      this.findRouteconfig(this.menuItems, currRouteObj, currName)
      // 左侧菜单状态控制
      this.$store.state.defaultSelectedKeys = [currName]
      // 设置路由 query、params
      const routeConfig = { name: item.key }
      // 当前路由为动态获取时，截取router name
      if (item.key.indexOf('#isDynamic') > -1) {
        let index = item.key.lastIndexOf('#isDynamic')
        routeConfig.name = item.key.substring(0, index)
      }
      if (currRouteObj) {
        if (currRouteObj.query) routeConfig.query = currRouteObj.query
        if (currRouteObj.params) routeConfig.params = currRouteObj.params
      }

      // 父级菜单
      const menus = this.$store.state.menuItems
      let pname = ''
      menus.forEach(g => {
        if (g.sub) {
          g.sub.forEach(c => {
            if (c.name === routeConfig.name) {
              pname = g.text
            }
          })
        } else {
          pname = g.text
        }
      })
      if (this.$route.name == 'iframeView') {
        this.$root.bus.$emit('iframe-menu-change', currRouteObj.url)
      } else {
        // micrc app route
        if (
          location.pathname.indexOf('trsFrame') > -1 ||
          location.pathname.indexOf('orderFrame') > -1 ||
          location.pathname.indexOf('jiyun') > -1
        ) {
          location.hash = routeConfig.name
        } else {
          this.$router.push(routeConfig)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.slider.ant-menu {
  padding-top: 14px;
  a,
  a:link {
    color: rgba(0, 0, 0, 0.65) !important;
  }
}
.ant-menu-item > a {
  display: block;
  color: rgba(0, 0, 0, 0.65) !important;
}
.slider .iconfont {
  margin-right: 9px;
  color: #878fa6;
}

.ant-menu-item:hover,
.ant-menu-submenu-title:hover {
  .iconfont {
    // transition: all 0.3s;
    color: rgb(0, 102, 255);
  }
}

.ant-menu-item,
.ant-menu-submenu-title {
  .iconfont {
    // transition: all 0.3s;
    color: #878fa6;
  }
}

.ant-menu-item-selected,
.ant-menu-submenu {
  width: 200px;
  margin: 4px 12px;
  line-height: 36px;
  border-radius: 4px;
}
.ant-menu-submenu-title:hover {
  color: red;
  .iconfont {
    color: red;
  }
  // color: #3960df;
}
// 没有子菜单
.slider-wrapper .ant-menu-root > .ant-menu-item {
  width: 200px;
  margin: 8px 12px;
  padding-left: 8px !important;
  height: 36px !important;
  line-height: 36px;
  border-radius: 4px;
  font-weight: 600;
  color: #323643;
  a {
    color: #323643 !important;
  }
}
.slider-wrapper .ant-menu-root > .ant-menu-item.ant-menu-item-selected {
  width: 200px;
  margin: 8px 12px;
  padding-left: 8px !important;
  height: 36px !important;
  line-height: 36px;
  border-radius: 4px;
  font-weight: 600;
  background: #3960df;
  color: #fff !important;
  a {
    color: #fff !important;
  }
  .iconfont {
    color: #fff;
  }
}

.slider-wrapper .ant-menu-root > .ant-menu-item.ant-menu-item-active {
  width: 200px;
  margin: 8px 12px;
  padding-left: 8px !important;
  height: 36px !important;
  line-height: 36px;
  border-radius: 4px;
  font-weight: 600;
  color: #3960df !important;
}
.slider-wrapper
  .ant-menu-root
  > .ant-menu-item.ant-menu-item-active.ant-menu-item-selected {
  width: 200px;
  margin: 8px 12px;
  padding-left: 8px !important;
  height: 36px !important;
  line-height: 36px;
  border-radius: 4px;
  font-weight: 600;
  color: #fff !important;
}

::v-deep.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 8px !important;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 36px !important;
  line-height: 36px;
  color: #323643;
  font-weight: 600;
}
// 选中的父级菜单
.ant-menu-sub .ant-menu-item-selected,
.ant-menu-sub .ant-menu-submenu-active,
.ant-menu-sub .ant-menu-submenu-selected {
  width: 200px;
  margin: 6px 12px;
  line-height: 36px;
  border-radius: 4px;
  color: #3960df;
  .iconfont {
    color: #3960df;
  }
}

::v-deep.ant-menu-inline > .ant-menu-submenu-selected > .ant-menu-submenu-title,
::v-deep.ant-menu-inline > .ant-menu-submenu-active > .ant-menu-submenu-title {
  padding-left: 8px !important;
  height: 36px !important;
  line-height: 36px;
  border-radius: 4px;
  color: #3960df;
  background: #eef1f6;
  .iconfont {
    color: #3960df;
  }
}

// 选中的子菜单
.ant-menu:not(.ant-menu-horizontal).ant-menu-sub .ant-menu-item {
  margin: 4px auto;
  padding-left: 34px !important;
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 6px;
  &::hover {
    margin: 0;
    padding-left: 34px !important;
    width: 200px;
    height: 36px;
    line-height: 36px;
    color: #fff;
    background: #005adc;
  }
}
.ant-menu:not(.ant-menu-horizontal).ant-menu-sub .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal).ant-menu-sub .ant-menu-item-active {
  margin: 4px auto;
  padding-left: 34px !important;
  width: 200px;
  height: 36px;
  line-height: 36px;
  border-radius: 6px;
  color: #fff;
  background: #005adc;
  a {
    color: #fff !important;
  }
}

.ant-menu-inline-collapsed .iconfont + span {
  display: none;
}

// 折叠菜单样式
// 折叠菜单后，把修改菜单宽度
.slider-collapsed {
  .ant-menu-submenu {
    margin: 0;
    width: 56px;
  }
  .ant-menu-submenu-selected {
    margin: 0;
    width: 56px;
  }

  .slider-wrapper .ant-menu-root > .ant-menu-item-selected {
    padding: 0px 8px !important;
    width: 32px;
    box-sizing: border-box;
    height: 32px !important;
    line-height: 32px;
    .iconfont {
      margin-right: 0;
    }
  }
  .slider-wrapper .ant-menu-root > .ant-menu-item {
    width: 32px;
  }
}

// 折叠的子类悬浮框
::v-deep.ant-menu-vertical.ant-menu-sub {
  padding: 50px 0;
}
// 折叠后浮动的子类
::v-deep.ant-menu-submenu-popup {
  // background: red;
  padding: 100px;
}
.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal).ant-menu-sub {
  padding: 22px 0 !important;
}
// 折叠后浮动的子类
.ant-menu-submenu-popup
  .ant-menu:not(.ant-menu-horizontal).ant-menu-sub
  .ant-menu-item {
  margin: 2px auto;
  width: auto;
  padding: 0 24px;
  height: 36px;
  line-height: 36px;
}
.ant-menu-submenu-popup
  .ant-menu:not(.ant-menu-horizontal).ant-menu-sub
  .ant-menu-item-selected,
.ant-menu-submenu-popup
  .ant-menu:not(.ant-menu-horizontal).ant-menu-sub
  .ant-menu-item-active {
  margin: 2px auto;
  width: auto;
  padding: 0 24px;
  height: 36px;
  linhe-height: 36px;
  background: #005adc;
  border-radius: 0;
  .iconfont {
    color: #fff;
  }
}

.slider-collapsed {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  text-align: center;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
  user-select: none;
}

::v-deep.ant-menu-inline-collapsed {
  width: 56px;
}
::v-deep.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 0 12px !important;
}

::v-deep.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  > span {
  border-radius: 4px;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  .iconfont {
    color: #878fa6;
    margin-right: 0;
  }
}
// 选中
::v-deep.ant-menu-inline-collapsed
  > .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > span {
  background: #3960df;
  .iconfont {
    color: #fff;
  }
  .a {
    color: #fff !important;
  }
}
</style>
