<template>
  <div>
    <div class="recommend-tit-box">
      <div class="recommend-tit">最快5分钟完成入驻</div>
    </div>
    <div class="reg-box">
      <!-- <div class="content-top">
        <div class="content-name">简单几步 最快30分钟完成入驻</div>
        <div @click="goRegstratopm" class="content-link">
          立即体验 <i class="iconfont iconyoujiantou"></i>
        </div>
      </div> -->
      <img
        class="reg-flow-img"
        src="../../../assets/newIndex/reg_img.svg"
        alt=""
      />
      <div class="btn-box">
        <a-button class="btn" @click="goRegstratopm" size="large" type="primary"
          >我要入驻</a-button
        >
        <a-button
          class="btn"
          @click="goInstructions"
          size="large"
          type="primary"
          ghost
          >查看指南</a-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RegistrationGuidelines',
  data() {
    return {
      tabValue: 0
    }
  },
  created() {},
  mounted() {},
  methods: {
    changeContentItem(index) {
      this.tabValue = index || 0
    },
    goRegstratopm() {
      this.$router.push('/openProgress')
    },
    goInstructions() {
      this.$router.push('/instructions')
    }
  }
}
</script>
<style lang="less" scoped>
* {
  caret-color: transparent;
}
.reg-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px;

  .reg-flow-img {
    width: 100%;
    height: auto;
    min-height: 200px;
    transition: all 0.3s;
    animation: all 0.3s;
  }
  .btn-box {
    margin-top: 20px;
    display: flex;
    align-content: center;

    .btn {
      width: 160px;
      height: 44px;
      line-height: 42px;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-content: center;
      margin-right: 12px;
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
}
.recommend-tit {
  &::before {
    transform: rotate(180deg) translate(100%, 0px) !important;
    margin-left: -32px !important;
  }
}
</style>
