
<template>
  <div>
    <a-empty
      v-if="!pieData || (pieData.length == 0)"
      style="width: 100%; height: 270px; padding-top: 154px"
    />
    <!-- -->

    <div
      id="pie-chart"
      :style="{ 'margin-top': showType ? '30px' : '0px' }"
    ></div>
    <div class="legend" :style="{ 'margin-top': showType ? '56px' : '0px' }">
      <div class="item" v-for="(lgItem, index) in legendData" :key="index">
        <i class="icon" :style="{ background: color[index] }"></i>
        {{ lgItem }}
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: 'PieCharts',
  data() {
    return {
      legendData: [],
      chartData: [],
      color: [
        '#aff08b',
        '#55d4b3',
        '#1aa4e3',
        '#1f76f3',
        '#ffd350',
        '#ff9f2f',
        '#f86553',
        '#ff2e2e',
      ],
    }
  },
  props: {
    pieData: {
      type: Array,
      default: [],
    },
    showType: {
      type: String,
      default: '',
    },
  },
  watch: {
    pieData(newval) {
      let legendData = []
      let chartData = []
      newval && (newval.forEach((item) => {
        if (item.value > 0) {
          legendData.push(item.name)
          chartData.push({
            ...item,
          })
        }
      }))
      this.legendData = legendData
      this.chartData = chartData
      this.drawPie()
    },
  },
  mounted() {
    this.drawPie()
  },
  methods: {
    setPieRadius() {
      let radius = ['40%', '65%']

      let width = document.body.clientWidth
      if (width > 1660) {
        radius = ['45%', '70%']
      } else if (1660 > width > 1440) {
        radius = ['40%', '55%']
      } else if (width < 1440) {
        radius = ['28%', '45%']
      }
      return radius
    },
    drawPie() {
      // if (this.chartData.length == 0) return
      let that = this
      let myChart = echarts.init(document.getElementById('pie-chart'))
      // 绘制图表
      myChart.setOption({
        grid: {
          top: '10%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true,
        },
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '{a} <br/>{b}:   ({d}%)',
        },
        // legend: {
        //   orient: 'horizontal',
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   icon: 'circle',
        //   bottom: 10,

        //   data: this.legendData,
        // },

        color: this.color,
        series: [
          {
            type: 'pie',
            radius: this.setPieRadius(),
            label: {
              // formatter: '{per|{d}%}\n{b|{b}}',
              formatter: '{per|{d}%}',

              rich: {
                a: {
                  color: '#999',
                  lineHeight: 22,
                  align: 'center',
                },

                hr: {
                  borderColor: '#aaa',
                  width: '100%',
                  borderWidth: 0.5,
                  align: 'center',
                  height: 0,
                },
                b: {
                  lineHeight: 33,
                  align: 'center',
                  color: '#969cab',
                  fontSize: 12,
                },
                per: {
                  color: '#000',
                  fontSize: 14,
                  // backgroundColor: '#334455',
                  padding: [2, 4],
                  borderRadius: 2,
                },
              },
            },
            data: that.chartData,
          },
        ],
      })
    },
  },
}
</script>

<style lang="less" scoped>
#pie-chart {
  margin: 0 auto;
  width: 100%;
  height: 350px;
}
.legend {
  margin: 0 auto;
  max-width: 320px;
  .item {
    padding: 18px 15px;
    display: inline-block;
    font-size: 14px;
    height: 14px;
    width: 50%;
  }
  .icon {
    margin-right: 8px;
    display: inline-block;
    background: #f00;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
</style>
