<template>
  <div :class="{ isHomePage: isHomePage }">
    <div class="title-name" v-if="!isHomePage">
      <span id="inful-all">{{ $t('performance.overview') }}</span>
    </div>
    <div v-else :style="{ marginBottom: '10px' }">
      <div class="index-title">
        {{ $t('outbound.performance.monitoring') }}
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: this.$t('outbound.performance.monitoring') }
              })
            }
          "
        />
      </div>
      <div class="b-more" @click="goDetail">
        {{ $t('more') }}
        <div class="arrow-right"></div>
      </div>
    </div>
    <a-steps :current="-1" size="small" labelPlacement="vertical">
      <a-step title="创建" />
      <a-step title="出库">
        <div slot="description" class="desc">
          <span>出库件数</span>
          <span>{{ allData.outboundPcs }}</span>
        </div>
      </a-step>
      <a-step title="上网" />
      <a-step title="妥投" />
    </a-steps>
    <div v-if="!isHomePage" style="height: 16px"></div>
    <Chart :list="chartData" :baseNum="chartData[0].value" />
  </div>
</template>

<script>
import Chart from '../chart.vue'
const chartData = [
  {
    label: '创建单量',
    value: 0,
    desc:
      '在所选时间范围内已成功创建需要出库的订单量，不包含草稿和取消状态的订单',
    state: 'created'
  },
  {
    label: '出库单量',
    value: 0,
    desc:
      '<div><div>出库件数：根据北京时间进行统计，在海外仓已完成出库的SKUpcs数</div><div>出库单量：根据北京时间进行统计，在海外仓已完成出库的订单量</div></div>',
    state: 'outbound'
  },
  {
    label: '上网单量',
    value: 0,
    desc: '根据北京时间进行统计，已经上网可查询轨迹的订单量',
    state: 'ascan'
  },
  {
    label: '妥投单量',
    value: 0,
    desc: '根据北京时间进行统计，服务商已完成妥投的订单量',
    state: 'dscan'
  }
]
export default {
  props: {
    isHomePage: {
      type: Boolean,
      default: false
    },
    pandectSeach: {
      type: Object
    }
  },
  data() {
    return {
      allData: {},
      chartData: [...chartData]
    }
  },
  components: {
    Chart
  },
  // created() {
  //   this.pandectQuery()
  // },
  methods: {
    pandectQuery() {
      //总览查询
      var data = { ...this.pandectSeach }
      this.$post('v2/warehouseout/overview', data).then(res => {
        this.allData = res
        this.chartData = this.chartData.map(e => ({
          ...e,
          value: res[e.state]
        }))
      })
    },
    goDetail() {
      this.$router.push('/outboundFulfillment')
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/styles/newstyle.less';

.isHomePage {
  width: 66.67%;
  height: 332px;
  background: #fff;
  margin-right: 20px;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: auto;
  border-radius: 8px;
}
</style>
