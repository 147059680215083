<template>
  <div class="tag-box">
    <span
      class="tag"
      v-for="(tItem, tIndex) in item.labelCodeList"
      :key="tIndex"
      >{{ tItem }}</span
    >
    <span
      class="tag"
      v-if="item.productCategory == 'unionMail'"
      :class="item.isBattery == 1 ? ' tag-g' : 'tag-o'"
      >{{ item.isBattery == 1 ? '可带电池' : '不可带电' }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.tag-box {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  scrollbar-width: none; /* 对于 Firefox 隐藏滚动条 */
  /* 隐藏滚动条的样式 */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
  // &::after {
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   background: red;
  //   display: block;
  //   content: '';
  //   width: 20px;
  //   height: 20px;
  //   z-index: 300;
  // }
  .tag {
    margin-top: 10px;
    margin-right: 6px;
    display: inline-block;
    padding: 0 6px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 12px;
    color: #4c6fe2;
    line-height: 18px;
    height: 20px;
    border: 1px solid #88a0ec;
    border-radius: 4px;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
  .tag-g {
    color: #07ab81;
    background: #18bd910a;
    border-color: #18bd910a;
  }
  .tag-o {
    color: #ff6400;
    background: #ff660010;
    border-color: #ff660010;
  }
}
</style>
