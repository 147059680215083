/*
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-06-29 20:50:16
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-04-29 22:47:02
 */

import {
  Api,
  http_get,
  http_post,
  http_post_json,
  http_getRules
} from '@microDss/service/http/http'
export default {
  state: {
    locale: 'zh',
    accountInfo: null,
    // 主子账号
    subAccount: null,
    // 是否使用滚动表格
    needScrollTable: false,
    userName: '',
    collect: {
      type: 0,
      forecastTime: '',
      bagCount: 0,
      totalPieces: 0,
      totalWeight: 0,
      batch_bagCount: 0,
      batch_totalPieces: 0,
      batch_totalWeight: 0
    },
    // 规则集合
    rules: {},
    // 基础数据
    base: {},
    // 子账号
    subUser: null,
    // 是否可以跳转至旧网站
    canLink: true,
    // 卖家账号ID
    tenantCustomerId: null,
    // 包裹信息
    packages: {}
  },
  getters: {
    getUserName(state) {
      if (state.userName) return state.userName
      const info = sessionStorage.getItem('u_i')
      if (info) {
        const dt = JSON.parse(decodeURIComponent(info))
        return (state.userName = dt.customerName + '(' + dt.customerCode + ')')
      }
    },
    getSubAccount(state) {
      if (state.subAccount) {
        return state.subAccount
      }
      const localed = sessionStorage.getItem('dss-u001')
      if (localed) {
        return JSON.parse(decodeURIComponent(localed))
      }
      return null
    },
    getAccountInfo(state) {
      if (state.accountInfo) {
        return state.accountInfo
      }
      const localed = sessionStorage.getItem('dss-accinfo')
      if (localed) {
        return JSON.parse(decodeURIComponent(localed))
      }
      return null
    },
    getTenantCustomerId(state) {
      if (state.tenantCustomerId) {
        return state.tenantCustomerId
      }
      const localed = sessionStorage.getItem('dss-tenantCustomerId')
      if (localed) {
        return JSON.parse(decodeURIComponent(localed))
      }
      return null
    }
  },
  mutations: {
    /**
     * 设置规则集合
     * @param {*} state
     * @param {*} payload
     */
    setRules(state, payload) {
      state.rules = payload || {}
    },
    /**
     * 订单基础信息，便于其它地方使用
     * @param {*} state
     * @param {*} payload
     */
    setBase(state, payload) {
      // Object.assign(state.base, payload)
      /**
       * 在下单页面SingleOdrder 组件中使用 edit.help.js 调用修改，子组件Declare的computed 中逻辑处理
       * 旧：使用Object.assign 修改state.base时，computed只能拿到上一次修改值
       */
      state.base = {
        ...state.base,
        ...payload
      }
      // 如果置空的时候，清空缓存数据
      if (payload && typeof payload === 'object' && !Object.keys(payload || {})?.length) {
        state.base = {}
      }
    },
    setSubAccount(state, data) {
      state.subAccount = data
      sessionStorage.setItem(
        'dss-u001',
        encodeURIComponent(JSON.stringify(state.subAccount))
      )
    },
    setAccountInfo(state, data) {
      state.accountInfo = data
      sessionStorage.setItem(
        'dss-accinfo',
        encodeURIComponent(JSON.stringify(data))
      )
    },
    setTenantCustomerId(state, data) {
      state.tenantCustomerId = data
      sessionStorage.setItem(
        'dss-tenantCustomerId',
        encodeURIComponent(JSON.stringify(data))
      )
    },
    /**
     * 设置包裹信息数据
     * @param {*} state
     * @param {*} payload
     */
    setPackages(state, payload) {
      state.packages = payload || {}
    }
  },
  actions: {
    /**
     * 获取下单规则，
     * @param {*} param0
     * @param {*} query
     */
    async reloadRules({ commit }, query) {
      const res = await http_getRules(query).catch(err => {
        alert('获取规则信息出错，请刷新重试！')
        console.log(err)
      })
      commit('setRules', res || {})
      return res
    },
    /**
     * @description: 主子账号
     * @param {*} commit
     * @return {*}
     */
    getSubAccount({ commit }) {
      const u = sessionStorage.getItem('dss-u001')
      if (u) {
        commit('setSubAccount', JSON.parse(decodeURIComponent(u)))
        return
      }
      http_post_json('user/getUserInfo', null).then(res => {
        try {
          if (res) res = JSON.parse(res)
          if (res && res.status === 1 && res.data) {
            console.log('subAccount:', res.data)
            commit('setSubAccount', res.data)
          }
        } catch (e) {
          console.log(e)
        }
      })
    },
    /**
     * @description: 查询主子账户标识，试单标识，客户名等
     * @param {*} commit
     * @return {*}
     */
    getAccountInfo({ commit }) {
      return http_get('order/personal_center/queryAccountInfo')
        .then(res => {
          if (res && res.status == 1) {
            commit('setAccountInfo', res.data)
          } else {
            commit('setAccountInfo', null)
          }
        })
        .catch(() => {
          commit('setAccountInfo', null)
        })
    },

    /**
     * @description: 卖家ID查询
     * @param {*} commit
     * @return {*}
     */
    getTenantCustomerId({ getters, dispatch, commit }, viewData) {
      // viewData{
      //   useCallback   取值后回调
      //   call          承接vue实例对象
      // }

      if (getters.getTenantCustomerId) {
        viewData.call.tenantCustomerId = getters.getTenantCustomerId
        if (viewData.useCallback) {
          viewData.useCallback.call(viewData.call)
          return
        }
      }

      let info = getters.getAccountInfo
      // let info = getters.getAccountInfo

      if (!info) {
        dispatch('getAccountInfo').then(() => {
          let info = getters.getAccountInfo
          getMJID(info)
        })
      } else {
        getMJID(info)
      }

      function getMJID(info) {
        http_post(Api.getTenantAccount, {
          tenantCode: 'XOMS',
          customerCode: info.customerId,
          locale: sessionStorage.getItem('locale') == 'en' ? 'en-US' : 'zh_CN'
        })
          .then(res => {
            if (res && res.tenantCustomerId) {
              commit('setTenantCustomerId', res.tenantCustomerId)
            } else {
              commit('setTenantCustomerId', null)
            }

            viewData.call.tenantCustomerId = res.tenantCustomerId
            if (viewData.useCallback) {
              viewData.useCallback.call(viewData.call)
              return
            }
          })
          .catch(() => {
            commit('setTenantCustomerId', null)
          })
      }
    }
  }
}
