<!--
 * @Description: 单量统计分析
 * @Autor: S9637/chifuk
 * @Date: 2020-08-29 17:44:47
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-10-13 11:49:41
-->
<i18n>
{
  "zh": {
    "title": "单量分析",
    "Detaileddata": "详细数据"
  },
  "en": {
    "title": "Single statistical analysis",
    "Detaileddata": "Detailed data"
  },
  "de": {
    "title": "Analyse des Einheitsvolumens",
    "Detaileddata": "Detaillierte Daten"
  },
  "ja": {
    "title": "ユニットボリューム解析",
    "Detaileddata": "詳細データ"
  }
}
</i18n>
<template>
  <div class="statistics section-left">
    <a-spin :spinning="spinning">
      <div class="index-title">
        {{ $t('title') }}
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: this.$t('title') }
              })
            }
          "
        />
      </div>
      <div
        class="b-more"
        @click="goDetail"
        data-baidu-pv="首页，详细数据，单量分析"
      >
        {{ $t('Detaileddata') }}
        <div class="arrow-right"></div>
      </div>

      <MaskView
        type="line"
        style="height: 270px; margin-top: 4px"
        v-if="!isOpenFB4 && isCloseFB4 != 4"
      />
      <template v-else>
        <ve-line
          height="294px"
          :data="chartData"
          :settings="chartSettings"
          :extend="extend"
          v-if="chartData.rows.length > 0"
        ></ve-line>

        <a-empty style="margin-top: 54px" v-else></a-empty>
      </template>
    </a-spin>
  </div>
</template>

<script>
import VeLine from 'v-charts/lib/line.common'
import { createDataByZero } from '@v/dataCenter/help/help'
import MaskView from '../mask/MaskView'
import moment from 'moment'
export default {
  name: 'statistics',
  components: {
    VeLine,
    MaskView
  },
  computed: {
    isOpenFB4() {
      return this.$store.state.userInfo.isFB4Open
    },
    isCloseFB4() {
      return this.$store.state.brandStatus.FB4
    }
  },
  data() {
    this.chartSettings = {
      area: true
    }
    this.extend = {
      ...this.$defaultChartSettings,
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: 'rgba(6,92,243,0.5)' // 0% 处的颜色
          },
          {
            offset: 1,
            color: '#FFFFFF' // 100% 处的颜色
          }
        ],
        global: false // 缺省为 false
      },
      legend: {
        show: false
      },
      series: {
        type: 'line',
        height: 256
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#1F2E55',
        formatter: function(obj) {
          // 单量 单 货量 件 重量 kg 体积  cm³
          const marker =
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#065CF3;"></span>'
          return `${obj[0].value[0]} <br /> ${marker} ${obj[0].seriesName}: ${obj[0].value[1]} 单`
        }
      }
    }
    return {
      spinning: false,
      chartData: {
        columns: [],
        rows: []
      }
    }
  },
  created() {
    this.getSummaryData()
  },
  methods: {
    getSummaryData() {
      this.spinning = true
      this.$post('v2/dataCenter/getExWarehouseGatherDTO', {
        customerCode: '',
        skuCode: '',
        warehouseCode: '',
        completeTimeStart: moment()
          .subtract(7, 'days')
          .format(this.$root.dateFormat),
        completeTimeEnd: moment()
          .subtract(1, 'days')
          .format(this.$root.dateFormat)
      })
        .then(res => {
          this.spinning = false
          const dates = createDataByZero(res || [], 'completeTime')
          const list = res || []
          const result = []
          dates.forEach(date => {
            const isFind = list.some(v => {
              if (v.completeTime == date) {
                result.push(v)
                return true
              }
              return false
            })

            if (!isFind) {
              result.push({
                outBagCnt: 0,
                outCbm: 0,
                outCnt: 0,
                outWeight: 0,
                completeTime: date
              })
            }
          })

          this.generateChartData(result)
        })
        .catch(() => {
          this.spinning = false
        })
    },
    generateChartData(list) {
      const rows = []
      const name = this.$t('outCnt')
      list.forEach(item => {
        rows.push({
          completeTime: item.completeTime,
          [name]: item['outCnt']
        })
      })

      this.chartData = {
        columns: ['completeTime', name],
        rows
      }
    },
    /**
     * @description: 跳转至数据中心
     * @param {type}
     * @return {type}
     */
    goDetail() {
      this.$router.push({ name: 'outAnalyze' })
    }
  }
}
</script>

<style lang="less" scoped>
.statistics {
  width: 66.67%;
  height: 332px;
  background: #fff;
  margin-right: 20px;
  padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
</style>
