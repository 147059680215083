<template>
  <div>
    <a-modal
      :centered="true"
      :visible="show || visible"
      :mask="true"
      :dialog-style="{ top: '-120px' }"
      :maskClosable="false"
      width="612px"
      @cancel="close"
      height="269px"
      :footer="null"
    >
      <div class="content">
        <img class="open-img" src="../../../assets/openFlow.jpg" alt="" />
        <a-button class="btn" type="primary" @click="onOpen()">
          {{ $t('op_brand_010') }}
        </a-button>
      </div>
      <!-- <template slot="footer"> </template> -->
    </a-modal>
    <!-- 非转化客户 -->
    <ChangeClue
      @cancel="changeClueModal = false"
      :changeClueModal="changeClueModal"
      :msg="changeClueMsg"
      :mTitle="$t('friendtip')"
      v-if="changeClueModal"
    />
  </div>
</template>

<script>
import ChangeClue from '../businessKanban/newStatus/ChangeClue'
export default {
  name: 'OpenFlow',
  components: { ChangeClue },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    checkPopUp: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      show: false,
      changeClueModal: false,
      changeClueMsg: '-'
    }
  },
  created() {
    // 使用组件传参是显示的时候，无需请求接口判断显示隐藏
    if (this.visible) {
      this.show = true
    } else {
      this.checkPopUp ? this.getJudgmentPopUp() : ''
    }
  },
  mounted() {},

  methods: {
    close() {
      this.show = false
      this.$emit('close')
    },
    getJudgmentPopUp() {
      this.$get('v2/guidedSettlement/judgmentPopUp').then(res => {
        this.show = res == true || res == 'true' ? true : false
      })
    },
    async onOpen() {
      let isChangeClue = await this.changeClue()
      if (isChangeClue && isChangeClue.isChangeClue == false) {
        //非转化客户
        this.changeClueModal = true
        this.close()
        if (isChangeClue && isChangeClue.code == '500001') {
          this.changeClueMsg = isChangeClue.msg
        }

        this.$hide()
        return false
      } else {
        this.closePopup()
      }
    },
    /**
     * @description: 判断是否为转客户
     * @param {type}
     * @return {type}
     */
    changeClue() {
      return new Promise((resolve, reject) => {
        this.$show()
        this.$post('v2/userAuth/isChangeClue')
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
            this.$hide()
          })
      })
    },
    closePopup() {
      this.$show()
      this.$get('v2/guidedSettlement/isPopUp')
        .then(() => {
          this.$hide()
          this.close()
          this.$router.push({
            name: 'openProgress'
          })
        })
        .catch(err => {
          this.$hide()
        })
    }
  }
}
</script>

<style lang="less" scoped>
.open-img {
  margin-top: 30px;
  width: 564px;
  height: 269px;
}
.btn {
  margin: 18px auto 0;
  display: block;
  height: 40px;
  line-height: 40px;
}
</style>
