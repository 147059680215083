/*
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2021-06-10 17:44:10
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-08-05 21:36:24
 */
import { getDomainWorld } from '@/utiles/index'
import { getST, validateST } from '@/api/index'
import { LINKS } from '@const/index'
import Cookies from 'js-cookie'
/**
 * @description: 唤醒 APP 登录
 * @param {*} async
 * @return {*}
 */
const loginMicroApp = async brand => {
  // 判断是否是海外部署需求
  const hostname = location.hostname
  let isOverSeas =
    hostname.indexOf('eu') > -1 ||
    hostname.indexOf('asia') > -1 ||
    hostname.indexOf('us') > -1
  let veriable = ''
  if (hostname.indexOf('eu') > -1) veriable = 'eu'
  if (hostname.indexOf('asia') > -1) veriable = 'asia'
  if (hostname.indexOf('us') > -1) veriable = 'us'
  if (isOverSeas && brand != 'ORDER') {
    return false
  }
  const world = getDomainWorld()
  if (!world || world === 'local') return
  // 从 cookie 中读取 TGC
  // tgt_test/uat/cn
  const TGC = Cookies.get(
    `tgt_${world === 'prod' ? (isOverSeas ? veriable : 'cn') : world}`
  )
  if (TGC == '' || TGC == undefined || TGC == null) {
    return false
  }
  let UrlLink = LINKS[brand].replace('http://', 'https://')
  // 获取 ST
  const ssoUrl =
    world === 'prod'
      ? isOverSeas
        ? `https://sso.${veriable}.4px.com/v1/tickets`
        : `https://sso.4px.com/v1/tickets`
      : world === 'test'
      ? `https://sso-${world}.4px.com/v1/tickets`
      : `https://sso.${world}.4px.com/v1/tickets`
  const ST = await getST(`${ssoUrl}/${TGC}`, {
    service: `${LINKS[brand]}/cas`
  })
  return new Promise((resolve, reject) => {
    if (ST) {
      // 验证 ST
      validateST(`${UrlLink}/cas?ticket=${ST}`)
        .then(res => {
          if (res) {
            localStorage.setItem(`session_${brand}`, res.data.sessionId)
          }
          resolve()
        })
        .catch(() => {
          reject()
        })
    } else {
      reject()
    }
  })
}

export { loginMicroApp }
