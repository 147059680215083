export const freightColumns = vue => [
  {
    title: vue.$t('wallet.consume.t11'),
    dataIndex: 'totalRmbAmount',
    customRender: text => `${text || '-'} RMB`
  },
  {
    title: vue.$t('chargeWeight'),
    dataIndex: 'chargeWeight',
    customRender: text => `${text || '-'} KG`
  },
  {
    title: '时效(工作日）',
    dataIndex: 'deliveryPeriod',
    customRender: text => `${text || '-'} 天(从4PX入库扫描到首次妥投)`
  }
]

export const quotationColumns = vue => [
  {
    title: vue.$t('user_tran_004'),
    dataIndex: 'countryName',
    scopedSlots: { customRender: 'countryName' }
  },
  {
    title: vue.$t('product_expression_041') + '(KG)',
    dataIndex: 'weightBegin',
    scopedSlots: { customRender: 'weightBegin' }
  },
  {
    title: vue.$t('index_tran_094') + '(RMB/KG)',
    dataIndex: 'expressFreight'
  },
  {
    title: vue.$t('product_expression_042') + `(RMB/${vue.$t('ticket')})`,
    dataIndex: 'registrationFee'
  }
]

export const isBatteryArr = vue => [
  { value: 1, name: vue.$t('product_expression_017') },
  { value: 2, name: vue.$t('product_expression_043') }
]

export const cargoTypeArr = vue => [
  { value: 'D', name: vue.$t('sv.t13') },
  { value: 'P', name: vue.$t('sv.t12') }
]

export const trackTypeArr = vue => [
  { value: 1, name: vue.$t('product_expression_044') },
  { value: 2, name: vue.$t('product_expression_045') },
  { value: 3, name: vue.$t('product_expression_046') }
]

export const serviceLevelArr = vue => [
  { value: 1, name: vue.$t('product_expression_047') },
  { value: 2, name: vue.$t('product_expression_048') },
  { value: 3, name: vue.$t('product_expression_049') },
  { value: 4, name: '超级经济' },
  { value: 5, name: '特惠' },
  { value: 6, name: '超货' },
  { value: 7, name: 'EUB' },
  { value: 8, name: 'EMS' },
  { value: 9, name: '邮政大包' },
  { value: 10, name: '挂号' },
  { value: 11, name: '平邮' },
  { value: 20, name: '晚必赔' },
  { value: 21, name: '快线' }
]
