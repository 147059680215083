<!--
 * @Description: fb4 仓库选择列表
 * @Autor: S9637/chifuk
 * @Date: 2020-09-17 15:16:01
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-03-01 14:07:47
-->
<template>
  <a-select
    show-search
    option-filter-prop="children"
    :filter-option="filterOption"
    v-model="val"
    @change="handleChange"
    allowClear
  >
    <a-select-option
      :key="item.warehouseCode"
      :value="item.warehouseCode"
      v-for="item in list"
    >
      {{ item.warehouseName + '(' + item.warehouseCode + ')' }}
    </a-select-option>
    <em slot="suffixIcon" class="iconfont iconxiala" style="font-size: 10px;" />
  </a-select>
</template>
<script>
export default {
  name: 'warehouse',
  props: {
    value: String
  },
  data() {
    return {
      list: [],
      val: ''
    }
  },
  watch: {
    value(val) {
      this.val = val
    }
  },
  created() {
    this.getFB4Warehouses()
  },
  methods: {
    getFB4Warehouses() {
      this.$get('v2/common/getFB4Warehouses', { cache: true }).then(res => {
        this.list = res || []
      })
    },
    handleChange(value) {
      this.$emit('warehouse', value ? value : '')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>

<style></style>
