//服务中心菜单栏
import store from '../../store/index'
import { grayscaleSwitch, firstDirectory } from './servicecenter.service.js'
const isOverSeas = store?.getters?.isOverSeas
const getMenuData = async vue => {
  let menuList = [
    {
      name: 'serviceCenterIndex',
      text: vue.$t('home.page.of.service.center'),
      icon: 'iconshouye',
      // 不在权限系统配置 即始终展示的菜单
      nolimit: true
    },
    // 旧版服务单
    // {
    //   name: 'selfService',
    //   text: vue.$t('Claim.for.inspection'),
    //   icon: 'iconzizhufuwu',
    //   sub: [
    //     {
    //       name: 'overseasSearch',
    //       text: vue.$t('FB4.Service.provider.search'),
    //       pv: '服务中心，海外仓服务商查件，查件索赔'
    //     },
    //     {
    //       name: 'overseasClaim',
    //       text: vue.$t('FB4.claim'),
    //       pv: '服务中心，海外仓索赔，查件索赔'
    //     },
    //     {
    //       name: 'overseasService',
    //       text: vue.$t('FB4.Value.added.services'),
    //       pv: '服务中心，海外仓增值服务，查件索赔'
    //     },
    //     {
    //       name: 'directSearch',
    //       text: vue.$t('XMS.Check'),
    //       pv: '服务中心，直邮查件，查件索赔'
    //     },
    //     {
    //       name: 'directClaim',
    //       text: vue.$t('XMS.claim'),
    //       pv: '服务中心，直邮索赔，查件索赔'
    //     }
    //   ]
    // },
    {
      name: 'myserviceList',
      text: vue.$t('Service.list'),
      icon: 'iconfuwudan',
      sub: [
        {
          name: 'myserviceList',
          text: vue.$t('My.service.list'),
          pv: '服务中心，我的服务单，服务单'
        },
        // {
        //   name: 'consultingService',
        //   text: vue.$t('Consulting.service.sheet'),
        //   pv: '服务中心，咨询服务单，服务单'
        // },
        {
          name: 'addService',
          text: vue.$t('add.service.sheet'),
          pv: '服务中心，新建服务单，服务单'
        }
      ]
    },
    {
      name: 'selfServiceInquiry',
      text: vue.$t('Self.service.inquiry'),
      icon: 'iconzizhuchaxun',
      sub: [
        {
          name: 'dssFreight',
          text: vue.$t('product_expression_001'),
          pv: '服务中心，自助查询，新版-直发费用试算'
        },
        {
          name: 'freight',
          text: vue.$t('Trial.calculation.of.freight'),
          pv: '服务中心，运费试算，自助查询'
        },
        {
          name: 'warehouseRent',
          text: vue.$t('Trial.calculation.of.warehouse.rent'),
          pv: '服务中心，仓租试算，自助查询'
        },
        {
          name: 'usFreight',
          text: vue.$t('Trial.calculation.of.US.freight'),
          pv: '服务中心，美国运费计算器，自助查询'
        }
      ]
    },
    {
      name: 'trajectoryService',
      text: vue.$t('trajectory_030'),
      icon: 'iconguiji',
      sub: [
        {
          name: 'trajectorySubscribe',
          text: vue.$t('trajectory_006'),
          pv: '服务中心，轨迹服务，轨迹订阅'
        }
      ]
    },
    {
      name: 'instructions',
      text: vue.$t('instructions'),
      icon: 'iconcaozuozhinan',
      nolimit: true,
      sub: [
        {
          name: 'instructions',
          text: vue.$t('beginner'),
          nolimit: true,
          pv: '服务中心，新手指南，操作指南'
        }
      ]
    }
    // {
    //   name: 'feedback',
    //   text: vue.$t('Feedback.suggestions'),
    //   icon: 'iconzizhanghao'
    // }
  ]

  // 游客模式需要隐藏相关菜单
  if (!sessionStorage.getItem('mnu')) {
    menuList = [
      {
        name: 'instructions',
        text: vue.$t('instructions'),
        icon: 'iconcaozuozhinan',
        nolimit: true,
        sub: [
          {
            name: 'instructions',
            text: vue.$t('beginner'),
            nolimit: true,
            pv: '服务中心，新手指南，操作指南'
          }
        ]
      }
    ]
  }
  // 国内
  if (!isOverSeas) {
    try {
      let resfirstDirectoryData = (await firstDirectory()) || []
      if (resfirstDirectoryData.length > 0) {
        var sonList = resfirstDirectoryData.map(item => {
          return {
            name: `helpGuideIndex#isDynamic${item.code}`, //#isDynamic 用来识别是否为动态获取路由， 拼接 item.code 标记为菜单:key
            query: {
              code: item.code
            },
            nolimit: true,
            text: item.title,
            pv: '服务中心，操作指南'
          }
        })

        for (var i in menuList) {
          if (menuList[i].name == 'instructions') {
            menuList[i].sub = menuList[i].sub.concat(sonList)
            vue.$store.state.menuItems = menuList
          }
        }
      } else {
        vue.$store.state.menuItems = menuList
      }
    } catch {
      vue.$store.state.menuItems = menuList
    }

    // 轨迹灰度接口判断
    try {
      let resGrayData = (await grayscaleSwitch()) || {}
      if (resGrayData?.enableGrayScale == false) {
        menuList = menuList.filter(item => {
          if (
            item.name != 'trajectoryService' &&
            item.name != 'trajectorySubscribe'
          ) {
            return item
          }
        })
        vue.$store.state.menuItems = menuList
      }
    } catch {
      vue.$store.state.menuItems = menuList
    }
  } else {
    // 海外
    menuList = menuList.filter(item => {
      if (
        item.name != 'serviceCenterIndex' &&
        item.name != 'instructions' &&
        item.name != 'myserviceList' &&
        item.name != 'myserviceList'
      ) {
        return item
      }
    })
    menuList.forEach(item => {
      if (item.name == 'selfServiceInquiry') {
        item.sub.forEach((subItem, subIndex) => {
          if (subItem.name == 'usFreight') {
            item.sub.splice(subIndex, 1)
          }
        })
      }
    })
    vue.$store.state.menuItems = menuList
    // return menuList
  }
}

//获取我的服务单查件的表头
const getCheckServiceLists = vue => [
  {
    dataIndex: 'serviceNumber',
    key: 'serviceNumber',
    title: vue.$t('SC.serviceNumber'),
    scopedSlots: {
      customRender: 'serviceNumber'
    }
  },
  {
    dataIndex: 'documentNo',
    key: 'documentNo',
    title: vue.$t('SC.documentNo')
  },
  {
    dataIndex: 'checkType',
    key: 'checkType',
    title: vue.$t('SC.checkType')
  },
  {
    dataIndex: 'creationTime',
    key: 'creationTime',
    title: vue.$t('SC.creationTime')
  },
  {
    dataIndex: 'updateTime',
    key: 'updateTime',
    title: vue.$t('SC.updateTime')
  },
  {
    title: vue.$t('SC.latestFeedback'),
    dataIndex: 'latestFeedback',
    key: 'latestFeedback',
    width: '30ox',
    scopedSlots: {
      customRender: 'latestFeedback'
    }
  },
  {
    title: vue.$t('SC.operation'),
    dataIndex: 'action',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]
//获取我的服务单索赔的表头
const getClaimServiceLists = vue => [
  {
    dataIndex: 'serviceNumber',
    key: 'serviceNumber',
    title: vue.$t('SC.serviceNumber'),
    scopedSlots: {
      customRender: 'serviceNumber'
    }
  },
  {
    dataIndex: 'documentNo',
    key: 'documentNo',
    title: vue.$t('SC.documentNo')
  },
  {
    dataIndex: 'reasonClaim',
    key: 'reasonClaim',
    title: vue.$t('SC.reasonClaim'),
    scopedSlots: {
      customRender: 'reasonClaim'
    }
  },
  {
    dataIndex: 'creationTime',
    key: 'creationTime',
    title: vue.$t('SC.creationTime')
  },
  {
    dataIndex: 'updateTime',
    key: 'updateTime',
    title: vue.$t('SC.updateTime')
  },
  {
    title: vue.$t('SC.latestFeedback'),
    dataIndex: 'latestFeedback',
    key: 'latestFeedback',
    scopedSlots: {
      customRender: 'latestFeedback'
    }
  },
  {
    title: vue.$t('SC.operation'),
    dataIndex: 'action',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

//获取我的服务单增值服务的表头
const getZZServiceLists = vue => [
  {
    dataIndex: 'serviceNumber',
    key: 'serviceNumber',
    title: vue.$t('SC.serviceNumber'),
    scopedSlots: {
      customRender: 'serviceNumber'
    }
  },
  {
    dataIndex: 'valueTypes',
    key: 'valueTypes',
    title: vue.$t('SC.valueTypes')
  },
  {
    dataIndex: 'creationTime',
    key: 'creationTime',
    title: vue.$t('SC.creationTime')
  },
  {
    dataIndex: 'updateTime',
    key: 'updateTime',
    title: vue.$t('SC.updateTime')
  },
  {
    title: vue.$t('SC.latestFeedback'),
    dataIndex: 'latestFeedback',
    key: 'latestFeedback',
    scopedSlots: {
      customRender: 'latestFeedback'
    }
  },
  {
    title: vue.$t('SC.operation'),
    dataIndex: 'action',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const getSKULists = vue => [
  {
    dataIndex: 'oldSkuCode',
    key: 'oldSkuCode',
    title: vue.$t('SC.oldSkuCode'),
    scopedSlots: {
      customRender: 'oldSkuCode'
    }
  },
  {
    dataIndex: 'oldSkuQty',
    key: 'oldSkuQty',
    title: vue.$t('SC.oldSkuQty'),
    scopedSlots: {
      customRender: 'oldSkuQty'
    }
  },
  {
    dataIndex: 'amountPerBox',
    key: 'amountPerBox',
    title: vue.$t('SC.amountPerBox'),
    scopedSlots: {
      customRender: 'amountPerBox'
    }
  },
  {
    dataIndex: 'newSkuCode',
    key: 'newSkuCode',
    title: vue.$t('SC.newSkuCode'),
    scopedSlots: {
      customRender: 'newSkuCode'
    }
  },
  {
    dataIndex: 'newSkuQty',
    key: 'newSkuQty',
    title: vue.$t('SC.newSkuQty'),
    scopedSlots: {
      customRender: 'newSkuQty'
    }
  },
  {
    title: ' ',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const getSKU = vue => [
  {
    dataIndex: 'skuCode',
    key: 'skuCode',
    title: vue.$t('SC.skuCode'),
    scopedSlots: {
      customRender: 'skuCode'
    }
  },
  {
    dataIndex: 'qty',
    key: 'qty',
    title: vue.$t('SC.qty'),
    scopedSlots: {
      customRender: 'qty'
    }
  },
  {
    // title: vue.$t('fb4.operating'),
    dataIndex: 'action',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

const getSDK = vue => [
  {
    dataIndex: 'skuCode',
    key: 'skuCode',
    title: vue.$t('SC.skuCode'),
    scopedSlots: {
      customRender: 'skuCode'
    }
  },
  {
    dataIndex: 'qty',
    key: 'qty',
    title: vue.$t('SC.qty'),
    scopedSlots: {
      customRender: 'qty'
    }
  },
  {
    dataIndex: 'itemPrice',
    key: 'itemPrice',
    title: vue.$t('SC.itemPrice'),
    scopedSlots: {
      customRender: 'itemPrice'
    }
  },
  {
    dataIndex: 'itemCurrency',
    key: 'itemCurrency',
    title: vue.$t('SC.itemCurrency'),
    scopedSlots: {
      customRender: 'itemCurrency'
    }
  },
  {
    // title: vue.$t('fb4.operating'),
    dataIndex: 'action',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
]

// 仓租试算列表
const getWarehouseRentColumns = vue => [
  {
    dataIndex: 'warehouse',
    key: 'warehouse',
    title: vue.$t('SC.warehouse')
  },
  {
    title: vue.$t('SC.SKUVolume'),
    dataIndex: 'SKUVolume',
    key: 'SKUVolume'
  },
  {
    title: vue.$t('SC.totalDays'),
    dataIndex: 'totalDays',
    key: 'totalDays'
  },
  {
    title: vue.$t('SC.billingDays'),
    dataIndex: 'billingDays',
    key: 'billingDays'
  },
  {
    title: vue.$t('SC.trialPrice'),
    dataIndex: 'trialPrice',
    key: 'trialPrice',
    scopedSlots: {
      customRender: 'trialPrice'
    }
  },
  {
    title: vue.$t('SC.billingPrice'),
    dataIndex: 'billingPrice',
    key: 'billingPrice',
    scopedSlots: {
      customRender: 'billingPrice'
    }
  },
  {
    title: vue.$t('SC.segmentBillingDays'),
    dataIndex: 'segmentBillingDays',
    key: 'segmentBillingDays',
    scopedSlots: {
      customRender: 'segmentBillingDays'
    }
  },
  {
    title: vue.$t('SC.subleaseAmount'),
    dataIndex: 'subleaseAmount',
    key: 'subleaseAmount',
    scopedSlots: {
      customRender: 'subleaseAmount'
    }
  },
  {
    title: vue.$t('SC.itemCurrency'),
    dataIndex: 'currency',
    key: 'currency'
  },
  {
    title: vue.$t('SC.totalAmount'),
    dataIndex: 'totalAmount',
    key: 'totalAmount'
  }
]

//运费试算列表
const getFreightTrial = vue => [
  {
    dataIndex: 'productName',
    key: 'productName',
    title: vue.$t('SC.deliveryMode'),
    scopedSlots: {
      customRender: 'deliveryMode'
    }
  },
  {
    title: vue.$t('SC.prescription'),
    dataIndex: 'timely',
    key: 'timely',
    sorter: (a, b) => a.timelySort - b.timelySort,
    scopedSlots: {
      customRender: 'prescription'
    }
  },
  {
    title: vue.$t('SC.totalAmounts'),
    dataIndex: 'totalFeeAmount',
    key: 'totalFeeAmount',
    scopedSlots: {
      customRender: 'totalAmount'
    },
    sorter: (a, b) => a.totalFeeAmount - b.totalFeeAmount
  },
  {
    title: vue.$t('SC.operation'),
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: {
      customRender: 'operation'
    }
  }
]

const getComplaintLists = vue => [
  {
    dataIndex: 'serviceNumber',
    key: 'serviceNumber',
    title: vue.$t('SC.serviceNumber'),
    scopedSlots: {
      customRender: 'serviceNumber'
    }
  },
  {
    dataIndex: 'documentNo',
    key: 'documentNo',
    title: vue.$t('SC.documentNo')
  },
  {
    dataIndex: 'complaintCategory',
    key: 'complaintCategory',
    title: vue.$t('SC.complaintCategory')
  },
  {
    dataIndex: 'questionType',
    key: 'questionType',
    title: vue.$t('SC.questionType')
  },
  {
    dataIndex: 'creationTime',
    key: 'creationTime',
    title: vue.$t('SC.creationTime')
  },
  {
    dataIndex: 'updateTime',
    key: 'updateTime',
    title: vue.$t('SC.updateTime')
  },
  {
    title: vue.$t('SC.latestFeedback'),
    dataIndex: 'latestFeedback',
    key: 'latestFeedback',
    scopedSlots: {
      customRender: 'latestFeedback'
    }
  }
]

const getConsultingServiceLists = vue => [
  {
    dataIndex: 'serviceNumber',
    key: 'serviceNumber',
    title: vue.$t('SC.serviceNumber'),
    scopedSlots: {
      customRender: 'serviceNumber'
    }
  },
  {
    dataIndex: 'documentNo',
    key: 'documentNo',
    title: vue.$t('SC.documentNo')
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: vue.$t('SC.description')
  },
  {
    dataIndex: 'creationTime',
    key: 'creationTime',
    title: vue.$t('SC.creationTime')
  },
  {
    dataIndex: 'updateTime',
    key: 'updateTime',
    title: vue.$t('SC.updateTime')
  },
  {
    title: vue.$t('SC.latestFeedback'),
    dataIndex: 'latestFeedback',
    key: 'latestFeedback',
    scopedSlots: {
      customRender: 'latestFeedback'
    }
  }
]

const getVerifySKU = vue => [
  {
    dataIndex: 'skuCode',
    key: 'skuCode',
    title: vue.$t('SC.skuCode'),
    width: 90,
    scopedSlots: {
      customRender: 'skuCode'
    }
  }
]
const getPhotoRequirementsCol = vue => [
  {
    dataIndex: 'skuCode',
    key: 'skuCode',
    title: vue.$t('SC.skuCode'),
    scopedSlots: {
      customRender: 'skuCode'
    }
  },
  {
    dataIndex: 'photosNum',
    key: 'photosNum',
    title: vue.$t('SC.photosNum'),
    scopedSlots: {
      customRender: 'photosNum'
    }
  },
  {
    dataIndex: 'photoRequireName',
    key: 'photoRequireName',
    title: vue.$t('SC.photoRequireName'),
    scopedSlots: {
      customRender: 'photoRequireName'
    }
  },
  {
    dataIndex: 'suppleNote',
    key: 'suppleNote',
    title: vue.$t('SC.suppleNote'),
    scopedSlots: {
      customRender: 'suppleNote'
    }
  }
]
// 货型列表
const getCargotypeLists = vue => [
  {
    name: vue.$t('SC.warehouseRent.Cargotype.T25'),
    id: 'T25'
  },
  {
    name: vue.$t('SC.warehouseRent.Cargotype.T50'),
    id: 'T50'
  },
  {
    name: vue.$t('SC.warehouseRent.Cargotype.T120'),
    id: 'T120'
  },
  {
    name: vue.$t('SC.warehouseRent.Cargotype.T240'),
    id: 'T240'
  },
  {
    name: vue.$t('SC.warehouseRent.Cargotype.TM'),
    id: 'TM'
  }
]
// 货型描述详情
const cargotypeColumns = vue => [
  {
    dataIndex: 'type',
    key: 'type',
    title: vue.$t('SC.warehouseRent.Cargotype.Detail.type')
  },
  // {
  //   dataIndex: 'abbreviation',
  //   key: 'abbreviation',
  //   title: vue.$t('SC.warehouseRent.Cargotype.Detail.abbreviation')
  // },
  {
    dataIndex: 'weight',
    key: 'weight',
    title: vue.$t('SC.warehouseRent.Cargotype.Detail.weight')
  },
  {
    dataIndex: 'long',
    key: 'long',
    title: vue.$t('SC.warehouseRent.Cargotype.Detail.long')
  },
  {
    dataIndex: 'width',
    key: 'width',
    title: vue.$t('SC.warehouseRent.Cargotype.Detail.width')
  },
  {
    dataIndex: 'high',
    key: 'high',
    title: vue.$t('SC.warehouseRent.Cargotype.Detail.high')
  }
]

// 货型描述详情
const cargotypeData = vue => [
  {
    type: vue.$t('SC.warehouseRent.Cargotype.T25'),
    abbreviation: 'T25',
    weight: '0<x≤0.5',
    long: '0<x≤25',
    width: '0<x≤20',
    high: '0<x≤2.5',
    id: 1
  },
  {
    type: vue.$t('SC.warehouseRent.Cargotype.T50'),
    abbreviation: 'T50',
    weight: '0.5<x≤10',
    long: '25<x≤50',
    width: '20<x≤40',
    high: '2.5<x≤25',
    id: 2
  },
  {
    type: vue.$t('SC.warehouseRent.Cargotype.T120'),
    abbreviation: 'T120',
    weight: '10<x≤31.5',
    long: '50<x≤120',
    width: '40<x≤100',
    high: '25<x≤80',
    id: 3
  },
  {
    type: vue.$t('SC.warehouseRent.Cargotype.T240'),
    abbreviation: 'T240',
    weight: '31.5<x≤40',
    long: '120<x≤240',
    width: '100<x≤160',
    high: '80<x≤100',
    id: 4
  },
  {
    type: vue.$t('SC.warehouseRent.Cargotype.TM'),
    abbreviation: 'TM',
    weight: '40<x≤100',
    long: '240<x≤300',
    width: '160<x≤240',
    high: '100<x≤200',
    id: 5
  }
]

export {
  getMenuData,
  getCheckServiceLists,
  getClaimServiceLists,
  getZZServiceLists,
  getSKULists,
  getSKU,
  getSDK,
  getWarehouseRentColumns,
  getFreightTrial,
  getComplaintLists,
  getConsultingServiceLists,
  getVerifySKU,
  getPhotoRequirementsCol,
  getCargotypeLists,
  cargotypeColumns,
  cargotypeData
}
