/*
 * @Description: const
 * @Autor: S9637/chifuk
 * @Date: 2020-09-02 15:34:49
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-04-21 21:26:44
 */

const isProd = process.env.NODE_ENV === 'production'
const protocol = location.protocol
// 文件上传地址
const uploadUrl = isProd
  ? protocol + '//upload.i4px.com/upload/uploadFileGetUrl'
  : protocol + '//upload.test.i4px.com/upload/uploadFileGetUrl'
const uploadBaseURL = isProd
  ? protocol + '//upload.i4px.com/upload/signUpload'
  : protocol + '//upload.test.i4px.com/upload/signUpload'
// 直发文件上传
let uploaDssdUrl = null
if (location.host === 'b.4px.com') {
  uploaDssdUrl = 'https://b.4px.com'
} else if (location.host === 'b-pre.4px.com') {
  uploaDssdUrl = 'https://b-pre.4px.com'
} else {
  uploaDssdUrl = 'https://b-test.4px.com'
}
const uploadDissURL = isProd
  ? uploaDssdUrl + '/v2/repairOrder/upload'
  : protocol + 'api/v2/repairOrder/upload'

const hostname = location.hostname
let veriable = ''
if (hostname.indexOf('dev') > -1) veriable = 'dev'
if (hostname.indexOf('test') > -1) veriable = 'test'
if (hostname.indexOf('uat') > -1) veriable = 'uat'
if (hostname.indexOf('pre') > -1) veriable = 'pre'
//  商家海外站点
if (hostname.indexOf('eu') > -1) veriable = 'eu'
if (hostname.indexOf('asia') > -1) veriable = 'asia'
if (hostname.indexOf('us') > -1) veriable = 'us'
let isOverSeas =
  location.hostname.indexOf('eu') > -1 ||
  location.hostname.indexOf('asia') > -1 ||
  location.hostname.indexOf('us') > -1

const ORDER_LINK = {
  dev: 'https://order-portal-api-test.4px.com',
  test: 'https://order-portal-api-test.4px.com',
  uat: 'https://order-portal-api-stage.4px.com',
  pre: 'https://order-portal-api-stage.4px.com'
}

const LINKS = {
  DSS: veriable
    ? veriable === 'test'
      ? `${protocol}//order-${veriable}.4px.com`
      : isOverSeas
      ? `${protocol}//order.${veriable}.4px.com`
      : `${protocol}//order-${veriable == 'pre' ? 'yf' : veriable}.4px.com`
    : `${protocol}//order.4px.com`,
  FB4: isProd
    ? veriable
      ? veriable === 'test'
        ? `http://order-fulfillment-${veriable}.4px.com?fromNewVersion=1`
        : `http://order-fulfillment${
            veriable == 'pre' ? '-stage' : '.' + veriable
          }.4px.com?fromNewVersion=1`
      : isOverSeas
      ? `https://order-fulfillment.${veriable}.4px.com?fromNewVersion=1`
      : 'http://order-fulfillment.4px.com?fromNewVersion=1'
    : 'http://order-fulfillment.test.4px.com?fromNewVersion=1',
  TRS: isProd
    ? veriable
      ? `${protocol}//transrush-uat.4px.com`
      : `${protocol}//transrush.4px.com`
    : `${protocol}//transrush.4px.com`,
  GRS: isProd
    ? veriable
      ? `${protocol}//grs.${veriable}.4px.com/goms`
      : `${protocol}//grs.4px.com/goms`
    : `${protocol}//grs.uat.4px.com/goms`,
  B: veriable ? `${protocol}//b.${veriable}.4px.com` : `${protocol}//b.4px.com`,
  ORDER:
    isProd && !veriable
      ? 'https://order-fulfillment-api.4px.com'
      : isProd && isOverSeas
      ? `https://order-fulfillment-api.${veriable}.4px.com`
      : ORDER_LINK[veriable]
}

// 库龄分段
const CONST_WAREHOUSE_DAY = [
  { text: '0-30天', value: 30 },
  { text: '31-60天', value: 60 },
  { text: '61-90天', value: 90 },
  { text: '91-120天', value: 120 },
  { text: '121-180天', value: 180 },
  { text: '181-360天', value: 360 }
]

// 库存质量
// G - 良好
// E - 异常
// R - 退件
// B - 破损
const CONST_STORE_QUANITY = ['G', 'E', 'R', 'B']

const ORDER_TRANSACTION_URL = isProd
  ? veriable
    ? `http://transaction-order-${veriable}.4px.com`
    : 'http://transaction-order.4px.com'
  : 'http://transaction-order-uat.4px.com'

function getPayUrlFun() {
  let url = `${protocol}//pay.4px.com`
  if (isProd && veriable && veriable === 'pre') {
    url = `${protocol}//pay-yf.4px.com`
  } else if (isProd && veriable) {
    url = `${protocol}//pay.${veriable}.4px.com`
  } else if (!isProd) {
    url = `${protocol}//pay.test.4px.com`
  }
  return url
}
const PAY_URL = getPayUrlFun()

//卖家助手接口前缀配置
const sellerAcc = veriable
  ? 'http://10.104.6.40:11115/'
  : 'https://eclink.4px.com/'

export {
  LINKS,
  uploadUrl,
  uploadDissURL,
  uploadBaseURL,
  CONST_WAREHOUSE_DAY,
  CONST_STORE_QUANITY,
  ORDER_TRANSACTION_URL,
  PAY_URL,
  veriable,
  sellerAcc
}
