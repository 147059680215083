export default [
  // 产品服务
  {
    path: '/productService',
    name: 'productService',
    meta: {
      ignore: true,
      unLogin: true,
      title: '直发产品服务'
    },
    component: () =>
      import(
        /* webpackChunkName: "productIndex" */ '../views/productService/productService'
      )
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    meta: {
      ignore: true,
      unLogin: true,
      title: '产品详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "productIndex" */ '../views/productService/productDetails'
      )
  }
]
