/*
 * @Description:
 * @Autor: S9637/chifuk
 * @Date: 2020-09-27 14:30:14
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2021-06-01 13:09:36
 */
export default {
  state: {
    // 顶部导航菜单部分受控于权限，部分不受控
    // 通过 permissionId 来标识需要受控于权限管理
    unReadMeaages: 0,
    items: [
      { name: 'index', active: false, pv: '顶部导航栏，首页，查看首页' },
      { name: 'DSS', active: false, pv: '顶部导航栏，直发，直发系统' },
      { name: 'FB4', active: false, pv: '顶部导航栏，海外仓，海外仓系统' },
      { name: 'TRS', active: false, pv: '顶部导航栏，转运，转运系统' },
      // { name: 'GRS', active: false, pv: '顶部导航栏，退件，退件系统' },
      {
        name: 'oceanAir',
        pId: '6327',
        active: false,
        pv: '顶部导航栏，B2B头程海运空运委托单'
      },
      {
        name: 'jiyun',
        pId: '6328',
        active: false,
        pv: '顶部导航栏，2B头集运'
      },
      {
        name: 'FXGL',
        permissionId: '6321',
        active: false,
        pv: '顶部导航栏，分销管理，查看分销管理'
      },
      {
        name: 'storeSummary',
        pId: '6233',
        active: false,
        pv: '顶部导航栏，数据中心，查看数据中心'
      },
      {
        name: 'dssAccount',
        pId: '6234',
        active: false,
        pv: '顶部导航栏，我的账户，查看我的账户'
      },
      {
        name: 'serviceCenterIndex',
        pId: '6236',
        active: false,
        pv: '顶部导航栏，服务中心，查看服务中心'
      },
      {
        name: 'productService',
        pId: '6485',
        active: false,
        pv: '顶部导航栏，产品服务，查看直发产品服务'
      }
    ],
    collapsed: false
  },
  getters: {
    getNavItems(state) {
      const val = sessionStorage.getItem('nav.items')
      if (val) {
        return JSON.parse(val)
      } else {
        return state.items
      }
    },
    getCollapsed(state) {
      return state?.collapsed
    }
  },
  mutations: {
    updateCollapsed(state, payload) {
      state.collapsed = payload
    },
    updateNavItems(state, payload) {
      state.items = [...payload]
      console.log('update nav')
      if (payload) {
        sessionStorage.setItem('nav.items', JSON.stringify(payload))
      }
    }
  }
}
