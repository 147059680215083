<template>
  <div class="section">
    <div class="order-card">
      <div class="s-tit">{{ $t('experience_tran_002') }}</div>
      <ranking-table showType="index" :top7="top10"></ranking-table>
    </div>
    <div class="order-card">
      <div class="s-tit">{{ $t('experience_tran_003') }}</div>
      <pie-charts showType="index" :pieData="pieData"></pie-charts>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import PieCharts from '@/views/dataCenter/dss//PieCharts'
import RankingTable from '@/views/dataCenter/dss/RankingTable'
const defaultForm = {
  destination_country_list: '',
  p_name_list: '',
  storage_day_begin: moment()
    .add(-6, 'months')
    .format('YYYY-MM-DD'),
  storage_day_end: moment().format('YYYY-MM-DD')
}
export default {
  name: 'DssOrderData',
  components: { RankingTable, PieCharts },
  computed: {
    isOpenFB4() {
      return this.$store.state.userInfo.isFB4Open
    },
    isCloseFB4() {
      return this.$store.state.brandStatus.FB4
    }
  },
  data() {
    return {
      spinning: false,
      form: { ...defaultForm },
      top10: [],
      pieData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$show()
      this.$post('v2/directOrderReport/deliveredRateTop10', this.form)
        .then(res => {
          this.$hide()
          let top10Data = res.rows
          let top10 = []
          for (let i = 0; i < top10Data.length; i++) {
            if (i < 10) {
              top10.push({
                key: i,
                rangking: i + 1,
                deliever_rate: top10Data[i].deliever_rate * 100 + '%',
                product_name: top10Data[i].product_name
              })
            }
          }
          this.top10 = top10
        })
        .catch(() => {
          this.$hide()
        })
      this.$post('v2/directOrderReport/deliveredRate', this.form)
        .then(res => {
          this.$hide()
          this.pieData = res
        })
        .catch(() => {
          this.$hide()
        })
    }
  }
}
</script>

<style lang="less" scoped>
.section {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 640px;

  .s-tit {
    margin-bottom: 20px;
    width: 100%;
    height: 18px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 900;
    color: #000000;
    line-height: 18px;
  }
  .order-card :nth-child(1) {
    // margin-right: 20px;
  }
  .order-card {
    width: calc(50% - 10px);
    padding: 20px;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}
</style>
