/*
 * @Description: i18n
 * @Autor: S9637/chifuk
 * @Date: 2020-08-27 18:14:34
 * @LastEditors: S9637/chifuk
 * @LastEditTime: 2020-08-27 20:57:10
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

/**
 * 遍历 locales 文件夹中的 json 文件
 * 这种方式回一次性加载所以翻译
 * 鉴于目前只考虑2种语言
 *
 * 后续考虑 延迟加载翻译
 * 比如点击切换语言时再去加载对应的语言翻译
 * i18n.setLocaleMessage(lang, msgs.default)
 *
 * @returns json 数据
 */
function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if (messages[locale]) {
        messages[locale] = { ...messages[locale], ...locales(key) }
      } else {
        messages[locale] = locales(key)
      }
    }
  })
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'zh_CN',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh_CN',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
})
