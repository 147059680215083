<template>
  <div :class="{ idReport: isHomePage }">
    <div v-if="isHomePage" class="title">
      <div class="index-title">
        库存诊断
        <a-icon
          v-if="!$store.getters.isOverSeas"
          type="setting"
          @click="
            () => {
              this.$router.push({
                name: 'index_settings',
                params: { title: '库存诊断' }
              })
            }
          "
        />
      </div>
      <div class="b-more" @click="goDetail">
        更多
        <div class="arrow-right"></div>
      </div>
    </div>

    <div class="inventory-diagnosis" :class="{ isHomePage: isHomePage }">
      <div
        class="diagnosis-box"
        :class="{ f1: !isHomePage }"
        v-for="(data, index) in inventoryData"
        :key="index"
      >
        <p class="diagnosis-title">
          {{ data.name }}
          <a-tooltip class="diagnosis-tooltip">
            <template slot="title">
              {{ data.tip }}
            </template>
            <a-icon type="question-circle" class="tip-circle" />
          </a-tooltip>
        </p>
        <div class="diagnosis-content">
          <span
            class="diagnosis-content-num"
            :style="{ color: index > 1 ? '#f85359' : '#353946' }"
          >
            {{ data.value }}
          </span>
          <a-button v-if="index == 0 && !isHomePage" @click="getCalculate"
            >分布</a-button
          >
          <div class="diagnosis-content-proportion" v-if="index > 1">
            <span v-if="data.per === '-'">-</span>
            <span v-else> 占{{ data.per }}%</span>
          </div>
        </div>
        <div class="diagnosis-foot">
          <div class="percent" v-if="data.trend === '-'">
            <span class="trend">-</span>
          </div>
          <div class="percent up" v-else-if="data.trend === 0">
            <span class="word">环比前日持平</span>
          </div>
          <div class="percent up" v-else-if="data.trend > 0">
            <span><i class="iconfont iconshangzhang"></i></span>
            <span class="trend">{{ data.trend }}%</span>
            <span class="word">环比前日上涨</span>
          </div>
          <div class="percent down" v-else>
            <span><i class="iconfont iconxiajiang"></i></span>
            <span class="trend">{{ Math.abs(data.trend) }}%</span>
            <span class="word">环比前日下降</span>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :visible="modalVisible"
      centered
      :title="$t('预算分布')"
      @cancel="modalVisible = false"
      width="588px"
    >
      <div class="step-modal-body">
        <div class="flex-start">
          <span class="budgetTip"> 您各仓仓租今日预算如下，数据仅供参考 </span>
        </div>
        <a-table
          :columns="budgetColumns"
          :data-source="budgetList"
          :pagination="false"
          :loading="budgetLoading"
          :scroll="{ y: 432 }"
        >
          <span slot="feeAmount" slot-scope="text">
            <a href="javascript:;" class="calcMount">{{ text }}</a>
          </span>
        </a-table>
      </div>
      <template slot="footer">
        <div class="budgetFooter">
          换算总计
          <a href="javascript:;">{{
            !budgetLoading ? storageCalculate.count : '-'
          }}</a
          >人民币
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
const inventoryData = [
  {
    name: '仓租预算(人民币)',
    valueIndex: 'countDoi',
    trendIndex: 'countDoiTrend',
    value: '-',
    trend: '-',
    tip: '根据库存体积和库龄段价格计算的日预日计仓租，具体费用请以账单为准'
  },
  {
    name: '周转天数',
    valueIndex: 'countDoi',
    trendIndex: 'countDoiTrend',
    value: '-',
    trend: '-',
    tip:
      '所有商品至全部出库为止所需要经历的天数，周转天数=在库库存数量/近14天平均出库量'
  },
  {
    name: '库存不足SKU种数',
    valueIndex: 'countSkuDe',
    trendIndex: 'countSkuDeTrend',
    perIndex: 'countSkuDeProportion',
    value: '-',
    trend: '-',
    per: '-',
    tip: '在库SKU中需要补货的SKU，库存可用数量低于安全库存数量为库存不足SKU'
  },
  {
    name: '滞销SKU种数',
    valueIndex: 'countSkuUma',
    trendIndex: 'countSkuUmaTrend',
    perIndex: 'countSkuUmaProportion',
    value: '-',
    trend: '-',
    per: '-',
    tip: '周转天数大于120天的SKU种数'
  },
  {
    name: '30天不动销SKU种数',
    valueIndex: 'countSkuFip',
    trendIndex: 'countSkuFipTrend',
    perIndex: 'countSkuFipProportion',
    value: '-',
    trend: '-',
    per: '-',
    tip: '在库SKU中不动销的SKU，超过30天无销售出库数量为不动销SKU'
  }
]

const budgetListColumns = vue => [
  {
    title: vue.$t('仓库名称'),
    dataIndex: 'warehouseName',
    key: 'warehouseName',
    scopedSlots: { customRender: 'warehouseName' }
  },
  {
    title: vue.$t('试算金额'),
    dataIndex: 'feeAmount',
    key: 'feeAmount',
    scopedSlots: { customRender: 'feeAmount' }
  },
  {
    title: vue.$t('币种'),
    dataIndex: 'currencyCode',
    key: 'currencyCode',
    scopedSlots: { customRender: 'currencyCode' }
  }
]

export default {
  props: {
    isHomePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inventoryData: [...inventoryData],
      budgetColumns: budgetListColumns(this),
      budgetList: [],
      storageCalculate: {},
      budgetLoading: false,
      modalVisible: false
    }
  },
  created() {
    this.getAllData()
    this.getTrend()
  },
  methods: {
    // 分布
    getCalculate() {
      this.modalVisible = true
      this.budgetLoading = true
      this.$post('v2/inventoryDiagnose/storageChargesCalculate/trend')
        .then(res => {
          this.budgetList = res.currencyAmount
          this.storageCalculate = res
          this.budgetLoading = false
        })
        .catch(() => (this.budgetLoading = false))
    },
    // 概览数据
    getAllData() {
      this.$post('v2/inventoryDiagnose/inventoryDiagnoseView').then(res => {
        if (res) {
          this.skuData = res
          this.inventoryData = this.inventoryData.map((e, i) => {
            if (i !== 0) {
              const isValueNull =
                res[e.valueIndex] == null || res[e.valueIndex] === ''
              const isTrendNull =
                res[e.trendIndex] == null || res[e.trendIndex] === ''
              const isPerNull =
                res[e.perIndex] == null || res[e.perIndex] === ''
              return {
                ...e,
                value: isValueNull ? '-' : res[e.valueIndex],
                trend: isTrendNull ? '-' : res[e.trendIndex],
                per: isPerNull ? '-' : res[e.perIndex]
              }
            }
            return e
          })
        }
      })
    },
    // 仓租数据
    getTrend() {
      this.$post('v2/inventoryDiagnose/storageChargesCalculate/trend').then(
        res => {
          this.inventoryData = this.inventoryData.map((e, i) => {
            return i === 0
              ? {
                  ...e,
                  value: res.count,
                  trend: res.trend
                }
              : e
          })
        }
      )
    },
    goDetail() {
      this.$router.push('/inventoryDiagnosis')
    }
  }
}
</script>

<style lang="less" scoped>
.inventory-diagnosis {
  background: #f2f4f8;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.diagnosis-box {
  width: 31.33%;
  background: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  padding: 20px 24px;
  color: #323643;
  .diagnosis-title {
    margin: 0;
    overflow: hidden;
    font-weight: bolder;
  }

  .diagnosis-tooltip {
    font-size: 14px;
    font-weight: 200;
    float: right;
    margin-top: 5px;
    // color: #645c5c;
  }

  .diagnosis-content {
    margin-top: 10px;
    overflow: hidden;

    .diagnosis-content-num {
      float: left;
      font-size: 34px;
      font-weight: bolder;
    }

    button {
      height: 30px;
      padding: 0 10px;
      float: right;
      margin-top: 10px;
    }

    .diagnosis-content-proportion {
      float: right;
      margin-top: 15px;
    }
  }

  .diagnosis-foot {
    margin: 10px 0 0 0;
  }
}

.f1 {
  flex: 1;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.idReport {
  width: 66.67%;
  height: 332px;
  margin-right: 20px;
  overflow: auto;
  border-top-left-radius: 4px;
  border-top-left-radius: 4px;
}

.title {
  padding: 20px;
  background-color: #fff;
}

.isHomePage {
  justify-content: flex-start;
  // width: 66.67%;
  height: 274px;
  margin-bottom: 0;
  background: #fff;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  // border-radius: 8px;
  .diagnosis-box {
    padding: 16px;
  }
}
</style>
