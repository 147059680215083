<template>
  <div class="product">
    <div class="item-tit">{{ $t('product_expression_059') }}</div>
    <div class="item-box">
      <div class="range-box">
        <a-input
          style="width:26%"
          @input="validatedInputData('volumeLength')"
          @blur="validatedInputData('volumeLength')"
          ref="reflength"
          :placeholder="$t('长')"
          v-model="form.volumeLength"
          suffix="cm"
        ></a-input>
        *
        <a-input
          ref="refwidth"
          @input="validatedInputData('width')"
          @blur="validatedInputData('width')"
          style="width:26%;margin: 0 12px"
          :placeholder="$t('宽')"
          v-model="form.width"
          suffix="cm"
        ></a-input>
        *
        <a-input
          style="width:26%"
          @input="validatedInputData('height')"
          @blur="validatedInputData('height')"
          ref="refheight"
          :placeholder="$t('高')"
          v-model="form.height"
          suffix="cm"
        ></a-input>
      </div>
    </div>

    <div class="item-tit">{{ $t('product_expression_005') }}</div>
    <div class="item-box">
      <a-radio-group v-model="form.isBattery" size="small" button-style="solid">
        <a-radio-button
          v-for="(item, index) in isBatteryArr"
          class="radio-item"
          :key="index"
          :value="item.value"
        >
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
      <div class="tips-box warring-box" v-if="form.isBattery">
        <i class="icon iconfont iconkefubeifen"></i>
        {{ $t('attrTips001') }}
      </div>
    </div>

    <div class="item-tit">{{ $t('sv.t11') }}</div>
    <div class="item-box">
      <a-radio-group size="small" v-model="form.cargoType" button-style="solid">
        <a-radio-button
          v-for="(item, index) in cargoTypeArr"
          class="radio-item"
          :key="index"
          :value="item.value"
        >
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="item-tit">{{ $t('product_expression_060') }}</div>
    <div class="item-box">
      <div class="warring-box" v-if="embargoCargoTypeList.length">
        <i class="icon iconfont iconjingshi"></i
        >{{ $t('product_expression_061') }}
        <span v-for="(eItem, eIndex) in embargoCargoTypeList" :key="eIndex"
          >{{ eItem.name }}、</span
        >{{ $t('product_expression_064') }}
      </div>
      <a-radio-group
        v-if="attrData.length > 0"
        v-model="form.categoryId"
        size="small"
        button-style="solid"
      >
        <a-popover>
          <template slot="content">
            <p>{{ $t('product_expression_063') }}</p>
          </template>
          <a-radio-button class="radio-item" value="product_expression_062">
            {{ $t('product_expression_062') }}
          </a-radio-button>
        </a-popover>

        <a-radio-button
          class="radio-item"
          v-for="item in limitCargoTypeList"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </div>

    <div class="item-tit">{{ $t('declared.value') }}</div>
    <div class="item-box">
      <a-input
        v-model="form.declareValue"
        @input="validatedInputData('declareValue')"
        @blur="validatedInputData('declareValue')"
        :placeholder="$t('product_expression_065')"
      >
        <a-select slot="addonAfter" default-value="USD" style="width: 80px">
          <a-select-option value="USD">
            USD
          </a-select-option>
        </a-select>
      </a-input>
    </div>
    <div class="btn-box">
      <a-button @click="() => $emit('reset')">{{ $t('reset') }}</a-button>
      <a-button @click="() => $emit('ok')" type="primary">
        {{ $t('index_tran_016') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { isBatteryArr, cargoTypeArr } from './data'
export default {
  name: 'GoodsAttr',
  props: {
    attrData: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object
      // require: true,
      // default: ()=>({
      //   trackType: '',
      //   serviceLevel: '',
      // })
    }
  },
  data() {
    return {
      isBatteryArr: isBatteryArr(this),
      cargoTypeArr: cargoTypeArr(this),
      form: {
        volumeLength: '',
        width: '',
        height: '',
        isBattery: '', // 货物属性 是否是否带电
        cargoType: '', //D 文件 P 包裹"
        categoryId: '', //"品类属性id"
        declareValue: '' //"申报价值"
      }
    }
  },
  computed: {
    // 禁运分类
    embargoCargoTypeList() {
      return this.attrData.filter(item => item.attribute === 'P') || []
    },
    // 限运分类
    limitCargoTypeList() {
      return this.attrData.filter(item => item.attribute === 'R') || []
    }
  },
  created() {
    this.form = {
      ...this.formData
    }
    this.form.cargoType = this.formData.cargoType || this.form.cargoType
  },
  methods: {
    validatedInputData(filed = 'width') {
      let reg = /^\d*\.?\d{0,3}$/
      if (!reg.test(this.form[filed])) {
        // 如果输入的数据不符合要求，将其设置为最后一个有效值
        this.form[filed] = this.form[filed].slice(0, -1)
      }
      // 如果输入的数据包含除数字和小数点以外的字符，将其删除
      this.form[filed] = this.form[filed].replace(/[^0-9.]/g, '')
      // 如果输入的数据有多个小数点，只保留第一个
      this.form[filed] = this.form[filed].replace(/\.+/g, function(m, i) {
        return i === this.form[filed].indexOf('.') ? '.' : ''
      })
    },
    rangeValidate(filed = 'width') {
      const value = this.form[filed]
      if (!value) {
        if (!this.form.volumeLength && !this.form.width && !this.form.height) {
          this.setRangeErrorStatus('volumeLength', false)
          this.setRangeErrorStatus('width', false)
          this.setRangeErrorStatus('height', false)

          this.setRangeErrorStatus(filed, false)
          return (this[filed + 'Error'] = false)
        }

        this.setRangeErrorStatus(filed, true)
        return (this[filed + 'Error'] = true)
      }
      if (_.isNumber(+value) && value > 0 && value < 1000) {
        const dot = value.toString().split('.')
        if (dot.length == 2 && dot[1].length > 2) {
          this.setRangeErrorStatus(filed, true)
          return (this[filed + 'Error'] = true)
        }
        this.setRangeErrorStatus(filed, false)
        return (this[filed + 'Error'] = false)
      } else {
        this.setRangeErrorStatus(filed, true)
        return (this[filed + 'Error'] = true)
      }
    },
    setRangeErrorStatus(filed, isError) {
      this.$refs['ref' + filed].$el.setAttribute(
        'class',
        isError
          ? 'ant-input-affix-wrapper has-error'
          : 'ant-input-affix-wrapper'
      )
      this[`${filed}Error`] = isError
    }
  }
}
</script>

<style lang="less" scoped>
.product {
  width: 570px;
  background: #fff;
  .ant-tabs-tabpane {
    max-height: 30vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
}
.query-select {
  .range-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .question-circle {
    color: #c0c7d8;
    cursor: pointer;
  }
}
.item-box {
  padding-bottom: 6px;
  .warring-box {
    margin-bottom: 12px;
    padding: 6px 12px;
    color: #f85359;
    line-height: 20px;
    background: rgba(248, 83, 89, 10%);
    .iconjingshi {
      margin-right: 6px;
      font-size: 14px;
      color: #f85359;
      vertical-align: text-top;
    }
  }
  .tips-box {
    margin-bottom: 0px;
    color: #3960df;
    background: #ebeffb;
  }
}

.item-tit {
  margin-bottom: 6px;
  font-size: 14px;
  color: #545d75;
  font-weight: 400;
  line-height: 20px;
}
.item-tit:nth-of-type(1) {
  margin-top: 0;
}
// radio
::v-deep.radio-item {
  margin-bottom: 6px;
  padding: 0 12px;
  height: 30px;
  line-height: 28px;
  box-sizing: border-box;
  margin-right: 6px;
  border-radius: 4px;
  border: 1px solid #c3cbd5;
}
::v-deep .ant-radio-button .ant-radio-button-checked {
  border-radius: 4px !important;
}
::v-deep .ant-radio-button-wrapper:not(:first-child)::before {
  // position: relative;
  top: 0px;
  left: 0px;
  display: block;
  box-sizing: content-box;
  width: 0px;
  height: 100%;
  padding: 0px 0;
  background-color: transparent;
  transition: background-color 0.3s;
  content: '';
}
.btn-box {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
