import { render, staticRenderFns } from "./DssOrderData.vue?vue&type=template&id=2925158e&scoped=true"
import script from "./DssOrderData.vue?vue&type=script&lang=js"
export * from "./DssOrderData.vue?vue&type=script&lang=js"
import style0 from "./DssOrderData.vue?vue&type=style&index=0&id=2925158e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2925158e",
  null
  
)

export default component.exports