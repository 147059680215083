<i18n>
{
  "zh": {
    "destCountry": "目的国/地区",
    "destPostCode": "目的国/地区邮编",
    "destPostCodeTip": "请输入邮编",
    "destCountryPl": "请选择目的国/地区",
    "postCodeErr": "请输入正确邮编！",
    "send": "发货地",
    "sendPl": "请选择发货地",
    "type": "货物类型",
    "parcel": "包裹",
    "file": "文件",
    "weight": "货物重量",
    "volume": "货物体积",
    "length": "长",
    "width": "宽",
    "height": "高",
    "rectTip": "长 宽 高必须小于1000cm, 且保留2位小数",
    "try": "试算",
    "weightValidate":"请输入重量",
    "weightTip1": "重量必须大于0小于1000kg, 且保留3位小数"
  },
  "en": {
    "destCountry": "Destination Country/Region",
    "destPostCode": "Destination country/region Zip code",
    "destPostCodeTip": "Please enter zip code",
    "destCountryPl": "Please select the destination country/region",
    "postCodeErr": "Please enter the correct zip code!",
    "send": "Ship from",
    "type": "Cargo Type",
    "parcel": "Parcel",
    "file": "File",
    "weight": "Cargo Weight",
    "volume": "Cargo Volume",
    "length": "length",
    "width": "Width",
    "height": "High",
    "rectTip": "Length, width and height must be less than 1000cm, and keep 2 decimal places",
    "try": "Try to calculate",
    "weightValidate":"Please enter the weight",
    "weightTip1": "The weight must be greater than 0 and less than 1000kg, with 3 decimal places"
  },
  "de": {
    "destCountry": "Destination Country/Region",
    "destPostCode": "Destination country/region Zip code",
    "destPostCodeTip": "Please enter zip code",
    "destCountryPl": "Please select the destination country/region",
    "postCodeErr": "Please enter the correct zip code!",
    "send": "Ship from",
    "type": "Cargo Type",
    "parcel": "Parcel",
    "file": "File",
    "weight": "Cargo Weight",
    "volume": "Cargo Volume",
    "length": "length",
    "width": "Width",
    "height": "High",
    "rectTip": "Length, width and height must be less than 1000cm, and keep 2 decimal places",
    "try": "Try to calculate",
    "weightValidate":"Please enter the weight",
    "weightTip1": "The weight must be greater than 0 and less than 1000kg, with 3 decimal places"
  },
  "ja": {
    "destCountry": "Destination Country/Region",
    "destPostCode": "Destination country/region Zip code",
    "destPostCodeTip": "Please enter zip code",
    "destCountryPl": "Please select the destination country/region",
    "postCodeErr": "Please enter the correct zip code!",
    "send": "Ship from",
    "type": "Cargo Type",
    "parcel": "Parcel",
    "file": "File",
    "weight": "Cargo Weight",
    "volume": "Cargo Volume",
    "length": "length",
    "width": "Width",
    "height": "High",
    "rectTip": "Length, width and height must be less than 1000cm, and keep 2 decimal places",
    "try": "Try to calculate",
    "weightValidate":"Please enter the weight",
    "weightTip1": "The weight must be greater than 0 and less than 1000kg, with 3 decimal places"
  }
}
</i18n>
<template>
  <div class="section">
    <NewDssFreight :isHomePage="true" :isDssHomePage="true" />
    <!-- <div class="s-tit">{{ $t('experience_tran_005') }}</div>
    <div class="query-select">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('send')"
              class="label-r-f"
              :colon="false"
            >
              <ShippmentAddress ref="shippmentAddress" @ogId="onReciveOgId" />
            </a-form-model-item>
            <div class="ant-form-explain" v-if="showSendAreaError">
              {{ $t('sendPl') }}
            </div>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('destCountry')"
              class="label-r-f"
              :colon="false"
              ref="refCountry"
            >
              <CountrySelect @pop-value="onSelectCountry" />
            </a-form-model-item>
            <div class="ant-form-explain" v-if="showCountryError">
              {{ $t('destCountryPl') }}
            </div>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('weight')"
              prop="weight"
              :colon="false"
            >
              <a-input v-model="form.weight" suffix="kg"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <div class="btn-box">
          <a-button type="primary" @click="onSubmit">{{
            $t('sv.t1')
          }}</a-button>
        </div>
      </a-form-model>
    </div> -->
  </div>
</template>

<script>
import CountrySelect from '@microDss/component/CountrySelect'
import ShippmentAddress from '@microDss/view/spreadsheet/ShipmentAddress'
import NewDssFreight from '../../serviceCenter/dssFreight/index'

export default {
  name: 'Trial',
  components: { CountrySelect, NewDssFreight, ShippmentAddress },
  computed: {
    isOpenFB4() {
      return this.$store.state.userInfo.isFB4Open
    },
    isCloseFB4() {
      return this.$store.state.brandStatus.FB4
    }
  },
  data() {
    // 重量校验
    let weightValidate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('weightValidate')))
      }
      if (value[0] != '.' && _.isNumber(+value) && value > 0 && value < 1000) {
        const dot = value.toString().split('.')
        if (dot.length == 2 && dot[1].length > 3) {
          return callback(new Error(this.$t('weightTip1')))
        }
        return callback()
      } else {
        return callback(new Error(this.$t('weightTip1')))
      }
    }
    return {
      form: {
        countryName: '',
        country: '',
        weight: '',
        length: '',
        width: '',
        height: '',
        postCode: '',
        ogIdPickup: '',
        orgName: '',
        cargoType: 'P'
      },
      showCountryError: false,
      heightError: false,
      widthError: false,
      lengthError: false,
      showSendAreaError: false,
      isFullRect: true,
      rules: {
        weight: [
          {
            required: true,
            message: this.$t('weightValidate'),
            trigger: 'change'
          },
          { validator: weightValidate, trigger: 'change' }
        ],
        postCode: [
          { pattern: /^[A-Za-z0-9-]+$/, message: this.$t('postCodeErr') }
        ]
      }
    }
  },
  created() {},
  methods: {
    onReciveOgId(id, name) {
      this.form.ogIdPickup = id
      this.form.orgName = name
      this.showSendAreaError = !id
    },
    /**
     * @description: 选择目的国/地区
     * @param {*} code
     * @param {*} name
     * @return {*}
     */
    onSelectCountry(code, name) {
      this.form.countryName = name
      this.form.country = code
      this.clearCountryValidateStatus()
    },
    /**
     * @description: 提交查询
     * @param {*}
     * @return {*}
     */
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (!this.form.ogIdPickup) {
          this.showSendAreaError = true
        } else {
          this.showSendAreaError = false
        }

        // 校验目的国家/地区
        if (!this.form.country) {
          const wrapper = this.$refs.refCountry.$el
          if (wrapper) {
            const control = wrapper.getElementsByClassName(
              'ant-form-item-control'
            )[0]
            control.setAttribute('class', 'ant-form-item-control has-error')
            this.showCountryError = true
          }
        }

        if (!(!this.form.length && !this.form.width && !this.form.height)) {
          if (!this.form.length) this.setRangeErrorStatus('length', true)
          if (!this.form.width) this.setRangeErrorStatus('width', true)
          if (!this.form.height) this.setRangeErrorStatus('height', true)
        }

        if (
          valid &&
          !this.showCountryError &&
          !this.showSendAreaError &&
          !(this.heightError || this.widthError || this.lengthError)
        ) {
          this.fetchList()
        } else {
          return false
        }
      })
    },
    /**
     * @description:
     * @param {*}
     * @return {*}
     */
    fetchList() {
      // 跳转试算页面
      console.log('this.form:', this.form)
      this.$router.push({
        name: 'dssFreight',
        params: {
          ...this.form,
          orgName:
            this.form.ogIdPickup == 10 && !this.form.orgName
              ? '深圳'
              : this.form.orgName
        }
      })
    },
    /**
     * @description: 重置查询
     * @param {*}
     * @return {*}
     */
    resetForm() {
      this.$refs.ruleForm.resetFields()
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
      this.form.cargoType = 'P'
      this.clearCountryValidateStatus()
      this.$root.bus.$emit('clear')
      this.$refs['shippmentAddress'].clearSelected()

      this.setRangeErrorStatus('length', false)
      this.setRangeErrorStatus('width', false)
      this.setRangeErrorStatus('height', false)
    },
    clearCountryValidateStatus() {
      this.showCountryError = false
      const wrapper = this.$refs.refCountry.$el
      if (wrapper) {
        const control = wrapper.getElementsByClassName(
          'ant-form-item-control'
        )[0]
        control.setAttribute('class', 'ant-form-item-control')
      }
    },
    /**
     * @description: 长宽高校验
     * @param {*} filed
     * @return {*}
     */
    rangeValidate(filed) {
      const value = this.form[filed]
      if (!value) {
        if (!this.form.length && !this.form.width && !this.form.height) {
          this.setRangeErrorStatus('length', false)
          this.setRangeErrorStatus('width', false)
          this.setRangeErrorStatus('height', false)

          this.setRangeErrorStatus(filed, false)
          return (this[filed + 'Error'] = false)
        }

        this.setRangeErrorStatus(filed, true)
        return (this[filed + 'Error'] = true)
      }
      if (_.isNumber(+value) && value > 0 && value < 1000) {
        const dot = value.toString().split('.')
        if (dot.length == 2 && dot[1].length > 2) {
          this.setRangeErrorStatus(filed, true)
          return (this[filed + 'Error'] = true)
        }
        this.setRangeErrorStatus(filed, false)
        return (this[filed + 'Error'] = false)
      } else {
        this.setRangeErrorStatus(filed, true)
        return (this[filed + 'Error'] = true)
      }
    },
    setRangeErrorStatus(filed, isError) {
      this.$refs['ref' + filed].$el.setAttribute(
        'class',
        isError
          ? 'ant-input-affix-wrapper has-error'
          : 'ant-input-affix-wrapper'
      )
      this[`${filed}Error`] = isError
    }
  }
}
</script>

<style lang="less" scoped>
.section {
  margin-bottom: 24px;
  width: 100%;
  min-height: 192px;
  background: #fff;
  // padding: 20px;
  box-shadow: 0px 18px 46px -18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .s-tit {
    margin-bottom: 20px;
    width: 100%;
    height: 18px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 900;
    color: #000000;
    line-height: 18px;
  }
  .query-select {
    margin-top: -10px;
  }
  .btn-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>
